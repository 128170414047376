<!--
 * @Author: sixteen
 * @Date: 2021-12-20 18:51:41
 * @LastEditTime: 2022-07-28 15:50:23
 * @LastEditors: ZF-WLY
 * @Description: 评估报告
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\appraisalReport.vue
-->

<template>
  <el-container>
    <el-aside>
      <el-input
        v-model="search"
        placeholder="请输入关键词"
        clearable
        maxlength="20"
        class="aside-input"
      />
      <el-tree
        ref="tree"
        class="user-tree"
        :data="treeData"
        default-expand-all
        :filter-node-method="filterTree"
        :expand-on-click-node="false"
        @node-click="onClickTreeRow"
      >
        <span class="el-tree-node__label" slot-scope="{ data }">
          {{ data.label
          }}<span v-if="data.nums!=null && data.nums&gt;0 "
            >({{ data.nums }})</span
          >
        </span>
      </el-tree>
    </el-aside>

    <!-- <div > -->
    <el-main class="basic-main">
      <div v-if="type == 0" class="headerBox">
        <div class="reportListBox">
          <el-tag
            closable
            v-for="item in reportList"
            :key="item.id"
            @click="onClickReportTag(item.id)"
            @close="onCloseReportTag(item.id)"
            :effect="item.id == checkId ? 'dark' : 'light'"
          >
            <div class="tagInfo">
              <span>{{ item.name }}</span>
              <span>{{
                item.evaluateDate
                  ? moment(item.evaluateDate).format("YYYY-MM-DD")
                  : "--"
              }}</span>
            </div>
          </el-tag>
        </div>
        <div class="uploadFileBtn">
          <el-button
            v-if="addUploadReport"
            type="primary"
            @click="setModelInput()"
          >
            上传
          </el-button>
        </div>
      </div>
      <div v-else class="headerBox">
        <div class="reportListBox">
          <el-tag
            v-for="item in reportList"
            :key="item.id"
            @click="onClickScaleTag(item.id)"
            :effect="item.id == checkId ? 'dark' : 'light'"
          >
            <div class="tagInfo">
              <span>{{ item.name }}</span>
              <span>{{item.evaluateDate ? moment(item.evaluateDate).format("YYYY-MM-DD") : "--"}}</span>
            </div>
          </el-tag>
        </div>
      </div>
      <div class="reportFileBox" id="printFile" ref="printFile">
        <reportFile
          v-if="type == 0"
          :fileKey="fileKey"
          :type="filetype"
          :print="true"
        />
        <scaleFile
          v-else-if="type == 1 && scaleId"
          :patientScaleId="scaleId"
          :flag="'3'"
        />
      </div>
    </el-main>
    <!-- </div> -->
    <addDialog
      :reportList="treeData"
      :basicInfoId="basicInfoId"
      :scaleReportTypeId="scaleReportTypeId"
      ref="insert"
      @refresh="getScaleReportList"
    />
  </el-container>
</template>

<script>
import {
  getScaleReportTree,
  getScaleReportList,
  getScaleListByType,
  getScaleReportDetail,
  deleteReport
} from "@/api/patientAssessment";

import reportFile from "@/views/scale/patientAssessment/reportFile.vue"; //显示报告
import addDialog from "@/views/scale/patientAssessment/uploadReportDialog"; //上传报告
import scaleFile from "@/views/scale/patientAssessment/patientScale.vue";

export default {
  data() {
    return {
      search: "", //树搜索
      addUploadReport: true, //上传权限
      scaleReportTypeId: "", //报告类型ID
      type: 100, //,所属类型 0 报告 1 评定量表
      treeData: [],
      reportList: [],
      fileKey: "",
      filetype: "",
      checkId: "",
      scaleId: ""
    };
  },
  components: {
    reportFile,
    scaleFile,
    addDialog
  },
  props: {
    basicInfoId: {
      type: String
    }
  },
  watch: {
    search(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.addUploadReport = this.hasPermission("upload_Report");
    this.getReportTree();
  },
  methods: {
    /**
     * @description: 获取分类树
     * @param {*}
     * @return {*}
     */
    getReportTree() {
      this.$nextTick(() => {
        const param = { basicInfoId: this.basicInfoId };
        getScaleReportTree(param).then(({ res, timeStamp }) => {
          this.treeData = res; // 新的树结构
        });
      });
    },

    /**
     * @description: 报告列表
     * @param {*}
     * @return {*}
     */
    getScaleReportList() {
      this.$nextTick(() => {
        const param = {
          scaleReportTypeId: this.scaleReportTypeId,
          basicInfoId: this.basicInfoId
        };
        getScaleReportList(param).then(({ res, timeStamp }) => {
          this.reportList = res;
          this.timeStamp = timeStamp;
          this.fileKey = "";
          this.filetype = "";
        });
      });
    },

    /**
     * @description: 获取报告详情
     * @param {*}
     * @return {*}
     */
    getScaleReportDetail(id) {
      this.$nextTick(() => {
        const param = {
          id: id
        };
        getScaleReportDetail(param).then(({ res, timeStamp }) => {
          this.fileKey = res.file.id;
          this.filetype = res.file.suffix;
        });
      });
    },

    /**
     * @description: 报告列表
     * @param {*}
     * @return {*}
     */
    getScaleListByType() {
      this.$nextTick(() => {
        const param = {
          scaleReportTypeId: this.scaleReportTypeId,
          basicInfoId: this.basicInfoId
        };
        getScaleListByType(param).then(({ res, timeStamp }) => {
          this.reportList = res;
          this.timeStamp = timeStamp;
          this.fileKey = "";
          this.filetype = "";
        });
      });
    },
    /**
     * @description: 左侧树结构点击事件
     * @param {*}
     * @return {*}
     */
    onClickTreeRow(obj) {
      this.checkId = "";
      this.fileKey = "";
      this.filetype = "";
      (this.scaleId = ""), (this.scaleReportTypeId = obj.id);
      this.type = obj.type;

      if (this.type == 0) {
        this.getScaleReportList();
      } else {
        this.getScaleListByType();
      }
    },
    // 通过名称过滤树结构
    filterTree(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    /**
     * @description: 点击获取报告详情
     * @param {*} id
     * @return {*}
     */
    onClickReportTag(id) {
      this.checkId = id;
      this.getScaleReportDetail(id);
    },

    /**
     * @description: 删除
     * @param {*}
     * @return {*}
     */
    onCloseReportTag(id) {
      const {
        $delMsg,
        $delTitle,
        $messageBoxConfig,
        $handleSuccess
      } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: id };
        deleteReport(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getScaleReportList();
        });
      });
    },

    /**
     * @description: 点击获取量表详情
     * @param {*} id
     * @return {*}
     */
    onClickScaleTag(id) {
      console.log("onClickScaleTag");
      this.checkId = id;
      this.scaleId = this.checkId;
    },

    /**
        /**
         * @description: 点击上传
         * @param {*}
         * @return {*}
         */
    setModelInput() {
      this.$refs.insert.updateVisible(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.user-tree {
  border: 1px solid $tableColor;
  height: 100%;
  max-height: calc(100% - 165px);
  overflow-y: auto;
  margin-bottom: 10px;
}
.el-container {
  margin-top: 0;
}
.headerBox {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  flex-direction: row;
  justify-content: space-between;
  .reportListBox {
    display: flex;
    overflow-x: auto;
    margin-right: 20px;
  }
  .reportListBox .el-tag {
    height: 50px;
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    .tagInfo {
      flex-direction: column;
      display: flex;
    }
  }
}
.reportFileBox {
  margin: 10px 5px;
  max-height: calc(100% - 165px);
  overflow: auto;
}
</style>
