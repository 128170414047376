
<!--
* @Description: 评定项目统计详情页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
    </el-header>
    <el-main class="basic-main">
        <el-form class="searchForm" :inline="true">
            <div class="search-btn-group">
            <el-form-item>
                <el-button type="default" @click="back()">返回</el-button>
            </el-form-item>
            </div>
        </el-form>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @onClickRow="onClickRow">

        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getDetailList } from '@/api/judgeItems'
import generalTable from '@/components/Table'

export default {
    name: 'JudgeItemsDetail',
    components: {
        generalTable,
        
    },
    data() {
        return {
            tableData: [],
            searchOptions: [],
            tableConfig: {
                id: 'judgeItems',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'patientName' // 必填
                    // formatter: (row, column) => {
                    //     return row.archivesInfoId.name
                    // }
                },
                {
                    label: '评定医生', // 必填
                    prop: 'doctorName' // 必填
                },
                {
                    label: '评定次数', // 必填
                    prop: 'patientAssessmentNum' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = {
                    scaleProgramId:this.$route.query.scaleProgramId,
                    patientScaleType:this.$route.query.patientScaleType,
                    evaluationTimeStartTime:this.$route.query.evaluationTimeStartTime,
                    evaluationTimeEndTime:this.$route.query.evaluationTimeEndTime
                }
                getDetailList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = res.total
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...$handleSuccess})
                })
            })
        },
        onClickRow(row){
            console.log('点击行:',row)
        },
        back() {
        this.$router.back()
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        