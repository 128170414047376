/**
 * 页面: 医治沟通
 * pageName: communication
 **/
import request from '@/utils/request'

/**
 * @description: 获取医治沟通列表数据
 */
export function getList(data) {
  return request({
    url: 'cooperativeWork/list',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 新增医治沟通数据
 */
export function insertData(data) {
  return request({
    url: '/cooperativeWork/add',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 修改医治沟通数据
 */
export function editData(data, timeStamp) {
  return request({
    url: '/cooperativeWork/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 获取医治沟通详情数据
 */
 export function getDetail(data) {
  return request({
    url: '/cooperativeWork/editInfo',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 获取医治沟通详情数据
 */
export function getInfoDetail(data) {
  return request({
    url: '/cooperativeWork/cooperativeInfo',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 添加建议
 */
 export function addSuggestion(data, timeStamp) {
  return request({
    url: '/cooperativeWork/suggestion',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 删除医治沟通数据
 */
export function deleteData(data, timeStamp) {
  return request({
    url: '/cooperativeWork/delete',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
/**
 * @description: 发布
 */
export function publish(data, timeStamp) {
  return request({
    url: '/cooperativeWork/publish',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
/**
 * @description: 开始
 */
export function start(data, timeStamp) {
  return request({
    url: '/cooperativeWork/start',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
/**
 * @description: 结束
 */
export function end(data, timeStamp) {
  return request({
    url: '/cooperativeWork/end',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
