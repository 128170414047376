<!--
 * @Author: your name
 * @Date: 2021-03-18 11:04:56
 * @LastEditTime: 2022-01-26 13:34:15
 * @LastEditors: ZF-WLY
 * @Description: 日志-对话框
 * @FilePath: \recovery_management\src\views\system\record\dialog.vue
-->
<template>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @closed="resetForm"
        width="60%"
    >
		<el-descriptions class="margin-top" :column="2">
			<el-descriptions-item label="主键">{{ form.id || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="标签">{{ form.controllerTag || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="链路Id">{{ form.traceld || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="执行设备">{{ form.executorDevice || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="执行请求Ip">{{ form.executorlp || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="执行时间">{{ moment(form.executorTime).format("YYYY-MM-DD HH:mm:ss") || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="执行人">{{ form.executorUser || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="执行设备登陆的uuid">{{ form.executorUuid || commonConfig.$nullData }}</el-descriptions-item>
		</el-descriptions>
		<el-descriptions class="margin-top" :column="1">
            <el-descriptions-item label="URL">{{ form.uri || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="参数">{{ form.methodParam || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="返回信息">{{ form.returnMsg || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="方法描述">{{ form.methodDescribed || commonConfig.$nullData }}</el-descriptions-item>
			<el-descriptions-item label="日志消息">{{ form.logMessage || commonConfig.$nullData }}</el-descriptions-item>
		</el-descriptions>
        <generalTable
          ref="mainTable"
          :table-data="form.list"
          :config="tableConfig"
        >
            <template slot="methodName" slot-scope="scope">
                <a v-if="scope.data.row.methodName" class="one-line clickCell" @click="toDetail('执行请求的方法', scope.data.row.methodName)">{{ scope.data.row.methodName }}</a>
                <span v-else>{{commonConfig.$nullData}}</span>
            </template>
            <template slot="paramJson" slot-scope="scope">
                <a v-if="scope.data.row.paramJson" class="one-line clickCell" @click="toDetail('参数', scope.data.row.paramJson)">{{ scope.data.row.paramJson }}</a>
                <span v-else>{{commonConfig.$nullData}}</span>
            </template>
            <template slot="historicalData" slot-scope="scope">
                <a v-if="scope.data.row.historicalData" class="one-line clickCell" @click="toDetail('修改前的数据', scope.data.row.historicalData)">{{ scope.data.row.historicalData }}</a>
                <span v-else>{{commonConfig.$nullData}}</span>
            </template>
        </generalTable>
		<span slot="footer" class="dialog-footer">
			<el-button @click="submit()">关 闭</el-button>
		</span>
        <detailDialog ref="detail" :label="label" :content="content" />
    </el-dialog>
</template>
<script>
import moment from 'moment'
import generalTable from "@/components/Table"
import detailDialog from '@/views/system/record/detail'
export default {
    components: {
        generalTable,
        detailDialog
    },
    data() {
        return {
            tableConfig: {
                id: "logTableConfig",
                index: true, // 不必填 序号
                column: [
                    {
                        label: "主键", // 必填
                        prop: "id", // 必填
                        width:200,
                    },
                    {
                        label: "参数", // 必填
                        slot: "paramJson", // 必填
                        width:200,
                    },
                    {
                        label: "sql信息", // 必填
                        prop: "sqllnfo", // 必填
                        width:200,
                    },
                    {
                        label: "执行的表名", // 必填
                        prop: "tableName", // 必填
                        width:200,
                    },
                    {
                        label: "sql类型", // 必填
                        prop: "sqlType", // 必填
                        width:120,
                    },
                    {
                        label: "参与修改的主键", // 必填
                        prop: "tableld", // 必填
                        width:200,
                    },
                    {
                        label: "影响记录数", // 必填
                        prop: "affectNumber", // 必填
                        width:120,
                    },
                    {
                        label: "post请求id", // 必填
                        prop: "logPostTraceld", // 必填
                        width:200,
                    },
                    {
                        label: "修改前的数据", // 必填
                        slot: "historicalData", // 必填
                        width:200,
                    },
                    {
                        label: "执行请求的方法", // 必填
                        slot: "methodName", // 必填
                        width:200,
                    },
                ],
            },
            label: "",
            content: ""
        }
    },
    mounted() {
    },
    props: {
        dialogTitle: String,
        dialogVisible: Boolean,
        form: Object
    },
    methods: {
        resetForm() {
            this.$emit('show', false)
        },
        submit() {
            this.$emit('show', false)
        },
        toDetail(label, content){
            this.label = label
            this.content = content
            this.$refs.detail.updateVisible(true)
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>