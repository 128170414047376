
<!--
* @Description: 操作项目页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group">
        <el-button type="primary" v-if="addStatus" @click="toInsert()">新增</el-button>
        <el-button type="primary" v-if="delesStatus" @click="toDelete()">批量删除</el-button>
      </el-row>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
          <el-button type="text" v-if="editStatus" @click="toEdit(scope)">编辑</el-button>
          <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog :id="checkId" :timeStamp="timeStamp" ref="insert" @submit="search" />
    </el-main>
  </el-container>
</template>

<script>
import { getList, deleteData, deleteDataList, } from "@/api/operation";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/businessRules/operation/addDialog";
import detailDialog from "@/views/businessRules/operation/detailDialog";
import { getDict } from '@/api/common'

export default {
  name: "Operation",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "select",
          placeholder: "请选择",
          key: "operType",
          filterable: true,
          label: "所属治疗",
          options: [],
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入项目名称",
          key: "name",
          label: "操作项目名称",
          defaultVal: "",
        },
      ],
      tableConfig: {
        id: "operation",
        align: "center", // 不必填 默认为center
        selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "操作项目名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "所属治疗", // 必填
            prop: "operTypeLabel", // 必填
          },
          {
            label: "医嘱类型", // 必填
            prop: "doctorOrderTypeLabel", // 必填
          },
          {
            label: "是否生效", // 必填
            prop: "chargeLabel", // 必填
          },
          {
            label: "操作项目时间片", // 必填
            prop: "timesLabel", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      addStatus: true, // 新增按钮权限
      editStatus: true, // 修改按钮权限
      delStatus: true, // 删除按钮权限
      delesStatus: true, // 批量删除按钮权限
    };
  },
  created() {
    this.getList();
    this.getOperTypeList();
    this.addStatus = this.hasPermission('oper-add')
    this.editStatus = this.hasPermission('oper-edit')
    this.delStatus = this.hasPermission('oper-del')
    this.delesStatus = this.hasPermission('oper-deles')
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total)
          this.timeStamp = timeStamp;
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
      var ids =[scope.data.row.id]
        deleteDataList(ids, this.timeStamp).then(({ res }) => {
          this.$message({...this.commonConfig.$handleSuccess});
          this.getList()
        });
      });
    },
    /**
     * 批量删除功能
     */
    toDelete() {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig;
      const checkedArray = this.$refs.mainTable.getChecked();
      const ids =checkedArray.map((element) => {
          return element.id;
      });
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
      deleteDataList(ids, this.timeStamp).then(({ res }) => {
        this.$message({...this.commonConfig.$handleSuccess});
        this.getList()
      });
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
    getOperTypeList(){
      getDict({ type: 'oper_type' }).then(({res}) => {
        this.searchOptions[0].options = res.oper_type;
      });
    },
    onClickRow(){}
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        