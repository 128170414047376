/*
 * @Author: your name
 * @Date: 2021-12-28 16:34:34
 * @LastEditTime: 2021-12-29 16:20:48
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\therapistPersonal.js
 */

/**
 * 页面: 治疗师个人工作量统计
 * pageName: therapistPersonal
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗师个人工作量统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/therapistPersonal/findList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 导出
 */
 export function toExport(data) {
    return request({
        url: '/statistics/therapistPersonal/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}
        