
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增设备' : '修改设备'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">设备信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="设备名称" prop="deviceId">
                        <el-select
                            v-model="form.deviceId"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            @change="checkDiffName"
                        >
                            <el-option
                                v-for="option in ruleDeviceList"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="设备编号" prop="deviceNumber">
                        <el-input
                            v-model="form.deviceNumber"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                            @change="checkDiffName"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗设备简码" prop="deviceCode">
                        <el-input
                            v-model="form.deviceCode"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗区" prop="consultingRoom">
                        <el-select
                            v-model="form.consultingRoom"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in consulting_room"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="负责人" prop="userId">
                        <el-input
                            @focus="toSelectPerson"
                            readonly="true"
                            prefix-icon="el-icon-search"
                            v-model="form.userName"
                            placeholder="请选择"
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-select
                            v-model="form.status"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in armarium_status"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="form.remark"
                            placeholder="请输入备注"
                            clearable
                            maxlength="200"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <selectPerson selectType="single" ref="selectPerson" @selectPerson="selectPerson"></selectPerson>
    </el-dialog>
</template>
<script>

import selectPerson from '@/views/device/medicalCare/selectPerson'
import { insertData, editData, getDetail, getruleDeviceList, checkDiffName } from "@/api/medicalCare";
import { getDict } from "@/api/common";
export default {
    components: {
        selectPerson,
    },
    props: {
        id: {
            type: String,
        },
    },
    
    data() {
        var validateDeviceNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入设备编号'));
            } else if (this.diffName!=0) {
                callback(new Error('请输入不同的设备编号'));
            } else {
                callback();
            }
        };
        return {
            rules: {
                deviceId: [
                    {
                        required: true,
                        message: "请输入设备名称",
                        trigger: "blur",
                    },
                ],
                deviceNumber: [
                    {   validator: validateDeviceNumber, trigger: 'blur' }
                ],
                consultingRoom: [
                    {
                        required: true,
                        message: "请选择治疗区",
                        trigger: "change",
                        
                    },
                ],
                userId: [
                    {
                        required: true,
                        message: "请选择负责人",
                        trigger: "change",
                        
                    },
                ],
                status: [
                    {
                        required: true,
                        message: "请选择负责人",
                        trigger: "change",
                        
                    },
                ],
            },
            ruleDeviceList: [],
            form: {
                deviceId:"",
                deviceNumber:"",
                deviceCode:"",
                consultingRoom:"",
                status:"0",
                userId:"",
                userName:"",
                remark:"",
            },
            armarium_status:[],
            consulting_room:[],
            visible: false,
            diffName:0,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    created() {
        this.getruleDeviceList()
        this.get_armarium_status()
        this.get_consulting_room()
    },
    mounted() {},
    methods: {
        toSelectPerson(){
            this.$refs.selectPerson.updateVisible(true)
        },
        selectPerson(users){
            this.form.userId = users.id
            this.form.userName = users.name
        },
        checkDiffName(){
            if(this.form.deviceId&&this.form.deviceNumber){
                let parm={
                    deviceId:this.form.deviceId,
                    deviceNumber:this.form.deviceNumber,
                    id:this.id?this.id:""
                }
                checkDiffName(parm).then(({res}) => {
                    this.diffName = res;
                    this.$refs.insertForm.validateField('deviceNumber');
                })
            }
            
        },
        get_armarium_status() {
            getDict({ type: 'armarium_status' }).then(({res}) => {
                let typeOptionsCache = res.armarium_status.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                this.armarium_status = typeOptionsCache
            });
        },
        get_consulting_room() {
            getDict({ type: 'consulting_room' }).then(({res}) => {
                let typeOptionsCache = res.consulting_room.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                this.consulting_room = typeOptionsCache
            });
        },
        getruleDeviceList(){
            getruleDeviceList().then(({ res }) => {
                this.ruleDeviceList = res.map((item,index)=>{
                    return{
                        value:item.id,
                        label:item.name,
                    }
                })
            });
        },
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form;
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        parm.id = this.id
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
                this.timeStamp = timeStamp
            });
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form={
                    deviceId:"",
                    deviceNumber:"",
                    deviceCode:"",
                    consultingRoom:"",
                    status:"",
                    userId:"",
                    userName:"",
                    remark:"",
                }
            }
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        