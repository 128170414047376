
<template>
    <el-dialog width="70%" :title="id === '' ? '新增质控指标' : '修改质控指标'" :visible.sync="visible"
        :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox"
            :rules="rules">
            <div class="mdrcc-info-title">
                质控指标
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="质控指标" prop="name">
                        <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="20" />
                    </el-form-item>
                </el-col><el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="编码" prop="code">
                        <el-input v-model="form.code" placeholder="请输入..." clearable maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="年份" prop="year">
                        <el-date-picker v-model="form.year" type="year" value-format="yyyy" placeholder="选择年" style="width:100%"/>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="定义" prop="definition">
                        <el-input v-model="form.definition" placeholder="请输入" clearable type="textarea" :rows="3" resize="none" maxlength="200" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="计算公式" prop="formula">
                        <el-input v-model="form.formula" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="说明" prop="description">
                        <el-input v-model="form.description" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="意义" prop="significance">
                        <el-input v-model="form.significance" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/indicatorManagement'
import { currentYear } from "@/utils/qualityControl"

export default {
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '质控指标', trigger: 'blur' }
                ],
                year: [
                    { required: true, message: '请选择年份', trigger: 'change' }
                ],
            },
            form: {
                name: "",	// 名称		false	string
                code: "",	// 编码		false	string
                year: currentYear + "",	// 年份		false	integer(int32)
                state: "",	// 状态		false	string
                formula: "",	// 公式		false	string
                definition: "",	// 定义		false	string
                description: "",	// 说明		false	string
                significance: "",	// 意义
            },
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail()
                }
                //...todo
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const {year} = this.form
                    const parm = {...this.form, year: year * 1}
                    if (this.id === '') {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                const {year}  = res
                this.form = {
                    ...res,
                    year: year + ""
                }
                this.timeStamp = timeStamp
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>

</style>
      
