
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增用户' : '修改用户'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="用户姓名" prop="trueName">
                        <el-input
                            v-model="form.trueName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                            @blur="setNamePinyinHead"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="工号" prop="workNumber">
                        <el-input
                            v-model="form.workNumber"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="登录账号" prop="username">
                        <el-input
                            v-model="form.username"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="手机号" prop="phone">
                        <el-input
                            v-model="form.phone"
                            placeholder="请输入..."
                            clearable
                            maxlength="11"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="出生日期" prop="birthday">
                        <el-date-picker
                            v-model="form.birthday"
                            align="right"
                            type="date"
                            clear-icon="el-input__icon"
                            placeholder="选择日期"
                            :editable="false"
                            style="width: 100%"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="岗位" prop="position">
                        <el-select
                            v-model="form.position"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in positionOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="职位名称" prop="jobTitle">
                        <el-select
                            v-model="form.jobTitle"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in jobTitleOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="所属机构" prop="deptList">
                        <el-cascader
                            v-model="form.deptList"
                            :options="departmentOptions"
                            :show-all-levels="false"
                            placeholder="请选择"
                            collapse-tags
                            :props="{
                                checkStrictly: true,
                                label: 'label',
                                value: 'id',
                                emitPath:false,
                                multiple: true,
                            }"
                            clearable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="角色" prop="roleList">
                        <el-select
                            v-model="form.roleList"
                            clearable
                            placeholder="请选择"
                            multiple
                            filterable
                        >
                            <el-option
                                v-for="item in roleOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗区" prop="consultingRoom">
                        <el-select
                            v-model="form.consultingRoom"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in consultingRoomOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-switch
                            v-model="form.status"
                            name="status"
                            active-text="启用"
                            inactive-text="禁用"
                            inactive-value="1"
                            active-value="0"
                            :disabled="id === '1'"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="是否登录" prop="login">
                        <el-switch
                            v-model="form.login"
                            name="login"
                            active-text="是"
                            inactive-text="否"
                            inactive-value="1"
                            active-value="0"
                            :disabled="id === '1'"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="form.remark"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4}"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createUser, updateUserInfo, getUserInfo } from '@/api/user'
import { getDict } from '@/api/common'
import { validatorPhone } from '@/utils/validate'
import { CH2CYTPALL } from '@/utils/common'
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        const validatorUserName = (rule, value, callback) => {
            if (/^(?![A-Za-z]+$)(?![\W_]+$)\S{6,20}$/.test(value) === false) {
                callback(new Error('6-20位,数字、字母、下划线至少两项 '))
            } else {
                callback()
            }
        }
        return {
            rules: {
                trueName: [
                    {
                        required: true,
                        message: '请输入用户姓名',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 10,
                        message: '长度在 2 到 10 个字符',
                        trigger: 'blur'
                    }
                ],
                workNumber: [
                    { required: true, message: '请输入工号', trigger: 'blur' },
                    {
                        min: 1,
                        max: 20,
                        message: '长度在 1 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        validator: validatorUserName,
                        trigger: 'blur'
                    }
                ],
                phone: [
                    {
                        required: true,
                        validator: validatorPhone,
                        trigger: 'blur'
                    }
                ],
                birthday: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change'
                    }
                ],
                position: [
                    { required: true, message: '请选择岗位', trigger: 'change' }
                ],
                consultingRoom: [
                    { required: true, message: '请选择治疗区', trigger: 'change' }
                ],
                jobTitle: [
                    { required: true, message: '请选择职位名称', trigger: 'change' }
                ],
                deptList: [
                    {
                        required: true,
                        message: '请选择机构',
                        trigger: 'change',
                        type: 'array'
                    }
                ],
                roleList: [
                    { required: true, message: '请选择角色', trigger: 'change' }
                ]
            },
            form: {
                username: '', // 登录账号
                trueName: '', // 真实姓名
                phone: '', // 电话
                deptList: [], // 部门id
                deptLabels: [], // 部门名称
                id: '', // 用户id
                workNumber: '', // 工号
                status: '0', // 状态
                position: '', // 岗位
                consultingRoom:'',//治疗区
                jobTitle: '',//职位名称
                roleList: [], // 角色
                remark: '', // 备注
                login: '0' // 登录状态
            },
            total: 0,
            visible: false,
            positionOptions: [], // 岗位配置项
            consultingRoomOptions:[],//治疗区配置项
            jobTitleOptions :[],//职位名称配置项
            departmentOptions: [], // 部门配置项
            roleOptions: [], // 角色配置项
            timeStamp: '', // 时间戳
            submitFlag: true // 防抖
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getUserInfo()
                this.getDoctorPosition()
                this.getJobTitle()
                this.getConsultingRoom()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    const time = new Date(parm.birthday).getTime()
                    parm.birthday = time
                    parm.namePinyinHead = CH2CYTPALL(parm.trueName);
                    if (parm.id === '') {
                        createUser(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)

                        })
                    } else {
                        updateUserInfo(parm, this.timeStamp).then(({ res }) => {
                            console.log(parm);
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }
                } else{
                    this.submitFlag = true
                }
            })
        },
        submitFailure(error) {
            this.submitFlag = true
        },
        /**
         * 患者姓名首字母
         * 根据患者姓名自动获取患者姓名的拼音首字母
         */
        setNamePinyinHead()  {
            this.form.namePinyinHead = CH2CYTPALL(this.form.trueName);
        },
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        /**
         * 移除所有树结构中禁用的机构
         * @description: 使用status判断，status为“1”时，即为禁用的机构
         * @param {Array} orgData 原始数组结构
         * @param {Array} newData 过滤掉禁用机构之后的树结构
         * @return {*}
         */
        removeDisable(orgData, newData) {
            orgData &&
                orgData.forEach((item) => {
                    if (item.status !== '1') {
                        newData.push({ ...item, children: [] })
                    }
                    if (item.children) {
                        newData.length &&
                            this.removeDisable(
                                item.children,
                                newData[newData.length - 1].children
                            )
                    }
                })
        },
        /**
         * 移除空的子节点
         * @description: 如果某个对象中，children的数组长度为0，则移除该层children；递归遍历查找每层children
         * @param {Array} orgData 原始树结构数组
         * @return {Array} 移除原始树结构中空children后的结果
         */
        removeEmptyChild(orgData) {
            orgData = orgData.map((item) => {
                let { children, ...temp } = item
                if (children && children.length) {
                    return {
                        ...temp,
                        children: this.removeEmptyChild(children) // 递归遍历，把新的过滤好的children插入到原先的children中
                    }
                } else if (children) {
                    return temp
                }
            })
            return orgData
        },
        getUserInfo() {
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getUserInfo(param).then(({ res, timeStamp }) => {
                this.departmentOptions = res.deptLevelTreeList
                this.roleOptions = res.roleResList
                this.timeStamp = timeStamp
                if (res.sysUserDetailsRes && res.sysUserDetailsRes.id) {
                    this.form.id = res.sysUserDetailsRes.id
                    this.form.deptList = [] // 新的树结构
                    this.removeDisable(
                        res.deptLevelTreeList,
                        this.form.deptList
                    ) // 移除原先禁用的机构
                    this.form.deptList = this.removeEmptyChild(
                        this.form.deptList
                    ) // 移除空的子节点
                    this.form = {
                        ...this.form,
                        ...res.sysUserDetailsRes
                    }
                }
            })
        },
        // 获取岗位字段
        getDoctorPosition() {
            getDict({ type: 'position' }).then(({ res }) => {
                this.positionOptions = res.position
            })
        },
        //获取职位名称
        getJobTitle() {
            getDict({ type: 'job_title' }).then(({ res }) => {
                this.jobTitleOptions = res.job_title
            })
        },
        //获取治疗区字段
        getConsultingRoom() {
            getDict({ type: 'consulting_room' }).then(({ res }) => {
                this.consultingRoomOptions = res.consulting_room
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped>
</style>

