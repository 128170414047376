<!--
 * @Author: your name
 * @Date: 2021-11-26 08:45:20
 * @LastEditTime: 2022-02-23 09:50:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\treatment\patientTreatment\detailDialog.vue
-->

<!--
* @Description: 患者治疗-详情页面
* @Reference:
-->

<template>
<el-container>
    <el-tabs type="border-card" style="width:100%" @tab-click="tabChange" value="treatmentPlan">
        <el-tab-pane :lazy="true" label="治疗计划" name="treatmentPlan"><treatmentPlan :basicInfoId="basicInfoId" v-if="tabValue === 'treatmentPlan'"/></el-tab-pane>
        <el-tab-pane :lazy="true" label="治疗记录" name="treatmentRecord"><treatmentRecord :basicInfoId="basicInfoId" v-if="tabValue === 'treatmentRecord'" /></el-tab-pane>
    </el-tabs>
    <patient360 width="700px" height="500px" :id="$route.query.archivesInfoId" :basicInfoId="basicInfoId" />
</el-container>
</template>

<script>
import treatmentPlan from '@/views/treatment/patientTreatment/treatmentPlan.vue'
import treatmentRecord from '@/views/treatment/patientTreatment/treatmentRecord.vue'
import patient360 from '@/views/other/Patient360'

export default {
    name: 'detail',
    components:{
        patient360,
        treatmentPlan,
        treatmentRecord
    },
    data() {
        return {
            tabValue: 'treatmentPlan',
            basicInfoId: this.$route.query.basicInfoId//患者住院id
        }
    },
    created() {
    },
    mounted: function() {},
    methods: {
        tabChange(event){
            this.tabValue = event.name
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>


        