<!--
 * @Author: ligc
 * @Date: 2021-11-25 16:19:38
 * @LastEditTime: 2021-11-30 16:57:15
 * @LastEditors: Please set LastEditors
 * @Description: 治疗记录
 * @FilePath: \recovery_management\src\views\treatment\patientTreatment\treatmentRecord.vue
-->


<template>
  <div>
    <el-header height="auto">
      <searchForm
        style="padding-top: 15px"
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"
        ><el-button type="primary" @click="toExport()">导出</el-button></el-row
      >
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
    </el-main>
  </div>
</template>
<script>
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getTreatMentList } from "@/api/arrangeTreatment";
import { exportTreat } from "@/api/patientTreatment";
import detailDialog from "@/views/treatment/arrangeTreatment/detailDialog";

export default {
  name: "TreatmentRecord",
  props: {
    basicInfoId: {
      type: String,
    },
  },
  components: {
    generalTable,
    searchForm,
    detailDialog,
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "therapyName",
          label: "治疗师",
          defaultVal: "",
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "operName",
          label: "治疗项目",
          defaultVal: "",
        },
        {
          type: "daterange",
          usesTimePlaceholder: "治疗开始日期",
          usedTimePlaceholder: "治疗结束日期",
          key: "treatmentDate",
          label: "治疗时间",
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "患者姓名", // 必填
            prop: "basicInfoName", // 必填
          },
          {
            label: "住院号", // 必填
            prop: "recordNum", // 必填
          },
          {
            label: "就诊号", // 必填
            prop: "serialNum", // 必填
          },
          {
            label: "治疗师", // 必填
            prop: "therapyName", // 必填
          },
          {
            label: "医嘱类型", // 必填
            prop: "doctorOrderTypeLabel", // 必填
          },
          {
            label: "所属治疗", // 必填
            prop: "operTypeLabel", // 必填
          },
          {
            label: "治疗项目", // 必填
            prop: "operName", // 必填
          },
          {
            label: "治疗时间", // 必填
            prop: "treatmentDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.treatmentDate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            },
          },
          {
            label: "状态", // 必填
            prop: "statusLabel", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "180", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkStatus: true,
      backStatus: true,
      timeStamp: "", //时间戳 用于修改数据或删除数据
      paramObject: null, //不带分页的参数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
    };
  },
  created() {
    this.checkStatus = this.hasPermission("treatment-record");
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data = {}) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    back() {
      this.$router.back();
    },
    getList() {
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        const param = Object.assign(this.getParam(), {
          basicInfoId: this.basicInfoId,
        });
        getTreatMentList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    /**
     * @description:导出
     * @param {*}
     * @return {*}
     */
    toExport() {
      const obj = Object.assign(this.paramObject, {
        basicInfoId: this.basicInfoId,
      }); //参数
      exportTreat(obj)
        .then(({ res }) => {
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const fileName = "治疗记录.xlsx";
          const linkNode = document.createElement("a");
          linkNode.download = fileName; //a标签的download属性规定下载文件的名称
          linkNode.style.display = "none";
          linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
          document.body.appendChild(linkNode);
          linkNode.click(); //模拟在按钮上的一次鼠标单击

          URL.revokeObjectURL(linkNode.href); // 释放URL 对象
          document.body.removeChild(linkNode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @description:详情
     * @param {*}
     * @return {*}
     */
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      if (paramObj.endTime && paramObj.startTime) {
        paramObj.usesTime = new Date(
          this.moment(paramObj.startTime).format("YYYY-MM-DD") + " 00:00:00"
        ).getTime();
        paramObj.usedTime = new Date(
          this.moment(paramObj.endTime).format("YYYY-MM-DD") + " 23:59:59"
        ).getTime();
      }
      this.paramObject = paramObj;
      const param = Object.assign({ allPatientFlag: "0" }, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
