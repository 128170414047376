
/**
 * 页面: 我的文档
 * pageName: myDocument
 **/
import request from '@/utils/request'

/**
 * @description: 获取我的文档目录树
 */
export function getTreeList(data) {
    return request({
        url: 'knowledgeBaseType/listTree',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取我的文档列表数据
 */
export function getList(data) {
    return request({
        url: 'knowledgeBase/listByUserId',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增我的文档目录
 */
export function insertTreeNode(data) {
    return request({
        url: 'knowledgeBaseType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 新增我的文档数据
 */
export function insertData(data) {
    console.log("------insertData------", data)
    return request({
        url: 'knowledgeBase/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改我的文档数据
 */
export function editData(data, timeStamp) {
    console.log("------editData------", data)
    return request({
        url: 'knowledgeBase/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改我的文档目录
 */
export function editNodeData(data, timeStamp) {
    return request({
        url: 'knowledgeBaseType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取我的文档详情数据
 */
export function getDetail(data) {
    return request({
        url: 'knowledgeBase/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

// knowledgeBaseType/info
            

/**
 * @description: 获取我的文档详情数据
 */
export function getNodeDetail(data) {
    return request({
        url: 'knowledgeBaseType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除我的文档数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: 'knowledgeBase/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            
/**
 * @description: 删除我的文档目录
 */
export function deleteNodeData(data, timeStamp) {
    return request({
        url: 'knowledgeBaseType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        