
<!--
* @Description: 字典管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
    <el-row class="table-btn-group">
        <el-button type="primary"  v-if="addButton" @click="toInsert()">新增</el-button>
    </el-row>
    <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
    >
    <template slot="handle" slot-scope="scope">
      <el-button  type="text" v-if="editButton" @click="toEdit(scope)">编辑</el-button>
      <el-button size="mini" type="text" v-if="runJobButton" @click="handleRunJob(scope)">
        执行
      </el-button>
      <el-button v-if="scope.data.row.scheduleStatus=='0'&& startButton "  type="text" @click="handleModifyScheduleStatus(scope,'1')">
        启用
      </el-button>
      <el-button v-if="scope.data.row.scheduleStatus=='1' && stopButton" type="text" @click="handleModifyScheduleStatus(scope,'0')">
        停用
      </el-button>
      <el-button v-if="scope.data.row.scheduleStatus=='0' && delButton" type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
    </template>
    </generalTable>
    <addDialog :id="checkId" ref="insert" @submit="search" />
    </el-main>
  </el-container>
</template>

<script>
import { getList,deleteData,modifyScheduleStatus,runJobArticle } from "@/api/kettle";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/dataPlatForm/kettle/addDialog";

export default {
  name: "EtlConfig",
  components: {
    searchForm,
    generalTable,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "任务名称",
          key: "jobName",
          label: "任务名称",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "任务状态",
          key: "scheduleStatus",
          filterable: true,
          label: "类型",
          options: [
                {
                    value: '0',
                    label: '停用'
                },
                {
                    value: '1',
                    label: '启用'
                },
            ],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "etlConfig",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "任务名称", // 必填
            prop: "jobName", // 必填
            width: "200",
          },
          {
            label: "任务标识", // 必填
            prop: "jobKey", // 必填
            width: "100",
          },
          {
            label: "执行状态", // 必填
            prop: "runStatus", // 必填
            formatter: (row, column) => {
              return ["待执行", "执行中"][row.runStatus];
            },
          },
          {
            label: "任务状态", // 必填
            prop: "scheduleStatus", // 必填
            formatter: (row, column) => {
              return ["停用", "启用"][row.scheduleStatus];
            },
          },
          {
            label: "执行频率", // 必填
            prop: "scheduleCron", // 必填
          },
          {
            label: "文件路径", // 必填
            prop: "etlPath", // 必填
            width: "200",
          },
          {
            label: "上次更新时间", // 必填
            prop: "lastUpdateDate", // 必填
           formatter: (row, column) => {
              return row.lastUpdateDate?this.moment(row.lastUpdateDate).format("YYYY-MM-DD HH:mm:ss"): this.commonConfig.$nullData;
            },
            width: "220", // 不必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      addButton:true,//新增权限标识
      editButton:true,//编辑权限标识
      runJobButton:true,//执行权限标识
      startButton:true,//启用权限标识
      stopButton:true,//停用权限标识
      delButton:true,//删除权限标识
    };
  },
  created() {
    this.addButton = this.hasPermission('kettle-add')
    this.editButton = this.hasPermission('kettle-edit')
    this.runJobButton = this.hasPermission('kettle-runJob')
    this.startButton = this.hasPermission('kettle-start')
    this.stopButton = this.hasPermission('kettle-stop')
    this.delButton = this.hasPermission('kettle-del')
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    }, 
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    /**
     * @description: 编辑
     */
    toEdit(scope) {
      this.checkId = scope.data.row.id
      this.$refs.insert.updateVisible(true)
    },
    /**
     * @description: 删除
     */
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getList();
        });
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
    /**
     * @description: 修改启用停用状态
     */
    handleModifyScheduleStatus(row, status) {
      const param = {
        id:row.data.row.id,
        scheduleStatus:status,
        jobKey:row.data.row.jobKey,
        scheduleCron:row.data.row.scheduleCron,
        jobName:row.data.row.jobName,
      };
      modifyScheduleStatus(param, this.timeStamp).then((res) => {
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getList();
      })
    },
    /**
     * @description: 执行
     */
    handleRunJob(row) {
      const param = {
        id:row.data.row.id,
        scheduleStatus:row.data.row.status,
        jobKey:row.data.row.jobKey
      };
      runJobArticle(param).then((res) => {  
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getList();
      })
    }
  },


  
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        