
/**
 * 页面: 按照治疗项医嘱统计
 * pageName: treatmentOrder
 **/
import request from '@/utils/request'

/**
 * @description: 获取按照治疗项医嘱统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/treatmentOrder/index',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 获取按照治疗项医嘱统计详情数据
 */
 export function getDetail(data) {
    return request({
        url: 'statistics/treatmentOrder/advice',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
        