<!--
 * @LastEditors: Please set LastEditors
 * @Author: Richardlv
-->

<template>
  <el-dialog
    width="85%"
    :title="insertType == 1 ? '新增医嘱' : '新增组套'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-row :gutter="10" type="flex" justify="start">
      <el-row style="width: 22%; margin-right: 10px">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <!-- 所属治疗筛选 -->
          <el-select
            v-if="insertType == 1"
            v-model="selectType"
            placeholder="请选择"
            :filterable="true"
            style="width: 120px; margin-right: 10px"
          >
            <el-option
              v-for="option in typeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
          <el-select
            v-if="insertType != 1"
            v-model="selectType"
            placeholder="请选择"
            :filterable="true"
            style="width: 120px; margin-right: 10px"
          >
            <el-option
              v-for="option in ruleSetList"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
          <el-input
            v-model="searchName"
            placeholder="请输入"
            clearable
            maxlength="20"
          />
        </div>
        <div
          style="
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 10px;
          "
        >
          <el-button @click="resetSearch()" 
            >重置</el-button
          >
          <el-button type="primary" @click="getDetail()" style="margin-right: 10px">查找</el-button>
        </div>
        <!-- 医嘱列表 -->
        <generalTable
          v-if="insertType == 1"
          ref="oper_type"
          :table-data="ruleOperationList"
          :config="tableConfig"
          style="height: 73%"
          :newHeight="'100%'"
        >
        </generalTable>
        <!-- 组套列表 -->
        <generalTable
          v-if="insertType != 1"
          ref="setList"
          :table-data="ruleOperationList"
          :config="setListConfig"
          style="height: 73%"
          :newHeight="'100%'"
        >
        </generalTable>
        <el-button style="margin-top: 10px" type="primary" @click="addAdvice()"
          >添加</el-button
        >
      </el-row>
      <el-row style="width: 76%">
        <generalTable
          ref="mainTable"
          :table-data="adviceList"
          :config="adviceTableConfig"
          style="height: 70%"
        >
          <template slot="doctorOrderType" slot-scope="scope">
            <el-select
              v-model="scope.data.row.doctorOrderType"
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in adviceTypeOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          <template slot="createCount" slot-scope="scope">
            <span v-if="scope.data.row.doctorOrderType == 1">长期</span>
            <el-input-number
              class="numinput"
              v-if="scope.data.row.doctorOrderType != 1"
              v-model="scope.data.row.createCount"
              size="mini"
              :min="1"
              :max="100"
            ></el-input-number>
          </template>
          <template slot="schCount" slot-scope="scope">
            <el-input-number
              class="numinput"
              v-model="scope.data.row.schCount"
              size="mini"
              :min="1"
              :max="100"
            ></el-input-number>
          </template>
          <template slot="frequencyCount" slot-scope="scope">
            <el-input-number
              class="numinput"
              v-model="scope.data.row.frequencyCount"
              size="mini"
              :min="1"
              :max="100"
            ></el-input-number>
          </template>
          <template slot="frequencyTime" slot-scope="scope">
            <el-input-number
              class="numinput"
              v-model="scope.data.row.frequencyTime"
              size="mini"
              :min="1"
              :max="100"
            ></el-input-number>
          </template>
          <template slot="frequencyUnit" slot-scope="scope">
            <el-select
              style="width: 70px"
              v-model="scope.data.row.frequencyUnit"
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in unitList"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          <template slot="doctorOrder" slot-scope="scope">
            <el-select
              v-model="scope.data.row.doctorOrder"
              placeholder="请选择"
              :filterable="true"
              multiple
            >
              <el-option
                v-for="option in scope.data.row.operOrderList"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          <template slot="handle" slot-scope="scope">
            <el-button
              @click="delAdvice(scope.data.$index)"
              type="text"
              class="delBtn"
              >删除</el-button
            >
          </template>
        </generalTable>
      </el-row>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  getRuleOperationList,
  addPatientAdviceInfo,
  getUserSetList,
} from "@/api/advice";
import { getDict } from "@/api/common";
import generalTable from "@/components/Table";

export default {
  components: {
    generalTable,
  },
  props: {
    basicInfoId: {
      type: String,
    },
    insertType: {
      type: Number,
    },
  },
  data() {
    return {
      ruleOperationList: [],
      typeList: [],
      selectType: "",
      searchName: "",
      typeOptions: [],
      visible: false,
      timeStamp: "", //时间戳 用于修改数据或删除数据
      tableConfig: {
        id: "oper_type",
        selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        column: [
          {
            label: "所属治疗", // 必填
            prop: "operType", // 必填
            formatter: (row, column) => {
              return this.typeList[row.operType];
            },
            width: 80,
          },
          {
            label: "操作项名称", // 必填
            prop: "name", // 必填
          },
        ],
      },
      setListConfig: {
        id: "setList",
        selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        column: [
          {
            label: "组套类型", // 必填
            prop: "operType", // 必填
            formatter: (row, column) => {
              return this.setTypeList[row.hospitalPrivate];
            },
            width: 80,
          },
          {
            label: "组套名称", // 必填
            prop: "name", // 必填
          },
        ],
      },
      adviceList: [],
      adviceTableConfig: {
        id: "advice",
        index: true, // 不必填 序号
        column: [
          {
            label: "所属治疗", // 必填
            prop: "operType", // 必填
            formatter: (row, column) => {
              return this.typeList[row.operType];
            },
            width: 70,
          },
          {
            label: "操作项目名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "医嘱类型",
            slot: "doctorOrderType",
            width: 120,
          },
          {
            label: "次数",
            slot: "createCount",
            width: 110,
          },
          {
            label: "单量",
            slot: "schCount",
            width: 110,
          },
          {
            label: "频率次数",
            slot: "frequencyCount",
            width: 110,
          },
          {
            label: "频率间隔",
            slot: "frequencyTime",
            width: 110,
          },
          {
            label: "频率单位",
            slot: "frequencyUnit",
            width: 110,
          },
          {
            label: "嘱托",
            slot: "doctorOrder",
            width: 120,
          },
          {
            label: "操作",
            slot: "handle",
            width: 70,
          },
        ],
      },
      adviceTypeOptions: [
        {
          value: "1",
          label: "长期医嘱",
        },
        {
          value: "2",
          label: "限定医嘱",
        },
      ],
      unitList: [],
      ruleSetList: [],
      setTypeList: [],
      schCountList:[],
      addFlag: true,
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        this.getDetail();
      } else {
        this.adviceList = [];
        this.resetSearch();
      }
    },
  },
  created() {
    this.getDetail();
    this.getBToCares();
    this.get_frequency_unit();
    this.get_sch_count()
    this.get_rule_set_type();
  },
  mounted() {},
  methods: {
    delAdvice(index) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        let adviceListCache = this.adviceList;
        adviceListCache.splice(index, 1);
        this.adviceList = adviceListCache;
      });
    },
    addAdvice() {
      if (this.addFlag) {
        if (this.insertType == 1) {
          this.addFlag = false;
          let oper_typeList = this.$refs.oper_type.getChecked();
          let adviceCache = oper_typeList.map((item, index) => {
            let operOrderList = item.operOrderStr
              ? item.operOrderStr.split("@@").map((item1, index1) => {
                  return {
                    value: item1,
                    label: item1,
                  };
                })
              : [];
            return {
              operId: item.id,
              name: item.name,
              operType: item.operType,
              doctorOrderType: item.doctorOrderType,
              createCount: 1,
              schCount: this.schCountList.length>0?this.schCountList[0].label:5,
              frequencyCount: 1,
              frequencyTime: 1,
              frequencyUnit: "0",
              operOrderList: operOrderList,
              doctorOrder: item.operOrderList ? item.operOrderList[0] : "",
            };
          });
          this.adviceList = this.adviceList.concat(adviceCache);
          this.getDetail();
        } else {
          this.addFlag = false;
          var setListAdvice = [];
          let setListChecked = this.$refs.setList.getChecked();
          for (let i = 0; i < setListChecked.length; i++) {
            setListAdvice = setListAdvice.concat(setListChecked[i].list);
          }
          setTimeout(() => {
            let adviceCache = setListAdvice.map((item, index) => {
              let operOrderList = item.operOrderStr
                ? item.operOrderStr.split("@@").map((item1, index1) => {
                    return {
                      value: item1,
                      label: item1,
                    };
                  })
                : [];
              return {
                operId: item.id,
                name: item.name,
                operType: item.operType,
                doctorOrderType: item.doctorOrderType,
                createCount: 1,
                schCount: this.schCountList.length>0?this.schCountList[0].label:5,
                frequencyCount: 1,
                frequencyTime: 1,
                frequencyUnit: "0",
                operOrderList: operOrderList,
                doctorOrder: item.operOrderList ? item.operOrderList[0] : "",
              };
            });
            this.adviceList = this.adviceList.concat(adviceCache);
            this.getDetail();
          }, 10);
        }
      }
    },
    submit() {
      if (this.adviceList.length > 0) {
        let adviceListCache = this.adviceList.map((item, index) => {
          return {
            operId: item.operId,
            basicInfoId: this.basicInfoId,
            doctorOrderType: item.doctorOrderType,
            createCount: item.createCount,
            schCount: item.schCount,
            frequencyCount: item.frequencyCount,
            frequencyTime: item.frequencyTime,
            frequencyUnit: item.frequencyUnit,
            doctorOrder: item.doctorOrder.toString(),
          };
        });
        addPatientAdviceInfo(adviceListCache).then(({ res }) => {
          this.submitSuccess();
        });
      } else {
        this.$message({
          message: this.insertType == 1 ? "请添加操作项" : "请添加组套",
          type: "warning",
        });
      }
    },
    resetSearch() {
      this.selectType = "";
      this.searchName = "";
    },
    submitSuccess(res) {
      this.$emit("submit");
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
    },
    get_frequency_unit() {
      getDict({ type: "frequency_unit" }).then(({ res }) => {
        this.unitList = res.frequency_unit.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
      });
    },
    get_sch_count() {
      getDict({ type: "sch_count" }).then(({ res }) => {
        this.schCountList = res.sch_count.map((item) => {
            const ele = {
                value: item.value,
                label: item.label,
            };
            return ele;
        });
      });
    },
    get_rule_set_type() {
      getDict({ type: "rule_set_type" }).then(({ res }) => {
        let typeList = [];
        let typeOptionsCache = res.rule_set_type.map((item) => {
          typeList[item.value] = item.label;
          return {
            value: item.value,
            label: item.label,
          };
        });
        typeOptionsCache = [{ value: "", label: "全部" }].concat(
          typeOptionsCache
        );
        this.ruleSetList = typeOptionsCache;
        this.setTypeList = typeList;
      });
    },
    getBToCares() {
      getDict({ type: "oper_type" }).then(({ res }) => {
        let typeList = [];
        let typeOptionsCache = res.oper_type.map((item) => {
          typeList[item.value] = item.label;
          return {
            value: item.value,
            label: item.label,
          };
        });
        typeOptionsCache = [{ value: "", label: "全部" }].concat(
          typeOptionsCache
        );
        this.typeOptions = typeOptionsCache;
        this.typeList = typeList;
      });
    },
    getDetail() {
      if (this.insertType == 1) {
        let param = Object.assign({
          operType: this.selectType,
          name: this.searchName,
          charge: 1,
        });
        getRuleOperationList(param).then(({ res, timeStamp }) => {
          this.addFlag = true;
          this.ruleOperationList = res;
          this.timeStamp = timeStamp;
        });
      } else {
        let param = Object.assign({
          hospitalPrivate: this.selectType,
          name: this.searchName,
          charge: 1,
        });
        getUserSetList(param).then(({ res, timeStamp }) => {
          this.addFlag = true;
          this.ruleOperationList = res;
          this.timeStamp = timeStamp;
        });
      }
    },
    updateVisible(val) {
      this.visible = val;
    },
  },
};
</script>
<style scoped lang='scss'>
.numinput {
  width: 85px;
}
</style>
      
        