/*
 * @Author: your name
 * @Date: 2022-09-08 11:06:31
 * @LastEditTime: 2022-09-08 14:12:23
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\treatmentVerification.js
 */

import request from '@/utils/request'

/**
 * @description: 治疗核对列表
 */
export function getList(data) {
    return request({
        url: 'patient/treatBook/checkList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 治疗核对记录
 */
export function getCheckForm(data) {
    return request({
        url: 'patient/treatBook/checkForm',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 审核
 */
export function updateCheckStatus(data, timeStamp) {
    return request({
        url: 'patient/treatBook/updateCheckStatus',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}
