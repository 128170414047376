<!--
 * @LastEditors: mazihao mazihao@newpay.la
 * @Author: Richardlv
-->

<template>
  <el-container>
    <el-main class="basic-main">
      <el-scrollbar>
        <div class="flex">
          <el-descriptions title="基本信息"
                           size="mini"
                           :column="2">
            <el-descriptions-item label="工作时间">{{ show.workingHours || commonConfig.$nullData }}至{{
                show.rushHour || commonConfig.$nullData
              }}</el-descriptions-item>
            <el-descriptions-item label="午休时间">{{ show.startLunchBreak || commonConfig.$nullData }}至{{
                show.endLunchBreak || commonConfig.$nullData
              }}</el-descriptions-item>
            <el-descriptions-item label="治疗工作间隔	">{{
                show.workInterval || commonConfig.$nullData
              }}分钟</el-descriptions-item>
            <el-descriptions-item label="治疗区	">
              {{
                show.consultingRoomLabel || commonConfig.$nullData
              }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-right: 5px;">
            <el-button type="primary"
                       @click="editTime()">编辑</el-button>
          </div>
          <div>
            <el-button type="default"
                       @click="back()"> 返回 </el-button>
          </div>
        </div>
        <div class="my-title">
          <div class="mdrcc-info-title">工作技能绑定</div>
          <div>
            <el-button class="button-height"
                       type="primary"
                       @click="addSkill()">添加</el-button>
          </div>
        </div>
        <!-- 工作技能绑定表格高度设置为5行 -->
        <generalTable ref="mainTable"
                      :table-data="show.operResList"
                      :config="tableConfig"
                      :total="total"
                      :newHeight="'185px'">
          <template slot="handle"
                    slot-scope="scope">
            <el-button type="text"
                       class="delBtn"
                       @click="toDel(scope.data.row.operId)">删除</el-button>
          </template>
        </generalTable>
        <div class="my-title">
          <div class="mdrcc-info-title">治疗设备绑定</div>
          <div>
            <el-button class="button-height"
                       type="primary"
                       @click="addDevices()">添加</el-button>
          </div>
        </div>
        <generalTable ref="mainTable"
                      :table-data="show.armariumResList"
                      :config="deviceTableConfig"
                      :total="deviceTotal"
                      :newHeight="'185px'">
          <template slot="handle"
                    slot-scope="scope">
            <!-- TODO: 删除设备绑定接口尚未完成 -->
            <el-button type="text"
                       class="delBtn"
                       @click="toDelDevice(scope.data.row.id)">删除</el-button>
          </template>
        </generalTable>
      </el-scrollbar>
    </el-main>
    <editTimeDialog @submit="submit"
                    :data="show"
                    :timeStamp="timeStamp"
                    ref="editTime" />
    <addSkillDialog @submit="submit"
                    :timeStamp="timeStamp"
                    ref="addSkill" />
    <addDeviceDialog @submit="submit"
                     :timeStamp="timeStamp"
                     ref="addDevice" />
  </el-container>
</template>
<script>
import addSkillDialog from "@/views/other/personalSetting/addSkillDialog";
import addDeviceDialog from "@/views/other/personalSetting/addDeviceDialog";
import editTimeDialog from "@/views/other/personalSetting/editTimeDialog";
import {
  getPersonalInfo,
  delUserOperRel,
  removeUserArmariumRel,
} from "@/api/personalSetting";
import generalTable from "@/components/Table";
import { getDict } from "@/api/common";

export default {
  name: "personalSetting",
  components: {
    addSkillDialog,
    addDeviceDialog,
    editTimeDialog,
    generalTable,
  },
  data() {
    return {
      show: {
        workingHours: "",
        rushHour: "",
        startLunchBreak: "",
        endLunchBreak: "",
        workInterval: "",
        consultingRoom: "",
        operResList: [],
        // 设备列表数据
        armariumResList: [],
      },
      timeStamp: "", // 时间戳
      tableConfig: {
        id: "operResList",
        align: "center", // 不必填 默认为center
        index: true, // 不必填 序号
        column: [
          {
            label: "项目类型", // 必填
            prop: "careLabel",
          },
          {
            label: "操作项名称", // 必填
            prop: "operName", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "150", // 不必填
          },
        ],
      },
      // 设备列表表格配置
      deviceTableConfig: {
        id: "armariumResList",
        align: "center", // 不必填 默认为center
        index: true, // 不必填 序号
        column: [
          {
            label: "设备名称", // 必填
            prop: "deviceName",
          },
          {
            label: "设备编号", // 必填
            prop: "deviceNumber", // 必填
          },
          {
            label: "状态", // 必填
            prop: "statusLabel", // 必填
            width: "150",
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "150", // 不必填
          },
        ],
      },
      total: 0,
      // 设备表格翻页数据
      deviceTotal: 0,
    };
  },
  created() {},
  mounted() {
    this.get_consulting_room();
    this.getPersonalInfo();
  },
  methods: {
    getPersonalInfo() {
      getPersonalInfo().then(({ res, timeStamp }) => {
        this.timeStamp = timeStamp;
        this.show = { ...res };
        for (let i in this.consultingArr) {
          if (this.consultingArr[i].value == this.show.consultingRoom) {
            this.show.consultingRoomLabel = this.consultingArr[i].label;
          }
        }
      });
    },
    //获取治疗区名称字典项
    get_consulting_room() {
      getDict({ type: "consulting_room" }).then(({ res }) => {
        let typeOptionsCache = res.consulting_room.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
        this.consultingArr = typeOptionsCache;
      });
    },
    editTime() {
      this.$refs.editTime.updateVisible(true);
    },
    back() {
      this.$router.back();
    },
    submit() {
      this.getPersonalInfo();
    },
    addSkill() {
      this.$refs.addSkill.updateVisible(true);
    },
    /** 添加绑定设备
     * @description: 显示添加设备弹窗
     * @param {*}
     * @return {*}
     */

    addDevices() {
      this.$refs.addDevice.updateVisible(true);
    },
    toDel(id) {
      delUserOperRel({ id: id }).then(({ res }) => {
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getPersonalInfo();
      });
    },
    /** 删除绑定设备
     * @description: 删除前进行提示，删除后重新获取用户信息
     * @param {String} id 要删除的设备id
     * @return {*}
     */

    toDelDevice(id) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        removeUserArmariumRel({ armariumId: [id] }).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
          this.getPersonalInfo();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.my-title {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.flex {
  display: flex;
}
</style>
