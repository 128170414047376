/**
 * 页面: 数据接口日志
 * pageName: webserviceLog
 **/
import request from "@/utils/request";

/**
 * @description: 获取数据接口日志列表数据
 */
export function getList(data) {
  return request({
    url: "/webserviceLog/list",
    method: "GET",
    params: data,
    showLoading: true,
  });
}

/**
 * @description: 获取数据接口日志详情数据
 */
export function getDetail(data) {
  return request({
    url: "/webserviceLog/info",
    method: "GET",
    params: data,
    showLoading: true,
  });
}
