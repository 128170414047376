var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"table-btn-group"},[_c('div',{staticClass:"questionnaires",attrs:{"id":_vm.id}},[(!_vm.scaleId)?_c('span',{staticClass:"basic-info"},_vm._l((_vm.basicInfoList),function(basicInfoItem){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],key:basicInfoItem.id},[_vm._v(_vm._s(basicInfoItem))])}),0):_vm._e(),_c('div',{staticClass:"scale-title"},[_vm._v(_vm._s(_vm.scaleName))]),_c('el-divider'),_vm._l((_vm.queList),function(queItem,queIndex){return _c('div',{key:queItem.id},[_c('div',{staticClass:"question",attrs:{"data-type":queItem.answerType,"id":queItem.id}},[_c('div',{staticClass:"question-title",class:queItem.validRule && queItem.validRule.required ? 'required' : ''},[_vm._v(" "+_vm._s(queItem.name)+" ")]),(queItem.imageData)?_c('div',{staticClass:"question-img"},[_c('el-image',{attrs:{"src":queItem.imageData}})],1):_vm._e(),(['0', '1'].includes(queItem.answerType))?_vm._l((queItem.optionList),function(optionItem){return _c('div',{key:optionItem.id,staticClass:"answer-check",class:[
                            _vm.checkMap[queItem.answerType],
                            queItem.selectOpts.includes(optionItem.id) ? 'active' : ''
                        ],style:({
                            cursor:
                                _vm.scaleType === '2' && !queItem.selectOpts.includes(optionItem.id)
                                    ? 'pointer'
                                    : 'default'
                        }),attrs:{"data-question":queItem.id,"data-mark":optionItem.mark,"data-id":optionItem.id},on:{"click":function($event){_vm.scaleType === '2' && _vm.select(queIndex, optionItem, $event)}}},[_vm._v(" "+_vm._s(optionItem.name)+" ")])}):_vm._e(),(['2'].includes(queItem.answerType))?[_c('el-input',{attrs:{"id":queItem.answerCoordinate,"data-question":queItem.id,"type":"textarea","rows":"4","resize":"none","placeholder":"请输入...","readonly":(queItem.validRule && queItem.validRule.readonly) || _vm.scaleType !== '2'},on:{"change":function($event){return _vm.inputChange(queItem, $event)}},nativeOn:{"keyup":function($event){return _vm.inputKeyup(queItem, $event)}},model:{value:(queItem.answerContent),callback:function ($$v) {_vm.$set(queItem, "answerContent", $$v)},expression:"queItem.answerContent"}})]:_vm._e(),(['3', '4'].includes(queItem.answerType))?[_c('el-input',{attrs:{"id":queItem.answerCoordinate,"data-question":queItem.id,"placeholder":"请输入...","readonly":(queItem.validRule && queItem.validRule.readonly) || _vm.scaleType !== '2'},on:{"change":function($event){return _vm.inputChange(queItem, $event)}},nativeOn:{"keyup":function($event){return _vm.inputKeyup(queItem, $event)}},model:{value:(queItem.answerContent),callback:function ($$v) {_vm.$set(queItem, "answerContent", $$v)},expression:"queItem.answerContent"}})]:_vm._e()],2)])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }