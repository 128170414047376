<!--
 * @LastEditors: Richardlv
 * @Author: Richardlv
-->

<template>
  <div>
    <el-dialog
      width="70%"
      :visible.sync="addUserFlag"
      title="分配用户"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @close="closeDetailDialog()"
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="8">角色:{{ detailData.name }}</el-col>
        <el-col
          :span="8"
        >权限类型:{{
          permissionTypeList[detailData.permissionType]
        }}</el-col>
      </el-row>
      <el-button
        type="primary"
        style="margin-bottom: 10px"
        size="small"
        @click="addUser()"
      >新增</el-button>
      <generalTable
        ref="roleUserTable"
        :table-data="roleUserData"
        :config="roleUserConfig"
        :total="roleUserTotalNum"
        @updatePage="getRoleUser()"
      >
        <template slot-scope="scope" slot="handle">
          <el-popconfirm
            confirm-button-text="确认"
            cancel-button-text="取消"
            icon="el-icon-info"
            icon-color="red"
            title="请确认是否删除此条数据？"
            @confirm="toDelRoleUser(scope.data.row.id)"
          >
            <el-button
              slot="reference"
              type="text"
              size="mini"
              class="delBtn"
              plain
            >删除
            </el-button>
          </el-popconfirm>
        </template>
      </generalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeAddUserFlag">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      top="10vh"
      width="70%"
      :visible.sync="userFlag"
      title="选择用户"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @close="closeUserDialog()"
    >
      <searchForm ref="mainSearch" :searchoptions="searchOptions" :back-status="false" @search="getUserData(1)" />
      <el-table
        ref="multipleTable"
        :data="userdata"
        border
        row-key="id"
        highlight-current-row
        height="400"
        @select="selectUser"
        @select-all="selectUser"
      >
        <el-table-column type="selection" width="50" />
        <el-table-column
          prop="trueName"
          label="姓名"
          width="150"
          align="center"
        />
        <el-table-column
          prop="workNumber"
          label="工号"
          align="center"
        />
        <el-table-column
          prop="username"
          label="账号"
          width="200"
          align="center"
        />
        <el-table-column
          prop="position"
          label="岗位"
          width="200"
          align="center"
        />
      </el-table>
      <el-footer class="paginationBox" height="60px">
        <el-pagination
          :current-page="pageNo"
          :page-sizes="commonConfig.$pageSizes"
          :page-size="pageSize"
          background
          layout="total, sizes, prev, pager, next"
          :total="totalNum"
          @size-change="setPageSize"
          @current-change="setPageNo"
        />
      </el-footer>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmUser()" >确 定</el-button>
        <el-button @click="userFlag = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getUserList, getUserByRole } from '@/api/user'
import { getDict } from '@/api/common'
import generalTable from '@/components/Table'
import searchForm from '@/components/SearchForm'
import {
    addUserToRole,
    disassociate
} from '@/api/character'
export default {
	components: {
		generalTable,
		searchForm
    },
    props: {
        addUserFlag: Boolean,
        detailData: Object
    },
    data() {
        return {
            permissionTypeList: ['', '功能权限', '数据权限'],
            roleUserTotalNum: 0, // 总条数
            roleUserData: [],
            userFlag: false,
            userdata: [],
            allPageUser: [],
            userSearchForm: {
                workNumber: '',
                name: '',
                doctorPosition: ''
            },
            doctorPositionOption: [],
            pageNo: 1, // 页码
            pageSize: 20, // 显示数量
            totalNum: 0, // 总条数
			roleUserConfig: {
                id: 'roleUser',
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '姓名', // 必填
                        prop: 'trueName' // 不必填 单列插槽
                    },
					{
                        label: '工号', // 必填
                        prop: 'workNumber'
                    },
                    {
                        label: '账号', // 必填
                        prop: 'username'
                    },
					{
                        label: '手机号', // 必填
                        prop: 'phone'
                    },
					{
                        label: '岗位', // 必填
                        prop: 'position'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
			searchOptions: [
                {
                    type: 'input',
                    key: 'name',
                    label: '用户名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    key: 'workNumber',
                    label: '工号',
                    defaultVal: ''
                }
            ]
        }
    },
    watch: {
        detailData(oldValue, newValue) {
            if (this.detailData.id.length > 0 && this.addUserFlag) {
                this.getRoleUser()
            }
        }
    },
    mounted() {},
    created() {
        this.getDoctorPosition()
    },
    methods: {
        toDelRoleUser(userId) { // 删除角色用户
            const parm = {
                roleId: this.detailData.id,
                userId: userId
            }
            disassociate(parm).then(({ res }) => {
                this.$message({...this.commonConfig.$handleSuccess})
                this.getRoleUser()
            })
        },
        closeDetailDialog() {
            this.roleUserData = []
        },
        addUser() {
            this.userFlag = true
            this.getUserData()
        },
        setUser() {
            this.$nextTick(() => {
                let userIdCrash = []
                userIdCrash = this.allPageUser[this.roleUserPageNo]
                if (userIdCrash && userIdCrash.length > 0) {
                    this.userdata.map((item, index) => {
                        if (userIdCrash.indexOf(item.id) > -1) {
                            this.$refs.multipleTable.toggleRowSelection(
                                this.userdata[index],
                                true
                            )
                        }
                    })
                }
            })
        },
        changeAddUserFlag() {
            this.$emit('changeAddUserFlag')
        },
        getRoleUser() {
            this.$nextTick(() => {
                const page = this.$refs.roleUserTable?this.$refs.roleUserTable.getPage():{pageNo:1,pageSize:20}
                const param = Object.assign(page, {id: this.detailData.id})
                getUserByRole(param).then(({ res }) => {
                    const { total, records } = res
                    this.roleUserData = records
                    this.roleUserTotalNum = parseInt(total)
                })
            })
        },
        selectUser(selection) { // 设置选中的用户
            const pageUserCrash = this.allPageUser
            const nowPageUserId = selection.map((item, index) => {
                return item.id
            })
            pageUserCrash[this.pageNo] = nowPageUserId
            this.allPageUser = pageUserCrash
        },
        setPageSize(val) {
            this.pageSize = val
            this.getUserData()
        },
        setPageNo(val) {
            this.pageNo = val
            this.getUserData()
        },
        getUserData(val) { // 获取用户数据 不显示notShowRoleId中的用户
            if(val){
                this.pageNo = val
            }
            const paramObj = this.$refs.mainSearch?this.$refs.mainSearch.packageData():this.userSearchForm;
            const param = {
                status: 0,
                current: this.pageNo,
                size: this.pageSize,
                notShowRoleId: this.detailData.id,
                name: paramObj.name,
                doctorPosition: paramObj.doctorPosition,
                workNumber: paramObj.workNumber
            }
            getUserList(param).then(({ res }) => {
                const { total, records } = res
                this.userdata = records
                this.totalNum = parseInt(total)
                setTimeout(() => {
                    this.setUser()
                }, 10)
            })
        },
        getDoctorPosition() { // 获取岗位列表字典
            getDict({ type: 'position' }).then(({ res }) => {
                this.doctorPositionOption = res.position
				this.getRoleUser()
            })
        },
        closeUserDialog() {
            this.userdata = []
            this.allPageUser = []
            this.userSearchForm = {
                workNumber: '',
                name: '',
                doctorPosition: ''
            }
            this.pageNo = 1
            this.pageSize = 20
        },
        confirmUser() { // 提交添加用户 relIds:用户ID id:角色ID
            let allUserId = []
            this.allPageUser.map((item, index) => {
                if (item && item.length > 0) {
                    allUserId = allUserId.concat(item)
                }
            })
            if (allUserId.length > 0) {
                const parm = {
                    relIds: allUserId,
                    id: this.detailData.id
                }
                addUserToRole(parm).then(({ res }) => {
                    this.$message({...this.commonConfig.$handleSuccess})
                    this.userFlag = false
                    this.getRoleUser()
                })
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择用户',
                    type: 'warn'
                })
            }
        }
    }
}
</script>
