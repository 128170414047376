<!--
 * @Author: your name
 * @Date: 2021-12-24 10:02:41
 * @LastEditTime: 2021-12-30 10:56:05
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\treat.vue
-->

<template>
    <el-main>
        <el-row v-if="!data.length">{{message}}</el-row>
        <el-row v-for="item in data" :key="item.id" class="card-row">
            <el-row class="row-arrow">
                <el-col :span="16">
                    <div>{{item.operName}}*{{item.schCount}}</div>
                </el-col>
                <el-col :span="5">
                    <div>{{item.device || ""}}</div>
                    <div>{{item.therapyName || commonConfig.$nullData}}</div>
                    <div></div>
                </el-col>
                <el-col :span="3">
                    <div class="arrow">
                        <i class="el-icon-arrow-right more" @click="showDetail(item.id)"></i>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="21">
                    <div class="date">{{item.treatmentDate ?moment(item.treatmentDate).format("YYYY-MM-DD HH:mm") : commonConfig.$nullData}}</div>
                    <div></div>
                </el-col>
                <el-col :span="3">
                    <div>
                    </div>
                </el-col>
            </el-row>
        </el-row>
        <detailDialog ref="treatDetail" :id="checkedId" @close="openPat360()"/>
    </el-main>
</template>

<script>
import detailDialog from '@/views/other/Patient360/components/treatDetail'
export default {
    name: 'treatPat360',
    components: {
        detailDialog
    },
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Array
        }
    },
    data(){
        return {
            checkedId: "",
            message: "暂无数据"
        }
    },
    methods: {
        /** 治疗详情
         * @description: 更新checkedId,关闭当前弹窗,显示治疗详情弹窗
         * @param {String} id 治疗id
         * @return {*}
         */        
        showDetail(id) {
            this.checkedId = id
            this.$emit("close")
            this.$refs.treatDetail.updateVisible(true)
        },
        /** 打开患者360弹窗
         * @description: 触发父级`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        openPat360(){
            this.$emit("close")
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
// 空数据卡片
.card-empty {
    background-color: $dialogHeader;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
}
// 有数据卡片
.card-row {
    background-color: $dialogHeader;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
    &::after {
        content: "已治疗";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $tableColor;
        color: $noticeDate;
        font-size: 14px;
        padding: 5px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
// 更多部分
.more {
    cursor: pointer;
    &:hover {
        color: $arrowActive;
    }
}
.row-arrow {
    display: flex;
    align-items: center;
    .arrow {
        text-align: right;
    }
    div:not(.arrow):not(:last-of-type) {
        margin-bottom: 5px;
    }
}
.date {
    color: $noticeDate;
    font-size: 14px;
    margin-top: 5px;
}
</style>