
/**
 * 页面: 医嘱统计
 * pageName: orderStatistics
 **/
import request from '@/utils/request'

/**
 * @description: 获取医嘱统计列表数据
 */
export function getList(data) {
    return request({
        url: '/statistics/patientAdvice/advice',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
