
<template>
    <el-dialog width="60%" title="康复质控详情" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="康复质控信息" :column="3">
            <el-descriptions-item label="指标名称">{{ commonConfig.$checkNullData(detail.name) }}</el-descriptions-item>
            <el-descriptions-item label="推送类型">{{ commonConfig.$checkNullData(pushTypeMap[detail.pushType]) }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ commonConfig.$checkNullData(stateMap[detail.state]) }}</el-descriptions-item>
            <el-descriptions-item label="描述">{{
                commonConfig.$checkNullData(detail.description)
            }}</el-descriptions-item>
            <el-descriptions-item label="推送内容">{{ commonConfig.$checkNullData(detail.content) }}</el-descriptions-item>
            <el-descriptions-item label="备注">{{ commonConfig.$checkNullData(detail.remark) }}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from '@/api/recoveryManagement'
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            pushTypeMap: {
                '0': '消息提醒且任务推送',
                '1': '消息提醒',
                '2': '任务推送'
            },
            stateMap: {
                '0': '启用',
                '1': '禁用'
            },
            detail: {}, // 详情数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail()
        }
    },
    created() { },
    mounted() {
    },
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res
            })
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang='scss'>

</style>
