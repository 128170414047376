/*
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
 */
/**
 * 页面: 公共量表库
 * pageName: publicScaleLib
 **/
import request from '@/utils/request'

/**
 * @description: 获取公共量表库列表数据
 */
export function getList(data) {
    return request({
        url: 'basicScaleInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增公共量表库数据
 */
export function insertData(data) {
    return request({
        url: '/basicScaleInfo/add',
        method: 'POST',
        data,
        timeout: 100 * 1000,
        showLoading: true
    })
}

/**
 * @description: 修改公共量表库数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/basicScaleInfo/update',
        method: 'POST',
        data,
        timeout: 100 * 1000,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取公共量表库详情数据
 */
export function getDetail(data) {
    return request({
        url: '/basicScaleInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取量表问题分类
 */
export function getScaleType(data) {
    return request({
        url: '/basicScaleQuestionType/tree',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增量表问题分类
 */
export function addScaleType(data) {
    return request({
        url: '/basicScaleQuestionType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 编辑量表问题分类
 */
export function updateScaleType(data, timeStamp) {
    return request({
        url: '/basicScaleQuestionType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除量表问题分类
 */
export function delScaleType(data, timeStamp) {
    return request({
        url: '/basicScaleQuestionType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表库量表问题列表
 */
export function getBasicScaleQuestion(data, timeStamp) {
    return request({
        url: '/basicScaleQuestion/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 添加量表库量表问题表
 */
export function addBasicScaleQuestion(data) {
    return request({
        url: '/basicScaleQuestion/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 编辑量表库量表问题表
 */
export function updateBasicScaleQuestion(data, timeStamp) {
    return request({
        url: '/basicScaleQuestion/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除量表问题分类
 */
export function delBasicScaleQuestion(data, timeStamp) {
    return request({
        url: '/basicScaleQuestion/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表库量表问题详情
 */
export function getBasicScaleQuestionInfo(data) {
    return request({
        url: '/basicScaleQuestion/info',
        method: 'GET',
        params: data
    })
}

/**
 * @description: 下载量表模板文件类型
 */
export function getBlobFileType(data) {
    return request({
        url: '/file/getBlobFileType',
        method: 'GET',
        params: data
    })
}

/**
 * @description: 下载量表模板文件
 */
export function downloadScaleFile(data) {
    return request({
        url: '/file/downloadBlobFile',
        method: 'GET',
        responseType: 'blob',
        params: data
    })
}

/**
 * @description: 删除公共量表库数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: 'basicScaleInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 复制量表库量表
 */
export function copyScale(data) {
    return request({
        url: 'basicScaleInfo/copy',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 下发量表库量表
 */
export function issuedScale(data, timeStamp) {
    return request({
        url: 'basicScaleInfo/issued',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 提交量表规则
 */
export function submitResults(data, timeStamp) {
    return request({
        url: '/basicScaleInfo/addBasicScaleConclusion',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表规则
 */
export function getScaleRule(data) {
    return request({
        url: '/basicScaleInfo/getScaleRule',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取量表html
 */
export function getMouldCode(data) {
    return request({
        url: '/basicScaleInfo/getMouldCode',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 评估id获取量表html
 */
export function getMouldCodeByScalePatientId(data) {
    return request({
        url: '/scaleInfo/getMouldCodeByScalePatientId',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 评估id获取显示模板html
 */
export function getViewMouldCodeByScalePatientId(data) {
    return request({
        url: '/scaleInfo/getViewMouldCodeByScalePatientId',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取量表题目
 */
export function getAnswerQuestions(data) {
    return request({
        url: '/scaleQuestion/getAnswerQuestions',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 评估id获取量表题目
 */
export function getScaleInfoQuestionByPatientScaleId(data) {
    return request({
        url: '/scalePatient/getPatientAnswer',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 评估id获取量表题目
 */
export function lastTimePatientAnswer(data) {
    return request({
        url: '/scalePatient/lastTimePatientAnswer',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
