<!--
* @Description: 住院患者页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
        </el-header>
        <el-main class="basic-main">
            <div style="margin:0 -15px;background-color:#f6f6f6;padding:7.5px 5px 0 0px">
                <el-row style="margin-bottom:15px;height:28px" class="table-btn-group">
                    <el-button v-if="inhosAdd" type="primary" @click="toInsert()">新增</el-button>
                    <el-button type="primary" @click="toEMR()">电子病历</el-button>
                    <span style="float: right;font-size:12px">今日入院（{{ count|| 0 }}）</span>
                </el-row>
                <div ref="mainTable" :total="total">
                    <div class="menu-item">
                        <el-row
                            ref="mainTable"
                            :column="4"
                            :gutter="10"
                            :table-data="tableData"
                            :total="total"
                            @updatePage="getList"
                        >
                            <el-col v-for="item in tableData" :key="item.id" :sm="12" :md="8" :lg="6" :xl="4">
                                <el-card class="box-card" shadow="hover">
                                    <div
                                        style="position: relative"
                                        @click="
                                            toDetail({ id: item.id, archivesInfoId: item.archivesInfoId, fromFlag: 1 })
                                        "
                                    >
                                        <div style="display: flex; margin-bottom: 10px">
                                            <div class="bedNumBox">{{ item.bedNum }}床</div>
                                            <div style="flex: 1; padding-left: 10px">
                                                <div class="name-style patient-name">{{ item.name }}</div>
                                                <div style="display: flex">
                                                    <div class="patient-info" style="margin-right: 10px">
                                                        {{ commonConfig.$sexList[item.sex] }}
                                                    </div>
                                                    <div class="patient-info" style="margin-right: 10px">
                                                        {{ item.age || 0 }}岁
                                                    </div>
                                                    <div>{{ item.deptName }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="textBox patient-info">
                                            管床医生：{{ item.bedDocName || commonConfig.$nullData }}
                                        </div>
                                        <div class="textBox patient-info">
                                            住院号：{{ item.recordNum || commonConfig.$nullData }}
                                        </div>
                                        <div class="textBox patient-info">
                                            就诊号：{{ item.serialNum || commonConfig.$nullData }}
                                        </div>
                                        <div class="textBox patient-info">
                                            入院日期： {{ moment(item.heDte).format('YYYY-MM-DD') }}
                                        </div>
                                        <div v-if="item.isOnDay === '1'" class="newBox">今日</div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <el-pagination
                background
                :current-page="pageNo"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total"
                style="float: right; margin-top: 7.5px; padding-right: 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
            <!-- <detailDialog :id="checkId" ref="detail" :details="item" /> -->
            <addDialog ref="insert" @submit="getList" />
        </el-main>
    </el-container>
</template>

<script>
import { getList } from '@/api/inHospital'
import { getDept, getCount } from '@/api/patient'
import searchForm from '@/components/SearchForm'
import addDialog from '@/views/patient/patient/addDialog'
import { getRefreshFlag } from '@/utils/common'

export default {
    name: 'InHospital',
    components: {
        searchForm,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'sex',
                    filterable: true,
                    label: '性别',
                    options: this.commonConfig.$sexOptions,
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'recordNum',
                    label: '住院号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'serialNum',
                    label: '就诊号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'impDocName',
                    label: '主治医生',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'bedNum',
                    label: '床号',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'heDte',
                    label: '入院日期',
                    defaultVal: []
                }
            ],
            paramObj: null, // 筛选项
            total: 0, // 总条数
            pageNo: 1,
            pageSize: 10,
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            item: {},
            timeStamp: '', // 时间戳 用于修改数据或删除数据
            deptOptions: [], // 所属科室
            count: '0' // 人数
        }
    },
    created() {
        this.inhosAdd = this.hasPermission('inhos-add')
        this.getList()
        this.getOptions()
        this.num()
    },
    watch: {
        $route(to, from) {
            // 对路由变化作出响应...
            if (getRefreshFlag()) {
                this.getList()
            }
        }
    },
    mounted: function() {},
    methods: {
        /**
         * @description:获取今日入院人数
         * @return {*}
         */
        num() {
            const param = { status: '1' }
            getCount(param).then(({ res, timeStamp }) => {
                this.count = res
            })
        },
        // 页面条数
        handleSizeChange(val) {
            this.pageSize = val
            this.pageNo = 1
            this.getList()
        },
        // 页码
        handleCurrentChange(val) {
            this.pageNo = val
            this.getList()
        },
        search() {
            this.pageNo = 1
            this.getList()
        },
        /**
         * @description:列表
         * @param {*}
         * @return {*}
         */
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description:获取所属科室
         * @param {*}deptType 部门类型 4=>科室
         * @return {*}
         */
        getOptions() {
            getDept({ deptType: '4' }).then(({ res, timeStamp }) => {
                res.forEach(element => {
                    const ele = {
                        value: element.id,
                        label: element.label
                    }
                    this.deptOptions.push(ele)
                })
                this.searchOptions[5].options = this.deptOptions
            })
        },
        /**
         * @description:查找
         * @param {*}
         * @return {*}
         */
        getListSearch() {
            const param = this.paramObj
            getList(param).then(({ res, timeStamp }) => {
                this.tableData = res.records
                this.total = parseInt(res.total)
                this.timeStamp = timeStamp
            })
        },
        /**
         * @description:获取参数
         * @param {*}
         * @return {*}
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const param = Object.assign({ status: '1', current: this.pageNo, size: this.pageSize }, paramObj)
            return param
        },
        /**
         * @description:新增
         * @param {*}
         * @return {*}
         */
        toInsert() {
            this.$refs.insert.getMessageByDetail(true, '', true, true)
        },

        /**
         * @description: 电子病历
         * @param {*}
         * @return {*}
         */
        toEMR() {
            this.$router.push({ path: '/patient/inHospital/emr' })
        },
        /**
         * @description:查询详情
         * @param {*} query
         * @return {*}
         */
        toDetail(query) {
            this.$router.push({ path: '/patient/inHospital/detail', query: query })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.basic-main {
    padding-top: 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.el-col {
    border-radius: 4px;
}
.bg-purple-dark {
    background: #99a9bf;
}
.bg-purple {
    background: #d3dce6;
}
.bg-purple-light {
    background: #e5e9f2;
}
.grid-content {
    border-radius: 4px;
    min-height: 36px;
}
.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
.textBox {
    margin-bottom: 10px;
}
.name-style {
    font-size: 18px;
}
.newBox {
    font-size: 12px;
    background-color: $theme;
    color: #ffffff;
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    right: -15px;
}
.box-card {
    margin-bottom: 10px;
}
.bedNumBox {
    border-radius: 5px;
    background: #80b9f6;
    width: 70px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
}
.patient-info {
    color: $text;
    font-size: 16px;
}
.patient-name {
    color: $titleColor;
    font-weight: 600;
}
</style>
