<!--
 * @Author: your name
 * @Date: 2022-11-15 11:03:01
 * @LastEditTime: 2022-12-09 09:57:30
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\tags\addDialog.vue
-->
<template>
    <el-dialog
        width="80%"
        :title="id === '' || id === undefined ? '新增标签' : '修改标签'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <el-form
            style="height: 600px;"
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">标签信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="标签名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="30" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-select
                            v-model="form.status"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="option in statusOpt"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="说明" prop="remark">
                        <el-input type="textarea" v-model="form.remark" rows="4" maxlength="50"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {updateLabel, insertLabel, labelInfo} from "@/api/caseReport"
export default {
    name: 'AddDialog',
    components: {},
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            total: 0, // 总条数
            goals: '',
            diseaseDiagnosis: '',
            rules: {
                name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
                status: [{ required: true, message: '请选择标签状态' }]
            },
            dept: [],
            impDoc: [],
            form: {
                name: '',
                status: '0',
                remark: ''
            },
            visible: false,
            statusOpt: [
                {
                    value: '0',
                    label: '启用'
                },
                {
                    value: '1',
                    label: '禁用'
                }
            ],
            timeStamp: ''
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getLabelInfo(){
            labelInfo({id: this.id}).then(({res, timeStamp}) => {
                console.log("------label info", res, timeStamp)
                this.timeStamp = timeStamp
                const {name, status, remark} = res
                this.form = {name, status, remark}
            })
        },
        submit() {
            //0 疾病诊断  1 阶段目标
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    const param = this.id ?
                        {...this.form, id: this.id} : 
                        this.form
                    console.log("------param", param)
                    if (this.id) {
                        updateLabel({...param, id:this.id}, this.timeStamp).then(res => {
                            this.submitSuccess(res)
                        })
                    } else {
                        insertLabel(param).then(res => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            console.log("visible", val, this.id)
            if (!val) {
                this.$refs.insertForm.resetFields()
            }
            if (val && this.id) {
                this.getLabelInfo()
            }
            this.visible = val
        },
    }
}
</script>
<style scoped></style>
