/*
 * @Author: your name
 * @Date: 2022-09-06 08:55:40
 * @LastEditTime: 2022-09-07 13:59:55
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\treatmentWorkbook.js
 */

import request from '@/utils/request'

/**
 * @description: 获取治疗工作簿列表
 */
export function getList(data) {
    return request({
        url: 'patient/treatBook/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取治疗工作簿详情
 */
export function getData(data) {
    return request({
        url: 'patient/treatBook/toSeeView',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 登记/变更治疗
 */
export function editData(data, timeStamp) {
    return request({
        url: 'patient/treatBook/markAdvice',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 治疗变更记录
 */
export function getChargeView(data) {
    return request({
        url: 'patient/treatBook/chargeView',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
