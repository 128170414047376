<!--
 * @Author: your name
 * @Date: 2022-04-20 16:11:43
 * @LastEditTime: 2022-04-22 13:38:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\treatment\treatmentWithout\allRegisterDialog.vue
-->
<template>
    <el-dialog
        width="90%"
        :title="'一键登记'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <div class="mdrcc-info-title">登记信息</div>
        <div>已选择:{{checkIds.length}}/{{this.tableData.length}}</div>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :checkIds="tableChecked" @updateSelectedIds="updateSelectedIds" >
            <template slot="ruleOperList" slot-scope="scope">
                <el-select
                    v-model="scope.data.row.advice.id"
                    :placeholder="'请选择...'"
                    :filterable="true"
                    @change="changeOperationId($event, scope.data.row)"
                    @clear="clearOperationId($event, scope.data.row)"
                >
                    <el-option
                        v-for="(option, index) in scope.data.row.ruleOperList"
                        :key="index"
                        :label="option.name"
                        :value="option.id"
                    />
                </el-select>
            </template>
            <template slot="schCount" slot-scope="scope">
                <div @click.stop>
                    <el-input-number v-model="scope.data.row.advice.schCount" clearable :max="99" :min="1" />
                </div>
            </template>
            <template slot="deviceList" slot-scope="scope">
                <el-select
                    v-model="scope.data.row.device.id"
                    :placeholder="'请选择...'"
                    :filterable="true"
                    clearable
                    @change="(val) => {changeDeviceSelect(scope, val)}"
                    @clear="clearDevice($event, scope.data.row)"
                >
                    <el-option
                        v-for="(option, index) in ruleArmariumResList"
                        :key="index"
                        :label="option.deviceName + option.deviceNumber"
                        :value="option.id"
                    />
                </el-select>
            </template>
            <template slot="userConsultingRoom" slot-scope="scope">
                <el-select
                    v-model="scope.data.row.userConsultingRoom"
                    :placeholder="'请选择...'"
                    :filterable="true"
                >
                    <el-option
                        v-for="(option, index) in userConsultingRoom"
                        :key="index"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>
            </template>
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getAllTreatmentList } from '@/api/arrangeTreatment'
import { treatmentWithoutAdvice } from'@/api/treatmentWithout'
import { getUserDevices } from '@/api/common'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'

export default {
    components: {
        generalTable
    },
    data() {
        return {
            checkId: '',
            tableData: [],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                saveSelected: true,
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'patientName', // 必填
                        width: 70
                    },
                    {
                        label: '计划时间', // 必填
                        prop: 'scheduleTime', // 必填
                        width: 90,
                        formatter: row => {
                            return row.scheduleTime + '-' + row.scheduleTimeEnd
                        }
                    },
                    {
                        label: '治疗项目/设备', // 必填
                        prop: 'operName', // 必填
                        formatter: row => {
                            return row.operName || row.deviceName
                        },
                    },
                    {
                        label: '治疗项目', // 必填
                        slot: 'ruleOperList', // 必填
                    },
                    {
                        label: '单次数量', // 必填
                        slot: 'schCount', // 必填
                        width: '150'
                    },
                    {
                        label: '使用设备',
                        slot: 'deviceList',
                    },
                    {
                        label: '治疗区',
                        slot: 'userConsultingRoom',
                        width: '130'
                    }
                ]
            },
            tableChecked: [], //表格默认选中项
            visible: false,
            ruleArmariumResList: [], //用户绑定的治疗设备列表
            failedData: '', // 医嘱与设备匹配不上时返回的医嘱id
            checkIds:[],
            userConsultingRoom: [], // 治疗诊室列表
            userConsultingRoom : '',
            //ruleOperList:[] //治疗项目列表
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getUserDevices()
                this.getAllTreatmentList()
                this.clearFailed()
                this.getDict()
            }
        },
        failedData(newVal, oldVal) {
            if (!newVal) {
                return
            }
            const orgSelect = this.$refs.mainTable.getChecked()
            const ableList = orgSelect.filter(item => item.advice.id !== newVal)
            this.updateTableData(newVal)
            this.tableChecked = ableList.map(item => item.id)
        }
    },
    created() {
    },
    mounted() {},
    methods: {
        /** 修改设备选中项
         * @description: 治疗区默认为用户治疗区，修改设备选中项时，将治疗区修改成设备所在治疗区
         * @param {Number} val Number
         * @return {*}
         */        
        changeDeviceSelect(scope, val){
            const currentSelect = this.ruleArmariumResList.filter(item => item.id === val)
            if (currentSelect.length) {
                this.tableData[scope.data.$index].userConsultingRoom = currentSelect[0].consultingRoom
            } else {
                this.getAllTreatmentList()
            }
            
        },
       //获取治疗区字典项
        getDict(){
            const roomLabel = "consulting_room"
            this.getDictByType(roomLabel, (roomList) => {
                this.userConsultingRoom = [...roomList]
            })
        },
        /** 按类型获取字典项
         * @description: 字典项获取成功后，进入回调函数进行后续操作
         * @param {String} type 要获取的字典项名称
         * @param {Function} callback 回调函数，其中从后台获取的数据作为回调函数的参数传入
         * @return {*}
         */        
        getDictByType(type, callback){
            getDict({type}).then(({res}) => {
                if (callback && typeof callback === "function") {
                    callback(res[type])
                }
            })
        },
               /** 更新选中的项目ids
         * @description: 将payload中数组更新为当前页面的this.checkIds
         * @param {Array} checkIds
         * @return {*}
         */        
        updateSelectedIds(checkIds){
            this.checkIds = [...checkIds]
        },
        /**
         * @description: 封装param
         * @param {Array} list  表格数据
         * @return {Array} 对应后台字段的数据
         */
        packageParm(list = []) {

            const paramList =
                list.length > 0 &&
                list.map(item => {
                    const obj = {
                        basicId:item.basicInfoId,
                        operationId: item.advice.id,
                        scheduleId: item.id,
                        armariumId: item.device.id,
                        schCount: item.advice.schCount || 1,
                        responseTreatment: '无',
                        usesTime: item.scheduleTime,
                        usedTime: item.scheduleTimeEnd,
                        treatmentDate: new Date().getTime(),
                        consultingRoom:item.userConsultingRoom
                    }
                    return obj
                })
            return paramList
        },
        /**
         * @description: 提交项验证
         * @return {Boolean | Array} 验证失败或成功
         */
        validateTable() {
            const checked = this.$refs.mainTable.getChecked() || []
            console.log('------validateTable checked ------', checked)
            let validateStatus = true
            //过滤没有选中医嘱的数据
            let checkedAdviceList = []
            for (let j = 0; j < checked.length; j++) {
                const obj = checked[j]
                if (obj.advice.id === '') {
                    validateStatus = false
                    this.$message({
                        message: '选中状态下的治疗项目为必填项',
                        type: 'warning'
                    })
                    break;
                } else {
                    checkedAdviceList.push(obj)
                }
            }
            if (validateStatus) {
                return checkedAdviceList
            } else {
                return false
            }
        },
        //清空问题信息
        clearFailed() {
            console.log('clearFailed')
            this.failedData = ''
        },
        submit() {
            // this.clearFailed();
            const validate = this.validateTable()
            let parm = null
            if (validate === false) {
                return false
            } else {
                parm = this.packageParm(validate)
                console.log("------treatmentRegistration params ------", parm)
                if (!parm) {
                    return false
                }
                treatmentWithoutAdvice(parm)
                    .then(({ res }) => {
                        this.submitSuccess(res)
                    })
                    .catch(err => {
                        console.log("------treatmentRegistration error before------", err,this.$refs.mainTable.getChecked())
                        // 状态码为4017 时 表示 医嘱与设备不匹配
                        // 返回的数组为日程数组
                        if (err.state === 4017 || err.state === 4002) {
                            this.failedData = err.data
                        }
                    })
            }
        },
        submitSuccess(res) {
            this.checkIds = [];
            this.tableChecked = []
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        /**
         * 获取一键登记列表
         */
        getAllTreatmentList() {
            this.$nextTick(() => {
                getAllTreatmentList().then(({ res, timeStamp }) => {
                    this.tableData = this.packageData(res)
                })
            })
        },
        /**
         * 二次封装数据
         * @description: 提取治疗项目对应的操作项封装
         * @param {Array} arr  接口返回数组
         * @return {Array} dataList 封装后的数组
         */
        packageData(arr) {
            const dataList = arr.map(item => {
                let ruleOper = {
                    id: '',
                    name: ''
                }
                let patientDevice = {
                    id: '',
                    name: ''
                }
                const ruleOperList = _.filter(item.ruleOperList, obj => obj.name === item.operName)
                if (ruleOperList.length > 0) {
                    this.tableChecked = [...this.tableChecked, item.id]
                    ruleOper = Object.assign({schCount: 1}, ruleOperList[0]);
                }

                // 如果有匹配的设备，默认显示匹配的设备
                const selectDevices = this.ruleArmariumResList.filter(obj => obj.id === item.armariumId)
                if (item.armariumId && selectDevices.length) {
                    patientDevice = selectDevices[0]
                    const matchAdvice = this.deviceMatchingAdvice(selectDevices[0], item.ruleOperList)
                    if (matchAdvice.id !== null) {
                        ruleOper = matchAdvice
                        this.tableChecked = [...this.tableChecked, item.id]
                    }
                }
                item.advice = Object.assign({}, ruleOper)
                item.device = Object.assign({}, patientDevice)
                return item
            })
            return dataList;
        },
        /** 匹配医嘱
         * @description: 通过治疗设备 匹配 医嘱信息
         * @param {Object} selectDevice 匹配上的设备信息
         * @param {Array} adviceList 该患者的医嘱数据
         * @return {Object} 匹配上的对象
         */
        deviceMatchingAdvice(selectDevice, adviceList) {
            const deviceOperIds = selectDevice.operId ? selectDevice.operId.split(',') : []
            let matchAdvice = { id: null }
            for (let i = 0; i < deviceOperIds.length; i++) {
                const ele = deviceOperIds[i]
                if (matchAdvice.id !== null) {
                    break
                }
                matchAdvice = this.matchingAdvice(ele, adviceList)
            }
            return matchAdvice
        },
        /**
         * @description: 获取用户绑定的治疗设备列表
         * @param {*}
         * @return {*}
         */

        getUserDevices() {
            getUserDevices().then(({ res }) => {
                this.ruleArmariumResList = res
            })
        },
             /** 修改治疗项目选中项
         * @description:修改时获取项目id
         * @param {*}
         * @return {*}
         */
         changeOperationId(event, data){
            data.advice = this.matchingAdvice(event, data.ruleOperList)
        },

        /** 清空治疗项目选中项
         * @description: 清空时，清空对应的操作项id
         * @param {*}
         * @return {*}
         */   
        clearOperationId(){
              data.advice = { id: '', schCount: 1 }
        },
        clearDevice(event, data) {
            data.device = { id: '' }
        },
        /**
         * @description: 通过字符串获取对象
         * @param {String} id 操作项id
         * @param {Array} ruleOperList 操作项数据
         * @return {Object} 匹配上的对象
         */
        matchingAdvice(id, ruleOperList) {
            let advice = { id: null, schCount: 1 }
            if (ruleOperList) {
                for (let j = 0; j < ruleOperList.length; j++) {
                    const obj = ruleOperList[j]
                    if (id === obj.id) {
                        advice = Object.assign({}, advice, obj)
                        break
                    }
                }
            }
            return advice
        },
        /**
         * @description: 提交后 医嘱信息与设备信息不匹配后 重新封装数据 （增加className）
         * @param {Array} list
         * @return {*}
         */

        updateTableData(propId) {
            console.log("------propId------", propId)
            const tableList = this.tableData.map(item => {
                if ((propId === item.advice.id || propId === item.id) && propId !== '') {
                    item.className = 'failedItem'
                } else {
                    item.className = ''
                }
                return item
            })
            this.tableData = tableList
            console.log("------updateTableData tableChecked------", this.tableData.map(item => item.advice.id), this.tableChecked)
            // this.tableChecked = []
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped lang="scss">
@import '~@/styles/variables.scss';
.popverP {
    padding: 0 10px;
}
</style>
