<!--
 * @Author: ligc
 * @Date: 2021-11-25 16:19:25
 * @LastEditTime: 2022-08-29 14:49:32
 * @LastEditors: ZF-WLY
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\treatment\patientTreatment\treatmentPlan.vue
-->

<template>
  <div>
    <el-main class="basic-main">
      <div  class="lineStyle">
        <el-row class="table-btn-group" id="noPrint">
          <el-button class="buttonStyle" plain @click="back()">返回</el-button>
          <el-button class="buttonStyle" type="primary" @click="toPrint">打印</el-button>
        </el-row>
        <div id="printMe" ref="printMe">
          <div align='center'>{{hospitalName}}治疗单</div>
          <div v-if="patientName" class="nameAndNum"> 
            <span>姓名: {{ patientName || commonConfig.$nullData }}</span>
            <span>就诊号: {{ serialNum || commonConfig.$nullData }}</span> 
          </div>
          <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            :newHeight="'450'"
            style="margin-top:15px"
          >
          </generalTable>
        </div>
      </div>
    </el-main>
  </div>
</template>
<script>
import generalTable from "@/components/Table";
import {print} from "@/utils/print";
import { getTreatmentList } from "@/api/patientTreatment";
import { tableBorder } from '@/config/tablePrintStyle' // 打印表格公共样式

export default {
  name: "TreatmentPlan",
  components: {
    generalTable,
  },
  props: {
    basicInfoId: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      hospitalName: '',
      tableData: [],
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: false, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label:"治疗区",//必填
            prop:"consultingRoom",//必填
          },
          {
            label: "治疗师", // 必填
            prop: "userName", // 必填
          },
          {
            label: "治疗项目/治疗设备", // 必填
            prop: "operName", // 必填
          },
          {
            label: "计划时间", // 必填
            prop: "scheduleTime", // 必填
          },
        ],
      },
      paramObj: null, // 筛选项
      timeStamp: "",
      total: 0, // 总条数
      patientName: "", //患者姓名
      serialNum: "", //流水号
    };
  },
  created() {
    console.log("this.$route.query.param",this.$route.query.param);
    const resJson = JSON.parse(this.$route.query.param)
    this.tableData = resJson instanceof Array ? resJson : resJson.tableList;
    this.patientName = resJson.patientName || ""
    this.serialNum = resJson.serialNum || ""
    console.log("query",this.tableData);
  },
  mounted: function () {},
  methods: {
    back() {
      this.$router.back();
    },
    toPrint() {
      this.$nextTick(()=>{
        print('printMe', 
        `.nameAndNum{
          display: flex;
          justify-content: space-around; 
        }
        table{
          border-collapse: collapse;
          border-spacing: 0;
        }
        table tr td,th { border:thin #dedede solid;text-align:center;font-size:12px;color:#666;line-height:28px }
        `
        )
      })
    },
    getPlanList() {
      this.$store.dispatch('getHosInfo').then(res => {
        this.hospitalName = res.hospitalName
      })
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        getTreatmentList({ basicInfoId: this.basicInfoId }).then(
          ({ res, timeStamp }) => {
            this.tableData = res.records;
            this.patientName = res.patientName;
            this.serialNum = res.serialNum;
            this.timeStamp = timeStamp;
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.lineStyle{
  width: 45%;
  margin: 55px auto 0;
}
.buttonStyle{
  float: right;
  margin-right: 10px;
}
.nameAndNum{
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
</style>