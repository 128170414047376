<!--
 * @Author: magy
 * @Date: 2021-12-29 11:36:02
 * @LastEditTime: 2022-01-04 16:27:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\statistics\equipmentUsage\detail.vue
-->

<!--
* @Description: 设备使用情况统计详情页面
* @Reference:
-->

<template>
  <el-container>
    <el-main class="basic-main">
      <el-form class="searchForm">
            <div class="search-btn-group">
              <el-form-item>
                  <el-button type="default" @click="back()">返回</el-button>
              </el-form-item>
            </div>
        </el-form>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="listDetail"
        @onClickRow="onClickRow"
      >
      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import { listDetail } from "@/api/equipmentUsage";
import generalTable from "@/components/Table";

export default {
  name: "EquipmentUsageDetail",
  components: {
    generalTable,
  },
  data() {
    return {
      tableData: [],
      queryParam: {
        deviceId: this.$route.query.deviceId, //设备库id
        deviceStartTime: this.$route.query.deviceStartTime,//治疗日期开始时间
        deviceEndTime: this.$route.query.deviceEndTime,//治疗日期结束时间
      },
      tableConfig: {
        id: "equipmentUsage",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "设备名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "负责人", // 必填
            prop: "trueName", // 必填
          },
          {
            label: "治疗师姓名", // 必填
            prop: "patientName", // 必填
          },
          {
            label: "使用次数", // 必填
            prop: "userNum", // 必填
          },
          {
            label: "营收金额", // 必填
            prop: "price", // 必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
    };
  },
  created() {
    this.listDetail();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    listDetail() {
      this.$nextTick(() => {
        const param = this.queryParam;
        listDetail(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.timeStamp = timeStamp;
          this.total = res.total;
        });
      });
    },
    onClickRow(row) {
      console.log("点击行:", row);
    },
    back(){
      this.$router.back()
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
