<!--
 * @LastEditors: Richardlv
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="70%"
        title="关联治疗项"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="getOperList" />
        <generalTable
            ref="oper_type"
            :table-data="ruleOperationList"
            :config="tableConfig"
            :total="total"
            
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>

import { getDict } from "@/api/common";
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { insertData, editData, getDetail } from "@/api/library";
import { getRuleOperationList } from '@/api/advice'

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {
        id: {
            type: String,
        },
        selectedIds:{
            type: Array,
        },
    },
    data() {
        return {
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '治疗项目',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'operType',
                    label: '所属治疗',
                    options: [],
                }
            ],
            tableConfig: {
                id: "oper_type",
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
                column: [
                    {
                        label: "所属治疗", // 必填
                        prop: "operType", // 必填
                        formatter: (row, column) => {
                            return this.typeList[row.operType]
                        },
                        width:80,
                    },
                    {
                        label: "操作项名称", // 必填
                        prop: "name", // 必填
                    },
                ],
            },
            visible:false,
            ruleOperationList:[],
            timeStamp:"",
            total:0
        };
    },
    watch: {
        visible(newVal, oldVal) {
            this.$refs.mainSearch&&this.$refs.mainSearch.resetForm()
            if (newVal === true) {
                this.getOperList()
            }
        },
    },
    created() {
        this.getoper_type();
    },
    mounted() {},
    methods: {
        submit(){
            if(this.$refs.oper_type.getChecked().length>0){
                this.$emit("selectedOper",this.$refs.oper_type.getChecked());
                this.updateVisible(false)
            }else{
                this.$message({
                    showClose: true,
                    message: '请选择操作项',
                    type: 'warning'
                })
            }
        },
        getoper_type() {
            getDict({ type: 'oper_type' }).then(({res}) => {
                let typeList=[]
                let typeOptionsCache = res.oper_type.map((item) => {
                    typeList[item.value] = item.label
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                typeOptionsCache = [{value:"",label:"全部"}].concat(typeOptionsCache)
                this.searchOptions[1].options = typeOptionsCache
                this.typeList = typeList
            });
        },
        getOperList(){
            const paramObj = this.$refs.mainSearch?this.$refs.mainSearch.packageData():{}
            let param = Object.assign(
                { 
                    operType: paramObj.operType,
                    name:paramObj.name,
                    charge:1,
                }
            );
            getRuleOperationList(param).then(({res,timeStamp}) => {
                let ruleOperationListCache = []
                for(let i=0;i<res.length;i++){
                    if(this.selectedIds.indexOf(res[i].id)==-1){
                        ruleOperationListCache.push(res[i])
                    }else{
                        
                    }
                }
                this.ruleOperationList = ruleOperationListCache
                this.timeStamp = timeStamp
            })
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
            }
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        