/*
 * @Author: ZF-WLY
 * @Date: 2021-03-12 14:03:19
 * @LastEditTime: 2022-03-10 15:27:15
 * @LastEditors: Please set LastEditors
 * @Description: 组织机构相关接口
 *      修改、更新状态及删除接口需要传入一个timeStamp，该属性在获取列表时获得；
 *      因此每次修改、更新、删除、插入操作后都需要重新获取一次列表以便获得最新的timeStamp，否则会出现更新不成功的情况
 * @FilePath: \recovery_management\src\api\organization.js
 */

import request from '@/utils/request'

/**
 * @description: 组织机构
 */
export function getOrgList(data) {
    return request({
        url: '/admin/dept/allList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 组织机构(去掉禁用)
 */
 export function getOrgUserList(data) {
    return request({
        url: '/admin/dept/useList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除机构
 */
 export function orgDel(data, timeStamp) {
    return request({
        url: '/admin/dept/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 禁用机构
 */
 export function orgDisable(data, timeStamp) {
    return request({
        url: '/admin/dept/disableDept',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改机构
 */
 export function orgUpdate(data, timeStamp) {
    return request({
        url: '/admin/dept/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 新增机构
 */
 export function orgInsert(data) {
    return request({
        url: '/admin/dept/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 部门展示树
 */
 export function getDeptTree(data) {
    return request({
        url: '/admin/dept/getDeptTree',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 为机构分配用户
 */
 export function addUserToDept(data) {
    return request({
        url: '/admin/userDept/addUserToDept',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 解除部门与用户关系
 */
 export function disassociate(data) {
    return request({
        url: '/admin/userDept/disassociate',
        method: 'POST',
        data,
        showLoading: true
    })
}
