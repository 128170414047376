
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增表格列配置' : '修改表格列配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">表格列信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="列名称(CN)" prop="name">
                    <el-input
                        v-model="form.name"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                    />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="字段名称(EN)" prop="fieldName">
                        <el-input
                            v-model="form.fieldName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="前端插槽" prop="slot">
                        <el-input
                            v-model="form.slot"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="defaultSort">
                        <el-input
                            v-model="form.defaultSort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="defaultStatus">
                    <el-switch
                        v-model="form.defaultStatus"
                        name="defaultStatus"
                        active-text="显示"
                        inactive-text="隐藏"
                        inactive-value="1"
                        active-value="0"
                    />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="form.remark" type="textarea" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
            <el-button type="primary" @click="submit()">提 交</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertMenuColumnData, editMenuColumnData,getMenuColumnDetail } from '@/api/customCollocation'
export default {
    props: {
        menuId: {
            type: String,
            require: true
        },
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入列名称', trigger: 'blur' },
                ],
                fieldName: [
                    { required: true, message: '请输入字段名称', trigger: 'blur' },
                ]
            },
            form: {
                defaultStatus: '0',
                name: '',
                fieldName: '',
                defaultSort: '',
                remark: '',
                slot:'',
            },
            visible: false,
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
            //...todo
          }
        }
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = Object.assign({},this.form,{menuId:this.menuId,id:this.id})
                    if (this.id === '') {
                        insertMenuColumnData(parm).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editMenuColumnData(parm,this.timeStamp).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail(){
            getMenuColumnDetail({id:this.id}).then(({res,timeStamp}) => {
                this.form = res
            })
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig} = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...this.commonConfig.$handleSuccess})
                })
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        