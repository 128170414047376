<!--
 * @Author: your name
 * @Date: 2021-12-28 16:19:58
 * @LastEditTime: 2021-12-29 17:24:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\statistics\doctorPersonal\index.vue
-->

<!--
* @Description: 医生个人工作量统计页面
* @Reference:
-->

<template>
<el-container>
    <el-tabs type="border-card" style="width:100%" @tab-click="tabChange" v-model="tabValue">
        <el-tab-pane :lazy="true" label="患者" name="patient"><patientOrder :query="query" v-if="tabValue === 'patient'"/></el-tab-pane>
        <el-tab-pane :lazy="true" label="治疗项" name="oper"><treatmentOrder :query="query" v-if="tabValue === 'oper'" /></el-tab-pane>
    </el-tabs>
</el-container>
</template>

<script>
import treatmentOrder from "@/views/statistics/treatmentOrder";
import patientOrder from "@/views/statistics/patientOrder";
export default {
    name: 'doctorPersonal',
    components:{
        treatmentOrder,
        patientOrder
    },
    data() {
        return {
            tabValue: '',
            userId: this.$route.query.userId,//用户Id
            query :this.$route.query,//用户信息
        }
    },
    created() {
        //0跳 治疗项  1跳患者
        if(this.$route.query.jumpFlag==='0'){
            this.tabValue='oper'
        }else{
            this.tabValue='patient'
        }
    },
    mounted: function() {},
    methods: {
        tabChange(event){
            this.tabValue = event.name
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        