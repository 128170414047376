<!--
* @Description: 新增疾病诊断
* @Reference:
-->

<template>
  <el-dialog
    width="78%"
    :title="titleText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-form-item label="日期" prop="date">
        <el-date-picker
          v-model="form.date"
          align="right"
          type="date"
          clear-icon="el-input__icon"
          placeholder="选择日期"
          :editable="false"
        />
      </el-form-item>
      <el-form-item label="时间" prop="time">
        <el-time-picker
          :clearable="false"
          v-model="form.time"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:00',
          }"
          placeholder="选择时间"
        />
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input
          v-model="form.content"
          rows="3"
          type="textarea"
          placeholder="请输入..."
          clearable
          maxlength="200"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
    addDiseaseDiagnosis,
    updateDiseaseDiagnosis
} from '@/api/addDiseaseDiagnosis'

import moment from 'moment'
export default {
    components: {},
    props: {
        detail: Object,
        timeStamp:String,
    },
    data() {
        return {
            total: 0, // 总条数
            goals: '', // 阶段目标
            diseaseDiagnosis: '', // 疾病诊断
            visible: false,
            form: {
              date: '',
              time: '',
              content: ''
            },
            targetId: '',
            titleText: '', // title名称
            rules:{
              content:{
                required: true,
                message: '请输入内容',
                trigger: 'blur'
              }
            }
        }
    },
	watch: {
      detail(oldValue, newValue) {
        const textList = ['疾病诊断', '阶段目标']
        const addText = this.detail.id ? '编辑' : '新增'
        this.titleText = addText + textList[this.detail.status]
        if (this.detail.id) {
          this.form = {
            date: this.detail.dateTime,
            time: this.detail.dateTime,
            content: this.detail.content
          }
        } else {
          this.form = {
            date: new Date(),
            time: new Date(),
            content: ''
          }
        }
      }
    },
    created() {
    },
    mounted() {
	},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                const parm = this.form
                const date = moment(parm.date).format('YYYY-MM-DD')
                const time = moment(parm.time).format('HH:mm')
                const parmObj = Object.assign({
                    dateTime: new Date(date + ' ' + time).getTime(),
                    status: this.detail.status,
                    content: parm.content,
                    basicInfoId: this.detail.basicInfoId,
                    archivesInfoId: this.detail.archivesInfoId,
					id: this.detail.id
                })
                if (valid) {
                    if (parmObj.id && parmObj.id.length > 0) {
                        updateDiseaseDiagnosis(parmObj, this.timeStamp).then((res) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        addDiseaseDiagnosis(parmObj).then((res) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped>
</style>

