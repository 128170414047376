<!--
 * @Author: your name
 * @Date: 2021-12-22 14:28:20
 * @LastEditTime: 2022-03-03 13:26:14
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\knowledgeBase\myDocument\selectPat.vue
-->
<template>
    <el-dialog width="70%" title="选择患者" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" append-to-body>
        <searchForm ref="mainSearch" :searchoptions="searchOptions" :back-status="false" @search="search" />
        <generalTable v-if="visible" ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"></generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import searchForm from '@/components/SearchForm'
import { getList, getDept } from '@/api/patient'

export default {
    components: {
        generalTable,
        searchForm
    },
    props: {
        selectType: {
            type: String
        }
    },
    data() {
        return {
            checkId: '',
            tableData: [],
            deptOptions: [], // 所属科室
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'sex',
                    filterable: true,
                    label: '性别',
                    options: this.commonConfig.$sexOptions,
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                }
            ],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                total: 0,
                column: [
                    {
                        label: '姓名', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '性别', // 必填
                        prop: 'sex', // 必填
                        formatter: (row, column) => {
                            return this.commonConfig.$sexList[row.sex]
                        },
                        width: '50' // 不必填
                    },
                    {
                        label: '所属科室', // 必填
                        prop: 'deptName' // 必填
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'phone' // 必填
                    }
                ]
            },
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: '' //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        /** 监听visible状态
         * @description: 弹窗显示时，获取患者列表以及对应的所属科室列表
         * @param {*} newVal
         * @param {*} oldVal
         * @return {*}
         */        
        visible(newVal, oldVal) {
            if (newVal === true) { // 弹窗显示
                this.getList()
                this.getOptions()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * @description: 筛选查询
         * @param {Object} data 搜索框中要筛选的内容
         * @return {*}
         */
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },

        /**
         * @description: 获取所属科室
         *      获取成功后，将内容绑定到下拉框的选项中，
         *      value为对应select的v-model绑定值，label为页面显示值
         * @param {*}
         * @return {*}
         */
        getOptions() {
            getDept({ deptType: '4' }).then(({ res, timeStamp }) => {
                res.forEach((element) => {
                    const ele = {
                        value: element.id,
                        label: element.label
                    }
                    this.deptOptions.push(ele)
                })
                this.searchOptions[2].options = this.deptOptions
            })
        },
        /** 获取参数
         * @description: 将搜索框及表格插件翻页信息拼接到一起 进行返回
         * @param {String} typeId 类型id
         * @return {Object} 要发送的参数
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        /**
         * @description: 获取患者列表
         * @param {*}
         * @return {*}
         */
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = Number(res.total)
                })
            })
        },

        /** 提交
         * @description: 如果未选中患者，进行消息提示
         *      如果选中了患者，将患者信息传入到父级页面`@selectPerson`绑定的函数中，并关闭弹窗
         * @param {*}
         * @return {*}
         */
        submit() {
            if (this.$refs.mainTable.getChecked().length != 0) {
                this.$emit('selectPerson', this.$refs.mainTable.getChecked())
                this.updateVisible(false)
            } else {
                this.$message({
                    message: '请选择患者',
                    type: 'warning'
                })
            }
        },
        /** 更新显示状态
         * @description: 控制弹窗显隐
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped lang="scss"></style>