
<!--
* @Description: 表格列配置页面
* @Reference:
-->

<template>
    <div>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="getList" :backStatus="backStatus"/>
        </el-header>
        <el-divider/>
        <el-main class="basic-main">
            <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <addDialog :menuId="menuId" :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="getList" />
        </el-main>
    </div>
</template>

<script>
import { getMenuColumnList, deleteMenuColumnData } from '@/api/customCollocation'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/customCollocation/dispose/tableColumn/addDialog'

export default {
    name: 'TableColumn',
    components: {
        searchForm,
        generalTable,
        addDialog
    },
    props: {
        menuId: {
            type: String,
            require: true
        },
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'fieldName',
                    label: '字段名称',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'tableColumn',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                {
                    label: '名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '字段名称', // 必填
                    prop: 'fieldName' // 必填
                },
                {
                    label: '前端插槽',
                    prop: 'slot',
                },
                {
                    label: '排序', // 必填
                    prop: 'defaultSort' // 必填
                },
                {
                    label: '状态', // 必填
                    prop: 'defaultStatus', // 必填
                    formatter: (row,column) =>{
                        return row.defaultStatus === '0' ? '显示' : '隐藏'
                    }
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function() {},
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getMenuColumnList(param).then(({ res, timeStamp }) => {
                    this.tableData = res || []
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteMenuColumnData(param,this.timeStamp).then(({res}) => {
                    this.$message($handleSuccess)
                    this.getList()
                })
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const param = Object.assign({}, this.paramObj, paramObj,{menuId:this.menuId})
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.el-header{
    margin-bottom: 0 !important;
}
.el-divider--horizontal{
    margin-bottom: 0;
}
</style>

        