<!--
 * @Author: ZF-WLY
 * @Date: 2021-10-20 09:57:32
 * @LastEditTime: 2021-11-11 11:09:47
 * @LastEditors: Richardlv
 * @Description: 菜单管理列表页
 * @FilePath: \recovery_management\src\views\system\menu\index.vue
-->

<template>
    <el-container>
        <el-main class="basic-main">
            <el-form class="searchForm" :inline="true">
                <div class="search-btn-group">
                    <el-form-item>
                        <el-button type="primary" v-if="addStatus" @click="toInsert()">新增</el-button>
                        <el-button type="primary" plain v-if="addStatus" @click="download()">菜单规则</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="default" @click="back()">返回</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <!-- <el-row class="table-btn-group">
                <el-button type="primary" @click="toInsert()">新增</el-button>
            </el-row>-->
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
            >
                <!-- 点击姓名展示详情 -->
                <template slot="name" slot-scope="scope">
                    <a class="clickCell" @click="toDetail(scope)">{{ scope.data.row.name }}</a>
                </template>
                <!-- 切换禁用/启用状态 设置机构禁用/启用 -->
                <template slot="status" slot-scope="scope">
                    <el-switch
                        v-model="scope.data.row.status"
                        :disabled="scope.data.row.id === '0' || !menuStatus"
                        inactive-value="1"
                        active-value="0"
                        @change="setStatus(scope.data.row)"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" v-if="editStatus" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" v-if="addChildStatus" @click="insertChild(scope)">新增子级</el-button>
                    <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <!-- 菜单详情弹窗 -->
            <detailDialog :id="checkId" ref="detail" :table-data="tableData" />
            <!-- 新增/编辑菜单弹窗 -->
            <addDialog
                :id="checkId"
                ref="insert"
                :parent-id="parentId"
                :table-data="tableData"
                :time-stamp="timeStamp"
                @submit="search"
            />
        </el-main>
    </el-container>
</template>

<script>
import moment from 'moment' // moment插件
import {
    getList, // 获取菜单列表
    deleteData, // 删除菜单
    changeMenuStatus // 更改菜单状态
} from '@/api/menu'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/menu/addDialog'
import detailDialog from '@/views/system/menu/detailDialog'

const menuTypeMap = {
    1: '菜单',
    2: '按钮',
    3: '其他',
}

export default {
    name: 'Menu',
    components: {
        generalTable, // 表格控件
        detailDialog, // 菜单详情控件
        addDialog // 新增/修改菜单控件
    },
    data() {
        return {
            tableData: [],
            tableConfig: {
                id: 'menu',
                align: 'left', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                expandOpen: false, // 默认展开
                column: [
                    {
                        label: '菜单名称', // 必填
                        slot: 'name', // 必填
                        width: 230
                    },
                    {
                        label: '页面名称', // 必填
                        prop: 'pageName', // 必填
                        width: 230
                    },
                    {
                        label: '类型', // 必填
                        prop: 'type', // 必填
                        sortable: false, // 非必填 默认为true
                        width:50,
                        formatter: (row, column) => {
                            // 按菜单类型显示菜单名称
                            return menuTypeMap[row.type]
                        }
                    },
                    {
                        label: '组件路径', // 必填
                        prop: 'component', // 必填
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        label: '跳转路径', // 必填
                        prop: 'path', // 必填
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        label: '重定向', // 必填
                        prop: 'redirect', // 必填
                        sortable: false, // 非必填 默认为true
                        width: 100,
                    },
                    {
                        label: '排序', // 必填
                        prop: 'sort', // 必填
                        sortable: false, // 非必填 默认为true
                        width: 50,
                        align: 'center'
                    },
                    {
                        label: '禁用/启用', // 必填
                        slot: 'status', // 必填
                        width: 80,
                        align: 'center'
                    },
                    {
                        label: '权限标识', // 必填
                        prop: 'permission', // 必填
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        label: '备注',
                        prop:'remark'
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            // 将时间戳转换成YYYY-MM-DD HH:mm:ss格式时间
                            return moment(row.createDate).format(
                                'YYYY-MM-DD HH:mm:ss'
                            )
                        },
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: {}, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            parentId: '', // 父级菜单id 用于显示父级层级
            timeStamp: '', // 时间戳
            addStatus: true, // 新增按钮权限
            editStatus: true, // 修改按钮权限
            delStatus: true, // 删除按钮权限
            addChildStatus: true, // 新增子级权限
            menuStatus: true, // 菜单状态权限
        }
    },
    created() {
        this.addStatus = this.hasPermission('menu-add')
        this.editStatus = this.hasPermission('menu-edit')
        this.delStatus = this.hasPermission('menu-del')
        this.addChildStatus = this.hasPermission('menu-insert-child')
        this.menuStatus = this.hasPermission('menu-status')
        this.getList() // 页面创建时，马上获取列表
    },
    mounted: function () {},
    methods: {
        /** 搜索
         * @description:更新搜索参数，并触发表格控件的handleCurrentChange函数
         * @param {Object} data 传入的搜索项
         * @return {*}
         */
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /**
         * 获取用户列表
         * @description:
         *      该用户列表为树形结构，子层嵌套在父层的children关键字中，
         *      并于页面加载完成后将数据同步到tableData中
         * @param {*}
         * @return {*}
         */
        getList(callback) {
            this.$nextTick(() => {
                const param = {}
                getList(param).then(({ res, timeStamp }) => {
                    this.timeStamp = timeStamp
                    this.tableData = res
                    if (typeof callback === 'function') {
                        callback()
                    }
                })
            })
        },
        /** 插入菜单
         * @description:
         *      清空checkId及parentId，
         *      显示新增菜单弹窗
         * @param {*}
         * @return {*}
         */
        toInsert() {
            this.checkId = ''
            this.parentId = ''
            this.$refs.insert.updateVisible(true)
        },
        /** 查看菜单详情
         * @description:
         *      将当前行id作为checkId传入菜单详情弹窗
         *      清空parentId，
         *      显示菜单详情弹窗
         * @param {*}
         * @return {*}
         */
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.parentId = ''
            this.$refs.detail.updateVisible(true)
        },
        /** 编辑菜单
         * @description:
         *      将当前行id作为checkId传入新增菜单弹窗
         *      清空parentId，
         *      显示插入菜单弹窗
         * @param {*}
         * @return {*}
         */
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        /** 插入子级菜单
         * @description:
         *      将当前行id作为parentId传入新增菜单弹窗
         *      清空checkId，
         *      显示插入菜单弹窗
         * @param {*} scope
         * @return {*}
         */
        insertChild(scope) {
            this.checkId = ''
            this.parentId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        /** 删除菜单
         * @description:
         *      进行删除提示
         *      确认后，根据当前行数据中所给出的id进行菜单删除
         *      删除成功后，进行消息提示并更新表格
         * @param {*} scope
         * @return {*}
         */
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                    this.$message({...this.commonConfig.$handleSuccess})
                    this.$refs.mainTable.handleCurrentChange(1)
                })
            })
        },
        /** 获取搜素参数
         * @description: 根据表格插件中给出的
         * @param {*}
         * @return {*}
         */
        getParam() {
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, page)
            this.paramObj = param
            return param
        },
        /** 设置菜单状态
         * @description: 更新菜单启用/禁用状态，更新成功后，进行消息提示
         * @param {Object} row 当前行数据
         * @return {*}
         */
        setStatus(row) {
            const parm = {
                id: row.id,
                status: row.status
            }
            changeMenuStatus(parm, this.timeStamp).then(({ res }) => {
                this.getList(() => {
                    // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                    this.$message({...this.commonConfig.$handleSuccess})
                })
            })
        },
        /**
         * 返回至上一页面
         * @description:
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back()
        },
        download(){
            const name = 'menu_roles'
            const href= `/doc/${name}.doc`
            let a = document.createElement('a')
            a.href = href
            a.download = name
            a.click()
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.table-btn-group {
    margin-top: 20px;
}
.search-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>

