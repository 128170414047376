<!--
* @Description: 医嘱统计页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <div style="display: flex; justify-content: end; align-items: center; margin-bottom: 5px">
        <el-button type="default" @click="back()"
        >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">

      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import { getList } from '@/api/orderStatistics'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
  name: 'OrderStatistics',
  components: {
    searchForm,
    generalTable
  },
  data() {
    return {
      tableData: [],
      tableConfig: {
        id: 'orderStatistics',
        align: 'center', // 不必填 默认为center
        selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: '患者姓名', // 必填
            prop: 'patientName' // 必填
          },
          {
            label: '所属治疗', // 必填
            prop: 'operType' // 必填
          },
          {
            label: '操作项名称', // 必填
            prop: 'operName' // 必填
          },
          {
            label: '医嘱类型', // 必填
            prop: 'doctorOrderTypeLabel' // 必填
          },
          {
            label: '总次数', // 必填
            prop: 'createCount', // 必填
            formatter: (row, column) => {
              return row.doctorOrderType=='1'?"长期":row.createCount;
            }
          },
          {
            label: '单次数量', // 必填
            prop: 'schCount' // 必填
          },
          {
            label: '治疗频次', // 必填
            prop: 'frequencyCount', // 必填
            formatter: (row, column) => {
            return (
                  row.frequencyCount+"次/" +
                  row.frequencyTime +
                  ["天", "周", "月", "年"][row.frequencyUnit]
              )
            }
          },
          {
            label: '执行次数', // 必填
            prop: 'executeNums' // 必填
          },
          {
            label: '单价', // 必填
            prop: 'price' // 必填
          },
          {
            label: '营收金额', // 必填
            prop: 'amount' // 必填
          }
        ]
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: '', //时间戳 用于修改数据或删除数据
      searchParam: null
    }
  },
  created() {
    this.searchParam = {
      userId: this.$route.query.userId,
      basicInfoId: this.$route.query.basicInfoId,
      adviceDateStartTime: this.$route.query.adviceDateStartTime,
      adviceDateEndTime: this.$route.query.adviceDateEndTime
    }
    this.getList()
  },
  methods: {
    getList() {
      this.$nextTick(() => {
        const param = this.getParam()
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records
          this.total=parseInt(res.total)
        })
      })
    },
    onClickRow(row) {
    },
    getParam() {
      const searchParam = this.searchParam
      const page = this.$refs.mainTable.getPage()
      const param = Object.assign({}, this.paramObj, page, searchParam)
      this.paramObj = param
      return param
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style lang='scss' scoped>
  @import "~@/styles/variables.scss";
</style>

