
<!--
* @Description: 设备库页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <el-row v-if="addSet" class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="toGetList">
            <template slot="handle" slot-scope="scope">
            <el-button v-if="detailSet" type="text" @click="toDetail(scope)">详情</el-button>
            <el-button v-if="editSet" type="text" @click="toEdit(scope)">修改</el-button>
            <el-button v-if="delSet" type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
            </template>
        </generalTable>
        <detailDialog :id="checkId" ref="detail" />
        <addDialog :id="checkId" ref="insert" @submit="search"/>
    </el-main>
</el-container>
</template>

<script>
import { getDict } from "@/api/common";
import { getList, deleteData } from '@/api/library'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/device/library/addDialog'
import detailDialog from '@/views/device/library/detailDialog'

export default {
    name: 'Library',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '设备名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'operType',
                    filterable: true,
                    label: '所属治疗',
                    options: [],
                    defaultVal: []
                },{
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'operSearch',
                    label: '治疗项目',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'library',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '设备名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '设备型号', // 必填
                    prop: 'model' // 必填
                },
                {
                    label: '所属治疗', // 必填
                    prop: 'operTypeLabel' // 必填
                },
                {
                    label: '关联治疗项', // 必填
                    prop: 'operNums' // 必填
                },
                {
                    label: '设备数量', // 必填
                    prop: 'armariumNums' // 必填
                },
                {
                    label: '可用设备数量', // 必填
                    prop: 'useableArmariumNums' // 必填
                },
                {
                    label: '备注', // 必填
                    prop: 'remark' // 必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            addSet:true,
            delSet:true,
            editSet:true,
            detailSet:true,
        }
    },
    created() {
        this.getoper_type()
        this.toGetList()
        this.addSet = this.hasPermission('library-add')
        this.delSet = this.hasPermission('library-del')
        this.editSet = this.hasPermission('library-edit')
        this.detailSet = this.hasPermission('library-detail')
    },
    mounted: function() {},
    methods: {
        getoper_type() {
            getDict({ type: 'oper_type' }).then(({res}) => {
                let typeOptionsCache = res.oper_type.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                typeOptionsCache = [{value:"",label:"全部"}].concat(typeOptionsCache)
                this.searchOptions[1].options = typeOptionsCache
            });
        },
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
            this.toGetList()
        },
        toGetList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = res.total
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            if(scope.data.row.armariumNums>0){
                this.$message({
                    showClose: true,
                    message: '请先删除设备库下的所有设备',
                    type: 'warning'
                })
            }else{
                const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
                this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                    const param = { id: scope.data.row.id }
                    deleteData(param,this.timeStamp).then(({res}) => {
                        this.$message({...$handleSuccess})
                        this.toGetList()
                    })
                })
            }
            
            
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        