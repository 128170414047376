<!--
 * @Author: 张东阳
 * @Date: 2022-7-21 11:39:19
 * @LastEditTime: 2022-7-21 11:39:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\outSystem\treatmentPlan\index.vue
-->

<template>
  <div>
    <el-main class="basic-main">
      <div  class="lineStyle">
        <div id="printMe" ref="printMe">
          <div align='center'>{{hospitalName}}治疗单</div>
          <div class="nameAndNum">
            <span>姓名: {{ patientName }}</span>
            <span>就诊号: {{ serialNum || commonConfig.$nullData }}</span>
          </div>
          <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            style="margin-top:15px"
          >
          </generalTable>
        </div>
      </div>
    </el-main>
  </div>
</template>
<script>
import generalTable from "@/components/Table";
import { getTreatmentListByVisitId } from "@/api/patientTreatment";

export default {
  name: "outSystemTreatmentPlan",
  components: {
    generalTable,
  },
  data() {
    return {
      activeName: "first",
      hospitalName: '',
      tableData: [],
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: false, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label:"治疗区",//必填
            prop:"consultingRoom",//必填
          },
          {
            label: "治疗师", // 必填
            prop: "userName", // 必填
          },
          {
            label: "治疗项目/治疗设备", // 必填
            prop: "operName", // 必填
          },
          {
            label: "计划时间", // 必填
            prop: "scheduleTime", // 必填
          },
        ],
      },
      paramObj: null, // 筛选项
      timeStamp: "",
      total: 0, // 总条数
      patientName: "", //患者姓名
      serialNum: "", //流水号
      hisVisitId: this.$route.query.id
    };
  },
  created() {
    this.getPlanList();
  },
  mounted: function () {},
  methods: {
    getPlanList() {
      this.$store.dispatch('getHosInfo').then(res => {
        this.hospitalName = res.hospitalName
      })
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        getTreatmentListByVisitId({ hisVisitId: this.hisVisitId }).then(
          ({ res, timeStamp }) => {
            this.tableData = res.records;
            this.patientName = res.patientName;
            this.serialNum = res.serialNum;
            this.timeStamp = timeStamp;
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";
  .lineStyle{
    width: 45%;
    margin: 0 auto;
  }
  .buttonStyle{
    float: right;
    margin-right: 10px;
  }
  .nameAndNum{
    display: flex;
    justify-content: space-around;
  }
</style>
