<!--
 * @Author: your name
 * @Date: 2022-11-16 10:07:18
 * @LastEditTime: 2022-12-09 13:53:28
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\template\detail.vue
-->
<template>
    <el-container ref="container">
        <el-header height="auto">
            <el-form ref="insertForm" :inline="true" class="searchForm">
                <div class="search-btn-group">
                    <el-form-item>
                        <el-button type="primary" @click="edit()">编辑</el-button>
                    </el-form-item>
                    <el-form-item style="margin-right: 0">
                        <el-button type="primary" plain @click="back()">返回</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-header>
        <el-main class="basic-main">
            <el-descriptions class="margin-top" title="模板详情" :column="3" style="width: 952px;">
                <el-descriptions-item label="模板名称">
                    {{ commonConfig.$checkNullData(form.name) }}
                </el-descriptions-item>
                <el-descriptions-item label="标签">
                    {{ commonConfig.$checkNullData(form.labelName) }}
                </el-descriptions-item>
                <el-descriptions-item label="状态">
                    {{ commonConfig.$checkNullData(form.status) }}
                </el-descriptions-item>
                <el-descriptions-item label="模板内容" :span="3"></el-descriptions-item>
                <el-descriptions-item label="" :span="3">
                    <tinymce
                        :disabled="true"
                        ref="tinymce"
                        :height="700"
                        style="width: 890px;"
                        name="content"
                        class="content"
                        v-model="form.templateData"
                        id="tinymce"
                    ></tinymce>
                </el-descriptions-item>

                <el-descriptions-item label="描述" :span="3">
                    {{ commonConfig.$checkNullData(form.content) }}
                </el-descriptions-item>
            </el-descriptions>
        </el-main>
    </el-container>
</template>
<script>
import { casesTemplateInfo } from '@/api/caseReport'
import Tinymce from '@/components/Tinymce'
export default {
    components: {
        Tinymce
    },
    data() {
        return {
            id: '',
            disabled: false,
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                status: [{ required: true, message: '请选择模板状态', trigger: 'change' }],
                templateData: [{ required: true, message: '模板内容不能为空', trigger: 'blur' }]
            },
            title: '',
            content: '',
            form: {
                name: '',
                labelIds: [],
                status: '',
                templateData: '',
                content: ''
            },
            // 状态 0 启用 1禁用
            statusOpts: [
                { value: '0', label: '启用' },
                { value: '1', label: '禁用' }
            ],
            labelOpts: [],
            extData: [],
            allData: []
        }
    },
    created() {
        const { id } = this.$route.query
        this.id = id
        this.getDetail()
    },
    methods: {
        getDetail() {
            casesTemplateInfo({ id: this.id }).then(async ({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                console.log('------template info', res)
                const { extData, labelIds, status, ...others } = res
                const statusLabel = await this.valToLabel("template_status", status)
                console.log("------statusLabel", statusLabel)
                this.form = {
                    ...others,
                    status: statusLabel,
                    extData: JSON.parse(extData),
                    labelIds: labelIds.split(',')
                }
            })
        },
        edit() {
            this.$router.push({
                path: '/caseReport/template/add',
                query: {
                    id: this.id
                }
            })
        },
        back() {
            this.$router.back()
        }
    }
}
</script>
<style lang="scss" scoped>
.formBox {
    width: 1020px;
}
</style>
