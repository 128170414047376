var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"width":"50%","title":_vm.typeName ? _vm.typeConfig[_vm.typeName].title + '配置' : '配置',"visible":_vm.visible,"close-on-click-modal":false,"close-on-press-escape":false,"show-close":false,"append-to-body":""},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-main',{staticClass:"basic-main"},[_c('el-transfer',{staticClass:"my-transfer",attrs:{"target-order":"unshift","titles":[
        _vm.typeName && ((_vm.typeConfig[_vm.typeName].title) + "(" + (_vm.all.length) + ")"),
        ("已选择(" + (_vm.choose.length) + "/" + (_vm.typeConfig[_vm.typeName].maxNum) + ")")
      ],"format":{ noChecked: " ", hasChecked: " " },"props":{
        key: 'id',
        label: 'name',
        disable: 'status'
      },"data":_vm.all},on:{"right-check-change":_vm.rightItemHandle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var option = ref.option;
return _c('div',{staticClass:"option-item"},[_c('span',[_vm._v(_vm._s(option.name))]),(_vm.typeName !== 'commonUse')?_c('el-popover',{attrs:{"placement":"top-start","title":"功能介绍","width":"200","trigger":"hover","content":option.remark}},[_c('i',{staticClass:"badge",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("i")])]):_vm._e()],1)}}]),model:{value:(_vm.choose),callback:function ($$v) {_vm.choose=$$v},expression:"choose"}},[_c('div',{staticClass:"transfer-btn-group",attrs:{"slot":"right-footer"},slot:"right-footer"},[_c('el-button',{on:{"click":function($event){return _vm.handleUp()}}},[_vm._v("上移")]),_c('el-button',{on:{"click":function($event){return _vm.handleDown()}}},[_vm._v("下移")])],1)])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("提 交")]),_c('el-button',{on:{"click":function($event){return _vm.updateVisible(false)}}},[_vm._v("取 消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }