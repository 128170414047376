<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->
<template>
    <el-dialog
        title="修改基本信息"
        :visible.sync="visible"
        :close-on-click-modal="false"
        @closed="cancelSubmit()"
        width="60%"
    >
        <el-form
            ref="editForm"
            :model="form"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row class="formRow">
                <el-col :span="24">
                    <el-form-item
                        label="工作时段："
                        class="formItem"
                        prop="hospitalAddress"
                    >
                    <el-time-select
                        v-model="form.workingHours"
                        value-format="HH:mm"
                        :editable="false"
                        :picker-options="{
                            start: '00:00',
                            step: '00:10',
                            end: '24:00',
                            maxTime: form.rushHour
                        }"
                    >
                    </el-time-select>
                    至
                    <el-time-select
                        v-model="form.rushHour"
                        value-format="HH:mm"
                        :editable="false"
                        :picker-options="{
                            start: '00:00',
                            step: '00:10',
                            end: '24:00',
                            minTime: form.workingHours
                        }"
                    >
                    </el-time-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="formRow">
                <el-col :span="24">
                    <el-form-item
                        label="午休时段："
                        class="formItem"
                        prop="hospitalAddress"
                    >
                    <el-time-select
                        v-model="form.startLunchBreak"
                        value-format="HH:mm"
                        :editable="false"
                        :picker-options="{
                            start: '00:00',
                            step: '00:10',
                            end: '24:00',
                            minTime: form.workingHours,
                            maxTime: form.endLunchBreak
                        }"
                    >
                    </el-time-select>
                    至
                    <el-time-select
                        v-model="form.endLunchBreak"
                        value-format="HH:mm"
                        :editable="false"
                        :picker-options="{
                            start: '00:00',
                            step: '00:10',
                            end: '24:00',
                            minTime: form.startLunchBreak,
                            maxTime: form.rushHour
                        }"
                    >
                    </el-time-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="formRow">
                <el-col :span="12">
                    <el-form-item
                        label="治疗工作间隔："
                        class="formItem"
                        prop="hospitalEmail"
                    >
                    <div style="display:flex">
                        <el-input-number style="width:110px;margin-right:10px" v-model="form.workInterval"></el-input-number>分钟
                    </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSubmit()">确 定</el-button>
            <el-button @click="cancelSubmit()">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>

import {savePersonalInfo } from '@/api/personalSetting'


export default {
    data() {
        return {
            rules: {
                workInterval:[
                    {
                        required: true,
                        message: "请输入治疗工作间隔",
                        trigger: "blur",
                    },
                ]
            },
            form: {
                workingHours:"",    //上班时间
                rushHour:"",        //下班时间
                startLunchBreak:"", //午休开始时间
                endLunchBreak:"",   //午休结束时间
                workInterval:"",    //治疗工作时间间隔
            },
            visible: false,
            submitFlag:true,
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.form = this.data
            }
        }
    },
    mounted() {
        
    },
    created(){
    },
    props: {
        data: Object,
        timeStamp:String,
    },
    methods: {
        updateVisible(val) {
            this.visible = val
        },
        cancelSubmit(){
            this.updateVisible(false)
        },
        onSubmit(){
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.editForm.validate((valid) => {
                if (valid) {
                    const {
                        workingHours,
                        rushHour,
                        startLunchBreak,
                        endLunchBreak,
                        workInterval
                    } = this.form
                    let parm = {
                        workingHours,
                        rushHour,
                        startLunchBreak,
                        endLunchBreak,
                        workInterval
                    }
                    savePersonalInfo(parm,this.timeStamp).then(({res})=>{
                        this.submitSuccess()
                    })
                }
            })
        },
        submitSuccess() {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>