<!--
 * @Author: SixTeen
 * @Date: 2021-12-06 11:57:10
 * @LastEditTime: 2021-12-09 13:20:55
 * @LastEditors: Please set LastEditors
 * @Description: 添加量表
 * @FilePath: /recovery_management/src/views/scale/evaluationScheme/addScale.vue
-->

<template>
    <el-dialog
        width="70%"
        :title="'选择量表'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <searchForm
            ref="mainSearch"
            :searchoptions="searchOptions"
            :back-status="false"
            @search="search"
        />
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="getList"
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getListByNotIds, getScaleTypeList } from "@/api/reportClassify";

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {
        orgList: Array,
    },

    data() {
        return {
            checkId: "",
            tableData: [],
            dept: [], //部门数据
            searchOptions: [
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "scaleTypeId",
                    options: [],
                    label: "评定分类",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "请输入",
                    key: "scaleInfoName",
                    label: "量表名称",
                    defaultVal: "",
                },
            ],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "评定分类", // 必填
                        prop: "sctyName", // 必填
                    },
                    {
                        label: "量表名称", // 必填
                        prop: "scaleInfoName", // 必填
                    },
                ],
            },
            total: 0,
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: "", //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.getList();
            }
        },
    },
    created() {
        this.getScaleTypeList();
    },
    mounted() {},
    methods: {
        /**
         * @description: 获取评定分类
         * @param {*}
         * @return {*}
         */
        getScaleTypeList() {
            const param = {};
            getScaleTypeList(param).then(({ res, timeStamp }) => {
                this.typeOptions = res.map((element) => {
                    const ele = {
                        value: element.id,
                        label: element.name,
                    };
                    return ele;
                });
                this.searchOptions[0].options = this.typeOptions;
            });
        },
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },

        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getListByNotIds(param).then(({ res, timeStamp }) => {
                    const { total, records } = res;
                    this.tableData = records;
                    this.total = parseInt(total);
                    this.timeStamp = timeStamp;
                });
            });
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const idss = {
                idss: this.orgList.map((item) => item.scaleInfoId).join(),
            };
            const param = Object.assign(
                {},
                this.paramObj,
                page,
                paramObj,
                idss
            );
            this.paramObj = param;
            return param;
        },
        /**
         * @description: 确定 回传
         * @param {*}
         * @return {*}
         */
        submit() {
            if (this.$refs.mainTable.getChecked().length > 0) {
                const data = this.$refs.mainTable.getChecked();
                const org = this.orgList.concat(data);
                this.updateVisible(false);
                this.$emit("submit", org);
            } else {
                this.$message("请选择患者");
            }
        },
        /**
         * @description: 关闭
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            if (!val) {
                this.$refs.mainSearch.resetForm();
            }
            this.visible = val;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>