/*
 * @Author: your name
 * @Date: 2022-01-18 11:29:11
 * @LastEditTime: 2022-02-07 11:00:54
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\config\tablePrintStyle.js
 */
import { noticeDate } from '@/styles/variables.scss'
const borderStyle1 = `1px solid ${noticeDate}`
// 打印表格带边框样式
export const tableBorder = `
    table td input {
        border: none;
    }
    td, th {
        padding: 5px 10px;
    }
    table.el-table__header {
        border: ${borderStyle1}
    }
    table.el-table__header tr:not(:last-of-type) th {
        border-bottom: ${borderStyle1}
    }
    tr th:not(:first-of-type) {
        border-left: ${borderStyle1}
    }
    
    .el-table__body tr td:first-of-type{
        border-left: ${borderStyle1}
    }
    .el-table__body tr td{
        border-right: ${borderStyle1}
    }
    .el-table__body tr:not(:first-of-type) td{
        border-top: ${borderStyle1}
    }
    .el-table__body tr:last-of-type td{
        border-bottom: ${borderStyle1}
    }`