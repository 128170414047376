
<!--
* @Description: 患者医嘱页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto" style="padding-top:10px">
      <div style="display:flex">
        <el-descriptions class="margin-top" title="基本信息" :column="4">
          <el-descriptions-item label="患者姓名">{{
            paitentInfo.name || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="住院号">{{
            paitentInfo.recordNum || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="就诊号">{{
            paitentInfo.serialNum || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{
            paitentInfo.sex === "0" ? "男" : "女"
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            paitentInfo.phone || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="所属科室">{{
            paitentInfo.deptName || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="床号">{{
            paitentInfo.bedNum || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="主治医生">{{
            paitentInfo.impDocName || commonConfig.$nullData
          }}</el-descriptions-item>
          <el-descriptions-item label="入院/就诊时间">{{
            paitentInfo.heDte
              ? moment(paitentInfo.heDte).format("YYYY-MM-DD")
              : commonConfig.$nullData
          }}</el-descriptions-item>
        </el-descriptions>
        <div>
            <el-button style="margin-top:20px" @click="back()">返回</el-button>
        </div>
      </div>
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group">
        <el-button v-if="addAdvice" type="primary" @click="toInsert(1)">新增医嘱</el-button>
        <el-button v-if="addSet" @click="toInsert(2)">新增组套</el-button>
        <el-button v-if="printAdviceApply" @click="toPrint()">打印医嘱申请单</el-button>
      </el-row>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <!-- <template slot="status" slot-scope="scope">
          <el-button v-if="scope.data.row.status==3" disabled type="text">已删除</el-button>
          <div class="textBox">
            <div v-if="scope.data.row.status==2" class="stopText">停用</div>
            <div v-if="scope.data.row.status==0||scope.data.row.status==1" class="enableText">启用</div>
          </div>
        </template> -->
        <template slot="handle" slot-scope="scope">
          <el-button v-if="adviceListDetail" type="text" @click="toDetail(scope)">详情</el-button>
          <el-button v-if="scope.data.row.status==2" type="text" @click="toChangeStatus(scope)">启用</el-button>
          <el-button v-if="scope.data.row.status==0||scope.data.row.status==1" type="text" @click="toChangeStatus(scope)">停用</el-button>
          <el-button v-if="scope.data.row.doctorOrderType==2&&scope.data.row.status!=3" type="text" @click="toInvalid(scope)">废弃</el-button>
          <el-button v-if="adviceListDel&&scope.data.row.status!=3" type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog :basicInfoId="basicInfoId" ref="insert" @submit="submitSuccrss" :insertType="insertType"/>
    </el-main>
    <div style="display: none">
      <div id="printMe" ref="printMe">
        <h2 align="center">{{hospitalName}}</h2>
        <h3 align="center">治疗申请单</h3>
        <p class="pStyle">
          <span>姓名：{{paitentInfo.name || commonConfig.$nullData}}</span>
          <span>性别：{{paitentInfo.sex === "0" ? "男" : "女"}}</span>
          <span>年龄：{{paitentInfo.age}}</span>
          <span>手机号：{{paitentInfo.phone || commonConfig.$nullData}}</span>
        </p>
        <p class="pStyle">
          <span>住院号：{{paitentInfo.recordNum || commonConfig.$nullData}}</span>
          <span>就诊号：{{paitentInfo.serialNum || commonConfig.$nullData}}</span>
          <span>主治医生：{{paitentInfo.impDocName || commonConfig.$nullData}}</span>
          <span></span>
        </p>
        <p>
          <span>疾病诊断：{{paitentInfo.diagnosisJoinStr || commonConfig.$nullData}}</span>
        </p>
        <generalTable
          ref="printTable"
          :table-data="tablePrintData"
          :config="tablePrintConfig"
        >
        </generalTable>
      </div>
    </div>
  </el-container>
</template>

<script>
import { getList, deleteData, ChangeStatus, invalidAdvice } from "@/api/advice";
import { getPatientsSimpleBasicRes } from "@/api/patient";
import { print } from "@/utils/print";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/advice/addDialog";
import detailDialog from "@/views/advice/detailDialog";
import moment from "moment";

export default {
  name: "Advice",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      basicInfoId: this.$route.query.id,
      paitentInfo: {},
      tableData: [],
      searchOptions: [],
      tableConfig: {
        id: "advice",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        column: [
          {
            label: "所属治疗", // 必填
            prop: "operType", // 必填
          },
          {
            label: "操作项目名称", // 必填
            prop: "operName", // 必填
          },
          {
            label: "医嘱类型", // 必填
            prop: "doctorOrderType", // 必填
            formatter: (row, column) => {
              return ["", "长期医嘱", "限定医嘱"][row.doctorOrderType];
            },
          },
          {
            label: "总次数", // 必填
            prop: "createCount", // 必填
            formatter: (row, column) => {
              if ("1" === row.doctorOrderType) {
                return "长期";
              } else {
                return row.createCount;
              }
            },
          },
          {
            label: "单次数量", // 必填
            prop: "schCount", // 必填
          },
          {
            label: "治疗频次", // 必填
            prop: "frequencyCount", // 必填
            formatter: (row, column) => {
              return (
                row.frequencyCount +
                "次/" +
                row.frequencyTime +
                ["天", "周", "月", "年"][row.frequencyUnit]
              );
            },
          },
          {
            label: "执行次数", // 必填
            prop: "usedCount", // 必填
          },
          {
            label: "状态", // 必填
             prop: "status", // 必填
            formatter: (row, column) => {
              return (
                ["启用", "使用中", "停用", "已删除"][row.status]
                // row.frequencyCount +
                // "次/" +
                // row.frequencyTime +
                // ["天", "周", "月", "年"][row.frequencyUnit]
              );
            },
          },
          {
            label: "创建时间", // 必填
            prop: "adviceDate", // 必填
            width: "150", // 不必填
            formatter: (row, column) => {
              return (moment(row.adviceDate).format("YYYY-MM-DD HH:mm:ss"));
            },
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "190", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      adviceListDetail:true,//详情权限
      adviceListDel:true,//修改权限
      insertType:1,
      addAdvice:true,
      addSet:true,
      printAdviceApply:true,
      hospitalName: "",
      tablePrintData:[],
      tablePrintConfig: {
        id: "printAdvice",
        align: "center", // 不必填 默认为center
        index: true, // 不必填 序号
        column: [
          {
            label: "所属治疗", // 必填
            prop: "operType", // 必填
            width:100
          },
          {
            label: "操作项目名称", // 必填
            prop: "operName", // 必填
            width:200
          },
          {
            label: "医嘱类型", // 必填
            prop: "doctorOrderType", // 必填
            width:100,
            formatter: (row, column) => {
              return ["", "长期医嘱", "限定医嘱"][row.doctorOrderType];
            }
          },
          {
            label: "治疗频次", // 必填
            prop: "frequencyCount", // 必填
            width:100,
            formatter: (row, column) => {
              return (
                row.frequencyCount +
                "次/" +
                row.frequencyTime +
                ["天", "周", "月", "年"][row.frequencyUnit]
              );
            }
          },
          {
            label: "创建时间", // 必填
            prop: "adviceDate", // 必填
            width:200,
            formatter: (row, column) => {
              return (moment(row.adviceDate).format("YYYY-MM-DD HH:mm:ss"));
            }
          }

        ]
      }
    };
  },
  created() {
    this.adviceListDetail = this.hasPermission('advice-list-detail')
    this.adviceListDel = this.hasPermission('advice-list-del')
    this.addAdvice = this.hasPermission('advice-list-add-advice')
    this.addSet = this.hasPermission('advice-list-add-set')
    this.printAdviceApply = this.hasPermission('advice-list-print-adviceApply')
    this.initPatientInfo();
    this.getList();
    this.setHospitalName();
  },
  mounted: function () {},
  methods: {
    setHospitalName(){
      this.$store.dispatch('getHosInfo').then(res => {
        this.hospitalName = res.hospitalName
      })
    },
    toPrint(){
      this.tablePrintData = this.tableData.filter(o => {
        return o.status==0||o.status==1
      })
      this.$nextTick(()=>{
        print('printMe',`.pStyle{
          display: flex;
          justify-content: space-between;
        }
        .pStyle span{
          width:25%
        }
        table tr td,th { border-collapse: collapse;border:1px #dedede solid;text-align:center;font-size:12px;color:#666;line-height:28px }`)
      })
    },
    toInvalid(scope) {
      this.$prompt("您的最大废弃次数为"+scope.data.row.liveCount+"次,请输入废弃次数", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType:"number",
        inputValidator:(value)=>{
          if(value<=scope.data.row.liveCount&&value>0){
            return true;
          }else if(value<=0){
            return "最小废弃次数为1次"
          }else{
            return "最大废弃次数为"+scope.data.row.liveCount+"次"
          }
        },
      }).then(({ value }) => {
        const param = {
          id: scope.data.row.id,
          invalidCount:value,
        };
        invalidAdvice(param, this.timeStamp).then(({ res }) => {
          const {$handleSuccess } = this.commonConfig;
          this.$message({...$handleSuccess});
          this.submitSuccrss()
        });
      })
    },
    initPatientInfo() {
      getPatientsSimpleBasicRes({ id: this.basicInfoId }).then(
        ({ res, timeStamp }) => {
          this.paitentInfo = res;
        }
      );
    },
        /**
     * @description: 返回按钮
     * @param {*}
     * @return {*}
     */
    back() {
      this.$router.back();
    },
    submitSuccrss(){
      this.getList()
    },
    getList() {
      this.$nextTick(() => {
        getList({ basicInfoId: this.basicInfoId }).then(
          ({ res, timeStamp }) => {
            this.tableData = res;
            this.timeStamp = timeStamp;
          }
        );
      });
    },
    toInsert(type) {
      this.insertType = type
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toChangeStatus(scope) {
      let msg = ""
      if(scope.data.row.status==2){
        msg = "是否启用该医嘱"
      }else{
        msg = "是否停用该医嘱"
      }
      const { $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig;
      this.$confirm(msg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        ChangeStatus(param, this.timeStamp).then(({ res }) => {
          this.$message({...$handleSuccess});
          this.submitSuccrss()
        });
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({...$handleSuccess});
          this.submitSuccrss()
        });
      });
    },
    getParam() {
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.textBox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.stopText{
  width: 40px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $tableColor;
  border-radius: 5px;
  background-color: $disable;
  color:$arrowActive;

}
.enableText{
  width: 40px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $theme;
  border-radius: 5px;
  background-color: $active;
  color:$theme;
  font-size: 12px;
}
.buttonStyle{
  float: right;
  margin-right: 10px;
}
</style>
