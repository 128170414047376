
/**
 * 页面: 治疗项目创收统计
 * pageName: createIncome
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗项目创收统计列表数据
 */
export function getList(data) {
    return request({
        url: '/statistics/createIncomeStatistics/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

