/* eslint-disable no-undef */ /* eslint-disable no-undef */
<!--
 * @Description:表格搜索表单
 * @Author: yzw
 * @Date: 2021-08-11 15:34:45
 * @LastEditTime: 2023-02-14 11:13:18
 * @LastEditors: wangly wly574523605@163.com
 * @Reference:
-->

<template>
    <el-form ref="searchForm" :model="searchData" :inline="true" class="searchForm">
        <template v-for="item in searchoptions">
            <el-form-item :key="item.key" :label="item.label + ':'" :prop="item.key">
                <el-input
                    v-if="item.type === 'input'"
                    v-model="searchData[item.key]"
                    :placeholder="item.placeholder || '请输入...'"
                    clearable
                    :maxlength="item.maxlength || 20"
                />
                <el-select
                    v-if="item.type === 'select'"
                    v-model="searchData[item.key]"
                    :clearable="(item.clearable + '') === 'undefined' ? true : item.clearable"
                    :placeholder="item.placeholder || '请选择...'"
                    :filterable="item.filterable || true"
                    :multiple="item.multiple || false"
                    :collapse-tags="item.multiple || false"
                    @change="(e) => {changSelect(item.changeTrigger, item.key, e)}"
                >
                    <el-option
                        v-for="option in item.options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>
                <el-date-picker
                    v-if="item.type === 'datetimerange'"
                    v-model="searchData[item.key]"
                    type="datetimerange"
                    :clearable="true"
                    :picker-options="item.datetimePickerOptions || commonConfig.$datetimePickerOptions"
                    :default-time="['00:00:00', '23:59:59']"
                    range-separator="至"
                    :start-placeholder="item.startPlaceholder || '开始时间'"
                    :end-placeholder="item.endPlaceholder || '结束时间'"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :editable="false"
                />
                
                <el-date-picker
                    v-if="item.type === 'date'"
                    v-model="searchData[item.key]"
                    type="date"
                    :picker-options="item.datetimePickerOptions || commonConfig.$datetimePickerOptions"
                    :placeholder="item.placeholder || '请选择日期'"
                    :editable="false"
                />
                <el-date-picker
                    v-if="item.type === 'daterange'"
                    v-model="searchData[item.key]"
                    type="daterange"
                    :picker-options="item.datetimePickerOptions || commonConfig.$datetimePickerOptions"
                    range-separator="至"
                    :start-placeholder="item.startPlaceholder || '开始日期'"
                    :end-placeholder="item.endPlaceholder || '结束日期'"
                    value-format="yyyy-MM-dd"
                    :editable="false"
                />
                <el-time-picker
                    v-if="item.type === 'timerange'"
                    v-model="searchData[item.key]"
                    is-range
                    range-separator="至"
                    :start-placeholder="item.startPlaceholder || '开始时间'"
                    :end-placeholder="item.endPlaceholder || '结束时间'"
                    value-format="HH:mm:ss"
                    :editable="false"
                />
                <el-cascader
                    v-if="item.type === 'cascader'"
                    v-model="searchData[item.key]"
                    :options="item.options"
                    :props="{
                        checkStrictly: true,
                        ...item.props
                    }"
                    clearable
                    filterable
                />
            </el-form-item>
        </template>
        <div class="search-btn-group">
            <el-form-item>
                <el-button type="primary" @click="searchForm">
                    查询
                </el-button>
            </el-form-item>
            <el-form-item v-if="exportStatus">
                <el-button type="primary" plain @click="exportFun()">导出</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="resetForm">
                    重置
                </el-button>
            </el-form-item>
            <el-form-item v-if="backStatus" style="margin-right: 0">
                <el-button type="default" @click="back()">返回</el-button>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'SearchForm',
    remark: '搜索组件,用于表格头部',
    props: {
        searchoptions: {
            type: Array,
            required: true
        },
        backStatus: {
            type: Boolean
        },
        exportStatus: {
            type: Boolean,
            defalut: false
        }
    },
    data() {
        return {
            searchData: {}
        }
    },
    created() {
        // 默认值带入
        const paramData = {}
        _.map(this.searchoptions, item => {
            if (item.defaultVal !== null && item.defaultVal !== undefined) {
                paramData[item.key] = item.defaultVal
            }
        })
        this.searchData = paramData
    },
    methods: {
        searchForm() {
            const data = this.packageData()
            console.log(`searchForm:`, data)
            this.$emit('search', data)
        },
        changSelect(changeTrigger, key, e){
            if (changeTrigger) {
                if (this.$listeners[key + 'Change']) {
                    this.$emit(key + 'Change', e);
                }
            }
        },
        // 封装数据
        packageData() {
            const data = {}
            // 深拷贝对象
            // 日期、时间范围
            _.defaultsDeep(data, this.searchData)
            const dateList = _.filter(this.searchoptions, o => {
                return ['datetimerange', 'daterange', 'timerange'].includes(o.type)
            })
            // 单日期
            const date = _.filter(this.searchoptions, o => {
                return ['date'].includes(o.type)
            })
            // 字段默认为 key + startTime & key + endTime
            _.map(dateList, item => {
                const key = item.key
                // 日期区间，从开始日期的0时0分0秒~结束日期的23时59分59秒
                if (data[key] && item.type === "daterange") {
                    const startDate = this.moment(data[key][0] || '').format("YYYY-MM-DD")
                    const endDate = this.moment(data[key][1] || '').format("YYYY-MM-DD")
                    data[`${key}StartTime`] = data[key][0] ?  this.moment(`${startDate} 00:00:00`).valueOf():''
                    data[`${key}EndTime`] = data[key][1] ? this.moment(`${endDate} 23:59:59`).valueOf() : ''
                } else if (data[key]){
                    data[`${key}StartTime`] = data[key][0] ? this.moment(data[key][0]).valueOf() : ''
                    data[`${key}EndTime`] = data[key][1] ? this.moment(data[key][1]).valueOf() : ''
                } else {
                    data[`${key}StartTime`] = ''
                    data[`${key}EndTime`] = ''
                }
                delete data[key]
            })
            _.map(date, item => {
                const key = item.key
                data[key] = data[key] ? this.moment(data[key]).valueOf() : this.moment().valueOf()
            })
            // }
            return data
        },
        updataSearchData(searchData){
            this.searchData = Object.assign({}, searchData)
        },
        resetForm() {
            // this.$refs['searchForm'].resetFields()
            const resetData = {}
            const searchOpts = this.searchoptions
            _.forIn(this.searchData, function(value, key) {
                const filterObj = _.filter(searchOpts, o => {
                    return o.key === key
                })
                resetData[key] = filterObj[0].defaultVal
            })
            this.searchData = resetData
            console.log(this.searchData)
            const data = this.packageData()
            this.$emit('search', data)
        },
        exportFun() {
            if (this.$listeners['exportFun']) {
                this.$emit('exportFun')
            }
        },
        back() {
            if (this.$listeners['customBack']) {
                this.$emit('customBack')
            } else {
                this.$router.back()
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
