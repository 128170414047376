<!--
 * @Author: yzw
 * @Date: 2021-12-20 10:54:25
 * @LastEditTime: 2022-07-28 15:50:50
 * @LastEditors: ZF-WLY
 * @Description: 
-->

<!--
* @Description: 患者评定详情页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <div style="display: flex; margin: 10px 0 5px 0">
                <el-descriptions class="margin-top" :column="4">
                    <el-descriptions-item label="患者姓名">
                        {{ details.name || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="details.status == 1" label="床号">
                        {{ details.bedNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">{{ commonConfig.$sexList[details.sex] }}</el-descriptions-item>
                    <el-descriptions-item label="年龄">{{ details.age }}</el-descriptions-item>
                    <el-descriptions-item v-if="details.status == 1" label="住院号">
                        {{ details.recordNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="details.status == 2" label="就诊号">
                        {{ details.serialNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag != 1" label="主治医生">
                        {{ details.impDocName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="疾病诊断">
                        {{ details.diagnosisJoinStr || commonConfig.$nullData }}
                    </el-descriptions-item>
                </el-descriptions>
                <div style="display: flex; align-items: center; margin-bottom: 5px">
                    <el-button type="default" @click="back()">返回</el-button>
                </div>
            </div>
        </el-header>
        <el-tabs
            v-model="tabValue"
            type="border-card"
            style="width: 100%;height:calc(100vh - 176px)"
            @tab-click="tabChange"
            value="dailyAssessment"
        >
            <el-tab-pane :lazy="true" label="日常评估" name="dailyAssessment" v-if="dailyAssessment">
                <dailyAssessment :basicInfoId="basicInfoId" v-if="tabValue === 'dailyAssessment'" />
            </el-tab-pane>
            <el-tab-pane :lazy="true" :label="assessHistoryName" name="assessHistory" v-if="assessHistory">
                <assessHistory :basicInfoId="basicInfoId" v-if="tabValue === 'assessHistory'" @refreshData="getDetailById"/>
            </el-tab-pane>
            <el-tab-pane
                style="height:100%"
                :lazy="true"
                label="评估报告"
                name="appraisalReport"
                v-if="appraisalReport"
            >
                <appraisalReport :basicInfoId="basicInfoId" v-if="tabValue === 'appraisalReport'" />
            </el-tab-pane>
            <el-tab-pane style="height:100%"  :lazy="true" label="评估分析" name="evaluationAnalysis" v-if="evaluationAnalysis">
                <evaluationAnalysis :basicInfoId="basicInfoId" v-if="tabValue === 'evaluationAnalysis'" />
            </el-tab-pane>
        </el-tabs>
        <patient360 width="700px" height="500px" :id="archivesInfoId" :basicInfoId="basicInfoId" />
    </el-container>
</template>

<script>
import { scaleNavigation } from '@/api/patientAssessment'
import patient360 from '@/views/other/Patient360'
import dailyAssessment from '@/views/scale/patientAssessment/dailyAssessment.vue'
import assessHistory from '@/views/scale/patientAssessment/assessHistory.vue'
import appraisalReport from '@/views/scale/patientAssessment/appraisalReport.vue'
import evaluationAnalysis from '@/views/scale/patientAssessment/evaluationAnalysis.vue'

export default {
    name: 'PatientAssessmentDetail',
    components: {
        dailyAssessment,
        assessHistory,
        appraisalReport,
        evaluationAnalysis,
        patient360
    },
    data() {
        return {
            details: {}, //患者信息
            backStatus: true, //返回按钮的隐藏显示
            basicInfoId: this.$route.query.id,
            archivesInfoId: this.$route.query.archivesInfoId,
            tabValue: '',
            timeStamp: '',
            assessHistoryName: '评估历史',
            dailyAssessment: true, //日常评估权限
            assessHistory: true, //评估历史
            appraisalReport: true, //评估报告
            evaluationAnalysis: true //评估分析
        }
    },
    created() {
        this.getDetailById()
        this.dailyAssessment = this.hasPermission('scale-dailyAssessment')
        this.assessHistory = this.hasPermission('scale-assessHistory')
        this.appraisalReport = this.hasPermission('scale-appraisalReport')
        this.evaluationAnalysis = this.hasPermission('scale-evaluationAnalysis')
    },
    mounted: function() {},
    methods: {
        /**
         * @description: 获取详情信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}患者信息
         */
        getDetailById() {
            const parm = { basicInfoId: this.basicInfoId }
            scaleNavigation(parm).then(({ res, timeStamp }) => {
                this.details = res
                this.timeStamp = timeStamp
                if (res.allScaleNumber != res.completeScaleNumber && this.assessHistory) {
                    this.tabValue = 'assessHistory'
                } else {
                    if (!this.dailyAssessment) {
                        if (!this.appraisalReport) {
                            this.evaluationAnalysis && (this.tabValue = 'evaluationAnalysis')
                        } else {
                            this.tabValue = 'appraisalReport'
                        }
                    } else {
                        this.tabValue = 'dailyAssessment'
                    }
                }
                this.assessHistoryName = `评估历史 (${res.completeScaleNumber}/${res.allScaleNumber})`
            })
        },
        /**
         * @description: 返回按钮
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back()
        },
        tabChange(event) {
            this.tabValue = event.name
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
