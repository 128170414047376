<!--
 * @Author: SixTeen
 * @Date: 2021-11-18 10:56:35
 * @LastEditTime: 2021-12-29 13:31:12
 * @LastEditors: yzw
 * @Description: 关联治疗师详情
 * @FilePath: /recovery_management/src/views/treatment/distribution/detailDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="关联治疗师详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="getUserRelList"
        >
            <template slot-scope="scope" slot="handle">
                <el-button
                    type="text"
                    v-if="delStatus"
                    class="delBtn"
                    @click="toDel(scope)"
                    >删除</el-button
                >
            </template>
        </generalTable>
        <template slot="footer" class="dialog-footer">
            <el-button  @click="updateVisible(false)"
                >取 消</el-button
            >
        </template>
    </el-dialog>
</template>
<script>
import { getUserRelList, deleteUserRelData } from "@/api/distribution";
import generalTable from "@/components/Table";

export default {
    components: {
        generalTable,
    },
    props: {
        basicInfoId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tableData: [], // 详情数据
            total: 0, // 总条数
            visible: false,
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "所属机构", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "岗位", // 必填
                        prop: "position", // 必填
                    },
                    {
                        label: "姓名", // 必填
                        prop: "name", // 必填
                    },
                    {
                        label: "操作", // 必填
                        slot: "handle", // 必填
                    },
                ],
            },
            delStatus: true, // 删除按钮权限
            timeStamp: "",
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.basicInfoId) this.getUserRelList();
        },
    },
    created() {
        //删除权限
        this.delStatus = this.hasPermission("distribution_del");
        this.getUserRelList();
    },
    mounted() {},
    methods: {
        /**
         * @description: 获取关联列表
         * @param {*}
         * @return {*}
         */

        getUserRelList() {
            const parm = { basicInfoId: this.basicInfoId };
            getUserRelList(parm).then(({ res, timeStamp }) => {
                this.tableData = res;
                this.timeStamp = timeStamp;
            });
        },

        /**
         * @description: 删除
         * @param {*} scope 用户
         * @return {*}
         */
        toDel(scope) {
            const parm = {
                basicInfoId: this.basicInfoId.split(), //患者id，[]
                userId: scope.data.row.id.split(), //用户id，
            };
            deleteUserRelData(parm, this.timeStamp).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getUserRelList();
            });
        },

        /**
         * @description: 取消关闭
         * @param {*} flag
         * @return {*}
         */
        updateVisible(flag) {
            this.$emit("refresh");
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang="scss"></style>
