/*
 * @LastEditors: wangly wly574523605@163.com
 * @Author: yzw
 */
import router, {constantRoutes} from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import Cookies from 'js-cookie'
import commonConfig from './config/common'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const {$whiteList: whiteList} = commonConfig

function logout() {
  store.dispatch('logout')
}

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // clearPending()
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const roles = store.getters.roles
      const toDeepth = to.path.split('/').length
      const fromDeepth = from.path.split('/').length
      if (toDeepth < fromDeepth) {
        from.meta.keepAlive = false
        to.meta.keepAlive = true
      }

      if (roles.length > constantRoutes.length) {
        next()
      } else {
        store.dispatch('getInfo').then((res) => {
          // console.log('ROUTER-----------------------', router.options.routes)
          store.dispatch("getUnReadMsgList");
          next(to.fullPath)
        }).catch(e => {
          console.error('当前用户无权限', e)
          logout()
          next("/login");
        })
      }
    }
  } else {
    const { menuhide, publicKey, unique } = to.query
    if (menuhide === "true" && publicKey && unique) {
      console.log("------permission")
      const token = {
        publicKey: decodeURIComponent(publicKey),
        unique: decodeURIComponent(unique)
      }
      setToken(token)
      let timer = setInterval(() => {
        let token = Cookies.get('user_token')
        if (token) {
          let per_token = getToken()
          clearInterval(timer)
          console.log("after get token")
          next()
          NProgress.done()
        }
      }, 100)
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
