/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2022-12-09 13:50:00
 * @LastEditors: ZF-WLY
 * @Reference:
 */
import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/theme/index.css'

import moment from 'moment'
import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control

import commonConfig from '@/config/common'
import { hasPermission, valToLabel } from '@/utils/common'
import tableHeight from '@/utils/tableHeight'
window.lodash = require('lodash')

Vue.prototype.commonConfig = commonConfig
Vue.prototype.hasPermission = hasPermission
Vue.prototype.moment = moment
Vue.prototype.valToLabel = valToLabel

// const screenWidth = document.documentElement.clientWidth
const elementConfig = { zIndex: 3000, size: 'mini' }
// if (screenWidth >= 1920) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1680) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1600) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1440) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1366) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1280) {
// 	elementConfig.size = 'mini'
// } else if (screenWidth >= 1024) {
// 	elementConfig.size = 'mini'
// } else {
// 	elementConfig.size = 'mini'
// }

Vue.use(tableHeight)
Vue.use(ElementUI, elementConfig)
Vue.config.productionTip = false

new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App)
})
