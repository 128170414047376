<!--
 * @Author: ZF-WLY
 * @Date: 2021-03-09 16:54:06
 * @LastEditTime: 2022-07-28 15:48:27
 * @LastEditors: ZF-WLY
 * @Description: 组织机构管理页面，根据tab切换至列表视图或树层级视图
 * @FilePath: \recovery_management\src\views\system\organization\index.vue
-->

<template>
    <el-container>
        <el-tabs v-model="activeName" type="border-card" style="width:100%" @tab-click="changeTab">
            <el-tab-pane :lazy="true" label="机构管理" name="organization">
                <el-main class="basic-main">
                    <el-form class="searchForm" :inline="true">
                        <div class="search-btn-group">
                            <el-form-item>
                                <el-button type="primary" v-if="addStatus" @click="toInsert()">新增</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="default" @click="back()">返回</el-button>
                            </el-form-item>
                        </div>
                    </el-form>
                    <generalTable
                        ref="mainTable"
                        :table-data="tableData"
                        :config="tableConfig"
                        :total="total"
                        @updatePage="getTableData"
                    >
                        <!-- 点击姓名展示详情 -->
                        <template slot="name" slot-scope="scope">
                            <a class="clickCell" @click="toDetail(scope.data.row)">{{ scope.data.row.name }}</a>
                        </template>
                        <!-- 切换禁用/启用状态 设置机构禁用/启用 -->
                        <template slot="status" slot-scope="scope">
                            <el-switch
                                v-model="scope.data.row.status"
                                :disabled="scope.data.row.id === '0' || !organizationStatus"
                                inactive-value="1"
                                active-value="0"
                                @change="setStatus(scope.data.row)"
                            />
                        </template>
                        <template slot="handle" slot-scope="scope">
                            <el-button type="text" v-if="editStatus" @click="edit(scope.data.row)">编辑</el-button>
                            <el-button type="text" v-if="addChildStatus" @click="insertChild(scope.data.row)">新增子级</el-button>
                            <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope)">删除</el-button>
                        </template>
                    </generalTable>
                </el-main>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="组织架构树" name="organizationTree">
                <div class="treeBox">
                    <el-row type="flex" justify="center">
                        <tree :chart-data="treeData" :width="treeWidth" :height="treeHeight" />
                    </el-row>
                </div>
                
            </el-tab-pane>
        </el-tabs>
        <!-- 添加/编辑组织机构弹出层 -->
        <addDialog
            ref="insert"
            :detail-data="detailData"
            :table-data="tableData"
            :dept-type="deptType"
            :insert-type="insertType"
            :time-stamp="timeStamp"
            @submit="getTableData"
        />
        <!-- 机构详情弹出层 -->
        <detailDialog
            ref="detail"
            :detail-data="detailData"
            :table-data="tableData"
            :change-dept-type="changeDeptType"
        />
    </el-container>
</template>

<script>
import moment from 'moment' // moment插件
import {
    getOrgList, // 获取组织机构列表
    orgDel, // 删除机构
    orgDisable, // 禁用/启用机构
    getDeptTree // 获取组织机构树
} from '@/api/organization'
import generalTable from '@/components/Table' // table插件
import {
    getDict // 获取字典表
} from '@/api/common'
import tree from '@/components/tree' // 树插件
import addDialog from '@/views/system/organization/addDialog' // 添加/编辑组织机构
import detailDialog from '@/views/system/organization/detailDialog' // 机构详情

import {
    text,
    menuText,
    orgtreeBg,
    orgColorList
} from '@/styles/variables.scss'
const cannotChangeMsg = '顶层机构信息不可修改!'
export default {
    name: 'Organization',
    components: {
        generalTable,
        tree,
        addDialog,
        detailDialog
    },
    data() {
        return {
            activeName: 'organization', // 当前激活的tab页名称
            tableData: [], // 表格数据
            total: 0, // 列表总数，虽然表格不分页，但是total参数不能为空，只是不影响列表展示
            tableConfig: {
                id: 'organization',
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple 单选: single 默认为 normal
                expandOpen: true, // 默认展开全部
                column: [
                    {
                        label: '姓名', // 必填
                        slot: 'name'
                    },
                    {
                        label: '机构类型', // 必填
                        prop: 'deptType', // 必填
                        formatter: (row, column) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.deptType.filter(
                                (type) => type.value === row.deptType
                            )
                            if (currentType.length) {
                                return currentType[0].label
                            }
                        },
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        label: '上级机构', // 必填
                        prop: 'parentName', // 必填
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        label: '禁用/启用', // 必填
                        slot: 'status' // 必填
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            // 将时间戳格式化为YYYY-MM-DD格式
                            return row.createDate
                                ? moment(row.createDate).format('YYYY-MM-DD')
                                : '--'
                        },
                        sortable: false, // 非必填 默认为true
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列 left right
                        width: '220' // 不必填
                    }
                ]
            },
            detailData: {
                // 详细信息
                name: '', // 机构名称
                remark: '', // 备注
                parentId: '', // 父级机构id
                deptType: '', // 机构类型
                sort: '', // 排序
                deptHeads: '', // 负责人
                deptHeadsPhone: '', // 电话
                id: '' // 机构id
            },
            insertType: true, // 标识新增/编辑机构，为true时新增，为false时编辑
            deptType: [], // 机构类型列表
            changeDeptType: [], // 机构类型列表映射--> changeDeptType[value]为对应label值
            treeData: {}, // 组织机构树数据
            backgroundColorList: [orgColorList][0].split(','),
            addUserFlag: false,
            detailFlag: false,
            timeStamp: '',
            addStatus: true, // 新增按钮权限
            editStatus: true, // 修改按钮权限
            delStatus: true, // 删除按钮权限
            organizationStatus: true, // 机构状态权限
            addChildStatus: true, // 启用/禁用状态权限
            treeWidth:"100%",
            treeHeight:"600px"
        }
    },
    /** 页面创建周期函数
     * @description: 获取机构类型字典项，获取组织架构树数据，获取组织机构数据
     * @param {*}
     * @return {*}
     */
    created() {
        this.addStatus = this.hasPermission('organization-add')
        this.editStatus = this.hasPermission('organization-edit')
        this.delStatus = this.hasPermission('organization-del')
        this.organizationStatus = this.hasPermission('organization-status')
        this.addChildStatus = this.hasPermission('organization-insert-child')
        this.getTypeDict()
        this.getTableData()
        this.loadTree()
    },
    mounted() {},
    methods: {
        /** 判断该机构是否为顶层机构
         * @description: 如果为顶层机构，则进行消息提示，并返回true；否则返回false
         * @param {String} id 机构id
         * @return {Boolean} 顶层机构返回true，其余机构返回false
         */
        ifTopOrg(id) {
            if (id === '0') {
                this.$message({
                    message: cannotChangeMsg,
                    type: 'warning'
                })
                return true
            }
            return false
        },
        /**
         * 添加机构
         * @description: 将insertType设置为true以匹配添加弹出层
         * @param {*}
         * @return {*}
         */
        toInsert() {
            this.insertType = true
            this.detailData = {}
            this.$refs.insert.updateVisible(true)
        },
        /**
         * 删除机构
         * @description: 传入机构id调用删除机构函数
         * @param {Object} scope 插槽数据，scope.data.row.id为对应机构id
         * @return {*}
         */
        toDel(scope) {
            if (this.ifTopOrg(scope.data.row.id)) {
                return false
            }
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                this.delOrg(scope.data.row.id)
            })
        },
        /**
         * 切换tab
         * @description: 通过切换tab页，切换展示内容；机构管理为表格列表、组织架构树为树形展示列表
         * @param {Object} tab 该对象中的name用于绑定显示对应组件；展示树组件时，需要获取组织机构树数据
         * @return {*}
         */
        changeTab(tab) {
            if (tab.name === 'organizationTree') {
                this.loadTree()
            }
        },
        /**
         * 设置树样式
         * @description:根据传入的数据设置树样式
         * @param {Array} oldArr 传入的组织机构树数据
         * @return {*}
         */
        setTree(oldArr) {
            if (oldArr && oldArr.length > 0) {
                const newObj = oldArr.map((item, index) => {
                    return {
                        label: {
                            backgroundColor: orgtreeBg,
                            borderRadius: [5, 5, 5, 5],
                            height: 40,
                            formatter:
                                '{first|' +
                                item.name +
                                '}{second|' +
                                item.userCount +
                                '人}',
                            rich: {
                                first: {
                                    backgroundColor: this.backgroundColorList[
                                        item.deptType
                                    ],
                                    color: menuText,
                                    align: 'center',
                                    minWidth: 100,
                                    height: 40,
                                    borderRadius: [5, 0, 0, 5],
                                    padding: [0, 15, 0, 15]
                                },
                                second: {
                                    color: text,
                                    align: 'center',
                                    lineHeight: 17,
                                    padding: [0, 5, 0, 5]
                                }
                            }
                        },
                        children: this.setTree(item.children)
                    }
                })
                return newObj
            } else {
                return []
            }
        },
        /**
         * 加载树
         * @description:格局机构树数据及样式，加载组织机构树
         * @param {*}
         * @return {*}
         */
        loadTree() {
            getDeptTree().then(({ res }) => {
                const treeCrash = this.setTree(res)
                this.treeData = treeCrash[0]
            })
        },
        /**
         * 获取机构类型
         * @description: 通过字典表获取机构类型列表，
         * @param {Array} this.deptType 组织机构列表
         * @param {Array} this.changeDeptType 组织机构列表映射,即this.changeDeptType[value]可获取组织机构列表中该value所对应的label值
         * @return {*}
         */
        getTypeDict() {
            const parm = {
                type: 'dept_type'
            }
            getDict(parm).then(({ res }) => {
                this.deptType = res.dept_type
                const changeDeptTypeCrash = []
                res.dept_type.map((item, index) => {
                    changeDeptTypeCrash[item.value] = item.label
                })
                this.changeDeptType = changeDeptTypeCrash
            })
        },
        /**
         * 新增子级机构
         * @description: 
         *      新增前，判断该机构是否已被禁用：
         *      如果已禁用，消息提示
         *      如果没有禁用，将当前行id作为parentId传入添加机构弹出层，并展示添加机构弹出层，默认显示出父级机构层级
         * @param {Object} item
         * @return {*}
         */
        insertChild(item) {
            if (item.status === "1") {
                this.$message({
                    type: 'warning',
                    message: '该机构已禁用,不可新增子级!',
                    duration: 2000
                })
                return
            }
            this.insertType = true
            this.detailData = {
                parentId: item.id
            }
            this.$refs.insert.updateVisible(true)
        },
        /**
         * 编辑机构信息
         * @description: 将改行数据传入添加机构弹出层，显示添加机构弹出层，标题展示为编辑机构
         * @param {Object} item 要传入的数据，用于编辑机构弹出层的回显
         * @return {*}
         */
        edit(item) {
            if (this.ifTopOrg(item.id)) {
                return false
            }
            this.insertType = false
            this.detailData = {
                ...item
            }
            this.$refs.insert.updateVisible(true)
        },
        /**
         * 获取表格数据
         * @description: 从后台获取组织机构列表，获取完成后将数据绑定至tableData
         * @param {*}
         * @return {*}
         */
        getTableData() {
            getOrgList().then(({ res, timeStamp }) => {
                this.tableData = res
                this.timeStamp = timeStamp
            })
        },
        /**
         * 设置组织机构启用禁用
         * @description: 当页面中的启用/禁用状态发生修改时，触发该函数；通过后台接口设置机构的启用禁用状态，
         *      成功/失败后消息提示，并重新加载数据
         * @param {*} item
         * @return {*}
         */
        setStatus(item) {
            const parm = {
                id: item.id,
                status: item.status
            }
            orgDisable(parm, this.timeStamp).then(({ res }) => {
                // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                this.$message({...this.commonConfig.$handleSuccess})
                this.getTypeDict()
                this.getTableData()
                this.loadTree()
            }).catch((error) => {
                this.getTableData()
            })
        },
        /**
         * 删除组织机构
         * @description: 传入对应组织机构id，删除对应id的组织机构。成功后，进行消息提示，并重新获取组织机构类型列表、组织机构列表及组织机构树数据
         * @param {*} deptId
         * @return {*}
         */
        delOrg(deptId) {
            const parm = {
                id: deptId
            }
            orgDel(parm, this.timeStamp).then(({ res }) => {
                // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                this.$message({...this.commonConfig.$handleSuccess})
                this.getTypeDict()
                this.getTableData()
                this.loadTree()
            })
        },
        /**
         * 组织机构详情
         * @description: 将该行数据信息传入机构详情组件，并显示机构详情弹出层
         * @param {Object} row 当前行数据
         * @return {*}
         */
        toDetail(row) {
            this.detailData = {
                ...row,
                createDate: row.createDate ? moment(row.createDate).format("YYYY-MM-DD hh:mm:ss"): commonConfig.$nullData
            }
            this.$refs.detail.updateVisible(true)
        },
        /**
         * 返回至上一页面
         */
        back() {
            this.$router.back()
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.clickCell {
    color: $theme;
    cursor: pointer;
}
.search-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.treeBox{
    overflow: auto;
    height: 100%;
    width: 100%;
}
</style>
<style>
#pane-organizationTree{
    height: 100%;
}
</style>
