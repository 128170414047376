<template>
  <div style="display: flex">
    <div class="outBox">
      <el-select v-model="conditions.operator" :hidden="groupIndex === 0" :disabled="groupIndex === 0">
        <el-option v-for="logic in logicOptions" :key="logic.key" :value="logic.key" :label="logic.label"></el-option>
        <el-option v-if="groupIndex === 0" selected value=""></el-option>
      </el-select>
    </div>
    <div class="group">
      <div class="line">
        <el-button v-on:click="addLine">新增一行</el-button>
        <el-button v-on:click="addGroup">新增一组</el-button>
        <el-button v-on:click="$emit('remove-group',conditions.id)" v-if="conditions.id">删除</el-button>
      </div>
      <div class="line" v-for="(item,index) in conditions.conditions" :key="index" >
        <div v-if="!item.group" class="inBox">
          <el-select v-model="item.operator" :hidden="index === 0" :disabled="index === 0" placeholder="请选择">
            <el-option v-for="logic in logicOptions" :key="logic.key" :value="logic.key" :label="logic.label"></el-option>
            <el-option v-if="index === 0" selected value=""></el-option>
          </el-select>
          <el-select v-model="item.leftValue" placeholder="请选择字段" @change="setLeftValueType($event,item)">
            <el-option v-for="leftValue in leftValueOptions" :key="leftValue.key" :value="leftValue.key" :label="leftValue.label">
            </el-option>
          </el-select>
          <el-select v-model="item.compare" placeholder="请选择条件">
            <el-option v-for="operator in operatorOptions" :key="operator.key" :value="operator.key" :label="operator.label">
            </el-option>
          </el-select>
          <el-date-picker
            v-if="item.dateFormat"
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
          <el-input v-if="!item.dateFormat" v-model="item.value" type="type" placeholder="请输入"/>
          <el-button class="btnRemoveLine" v-on:click="removeLine(item.id)" >移除</el-button>
        </div>
        <group v-bind:conditions="item" :groupIndex="index" :options="options" v-if="item.group"  v-on:remove-group="removeGroup" ></group>
      </div>
    </div>
  </div>
</template>

<script>
let indexs = 1000
export default {
  name: 'group',
  props: ['conditions', 'groupIndex', 'options'],
  data: function() {
    return {
      logicOptions: [],
      operatorOptions: [],
      leftValueOptions: [],
      leftValueTypeMap:{},
      dateFormatMap:{}
    }
  },
  created() {
    this.logicOptions = this.options.logicOptions
    this.operatorOptions = this.options.operatorOptions
    this.leftValueOptions = this.options.leftValueOptions
    this.leftValueOptions.map(o=>{
      this.leftValueTypeMap[o.key]=o.type;
      this.dateFormatMap[o.key]=o.dateFormat;
    })
  },
  methods: {
    setLeftValueType:function(event, item){
      item.leftValueType = this.leftValueTypeMap[event];
      item.dateFormat = this.dateFormatMap[event];
    },
    addLine: function(event) {
      indexs++
      this.conditions.conditions.push({
        id: indexs,
        operator:this.logicOptions[0].key,
        negated:"Y",
        leftValue:this.leftValueOptions[0].key,
        leftValueType:this.leftValueTypeMap[this.leftValueOptions[0].key],
        compare:this.operatorOptions[0].key,
        dateFormat:this.dateFormatMap[this.leftValueOptions[0].key],
        value:""
      })
    },
    addGroup: function() {
      indexs++
      this.conditions.conditions.push({
        id: indexs,
        operator:this.logicOptions[0].key,
        negated:"Y",
        group:"group",
        conditions:[]
      })
    },
    removeLine: function(id) {
      for (let i = 0; i < this.conditions.conditions.length; i++) {
        if (this.conditions.conditions[i].id === id) {
          this.conditions.conditions.splice(i, 1)
          break
        }
      }
    },
    removeGroup: function(id) {
      for (let i = 0; i < this.conditions.conditions.length; i++) {
        if (this.conditions.conditions[i].id === id) {
          this.conditions.conditions.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
  .group {
    width: 100%;
    padding: 10px;
    margin: 0 10px;
    border: 1px solid $tableColor;
    border-radius: 5px;
  }

  .outBox{
    width: 100px;
  }

  .inBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & > div{
      margin: 5px;
      flex: 1;
    }
  }

  .line{
    margin: 15px 0;
  }
</style>
