<!--
 * @Author: your name
 * @Date: 2022-09-07 08:49:14
 * @LastEditTime: 2022-09-15 09:18:15
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\workbook\addDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        :title="type === 'edit' ? '变更' : '登记'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-descriptions class="margin-top" title="患者信息" :column="4">
            <el-descriptions-item label="姓名">
                {{ commonConfig.$checkNullData(patient.name) }}
            </el-descriptions-item>
            <el-descriptions-item label="住院/就诊编号">
                {{ commonConfig.$checkNullData(patient.hosNum) }}
            </el-descriptions-item>
        </el-descriptions>
        <div class="mdrcc-info-title">操作项</div>
        <generalTable ref="mainTable" :saveSelected="true" :checkIds="checkedIds" :table-data="list" :config="tableConfig" newHeight="400" style="margin-bottom: 15px;">
            <template slot="count" slot-scope="scope">
                <!-- 登记状态 0未登记、1未审核、2已审核 -->
                <!-- 全部都可以查看；未登记可以登记；未审核可以变更-->
                <el-input v-model="scope.data.row.count" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
            </template>
        </generalTable>
        <template v-if="type==='edit'">
            <div class="mdrcc-info-title"> <span class="requeired">*</span> 变更原因</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input type="textarea" resize="none" v-model="updateReason" rows="4" maxlength="200"></el-input>
                </el-col>
            </el-row>
        </template>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">{{type === 'edit' ? '变 更' : '登 记'}}</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import { getData, editData } from '@/api/treatmentWorkbook'
import { getDict } from '@/api/common'
export default {
    name: 'addWorkbook',
    components: {
        generalTable
    },
    props: {
        basicInfoId: String,
        type: String, // add为登记 edit为变更
        useDate: Number // 登记时间
    },
    data() {
        return {
            checkedIds: [],
            updateReason: "",
            operTypeMap: {},
            visible: false,
            timeStamp: '',
            patient: {},
            list: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                saveSelected: true,
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType' // 必填
                    },
                    {
                        label: '操作项名称', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        prop: 'adviceDate', // 必填
                    },
                    {
                        slot: 'count', // 不必填 单列插槽
                        label: '登记次数', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '200' // 不必填
                    }
                ]
            }
        }
    },
    created() {
        console.log('workbook created------')
        this.getOperType()
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.getData()
            } else {
                this.checkedIds = []
                this.patient = {}
                this.list = []
                this.updateReason = ""
            }
        }
    },
    methods: {
        getOperType() {
            const type = 'oper_type'
            getDict({ type }).then(({ res }) => {
                console.log('------oper_type', res)
                let operTypeMap = {}
                res[type].forEach(item => {
                    operTypeMap[item.value] = item.label
                })
                this.operTypeMap = operTypeMap
            })
        },
        getData() {
            const param = {
                basicInfoId: this.basicInfoId,
                useDate: this.useDate
            }
            getData(param).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                console.log('------workbook data', res)
                const { patient, list } = res
                this.patient = patient
                this.checkedIds = list.filter(item => item.isMark !== '0').map(item => item.adviceId)
                this.list = list.map(item => {
                    item.id = item.adviceId
                    item.operType = this.operTypeMap[item.operType]
                    item.adviceDate = this.moment(item.adviceDate * 1 || new Date().getTime()).format("YYYY-MM-DD HH:mm")
                    item.count = item.count * 1 || 1
                    return item
                })
                console.log("------update data", this.checkedIds, this.list)
            })
        },
        submit() {
            const checked = this.$refs.mainTable.getChecked()
            console.log('------checked', checked)
            if (!checked) {
                return
            }
            if (this.type === "edit" && !this.updateReason) {
                this.$message({
                    type: "warning",
                    message: "请输入变更原因！"
                })
                return
            }
            const checkedId = checked.map(item => item.adviceId).join()
            const count = checked.map(item => item.count).join()
            let param = {
                useDate: this.useDate,
                checkedId,
                count,
                basicInfoId: this.basicInfoId,
                updateReason: this.updateReason
            }
            if (this.type === "edit") {
                param.id=this.patient.id
            }
            editData(param, this.timeStamp).then((res) => {
                this.updateVisible(false)
                this.$emit('success', this.basicInfoId)
                this.$message({
                    message: `${this.type === 'edit' ? '变更' : '登记'}成功！`,
                    type: 'success'
                });
            })
        },
        /**
         * @description: 关闭
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.requeired {
    color: $danger;
}
</style>
