/*
 * @LastEditors: Richardlv
 * @Author: Richardlv
 */

/**
 * 页面: 医疗仪器管理
 * pageName: medicalCare
 **/
import request from '@/utils/request'

/**
 * @description: 获取医疗仪器管理列表数据
 */
export function getList(data) {
    return request({
        url: '/ruleArmarium/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增医疗仪器管理数据
 */
export function insertData(data) {
    return request({
        url: '/ruleArmarium/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改医疗仪器管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/ruleArmarium/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取医疗仪器管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/ruleArmarium/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取设备库列表
 */
 export function getruleDeviceList(data) {
    return request({
        url: '/ruleDevice/getRuleDevices',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 验证设备名称和编号是否重复
 */
 export function checkDiffName(data) {
    return request({
        url: '/ruleArmarium/validNameAndNum',
        method: 'GET',
        params: data,
    })
}
            
/**
 * @description: 删除医疗仪器管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/ruleArmarium/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            
/**
 * @description: 医疗仪器关联治疗师列表
 */
 export function userList(data) {
    return request({
        url: '/ruleArmarium/userList',
        method: 'GET',
        params: data,
        showLoading: true,
    })
}

/**
 * @description: 添加用户设备关联
 */
 export function addUserArmariumRel(data, timeStamp) {
    return request({
        url: '/ruleArmarium/addUserArmariumRel',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除用户设备关联
 */
 export function deleteUser(data, timeStamp) {
    return request({
        url: '/ruleArmarium/removeUserArmariumRel',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}