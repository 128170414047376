/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2023-02-21 15:40:07
 * @LastEditors: wangly wly574523605@163.com
 * @Reference:
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
// // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        type: "1",
        hidden: false,
        component: () => import("@/views/home/index"),
        meta: {
          title: "工作台",
          icon: "home",
          breadcrumb: false,
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/outSystem/treatmentPlan",
    component: () => import("@/views/outSystem/treatmentPlan"),
    name: "treatmentPlan",
    type: "1",
    hidden: true,
    meta: {
      title: "外用治疗计划",
      icon: "treatmentPlan",
      breadcrumb: false,
      keepAlive: false,
    },
  },
  {
    path: "/outSystem/treatmentRecord",
    name: "treatmentRecord",
    type: "1",
    hidden: true,
    component: () => import("@/views/outSystem/treatmentRecord"),
    meta: {
      title: "外用治疗记录",
      icon: "treatmentRecord",
      breadcrumb: false,
      keepAlive: false,
    },
  },
  {
    path: "/outSystem/largeScreenShow",
    name: "largeScreenShow",
    type: "1",
    hidden: true,
    component: () => import("@/views/outSystem/largeScreenShow"),
    meta: {
      title: "大屏显示",
      icon: "treatmentRecord",
      breadcrumb: false,
      keepAlive: false,
    },
  },
  // {
  //   path: '/customMain',
  //   component: Layout,
  //   redirect: '/customCollocation',
  //   children: [
  //     {
  //       path: '/customCollocation',
  //       name: 'CustomCollocation',
  //       type: '1',
  //       hidden: false,
  //       component: () => import('@/views/system/customCollocation/index'),
  //       meta: { title: '自定义配置', icon: 'home', breadcrumb: true, keepAlive: true }
  //     },
  //     {
  //       path: '/disposeList',
  //       name: 'Dispose',
  //       type: '2',
  //       hidden: false,
  //       component: () => import('@/views/system/customCollocation/dispose/index'),
  //       meta: { title: '自定义配置-详情', icon: 'home', breadcrumb: true, keepAlive: true }
  //     }
  //   ]
  // }
];

export const notFoundRouter = [
  {
    path: '*',
    redirect: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
