
/**
 * 页面: 全文检索
 * pageName: fullSearch
 **/
import request from '@/utils/request'

/**
 * @description: 获取全文检索列表数据
 */
export function getList(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增全文检索数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改全文检索数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取全文检索详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除全文检索数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        