<!--
* @Description: 我的文档->新增/编辑
* @Reference:
-->
<template>
  <el-container class="doc-add">
    <el-header class="doc-header">
      <el-row class="table-btn-group">
        <div>
          <el-button @click="back()" type="primary" plain>返回</el-button>
        </div>
      </el-row>
    </el-header>
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="100px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="12" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="文档名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入文档名称..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" prop="status">
            <el-radio v-model="form.status" label="1">私有</el-radio>
            <el-radio v-model="form.status" label="0">公开</el-radio>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="分类" prop="typeId">
            <el-cascader
              v-model="form.typeId"
              :options="options"
              :show-all-levels="false"
              placeholder="请选择"
              collapse-tags
              :props="{
                checkStrictly: true,
                label: 'label',
                value: 'id',
                emitPath: false,
                multiple: true
              }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="病种" prop="diseases">
            <el-select v-model="form.diseases" placeholder="请选择">
              <el-option
                v-for="item in diseaseList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item v-show="false" label="患者id" prop="basicInfoId">
            <el-input :value="form.basicInfoId" />
          </el-form-item>
          <el-form-item label="患者" prop="basicInfoName">
            <el-input
              @focus="toSelectPat"
              :readonly="true"
              prefix-icon="el-icon-search"
              :value="form.basicInfoName"
              placeholder="请选择"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="文件附件">
            <el-upload
              class="upload-demo"
              action="#"
              :http-request="uploadFile"
              :before-upload="beforeUpload"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :file-list="form.files"
            >
              <el-button size="small" type="primary" plain>
                <i class="el-icon-plus"></i>选择文件
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="正文" prop="content" class="content-wrapper">
            <tinymce
              :height="500"
              name="content"
              class="content"
              v-model="form.content"
              id="tinymce"
            ></tinymce>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label>
            <el-button type="primary" @click="submitForm()">提 交</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <selectPat ref="selectPat" @selectPerson="selectPerson"></selectPat>
  </el-container>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import { getTreeList, insertData, getDetail, editData } from "@/api/myDocument";
import selectPat from "@/views/knowledgeBase/myDocument/selectPat";
import { uploadFile, getDict, downloadFile } from "@/api/common";

export default {
  name: "FullSearch",
  components: {
    Tinymce,
    selectPat
  },
  data() {
    return {
      options: [], // 分类树结构
      diseaseList: [], // 病种列表
      form: {
        name: "", // 文档名称
        status: "1", // 0公开、1私有 默认私有
        typeId: [], // 所属分类
        diseases: "", // 病种，暂时没有，先空着，后期从字典表中获取
        basicInfoId: "", // 患者id
        basicInfoName: "",
        type: "1", // 暂时没有地方做修改
        files: [], // 附件列表
        fileList: [], // 附件上传后返回的id
        content: "" // 富文本
      },
      timeStamp: "", //时间戳 用于修改数据或删除数据
      rules: {
        name: [{ required: true, message: "请输入文档名称", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        typeId: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur",
            type: "array"
          }
        ]
      }
    };
  },
  updated() {},
  /** 页面创建
   * @description:
   *  页面创建后，
   *      获取类型树结构，并绑定到级联插件选项中
   *      获取病种库数据，并绑定到下拉框选项中
   *      如果传入了parentId，将parentId绑定到级联选择器中进行回显
   *      如果传入了id，通过id获取文档数据回显到页面中
   *  跳转至该页面有三个渠道
   *      1.知识库/我的文档/添加--->需要传入parentId
   *      2.知识库/我的文档/编辑--->需要传入id及parentId
   *      3.知识库/新增文档：不需要传参数
   * @param {*}
   * @return {*}
   */
  created() {
    this.getTreeList();
    this.getDiseaseList();
    if (this.$route.query.id) {
      // 知识库/我的文档/编辑
      this.getDetail(this.$route.query.id);
    } else if (this.$route.query.parentId) {
      // 知识库/我的文档/添加 + 知识库/我的文档/编辑
      this.form.typeId = this.$route.query.parentId;
    }
  },
  mounted: function() {},
  methods: {
    /**获取类型树结构
     * @description: 对于没有base-type-add权限的用户，不显示系统菜单(status为'2')
     * @param {*}
     * @return {*}
     */
    getTreeList() {
      // 没有系统菜单添加权限时，在选择类型时，不可选择系统菜单
      getTreeList().then(({ res }) => {
        if (this.hasPermission("base-type-add")) {
          this.options = res;
        } else {
          const { children, ...other } = res[0];
          this.options = [
            {
              ...other,
              children: children.filter(item => item.status !== "2") // ;过滤掉status为'2'的菜单
            }
          ];
        }
      });
    },
    /** 获取病种
     * @description: 从字典表中获取type为'disease_species'的数组，更新到this.diseaseList中
     * @param {*}
     * @return {*}
     */
    getDiseaseList() {
      const type = "disease_species";
      getDict({ type }).then(({ res }) => {
        this.diseaseList = res[type];
      });
    },
    /** 获取文档详情
     * @description: 当该页面为文档编辑页面时，需要进行回显
     * 后台中的typeId需要从逗号分隔的id重新转换成数组以便级联插件回显
     * 取出结果中的fileInterfaceList对象中的name和文件类型拼接成文件名放入files中，以便在页面中显示附件名
     * 后台中的typeId需要从逗号分隔的id重新转换成数组放入到fileList的id对象中，将files中的每一项内容放入到item中
     * @param {String} id 文档id
     * @return {*}
     */
    getDetail(id) {
      getDetail({ id }).then(({ res }) => {
        const { fileId, typeId, fileInterfaceList, ...info } = res;
        this.$nextTick(() => {
          this.form = {
            ...this.form,
            ...info,
            typeId: typeId ? typeId.split(",") : [],
            fileId: fileId ? fileId.split(",") : [],
            files: fileInterfaceList
              ? fileInterfaceList.map(item => {
                  return {
                    name: `${item.name}.${item.suffix}`
                  };
                })
              : []
          };
          this.form.fileList = this.form.files.map((item, index) => {
            return { id: this.form.fileId[index], file: item };
          });
        });
      });
    },
    /**点击选择患者时，显示患者弹窗
     * @description: 调用组件中的updateVisible函数，显示组件
     * @param {*}
     * @return {*}
     */
    toSelectPat() {
      this.$refs.selectPat.updateVisible(true);
    },
    /** 本地上传文件
     * @description: 选择文件后，将文件转换为formData格式，执行uploadFile方法，
     *      成功后返回文件id，将该返回值放入fileList对象中的id中，将file放入到file中，
     *      确保fileList的每项内容的file都与files中的每项内容一致
     * @param {*} data
     * @return {*}
     */
    uploadFile(data) {
      const { file } = data;
      let formData = new FormData();
      formData.append("file", file, file.name);
      uploadFile(formData).then(({ res }) => {
        this.form.files.push(file);
        this.form.fileList.push({
          id: res,
          file
        });
      });
    },
    /**选择患者
     * @description: 将子组件payload中的name和id取出，挂载到this.form.basicInfoName及this.form.basicInfoId中
     * 其中this.form.basicInfoName用于显示，this.form.basicInfoId用于新增/编辑
     * @param {*} patInfo
     * @return {*}
     */
    selectPerson(patInfo) {
      const { name, id } = patInfo;
      this.form.basicInfoId = id;
      this.form.basicInfoName = name;
    },
    /**前端移除文件
     * @description:
     * 过滤掉files中与传入的file完全一致的部分；
     * 过滤掉fileList中file对象与file完全一致的部分
     * ★★★ 一定要确保files与fileList一一对应
     * @param {*} file 要移除的文件对象
     * @return {*}
     */
    handleRemove(file) {
      this.form.fileList = this.form.fileList.filter(
        item => item.file !== file
      );
      this.form.files = this.form.files.filter(item => item !== file);
    },
    /**上传之前触发的函数
     * @description: 上传之前先校验文件类型，如果全局配置的commonConfig中没有包含该类型的文件，则阻止上传，并提示文件类型错误
     * @param {*} file 要上传的文件名
     * @return {*}
     */
    beforeUpload(file) {
      const {
        $docTypes,
        $imgTypes,
        $audioTypes,
        $videoTypes
      } = this.commonConfig;
      const allTypes = [
        ...$docTypes,
        ...$imgTypes,
        ...$audioTypes,
        ...$videoTypes
      ];
      // 用.切分文件名，最后一个.后面就是文件类型
      const names = file.name.split(".");
      const fileType = names[names.length - 1]; // 文件类型
      // 如果文件、图片、音频、视频文件类型都不包含该文件类型，则进行消息提示，并阻止上传
      if (!allTypes.includes(fileType)) {
        this.$message({
          type: "warning",
          message: "文件格式不正确！"
        });
        return false;
      } else {
        return true;
      }
    },
    /**移除前确认
     * @description: 对于上传成功的文件，移除时，进行消息提示，确认后方可移除
     *      此刻移除为前台移除，在点击提交前刷新页面即可恢复原始状态
     * @param {*} file 要移除的文件
     * @return {*}
     */
    beforeRemove(file) {
      // 过滤掉beforeUpload函数中，已阻止上传的文件
      if (file && file.status === "success") {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
    },
    /**提交校验
     * @description: 提交前先进行校验，校验通过后，再进入submit函数进行后台提交
     * @param {*}
     * @return {*}
     */
    submitForm() {
      this.$refs.insertForm.validate(valid => {
        valid && this.submit();
      });
    },
    /**提交
     * @description: 通过校验后，进行后台提交
     *      路由信息query.id存在时，执行编辑文档接口
     *      路由信息query.id不存在时，执行新增文档接口
     *      后台接口返回成功信息后，进入submitSuccess，执行成功回调
     * @param {*}
     * @return {*}
     */
    submit() {
      const {
        name, // 文档名称
        status, // 0公开、1私有 默认私有
        typeId, // 所属分类
        diseases, // 病种，暂时没有，先空着，后期从字典表中获取
        basicInfoId, // 患者id
        type, // 暂时没有地方做修改
        fileList, // 附件上传后返回的id
        content // 富文本内容
      } = this.form;
      let param = {
        name,
        status,
        typeId,
        diseases,
        basicInfoId,
        type,
        fileId: fileList.map(item => item.id),
        content
      };
      if (this.$route.query.id) {
        param.id = this.$route.query.id;
        editData(param, this.$route.query.timeStamp).then(({ res }) => {
          this.submitSuccess();
        });
      } else {
        insertData(param).then(({ res }) => {
          this.submitSuccess();
        });
      }
    },
    /** 成功回调函数
     * @description: 在文档新增/编辑成功后，判断该页面是否存在query.parentId路由信息
     *      如果存在，直接返回至上一页(知识库/我的文档/添加 + 知识库/我的文档/编辑)
     *      如果不存在，清空当前页面信息(知识库/新增文档)
     * @param {*}
     * @return {*}
     */
    submitSuccess() {
      const { $handleSuccess } = this.commonConfig;
      this.$message({ ...$handleSuccess });
      if (this.$route.query.parentId) {
        this.back();
      } else {
        this.$router.replace('/knowledgeBase/myDocument')
      }
    },
    /** 返回上一页
     * @description: 路由回退
     * @param {*}
     * @return {*}
     */
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
// 该页面的el-container部分；超出部分显示滚动条
.doc-add {
  background-color: $menuText;
  overflow-y: auto;
  padding-right: 100px;
}
// 该页面的el-header部分
.doc-header {
  height: auto !important;
  padding: 0;
}
// 按钮置右
.table-btn-group {
  div {
    text-align: right;
  }
}
</style>
