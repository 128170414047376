
<template>
  <el-dialog
    width="70%"
    title="复制量表库量表"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">复制信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="版本号" prop="versionNo">
            <el-input
              v-model="form.versionNo"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="量表分类" prop="typeValue">
            <el-select
              v-model="form.typeValue"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="dict in typeList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getDict } from "@/api/common";
import { copyScale } from "@/api/publicScaleLib";
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      typeList: [],
      rules: {
        name: [{ required: true, message: "请输入量表名称", trigger: "blur" }],
        versionNo: [{ required: true, message: "请输入版本号", trigger: "blur" }],
        typeValue: [
          { required: true, message: "请选择量表类型", trigger: "change" },
        ],
      },
      form: { name: "", typeValue: "", versionNo: "" },
      visible: false,
    };
  },
  created() {
    this.get_basic_scale_type();
  },
  mounted() {},
  methods: {
    get_basic_scale_type() {
      getDict({ type: "basic_scale_type" }).then(({ res }) => {
        this.typeList = res.basic_scale_type.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
      });
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          let param = this.form;
          param.scaleInfoId = this.id;
          copyScale(param).then(({ res }) => {
            this.submitSuccess(res);
          });
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang='scss'>
</style>
      
        