<template>
    <div  class="line">
        <el-timeline id="pat-record">
            <el-timeline-item
                v-for="(activity, index) in list"
                :key="index"
                :size="activity.size"
                placement="top"
                :color="activity.color"
                :timestamp="activity.date">
                <div class="line-item">
                    <div class="line-head" v-for="(record, recordIndex) in activity.items" :key="recordIndex">
                        <div class="line-type"><p>会诊科室：{{record.executeDeptName}}</p></div>
                        <div class="line-auth"><p>会诊建议：{{record.description}}</p></div>
                    </div>
                </div>
            </el-timeline-item>
        </el-timeline>
        <!-- <el-steps direction="vertical" id="pat-record">
            <el-step v-for="(item) in list" 
            :key="item.date" 
            :title="item.date" 
            color="#0bbd87" 
            icon=""
            :size="item.size">
            {{item}}
                <div slot="description" class="line-item">
                    <div class="line-head" v-for="(record, recordIndex) in item.items" :key="recordIndex">
                        <div class="line-type">会诊科室：{{record.executeDeptName}}</div>
                        <div class="line-auth">会诊记录：{{record.description}}</div>
                    </div>
                </div>
            </el-step>
        </el-steps> -->
    </div>
</template>
<script>
import { getMdtRecord } from '@/api/patient'
export default {
    name: 'patRecord',
    // 父级传入的值
    props: {
        id: String
    },
    watch: {
        // id(newVal, orgVal){
        //     this.getDetail()
        // }
    },
    data(){
        return {
            list: [],
        }
    },
    created(){
        console.log("-----components record creat", this.id)
        this.getDetail()
    },
    methods: {
        getDetail(){
            getMdtRecord({basicInfoId: this.id}).then(({res}) => {
                console.log("------getMdtRecord", res)
                let recordMapByDate = {}
                for (let item of res) {
                    const key = this.moment(item.mdtDate).format("YYYY-MM-DD")
                    if (!recordMapByDate[key]) {
                        recordMapByDate[key] = [item]
                    } else {
                        recordMapByDate[key].push(item)
                    }
                }
                let list = []
                for (let i in Object.keys(recordMapByDate)) {
                    let color = "#469cf8"
                    let size = ""
                    if (i === "0") {
                        color = "#469cf8"
                        size ="large"
                    } else {
                        color = "#aaaaaa"
                        size ="normal"
                    }
                    let date = Object.keys(recordMapByDate)[i]
                    list.push({
                        date, // 等同于date:date,
                        items: recordMapByDate[date],
                        size,
                        color
                    })
                }
                // for (let date in recordMapByDate) {
                //     list.push({
                //         date, // 等同于date:date,
                //         items: recordMapByDate[date]
                //     })
                // }
                console.log("getMdtRecord------", list)
                this.list = list
            })
        }
    }
}
</script>
<style lang="scss" scoped>

.line {
    height: 100%;
    background: #fff;
    padding-top: 20px;
    &-icon {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        border: 4px #acacac solid;
        background-color: #fff;
    }
    &-icon.active {
        border-color: #469cf8;
    }
    &-item{
        border-radius: 10px;
        // padding: 2px 5px;
        padding-right: 2%;
    }
    &-head{
        background: #f6f6f6;
        display: flex;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 4px;
    }
    &-type{
        text-indent: 1em;
        width: 15%;
    }
    &-auth{
        width: 85%;
    }
    
    &-type p{
        margin: 1px !important;
    }
    &-auth p{
        margin: 1px !important;
    }
    
}
#pat-record .el-timeline{
    padding-top: 10px;
}
#pat-record{
    padding-left: 15px;
}

</style>
