<!--
 * @Author: your name
 * @Date: 2021-12-24 10:03:07
 * @LastEditTime: 2021-12-28 14:35:31
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\record.vue
-->

<template>
    <el-main>
        <el-row>{{message}}</el-row>
    </el-main>
</template>

<script>
export default {
    name: 'recordPat360',
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Array
        }
    },
    data() {
        return {
            message: '暂无数据'
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
</style>