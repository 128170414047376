
/**
 * 页面: 治疗组套管理
 * pageName: ruleSet
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗组套管理列表数据
 */
export function getList(data) {
    return request({
        url: '/ruleSet/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增治疗组套管理数据
 */
export function insertData(data) {
    return request({
        url: '/ruleSet/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗组套管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/ruleSet/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取治疗组套管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/ruleSet/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除治疗组套管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/ruleSet/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
  /**
   * @description: 获取组套关联操作项目数据
   */
  export function getOperationList(data) {
    return request({
      url: '/ruleSet/getRuleSetOperations',
      method: 'GET',
      params: data,
      showLoading: true
    })
  }
    /**
     * @description: 删除治疗组套管理数据
     */
    export function batchDeleteData(data, timeStamp) {
      return request({
        url: '/ruleSet/batchDeleteRuleSet',
        method: 'POSt',
        data,
        showLoading: true,
        timeStamp
      })
    }
    /**
     * @description: 获取操作项目列表
     */
    export function getOperations(data) {
      return request({
        url: '/ruleSet/getOperations',
        method: 'GET',
        params: data
      })
    }
