
<!--
* @Description: 自定义配置页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
            <el-button type="text" @click="toDetail(scope)">详情</el-button>
            <el-button type="text" @click="toEdit(scope)">编辑</el-button>
            <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
            </template>
        </generalTable>
        <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="search" />
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/customCollocation'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/customCollocation/addDialog'

export default {
    name: 'CustomCollocation',
    components: {
        searchForm,
        generalTable,
        addDialog,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '菜单名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'pageName',
                    label: '页面名称',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'customCollocation',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '菜单名称(CN)', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '页面名称(EN)', // 必填
                    prop: 'pageName' // 必填
                },
                {
                    label: '备注', // 必填
                    prop: 'remark' // 必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '280' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            total:0,
        }
    },
    created() {
        this.getList()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records || []
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$router.push({path: '/system/disposeList', query:{id:this.checkId}})
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...$handleSuccess})
                })
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        