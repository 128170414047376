<!--
 * @Author: your name
 * @Date: 2022-11-16 10:07:18
 * @LastEditTime: 2022-12-12 09:21:12
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\template\add.vue
-->
<template>
    <el-container ref="container">
        <el-header height="auto">
            <el-form ref="insertForm" :inline="true" class="searchForm">
                <div class="search-btn-group">
                    <template v-if="!disabled">
                        <el-form-item>
                            <el-button type="primary" @click="submit()">确定</el-button>
                        </el-form-item>
                    </template>
                    <el-form-item v-else>
                        <el-button type="primary" @click="edit()">编辑</el-button>
                    </el-form-item>
                    <el-form-item style="margin-right: 0">
                        <el-button type="primary" plain @click="back()">返回</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-header>
        <el-main class="basic-main">
            <el-form
                ref="insertForm"
                :model="form"
                label-position="right"
                label-width="120px"
                class="formBox"
                :rules="rules"
            >
                <el-row :gutter="10" type="flex" justify="start">
                    <template v-if="!disabled">
                        <el-col :sm="12" :md="12" :lg="8" :xl="8">
                            <el-form-item label="模板名称" prop="name">
                                <el-input
                                    v-model="form.name"
                                    maxlength="30"
                                    clearable
                                    placeholder="请输入..."
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8" :xl="8">
                            <el-form-item label="标签" prop="labelIds">
                                <el-select v-model="form.labelIds" multiple collapse-tags placeholder="请选择模板标签">
                                    <el-option
                                        v-for="item in labelOpts"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :sm="12" :md="12" :lg="8" :xl="8">
                            <el-form-item label="状态" prop="status">
                                <el-select v-model="form.status" placeholder="请选择模板状态">
                                    <el-option
                                        v-for="item in statusOpts"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item label="模板" prop="templateData">
                            <tinymce
                                :disabled="disabled"
                                v-if="height"
                                ref="tinymce"
                                :height="height"
                                style="width: 890px;"
                                name="content"
                                class="content"
                                v-model="form.templateData"
                                id="tinymce"
                                :toolbar="'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | addTags'"
                                :plugins="'table advlist autolink link image lists charmap  media wordcount print'"
                                @addTags="insertLabels()"
                            ></tinymce>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="!disabled" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item label="描述" prop="content">
                            <el-input
                                v-model="form.content"
                                type="textarea"
                                clearable
                                :autosize="{ minRows: 2, maxRows: 4 }"
                                maxlength="200"
                                placeholder="请输入内容"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <add-label ref="insert" @updateLabel="updateLabel" />
    </el-container>
</template>
<script>
import { caseLabelList, insertTemplate, updateTemplate, casesTemplateInfo } from '@/api/caseReport'
import addLabel from '@/views/caseReport/template/labelsDialog'
import Tinymce from '@/components/Tinymce'
export default {
    components: {
        addLabel,
        Tinymce
    },
    data() {
        return {
            id: '',
            disabled: false,
            rules: {
                name: [
                    { required: true, message: '请输入模板名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                status: [{ required: true, message: '请选择模板状态', trigger: 'change' }],
                templateData: [{ required: true, message: '模板内容不能为空', trigger: 'blur' }]
            },
            title: '',
            height: 0,
            content: '',
            form: {
                name: '',
                labelIds: [],
                status: '0',
                templateData: '',
                content: ''
            },
            // 状态 0 启用 1禁用
            statusOpts: [
                { value: '0', label: '启用' },
                { value: '1', label: '禁用' }
            ],
            labelOpts: [],
            extData: [],
            allData: []
        }
    },
    created() {
        this.getLabelList()
        const { id, disabled } = this.$route.query
        this.disabled = Boolean(disabled)
        this.id = id
        this.id && this.getDetail()
        this.$nextTick(() => {
            window.fullHeight = document.documentElement.clientHeight
            this.height = window.fullHeight - 240 // 高
        })
    },
    methods: {
        getLabelList() {
            caseLabelList({ size: -1, status: '0' }).then(({ res }) => {
                console.log('-----caseLabelList', res)
                this.labelOpts = res.records
            })
        },
        getDetail(){
            casesTemplateInfo({id: this.id}).then(({res, timeStamp}) => {
                this.timeStamp = timeStamp
                console.log("------template info", res)
                const {extData, labelIds, ...others} = res
                this.extData = JSON.parse(extData)
                this.form = {
                    ...others,
                    extData: JSON.parse(extData),
                    labelIds: labelIds? labelIds.split(",") : []
                }
            })
        },
        insertLabels() {
            this.$refs.insert.updateVisible(true)
        },
        edit(){
            this.$router.push({
                path: '/caseReport/template/add',
                query: {
                    id: this.id
                }
            })
        },
        updateLabel(allData, extData) {
            this.allData = [...this.allData, ...allData]
            this.extData = [...this.extData, ...extData]
            this.insertContent(allData)
        },
        insertContent(allData) {
            allData.forEach(data => {
                if (data.dataScope) {
                    // 表格
                    const tds = data.list
                        .map(item => `<td style="border: thin #666666 solid;">【${item.label}】</td>`)
                        .join('')
                    const content = `
                        <table style="width: 100%;" id="${data.uuid}">
                            <tr>${tds}</tr>
                        </table><br><br>`
                    tinymce.activeEditor.selection.setContent(content)
                } else {
                    // 表单
                    const content =
                        data.list
                            .map(item => {
                                return `<span>${item.label}：【${item.keyword}】</span>`
                            })
                            .join('&nbsp;&nbsp;')
                    tinymce.activeEditor.selection.setContent(content)
                }
            })
            setTimeout(() => {
                const templateData = tinyMCE.editors[0].getContent()
                this.form = {
                    ...this.form,
                    templateData
                }
            }, 0)
        },
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (!valid) {
                    return
                }
                if (!this.form.templateData) {
                    this.$message({
                        type: 'warning',
                        message: '模板内容不能为空！'
                    })
                    return
                }
                let param = {
                    ...this.form,
                    labelIds: this.form.labelIds.join(),
                    extData: JSON.stringify(this.extData)
                    // templateData: this.content
                }
                console.log('------submit', param)
                if (this.id) {
                    param.id = this.id
                    updateTemplate(param, this.timeStamp).then(res => {
                        this.submitSuccess(res)
                    })
                } else {
                    insertTemplate(param).then(res => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('updateList')
            this.back()
        },
        back() {
            this.$router.back()
        }
    }
}
</script>
<style lang="scss" scoped>
.formBox {
    width: 1020px;
}
</style>
