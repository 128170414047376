
<template>
    <el-dialog
        width="30%"
        :title="nodeInfo.id ? '编辑类型' : '新增类型'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
            目录信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="24" :md="24" :lg="24" :xl="12">
                <el-form-item label="目录名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="请输入目录名称..."
                    clearable
                    maxlength="20"
                />
                </el-form-item>
            </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertTreeNode, editNodeData, getNodeDetail } from '@/api/myDocument'
          
export default {
    props: {
        // 类型信息
        nodeInfo: {
            type: Object
        },
        // 时间戳，更新类型时，需要传入
        timeStamp:{
            type: String
        }
    },
    data() {
        return {
            // 校验规则
            rules: {
                name: [
                    { required: true, message: '请输入目录名称', trigger: 'blur' },
                    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ]
            },
            // form表单信息
            form: {
                name: "",
            },
            // 弹窗显隐
            visible: false,
        }
    },
    watch: {
        /** 监控弹窗显示状态
         * @description: 显示且this.nodeInfo.id不为空时，获取类型信息
         * @param {*} newVal
         * @param {*} oldVal
         * @return {*}
         */        
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.nodeInfo.id) {
                this.getDetail(this.nodeInfo.id)
            }
          }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /** 后台提交
         * @description: 通过校验后，进行后台提交
         *      如果this.nodeInfo.id存在，执行编辑操作，编辑操作需要上一页传入的时间戳
         *      如果this.nodeInfo.id不存在，执行添加操作
         *      后台提交成功后，进入submitSuccess函数
         * @param {*}
         * @return {*}
         */        
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const {status, parentIds, parentId, id} = this.nodeInfo
                    let parm = {
                        name: this.form.name,
                        status,
                        parentId,
                        parentIds
                    }
                    if (id) {
                        parm.id = id
                        // 编辑时，需要时间戳信息，该信息从上一页传入
                        editNodeData(parm, this.timeStamp).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        insertTreeNode(parm).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        /** 成功回调
         * @description: 成功后，进行成功提示，关闭当前弹窗，触发父级`@submit`绑定的函数
         * @param {Object} res 成功返回值
         * @return {*}
         */        
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        /**获取类型信息
         * @description: 将返回值放入到this.form中
         * @param {String} id 类型id
         * @return {*}
         */        
        getDetail(id){
            getNodeDetail({id}).then(({res,timeStamp}) => {
                this.form = res
            })
        },
        /** 更新弹窗显示状态
         * @description: 将传入的val更新到this.visible中
         *      当弹窗隐藏时，清空页面数据
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */        
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        