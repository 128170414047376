<!--
 * @Author: your name
 * @Date: 2022-11-18 08:52:51
 * @LastEditTime: 2022-11-18 09:48:54
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\folder\addDialog.vue
-->
<template>
    <el-dialog
        width="78%"
        title="病历夹详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <el-descriptions class="margin-top" title="详情" :column="3">
            <el-descriptions-item label="标签名称">{{form.name || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="状态" :span="2">{{form.status || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="备注" :span="3">{{form.content || commonConfig.$nullData }}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertCasesCollect, updateCasesCollect, casesCollectInfo } from '@/api/casesCollect'
import { casesTemplateInfo } from '@/api/caseReport'
export default {
    props: {
        id: String
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.id && this.getCasesCollectInfo()
            }
        }
    },
    data() {
        return {
            timeStamp: '',
            form: {
                name: '',
                status: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                status: [{ required: true, message: '请选择模板状态', trigger: 'change' }]
            },
            // 状态 0 启用 1禁用
            statusOpts: [
                { value: '0', label: '启用' },
                { value: '1', label: '禁用' }
            ],
            visible: false
        }
    },
    methods: {
        getCasesCollectInfo() {
            casesCollectInfo({ id: this.id }).then(({ res, timeStamp }) => {
                const statusMap = {'0': '启用', '1': '禁用'}
                const { name, status, content } = res
                this.form = { name, status: statusMap[status], content }
                this.timeStamp = timeStamp
            })
        },
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (!valid) {
                    return
                }
                let param = this.form
                if (this.id) {
                    param.id = this.id
                    updateCasesCollect(param, this.timeStamp).then(res => {
                        this.submitSuccess(res)
                    })
                } else {
                    insertCasesCollect(param).then(res => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
