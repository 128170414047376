/*
 * @Author: your name
 * @Date: 2021-11-18 10:45:35
 * @LastEditTime: 2021-12-01 16:50:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\patientTreatment.js
 */

/**
 * 页面: 患者治疗
 * pageName: patientTreatment
 **/
import request from '@/utils/request';


/**
 * @description: 导出治疗记录
 */
export function exportTreat(data) {
    return request({
        url: '/patientTreatment/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

/**
 * @description: 获取患者治疗记录列表
 */
 export function getList(data) {
    return request({
        url: '/patientArchivesInfo/getAdviceList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取治疗计划列表
 */
 export function getTreatmentList(data) {
    return request({
        url: '/patientSchedule/treatmentPlan',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取治疗计划列表
 */
export function getTreatmentListByVisitId(data) {
  return request({
    url: '/patientSchedule/getTreatmentListByVisitId',
    method: 'GET',
    params: data,
    showLoading: true
  })
}



/**
 * @description: 获取患者治疗详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

