<!--
* @Description: 住院患者详情页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <div style="display:flex;margin:10px 0 5px 0">
                <el-descriptions class="margin-top" :column="4">
                    <el-descriptions-item label="患者姓名">
                        {{ details.name || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="住院号">
                        {{ details.recordNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="就诊号">
                        {{ details.serialNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag != 1" label="主治医生">
                        {{ details.impDocName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">{{ commonConfig.$sexList[details.sex] }}</el-descriptions-item>
                    <el-descriptions-item label="手机号码">
                        {{ details.phone || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号">
                        {{ details.idCard || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="所属科室">
                        {{ details.deptName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="床号">
                        {{ details.bedNum || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="主治医生">
                        {{ details.impDocName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="入院时间">
                        {{ moment(details.heDte).format('YYYY-MM-DD') || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag != 1" label="就诊时间">
                        {{ moment(details.heDte).format('YYYY-MM-DD') || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="家属姓名">
                        {{ details.faName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="家属手机号">
                        {{ details.faPhone || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="$route.query.fromFlag == 1" label="与患者关系">
                        {{ details.faRelation || commonConfig.$nullData }}
                    </el-descriptions-item>
                </el-descriptions>
                <div style="display:flex;align-items: center;margin-bottom:5px">
                    <el-button
                        type="default"
                        v-if="$route.query.fromFlag == 1"
                        @click="endTheTreatment(0)"
                    >
                        出院
                    </el-button>
                    <el-button
                        type="default"
                        v-if="$route.query.fromFlag != 1"
                        @click="endTheTreatment(3)"
                    >
                        结束治疗
                    </el-button>
                    <el-button v-if="editStatus" type="primary" @click="toEdit()">
                        编辑
                    </el-button>
                    <el-button type="default" @click="back()">返回</el-button>
                </div>
            </div>
        </el-header>
        <el-tabs
            v-model="activeName"
            style="flex: 1;margin-top:10px;border:0;box-shadow:none;display:flex"
            type="border-card"
            tab-position="left"
        >   
            
            <el-tab-pane
                :lazy="true"
                style="flex: 1; height: calc(100vh - 236px);"
                :label="$route.query.fromFlag == 1 ? '住院信息' : '就诊信息'"
                name="0"
            >
                <div class="tab1Box">
                    <div class="tab1TopBox">
                        <div class="tab1TopBoxLeft">
                            <div class="tab1TopBoxLeftTitle">
                                <div class="tab1TitleText">疾病诊断:</div>
                                <el-button
                                    type="text"
                                    style="padding: 10px"
                                    @click="clickDiagnosisAdd()"
                                    v-if="addDiagnosis"
                                >
                                    <div class="iconBox">
                                        <svg-icon icon-class="add" :style="{ color: '#469cf8', 'font-size': '14px' }" />
                                    </div>
                                </el-button>
                            </div>
                            <div v-for="item in diagnosisList" :key="item.id" class="tab1TopItemBox">
                                <div class="tab1TopItem">
                                    <div class="tab1TopTime">
                                        {{ moment(item.dateTime).format('YYYY-MM-DD HH:mm') }}
                                    </div>
                                    <div class="tab1TopItemText">
                                        {{ item.content }}
                                    </div>
                                </div>
                                <div style="display: flex">
                                    <el-button
                                        v-if="editDiagnosis"
                                        type="text"
                                        style="padding-top:2px"
                                        @click="clickDiagnosisedit(item)"
                                    >
                                        <div class="iconBox">
                                            <svg-icon
                                                icon-class="edit"
                                                :style="{ color: '#469cf8', 'font-size': '14px' }"
                                            />
                                        </div>
                                    </el-button>
                                    <el-button
                                        v-if="delDiagnosis"
                                        type="text"
                                        style="padding-top:2px"
                                        @click="clickDiagnosisdel(item.id)"
                                    >
                                        <div class="iconBox">
                                            <svg-icon
                                                icon-class="del"
                                                :style="{ color: '#469cf8', 'font-size': '14px' }"
                                            />
                                        </div>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div class="tab1TopBoxRight">
                            <div class="tab1TopBoxLeftTitle">
                                <div class="tab1TitleText">阶段目标:</div>
                                <el-button type="text" style="padding: 10px" @click="clickTargetAdd()" v-if="addTarget">
                                    <div class="iconBox">
                                        <svg-icon icon-class="add" :style="{ color: '#469cf8', 'font-size': '14px' }" />
                                    </div>
                                </el-button>
                            </div>
                            <div v-for="item in targetList" :key="item.id" class="tab1TopItemBox">
                                <div class="tab1TopItem">
                                    <div class="tab1TopTime">
                                        {{ moment(item.dateTime).format('YYYY-MM-DD HH:mm') }}
                                    </div>
                                    <div class="tab1TopItemText">
                                        {{ item.content }}
                                    </div>
                                </div>
                                <div style="display: flex">
                                    <el-button
                                        v-if="editTarget"
                                        type="text"
                                        style="padding-top:2px"
                                        @click="clickTargetedit(item)"
                                    >
                                        <div class="iconBox">
                                            <svg-icon
                                                icon-class="edit"
                                                :style="{ color: '#469cf8', 'font-size': '14px' }"
                                            />
                                        </div>
                                    </el-button>
                                    <el-button
                                        v-if="delTarget"
                                        type="text"
                                        style="padding-top:2px"
                                        @click="clickTargetdel(item.id)"
                                    >
                                        <div class="iconBox">
                                            <svg-icon
                                                icon-class="del"
                                                :style="{ color: '#469cf8', 'font-size': '14px' }"
                                            />
                                        </div>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab1BottomBox">
                        <div class="tab1TopItemBox">
                            <div class="tab1TopItem">
                                <div class="tab1BottomTime">主诉:</div>
                                <div class="tab1TopItemText">
                                    {{ details.chiefComplaint }}
                                </div>
                            </div>
                            <el-button
                                type="text"
                                style="padding-top:2px"
                                v-if="editComplain"
                                @click="clickChiefEdit()"
                            >
                                <div class="iconBox">
                                    <svg-icon icon-class="edit" :style="{ color: '#469cf8', 'font-size': '14px' }" />
                                </div>
                            </el-button>
                        </div>
                        <div class="tab1TopItemBox">
                            <div class="tab1TopItem">
                                <div class="tab1BottomTime">现病史:</div>
                                <div class="tab1TopItemText">
                                    {{ details.medicalHistory }}
                                </div>
                            </div>
                        </div>
                        <div class="tab1TopItemBox">
                            <div class="tab1TopItem">
                                <div class="tab1BottomTime">既往病史:</div>
                                <div class="tab1TopItemText">
                                    {{ details.pastMedicalHistory }}
                                </div>
                            </div>
                        </div>
                        <div class="tab1TopItemBox">
                            <div class="tab1TopItem">
                                <div class="tab1BottomTime">备注:</div>
                                <div class="tab1TopItemText">
                                    {{ details.remark }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane class="tab-pane" label="会诊记录" name="1">
                <!-- <div class="tab1Box"> -->
                    <record class="tab2Box" v-if="basicInfoId" :id="basicInfoId"/>
                <!-- </div> -->
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="康复医嘱" name="2">
                <generalTable
                    ref="adviceTable"
                    :table-data="adviceTableData"
                    :config="adviceTableConfig"
                    :total="adviceTotal"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button v-if="adviceListDetail" type="text" @click="toAdviceDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="康复评定" name="3">
                <generalTable
                    ref="assessmentTable"
                    :table-data="assessmentTableData"
                    :config="assessmentTableConfig"
                    :total="assessmentTotal"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button
                            type="text"
                            v-if="scope.data.row.patientScaleType === '0'"
                            @click="toAssessmentDetailByScale(scope)"
                        >
                            详情
                        </el-button>
                        <el-button
                            type="text"
                            v-if="scope.data.row.patientScaleType === '1'"
                            @click="toAssessmentDetailByProject(scope)"
                        >
                            方案详情
                        </el-button>
                        <el-button
                            type="text"
                            v-if="scope.data.row.patientScaleType === '2'"
                            @click="toAssessmentDetailByReport(scope)"
                        >
                            详情
                        </el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="治疗排程" name="4">治疗排程</el-tab-pane>
            <el-tab-pane :lazy="true" label="治疗记录" name="5">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(0)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="treatmentTableData"
                    :config="treatmentTableConfig"
                    :total="treatmentTotal"
                    @updatePage="getTreatMentList"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="toTreatmentDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane label="治疗小结" name="6">
                <conclusion v-if="$route.query.id" class="tab2Box" :basicInfoId="$route.query.id" />
            </el-tab-pane>
             <el-tab-pane label="治疗计划" name="7">
                <treatmentPlan v-if="$route.query.id" class="tab1Box" :basicInfoId="$route.query.id" />
            </el-tab-pane>
            <el-tab-pane label="病历夹" name="">
                <caseReport v-if="$route.query.id" class="tab2Box" :basicInfoId="$route.query.id" />
            </el-tab-pane>
            <!-- <el-tab-pane :lazy="true" label="治疗小结" name="6">治疗小结</el-tab-pane>
            <el-tab-pane :lazy="true" label="住院查房" name="7">住院查房</el-tab-pane>
            <el-tab-pane :lazy="true" label="出院指导" name="8">出院指导</el-tab-pane>
            <el-tab-pane :lazy="true" label="康复随访" name="9">康复随访</el-tab-pane>
            <el-tab-pane :lazy="true" label="检查" name="10">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(1)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="inspectionTableData"
                    :config="inspectionTableConfig"
                    :total="inspectionTotal"
                    @updatePage="getInspectionList"
                ></generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="检验" name="11">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(2)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="emrTableData"
                    :config="emrTableConfig"
                    :total="emrTotal"
                    @updatePage="getEmrList"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="getEmrDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane> -->
        </el-tabs>
        <addDiseaseDiagnosis ref="detail" :detail="editDetail" @submit="getDetailById" :timeStamp="timeStamp" />
        <endTheTreatment ref="endTheTreatment" :endTheTreatmentData="endTheTreatmentData" :timeStamp="timeStamp" />
        <updateChiefComplaint
            ref="ChiefDetail"
            :detail="editChiefDetail"
            :timeStamp="timeStamp"
            @submit="getDetailById"
        />
        <patient360 width="700px" height="500px" :id="$route.query.archivesInfoId" :basicInfoId="basicInfoId" />
        <addDialog :id="$route.query.id" ref="insert" :form="details" :timeStamp="timeStamp" @closeDialog="getDetailById" />
        <adviceDetailDialog :id="adviceCheckId" ref="adviceDetail" />
        <treatmentDetailDialog :id="TreatmentCheckId" ref="TreatmentDetail" />
        <emrDetailDialog :id="emrDetailId" ref="emrDetail" />
    </el-container>
</template>

<script>
import record from '@/components/Record'
import { getList as getAdviceList } from '@/api/advice'
import {
    getTreatmentInfo,
    deleteTreatmentInfo,
    getInspectionList,
    exportInspection,
    getEmrList,
    exportEmr,
    getAssessmentList
} from '@/api/patient'
import addDiseaseDiagnosis from '@/views/patient/patient/addDiseaseDiagnosis'
import endTheTreatment from '@/views/patient/patient/endTheTreatment'
import emrDetailDialog from '@/views/patient/patient/emrDetailDialog'
import updateChiefComplaint from '@/views/patient/patient/updateChiefComplaint'
import addDialog from '@/views/patient/patient/addDialog'
import patient360 from '@/views/other/Patient360'
import generalTable from '@/components/Table'
import moment from 'moment'
import adviceDetailDialog from '@/views/advice/detailDialog'
import { getTreatMentList } from '@/api/arrangeTreatment'
import treatmentDetailDialog from '@/views/treatment/arrangeTreatment/detailDialog'
import { exportTreat } from '@/api/patientTreatment'
import conclusion from '@/views/patient/summary'
import caseReport from '@/views/caseReport/tree/index'
import treatmentPlan from '@/views/patient/patient/treatmentPlan' // 患者治疗小结组件' // 患者治疗小结组件
export default {
    name: 'Detail',
    components: {
        patient360,
        addDiseaseDiagnosis,
        updateChiefComplaint,
        addDialog,
        endTheTreatment,
        generalTable,
        adviceDetailDialog,
        treatmentDetailDialog,
        emrDetailDialog,
        record,
        conclusion,
        caseReport,
        treatmentPlan
    },
    data() {
        return {
            visible: false,
            timeStamp: '',
            activeName: '0',
            basicInfoId: this.$route.query.id,
            details: {}, // 患者详情信息
            diagnosisList: [], // 疾病诊断数组
            targetList: [], // 阶段目标数组
            editDetail: {}, // 修改疾病诊断/阶段目标 信息
            editChiefDetail: {}, // 主诉信息
            endTheTreatmentData: {}, //结束治疗信息
            editStatus: true, //编辑患者档案
            addDiagnosis: true, //新增疾病诊断
            addTarget: true, //新增阶段目标
            editComplain: true, //编辑主诉及病史
            editDiagnosis: true, //编辑疾病诊断
            delDiagnosis: true, //删除疾病诊断
            editTarget: true, //编辑阶段目标
            delTarget: true, //删除阶段目标
            adviceTableData: [],
            adviceTotal: 0,
            adviceTableConfig: {
                id: 'advice',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType' // 必填
                    },
                    {
                        label: '操作项目名称', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        label: '医嘱类型', // 必填
                        prop: 'doctorOrderType', // 必填
                        formatter: (row, column) => {
                            return ['', '长期医嘱', '限定医嘱'][row.doctorOrderType]
                        }
                    },
                    {
                        label: '总次数', // 必填
                        prop: 'createCount', // 必填
                        formatter: (row, column) => {
                            if ('1' === row.doctorOrderType) {
                                return '长期'
                            } else {
                                return row.createCount
                            }
                        }
                    },
                    {
                        label: '单次数量', // 必填
                        prop: 'schCount' // 必填
                    },
                    {
                        label: '治疗频次', // 必填
                        prop: 'frequencyCount', // 必填
                        formatter: (row, column) => {
                            return (
                                row.frequencyCount+"次/" +
                                row.frequencyTime +
                                ["天", "周", "月", "年"][row.frequencyUnit]
                            )
                        }
                    },
                    {
                        label: '执行次数', // 必填
                        prop: 'usedCount' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'statusLabel' // 必填
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'adviceDate', // 必填
                        width: '150', // 不必填
                        formatter: (row, column) => {
                            return moment(row.adviceDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '190' // 不必填
                    }
                ]
            },
            adviceListDetail: true, //详情权限
            adviceCheckId: '',
            treatmentTableData: [],
            treatmentTableConfig: {
                id: 'treatmentTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'basicInfoName' // 必填
                    },
                    {
                        label: '住院号', // 必填
                        prop: 'recordNum' // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'serialNum' // 必填
                    },
                    {
                        label: '治疗师', // 必填
                        prop: 'therapyName' // 必填
                    },
                    {
                        label: '医嘱类型', // 必填
                        prop: 'doctorOrderTypeLabel' // 必填
                    },
                    {
                        label: '所属治疗', // 必填
                        prop: 'operTypeLabel' // 必填
                    },
                    {
                        label: '治疗项目', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        label: '治疗时间', // 必填
                        prop: 'treatmentDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.treatmentDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                    },
                    {
                        label: '状态', // 必填
                        prop: 'statusLabel' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            },
            treatmentTotal: 0,
            TreatmentCheckId: '',
            inspectionTableData: [], //检查数据
            inspectionTableConfig: {
                id: 'inspectionTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '检查名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '检查所见', // 必填
                        prop: 'checkSee' // 必填
                    },
                    {
                        label: '检查结果', // 必填
                        prop: 'checkResult' // 必填
                    },
                    {
                        label: '检查时间', // 必填
                        prop: 'therapyName', // 必填
                        formatter: (row, column) => {
                            return moment(row.therapyName).format('YYYY-MM-DD HH:mm:ss')
                        }
                    }
                ]
            }, //检查表格信息
            inspectionTotal: 0, //检查数据总条数
            emrTableData: [], //检验数据
            emrTableConfig: {
                id: 'emrTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '检验名称', // 必填
                        prop: 'rptTitle' // 必填
                    },
                    {
                        label: '样本类型', // 必填
                        prop: 'majorItemName' // 必填
                    },
                    {
                        label: '检查时间', // 必填
                        prop: 'submissionDate' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            }, //检验表格信息
            emrTotal: 0, //检验数据总条数
            emrDetailId: '', //检验ID
            assessmentTableData: [], //评定数据
            assessmentTableConfig: {
                id: 'emrTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类', // 必填
                        prop: 'patientScaleTypeLabel' // 必填
                    },
                    {
                        label: '量表名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '评定人', // 必填
                        prop: 'userName' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'patientScaleStatusLabel' // 必填
                    },
                    {
                        label: '评定日期', // 必填
                        prop: 'evaluateDate', // 必填
                        formatter: (row, column) => {
                            return moment(row.evaluateDate).format('YYYY-MM-DD')
                        }
                    },

                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            }, //评定表格数据
            assessmentTotal: 0 //评定总条数
        }
    },
    created() {
        this.getTreatMentList()
        this.adviceListDetail = this.hasPermission('advice-list-detail')
        if (this.$route.query.fromFlag == 1) {
            //fromFlag 1在院 2门诊
            this.editStatus = this.hasPermission('inhos-edit-record')
            this.addDiagnosis = this.hasPermission('inhos-add-diagnosis')
            this.addTarget = this.hasPermission('inhos-add-target')
            this.editComplain = this.hasPermission('inhos-edit-complain')
            this.editDiagnosis = this.hasPermission('inhos-edit-diagnosis')
            this.delDiagnosis = this.hasPermission('inhos-del-diagnosis')
            this.editTarget = this.hasPermission('inhos-edit-target')
            this.delTarget = this.hasPermission('inhos-del-target')
        } else {
            this.editStatus = this.hasPermission('outhos-edit-record')
            this.addDiagnosis = this.hasPermission('outhos-add-diagnosis')
            this.addTarget = this.hasPermission('outhos-add-target')
            this.editComplain = this.hasPermission('outhos-edit-complain')
            this.editDiagnosis = this.hasPermission('inhos-edit-diagnosis')
            this.delDiagnosis = this.hasPermission('inhos-del-diagnosis')
            this.editTarget = this.hasPermission('inhos-edit-target')
            this.delTarget = this.hasPermission('inhos-del-target')
        }
    },
    mounted() {
        this.getDetailById()
        this.getAdviceList()
        this.getInspectionList()
        this.getEmrList()
        this.getAssessmentList()
    },
    methods: {
        toExportTreatMent(flag) {
            const obj = {
                basicInfoId: this.basicInfoId
            }
            if (flag === 0) {
                //导出治疗记录
                exportTreat(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '治疗记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (flag === 1) {
                //导出检查
                exportInspection(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '检查记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (flag === 2) {
                //导出检验
                exportEmr(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '检验记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        toTreatmentDetail(scope) {
            this.TreatmentCheckId = scope.data.row.id
            this.$refs.TreatmentDetail.updateVisible(true)
        },
        /**
         * @description: 查看检验详情
         * @param {*}basicInfoId 患者住院id
         * @return {*}检验详情
         */
        getEmrDetail(scope) {
            this.emrDetailId = scope.data.row.id
            this.$refs.emrDetail.updateVisible(true)
        },
        getTreatMentList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = Object.assign(
                    this.$refs.treatmentTable ? this.$refs.treatmentTable.getPage() : { current: 1, size: 20 },
                    {
                        basicInfoId: this.$route.query.id,
                        allPatientFlag: '0' //不分页
                    }
                )
                getTreatMentList(param).then(({ res, timeStamp }) => {
                    this.treatmentTableData = res.records
                    this.treatmentTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取检验信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}检验信息
         */
        getEmrList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.$route.query.id,
                    allPatientFlag: '1'
                }
                getEmrList(param).then(({ res, timeStamp }) => {
                    this.emrTableData = res.records
                    this.emrTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取检查信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}检查信息
         */
        getInspectionList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.$route.query.id,
                    allPatientFlag: '1'
                }
                getInspectionList(param).then(({ res, timeStamp }) => {
                    this.inspectionTableData = res.records
                    this.inspectionTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取评估列表信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}评估列表
         */
        getAssessmentList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.$route.query.id
                }
                getAssessmentList(param).then(({ res, timeStamp }) => {
                    this.assessmentTableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 查看评估详情
         * @param {*}评估量表id
         * @return {*}评估详情
         */
        toAssessmentDetailByScale(scope) {
            this.$router.push({
                path: '/scale/patientScale',
                query: { patientScaleId: scope.data.row.scalePatientId, flag: 3 }
            })
        },
        /**
         * @description: 查看报告详情
         * @param {*}报告id
         * @return {*}报告详情
         */
        toAssessmentDetailByProject(scope) {
            console.log('toAssessmentDetailByProject', scope.data.row)
            this.$router.push({
                path: '/scale/patientScaleProgram',
                query: { patientScaleId: scope.data.row.scalePatientId, flag: 3 }
            })
        },
        /**
         * @description: 查看报告详情
         * @param {*}报告id
         * @return {*}报告详情
         */
        toAssessmentDetailByReport(scope) {
            this.$router.push({
                path: '/other/Patient360/assessmentReport',
                query: { id: scope.data.row.scalePatientId, printFlag: '1' }
            })
        },
        toAdviceDetail(scope) {
            this.adviceCheckId = scope.data.row.id
            this.$refs.adviceDetail.updateVisible(true)
        },
        getAdviceList() {
            this.$nextTick(() => {
                getAdviceList({ basicInfoId: this.$route.query.id }).then(({ res, timeStamp }) => {
                    this.adviceTableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        updateVisible(flag) {
            this.visible = flag
        },
        /**
         * @description: 结束治疗
         * @param {*}id :住院id status：出院状态
         * @return {*}
         */
        endTheTreatment(status) {
            this.endTheTreatmentData = { id: this.details.basicInfoId, status: status }
            this.$refs.endTheTreatment.updateVisible(true)
        },
        /**
         * @description: 返回按钮
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back()
        },
        /**
         * @description: 点击疾病诊断新增
         * @param {*}archivesInfoId ：基本信息id basicInfoId：住院id
         * @return {*}
         */
        clickDiagnosisAdd() {
            this.editDetail = {
                status: 0,
                archivesInfoId: this.details.id,
                basicInfoId: this.details.basicInfoId
            }
            this.$refs.detail.updateVisible(true)
        },
        /**
         * @description: 点击疾病诊断编辑
         * @param {*} item
         * @return {*}
         */
        clickDiagnosisedit(item) {
            this.editDetail = item
            this.$refs.detail.updateVisible(true)
        },
        /**
         * @description:点击疾病诊断删除
         * @param {*} 疾病诊断 id
         * @return {*}
         */
        clickDiagnosisdel(id) {
            const parm = { id: id }
            deleteTreatmentInfo(parm, this.timeStamp).then(({ res, timeStamp }) => {
                this.getDetailById()
            })
        },
        /**
         * @description: 点击阶段目标新增
         * @param {*}archivesInfoId ：基本信息id basicInfoId：住院id
         * @return {*}
         */
        clickTargetAdd() {
            this.editDetail = {
                status: 1,
                archivesInfoId: this.details.id,
                basicInfoId: this.details.basicInfoId
            }
            this.$refs.detail.updateVisible(true)
        },
        /**
         * @description: 点击阶段目标编辑
         * @param {*}阶段目标
         * @return {*}
         */
        clickTargetedit(item) {
            this.editDetail = item
            this.$refs.detail.updateVisible(true)
        },
        /**
         * @description: 点击阶段目标删除
         * @param {*}阶段目标/疾病诊断id
         * @return {*}
         */
        clickTargetdel(id) {
            const parm = { id: id }
            deleteTreatmentInfo(parm, this.timeStamp).then(({ res, timeStamp }) => {
                this.getDetailById()
            })
        },
        /**
         * @description: 获取详情信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}患者信息
         */
        getDetailById() {
            const parm = { basicInfoId: this.$route.query.id }
            if (this.$route.query.id) {
                getTreatmentInfo(parm).then(({ res, timeStamp }) => {
                    this.details = res
                    this.diagnosisList = res.diagnosisList
                    this.targetList = res.targetList
                    this.timeStamp = timeStamp
                })
            }
        },
        /**
         * @description: 点击修改
         * @param {*}
         * @return {*}
         */
        clickChiefEdit() {
            this.editChiefDetail = this.details
            this.$refs.ChiefDetail.updateVisible(true)
        },
        toEdit() {
            this.$refs.insert.getMessageByDetail(true, this.details, true, false)
        }
    }
}
</script>
<style>
.el-tabs__content {
    height: 100%;
    background-color: #ffffff !important;
}
</style>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.tab1Box {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
}
.tab2Box {
    background-color: $white;
    height: calc(100vh - 234px);
    width: 100%;
    overflow-y: auto;
}
.tab1TopBox {
    display: flex;
    margin-bottom: 15px;
    height: 45%;
}
.tab1BottomBox {
    flex: 1;
    background-color: #f8f8f8;
    overflow-y: scroll;
    padding: 10px 0;
    width: 100%;
}
.tab1TopBoxLeft {
    overflow-y: scroll;
    margin-right: 7px;
    background-color: #f8f8f8;
    width: 47%;
}
.tab1TopBoxRight {
    overflow-y: scroll;
    background-color: #f8f8f8;
    flex: 1;
}
.el-tab-pane {
    flex: 1;
}
.el-tabs__content {
    flex: 1;
}
.tab1TopBoxLeftTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}
.iconBox {
    border-radius: 6px;
}
.tab1TitleText {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
}
.tab1TopItemBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 10px;
}
.tab1TopItem {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    margin-bottom: 10px;
    line-height: 22px;
    font-size: 13px;
    width: 100%;
}
.tab1TopTime {
    flex-shrink: 0;
    margin-right: 5px;
}
.tab1BottomTime {
    flex-shrink: 0;
    width: 120px;
    color: #666666;
    font-weight: 600;
}
.tab1TopItemText {
    width: 100%;
    word-break: break-all;
    word-wrap: break-word;
}
.case {
    width: 100%;
    height: 100%; 
    display: flex;
    &-tree {
        width: 200px;
        height: 100%;
    }
    &-report {
        width: calc(100% - 200px);
        height: 100%;
    }
}
</style>
