
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增表格筛选项配置' : '修改表格筛选项配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">筛选项信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="名称(CN)" prop="name">
                    <el-input
                        v-model="form.name"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                    />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="字段名称(EN)" prop="fieldName">
                        <el-input
                            v-model="form.fieldName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="控件类型" prop="type">
                        <el-select
                            v-model="form.type"
                            clearable
                            placeholder="请选择..."
                            filterable
                            >
                            <el-option
                                v-for="option in this.commonConfig.$disposeItemsOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="defaultSort">
                        <el-input
                            v-model="form.defaultSort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="form.remark" type="textarea" />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">默认值信息</div>
            <el-row :gutter="10" v-if="form.type === 0 || form.type === 1" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="">
                    <el-form-item label="默认值" prop="defaultValue">
                        <el-input
                            v-model="form.defaultValue"
                            placeholder="请输入..."
                            clearable
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" v-else type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="下拉数据类型" prop="optionDataType">
                        <el-select
                            v-model="form.optionDataType"
                            clearable
                            placeholder="请选择..."
                            filterable
                            >
                                <el-option
                                    key="0"
                                    label="JSON字符串"
                                    value="0"
                                />
                                <el-option
                                    key="1"
                                    label="接口数据"
                                    value="1"
                                />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="下拉数据" prop="optionData">
                        <el-input
                            v-model="form.optionData"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="下拉默认名称" prop="optionName">
                        <el-input
                            v-model="form.optionName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="下拉默认值" prop="optionValue">
                        <el-input
                            v-model="form.optionValue"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertMenuSearchData, editMenuSearchData,getMenuSearchDetail } from '@/api/customCollocation'
export default {
    props: {
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入...', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入...', trigger: 'blur' },
                ],
            },
            form: {
                name: '',
                type: 0,
                fieldName: '',
                defaultSort: '',
                defaultValue: '',
                optionName: '',
                optionValue: '',
                optionData: '',
                optionDataType: '',
                remark: '',
            },
            total: 0,
            visible: false,
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
            //...todo
          }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                const parm = this.form
                if (this.id === '') {
                    insertMenuSearchData(parm).then(({res}) => {
                        this.submitSuccess(res)
                    })
                } else {
                    editMenuSearchData(parm,this.timeStamp).then(({res}) => {
                        this.submitSuccess(res)
                    })
                }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail(){
            getMenuSearchDetail(this.id).then(({res,timeStamp}) => {
                this.form = res
            })
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...this.commonConfig.$handleSuccess})
                })
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        