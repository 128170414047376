<!--
 * @Author: ligc
 * @Date: 2021-11-25 16:19:25
 * @LastEditTime: 2021-12-24 17:03:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\treatment\patientTreatment\treatmentPlan.vue
-->

<template>
  <el-dialog
    width="50%"
    title="查看检验报告"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getEmrResult"
      >
        <template slot="itmna" slot-scope="scope">
          <div class="textBox">
            <div v-if="scope.data.row.isnormal==1">{{scope.data.row.itmna}}</div>
            <div v-if="scope.data.row.isnormal!=1" class="red">{{scope.data.row.itmna}}</div>
          </div>
        </template>
        <template slot="printval" slot-scope="scope">
          <div class="textBox">
            <div v-if="scope.data.row.isnormal=='1'">{{scope.data.row.printval}}</div>
            <div v-if="scope.data.row.isnormal!='1' && scope.data.row.imtFlag=='H'"><span class="red">{{scope.data.row.printval}}</span>{{scope.data.row.unit}}<span class="red">↑</span></div>
            <div v-if="scope.data.row.isnormal!='1' && scope.data.row.imtFlag=='L'"><span class="red">{{scope.data.row.printval}}</span>{{scope.data.row.unit}}<span class="red">↓</span></div>
          </div>
        </template>
        <template slot="referencerange" slot-scope="scope">
          <div class="textBox">
            <div v-if="scope.data.row.isnormal=='1'">{{scope.data.row.referencerange}}</div>
            <div v-if="scope.data.row.isnormal!='1'" class="red">{{scope.data.row.referencerange}}</div>
          </div>
        </template>
      </generalTable>
    </el-main>
    <span slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import generalTable from "@/components/Table";
import { getEmrResult } from "@/api/patient";

export default {
  name: "TreatmentPlan",
  components: {
    generalTable,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      visible: false,
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal↓
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label: "项目名称", // 必填
            slot: "itmna", // 必填
          },
          {
            label: "结果", // 必填
            slot: "printval", // 必填
            },
          {
            label: "参考", // 必填
            slot: "referencerange", // 必填
          },
        ],
      },
      paramObj: null, // 筛选项
      timeStamp: "",
      total: 0, // 总条数
    };
  },
  created() {
  },
  mounted: function () {
  },
  methods: {
    getEmrResult() {
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        getEmrResult({ patientEmrInfoId: this.id }).then(
          ({ res, timeStamp }) => {
            this.tableData = res.records;
            this.timeStamp = timeStamp;
          }
        );
      });
    },
  updateVisible(val) {
      this.visible = val;
      if(val){
        this.getEmrResult();
      }
  },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.textBox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: $danger;
}
</style>