/*
 * @Author: your name
 * @Date: 2021-11-18 10:45:35
 * @LastEditTime: 2021-11-19 15:33:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\myPatient.js
 */

/**
 * 页面: 我的在治患者
 * pageName: myPatient
 **/
import request from '@/utils/request'

/**
 * @description: 获取我的在治患者列表数据
 */
export function getList(data) {
    return request({
        url: '/patientUserRel/myPatient',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 取消收藏
 */
    export function cancelCollection(data, timeStamp) {
    return request({
        url: '/patientUserRel/cancel',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        