<!--
 * @Author: your name
 * @Date: 2022-01-20 11:05:45
 * @LastEditTime: 2022-02-08 13:44:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\kettle\detailDialog.vue
-->
<template>
  <el-dialog
    width="70%"
    :title="'查看'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
    >
    <el-form-item label="请求时间" prop="updateDate">
      <el-date-picker
        v-model="form.updateDate"
        align="right"
        type="datetime"
        clear-icon="el-input__icon"
        placeholder="选择日期"
        readonly="readonly"
        :picker-options="pickerOptions"
      />
    </el-form-item>
    <el-form-item label="任务标识" prop="jobKey">
      <el-input readonly="readonly" v-model="form.jobKey" />
    </el-form-item>
    <el-form-item label="执行结果" prop="stat">
      <el-input readonly="readonly"  v-model="statData[form.stat]" />
    </el-form-item>
    <el-form-item label="结果信息" prop="message">
      <el-input autosize readonly="readonly" type="textarea" v-model="form.message" />
    </el-form-item>
  </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {getDetail} from "@/api/etlLog";
export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      pickerOptions: this.commonConfig.$datePickerOptions,
      checkId: "",
      tableData: [],
      statData:["成功","失败"],
      tableConfig: {},
      form: {},
      total: 0,
      visible: false,
      stat:[
          {
          value: '0',
          label: '成功'
          },
          {
          value: '1',
          label: '失败'
          },
        ],//执行结果
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    /**
     * @description: 查看
     * @param {*}id 
     * @return {*}etlLog详情
     */
    getDetail() {
        getDetail({ id: this.id }).then(({res}) => {
          this.form = res
        })
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>