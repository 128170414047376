
<template>
    <el-dialog
        width="70%"
        :title="'治疗登记'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="患者姓名" prop="patientName">
                        <el-input
                            v-model="form.patientName"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled=""
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="床号" prop="bedNum">
                        <el-input
                            v-model="form.bedNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="住院号" prop="recordNum">
                        <el-input
                            v-model="form.recordNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="就诊号" prop="serialNum">
                        <el-input
                            v-model="form.serialNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">治疗信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="计划项目/设备">
                        <el-input
                            v-model="visibleOperName"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="计划时间" prop="planTime">
                        <el-input
                            v-model="form.planTime"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <!-- ruleArmariumResList -->
               <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗项目" prop="operationSelect">
                        <el-select
                            v-model="form.operationSelect"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            @change="changeOperationId"
                            @clerar="clearOperationId"
                        >
                            <el-option
                                v-for="(option, index) in form.ruleOperList"
                                :key="option.id"
                                :label="option.name"
                                :value="index"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="单次数量" prop="schCount">
                        <el-input
                            v-model.number="form.schCount"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗设备" prop="deviceSelect">
                        <el-select
                            v-model="form.deviceSelect"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            @change="changeDeviceSelect"
                            @clear="clearDeviceSelect"
                        >
                            <el-option
                                v-for="(option, index) in form.ruleArmariumResList"
                                :key="option.id"
                                :label="option.deviceName + option.deviceNumber"
                                :value="index"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗区" prop="consultingRoom">
                        <el-select
                            v-model="form.consultingRoom"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="(option) in consultingRoom"
                                :key="option.id"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- consultingRoom -->
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="治疗反应" prop="responseTreatment">
                        <el-input
                            v-model="form.responseTreatment"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 6}"
                            maxlength="100"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getDetail } from '@/api/arrangeTreatment'
import { treatmentWithoutAdvice } from '@/api/treatmentWithout'
import { getDict } from '@/api/common'
export default {
    props: {
        info: Object
    },
    created(){
        // 页面创建时，获取全部字典项
        this.getDict()
    },
    data() {
        return {
            // 设置必填项
            rules: {
                operationSelect: [
                    {
                        required: true,
                        message: '请选择治疗项目',
                        trigger: 'change'
                    }
                ],
                schCount:[
                    {
                        required: true,
                        message: '请输入数字并且不能为0',
                        trigger: 'blur',
                        type: 'number',
                        min: 1
                    }
                ],
            },
            consultingRoom: [], // 治疗诊室列表
            form: {
                ruleOperList: [],
                responseTreatment:'无',
                consultingRoom:'',
            },
            submitFlag: true, // 提交锁
            visible: false,
            doctorOrderMap: {}, // 医嘱类型map项
            visibleOperName: '', // 计划项目/设备  进入页面后的默认字段 无需修改
        }
    },
    watch: {
        /** 监听弹窗显隐状态
         * @description: 显隐状态更新时，触发该函数
         * @param {Boolean} newVal 更新之后的值
         * @param {Boolean} oldVal 更新之前的值
         * @return {*}
         */        
        visible(newVal, oldVal) {
            // 弹窗显示时，通过传入对象的id获取登记信息
            if (newVal === true) {
                this.getDetail(this.info.id)
            }
        }
    },
    methods: {
        //清空设备时，重新获取用户治疗区
        getRoomLabel(){
            this.getInfo(this.info.id, (res) => {
                this.form.consultingRoom = res.userConsultingRoom
            })
        },
        getDetail(){
            this.getInfo(this.info.id, (res) => {
                const {scheduleTime, scheduleTimeEnd, operName,deviceName} = res
                //是否有与计划项目/设备 匹配的项目
                const selectOpers = res.ruleOperList.filter(item => item.name === operName)
                this.visibleOperName = operName || deviceName
                let currentSelect = {}
                // 如果有匹配的设备，默认显示匹配的设备
                const selectDevices = res.ruleArmariumResList.filter(item => item.id === this.info.armariumId)
                let deviceSelect = ""
                //显示治疗区
                let consultingRoom = ""
                consultingRoom = res.userConsultingRoom
                if (this.info.armariumId && selectDevices.length) {
                    deviceSelect = selectDevices[0].deviceName
                }

                // 如果有匹配的信息，默认显示的操作项
                if (selectOpers.length > 0) {
                    const {id: operationId,name: operationSelect, ...selectOper} = selectOpers[0]
                    currentSelect = {
                        ...selectOper,
                        operationSelect,
                        operationId
                    }
                }

                // 将上一个页面出入的信息、从后台获取的信息、匹配的执行医嘱、计划时间、治疗反应的默认值放入form中进行显示
                this.form = {
                    ...res, 
                    ...this.info,
                    ...currentSelect, 
                    planTime: `${scheduleTime}-${scheduleTimeEnd}`,
                    responseTreatment: "无",
                    deviceSelect,
                    consultingRoom
                }
            })
        },
        //回调getDetail函数，重新获取用户治疗区
        getInfo(id, callback){
            const param = {
                id
            }
            getDetail(param).then(({res}) => {
                callback && typeof callback === "function" && callback(res)
            })
        },
        /** 获取全部的字典项
         * @description: 本页面要加载的字典项分别为医嘱类型及治疗诊室
         *      医嘱类型获取成功后，将数组按{value:label}的格式放入到doctorOrderMap中，以方便显示
         *      治疗诊室获取成功后，直接将数组更新到this.consultingRoom中，以方便下拉选取
         * @param {*}
         * @return {*}
         */        
        getDict(){
            const roomLabel = "consulting_room"
            this.getDictByType(roomLabel, (roomList) => {
                this.consultingRoom = [...roomList]
            })
        },
        /** 按类型获取字典项
         * @description: 字典项获取成功后，进入回调函数进行后续操作
         * @param {String} type 要获取的字典项名称
         * @param {Function} callback 回调函数，其中从后台获取的数据作为回调函数的参数传入
         * @return {*}
         */        
        getDictByType(type, callback){
            getDict({type}).then(({res}) => {
                if (callback && typeof callback === "function") {
                    callback(res[type])
                }
            })
        },
        /** 修改设备选中项
         * @description: 修改设备选中项时，修改选中的设备id
         * @param {Number} val Number
         * @return {*}
         */        
        changeDeviceSelect(val){
            const armariumId =  this.form.ruleArmariumResList[val] ? this.form.ruleArmariumResList[val].id : ""
            let consultingRoom = ""
            if (this.form.ruleArmariumResList[val]) {
                consultingRoom = this.form.ruleArmariumResList[val].consultingRoom
                this.form = {
                    ...this.form,
                    armariumId,
                    consultingRoom
                }
            } else {
                this.form = {
                    ...this.form,
                    armariumId,
                }
                this.getRoomLabel()
            }
        },
        /** 清空设备选中项
         * @description: 清空时，情空对应的设备id
         * @param {*}
         * @return {*}
         */   
        clearDeviceSelect(){
            this.form = {
                ...this.form,
                armariumId: ""
            }
        },
        /** 修改治疗项目选中项
         * @description: 清空时，清空对应的治疗项目id
         * @param {*}
         * @return {*}
         */
        changeOperationId(val){
            const operationId =  this.form.ruleOperList[val] ? this.form.ruleOperList[val].id : ""
            this.form = {
                ...this.form,
                operationId,
            },
            console.log("form",this.form);
        },

        /** 清空治疗项目选中项
         * @description: 清空时，清空对应的操作项id
         * @param {*}
         * @return {*}
         */   
        clearOperationId(){
            this.form = {
                ...this.form,
               operationId:""
            }
        },
        /** 提交
         * @description: 校验必填项，通过校验后，发送登记请求，请求成功
         *      提交时，添加设备锁，在前一次提交成功/失败前，不能进行下一次提交
         *      如果armariumId不为空时，提交参数中添加一个armariumId字段，字段内容即为armariumId
         * @param {*}
         * @return {*}
         */        
        submit() {
            if (!this.submitFlag) {
                return
            }
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const {
                        basicInfoId: basicId, 
                        id: scheduleId, 
                        responseTreatment, 
                        schCount, 
                        scheduleTime: usesTime, 
                        scheduleTimeEnd: usedTime,
                        consultingRoom,
                        armariumId,
                        operationId

                    } = this.form
                    let param = {
                        basicId,
                        scheduleId:this.info.id,
                        responseTreatment,
                        schCount,
                        usesTime,
                        usedTime,
                        consultingRoom,
                        operationId,
                        treatmentDate: new Date().getTime()
                    }
                    // 如果armariumId不为空时，提交参数中添加一个armariumId字段，字段内容即为armariumId
                    armariumId && (param.armariumId = armariumId)
                    this.submitFlag = false
                    treatmentWithoutAdvice([param]).then((res) => {
                        console.log("[param]",[param]);
                        this.submitSuccess()
                    }).catch((error) => {
                        this.submitFlag = true
                    })
                }
            })
        },
        /** 提交成功后，进行的回调函数
         * @description: 提交成功后，对提交锁进行解锁
         *      进行成功提示
         *      关闭弹出层
         *      触发上一页面组价"@submit"绑定的函数
         * @param {*} res 回调函数返回的数据
         * @return {*}
         */        
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        /** 更新弹出层显示状态
         * @description: 关闭时，清空form中的所有内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */        
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped>
</style>

