
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增设备信息' : '修改设备信息'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">设备信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="设备名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="设备型号" prop="model">
                        <el-input
                            v-model="form.model"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="所属治疗" prop="operType">
                        <el-select
                            v-model="form.operType"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in options"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="form.remark"
                            placeholder="请输入备注"
                            clearable
                            maxlength="200"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">关联治疗项</div>
            <el-row :gutter="10">
                <div class="table-btn-group">
                    <el-button type="primary" @click="toSelectOper()">新增</el-button>
                </div>
                <generalTable
                    ref="mainTable"
                    :table-data="operList"
                    :config="tableConfig"
                    :total="total"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" class="delBtn" @click="toDel(scope)" >删除</el-button>
                    </template>
                </generalTable>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <selectOperDialog ref="selectOperDialog" @selectedOper="selectedOper" :selectedIds="operIdList" />
    </el-dialog>
</template>
<script>

import { getDict } from "@/api/common";
import generalTable from '@/components/Table'
import { insertData, editData, getDetail } from "@/api/library";
import selectOperDialog from '@/views/device/library/selectOper'
export default {
    components: {
        generalTable,
        selectOperDialog,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            checkId: "",
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "治疗项名称", // 必填
                        prop: "name", // 必填
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right",
                        width: "220", // 不必填
                    },
                ],
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入设备名称",
                        trigger: "blur",
                    },
                ],
                operType: [
                    {
                        required: true,
                        message: "请选择所属治疗",
                        trigger: "change",
                    },
                ],
            },
            options: [],
            form: {
                name:"",
                model:"",
                operType:"",
                remark:"",
            },
            total: 0,
            operList:[],
            operIdList:[],
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                
                if (this.id) {
                    this.getDetail()
                }
                //...todo
            }
        },
    },
    created() {
        this.getoper_type();
    },
    mounted() {},
    methods: {
        selectedOper(selectedOperList){
            let operListCache = this.operList
            let operIdListCache = this.operIdList
            for(var i = 0;i<selectedOperList.length;i++){
                if(this.operIdList.indexOf(selectedOperList[i].id)==-1){
                    operListCache.push(selectedOperList[i])
                    operIdListCache.push(selectedOperList[i].id)
                }else{

                }
            }
            this.operList = operListCache
            this.operIdList = operIdListCache
            console.log(this.operList)
        },
        toSelectOper(){
            this.$refs.selectOperDialog.updateVisible(true)
        },
        getoper_type() {
            getDict({ type: 'oper_type' }).then(({res}) => {
                let typeOptionsCache = res.oper_type.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                this.options = typeOptionsCache
            });
        },
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    var parm = this.form;
                    parm.operIds = this.operIdList;
                    if (parm.operIds.length != 0) {
                        if (this.id === "") {
                            insertData(parm).then(({ res }) => {
                                this.submitSuccess(res);
                            });
                        } else {
                            parm.id = this.id
                            editData(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res);
                            });
                        }
                    } else {
                        this.$message({
                            message: '请选择关联治疗项',
                            type: 'warning'
                        });
                    }
                    // if (this.id === "") {
                    //     insertData(parm).then(({ res }) => {
                    //         this.submitSuccess(res);
                    //     });
                    // } else {
                    //     parm.id = this.id
                    //     editData(parm, this.timeStamp).then(({ res }) => {
                    //         this.submitSuccess(res);
                    //     });
                    // }
                }
            });
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.$emit("submit");
            this.updateVisible(false);
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = {
                        name:res.name,
                        model:res.model,
                        operType:res.operType,
                        remark:res.remark,
                    };
                this.timeStamp = timeStamp
                this.operList = res.operations
                this.operIdList = res.operations.map((item,index)=>{
                    return item.id
                })
            });
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                let operListCache= this.operList
                operListCache.splice(scope.data.$index,1)
                this.operList = operListCache
                this.operIdList = operListCache.map((item,index)=>{
                    return item.id
                })
            });
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form = {
                    name:"",
                    model:"",
                    operType:"",
                    remark:"",
                }
                this.operList=[];
                this.operIdList=[];
            }
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        