/*
 * @Author: your name
 * @Date: 2021-12-28 16:19:58
 * @LastEditTime: 2022-03-02 09:39:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\doctorWorkload.js
 */

/**
 * 页面: 医生工作量统计
 * pageName: doctorWorkload
 **/
import request from '@/utils/request'

/**
 * @description: 获取医生工作量统计列表数据
 */
export function getList(data) {
    return request({
        url: '/statistics/doctorWorkload/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 导出医生工作量统计列表数据
 */
 export function toExport(data) {
    return request({
        url: 'statistics/doctorWorkload/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

        