<!--
 * @Author: your name
 * @Date: 2021-12-24 10:02:04
 * @LastEditTime: 2021-12-30 10:44:50
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\order.vue
-->

<template>
    <el-main>
        <el-row v-if="!data.length">{{message}}</el-row>
        <el-row v-for="item in data" :key="item.id" class="card-row">
            <el-col :span="3">{{item.doctorOrderType === "1" ? "长期" : "限定"}}</el-col>
            <el-col :span="10">{{item.operName}}</el-col>
            <el-col :span="5">单量：{{item.schCount}}</el-col>
            <el-col :span="5">{{item.frequencyCount}}次/{{item.frequencyTime}}{{unit[item.frequencyUnit]}}</el-col>
            <el-col :span="1"><i class="el-icon-arrow-right more" @click="showDetail(item.id)"></i></el-col>
        </el-row>
        <detailDialog ref="orderDetail" :id="checkedId" @close="openPat360()"/>
    </el-main>
</template>

<script>
// src\views\advice\detailDialog.vue
import detailDialog from '@/views/other/Patient360/components/orderDetail'
export default {
    name: 'orderPat360',
    components: {
        detailDialog
    },
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Array
        }
    },
    data(){
        return {
            message: "暂无数据",
            checkedId: "",
            unit: ["天", "周", "月", "年"]
        }
    },
    methods: {
        /** 医嘱详情
         * @description: 触发父组件`@close`绑定的函数,关闭患者360组件,显示医嘱详情页
         * @param {*}
         * @return {*}
         */
        showDetail(id) {
            this.checkedId = id
            this.$emit("close")
            this.$refs.orderDetail.updateVisible(true)
        },
        /** 显示患者360组件
         * @description: 触发父组件`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        openPat360(){
            this.$emit("close")
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.card-row {
    display: flex;
    align-items: center;
    background-color: $dialogHeader;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.more {
    cursor: pointer;
    &:hover {
        color: $arrowActive;
    }
}
</style>