<!--
 * @Author: your name
 * @Date: 2022-02-17 09:03:36
 * @LastEditTime: 2022-08-23 09:10:19
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\scale\scale\Questionnaires.vue
-->
<template>
    <el-row class="table-btn-group">
        <!-- <div :id="id" v-html="scaleHtml"></div> -->
        <div :id="id" class="questionnaires">
            <span v-if="!scaleId" class="basic-info">
                <span v-for="basicInfoItem in basicInfoList" :key="basicInfoItem.id" v-show="showHeader">{{ basicInfoItem }}</span>
            </span>
            <div class="scale-title">{{ scaleName }}</div>
            <el-divider />
            <div v-for="(queItem, queIndex) in queList" :key="queItem.id">
                <div class="question" :data-type="queItem.answerType" :id="queItem.id">
                    <div
                        class="question-title"
                        :class="queItem.validRule && queItem.validRule.required ? 'required' : ''"
                    >
                        {{ queItem.name }}
                    </div>
                    <div class="question-img" v-if="queItem.imageData">
                        <el-image :src="queItem.imageData"></el-image>
                    </div>
                    <!-- queItem.answerType: "0" => 单选 ; "1" => 多选 ; "2" => 问答 ; "3" => 小结 ; "4" => 填空 -->
                    <template v-if="['0', '1'].includes(queItem.answerType)">
                        <div
                            v-for="optionItem in queItem.optionList"
                            :key="optionItem.id"
                            :class="[
                                checkMap[queItem.answerType],
                                queItem.selectOpts.includes(optionItem.id) ? 'active' : ''
                            ]"
                            @click="scaleType === '2' && select(queIndex, optionItem, $event)"
                            class="answer-check"
                            :data-question="queItem.id"
                            :data-mark="optionItem.mark"
                            :data-id="optionItem.id"
                            :style="{
                                cursor:
                                    scaleType === '2' && !queItem.selectOpts.includes(optionItem.id)
                                        ? 'pointer'
                                        : 'default'
                            }"
                        >
                            {{ optionItem.name }}
                        </div>
                    </template>
                    <template v-if="['2'].includes(queItem.answerType)">
                        <el-input
                            :id="queItem.answerCoordinate"
                            v-model="queItem.answerContent"
                            @keyup.native="inputKeyup(queItem, $event)"
                            @change="inputChange(queItem, $event)"
                            :data-question="queItem.id"
                            type="textarea"
                            rows="4"
                            resize="none"
                            placeholder="请输入..."
                            :readonly="(queItem.validRule && queItem.validRule.readonly) || scaleType !== '2'"
                        />
                    </template>
                    <template v-if="['3', '4'].includes(queItem.answerType)">
                        <el-input
                            :id="queItem.answerCoordinate"
                            v-model="queItem.answerContent"
                            @keyup.native="inputKeyup(queItem, $event)"
                            @change="inputChange(queItem, $event)"
                            :data-question="queItem.id"
                            placeholder="请输入..."
                            :readonly="(queItem.validRule && queItem.validRule.readonly) || scaleType !== '2'"
                        />
                    </template>
                </div>
            </div>
        </div>
    </el-row>
</template>

<script>
import { getScaleInfoQuestionByPatientScaleId, lastTimePatientAnswer } from '@/api/publicScaleLib'
import { savePatientAnswer, getRule, calculate } from '@/api/patientAssessment'
import { getBasicScaleQuestion, getDetail } from '@/api/publicScaleLib'
import { getScaleQuestion, getDetailPrivate } from '@/api/privateScaleLib'
import { getTreatmentInfo } from '@/api/patient'
import { getPatientPackage } from '@/api/common'
import $ from 'jquery'
import { options } from 'runjs'

export default {
    name: 'questionnaires',
    props: {
        scaleId: {
            type: String,
            required: false
        },
        libFlag: {
            type: String,
            required: false
        },
        id: {
            type: String
        },
        basicInfoId: {
            type: String
        },
        scaleType: {
            type: String
        },
        evaluateByName: {
            type: String
        },
        evaluateDate: {
            type: Number
        },
        fileList: {
            type: Array
        },
        showMould: {
            type: String,
            required: false
        },
        showHeader: {//是否显示表头
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            checkMap: {
                '0': 'radio',
                '1': 'checkbox'
            },
            scaleName: '',
            queList: [],
            scaleHtml: '',
            answerList: [],
            marksRule: '',
            resultsRule: '',
            basicInfoList: []
        }
    },
    watch: {
        id(newVal, oldVal) {
            if (this.scaleId) {
                this.showMouldList()
                return
            }
            if (!newVal) {
                return
            }
            this.initPage()
        },
        scaleId(newVal, oldVal) {
            if (newVal) {
                this.showMouldList()
                return
            }
        }
    },
    created() {
        if (this.scaleId) {
            this.showMouldList()
            return
        }
        this.initPage()
    },
    methods: {
        showMouldList() {
            console.log('------ques------', this.scaleId, this.showMould)

            if (this.libFlag === 'publicLib') {
                // 公共量表库
                getDetail({id:this.scaleId}).then(({res}) => {
                    this.scaleName = res.name
                })
                getBasicScaleQuestion({ scaleInfoId: this.scaleId, scaleQuestionTypeId: '' }).then(({ res }) => {
                    this.initEmptyQues(res)
                })
            } else {
                // 评估量表库
                getDetailPrivate({id:this.scaleId}).then(({res}) => {
                    this.scaleName = res.name
                })
                getScaleQuestion({ scaleInfoId: this.scaleId, scaleQuestionTypeId: '' }).then(({ res }) => {
                    this.initEmptyQues(res)
                })
            }
        },
        initEmptyQues(res) {
            console.log(`------${this.libFlag}-------`, res)
            this.queList = res.map(item => ({ ...item, selectOpts: [] }))
            this.answerList = []
        },
        initPage() {
            this.getPatientPackage()
            this.getRule()
        },
        /**
         * @description: 获取评分规则,在提交之前使用
         * @param {*}
         * @return {*}
         */
        getRule() {
            if (!this.id) {
                return
            }
            getRule({ scalePatientId: this.id }).then(({ res }) => {
                this.marksRule = res.marksRule
                this.resultsRule = res.resultsRule
                this.getScaleInfoQuestionByPatientScaleId()
            })
        },
        getPatientPackage() {
            if (!this.basicInfoId) {
                return
            }
            getPatientPackage(0).then(({ res }) => {
                // this.basicInfoList = res.res
                getTreatmentInfo({ basicInfoId: this.basicInfoId }).then(({ res: treatInfoRes }) => {
                    const { choose } = res
                    this.basicInfoList = choose.sort((a,b) => a.sort-b.sort).map(chooseItem => {
                        let content = ""
                        if (treatInfoRes[chooseItem.keyField] instanceof Array) {
                            content = treatInfoRes[chooseItem.keyField].map(item => item.content).join("。")
                        } else {
                            content = chooseItem.keyField === "sex" ? this.commonConfig.$sexList[treatInfoRes[chooseItem.keyField]] : treatInfoRes[chooseItem.keyField]
                        }
                        return `${chooseItem.name}:${content || this.commonConfig.$nullData}`
                    })
                })
            })
        },
        /**
         * @description: 获取量表问题/评估人/评估时间/文件列表并返回父级
         * @param {*}
         * @return {*}
         */
        getScaleInfoQuestionByPatientScaleId() {
            getScaleInfoQuestionByPatientScaleId({ id: this.id }).then(({ res, timeStamp }) => {
                this.updateHtml(res)
            })
        },
        /** 更新页面
         * @description: 更新评估人、评估时间、编辑状态、附件列表及问题与答案
         * @param {*} res
         * @return {*}
         */
        updateHtml(res) {
            this.$emit('setevaluate', res.evaluateByName, res.evaluateDate, res.status, res.fileList)
            this.scaleName = res.scaleName
            this.queList = res.questionList.map(item => {
                const { validRule, ...others } = item
                const { answerCoordinate, answerType } = others
                const currentAnswer = res.answerList.filter(item => item.answerCoordinate === answerCoordinate)
                const answer = currentAnswer.length ? currentAnswer[0] : {}
                let newRule = validRule ? JSON.parse(`{${validRule}}`) : {}
                let quesAnswer = {}
                // "0" => 单选 ; "1" => 多选 ;
                if (['0', '1'].includes(answerType)) {
                    quesAnswer.selectOpts = answer.scaleQuestionOptionId ? answer.scaleQuestionOptionId.split(',') : []
                    quesAnswer.selectOpts = quesAnswer.selectOpts.filter(item => item)
                    quesAnswer.lsnArray = answer.answerLsn ? answer.answerLsn.split(',') : []
                }
                // "2" => 问答 ; "3" => 小结 ; "4" => 填空;
                if (['2', '3', '4'].includes(answerType)) {
                    quesAnswer.answerContent = answer.answerContent || ''
                }
                return {
                    ...others,
                    ...quesAnswer,
                    validRule: newRule
                }
            })
        },
        /** 点击选择题
         * @description: 单选题选中当前项;多选题将未选中的选中,将选中的取消选中
         * 选项修改后,重新计算结果
         * @param {*} index
         * @param {*} optionItem
         * @return {*}
         */

        select(index, optionItem) {
            // answerType: "0" => 单选 ; "1" => 多选 ;
            const { answerType } = this.queList[index] // 选择类型
            const { id, lsn } = optionItem // 选项id
            if (answerType === '0') {
                this.queList[index].selectOpts = [id]
                this.queList[index].lsnArray = [lsn]
            } else {
                if (this.queList[index].selectOpts.includes(id)) {
                    // 已选中的取消选中
                    if (this.queList[index].selectOpts.length === 1) {
                        this.$message({
                            type: 'warning',
                            message: '多选题至少选择一项!'
                        })
                    } else {
                        this.queList[index].selectOpts = this.queList[index].selectOpts.filter(item => item !== id)
                        this.queList[index].lsnArray = this.queList[index].lsnArray.filter(item => item !== lsn)
                    }
                } else {
                    // 未选中的插入选中
                    this.queList[index].selectOpts = [...this.queList[index].selectOpts, id]
                    this.queList[index].lsnArray = [...this.queList[index].lsnArray, lsn]
                }
            }
            this.calculateRes(this.queList)
        },
        /** 输入框/文本域keyup事件监听
         * @description:
         * @param {*} queItem
         * @return {*}
         */

        inputKeyup(queItem) {
            if (!queItem.validRule.type || queItem.validRule.type !== 'number') {
                return
            }
            const step = queItem.validRule && queItem.validRule.step ? queItem.validRule.step : '1'
            const decimals = step.includes('.') ? step.split('.')[1].length : 0
            if (decimals === 0) {
                queItem.answerContent = queItem.answerContent.replace(/^(\-)*(\d+).*$/, '$1$2') //只能输入整数
            } else if (decimals === 1) {
                queItem.answerContent = queItem.answerContent.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3') //只能输入两个小数
            } else if (decimals === 2) {
                queItem.answerContent = queItem.answerContent.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
            } else {
                queItem.answerContent = queItem.answerContent.replace(/[^\d.-]/g, '') // 去掉数字、点、负号以外的全部内容
            }
        },
        inputChange(queItem) {
            if (!queItem.validRule.type || queItem.validRule.type !== 'number') {
                return
            }
            const { validRule } = queItem
            const min = validRule.min ? Number(validRule.min) : -Infinity
            const max = validRule.max ? Number(validRule.max) : +Infinity
            const validRuleCount = validRule.validRuleCount
            let { answerContent, answerType } = queItem
            let flag = ''
            let messageMap = {
                min: {
                    message: `最小值为${min}`,
                    res: min
                },
                max: {
                    message: `最大值为${max}`,
                    res: max
                },
                error: {
                    message: `数值范围与逻辑表达式冲突,请重新配置题目规则`,
                    res: ''
                }
            }
            answerContent = Number(answerContent)
            const validRuleArray = validRule.validRuleArray ? eval(`[${validRule.validRuleArray}]`) : []
            if (min > answerContent) {
                flag = validRuleCount ? 'error' : 'min'
            } else if (max < answerContent) {
                flag = validRuleCount ? 'error' : 'max'
            } else if (validRuleArray.length && !validRuleArray.includes(answerContent)) {
                flag = 'error'
                messageMap[flag].message = `请输入${validRule.validRuleArray}任意一项！`
            }

            if (!flag) {
                this.calculateRes(this.queList)
                return
            }
            this.$message({
                type: 'warning',
                message: messageMap[flag].message
            })
            // 没有计算逻辑的，按最大最小值修改
            !validRuleCount && (queItem.answerContent = messageMap[flag].res)
            this.calculateRes(this.queList)
        },
        getRes(queList) {
            queList = queList ? queList : this.queList
            let data = {
                count: (souceArray, val) => {
                    const countArray = this.queList.filter(item => souceArray.includes(item.answerCoordinate))
                    const sum = countArray.length
                        ? countArray
                              .map(item => {
                                  if (item.answerContent && Number(item.answerContent) === val) {
                                      return 1
                                  } else {
                                      return 0
                                  }
                              })
                              .reduce((a, b) => a + b)
                        : 0
                    return sum
                }
            }
            queList.forEach(queItem => {
                const { answerCoordinate, answerType, selectOpts, answerContent, optionList } = queItem
                if (['0', '1'].includes(answerType)) {
                    const markArray = optionList.filter(item => selectOpts.includes(item.id))
                    const sumMark = markArray.length ? markArray.map(item => item.mark).reduce((a, b) => a + b) : 0
                    data[answerCoordinate] = sumMark
                } else {
                    data[answerCoordinate] = Number(answerContent) ? Number(answerContent) : answerContent
                }
            })
            return data
        },
        calculateRes(queList) {
            let data = this.getRes(queList)
            queList = queList.map(item => {
                if (!item.validRule || !item.validRule.validRuleCount) {
                    return item
                } else {
                    const validRuleCount = eval(item.validRule.validRuleCount)
                    return {
                        ...item,
                        answerContent:
                            typeof validRuleCount === 'number'
                                ? validRuleCount === parseInt(validRuleCount)
                                    ? validRuleCount.toFixed(0)
                                    : validRuleCount.toFixed(1)
                                : validRuleCount
                    }
                }
            })
            this.queList = [...queList]
        },
        validation(queList) {
            queList = queList ? queList : this.queList
            for (let queItem of queList) {
                if (queItem.validRule && queItem.validRule.required) {
                    if (['0', '1'].includes(queItem.answerType) && !queItem.selectOpts.length) {
                        return false
                    } else if (['2', '3', '4'].includes(queItem.answerType) && !queItem.answerContent) {
                        return false
                    }
                }
            }
            return true
        },
        /**
         * @description: 提交/保存答案
         * @param {String} type 1保存/2提交
         * @return {*}
         */
        submit(type, success, failure) {
            let data = this.getRes(this.queList)
            const flag = this.validation(this.queList)
            console.log("-------提交/保存问卷", type, flag)
            if (type === 2 && !flag) {
                this.$message({
                    type: 'warning',
                    message: '请输入或选择必填项!'
                })
                return
            }
            this.calculate(data, type, success, failure)
        },
        /** 计算评分及规则
         * @description: 先计算评分再计算规则，计算成功后更新答案
         * @param {*} data
         * @param {*} type
         * @return {*}
         */

        calculate(data, type, success, failure) {
            calculate({
                formula: this.marksRule,
                data: data
            })
                .then(({ res: markRes, timeStamp }) => {
                    if (this.marksRule && typeof eval(markRes) !== 'number') {
                        this.$message({
                            type: 'warning',
                            message: '分数书写格式有误,请校验规则后,重新修改!'
                        })
                        return
                    }
                    calculate({
                        formula: this.resultsRule,
                        data: data
                    })
                        .then(({ res: resultRes, timeStamp }) => {
                            this.updateAnswer(markRes, resultRes, data, type, timeStamp, success)
                        })
                        .catch(error => {
                            console.log("-------计算规则失败", error)
                            failure && typeof failure === 'function' && failure()
                        })
                })
                .catch(error => {
                    console.log("-------计算分数失败", error)
                    failure && typeof failure === 'function' && failure()
                })
        },

        updateAnswer(markRes, resultRes, data, type, timeStamp, success) {
            const answerDetail = this.queList.map(queItem => {
                const { id, answerType, answerCoordinate, selectOpts, lsnArray } = queItem
                let resItem = { questionId: id }
                if (['0', '1'].includes(answerType)) {
                    resItem.answerMark = Number(data[answerCoordinate])
                    resItem.optionId = (selectOpts && selectOpts.length) ? selectOpts.join() : ""
                    resItem.answerLsn = (lsnArray && lsnArray.length) ? lsnArray.join() : ""
                } else {
                    resItem.answerContent = data[answerCoordinate]
                }
                return resItem
            })
            const params = {
                answerDetail,
                evaluateByName: this.evaluateByName,
                evaluationResults: eval(resultRes) || '',
                evaluateDate: new Date(this.evaluateDate).getTime(),
                scalePatientId: this.id,
                fileList: this.fileList.map(item => {
                    return item.id
                }),
                status: type, //1保存 2提交
                fullMarks: eval(markRes) || 0
            }
            savePatientAnswer(params, timeStamp).then(({ res }) => {
                if (success && typeof success === 'function') {
                    success()
                } else {
                    this.$message({
                        message: type == 1 ? '保存成功' : '提交成功',
                        type: 'success'
                    })
                    type === 2 && this.$emit('success')
                }
            })
        },
        /**
         * @description: 复制上一次提交的答案
         * @param {*}
         * @return {*}
         */
        lastTimePatientAnswer() {
            lastTimePatientAnswer({ id: this.id }).then(({ res, timeStamp }) => {
                if (res.id) {
                    this.updateHtml(res)
                } else {
                    this.$message({
                        message: '无可复制量表',
                        type: 'warning'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.basic-info{
    display: flex;
    line-height: 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    margin:15px 0;
}
.scale-title{
    margin-top: 40px;
}
</style>
