
<template>
    <el-dialog width="70%" :title="id === '' ? '新增康复质控' : '修改康复质控'" :visible.sync="visible"
        :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox"
            :rules="rules">
            <div class="mdrcc-info-title">
                康复质控信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="指标名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="10" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="推送类型" prop="pushType">
                        <!-- 0 消息提醒且任务推送 1消息提醒 2任务推送 (消息类型8) -->
                        <el-select v-model="form.pushType" placeholder="请选择" :collapse-tags="true" style="width:100%">
                            <el-option v-for="option in pushTypeOptions" :key="option.value" :label="option.label"
                                :value="option.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="state">
                        <!-- 0 消息提醒且任务推送 1消息提醒 2任务推送 (消息类型8) -->
                        <el-select v-model="form.state" placeholder="请选择" :collapse-tags="true" style="width:100%">
                            <el-option v-for="option in stateOptions" :key="option.value" :label="option.label"
                                :value="option.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="描述" prop="description">
                        <el-input v-model="form.description" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="推送内容" prop="content">
                        <el-input v-model="form.content" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="form.remark" placeholder="请输入" clearable type="textarea" :rows="3" resize="none"
                            maxlength="200" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/recoveryManagement'

export default {
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            checkId: '',
            rules: {
                name: [
                    { required: true, message: '请输入指标名称', trigger: 'blur' },
                ],
                pushType: [
                    { required: true, message: '请选择推送类型', trigger: 'change' }
                ],
                state: [
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],
            },
            // 0 消息提醒且任务推送 1 消息提醒 2 任务推送
            pushTypeOptions: [
                {
                    value: '0',
                    label: '消息提醒且任务推送'
                },
                {
                    value: '1',
                    label: '消息提醒'
                },
                {
                    value: '2',
                    label: '任务推送'
                },
            ],
            // 0 启用 1 禁用
            stateOptions: [
                {
                    value: '0',
                    label: '启用'
                },
                {
                    value: '1',
                    label: '禁用'
                },
            ],
            form: {
                name: '',
                pushType: "0",
                state: "0",
                description: "",
                content: "",
                remark: ""
            },
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail()
                }
                //...todo
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    if (this.id === '') {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res
                this.timeStamp = timeStamp
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>

</style>
      
