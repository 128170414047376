/*
 * @LastEditors: Please set LastEditors
 * @Author: ligc
 */
import request from '@/utils/request'

/**
 * @description: 疾病诊断详情
 */
export function getDetail(data) {
    return request({
        url: '/patientBasicTargetContent/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增疾病诊断/阶段目标
 */
 export function addDiseaseDiagnosis(data) {
    return request({
        url: '/patientBasicTargetContent/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改疾病诊断/阶段目标
 */
 export function updateDiseaseDiagnosis(data, timeStamp) {
    return request({
        url: '/patientBasicTargetContent/update',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}
