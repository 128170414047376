
<template>
  <el-dialog
    width="70%"
    :title="libFlag === 'publicLib' ? '下发量表库量表' : '复制量表库量表'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title" v-if="libFlag === 'publicLib'">
        下发信息
      </div>
      <div class="mdrcc-info-title" v-if="libFlag === 'privateLib'">
        复制信息
      </div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="量表分类" prop="typeId">
            <el-select
              v-model="form.typeId"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in typeDict"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属机构" class="formItem" prop="deptIds">
            <el-cascader
              v-model="form.deptIds"
              :options="officeTree"
              :props="{
                checkStrictly: true,
                multiple: true,
                label: 'name',
                value: 'id',
              }"
              clearable
              filterable
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getTypeScale } from "@/api/privateScaleLib";
import { getDeptTree } from "@/api/organization";
import { issuedScale } from "@/api/publicScaleLib";
import { copyDataPrivate } from "@/api/privateScaleLib";
export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
    libFlag: {
      type: String,
    },
  },
  data() {
    return {
      typeDict: [],
      officeTree: [],
      rules: {
        typeId: [
          { required: true, message: "请选择量表分类", trigger: "change" },
        ],
        deptIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个机构",
            trigger: "change",
          },
        ],
      },
      form: { typeId: "", deptIds: [] },
      visible: false,
    };
  },
  created() {
    this.getScaleTypeList();
    this.loadTree();
  },
  mounted() {},
  methods: {
    loadTree() {
      getDeptTree().then(({ res }) => {
        this.officeTree = res;
      });
    },
    getScaleTypeList() {
      getTypeScale({status:'0'}).then(({ res, timeStamp }) => {
        this.typeDict = res.records;
      });
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          let param = this.form;
          let arr = [];
          this.form.deptIds.forEach((ele) => {
            if (ele.length > 0) {
              arr.push(ele[ele.length - 1]);
            }
          });
          param.deptIds = arr;
          param.scaleInfoId = this.id;
          if (this.libFlag === "publicLib") {
            issuedScale(param, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            const param = {
              id: this.id,
              deptIds: arr, 
              typeId: this.form.typeId, //所属类型id
            };
            /**
             * @description:评估量表库复制方法
             * @param {*} deptIds 机构id  typeId 所属类型id id量表模板id
             * @return {*}
             */
            copyDataPrivate(param, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang='scss'>
</style>
      
        