<!--
 * @Author: your name
 * @Date: 2021-11-17 16:40:05
 * @LastEditTime: 2022-07-25 09:26:36
 * @LastEditors: ZF-WLY
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\advice\patientList.vue
-->

<!--
* @Description: 医嘱管理-患者列表
* @Reference:
-->

<template>
  <el-container ref="container">
    <el-tabs type="border-card" style="width: 100%" @tab-click="tabClick">
      <el-tab-pane :lazy="true" label="我的患者"
        ><myPatientList v-if="tableShowFlag==0" :tableHeight="containerHeight" selectFlag="1"
      /></el-tab-pane>
      <el-tab-pane :lazy="true" label="全部患者" v-if="allPatientBotton" 
        ><allPatientList v-if="tableShowFlag==1" :tableHeight="containerHeight" selectFlag="0"
      /></el-tab-pane>
    </el-tabs>
  </el-container>
</template>

<script>
import myPatientList from "@/views/advice/myPatientList";
import allPatientList from "@/views/advice/myPatientList";

export default {
  name: "patientList",
  components: {
    myPatientList,
    allPatientList
  },
  data() {
    return {
      status: 1,
      containerHeight: 0,
      tableShowFlag:0,
    };
  },
  created() {
    this.allPatientBotton = this.hasPermission('all-patient-list');
    this.getContainerHeight();
  },
  mounted: function() {},
  methods: {
    tabClick(data){
      this.tableShowFlag = data.index
      console.log(data.index)
    },
    /**
     * @description:通过手动获取container高度,再删减部分高度,赋值给表格组件, 否则会出现污染高度情况
     */
    getContainerHeight() {
      this.$nextTick(() => {
        this.containerHeight = this.$refs.container.$el.clientHeight - 16 - 150;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
