
<template>
    <el-dialog
        width="70%"
        :title="'快速登记'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="患者姓名" prop="patientName">
                        <el-input
                            v-model="form.patientName"
                            placeholder="请搜索..."
                            clearable
                            maxlength="20"
                            disabled=""
                            class="searchInput"
                        />
                        <el-button type="primary" plain @click="toChoisePatient()">搜索</el-button>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="床号" prop="bedNum">
                        <el-input
                            v-model="form.bedNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="住院号" prop="recordNum">
                        <el-input
                            v-model="form.recordNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="就诊号" prop="serialNum">
                        <el-input
                            v-model="form.serialNum"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">医嘱信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="执行医嘱" prop="operSelect">
                        <el-select
                            v-model="form.operSelect"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            @clear="clearSelect"
                            @change="changeSelect"
                        >
                            <el-option
                                v-for="(option, index) in form.list"
                                :key="option.id"
                                :label="option.operName"
                                :value="index"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医嘱类型" prop="doctorOrderType">
                        <el-input
                            v-model="form.doctorOrderType"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="总次数" prop="createCount">
                        <el-input
                            v-model="form.doctorOrderType === '长期医嘱' ? '长期' : form.createCount"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="单次数量" prop="schCount">
                        <el-input
                            v-model.number="form.schCount"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗频次" prop="frequencyCount">
                        <el-input
                            v-model="form.frequencyCount ? `${form.frequencyCount}次/${form.frequencyTime}${form.frequencyUnitLabel}` : commonConfig.$nullData "
                            :placeholder="commonConfig.$nullData"
                            clearable
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="医嘱嘱托" prop="doctorOrder">
                        <el-input
                            v-model="form.doctorOrder"
                            :placeholder="commonConfig.$nullData"
                            clearable
                            type="textarea"
                            maxlength="20"
                            disabled
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">治疗信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗时间" prop="treatmentDate">
                        <el-date-picker
                            v-model="form.treatmentDate"
                            align="right"
                            type="datetime"
                            placeholder="选择日期"
                            :editable="true"
                            style="width: 100%"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗区" prop="consultingRoom">
                        <el-select
                            v-model="form.consultingRoom"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="(option) in consultingRoom"
                                :key="option.id"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗设备" prop="deviceSelect">
                        <el-select
                            v-model="form.deviceSelect"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            @change="changeDeviceSelect"
                            @clear="clearDeviceSelect"
                        >
                            <el-option
                                v-for="(option, index) in ruleArmariumResList"
                                :key="option.id"
                                :label="option.deviceName + option.deviceNumber"
                                :value="index"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="治疗反应" prop="responseTreatment">
                        <el-input
                            v-model="form.responseTreatment"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 6}"
                            maxlength="100"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <userDialog ref="userDialog" @submit="getDetail"/>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import { treatmentRegistration, arrangeTreatmentAdvice,getUserConsultingRoom } from '@/api/arrangeTreatment'
import { getDict, getUserDevices } from '@/api/common'
import userDialog from '@/views/treatment/mySchedule/addDialog.vue'
export default {
    components:{
        userDialog
    },
    created(){
        // 页面创建时，获取医嘱类型字典表
        this.getUserConsultingRoom()
        this.getOrderTypeDict()
    },
    data() {
        return {
            rules: {
                patientName: [
                    {
                        required: true,
                        message: '请选择患者',
                        trigger: 'blur'
                    },
                ],
                operSelect: [
                    {
                        required: true,
                        message: '请选择执行医嘱',
                        trigger: 'change'
                    },
                ],
                schCount:[
                    {
                        required: true,
                        message: '请输入数字并且不能为0',
                        trigger: 'change',
                        type: 'number',
                        min: 1
                    }
                ],
                treatmentDate:[
                    {
                        required: true,
                        message: '请选择治疗时间',
                        trigger: 'change'
                    },
                ],
            },
            form: {
                list: [],
                consultingRoom: '',
            },
            visible: false,
            timeStamp: '', // 时间戳
            submitFlag: true, // 提交锁
            doctorOrderMap: {}, // 医嘱类型map项
            consultingRoom: [], // 治疗诊室列表
            ruleArmariumResList:[], //治疗设备列表
        }
    },
    watch: {
        visible(newVal, oldVal) {
            // 弹窗显示时，治疗反应默认为“无”，治疗时间默认为当前时间
            if (newVal === true) {
                this.form = {
                    ...this.form,
                    responseTreatment: "无",
                    treatmentDate: moment().format("yyyy-MM-DD HH:mm")
                }
                this.getUserDevices()
            }
            
        }
    },
    methods: {
         //清空设备时，重新获取用户治疗区
         getRoomLabel(){
            // this.getInfo(this.info.id, (res) => {
            //     this.form.consultingRoom = res.userConsultingRoom
            // })
            getUserConsultingRoom();
        },
        /** 获取医嘱类型字典表
         * @description: 
         *      医嘱类型获取成功后，将数组按{value:label}的格式放入到doctorOrderMap中，以方便显示
         * @param {*}
         * @return {*}
         */        
        getOrderTypeDict(){
            const orderLabel = "doctor_order_type"
            this.getDictByType(orderLabel, (orderList) => {
                let doctorOrderMap = {}
                orderList.forEach(item => {
                    doctorOrderMap[item.value] = item.label
                })
                this.doctorOrderMap = {...doctorOrderMap}
            })
        },
        /** 按类型获取字典项
         * @description: 字典项获取成功后，进入回调函数进行后续操作
         * @param {String} type 要获取的字典项名称
         * @param {Function} callback 回调函数，其中从后台获取的数据作为回调函数的参数传入
         * @return {*}
         */        
        getDictByType(type, callback){
            getDict({type}).then(({res}) => {
                if (callback && typeof callback === "function") {
                    callback(res[type])
                }
            })
        },
        /** 修改执行医嘱选中项
         * @description: 执行医嘱选中项修改时，对应的执行医嘱、医嘱类型、总次数、单次数量、治疗频次、医嘱嘱托都要进行同步修改
         * @param {Number} val 选中列表的索引值
         * @return {*}
         */        
        changeSelect(val){
            const {doctorOrderType, doctorOrder, basicInfoId, id: adviceId, ...selectOper} = this.form.list[val]
            this.form = {
                ...this.form, 
                ...selectOper, 
                adviceId,
                doctorOrderType: this.doctorOrderMap[doctorOrderType],
                doctorOrder: doctorOrder && doctorOrder.split("@@").join()
            }
        },
        /** 清空执行医嘱选中项
         * @description: 清空时，对应的执行医嘱、医嘱类型、总次数、单次数量、治疗频次、医嘱嘱托都要进行同步清空
         * @param {*}
         * @return {*}
         */        
        clearSelect(){
            const obj = {
                adviceId: "",
                doctorOrderType: "",
                createCount: "",
                schCount: "",
                frequencyCount: "",
                doctorOrder: ""
            }
            this.form = {
                ...this.form,
                ...obj
            }
        },
        /** 修改设备选中项
         * @description: 修改设备选中项时，修改选中的设备id
         * @param {Number} val Number
         * @return {*}
         */        
        changeDeviceSelect(val){
            const armariumId = this.ruleArmariumResList[val]?this.ruleArmariumResList[val].id:''
            //  const consultingRoom =this.ruleArmariumResList[val].consultingRoom
            let consultingRoom =''
            if(this.ruleArmariumResList[val]){
                consultingRoom =this.ruleArmariumResList[val].consultingRoom
                this.form = {
                ...this.form,
                armariumId,
                consultingRoom,
                }
            }else{
                this.form = {
                ...this.form,
                armariumId,
                }
                 this.getUserConsultingRoom();
            }
            
            console.log("consultingRoom--------",consultingRoom)
        },
        /** 清空设备选中项
         * @description: 清空时，情空对应的设备id
         * @param {*}
         * @return {*}
         */   
        clearDeviceSelect(){
            this.form = {
                ...this.form,
                armariumId: ""
            }
        },
        /** 显示患者弹窗
         * @description: 
         * @param {*}
         * @return {*}
         */        
        toChoisePatient(){
            this.$refs.userDialog.updateVisible(true)
        },
        /** 获取详细信息
         * @description: 通过所选患者的basicInfoId获取该患者的执行医嘱列表
         *      将患者信息、执行医嘱信息更新到页面中
         * @param {*} userInfo
         * @return {*}
         */        
        getDetail(userInfo){
            const {basicInfoId} = userInfo
            const param = {
                basicInfoId
            }
            this.getDictByType("consulting_room", (roomList) => {
                this.consultingRoom = [...roomList]
            })
            arrangeTreatmentAdvice(param).then(({res}) => {
                // 将上一个页面传入的信息、从后台获取的执行医嘱放入form中进行显示
                this.form = {
                    ...this.form,
                    ...userInfo,
                    list: res
                }
            })
        },
        /** 
         * @description: 获取用户绑定的治疗设备列表
         * @param {*}
         * @return {*}
         */  
        getUserDevices(){
            getUserDevices().then(({res}) => {
                this.ruleArmariumResList = res
            })
        },
        /** 提交
         * @description: 校验必填项，通过校验后，发送登记请求，请求成功
         *      提交时，添加设备锁，在前一次提交成功/失败前，不能进行下一次提交
         * @param {*}
         * @return {*}
         */  
        submit() {
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const {
                        basicInfoId: basicId, 
                        adviceId,
                        responseTreatment, 
                        schCount, 
                        consultingRoom,
                        armariumId,
                        treatmentDate
                    } = this.form
                    const param = {
                        basicId,
                        adviceId,
                        responseTreatment,
                        schCount,
                        armariumId,
                        consultingRoom,
                        treatmentDate: new Date(treatmentDate).getTime()
                    }
                    this.submitFlag = false
                    treatmentRegistration([param]).then((res) => {
                        this.submitSuccess()
                    }).catch((error) => {
                        this.submitFlag = true
                    })
                } else{
                    this.submitFlag = true
                }
            })
        },
        /** 提交成功后，进行的回调函数
         * @description: 提交成功后，对提交锁进行解锁
         *      进行成功提示
         *      关闭弹出层
         *      触发上一页面组价"@submit"绑定的函数
         * @param {*} res 回调函数返回的数据
         * @return {*}
         */        
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        /** 更新弹出层显示状态
         * @description: 关闭时，清空form中的所有内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */        
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        },
        //获取当前用户治疗区
        getUserConsultingRoom() {
            this.$nextTick(() => {
                getUserConsultingRoom({}).then(({ res, timeStamp }) => {
                    this.form.consultingRoom = res.consultingRoom
                    this.getDictByType("consulting_room", (roomList) => {
                        this.consultingRoom = [...roomList]
                    })
                });
            });
        },
    }
}
</script>
<style scoped>
</style>

