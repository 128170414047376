<!--
* @Description: 科研数据管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <el-tabs type="border-card" style="width: 100%" v-model="tabValue">
        <el-tab-pane :lazy="true" name="1" label="普通查询">
        </el-tab-pane>
        <el-tab-pane :lazy="true" name="2" label="高级查询">
        </el-tab-pane>
      </el-tabs>
      <searchForm v-show="tabValue==1" ref="mainSearch" :searchoptions="searchOptions" @search="search"
                  :backStatus="backStatus"/>
      <el-form v-show="tabValue==2"
               ref="seniorSearch"
               :inline="true"
               v-model="seniorParam"
               class="searchForm"
      >
        <el-form-item label="高级查询条件" prop="paramId">
          <el-select
            v-model="paramId"
            clearable
            :placeholder="'请选择...'"
            :filterable="true"
            :multiple="false"
            :collapse-tags="false"
          >
            <el-option
              v-for="option in seniorParams"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
        </el-form-item>
        <div>
          <el-form-item>
            <el-button type="default" @click="toInsert()">添加</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toEdit()">编辑</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="default" @click="toDel()">删除</el-button>
          </el-form-item>
        </div>
        <div class="search-btn-group">
          <el-form-item>
            <el-button type="primary" @click="getList()">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="resetSeniorSearch"
              plain
            >
              重置
            </el-button>
          </el-form-item>
          <el-form-item v-if="backStatus" style="margin-right: 0">
            <el-button
              type="default"
              @click="toBack()"
            >返回
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group">
        <el-button type="primary" @click="toExport()">导出</el-button>
        <el-button type="primary" @click="toTaskPage()">导出任务查看</el-button>
      </el-row>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
                    @onClickRow="onClickRow">
      </generalTable>
      <addDialog
        :id="checkId"
        ref="insert"
        :table-data="tableData"
        :time-stamp="timeStamp"
        @submit="getSeniorParams"
      />
      <selectModel
        :models="models"
        ref="selectModel"
        @submit="updateModelId"
      />
    </el-main>
  </el-container>

</template>

<script>
import { getList, getSeniorParams, delParam, createTask,getByParamId } from '@/api/scientificData'
import { getList as getTemplateList } from '@/api/tempManage'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'
import addDialog from '@/views/scientific/scientificData/addDialog'
import selectModel from '@/views/scientific/scientificData/selectModel'
import detailDialog from '@/views/scientific/scientificData/detailDialog'
import { getDept } from '@/api/patient'

export default {
  name: 'ScientificData',
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
    selectModel
  },
  data() {
    return {
      tableData: [],
      models: [],
      tabValue: '1',
      seniorParam: {
        id: ''
      },
      paramId: '',
      seniorParams: [],
      searchOptions: [
        {
          type: 'input',
          placeholder: '请输入患者姓名',
          key: 'patientName',
          label: '患者姓名',
          defaultVal: ''
        },
        {
          type: 'select',
          placeholder: '请选择',
          key: 'sex',
          filterable: true,
          label: '性别',
          options: []
        },
        {
          type: 'input',
          placeholder: '请输入患者住院号',
          key: 'recordNum',
          label: '住院号',
          maxlength:2000,
          defaultVal: ''
        },
        {
          type: 'select',
          placeholder: '请选择',
          key: 'deptId',
          filterable: true,
          label: '所属科室',
          options: []
        },
        {
          type: 'select',
          placeholder: '请选择',
          key: 'status',
          filterable: true,
          multiple: false,
          label: '状态',
          options: [],
          defaultVal: '1'
        },
        {
          type: 'input',
          placeholder: '请输入相关诊断',
          key: 'diagnosis',
          label: '诊断',
          defaultVal: ''
        },
        {
          type: 'daterange',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          key: 'date',
          label: '在院时间'
        }
      ],
      tableConfig: {
        id: 'scientificData',
        align: 'center', // 不必填 默认为center
        selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: '患者姓名', // 必填
            prop: 'name' // 必填
            // formatter: (row, column) => {
            //     return row.archivesInfoId.name
            // }
          },
          {
            label: '性别', // 必填
            prop: 'sex' // 必填
          },
          {
            label: '住院号/门诊号', // 必填
            prop: 'recordNum' // 必填
          },
          {
            label: '所属科室', // 必填
            prop: 'deptName' // 必填
          },
          {
            label: '诊断', // 必填
            prop: 'diagnosis' // 必填
          },
          {
            label: '入院\\就诊日期', // 必填
            prop: 'heDte', // 必填
            formatter: (row, column) => {
              return this.moment(row.heDte).format('YYYY-MM-DD')
            }
          },
          {
            label: '出院日期', // 必填
            prop: 'leaveDate', // 必填
            formatter: (row, column) => {
              return row.leaveDate ? this.moment(row.leaveDate).format('YYYY-MM-DD') : this.commonConfig.$nullData
            }
          },
          {
            label: '状态', // 必填
            prop: 'status' // 必填
          }
        ]
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: '', //时间戳 用于修改数据或删除数据
      paramTimeStamp: '',
      leftValueType: '0'
    }
  },
  created() {
    this.getUserSexList()
    this.getDeptList()
    this.getPatientInHosStatusList()
    this.getSeniorParams()
    this.getList()
  },
  mounted: function() {
  },
  methods: {
    toBack(){
      this.$router.back()
    },
    /**
     * @description: 重置高级查询
     */
    resetSeniorSearch(){
      this.paramId=''
    },
    /**
     * @description: 跳转任务管理页面
     */
    toTaskPage(){
      this.$router.push('/scientific/task');
    },
    /**
     * @description: 设置模板id
     */
    updateModelId(modelId){
      let exportParam = {
        modelId:modelId
      }
      // 普通查询
      if (this.tabValue == '1') {
        exportParam.paramJson = this.getParam().paramJson
      } else { // 高级查询
        exportParam.paramId = this.paramId
      }
      createTask(exportParam).then(({res})=>{
        this.$router.push('/scientific/task');
      })
    },
    /**
     * @description: 导出
     */
    toExport() {
      if (this.tabValue == '2'&&!this.paramId) {
        this.$alert('请选择查询条件', this.$delTitle, {
          confirmButtonText: '确定'
        })
        return;
      }
      // 查询模板列表
      const tempParam = { size: -1, status: 0 }
      getTemplateList(tempParam).then(({ res }) => {
        if (res.records.length == 0) {
          this.$alert('暂无可用模板', this.$delTitle, {
            confirmButtonText: '确定'
          })
        } else if (res.records.length == 1) {
          this.updateModelId(res.records[0].id)
        } else {
          this.models = res.records;
          this.$refs.selectModel.updateVisible(true);
        }
      })
    },
    /**
     * @description: 获取高级查询参数列表
     */
    getSeniorParams() {
      getSeniorParams().then(({ res, timeStamp }) => {
        this.seniorParams = res
        this.paramTimeStamp = timeStamp
      })
    },
    /**
     * @description: 获取在院状态
     */
    getPatientInHosStatusList() {
      getDict({ type: 'patient_basic_status' }).then(({ res }) => {
        let statusCache = res.patient_basic_status.filter(item=>{return item.value==1||item.value==0}).map((item) => {
          return {
            value: item.value,
            label: item.label
          }
        })
        this.searchOptions[4].options = statusCache
      })
    },
    /**
     * @description: 获取所属科室
     * @param {*}
     * @return {*}
     */
    getDeptList() {
      getDept({ deptType: '4' }).then(({ res }) => {
        let deptList = res.map((item) => {
          return {
            value: item.id,
            label: item.label
          }
        })
        this.searchOptions[3].options = deptList
      })
    },
    getUserSexList() {
      getDict({ type: 'user_sex' }).then(({ res }) => {
        this.searchOptions[1].options = res.user_sex
      })
    },
    search(data) {
      Object.assign(this.paramObj, data)
      this.$refs.mainTable.handleCurrentChange(1)
    },
    getList() {
      this.$nextTick(() => {
        if (this.tabValue=='1'){
          const param = this.getParam()
          getList(param).then(({ res, timeStamp }) => {
            this.tableData = res.records
            this.total = parseInt(res.total)
            this.timeStamp = timeStamp
          })
        }else{
          if (this.paramId){
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, page, { id: this.paramId })
            getByParamId(param).then(({ res, timeStamp }) => {
              this.tableData = res.records
              this.total = parseInt(res.total)
              this.timeStamp = timeStamp
            })
          } else {
            this.$alert('请选择查询条件', this.$delTitle, {
              confirmButtonText: '确定'
            })
          }
        }
      })
    },
    toInsert() {
      this.checkId = ''
      this.$refs.insert.updateVisible(true)
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id
      this.$refs.detail.updateVisible(true)
    },
    toEdit() {
      if (this.paramId) {
        this.checkId = this.paramId
        this.$refs.insert.updateVisible(true)
      } else {
        this.$alert('请选择查询条件', this.$delTitle, {
          confirmButtonText: '确定'
        })
      }
    },
    toDel() {
      if (this.paramId) {
        const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
          this.commonConfig
        this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
          const param = { id: this.paramId }
          delParam(param, this.paramTimeStamp).then(({ res }) => {
            this.$message({ ...$handleSuccess })
            this.getSeniorParams()
            this.paramId = ''
          })
        })
      } else {
        this.$alert('请选择查询条件', this.$delTitle, {
          confirmButtonText: '确定'
        })
      }
    },
    onClickRow(row) {
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData()
      let conditions = []
      if (paramObj.patientName) {
        conditions.push({
          negated: 'Y',
          operator: 'AND',
          leftValue: 'basicPatientName',
          leftValueType: this.leftValueType,
          compare: 'like',
          value: paramObj.patientName
        })
      }
      if (paramObj.sex) {
        conditions.push(
          {
            negated: 'Y',
            operator: 'AND',
            leftValue: 'basicSex',
            leftValueType: this.leftValueType,
            compare: 'equals',
            value: paramObj.sex
          })
      }
      if (paramObj.recordNum) {
        conditions.push(
          {
            negated: 'Y',
            operator: 'AND',
            conditions:
              paramObj.recordNum.split(",").map((item,index)=>{
                let temp = {
                  negated: 'Y',
                  leftValue: 'basicHosNum',
                  leftValueType: this.leftValueType,
                  compare: 'like',
                  value: item
                }
                if(index!=0){
                  temp.operator = 'OR';
                }
                return temp;
              })
          })
      }
      if (paramObj.deptId) {
        conditions.push(
          {
            negated: 'Y',
            operator: 'AND',
            leftValue: 'basicDeptId',
            leftValueType: this.leftValueType,
            compare: 'equals',
            value: paramObj.deptId
          })
      }
      if (paramObj.status) {
        conditions.push(
          {
            negated: 'Y',
            operator: 'AND',
            leftValue: 'basicPatientStatus',
            leftValueType: this.leftValueType,
            compare: 'equals',
            value: paramObj.status
          })
      }
      if (paramObj.diagnosis) {
        conditions.push(
          {
            negated: 'Y',
            operator: 'AND',
            leftValue: 'basicDiagnosis',
            leftValueType: this.leftValueType,
            compare: 'like',
            value: paramObj.diagnosis
          })
      }
      if (paramObj.dateStartTime) {

        conditions.push({
          negated: 'Y',
          operator: 'AND',
          conditions: [
            {
              negated: 'Y',
              conditions: [
                {
                  negated: 'Y',
                  leftValue: 'basicHeDte',
                  leftValueType: this.leftValueType,
                  compare: 'gq',
                  value: this.moment(paramObj.dateStartTime).format('YYYY-MM-DD HH:mm:ss')
                }, {
                  negated: 'Y',
                  operator: 'AND',
                  leftValue: 'basicHeDte',
                  leftValueType: this.leftValueType,
                  compare: 'lq',
                  value: this.moment(paramObj.dateEndTime).format('YYYY-MM-DD HH:mm:ss')
                }
              ]
            },
            {
              negated: 'Y',
              operator: 'OR',
              conditions: [
                {
                  negated: 'Y',
                  leftValue: 'basicHeDte',
                  leftValueType: this.leftValueType,
                  compare: 'lq',
                  value: this.moment(paramObj.dateStartTime).format('YYYY-MM-DD HH:mm:ss')
                }, {
                  negated: 'Y',
                  operator: 'AND',
                  leftValue: 'basicLeaveDate',
                  leftValueType: this.leftValueType,
                  compare: 'gq',
                  value: this.moment(paramObj.dateEndTime).format('YYYY-MM-DD HH:mm:ss')
                }
              ]
            },
            {
              negated: 'Y',
              operator: 'OR',
              conditions: [
                {
                  negated: 'Y',
                  leftValue: 'basicLeaveDate',
                  leftValueType: this.leftValueType,
                  compare: 'gq',
                  value: this.moment(paramObj.dateStartTime).format('YYYY-MM-DD HH:mm:ss')
                }, {
                  negated: 'Y',
                  operator: 'AND',
                  leftValue: 'basicLeaveDate',
                  leftValueType: this.leftValueType,
                  compare: 'lq',
                  value: this.moment(paramObj.dateEndTime).format('YYYY-MM-DD HH:mm:ss')
                }
              ]
            }
          ]
        })
      }
      let p = {
        negated: 'Y',
        conditions: conditions
      }
      const page = this.$refs.mainTable.getPage()
      const param = Object.assign({}, this.paramObj, page, { paramJson: encodeURIComponent(JSON.stringify(p)) })
      this.paramObj = param
      return param
    }
  }
}
</script>

<style lang='scss' scoped>
  @import "~@/styles/variables.scss";
</style>

