/*
 * @Description: 关于医院相关信息Api
 * @Author: yzw
 * @Date: 2021-03-09 13:36:31
 * @LastEditTime: 2021-12-27 13:20:43
 * @LastEditors: yzw
 * @Reference:
 */
import request from '@/utils/request'

/**
 * @description: 获取当前医院信息
 */
 export function getHospitalInfo() {
    return request({
      url: '/admin/system/info',
      method: 'GET',
      showLoading: true
    })
}

/**
 * @description: 更新医院信息
 * @param {Object} data
 */
export function updateHospitalInfo(data) {
  return request({
    url: '/admin/system/update',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 更新默认工作台配置信息
 * @param {Object} data
 */
export function updateAllWorkbenchConfig(data, timeStamp) {
  return request({
    url: "/configWorkbench/updateWorkbench",
    method: "POST",
    data,
    showLoading: true,
    timeStamp
  });
}