
<template>
    <el-dialog
        width="70%"
        :title="'批量作废'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
                作废信息
            </div>
            <p style="text-align:center">确定批量作废{{ids.length || 0}}条已治疗信息</p>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="作废原因" prop="nullifyReason">
                        <el-input
                            v-model="form.nullifyReason"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 6}"
                            maxlength="100"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { batchInvalid } from '@/api/arrangeTreatment'
export default {
    props: {
        ids: {
            type: String | Array
        },
    },
    data() {
        return {
            rules: {
                nullifyReason: [
                    { required: true, message: '请输入...', trigger: 'blur' },
                ],
            },
            form: {},
            visible: false,
        }
    },
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = []
                    for (let i = 0; i < this.ids.length; i++) {
                        const ele = this.ids[i];
                        parm.push({id:ele.id,nullifyReason:this.form.nullifyReason})
                    }
                    batchInvalid(parm).then(({res}) => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        