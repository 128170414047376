/*
 * @Author: yzw
 * @Date: 2023-04-21 09:20:56
 * @LastEditTime: 2023-04-21 14:43:37
 * @LastEditors: yzw
 * @Description: 
 */

/**
 * 页面: 患者预约
 * pageName: reservation
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者预约列表数据
 */
export function getList(data) {
    return request({
      url: "/patientAppointmentQueuing/queuingList",
      method: "GET",
      params: data,
      // showLoading: true,
    });
}


/**
 * @description: 获取患者医嘱信息
 */
export function getPatientAdvice(data) {
    return request({
      url: "/patientAppointmentQueuing/queryPatientByIdCard",
      method: "GET",
      params: data,
      // showLoading: true,
    });
}

/**
 * @description: 提交预约
 */
export function submit(data,timeStamp) {
    return request({
      url: "/patientAppointmentQueuing/add",
      method: "POST",
      data,
      showLoading: true,
      timeStamp,
    });
}


        