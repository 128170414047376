<!--
 * @Author: your name
 * @Date: 2021-12-24 11:23:15
 * @LastEditTime: 2022-07-29 14:42:31
 * @LastEditors: ligc 951906427@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\orderDetail.vue
-->
<template>
    <el-dialog width="60%" title="患者医嘱详情" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="医嘱信息" :column="4">
            <el-descriptions-item label="所属治疗">
                {{ commonConfig.$checkNullData(detail.operType) }}
            </el-descriptions-item>
            <el-descriptions-item label="操作项名称">
                {{ commonConfig.$checkNullData(detail.operName) }}
            </el-descriptions-item>
            <el-descriptions-item label="医嘱类型">
                {{ detail.doctorOrderType === "1" ? "长期医嘱" : "限定医嘱" }}
            </el-descriptions-item>
            <el-descriptions-item label="开单医生">
                {{ commonConfig.$checkNullData(detail.billedDoctorName) }}
            </el-descriptions-item>
            <el-descriptions-item label="总次数">
                {{ detail.doctorOrderType === "1" ? "长期" : detail.createCount }}
            </el-descriptions-item>
            <el-descriptions-item label="单次数量">
                {{ commonConfig.$checkNullData(detail.schCount) }}
            </el-descriptions-item>
            <el-descriptions-item label="频次">{{
                detail.frequencyCount +
                "次/" +
                detail.frequencyTime +
                ["天", "周", "月", "年"][detail.frequencyUnit]
            }}
            </el-descriptions-item>
            <el-descriptions-item label="医嘱状态">
                {{ advice_statusList[detail.status] }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detail.doctorOrderType==2" label="废弃次数">
                {{ commonConfig.$checkNullData(detail.abandonCount) }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detail.doctorOrderType==2" label="剩余次数">
                {{ commonConfig.$checkNullData(detail.liveCount) }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detail.status==3" label="删除医生">
                {{ commonConfig.$checkNullData(detail.delBy) }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detail.status==3" label="删除时间">
                {{ commonConfig.$checkNullData(detail.delDate,"YYYY-MM-DD HH:mm:ss") }}
            </el-descriptions-item>
        </el-descriptions>
        <div class="mdrcc-info-title">嘱托</div>
        <div class="margin-top" title="嘱托" :column="1">
            <div :key="item" v-for="item in orderList">{{ item || commonConfig.$nullData }}</div>
        </div>
        <template slot="footer" class="dialog-footer">
            <el-button @click="close()">关 闭</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from '@/api/advice'
import { getDict } from '@/api/common'
import moment from 'moment'

export default {
    components: {
        moment
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false, // 弹窗显隐
            detail: [], // 详情数据
            orderList: [], // 医嘱列表
            advice_statusList: [] // 医嘱状态
        }
    },
    watch: {
        /** 监听插件显隐
         * @description: 显示时,如果传入id,获取详情;隐藏时,清空orderList
         * @param {*} newVal
         * @param {*} oldVal
         * @return {*}
         */        
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail()
            if (!newVal) {
                this.orderList = []
            }
        }
    },
    /** 页面创建时
     * @description: 获取医嘱状态
     * @param {*}
     * @return {*}
     */    
    created() {
        this.get_advice_status()
    },
    mounted() {},
    methods: {
        /** 获取医嘱状态
         * @description: 将医嘱状态列表转换为map对象,以便从value显示为label
         * @param {*}
         * @return {*}
         */        
        get_advice_status() {
            getDict({ type: 'advice_status' }).then(({ res }) => {
                let advice_statusList = []
                res.advice_status.map((item) => {
                    advice_statusList[item.value] = item.label
                })
                this.advice_statusList = advice_statusList
            })
        },
        /** 获取详情
         * @description: 获取返回值中orderList按字符串'@@'切分成的数组
         * @param {*}
         * @return {*}
         */        
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res
                if (res.doctorOrder) {
                    this.orderList = res.doctorOrder && res.doctorOrder.split('@@')
                }
            })
        },
        /** 更新弹窗显隐
         * @description: visible与flag同步
         * @param {Boolean} flag true时显示,false时隐藏
         * @return {*}
         */        
        updateVisible(flag) {
            this.visible = flag
        },
        /** 关闭按钮
         * @description: 隐藏当前弹窗,触发父级组件绑定的`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        close() {
            this.updateVisible(false)
            this.$emit('close')
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
