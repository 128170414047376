/*
 * @Author: your name
 * @Date: 2021-11-06 14:22:10
 * @LastEditTime: 2021-12-22 10:51:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\operation.js
 */

/**
 * 页面: 操作项目
 * pageName: operation
 **/
import request from '@/utils/request'

/**
 * @description: 获取操作项目列表数据
 */
export function getList(data) {
    return request({
        url: '/ruleOperation/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增操作项目数据
 */
export function insertData(data) {
    return request({
        url: '/ruleOperation/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改操作项目数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/ruleOperation/update',
        method: 'POST',
        data,
        showLoading: true, 
        timeStamp
    })
}
            

/**
 * @description: 获取操作项目详情数据
 */
export function getDetail(data) {
    return request({
        url: '/ruleOperation/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 批量删除操作项目数据
 */
    export function deleteDataList(data, timeStamp) {
    return request({
        url: '/ruleOperation/deleteDataList',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取嘱托数据
 */
 export function getOrderList(data) {
    return request({
        url: '/ruleOperOrder/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取操作项绑定的收费项列表
 */
 export function getFeeItemsList(data) {
    return request({
        url: '/ruleOperation/getFeeItemsList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}