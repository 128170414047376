/*
 * @Author: your name
 * @Date: 2022-11-24 14:23:54
 * @LastEditTime: 2022-11-25 10:22:30
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\patientTreatmentConclusion.js
 */
import request from '@/utils/request'

/**
 * @description: 获取治疗小结列表
 */
 export function getList(data) {
    return request({
        url: 'patientTreatmentConclusion/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            

/**
 * @description: 获取治疗小结详情数据
 */
export function getDetail(data) {
    return request({
        url: 'patientTreatmentConclusion/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取用户评估信息
 */
 export function getPatientAssessment(data) {
    return request({
        url: 'patientTreatmentConclusion/getPatientAssessment',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取用户治疗信息
 */
 export function getPatientTreatment(data) {
    return request({
        url: 'patientTreatmentConclusion/getPatientTreatment',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增治疗小结
 */
export function insertData(data) {
    return request({
        url: '/patientTreatmentConclusion/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗小结
 */
export function editData(data, timeStamp) {
    return request({
        url: '/patientTreatmentConclusion/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除治疗小结
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/patientTreatmentConclusion/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
