/*
 * @Author: your name
 * @Date: 2021-12-03 13:47:36
 * @LastEditTime: 2021-12-09 13:20:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\reportClassify.js
 */

/**
 * 页面: 报告分类管理
 * pageName: reportClassify
 **/
import request from '@/utils/request'

/**
 * @description: 获取报告分类管理列表数据
 */
export function getList(data) {
    return request({
        url: '/scaleReportType/tree',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增报告分类管理数据
 */
export function insertData(data) {
    return request({
        url: '/scaleReportType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改报告分类管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/scaleReportType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取报告分类管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/scaleReportType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除报告分类管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/scaleReportType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 禁用报告
 */
    export function disableReport(data, timeStamp) {
    return request({
        url: '/scaleReportType/disableReport',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 查询评定分类和量表
 */
    export function getScaleAndScaleType(data) {
    return request({
        url: '/scaleReportType/getScaleAndScaleType',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 查询量表
 */
 export function getListByNotIds(data) {
    return request({
        url: '/scaleProgram/getListByNotIds',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 查询评定分类
 */
 export function getScaleTypeList(data) {
    return request({
        url: '/scaleProgram/getScaleTypeList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 查询量表
 */
 export function getScaleList(data) {
    return request({
        url: '/scaleReportType/getScaleList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


        