/**
 * 页面: 治疗安排
 * pageName: patientTreatment
 **/
import request from "@/utils/request";

/**
 * @description: 获取治疗安排-今日待治疗-列表数据
 */
export function getUnTreatMentList(data) {
  return request({
    url: "patientSchedule/scheduleResPage",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 获取治疗安排-治疗记录-列表数据
 */
export function getTreatMentList(data) {
  return request({
    url: "patientTreatment/list",
    method: "GET",
    params: data,
    showLoading: true
  });
}
/**
 * @description: 获取治疗安排-治疗记录-列表数据
 */
export function getTreatmentListByVisitId(data) {
  return request({
    url: "patientTreatment/listByVisitId",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 患者登记治疗
 */
export function treatmentRegistration(data) {
  return request({
    url: "patientTreatment/treatmentRegistration",
    method: "POST",
    data,
    showLoading: true
  });
}

/**
 *
 * @description: 患者执行医嘱列表
 */
export function arrangeTreatmentAdvice(data) {
  return request({
    url: "patientAdviceInfo/patientTreatmentAdvice",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 获取 一键登记 列表数据
 */
export function getAllTreatmentList(data) {
  return request({
    url: "patientSchedule/keyRegister",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 修改治疗安排数据
 */
export function editData(data, timeStamp) {
  return request({
    url: "",
    method: "POST",
    data,
    showLoading: true,
    timeStamp
  });
}

/**
 * @description: 获取治疗安排详情数据
 */
export function getarrangeTreatmentInfo(data) {
  return request({
    url: "patientTreatment/getPatientTreatmentInfo",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 获取治疗安排详情数据
 */
export function getDetail(data) {
  return request({
    url: "patientSchedule/scheduleInfo",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 批量作废
 */
export function batchInvalid(data) {
  return request({
    url: "patientTreatment/batchInvalid",
    method: "POST",
    data,
    showLoading: true,
  });
}
/**
 * @description: 获取当前用户治疗区
 */
export function getUserConsultingRoom(data) {
  return request({
    url: "patientTreatment/userConsultingRoom",
    method: "GET",
    params: data,
    showLoading: true
  });
}
