<template>
  <el-dialog
    width="60%"
    title="查看收费项目"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="收费项目信息" :column="3" >
      <el-descriptions-item label="收费名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="单次收费金额(元)">{{detail.price||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="医保级别">{{detail.level||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="自付百分比(%)">{{detail.percent||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="物价标准编码"> {{detail.standCode||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="是否物价标准"> {{detail.standLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="备注"> {{detail.remark||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider/>
    <div class="mdrcc-info-title">
      关联信息
    </div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getOperationList"/>
    <el-divider/>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import {getDetail, getOperationList} from '@/api/feeItems'
  import generalTable from '@/components/Table'

  export default {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    components: {
      generalTable
    },
    data() {
      return {
        visible: false,
        detail: [], // 详情数据
        tableData: [],
        tableConfig: {
          align: 'center', // 不必填 默认为center
          selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
          index: true, // 不必填 序号
          pagination: false, // 不必填 分页 默认为false
          column: [
             {
                    label: '名称', // 名称
                    prop: 'name' // 
                },
                {
                    label: '所属治疗', // 所属治疗
                    prop: 'operTypeLabel' // 
                }
          ]
        },
        stand:[],
        total: 0 // 总条数
      }
    },
    watch: {
      visible(newVal, oldVal) {
        newVal && this.id && this.getDetail()
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      /**
      　* 获取收费项目详情
      　* @Description: 获取收费信息和收费关联操作项目
        * @Param null
        * @Return null
      　* @Author wangz
      　* @Date 2021/11/11 9:29
      　*/
      getDetail() {
        const param = {}
        //收费id
        if (this.id) {
          param.id = this.id
        }

        this.getOperationList();
        getDetail(param).then(({res}) => {
          console.log('getDetail-----------',res);
          this.detail = res;
     //  this.detail.stand = res.stand === '1' ? '否' : '是';
        
        
      })},
      /**
     　* 获取收费关联操作项目列表
     　* @Description: this.id为组套id,为空时直接返回，不为空时根据组套id获取组套关联的操作项目
       * @Param  null
       * @Return null
     　* @Author wangz
     　* @Date 2021/11/11 9:20
     　*/
      getOperationList() {
        this.$nextTick(() => {
          const param = {};
          if (this.id) {
            param.id = this.id
          } else {
            return;
          }
          getOperationList(param).then(({res, timeStamp}) => {
            this.tableData = res;
            this.timeStamp = timeStamp;
          })
        })
      },
      updateVisible(flag) {
        this.visible = flag
      }
    }
  }
</script>
<style scoped lang='scss'>
</style>
