/*
 * @LastEditors: Richardlv
 * @Author: Richardlv
 */

/**
 * 页面: 设备库
 * pageName: library
 **/
import request from '@/utils/request'

/**
 * @description: 获取设备库列表数据
 */
export function getList(data) {
    return request({
        url: '/ruleDevice/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增设备库数据
 */
export function insertData(data) {
    return request({
        url: '/ruleDevice/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改设备库数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/ruleDevice/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取设备库详情数据
 */
export function getDetail(data) {
    return request({
        url: '/ruleDevice/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除设备库数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/ruleDevice/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        