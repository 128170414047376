<!--
 * @Author: sixteen
 * @Date: 2021-12-23 11:41:15
 * @LastEditTime: 2021-12-24 11:14:28
 * @LastEditors: Please set LastEditors
 * @Description: 新增量表
 * @FilePath: /recovery_management/src/views/scale/patientAssessment/addScaleDialog.vue
-->

<template>
    <el-dialog
        width="70%"
        title="新增量表"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <searchForm
            ref="mainSearch"
            :searchoptions="searchOptions"
            :back-status="false"
            @search="search"
        />
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="getScaleList"
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getList, getTypeScale } from "@/api/privateScaleLib";

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {},
    data() {
        return {
            checkId: "",
            tableData: [],
            dept: [], //部门数据
            searchOptions: [
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "scaleTypeId",
                    options: [],
                    label: "评定分类",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "请输入",
                    key: "name",
                    label: "量表名称",
                    defaultVal: "",
                },
            ],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "评定分类", // 必填
                        prop: "scaleTypeName", // 必填
                    },
                    {
                        label: "量表名称", // 必填
                        prop: "name", // 必填
                    },
                ],
            },
            total: 0,
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: "", //时间戳 用于修改数据或删除数据
            check:[],
            scaleTypeOptions:[],
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getScaleList();
            }
        },
    },
    created() {
        this.getEvaluateClass();
    },
    mounted() {},
    methods: {
        /**
         * @description: 筛选查询
         * @param {*} data
         * @return {*}searchForm
         */
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },

        /**
         * @description: 获取量表分类
         * @param {*}
         * @return {*}
         */
        getEvaluateClass() {
            getTypeScale().then(({ res, timeStamp }) => {
                res.records.forEach((element) => {
                    const ele = {
                        value: element.id,
                        label: element.name,
                    };
                    this.scaleTypeOptions.push(ele);
                });
                this.searchOptions[0].options = this.scaleTypeOptions;
            });
        },
        /**
         * @description: 整合请求参数 status =0 启用状态
         * @param {*}
         * @return {*}
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj,{'status':'0'});
            this.paramObj = param;
            return param;
        },
        /**
         * @description: 获取量表
         * @param {*}
         * @return {*}
         */
        getScaleList() {
            this.$nextTick(() => {
                const param = Object.assign({},this.getParam(),{myDept:true});
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.total = res.total;
                    this.timeStamp = timeStamp;
                });
            });
        },

        /**
         * @description: 提交
         * @param {*}
         * @return {*}
         */
        submit() {
            this.check = this.$refs.mainTable.getChecked();
            if (this.check.length>0) {
                this.updateVisible(false);
                this.$emit("popData", this.check);
            } else {
                this.$message("请选择量表");
            }
        },

        /**
         * @description: 关闭弹窗
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val;
        },
    },
};
</script>
<style scoped lang="scss"></style>
