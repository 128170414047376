<!--
 * @Author: your name
 * @Date: 2021-12-24 09:19:20
 * @LastEditTime: 2021-12-28 14:35:15
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\information.vue
-->
<template>
    <!-- <el-container> -->
        <el-main>
            <div class="mdrcc-info-title">疾病诊断</div>
            <el-row v-if="!data.diagnosisList || !data.diagnosisList.length" class="diagnose-item">{{message}}</el-row>
            <div class="diagnose-item" v-for="item in data.diagnosisList" :key="item.id">
                {{item.content}}
            </div>
            <div class="mdrcc-info-title">阶段目标</div>
            <el-row v-if="!data.targetList || !data.targetList.length" class="diagnose-item">{{message}}</el-row>
            <div class="diagnose-item" v-for="item in data.targetList" :key="item.id">
                {{item.content}}
            </div>
            <div class="mdrcc-info-title">主诉</div>
            <div class="diagnose-item">
                {{data.chiefComplaint || commonConfig.$nullData }}
            </div>
            <div class="mdrcc-info-title">既往病史</div>
            <div class="diagnose-item">
                {{data.pastMedicalHistory || commonConfig.$nullData }}
            </div>
            <div class="mdrcc-info-title">现病史</div>
            <div class="diagnose-item">
                {{data.medicalHistory || commonConfig.$nullData }}
            </div>
        </el-main>
    <!-- </el-container> -->
</template>

<script>
export default {
    name: 'informationPat360',
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Object
        }
    },
    data() {
        return {
            size: 'mini',
            message: "暂无数据"
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.no-padding {
    padding: 0;
}
.diagnose-item {
    width: 100%;
    padding: 10px;
    background-color: $dialogHeader;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 10px;
}
</style>
