<!--
 * @Author: haojw
 * @Date: 2021-11-24 09:27:09
 * @LastEditTime: 2021-12-14 16:45:41
 * @LastEditors: Please set LastEditors
 * @Description: 量表分类列表页
 * @FilePath: \recovery_management\src\views\scale\scaleType\index.vue
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"
        ><el-button type="primary" @click="toInsert()">新增</el-button></el-row
      >
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="status" slot-scope="scope">
          <el-switch
            v-model="scope.data.row.status"
            inactive-value="1"
            active-value="0"
            @change="changeStatus(scope, $event)"
          />
        </template>
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
          <el-button type="text" @click="toEdit(scope)">编辑</el-button>
          <el-button type="text" class="delBtn" @click="toDel(scope)"
            >删除</el-button
          >
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog
        :id="checkId"
        ref="insert"
        :timeStamp="timeStamp"
        @submit="search"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getList, deleteData,disableScale } from "@/api/scaleType";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/scale/scaleType/addDialog";
import detailDialog from "@/views/scale/scaleType/detailDialog";

export default {
  name: "ScaleType",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "分类名称",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "status",
          filterable: true,
          multiple: false,
          label: "状态",
          options: [
            {
              value: "0",
              label: "启用",
            },
            {
              value: "1",
              label: "禁用",
            },
          ],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "scaleType",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "分类名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "创建时间", // 必填
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm");
            },
            width: "200",
          },
          {
            label: "创建人", // 必填
            prop: "createByName", // 必填
            width: "200",
          },
           {
            label: "状态", // 必填
            slot: "status", // 必填
            width: "200",
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
    };
  },
  created() {
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.timeStamp = timeStamp;
          this.total = parseInt(res.total)
        });
      });
    },
     /**
     * @description:修改量表分类状态
     * @param {*}
     * @return {*}
     */
    changeStatus(scope, event) {
      // event switch更新前状态
      // index 数据序号
      const { id, status } = scope.data.row;
      disableScale({ id, status: status }, this.timeStamp)
        .then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        })
        .catch((err) => {
          if (err) {
            this.tableData[scope.data.$index].status = event === 0 ? "1" : "0";
          }
        });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getList();
        });
      });
    },
    onClickRow(row) {
      console.log("点击行:", row);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
