
/**
 * 页面: 文档库
 * pageName: documentLib
 **/
import request from '@/utils/request'

/**
 * @description: 获取文档库列表数据
 */
export function getList(data) {
    return request({
        url: 'knowledgeBase/listByUserIdAndStatus',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增文档库数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改文档库数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取文档库详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除文档库数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        