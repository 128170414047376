/*
 * @Author: SixTeen
 * @Date: 2021-11-18 10:56:35
 * @LastEditTime: 2021-11-19 17:47:59
 * @LastEditors: Please set LastEditors
 * @Description: 治疗分配
 * @FilePath: /recovery_management/src/api/distribution.js
 */

/**
 * 页面: 治疗分配
 * pageName: distribution
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗分配列表数据
 */
export function getList(data) {
    return request({
        url: '/patientUserRel/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 添加分配数据（收藏）
 */
export function insertData(data, timeStamp) {
    return request({
        url: '/patientUserRel/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗分配数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            
/**
 * @description: 获取治疗师列表数据
 */
export function getTherapistList(data) {
    return request({
        url: '/patientUserRel/userList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取关联治疗师列表数据
 */
export function getUserRelList(data) {
    return request({
        url: '/patientUserRel/userRelList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除治疗分配数据
 */
    export function deleteUserRelData(data, timeStamp) {
    return request({
        url: '/patientUserRel/cancel',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        