
<!--
* @Description: 治疗师个人工作量统计页面
* @Reference:
-->

<template>
<el-container>
    <el-main class="basic-main">
        <el-form class="searchForm" :inline="true">
            <div class="search-btn-group">
            <el-form-item>
                <el-button type="primary" v-if="exportStatus" @click="toExport()">导出</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="default" @click="back()">返回</el-button>
            </el-form-item>
            </div>
        </el-form>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
             <template slot="pictureSignature" slot-scope="scope">
                <el-image
                style="width: 100px; height: 100px"
                :src="scope.data.row.pictureSignature"
                :fit="'fill'"></el-image>
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, toExport } from '@/api/therapistPersonal'
import generalTable from '@/components/Table'

export default {
    name: 'TherapistPersonal',
    components: {
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [],
            tableConfig: {
                id: 'therapistPersonal',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'patientName' // 必填
                },
                {
                    label: '医嘱类型', // 必填
                    prop: 'doctorOrderTypeLabel' // 必填
                },
                {
                    label: '所属治疗', // 必填
                    prop: 'operTypeLabel' // 必填
                },
                {
                    label: '治疗项目', // 必填
                    prop: 'operName' // 必填
                },
                {
                    label: '治疗设备', // 必填
                    prop: 'deviceName' // 必填
                },
                {
                    label: '治疗医生', // 必填
                    prop: 'impDocName' // 必填
                },
                {
                    label: '治疗登记时间', // 必填
                    prop: 'treatmentDate', // 必填
                    formatter: (row, column) => {
                        return this.moment(row.treatmentDate).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                {
                    label: '单价', // 必填
                    prop: 'price' // 必填
                },
                {
                    label: '项目数量', // 必填
                    prop: 'schCount' // 必填
                },
                {
                    label: '营收金额', // 必填
                    prop: 'amount' // 必填
                },
                {
                    label: '签字', // 必填
                    slot: 'pictureSignature' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            empId: '',  
            treatDateStartTime: '',
            treatDateEndTime: '',
            dateStartTime: '',
            dateEndTime: '',
            archivesInfoId: '',
            exportStatus: true,
            operId: '',
        }
    },
    created() {
        this.exportStatus = this.hasPermission('trerapist-export')
        this.empId = this.$route.query.empId;
        this.treatDateStartTime = this.$route.query.treatDateStartTime;
        this.treatDateEndTime = this.$route.query.treatDateEndTime;
        this.archivesInfoId = this.$route.query.archivesInfoId;
        this.dateStartTime = this.$route.query.dateStartTime;
        this.dateEndTime = this.$route.query.dateEndTime;
        this.operId = this.$route.query.operId;
        this.deptId = this.$route.query.deptId;
        this.getList()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const page = this.$refs.mainTable.getPage()
                const paramObj ={
                    empId: this.empId,
                    treatDateStartTime: this.treatDateStartTime,
                    treatDateEndTime: this.treatDateEndTime,
                    dateStartTime: this.dateStartTime,
                    dateEndTime: this.dateEndTime,
                    archivesInfoId: this.archivesInfoId,
                    operId: this.operId,
                    deptId: this.deptId
                }
                const param = Object.assign({}, page, paramObj)
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                })
            })
        },
        //导出
        toExport(){
            const obj = {
                empId: this.empId,
                treatDateStartTime: this.treatDateStartTime,
                treatDateEndTime: this.treatDateEndTime,
                dateStartTime: this.dateStartTime,
                dateEndTime: this.dateEndTime,
                archivesInfoId: this.archivesInfoId,
                operId: this.operId
            };
            toExport(obj).then(({ res }) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              var aData = new Date();
              const currentDate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              const fileName = "患者治疗记录单-"+currentDate+".xlsx";
              const linkNode = document.createElement("a");
              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击
    
              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            })
            .catch((err) => {
              console.log(err);
            });
        },
        back() {
        this.$router.back()
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.search-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>

        