<!--
* @Description: 设备使用情况统计页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
        :exportStatus="exportStatus" @exportFun="toExport"
      />
    </el-header>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="asarmariumNums" slot-scope="scope">
          <a class="clickCell" @click="toDetail(scope)">{{
            scope.data.row.asarmariumNums
          }}</a>
        </template>
      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import { getList,toExport } from "@/api/equipmentUsage";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";

export default {
  name: "EquipmentUsage",
  components: {
    searchForm,
    generalTable,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "设备库名称",
          defaultVal: "",
        },
        {
          type: "daterange",
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
          key: "device",
          label: "治疗日期",
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "equipmentUsage",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "设备库名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "设备数量", // 必填
            slot: "asarmariumNums", // 必填
          },
          {
            label: "所属治疗", // 必填
            prop: "operType", // 必填
            formatter: (row, column) => {
              const stampContainer = {
                0: "PT",
                1: "OT",
                2: "ST",
                3: "其他",
                4: "理疗",
                6: "通用",
                7: "脊柱",
              };
              return stampContainer[row.operType];
            },
            width: "200",
          },
          {
            label: "治疗项目", // 必填
            prop: "operName", // 必填
          },
          {
            label: "使用总次数", // 必填
            prop: "sumUserNum", // 必填
          },
          {
            label: "营收总金额", // 必填
            prop: "sumPrice", // 必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      exportStatus: true, // 导出按钮的隐藏/显示 true显示 false隐藏
      startDate: '', //当前月第一天
      endDate: '', //当前月最后一天
      monthDate: [], //当前月集合
    };
  },
  created() {
    this.getList();
    this.getCurrentMonthFirst()
    this.getCurrentMonthLast()
    this.getMonthDate()
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.timeStamp = timeStamp;
          this.total = res.total;
        });
      });
    },
      //给查询条件赋值为当前月份
      getMonthDate() {
           this.monthDate.push(this.startDate,this.endDate)
            this.searchOptions[1].defaultVal = this.monthDate
            
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },
            toExport(){
            // const paramObj = this.$refs.mainSearch.packageData()
            const param = this.getParam()
            toExport(param).then(({ res }) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              var aData = new Date();
              const currentDate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              const fileName = "设备使用情况统计-"+currentDate+".xlsx";
              const linkNode = document.createElement("a");
              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击
    
              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            })
            .catch((err) => {
              console.log(err);
            });
        },
    toDetail(scope) {
      const queryParam = {
        deviceId: scope.data.row.id,
        deviceStartTime: this.paramObj.deviceStartTime,
        deviceEndTime: this.paramObj.deviceEndTime,
      };
      console.log("1111111222222333", queryParam);
      //权限配置：先在页面中配置菜单项，编辑，将权限表示设置为页面名称，添加if方法，当hasPermission为true时，有权限
      if (!this.hasPermission("stats-equipmentUsageDetail")) {
        this.$message({
          type: "warning",
          message: "尚无权限!",
        });
        return;
      }
      this.$router.push({
        path: "/statistics/equipmentUsage/detail",
        query: queryParam,
      });
    },

    onClickRow(row) {
      console.log("点击行:", row);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
