/*
 * @LastEditors: ZF-WLY
 * @Author: ligc
 */
import request from '@/utils/request'

/**
 * @description: 患者列表
 */
export function getList(data) {
    return request({
        url: '/patientArchivesInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增患者
 */
 export function insertData(data) {
    return request({
        url: '/patientArchivesInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改患者基本信息
 */
 export function editData(data, timeStamp) {
    return request({
        url: '/patientArchivesInfo/update',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 获取就诊数量
 */
 export function getCount(data) {
    return request({
        url: '/patientBasicInfo/getCount',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 所属科室
 */
 export function getDept(data) {
    return request({
        url: '/admin/dept/list',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 获取患者详情数据
 */
 export function getDetail(data) {
    return request({
        url: '/patientArchivesInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 根据身份证号获取患者详情数据
 */
 export function getByIdCare(data) {
    return request({
        url: '/patientArchivesInfo/getByIdCare',
        method: 'GET',
        params: data
    })
}

/**
 * @description: 获取患者详情数据
 */
 export function getTreatmentInfo(data) {
    return request({
        url: '/patientArchivesInfo/treatmentInfo',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取检查信息
 */
export function getInspectionList(data){
    return request({
        url: '/patientInspectionInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })

}

/**
 * @description: 导出检查记录
 */
 export function exportInspection(data) {
    return request({
        url: '/patientInspectionInfo/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

/**
 * @description: 获取检验信息
 */
 export function getEmrList(data){
    return request({
        url: '/patientEmrInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 导出检验记录
 */
 export function exportEmr(data) {
    return request({
        url: '/patientEmrInfo/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

/**
 * @description: 获取检验结果
 */
 export function getEmrResult(data){
    return request({
        url: '/patientEmrResult/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取评估列表
 */
export function getAssessmentList(data){
    return request({
        url: '/scalePatient/assessmentList',
        method: 'GET',
        params: data,
        showLoading: true
    })
    
}

/**
 * @description: 结束治疗/出院
 */
export function stopTreatment(data,timeStamp){
    return request({
        url: '/patientBasicInfo/stopTreatment',
        method: 'POST',
        data,
        timeStamp,
    })
}

/**
 * @description: 获取患者住院信息
 */
 export function getInfo(data) {
    return request({
        url: '/patientBasicInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取患者基本信息
 */
 export function getPatientsSimpleBasicRes(data) {
    return request({
        url: '/patientBasicInfo/patientsSimpleBasicRes',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 编辑主诉
 */
 export function updateChiefComplaint(data, timeStamp) {
    return request({
        url: '/patientBasicInfo/update',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 删除疾病诊断
 */
export function deleteTreatmentInfo(data, timeStamp) {
    return request({
        url: '/patientBasicTargetContent/delete',
        method: 'POST',
        data,
        timeStamp
    })
}

/**
 * @description: 获取会诊记录
 */
 export function getMdtRecord(data) {
    return request({
        url: '/patientMdtRecord/list',
        method: 'GET',
        showLoading: true,
        params: data
    })
}


