
<!--
* @Description: 大屏配置页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <el-button class="header-btn" type="primary" @click="toInsert()">新增</el-button>
        </el-header>
        <el-main class="basic-main">
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getList" @onClickRow="onClickRow">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="getList" />
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData } from '@/api/largeScreenConfig'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/largeScreenConfig/addDialog'
export default {
    name: 'LargeScreenConfig',
    components: {
        searchForm,
        generalTable,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'role',
                    filterable: true,
                    multiple: true,
                    label: '多选',
                    options: [
                        {
                            value: '1',
                            label: '内容1'
                        },
                        {
                            value: '2',
                            label: '内容2'
                        },
                        {
                            value: '3',
                            label: '内容3'
                        },
                        {
                            value: '4',
                            label: '内容4'
                        },
                        {
                            value: '5',
                            label: '内容5'
                        }
                    ],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'date',
                    label: '日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'largeScreenConfig',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '大屏配置', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '地址', // 必填
                        prop: 'deviceIp' // 必填
                    },
                    {
                        label: '开始时间-结束时间', // 必填
                        prop: 'startTime', // 必填
                        formatter: (row) => {
                            return `${this.moment(row.startTime).format("HH:mm")}-${this.moment(row.endTime).format("HH:mm")}`
                        }
                    },
                    {
                        label: '时间间隔', // 必填
                        prop: 'timeInterval' // 必填
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                // const param = this.getParam()
                getList().then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.getList()
                })
            })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.header-btn {
    margin: 10px 0;
}
</style>

