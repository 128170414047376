<!--
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="70%"
        :title="typeDetail.id ? '编辑问题分类' : '新增问题分类'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入"
                            clearable
                            maxlength="1000"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类排序" prop="sort">
                        <el-input-number
                            v-model="form.sort"
                            placeholder="请输入"
                            clearable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="上级分类" prop="parentId">
                        <el-cascader
                            v-model="form.parentId"
                            :options="treeData"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        typeDetail: {
            type: Object,
        },
        treeData: {
            type: Array,
        },
    },
    data() {
        return {
            typeList: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入分类名称",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "请输入分类排序",
                        trigger: "blur",
                    },
                ],
                parentId: [
                    {
                        required: true,
                        message: "请选择上级分类",
                        trigger: "blur",
                    },
                ],
            },
            form: { 
                name: "",
                parentId:"",
                sort:"",
            },
            visible: false,
        };
    },
    created() {},
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.typeDetail.parentId) {
                    this.form={
                        id:this.typeDetail.id,
                        name: this.typeDetail.name,
                        parentId:this.typeDetail.parentId,
                        sort:this.typeDetail.sort,
                    }
                }
                //...todo
            }
        },
    },
    mounted() {},
    methods: {
        submit() {
            if(typeof(this.form.parentId)=="object"){
                this.form.parentId = this.form.parentId[this.form.parentId.length-1]
            }
            this.$refs.insertForm.validate((valid) => {
                if(valid){
                    this.$emit("submit",this.form);
                    this.updateVisible(false)
                }
            })
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form={ 
                    name: "",
                    parentId:"",
                    sort:"",
                }
            }
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        