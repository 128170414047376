<!--
 * @Author: your name
 * @Date: 2022-09-02 10:43:34
 * @LastEditTime: 2022-09-15 09:20:52
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\workbook\index.vue
-->
<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
        </el-header>
        <el-main class="basic-main">
            <generalTable
                ref="mainTable"
                :table-data="list"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                :checkIds="checkedIds"
            >
                <template slot="handle" slot-scope="scope">
                    <!-- 登记状态 0未登记、1未审核、2已审核 -->
                    <!-- 全部都可以查看；未登记可以登记；未审核可以变更-->
                    <el-button v-if="scope.data.row.isMark === '1'" @click="toUpdate(scope.data.row.basicInfoId, 'edit')" type="text">变更</el-button>
                    <el-button v-if="scope.data.row.isMark === '0'" @click="toUpdate(scope.data.row.basicInfoId, 'add')" type="text">登记</el-button>
                    <el-button v-else @click="toDetail(scope.data.row.basicInfoId)" type="text">变更记录</el-button>
                </template>
            </generalTable>
        </el-main>
        <addDialog ref="insert" :basicInfoId="basicInfoId" :useDate="useDate" :type="type" @success="updateSuccess" />
        <history ref="history" :basicInfoId="basicInfoId" :useDate="useDate" />
    </el-container>
</template>
<script>
import {getList} from '@/api/treatmentWorkbook';
import { getDict } from "@/api/common";
import searchForm from '@/components/SearchForm';
import generalTable from "@/components/Table";
import addDialog from "@/views/treatment/workbook/addDialog"
import history from '@/views/treatment/workbook/historyDialog'
export default {
    name: "treatmentWorkbook",
    components: {
        searchForm,
        generalTable,
        addDialog,
        history
    },
    data(){
        const today = this.moment().format("YYYY-MM-DD")
        const now = new Date().getTime()
        const datetimePickerOptions = {
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }
        return {
            type: "",
            list: [],
            total: 1,
            checkedIds: [],
            paramObj: {},
            timeStamp: "",
            basicInfoId: "",
            useDate: now,
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者', // 必填
                        prop: 'name', // 必填
                    },
                    {
                        label: '在院状态', // 必填
                        prop: 'statusIn', // 必填
                        formatter: (row, column) => {
                            // 1住院 0门诊
                            const statusMap = {
                                "1":"住院", 
                                "2":"门诊"
                            }
                            return statusMap[row.statusIn]
                        },
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'hosNum', // 必填
                    },
                    {
                        label: '医嘱数量', // 必填
                        prop: 'count', // 必填
                    },
                    {
                        label: '登记数量', // 必填
                        prop: 'markCount', // 必填
                    },
                    {
                        label: '登记人', // 必填
                        prop: 'markUser', // 必填
                    },
                    {
                        label: '登记状态', // 必填
                        prop: 'isMark', // 必填
                        formatter: (row, column) => {
                            // 0未登记、1未审核、2已审核
                            const markMap = {
                                "0":"未登记", 
                                "1":"未审核", 
                                "2":"已审核", 
                            }
                            return markMap[row.isMark]
                        },
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: "select",
                    placeholder: '请选择在院状态',
                    key: 'statusIn',
                    filterable: true,
                    label: '在院状态',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'date',
                    placeholder: '登记日期',
                    key: 'useDate',
                    label: '登记时间',
                    defaultVal: today,
                    datetimePickerOptions,
                }
            ],
        }
    },
    async created() {
        const statusIn = await this.getPatientStatus()
        const searchOptions = this.searchOptions.map(item => {
            if(item.key === "statusIn") {
                return {
                    ...item, options:statusIn
                }
            }
            return {
                ...item
            }
        })
        this.searchOptions = [...searchOptions]
        this.$nextTick(() => {
            this.getList()
        })
    },
    methods: {
        /**
         * @description:搜索
         * @param {*} data
         * @return {*}
         */
        search(data) {
            console.log("------search data", data)
            let paramObj = this.getParam(data)
            console.log("------getParam", this.paramObj)
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getParam(data) {
            let page = this.$refs.mainTable.getPage()
            let paramObj = this.$refs.mainSearch.packageData();
            let param = Object.assign({}, page, paramObj)
            data && Object.assign(param, data)
            this.paramObj = param
            return param
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                let param = this.getParam()
                console.log("------param", param)
                this.useDate = param.useDate
                getList(param).then(({ res, timeStamp }) => {
                    this.list = res.records
                    this.total = res.total * 1
                    this.timeStamp = timeStamp
                })
            })
        },
        toDetail(basicInfoId){
            this.basicInfoId = basicInfoId
            this.$refs.history.updateVisible(true)
        },
        toUpdate(basicInfoId, type){
            this.basicInfoId = basicInfoId
            this.type = type
            this.$refs.insert.updateVisible(true)
        },
        getPatientStatus() {
            const key = "patient_inHos_status"
            return new Promise(resolve => {
                getDict({ type: key }).then(({ res }) => {
                    const patient_inHos_status = res[key].map((item) => {
                        return {
                            value: item.value,
                            label: item.label,
                        };
                    });
                    resolve(patient_inHos_status)
                });
            })
        },
        updateSuccess(){
            this.$refs.mainSearch.resetForm()
        },
    }
}
</script>
<style lang="scss" scoped>

</style>