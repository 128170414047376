<!--
 * @LastEditors: Please set LastEditors
 * @Author: Richardlv
-->

<template>
    <el-container>
        <el-dialog
            width="60%"
            title="角色详情"
            v-if="detailFlag"
            :visible.sync="detailFlag"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            @close="clearTree()"
        >
            <el-descriptions class="margin-top" title="基本信息" :column="2">
                <el-descriptions-item label="角色名称">{{
                    detailData.name || commonConfig.$nullData
                }}</el-descriptions-item>
                <el-descriptions-item label="角色编码">{{
                    detailData.roleEncoding || commonConfig.$nullData
                }}</el-descriptions-item>
                <el-descriptions-item label="角色类型">{{
                    permissionTypeList[detailData.permissionType] ||
                    commonConfig.$nullData
                }}</el-descriptions-item>
                <el-descriptions-item label="角色描述">{{
                    detailData.remark || commonConfig.$nullData
                }}</el-descriptions-item>
            </el-descriptions>
            <div class="mdrcc-info-title">权限设置</div>
            <el-tabs type="border-card">
                <el-tab-pane :lazy="true" label="PC">
                    <el-row>
                        <el-col :span="24">
                            <el-tree
                                :data="menuData"
                                :props="{
                                    children: 'children',
                                    hasChildren: 'hasChildren',
                                    label: 'name',
                                    disabled:() => true
                                }"
                                show-checkbox
                                default-expand-all
                                :check-on-click-node="false"
                                :expand-on-click-node="false"
                                node-key="id"
                                :default-checked-keys="oldCheckedMenu"
                                ref="tree"
                            >
                            </el-tree>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane :lazy="true" label="APP"> </el-tab-pane>
                <el-tab-pane :lazy="true" label="数据权限" >
                    <el-row>
                        <el-col
                            :span="24"
                            style="background-color: #ffffff; margin-top: 10px"
                        >
                            <el-radio-group v-model="allFlag">
                                <el-radio disabled label="0">全部数据</el-radio>
                                <el-radio disabled label="1">自定义</el-radio>
                            </el-radio-group>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="allFlag == '1'"
                            style="background-color: #ffffff"
                        >
                            <el-checkbox v-model="self" disabled
                                >个人数据</el-checkbox
                            >
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="allFlag == '1'"
                            style="background-color: #ffffff"
                        >
                            <el-checkbox v-model="dept" disabled
                                >本部门/科室数据</el-checkbox
                            >
                        </el-col>
                        <el-col :span="24" v-if="allFlag == '1'">
                            <el-tree
                                disabled
                                style="height: 250px; overflow-y: scroll"
                                :data="orgTableData"
                                :props="{
                                    children: 'children',
                                    hasChildren: 'hasChildren',
                                    label: 'name',
                                    disabled:() => true
                                }"
                                show-checkbox
                                default-expand-all
                                :expand-on-click-node="false"
                                node-key="id"
                                check-on-click-node
                                :default-checked-keys="oldCheckedOrg"
                                ref="dataTree"
                            >
                            </el-tree>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button @click="changedetailFlag">取 消</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>
<script>
// import { getList } from "@/api/menu";
import { getRolePermissions } from "@/api/character";
export default {
    data() {
        return {
            typeOptions: [
                {
                    value: "1",
                    label: "功能权限",
                },
                {
                    value: "2",
                    label: "数据权限",
                },
            ],
            checkedMenu: [],
            permissionTypeList: ["", "功能权限", "数据权限"],
            allFlag: "", //0全部 1 自定义
            self: false, //0选中 1 未选
            dept: false, //0选中 1 未选
            oldCheckedOrg: [],
            checkedOrg: [],
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.checkedMenu = this.oldCheckedMenu;
                this.getRoleData();
            }
        },
        detailData(newVal){
            this.checkedMenu = this.oldCheckedMenu;

            this.getRoleData();
        }
    },
    created() {
        this.checkedMenu = this.oldCheckedMenu;
        this.getRoleData();
    },
    mounted() {},

    props: {
        detailData: Object,
        detailFlag: Boolean,
        oldCheckedMenu: Array,
        orgTableData: {
            type: Array,
        },
        menuData: {
            type: Array,
        },
    },

    methods: {
        clearTree() {
            this.$refs.tree.setCheckedKeys([]);
            if (this.$refs.dataTree) {
                this.$refs.dataTree.setCheckedKeys([]);
            }
        },
        changedetailFlag() {
            this.$emit("changedetailFlag");
        },

        // getMenuData() {
        //     getList().then(({ res }) => {
        //         this.menuData = res;
        //     });
        // },
        /**
         * @description: 获取选中数据权限 树
         * @param {*}
         * @return {*}
         */
        getRoleData() {
            console.log("this.detailData==", this.detailData);
            if (this.detailData.id !== "") {
                const parm = { roleId: this.detailData.id };
                getRolePermissions(parm).then(({ res }) => {
                    if(res!==null){
                        if (res.selectDept !== "") {
                            this.oldCheckedOrg = res.selectDept.split(",");
                            this.checkedOrg = res.selectDept.split(",");
                            }
                            this.allFlag = res.allFlag;
                            this.dept = res.dept == "0" ? true : false;
                            this.self = res.self == "0" ? true : false;
                    }else{
                        this.allFlag ="";
                        this.dept=false;
                        this.self=false;
                    }                  
                });
            }
        },
    },
};
</script>
<style scope>
.coverBox {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.el_input_cust > input {
    border: none;
}
.el_input_cust > textarea {
    border: none;
}
.el-tree {
    margin-top: 8px;
}
.el-row {
    background-color: white;
    padding-left: 10px;
}
.el-col{
    margin-top: 10px;
}
</style>