<!--
 * @Author: your name
 * @Date: 2022-12-08 13:49:34
 * @LastEditTime: 2023-01-31 11:23:23
 * @LastEditors: ligc 951906427@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\other\Patient360\components\treatmentPlan.vue
-->
<template>
    <el-main>
        <generalTable
            id="mainTable"
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :newHeight="tableData.length * 30 + 36"
        >
            <template slot="handle" slot-scope="scope">
                <el-button type="text" @click="toDetail(scope)">详情</el-button>
            </template>
        </generalTable>
        <detail ref="detail" :id="checkId" :basicInfoId="basicInfoId" @close="openPat360()" />
    </el-main>
</template>
<script>
import generalTable from '@/components/Table'
import detail from '@/views/other/Patient360/components/treatmentPlanDetailDialog'
import { getPlanList } from '@/api/treatmentPlan'
export default {
    props: {
        basicInfoId: String,
    },
    components: {
        generalTable,
        detail
    },
    created() {
        getPlanList({basicInfoId: this.basicInfoId, size: -1}).then(({res}) => {
            this.tableData = [...res.records]
        })
    },
    data() {
        return {
            tableHeight: 0,
            checkId: '',
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入计划名称',
                    key: 'planName',
                    label: '计划名称',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'createTime',
                    label: '计划创建日期',
                    defaultVal: []
                }
            ],
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '计划名称', // 必填
                        sortable: false,
                        window: 240,
                        prop: 'planName' // 必填
                    },
                    {
                        label: '创建人', // 必填
                        sortable: false,
                        with: 180,
                        prop: 'createName' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        sortable: false,
                        with: 180,
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate * 1).format('YYYY-MM-DD')
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        sortable: false,
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        // width: '180' // 不必填
                    }
                ]
            },
            total: 0, // 总条数
            timeStamp: ''
        }
    },
    methods: {
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$emit("close")
            this.$refs.detail.updateVisible(true)
        },
        /** 显示患者360组件
         * @description: 触发父组件`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        openPat360(){
            this.tableData = [...this.tableData]
            this.$emit("close")
        }
    }
}
</script>
