<!--
 * @Author: your name
 * @Date: 2022-11-24 10:30:49
 * @LastEditTime: 2022-11-25 13:44:49
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\patient\summary\addDialog.vue
-->

<template>
    <el-dialog
        width="78%"
        title="治疗小结详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        id="summary-detail"
    >
        <div class="mdrcc-info-title">
            评估信息
        </div>
        <el-row :gutter="10" class="mg-bt-15">
            <generalTable
                ref="mainTable"
                :table-data="scaleArray"
                :config="scaleConfig"
                :newHeight="200"
            ></generalTable>
        </el-row>
        <div class="mdrcc-info-title">
            治疗信息
        </div>
        <el-row :gutter="10" class="mg-bt-15">
            <generalTable
                ref="mainTable"
                :table-data="treatmentArray"
                :config="treatmentConfig"
                :newHeight="200"
            ></generalTable>
        </el-row>
        <el-descriptions class="margin-top" title="其他信息" :column="1">
            <el-descriptions-item label="小结类型">
                {{ form.conclusionTypeLabel || commonConfig.$nullData }}
            </el-descriptions-item>
            <el-descriptions-item label="小结内容">
                {{ form.conclusionContent || commonConfig.$nullData }}
            </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="updateVisible(false)">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getDetail } from '@/api/patientTreatmentConclusion'
import generalTable from '@/components/Table'
export default {
    components: {
        generalTable
    },
    props: {
        id: String
    },
    data() {
        return {
            treatmentArray: [],
            treatmentConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '治疗师',
                        prop: 'therapyName'
                    },
                    {
                        label: '医嘱类型',
                        prop: 'doctorOrderTypeLabel'
                    },
                    {
                        label: '所属治疗',
                        prop: 'operTypeLabel'
                    },
                    {
                        label: '治疗项目',
                        prop: 'operName'
                    },
                    {
                        label: '治疗时间',
                        prop: 'treatmentDateStr',
                        formatter: (row, column) => {
                            return row.treatmentDate ? this.moment(row.treatmentDate).format('YYYY-MM-DD') : '--'
                        }
                    },
                    {
                        label: '状态',
                        prop: 'statusLabel'
                    }
                ]
            },
            scaleArray: [],
            scaleConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类',
                        prop: 'typeName'
                    },
                    {
                        label: '类型',
                        prop: 'patientScaleTypeLabel'
                    },
                    {
                        label: '量表/方案名称',
                        prop: 'name'
                    },
                    {
                        label: '评定日期',
                        prop: 'evaluateDate',
                        formatter: (row, column) => {
                            return row.evaluateDate ? this.moment(row.evaluateDate).format('YYYY-MM-DD') : '--'
                        }
                    },
                    {
                        label: '评定人',
                        prop: 'userName'
                    },
                    {
                        label: '状态',
                        prop: 'patientScaleStatusLabel'
                    }
                ]
            },
            form: {
                conclusionTypeLabel: '',
                conclusionContent: ''
            },
            visible: false
        }
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.id && this.getDetail()
            } else {
                if (this.$listeners['close']) {
                    this.$emit('close')
                }
                this.form = {
                    conclusionTypeLabel: '',
                    conclusionContent: ''
                }
                this.scaleArray = []
                this.treatmentArray = []
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                console.log('------getDetail', res)
                const { assessmentResList, treatmentResList, ...others } = res
                this.form = { ...others }
                this.scaleArray = [...assessmentResList]
                this.treatmentArray = [...treatmentResList]
            })
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped>
.mg-bt-15 {
    margin-bottom: 15px;
}
#summary-detail ::v-deep .el-descriptions-item__content {
    white-space: pre-wrap;
}
#summary-detail ::v-deep .el-descriptions-item__label {
    align-items: flex-start;
}
</style>
