
<template>
  <el-dialog
    width="70%"
    class="dialog"
    title="添加量表"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
      />
    </el-header>
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="10">
        <generalTable
          ref="mainTable"
          :table-data="tableData"
          :config="tableConfig"
          :total="total"
          :height="300"
          @selection-change="handleSelectionChange"
          @updatePage="getListByNotIds"
        >
        </generalTable>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getDetail,getListByNotIds,getScaleTypeList } from "@/api/evaluationScheme";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import moment from "moment";
export default {
  props: {
    orgList: Array
  },
  components: {
    searchForm,
    generalTable,
  },
  // 数据更新生命周期函数
  updated() {},
  data() {
    return {
      checkId: "",
      tableData: [
      ],
      searchOptions: [
        
        {
           type: "select",
          placeholder: "请选择",
          key: "scaleTypeId",
          filterable: true,
          label: "评定分类",
          options: [],
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "量表名称",
          key: "scaleInfoName",
          label: "量表名称",
          defaultVal: "",
        },
      ],
      selectList: [],
      currentSelect :[],
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "评定分类", // 必填
            prop: "sctyName", // 必填
          },
          {
            label: "量表名称", // 必填
            prop: "scaleInfoName", // 必填
          },
          
        ],
      },
      rules: {
        scaleInfoName: [
          { required: true, message: "请输入量表名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      form: {
        checkbox: [],
        doctorOrder: [
          {
            content: "",
            id: moment().valueOf(),
          },
        ],
      },
      total: 0,
      visible: false,
      timeStamp: "", //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.getListByNotIds()
      } else {
        this.selectList = []
      }
    },
  },
  created() {
    this.getScaleTypeList();
  },
  mounted() {},
  methods: {
    /**
     * 根据康复评定项名称--查询除选择的以外的所有量表项
     */
    search(data) {
      const current = [...this.currentSelect]
      const lastTableIds = this.tableData.map(item => item.scaleInfoId)
      const lastOtherPage = this.selectList.filter(item => !lastTableIds.includes(item.scaleInfoId))
      this.selectList = [...lastOtherPage, ...current]
      this.$nextTick(() => {
        const paramObj = this.$refs.mainSearch.packageData()
        const param = {
          ...paramObj, 
          idss: this.orgList.map(item => item.scaleInfoId).join(),
          scaleInfoName: data.scaleInfoName,
          pageNo: 1
        }
        getListByNotIds({...param})
          .then(({ res }) => {
            const { records, total } = res;
            this.tableData = [...records];
            this.total = parseInt(total)
            const selectIds = this.selectList.map(item => item.scaleInfoId)
            const selectArray = this.tableData.filter(item => selectIds.includes(item.scaleInfoId))
            this.$nextTick(() => {
              this.$refs.mainTable.toggleSelection([...selectArray])
            })
          })
      })
    },
    /**
     * 查询除选择的以外的所有量表项
     */
    getListByNotIds() {
      const current = [...this.currentSelect]
      const lastTableIds = this.tableData.map(item => item.scaleInfoId)
      const lastOtherPage = this.selectList.filter(item => !lastTableIds.includes(item.scaleInfoId))
      this.selectList = [...lastOtherPage, ...current]
      this.$nextTick(() => {
        const page = this.$refs.mainTable.getPage()
        const param = {
          ...page, 
          idss: this.orgList.map(item => item.scaleInfoId).join(),
        }
        getListByNotIds({...param})
          .then(({ res }) => {
            const { records, total } = res;
            this.tableData = [...records];
            this.total = parseInt(total)
            const selectIds = this.selectList.map(item => item.scaleInfoId)
            const selectArray = this.tableData.filter(item => selectIds.includes(item.scaleInfoId))
            this.$nextTick(() => {
              this.$refs.mainTable.toggleSelection([...selectArray])
            })
          })
      })
    },
    handleSelectionChange(val) {
      this.currentSelect = val
      this.$refs.mainTable.tableChecked = val
    },
    submit() {
      const current = [...this.$refs.mainTable.getChecked()]
      const currentIds = current.map(item => item.id)
      if(currentIds.length == 0){
        this.$message("请选择一条量表项目");
        }else {
          const lastOtherPage = this.selectList.filter(item => !currentIds.includes(item.id))
          this.selectList = [...lastOtherPage, ...current]
          this.$emit("submit", this.selectList)
          this.selectList = []
          this.updateVisible(false)
        }
    },
    submitSuccess(res) {
      this.$message({
        showClose: true,
        message: "操作成功",
        type: "success",
      });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      getDetail(param).then(({ res, timeStamp }) => {
        this.form = res;
        this.timeStamp = timeStamp;
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message(this.$handleSuccess);
        });
      });
    },
    /**
     * @description: 获取评定分类
     * @param {*}
     * @return {*}
     */
    getScaleTypeList() {
        const param = {};
        getScaleTypeList(param).then(({ res, timeStamp }) => {
            this.typeOptions = res.map((element) => {
                const ele = {
                    value: element.id,
                    label: element.name,
                };
                return ele;
            });
            this.searchOptions[0].options = this.typeOptions;
        });
    },
    updateVisible(val) {
      if(!val) {
        this.$refs.mainSearch.resetForm()
        this.$refs.mainTable.handleCurrentChange(1);
      }
      this.visible = val;
    },
  },
};
</script>
<style scoped lang='scss'>
.mdrcc-info {
  height: calc(75vh - 400px);
  overflow-y: auto;
}
.doctor-order-add {
  margin-bottom: 15px;
}
.doctor-order-div {
  position: relative;
  margin-bottom: 15px;
}
.doctor-order-input {
  width: calc(100% - 48px);
  margin-right: 15px;
}
.doctor-order-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
      
        