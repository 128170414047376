
/**
 * 页面: 大屏展示
 * pageName: largeScreenShow
 **/
import request from '@/utils/request'

/**
 * @description: 获取大屏展示列表数据
 */
export function getList(data) {
    return request({
        url: '/largeScreenConfig/getScreenData',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 删除大屏展示数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        