<template>
    <el-dialog
        width="60%"
        title="预览文件"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <div class="pdf" v-if="typeName === 'pdf'">
            <div class="pdf-bar">
                <div>
                    <div class="pdf-title">页面：{{ pdf.pageNum }}/{{ pdf.pageTotalNum || '加载中' }}</div>
                    <!-- <div>进度：{{ pdf.loadedRatio }}</div> -->
                    <!-- <div>页面加载成功: {{ pdf.curPageNum }}</div> -->
                </div>
                <div>
                    <el-button @click.stop="clock">顺时针</el-button>
                    <el-button @click.stop="counterClock">逆时针</el-button>
                    <el-button
                        @click.stop="scaleD"
                        :class="{ select: pdf.idx == 0 }"
                        @touchstart="pdf.idx = 0"
                        @touchend="pdf.idx = -1"
                    >
                        放大
                    </el-button>
                    <el-button
                        @click.stop="scaleX"
                        :class="{ select: pdf.idx == 1 }"
                        @touchstart="pdf.idx = 1"
                        @touchend="pdf.idx = -1"
                    >
                        缩小
                    </el-button>
                    <el-button @click.stop="prePage">上一页</el-button>
                    <el-button @click.stop="nextPage">下一页</el-button>
                </div>
            </div>
            <div class="main">
                <pdf
                    ref="pdf"
                    :src="commonConfig.$fileUrl + fileKey"
                    :page="pdf.pageNum"
                    :rotate="pdf.pageRotate"
                    @progress="pdf.loadedRatio = $event"
                    @page-loaded="pageLoaded($event)"
                    @num-pages="pdf.pageTotalNum = $event"
                    @error="pdfError($event)"
                    @link-clicked="page = $event"
                ></pdf>
            </div>
        </div>
        <div v-if="typeName === 'img'" style="height: 600px;">
            <el-image :src="commonConfig.$fileUrl + fileKey" :fit="'contain'" style="height: 600px;">
                <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                </div>
            </el-image>
        </div>
        <div v-if="typeName === 'audio'">
            <basicAudio ref="audio" :url="commonConfig.$fileUrl + fileKey" />
        </div>
        <div v-if="typeName === 'video'">
            <video
                :src="commonConfig.$fileUrl + fileKey"
                controls
                class="video"
                ref="video"
                width="100%"
                height="600"
            ></video>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="updateVisible(false)">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import pdf from 'vue-pdf'
import commonConfig from '@/config/common'
import basicAudio from '@/components/audio'
export default {
    props: {
        fileKey: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    components: {
        pdf,
        basicAudio
    },
    data() {
        return {
            visible: false,
            typeName: '',
            pdf: {
                pageNum: 1,
                pageTotalNum: 1,
                pageRotate: 0,
                loadedRatio: 0, // 加载进度
                curPageNum: 0,
                scale: 100, //放大系数
                idx: -1
            }
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.typeName = this.processType(this.type)
            }
        },
        loadedRatio(newVal, oldVal) {
            if (newVal === 1) {
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * @description: 处理类型数据
         * @param  {String} type
         * @return {String} typeName 返回类型名称
         */

        processType(type) {
            const { $docTypes, $imgTypes, $audioTypes, $videoTypes } = this.commonConfig
            let typeName = ''
            if ($docTypes.includes(type.toLowerCase())) {
                typeName = 'pdf'
            } else if ($imgTypes.includes(type.toLowerCase())) {
                typeName = 'img'
            } else if ($audioTypes.includes(type.toLowerCase())) {
                typeName = 'audio'
            } else if ($videoTypes.includes(type.toLowerCase())) {
                typeName = 'video'
            }
            return typeName
        },
        /**
         * @description: 放大
         */
        scaleD() {
            this.pdf.scale += 5
            this.$refs.pdf.$el.style.width = parseInt(this.pdf.scale) + '%'
        },
        /**
         * @description: 缩小
         */

        scaleX() {
            if (this.pdf.scale == 100) {
                return
            }
            this.pdf.scale += -5
            this.$refs.pdf.$el.style.width = parseInt(this.pdf.scale) + '%'
        },
        /**
         * @description: 上一页
         */

        prePage() {
            var p = this.pdf.pageNum
            p = p > 1 ? p - 1 : this.pdf.pageTotalNum
            this.pdf.pageNum = p
        },
        /**
         * @description: 下一页
         */

        nextPage() {
            var p = this.pdf.pageNum
            p = p < this.pdf.pageTotalNum ? p + 1 : 1
            this.pdf.pageNum = p
        },
        /**
         * @description: 顺时针
         */

        clock() {
            this.pdf.pageRotate += 90
        },
        /**
         * @description: 逆时针
         */

        counterClock() {
            this.pdf.pageRotate -= 90
        },
        /**
         * @description: 页面页数
         * @param {Number} e
         */

        pageLoaded(e) {
            this.pdf.curPageNum = e
        },
        /**
         * @description: 加载失败
         * @param {*} error
         */

        pdfError(error) {
            console.error(error)
        },
        updateVisible(val) {
            if (!val) {
                console.log('preview file close', this.typeName)
                switch (this.typeName) {
                    case 'audio':
                        console.log('audio onPause', this.$refs.audio)
                        this.$refs.audio && this.$refs.audio.onPause()
                        break
                    case 'video':
                        console.log('video pause', this.$refs.video)
                        this.$refs.video && this.$refs.video.pause()
                        break
                    default:
                        break
                }
            }
            // setTimeout(() => {
            this.visible = val
            // }, 50)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.pdf {
    &-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $tableColor;
        padding-bottom: 15px;
    }
    &-title {
        color: $text;
        font-weight: bold;
    }
}
</style>
