
<template>
  <el-dialog
    width="60%"
    title="查看操作项目"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="操作项目详情" :column="4" >
      <el-descriptions-item label="名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="所属治疗">{{detail.operTypeLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="备注"> {{detail.remark||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <div class="mdrcc-info-title">
      关联信息
    </div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" />
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail, getFeeItemsList } from '@/api/operation'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
      generalTable
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            tableData: [],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '收费名称', // 必填
                        prop: 'name', // 必填
                    },
                    {
                        label: '单次收费金额（元）', // 必填
                        prop: 'price' // 必填
                    },
                    {
                        label: '医保级别', // 必填
                        prop: 'level' // 必填
                    },
                    {
                        label: '自付百分比（%）', // 必填
                        prop: 'percent' // 必填
                    },
                    {
                        label: '物价标准编码', // 必填
                        prop: 'standCode' // 必填
                    },
                    {
                        label: '是否物价标准', // 必填
                        prop: 'standLabel', // 必填
                    },
                    {
                        label: '次数', // 必填
                        prop: 'createCount' // 必填
                    }
                ]
            },
            operTypes: [],
            total: 0 // 总条数
        }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal && this.id) this.getDetail()
      }
    },
    created() {
      this.getOperTypeList();
    },
    mounted() {
    },
    methods: {
      getDetail() {
        const param = {}
        //操作项id
        if (this.id) {
          param.id = this.id
        }
        this.getFeeItemsList();
        getDetail(param).then(({res}) => {
          this.detail = res
          this.operTypes.forEach(element => {
            if(element.value == this.detail.operType){
              this.detail.operType = element.label;
            }
          });
        })
      },
      /**
       * 获取操作项绑定的收费项列表
       */
      getFeeItemsList() {
        this.$nextTick(() => {
          const param = {}
          //操作项id
          if (this.id) {
            param.id = this.id
          } else {
            return;
          }
          getFeeItemsList(param).then(({res}) => {
            this.tableData = res;
          })
        })
      },
      updateVisible(flag) {
        this.visible = flag
      },
      /**
     　* 获取组套类型列表
     　* @Description: 从字典表中获取组套类型（全院1/私有2），字典表启用状态类型为 rule_set_type
       * @Param
       * @Return
     　* @Author chenm
     　* @Date 2021/11/19 9:35
     　*/
      getOperTypeList()  {
        getDict({ type: 'oper_type' }).then(({res}) => {
          this.operTypes = res.oper_type;
        });
      },
    }
}
</script>
<style scoped lang='scss'>
</style>
