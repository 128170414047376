
<!--
* @Description: 报告分类管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-main class="basic-main">
      <el-form class="searchForm" :inline="true">
        <div class="search-btn-group">
          <el-form-item>
            <el-button type="primary" v-if="addStatus" @click="toInsert()">新增</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="default" @click="back()">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getTableData">
        <!-- 切换禁用/启用状态 设置报告禁用/启用 -->
        <template slot="status" slot-scope="scope">
            <el-switch
                v-if="disableStatus"
                v-model="scope.data.row.status"
                :disabled="scope.data.row.id === '0' || !reportStatus"
                inactive-value="1"
                active-value="0"
                @change="setStatus(scope.data.row)"
            />
        </template>
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
          <el-button type="text" v-if="editStatus" @click="toEdit(scope.data.row)">编辑</el-button>
          <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope)">删除</el-button>
          <el-button type="text" v-if="addChildStatus" @click="insertChild(scope.data.row)">新增</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <!-- 添加/编辑报告分类弹出层 -->
      <addDialog
          ref="insert"
          :detail-data="detailData"
          :table-data="tableData"
          :status="status"
          :type="type"
          :insert-type="insertType"
          :time-stamp="timeStamp"
          @submit="getTableData"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getList, deleteData, disableReport } from "@/api/reportClassify";
import generalTable from "@/components/Table";
import addDialog from "@/views/scale/reportClassify/addDialog";
import detailDialog from "@/views/scale/reportClassify/detailDialog";
import { getDict } from '@/api/common'

export default {
  name: "ReportClassify",
  components: {
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      tableConfig: {
        id: "reportClassify",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        expandOpen: true, // 默认展开全部
        column: [
          {
            label: "分类名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "排序", // 必填
            prop: "sort", // 必填
          },
          {
            label: "禁用/启用", // 必填
            slot: "status", // 必填
          },
          {
            label: "备注", // 必填
            prop: "remark", // 必填
          },
          {
            label: "创建时间", // 必填
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss");
            }
          },
          {
            label: "修改时间", // 必填
            prop: "updateDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.updateDate).format("YYYY-MM-DD HH:mm:ss");
            }
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      detailData: {
          // 详细信息
          name: '', // 分类名称
          remark: '', // 备注
          parentId: '', // 父级报告id
          status: '0', // 状态
          type: '0', // 所属类型
          sort: '1', // 排序
          id: '' // 报告id
      },
      insertType: true, // 标识新增/编辑报告，为true时新增，为false时编辑
      status: [],
      type: [],
      treeData: {}, // 报告树数据
      reportStatus: true, // 报告状态权限
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      addStatus: true,
      editStatus: true,
      delStatus: true,
      addChildStatus: true,
      disableStatus: true,
    };
  },
  created() {
    this.addStatus = this.hasPermission('reportClassify-add')
    this.editStatus = this.hasPermission('reportClassify-edit')
    this.delStatus = this.hasPermission('reportClassify-del')
    this.addChildStatus = this.hasPermission('reportClassify-addChild')
    this.disableStatus = this.hasPermission('reportClassify-disable')
    this.getTableData();
    this.getReportStatusList();
    this.getReportTypeList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    toInsert() {
      this.insertType = true
      this.detailData = {
        name: '', // 分类名称
        remark: '', // 备注
        parentId: '', // 父级报告id
        status: '0', // 状态
        type: '0', // 所属类型
        sort: '1', // 排序
        scales: [], // 评定量表
        id: '' // 报告id
      }
      this.$refs.insert.updateVisible(true);
    },
      /**
       * 新增子级报告
       * @description: 
       *      新增前，判断该报告是否已被禁用：
       *      如果已禁用，消息提示
       *      如果没有禁用，将当前行id作为parentId传入添加报告弹出层，并展示添加报告弹出层，默认显示出父级报告层级
       * @param {Object} item
       * @return {*}
       */
      insertChild(item) {
          if (item.status === "1") {
              this.$message({
                  type: 'warning',
                  message: '该报告已禁用,不可新增子级!',
                  duration: 2000
              })
              return
          }
          this.insertType = true
          this.detailData = {
              parentId: item.id,
              name: '', // 分类名称
              remark: '', // 备注
              status: '0', // 状态
              type: '0', // 所属类型
              sort: '1', // 排序
              scales: [], // 评定量表
              id: '' // 报告id
          }
          this.$refs.insert.updateVisible(true)
      },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(item) {
      this.insertType = false
      this.detailData = {
        ...item
      }
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      if (scope.data.row.status === "0") {
          this.$message({
              type: 'warning',
              message: '该报告已启用,不可删除!',
              duration: 2000
          })
          return
      }
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
      this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getTableData();
        });
      });
    },
    /**
     * 获取表格数据
     * @description: 从后台获取报告分类列表，获取完成后将数据绑定至tableData
     * @param {*}
     * @return {*}
     */
    getTableData() {
      console.log('getTableData')
        getList().then(({ res, timeStamp }) => {
            this.tableData = res
            this.timeStamp = timeStamp
        })
    },
    /**
     * 设置组织报告启用禁用
     * @description: 当页面中的启用/禁用状态发生修改时，触发该函数；通过后台接口设置报告的启用禁用状态，
     *      成功/失败后消息提示，并重新加载数据
     * @param {*} item
     * @return {*}
     */
    setStatus(item) {
        const parm = {
            id: item.id,
            status: item.status
        }
        disableReport(parm, this.timeStamp).then(({ res }) => {
            // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
            this.$message({...this.commonConfig.$handleSuccess})
            this.getTypeDict()
            this.getTableData()
            this.loadTree()
        }).catch((error) => {
            this.getTableData()
        })
    },
    /**
     * 加载树
     * @description:格局报告树数据及样式，加载组织报告树
     * @param {*}
     * @return {*}
     */
    loadTree() {
      console.log('loadTree')
        getReportTree().then(({ res }) => {
            const treeCrash = this.setTree(res)
            this.treeData = treeCrash[0]
        })
    },
    getReportStatusList()  {
        getDict({ type: 'report_status' }).then(({res}) => {
            this.status = res.report_status;
        });
    },
    getReportTypeList()  {
        getDict({ type: 'report_type' }).then(({res}) => {
            this.type = res.report_type;
        });
    },
    /**
     * 返回至上一页面
     */
    back() {
        this.$router.back()
    }
},
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.search-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>

        