/*
 * @Author: your name
 * @Date: 2021-12-28 16:34:34
 * @LastEditTime: 2022-03-01 16:33:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\therapistWorkload.js
 */

/**
 * 页面: 治疗师工作量统计
 * pageName: therapistWorkload
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗师工作量统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/therapistWorkload/findList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 导出治疗师工作量统计列表数据
 */
export function toExport(data) {
    return request({
        url: 'statistics/therapistWorkload/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}


/**
 * @description: 删除治疗师工作量统计数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        