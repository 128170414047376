<!--
 * @Author: your name
 * @Date: 2022-11-15 10:19:49
 * @LastEditTime: 2022-11-15 10:30:33
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\index.vue
-->


<template>
  <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from 'vuex'
import MenuBox from '@/components/MenuBox'
export default {
  name: 'caseReportMain',
  components: { MenuBox },
  data() {
    return {
      secondMenu: []
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ])
  },
  created() {
    const { roles } = this.$store.getters
    for (let i = 0; i < roles.length; i++) {
      const element = roles[i]
      if (element.name === 'caseReportMain') {
        console.log("------case index", element)
        this.secondMenu = element.children.filter(obj => obj.type === '1' && obj.hidden === false && element.redirect !== obj.path)
      }
    }
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
</style>