var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-main',{staticClass:"basic-main"},[_c('el-row',[(_vm.scaleType != 3)?_c('el-col',{attrs:{"sm":4,"md":4,"lg":3,"xl":3}},[(!_vm.analysis)?_c('el-button',{attrs:{"size":"small","type":"primary","plain":""},on:{"click":_vm.upload}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 选择文件 ")]):_vm._e(),(_vm.fileInput)?_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*,video/*,audio/*"},on:{"change":function($event){return _vm.changeImageUrl($event)}}}):_vm._e(),_vm._l((_vm.fileList),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"fileItem"},[(item.type.indexOf('image') > -1)?_c('el-image',{staticStyle:{"width":"75px","height":"75px"},attrs:{"preview-src-list":[
                                _vm.commonConfig.$fileUrl + item.id ],"src":_vm.commonConfig.$fileUrl + item.id,"fit":"contain"}}):_vm._e(),(item.type.indexOf('video') > -1)?_c('svg-icon',{style:({
                                color: '#469cf8',
                                'font-size': '70px',
                            }),attrs:{"icon-class":"video"}}):_vm._e(),(item.type.indexOf('audio') > -1)?_c('svg-icon',{style:({
                                color: '#469cf8',
                                'font-size': '70px',
                            }),attrs:{"icon-class":"audio"}}):_vm._e(),_c('div',{staticClass:"btnBox"},[_c('el-button',{staticClass:"delBtn",attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.delFile(index)}}},[_vm._v(" 删除 ")]),_c('el-button',{staticStyle:{"margin-left":"0px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.downLoad(item)}}},[_vm._v(" 下载 ")])],1)],1),_c('div',{staticClass:"fileText"},[_vm._v(_vm._s(item.name))])])})],2):_vm._e(),_c('el-col',{attrs:{"sm":20,"md":20,"lg":21,"xl":21}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('div',{staticStyle:{"display":"flex"}},[(_vm.scaleType == 2 && _vm.status != 2)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit(1)}}},[_vm._v(" 保存 ")]):_vm._e(),(_vm.scaleType == 2)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit(2)}}},[_vm._v("提交")]):_vm._e(),(_vm.showtype !== '1' && _vm.scaleType != 2)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.printHtml()}}},[_vm._v(" 打印 ")]):_vm._e()],1),(_vm.scaleType != 4)?_c('el-button',{on:{"click":function($event){return _vm.back()}}},[_vm._v("返回")]):_vm._e()],1),_c('el-row',{staticStyle:{"margin-top":"20px"},attrs:{"gutter":20}},[_c('div',{staticClass:"slider-menu"},[_c('el-tree',{ref:"tree",staticClass:"user-tree",attrs:{"node-key":"scaleInfoId","data":_vm.scaleList,"default-expand-all":"","filter-node-method":_vm.filterTree,"expand-on-click-node":false,"highlight-current":true},on:{"node-click":_vm.onClickTreeRow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var data = ref.data;
return _c('span',{staticClass:"el-tree-node__label"},[_c('span',{attrs:{"title":data.scaleInfoName}},[_vm._v(" "+_vm._s(data.scaleInfoName)+" ")])])}}])})],1),_c('div',{staticClass:"slider-info",style:(_vm.preview ? {maxHeight: '60vh'}: {}),on:{"scroll":_vm.scrollEvent}},[_c('div',{attrs:{"id":"scaleListBox"}},_vm._l((_vm.scaleList),function(item,index){return _c('div',{key:item.scalePatientId,staticClass:"pat-scale-pane",attrs:{"label":item.scaleInfoName}},[_c('patientScale',{key:item.scaleInfoId,ref:"patient-scale",refInFor:true,attrs:{"scaleId":item.scaleInfoId,"libFlag":"privateLib","flag":_vm.$route.query.flag,"showMould":"mouldCode","patientBasicInfoId":"","patientScaleId":item.scalePatientId,"analysis":_vm.analysis,"program":true,"showHeader":index == 0 ? true : false}})],1)}),0)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }