module.exports = {

  title: '数字化康复科室',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true
}
