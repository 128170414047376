
<!--
* @Description: 质控指标管理页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getList" @onClickRow="onClickRow">
                <template slot="state" slot-scope="scope">
                    <!-- 0 启用 1 禁用 -->
                    <!-- <div>{{ scope.data.row.state }}</div> -->
                    <el-switch 
                        v-model="scope.data.row.state" 
                        name="status" 
                        inactive-value="1" 
                        active-value="0" 
                        @change="changeStatus(scope,$event)"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">查看</el-button>
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <detailDialog :id="checkId" ref="detail" />
            <addDialog :id="checkId" ref="insert" @submit="search" />
        </el-main>
    </el-container>
</template>

<script>
import { getYears, currentYear} from '@/utils/qualityControl'
import { getList, deleteData, editState } from '@/api/indicatorManagement'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/quality/indicatorManagement/addDialog'
import detailDialog from '@/views/quality/indicatorManagement/detailDialog'

export default {
    name: 'IndicatorManagement',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        const yearList = getYears()
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '质控指标',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请输入...',
                    key: 'year',
                    clearable: false,
                    filterable: true,
                    multiple: false,
                    label: '年份',
                    options: yearList,
                    defaultVal: currentYear
                },
                {
                    type: 'select',
                    placeholder: '请输入...',
                    key: 'state',
                    filterable: false,
                    multiple: false,
                    label: '状态',
                    options: [
                        {
                            value: "",
                            label: "全部",
                        },
                        {
                            value: "0",
                            label: "启用",
                        },
                        {
                            value: "1",
                            label: "禁用",
                        },
                    ],
                    defaultVal: ""
                }
            ],
            tableConfig: {
                id: 'indicatorManagement',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '质控指标', // 必填
                        prop: 'name' // 必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: '年份', // 必填
                        prop: 'year' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss")
                        }
                    },
                    {
                        label: '创建人', // 必填
                        prop: 'createName' // 必填
                    },
                    {
                        slot: 'state', // 不必填 单列插槽
                        label: '禁用/启用', // 必填
                        width: '180' // 不必填
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.search()
                })
            })
        },
        changeStatus(scope, e){
            const {id} = scope.data.row
            const state = e
            const param = {id, state}
            editState(param, this.timeStamp)
                .then(({ timeStamp }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.timeStamp = timeStamp
                    this.search()
                })
                .catch((err) => {
                    if (err) {
                        this.tableData[scope.data.$index].state = e === '0' ? '1' : '0'
                    }
                })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            const {year} = param
            this.paramObj = { ...param, year: year * 1 }
            return this.paramObj
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

