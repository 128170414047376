
<!--
* @Description: 医疗仪器管理页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <el-row v-if="addSet" class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="user" slot-scope="scope">
                <el-button v-if="userSet" type="text" @click="toChangeUser(scope)">{{scope.data.row.userNums}}</el-button>
                <span v-if="!userSet">{{scope.data.row.userNums}}</span>
            </template>
            <template slot="handle" slot-scope="scope">
                <el-button v-if="detailSet" type="text" @click="toDetail(scope)">详情</el-button>
                <el-button v-if="editSet" type="text" @click="toEdit(scope)">编辑</el-button>
                <el-button v-if="delSet" type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
            </template>
        </generalTable>
        <detailDialog :id="checkId" ref="detail" />
        <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="search" />
        <changeUser :id="checkId" ref="changeUser" :timeStamp="timeStamp" @submit="search" />
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/medicalCare'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/device/medicalCare/addDialog'
import detailDialog from '@/views/device/medicalCare/detailDialog'
import changeUser from '@/views/device/medicalCare/changeUser'
import moment from "moment";
import { getDict } from "@/api/common";


export default {
    name: 'MedicalCare',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog,
        changeUser
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'deviceName',
                    label: '设备名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'deviceNumber',
                    label: '设备编号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'deviceCode',
                    label: '设备简码',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'status',
                    label: '状态',
                    options: [],
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'medicalCare',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '设备名称', // 必填
                    prop: 'deviceName' // 必填
                },
                {
                    label: '设备编号', // 必填
                    prop: 'deviceNumber' // 必填
                },
                {
                    label: '设备简码', // 必填
                    prop: 'deviceCode' // 必填
                },
                {
                    label: '负责人', // 必填
                    prop: 'userName' // 必填
                },
                {
                    label: '状态', // 必填
                    prop: 'statusLabel' // 必填
                },
                {
                    label: '更新日期', // 必填
                    formatter: (row, column) => {
                        return (moment(row.updateDate).format("YYYY-MM-DD"));
                    }
                },
                {
                    slot: 'user', // 不必填 单列插槽
                    label: '关联治疗师', // 必填
                    width: '100' // 不必填
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            addSet:true,
            delSet:true,
            editSet:true,
            detailSet:true,
            userSet:true,
        }
    },
    created() {
        this.getList();
        this.get_armarium_status()
        this.addSet = this.hasPermission('medicalCare-add')
        this.delSet = this.hasPermission('medicalCare-del')
        this.editSet = this.hasPermission('medicalCare-edit')
        this.detailSet = this.hasPermission('medicalCare-detail')
        this.userSet = this.hasPermission('medicalCare-user')
    },
    mounted: function() {},
    methods: {
        toChangeUser(scope){
            this.checkId = scope.data.row.id
            this.$refs.changeUser.updateVisible(true)
        },
        get_armarium_status() {
            getDict({ type: 'armarium_status' }).then(({res}) => {
                let typeOptionsCache = res.armarium_status.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })
                this.searchOptions[3].options = typeOptionsCache
            });
        },
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = res.total
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...$handleSuccess})
                    this.search()
                })
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        