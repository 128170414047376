
<!--
* @Description: 我的在治患者页面
* @Reference:
-->

<template>
  <el-container>
    <el-main class="basic-main">
      <div>
        <div style="background-color: #ffffff">
          <div class="lineStyle">
            <div>治疗师：{{ userName }}</div>
            <div>当前治疗人数：{{ treatingPatientNums }}</div>
            <div>已排日程人数：{{ schedulePatientNums }}</div>
            <div style="display: flex">
              <el-button type="default" @click="back()">返回</el-button>
            </div>
          </div>
        </div>
        <generalTable
          ref="mainTable"
          :table-data="tableData"
          :config="tableConfig"
          :total="total"
        >
          <template slot="handle" slot-scope="scope" v-if="currentUserFlag==='0'">
            <el-button type="text" @click="toEdit(scope)">取消收藏</el-button>
            <el-button type="text" @click="toDetail()" 
              >添加到日程</el-button
            >
          </template>
        </generalTable>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getList, cancelCollection } from "@/api/myPatient";
import generalTable from "@/components/Table";

export default {
  name: "MyPatient",
  components: {
    generalTable,
  },
  data() {
    return {
      tableData: [],
      tableConfig: {
        id: "myPatient",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        column: [
          {
            label: "患者姓名", // 必填
            prop: "patientName", // 必填
          },
          {
            label: "所属科室", // 必填
            prop: "deptName", // 必填
          },
          {
            label: "就诊号", // 必填
            prop: "serialNum", // 必填
          },
          {
            label: "住院号", // 必填
            prop: "recordNum", // 必填
          },
          {
            label: "入院日期/就诊日期", // 必填
            prop: "heDte", // 必填
            formatter: (row, column) => {
              return this.moment(row.heDte).format("YYYY-MM-DD");
            },
          },
          {
            label: "在院状态", // 必填
            prop: "status", // 必填
            formatter: (row, column) => {
              return ["出院", "在院", "门诊", "结束治疗"][row.status];
            },
          },
          {
            label: "治疗量", // 必填
            prop: "treatmentNums", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      userName: "", //治疗师姓名
      userId: "", //治疗师Id
      total: 0, // 总条数
      schedulePatientNums: "", //已排程人数
      treatingPatientNums: "", //治疗人数
      userIdArr: [], //用户id数组
      basicinfoIdArr: [], //患者id数组
      currentUserFlag: "0", //是否显示操作列

    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    /**
     * @description: 获取列表数据
     * @author:ligc
     * @param {*}
     * @return {*}
     */
    getList() {
      this.$nextTick(() => {
        const param = { userId: this.userId };
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.patientList;
          this.userName = res.userName;
          this.schedulePatientNums = res.schedulePatientNums;
          this.treatingPatientNums = res.treatingPatientNums;
          this.currentUserFlag = res.currentUserFlag;
          this.timeStamp = timeStamp;
        });
      });
    },
    back() {
      this.$router.back();
    },

    /**
     * @description: 取消收藏
     * @author:ligc
     * @param scope 患者id , 治疗师id
     * @return {*}
     */
    toEdit(scope) {
      this.userIdArr.push(this.$route.query.userId);
      this.basicinfoIdArr.push(scope.data.row.basicInfoId);
      const param = {
        userId: this.userIdArr,
        basicInfoId: this.basicinfoIdArr,
      };
      cancelCollection(param, this.timeStamp).then(({ res, timeStamp }) => {
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getList();
      });
    },

    /**
     * @description: 添加到日程
     * @author:ligc
     * @param scope 治疗师id
     * @return {*}
     */
    toDetail() {
      const param = {userId:this.$route.query.userId};
      this.$router.push({ path: "/treatment/schedule/my", query: param});
    },

    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const param = Object.assign({}, this.paramObj, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.lineStyle {
  margin-left: 10px;
  padding: 3px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>

        