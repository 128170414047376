
/**
 * 页面: 患者医嘱统计
 * pageName: patientOrder
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者医嘱统计列表数据
 */
export function getList(data) {
    return request({
        url: '/statistics/patientAdvice/index',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
