
/**
 * 页面: 今日已治疗
 * pageName: treatedToday
 **/
import request from '@/utils/request'

/**
 * @description: 获取今日已治疗列表数据
 */
export function getList(data) {
    return request({
        url: '/patientTreatment/hasTreatmentHistory',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

