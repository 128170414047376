<!--
 * @Author: SixTeen
 * @Date: 2021-11-18 10:56:35
 * @LastEditTime: 2021-12-23 11:54:39
 * @LastEditors: yzw
 * @Description: 治疗分配页面
 * @FilePath: /recovery_management/src/views/treatment/distribution/index.vue
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button
                    type="primary"
                    v-if="rolesAllCollectionStatus"
                    @click="toAllCollection()"
                    >批量收藏</el-button
                >
                <el-button
                    type="primary"
                    v-if="rolesAllAssignStatus"
                    @click="toAllAssign()"
                    >批量分配</el-button
                >
            </el-row>
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
            >
                <template slot="patientName" slot-scope="scope">
                    <div class="patientName">
                        <span>
                            {{ scope.data.row.patientName }}
                        </span>
                        <svg-icon
                            icon-class="new"
                            v-if="scope.data.row.newPatient == 1"
                            class="newStyle"
                        />
                    </div>
                </template>
                <template slot="userNums" slot-scope="scope">
                    <span class="clickCell" @click="toDetail(scope)">
                        {{ scope.data.row.userNums }}
                    </span>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button
                        v-if="rolesCollectionStatus"
                        type="text"
                        :disabled="scope.data.row.userStatus != 0"
                        @click="toCollection(scope)"
                        >收藏</el-button
                    >
                    <el-button
                        v-if="rolesAssignStatus"
                        type="text"
                        @click="toAssign(scope)"
                        >分配</el-button
                    >
                </template>
            </generalTable>
            <detailDialog
                :basicInfoId="checkId"
                ref="detail"
                @refresh="getList"
            />
            <addDialog
                :basicInfoId="basicInfoIds"
                ref="insert"
                @refresh="getList"
            />
        </el-main>
    </el-container>
</template>

<script>
import { getList, insertData } from "@/api/distribution";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/treatment/distribution/addDialog";
import detailDialog from "@/views/treatment/distribution/detailDialog";
import { getDept } from "@/api/patient"; //获取科室
import { getDict } from "@/api/common";

export default {
    name: "Distribution",
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog,
    },
    props:{
        entry:{
            type: String,
        }
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入姓名",
                    key: "patientName",
                    label: "姓名",
                    defaultVal: "",
                },
                {
                    type: "select",
                    key: "patientStatus",
                    filterable: true,
                    label: "在院状态",
                    options: [],
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择...",
                    key: "deptId",
                    label: "所属科室",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "daterange",
                    startPlaceholder: "入院/就诊开始日期",
                    endPlaceholder: "入院/就诊开始日期",
                    key: "heDte",
                    label: "入院日期",
                    defaultVal: [],
                },
                {
                    type: "input",
                    placeholder: "请输入就诊号",
                    key: "serialNum",
                    label: "就诊号",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "请输入住院号",
                    key: "recordNum",
                    label: "住院号",
                    defaultVal: "",
                },
            ],
            tableConfig: {
                id: "distribution",
                align: "center", // 不必填 默认为center
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "患者姓名", // 必填
                        slot: "patientName", // 必填
                    },
                    {
                        label: "所属科室", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "就诊号", // 必填
                        prop: "serialNum", // 必填
                    },
                    {
                        label: "住院号", // 必填
                        prop: "recordNum", // 必填
                    },
                    {
                        label: "入院日期", // 必填
                        prop: "heDte", // 必填
                        formatter: (row, column) => {
                            return this.moment(row.heDte).format("YYYY-MM-DD");
                        },
                    },
                    {
                        label: "在院状态", // 必填
                        prop: "status", // 必填
                    },
                    {
                        label: "关联治疗师", // 必填
                        slot: "userNums", // 必填
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "220", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            basicInfoIds: [], // 多条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
            rolesCollectionStatus: true, // 收藏权限按钮权限
            rolesAllCollectionStatus: true, // 批量收藏权限按钮权限
            rolesAssignStatus: true, // 分配用户按钮权限
            rolesAllAssignStatus: true, // 批量分配用户按钮权限
        };
    },
    created() {
        // 获取权限
        this.rolesCollectionStatus = this.hasPermission(
            "distribution_collection"
        );
        this.rolesAllCollectionStatus = this.hasPermission(
            "distribution_allCollection"
        );
        this.rolesAssignStatus = this.hasPermission("distribution_assign");
        this.rolesAllAssignStatus = this.hasPermission(
            "distribution_allAssign"
        );
        this.getDeptList();
        this.getPatientStatus();

        this.getList();
    },
    mounted: function () {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept({ deptType: "4" }).then(({ res, timeStamp }) => {
                this.deptOptions = res.map((item) => {
                    const ele = {
                        value: item.id,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions[2].options = this.deptOptions;
            });
        },
        /**
         * @description: 获取在院状态
         * @param {*}
         * @return {*}
         */
        getPatientStatus() {
            getDict({ type: "patient_inHos_status" }).then(({ res }) => {
                this.patientOptions = res.patient_inHos_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions[1].options = this.patientOptions;
            });
        },
        /**
         * @description: 获取列表数据
         * @param {*}
         * @return {*}
         */
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res;
                    this.tableData = records;
                    this.total = parseInt(total);
                    this.timeStamp = timeStamp;
                });
            });
        },
        /**
         * @description: 批量收藏
         * @param {*}
         * @return {*}
         */
        toAllCollection() {
            if (this.$refs.mainTable.getChecked().length > 0) {
                let basicInfoId = this.$refs.mainTable
                    .getChecked()
                    .map((item) => {
                        return item.basicInfoId; //治疗师用户id
                    });
                const parm = {
                    basicInfoId: basicInfoId, //患者id，
                };
                insertData(parm).then(({ res }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess });
                    this.getList();
                });
            } else {
                this.$message({ message: "请选择患者", type: "warning" });
            }
        },
        /**
         * @description:  收藏
         * @param {*} scope
         * @return {*}
         */
        toCollection(scope) {
            const parm = {
                basicInfoId: scope.data.row.basicInfoId.split(), //患者id，
            };
            insertData(parm).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getList();
            });
        },
        /**
         * @description: 批量分配
         * @param {*}
         * @return {*}
         */
        toAllAssign() {
            if (this.$refs.mainTable.getChecked().length > 0) {
                this.basicInfoIds = this.$refs.mainTable
                    .getChecked()
                    .map((item) => {
                        return item.basicInfoId; //治疗师用户id
                    });
                this.$refs.insert.updateVisible(true);
            } else {
                this.$message({ message: "请选择患者", type: "warning" });
            }
        },
        /**
         * @description: 分配
         * @param {*} scope
         * @return {*}
         */
        toAssign(scope) {
            this.checkId = scope.data.row.basicInfoId;
            this.basicInfoIds = scope.data.row.basicInfoId.split();
            this.$refs.insert.updateVisible(true);
        },
        /**
         * @description: 关联治疗师详情
         * @param {*} scope
         * @return {*}
         */
        toDetail(scope) {
            this.checkId = scope.data.row.basicInfoId;
            this.$refs.detail.updateVisible(true);
        },
        /**
         * @description: 请求搜索项参数
         * @param {*}
         * @return {*}
         */
        getParam() {
            let paramObj = this.$refs.mainSearch.packageData();
            paramObj = {
                startTime: paramObj.startTime
                    ? this.moment(paramObj.startTime).valueOf()
                    : "",
                endTime: paramObj.endTime
                    ? this.moment(paramObj.endTime).valueOf()
                    : "",
            };
            let propsData = {}
            if (this.$route.query.entry === 'home') {
                //首页入口 列表只显示未绑定治疗师的患者
                propsData.relFlag = 0
            }
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj,propsData);
            this.paramObj = param;
            return param;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.clickCell {
    color: $theme;
    cursor: pointer;
}
.patientName {
    display: flex;
    justify-content: center;
}
</style>
