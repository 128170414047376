/*
 * @Author: your name
 * @Date: 2021-03-18 10:40:03
 * @LastEditTime: 2022-01-11 15:44:35
 * @LastEditors: yzw
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\api\userInfo.js
 */
import request from '@/utils/request'

/**
 * @description: 修改当前登录用户密码
 * @param {Object} data
 */
 export function updatePassword(data,timeStamp) {
    return request({
      url: '/center/updatePassword',
      method: 'POST',
      data,
      timeStamp
    })
  }

/**
 * @description: 获取医生用户信息
 */
export function getInfo() {
return request({
    url: '/center/userIndex',
    method: 'GET'
})
}

/**
 * @description: 获取未读消息列表数据
 */
 export function getList(data) {
  return request({
      url: '/center/Msglist',
      method: 'GET',
      params: data,
  })
}
