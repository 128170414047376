
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增治疗组套' : '修改治疗组套'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
              组套信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                  <el-form-item label="组套名称" prop="name">
                  <el-input
                      v-model="form.name"
                      placeholder="请输入..."
                      clearable
                      maxlength="20"
                      @blur="setNamePinyinHead"
                  />
                  </el-form-item>
              </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                  <el-form-item label="组套简称" prop="namePinyinHead">
                      <el-input
                        v-model="form.namePinyinHead"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                      />
                  </el-form-item>
              </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="组套类型" prop="hospitalPrivate">
                  <el-select
                    v-model="form.hospitalPrivate"
                    clearable
                    placeholder="请选择"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    style="width:100%"
                  >
                    <el-option
                      v-for="option in typeOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="是否启用" prop="status">
                  <el-select
                    v-model="form.status"
                    clearable
                    placeholder="请选择"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    style="width:100%"
                  >
                    <el-option
                      v-for="option in statusOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
               <el-col :sm="12" :md="12" :lg="8" :xl="24">
                   <el-form-item label="备注" prop="remark">
                        <el-input v-model="form.remark" type="textarea"  maxlength="200"/>
                   </el-form-item>
               </el-col>
            </el-row>
            <el-divider />
            <div class="mdrcc-info-title">
              关联信息
            </div>
            <el-row :gutter="20">
                 <el-main class="basic-main">
                      <el-row class="table-btn-group">
                          <el-button type="primary" @click="toInsert()">新增</el-button>
                      </el-row>
                      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getOperationList">
                          <template slot="handle" slot-scope="scope">
                              <el-button type="text" class="delBtn" @click="delOperation(scope)">删除</el-button>
                          </template>
                      </generalTable>
                      <el-divider />
                      <addDialog :id="checkId" ref="insert" @add="addOperation"/>
                 </el-main>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {insertData, editData, getOperationList, getDetail,getRuleSetStatus,getRuleSetTypes } from '@/api/ruleSet'
import generalTable from '@/components/Table'
import addDialog from '@/views/businessRules/ruleSet/addOperationDialog'
import { getDict } from '@/api/common'
import { CH2CYTPALL } from '@/utils/common'

export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
      generalTable,
      addDialog
    },
    data() {
        return {
            checkId: '',
            tableData: [],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '操作项目', // 必填
                        prop: 'operName', // 必填
                    },
                    {
                        label: '所属治疗', // 必填
                        prop: 'operTypeName' // 必填
                    },
                    {
                        label: '建议分钟数', // 必填
                        prop: 'propMin' // 必填
                    },
                    {
                        label: '单次数量', // 必填
                        prop: 'schCount' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            rules: {
                name: [
                    { required: true, message: '请输入组套名称', trigger: 'blur' }
                ],
                hospitalPrivate: [
                    { required: true, message: '请选择组套类型', trigger: 'change' }
                ],
                status: [
                    { required: true, message: '请选择是否启用', trigger: 'change' }
                ],
                namePinyinHead: [
                     { required: true, message: '请输入组套简称', trigger: 'blur' }
                ]
            },
            typeOptions: [],
            statusOptions: [],
            form: {
                checkbox: [],
                hospitalPrivate: '',
                status: '',
                namePinyinHead: '',
                remark: '',
            },
            total: 0,
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            } else {
              //新增时设置组套状态和组套类型默认值
              this.form.status='1';
              this.form.hospitalPrivate='1';
              this.tableData=[];
            }
          }
        }
    },
    created() {
        this.getRuleSetTypes();
        this.getRuleSetStatus();
        this.getOperationList();
    },
    mounted() {},
    methods: {
      submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                  const parm = this.form;
                  parm.id = this.id;
                  parm.operationList = this.tableData;
                  if (parm.id == undefined || parm.id === '') {
                      insertData(parm).then(({res}) => {
                          this.submitSuccess(res)
                      })
                  } else {
                      editData(parm,this.timeStamp).then(({res}) => {
                          this.submitSuccess(res)
                      })
                  }
                }
            })
      },
      submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
      },
     /**
     　* 获取组套详情
     　* @Description:
       * @Param null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:23
     　*/
      getDetail(){
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getDetail(param).then(({res,timeStamp}) => {
                this.form = res;
                this.timeStamp = timeStamp;
                this.getOperationList();
            })
      },
     /**
     　*  跳转新增组套关联操作项目页面
     　* @Description:
       * @Param null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:31
     　*/
     toInsert() {
          this.checkId = ''
          this.$refs.insert.updateVisible(true)
     },
      /**
      　* 操作项目列表添加方法
      　* @Description: 已存在的操作项目和新增的操作项目合并显示
        * @Param newObj 操作项目对象
        * @Return null
      　* @Author wangjh
      　* @Date 2021/11/11 9:31
      　*/
      addOperation(newObj){
        this.tableData = [
          ...this.tableData,
          {...newObj}
        ]
      },

     /**
     　* 删除组套关联操作项目
     　* @Description: 组套关联操作项目列表删除选择操作项目
       * @Param scope
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:32
     　*/
     delOperation(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                this.tableData.splice(scope.data.$index, 1)
            })
     },
     /**
     　* 获取组套关联操作项目列表
     　* @Description:this.id为组套id,为空时直接返回，不为空时根据组套id获取组套关联的操作项目
       * @Param null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:33
     　*/
      getOperationList() {
          this.$nextTick(() => {
              const param = {};
              if (this.id) {
                  param.id = this.id
              } else {
                  return;
              }
              getOperationList(param).then(({ res, timeStamp }) => {
                this.tableData = res;
                this.timeStamp = timeStamp;
              })
           })
      },

      updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
      },


     /**
     　* 获取组套启用状态
     　* @Description: 从字典表中获取组套启用状态（是1/否0），字典表启用状态类型为 rule_set_status
       * @Param null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:34
     　*/
       getRuleSetStatus() {
          getDict({ type: 'rule_set_status' }).then(({res}) => {
            this.statusOptions = res.rule_set_status;
          });
      },

     /**
     　* 获取组套类型列表
     　* @Description: 从字典表中获取组套类型（全院1/私有2），字典表启用状态类型为 rule_set_type
       * @Param null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:35
     　*/
      getRuleSetTypes() {
          getDict({ type: 'rule_set_type' }).then(({res}) => {
              this.typeOptions = res.rule_set_type;
          });
      },

      /**
      　* 组套简称赋值
      　* @Description: 根据组套名称自动获取组套的拼音首字母
        * @Param null
        * @Return null
      　* @Author wangjh
      　* @Date 2021/11/12 16:28
      　*/
      setNamePinyinHead()  {
          if (this.form.name) {
              this.form.namePinyinHead = CH2CYTPALL(this.form.name);
          }

      }

    }
}
</script>
<style scoped lang='scss'>
</style>
      
