<!--
 * @LastEditors: Please set LastEditors
 * @Author: Richardlv
-->

<!--
* @Description: 用户管理页面
* @Reference:
-->

<template>
    <el-container>
        <el-main class="basic-main">
            <el-descriptions class="margin-top" title="医院信息" :column="4">
                <template slot="extra">
                    <el-button type="primary" v-if="editStatus" @click="editHosp()">编辑</el-button>
                    <el-button type="default" @click="back()">返回</el-button>
                </template>
                <el-descriptions-item label="医院名称">
                    {{ show.hospitalName || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="系统名称">
                    {{ show.systemName || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="负责人">
                    {{ show.hospitalPrincipal || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院电话">
                    {{ show.hospitalPhone || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院传真">
                    {{ show.hospitalFax || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院邮箱">
                    {{ show.hospitalEmail || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院地址">
                    {{ show.hospitalAddress || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院备注">
                    {{ show.hospitalRemark || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="医院logo">
                    <el-image
                        style="background:#f6f6f6"
                        v-show="show.logo"
                        class="menu-item-img"
                        :src="show.logoAvatar"
                        fit="contain"
                    ></el-image>
                </el-descriptions-item>
                <el-descriptions-item label="APP下载地址">
                    <el-image v-show="show.appDownloadAddress" class="menu-item-img" :src="show.avatar" fit="contain" />
                </el-descriptions-item>
            </el-descriptions>
            <el-divider />
            <el-descriptions class="margin-top" title="工作时间" :column="5">
                <el-descriptions-item label="工作时间">
                    {{ show.workingHours || commonConfig.$nullData }}至{{ show.rushHour || commonConfig.$nullData }}
                </el-descriptions-item>
                <el-descriptions-item label="午休时间">
                    {{ show.startLunchBreak || commonConfig.$nullData }}至{{
                        show.endLunchBreak || commonConfig.$nullData
                    }}
                </el-descriptions-item>
                <el-descriptions-item label="治疗工作间隔	">
                    {{ show.workInterval || commonConfig.$nullData }}分钟
                </el-descriptions-item>
            </el-descriptions>
            <el-divider />
            <el-descriptions class="margin-top" title="量表显示" :column="5">
                <el-descriptions-item label="显示形式">
                    {{ show.scaleShowType || commonConfig.$nullData }}
                </el-descriptions-item>
            </el-descriptions>
            <el-divider />
            <div class="mdrcc-info-title">
                量表/方案基础信息
                <div class="mdrcc-info-btn-group">
                    <el-button type="primary" @click="toAssConfig()">
                        编辑
                    </el-button>
                </div>
            </div>
            <generalTable
                ref="assessmentTable"
                :table-data="assessmentList.choose"
                :config="assessmentConfig"
                :newHeight="'200'"
            ></generalTable>
            <el-divider />
            <div class="mdrcc-info-title">日常统计</div>
            <generalTable
                ref="statisticsTable"
                :table-data="statisticsData"
                :config="statisticsConfig"
                :newHeight="'200'"
                :total="0"
                @updatePage="getAllWorkbench"
            >   
                <template slot="status" slot-scope="scope">
                    <el-switch
                        v-model="scope.data.row.status"
                        inactive-value="1"
                        active-value="0"
                        @change="setStatus(scope, $event)"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" v-if="editStatus" @click="toConfig(scope, 'statistics')">编辑</el-button>
                </template>
            </generalTable>
            <el-divider />
            <div class="mdrcc-info-title">待办任务</div>
            <generalTable
                ref="taskTable"
                :table-data="taskData"
                :config="taskConfig"
                :newHeight="'200'"
                :total="0"
                @updatePage="getAllWorkbench"
            >
                <template slot="status" slot-scope="scope">
                    <el-switch
                        v-model="scope.data.row.status"
                        inactive-value="1"
                        active-value="0"
                        @change="setStatus(scope, $event)"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" v-if="editStatus" @click="toConfig(scope, 'task')">编辑</el-button>
                </template>
            </generalTable>
            <el-divider />
            <div class="mdrcc-info-title">常用功能</div>
            <generalTable
                ref="commonUseTable"
                :table-data="commonUseData"
                :config="commonUseConfig"
                :newHeight="'200'"
                :total="0"
                @updatePage="getAllWorkbench"
            >
                <template slot="status" slot-scope="scope">
                    <el-switch
                        v-model="scope.data.row.status"
                        inactive-value="1"
                        active-value="0"
                        @change="setStatus(scope, $event)"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" v-if="editStatus" @click="toConfig(scope, 'commonUse')">编辑</el-button>
                </template>
            </generalTable>
        </el-main>
        <editDialog
            v-if="dialogVisible"
            :dialog-title="'编辑'"
            :dialog-visible="dialogVisible"
            @onSubmit="onSubmit"
            @cancelSubmit="onCancel"
        />
        <workbrenchConfig
            :detailData="checkObj"
            :timeStamp="timeStamp"
            :typeName="typeName"
            ref="config"
            @submit="getAllWorkbench"
        />
        <assessmentConfig :timeStamp="timeStamp" ref="assConfig" @submit="getPatientPackage" />
    </el-container>
</template>

<script>
import editDialog from '@/views/system/hospital/dialog'
import workbrenchConfig from '@/views/system/hospital/workbrenchConfig'
import assessmentConfig from '@/views/system/hospital/assessmentConfig'
import generalTable from '@/components/Table'
import { uploadFiles } from '@/api/common'
import { getAllWorkbench } from '@/api/home'
import { getPatientPackage } from '@/api/common'
import { updateAllWorkbenchConfig } from '@/api/hospital'

export default {
    name: 'Hospital',
    components: {
        editDialog,
        generalTable,
        workbrenchConfig,
        assessmentConfig
    },
    data() {
        return {
            show: {
                hospitalName: '', //医院名称
                systemName: '', //系统名称
                hospitalPrincipal: '', //负责人
                hospitalPhone: '', //医院电话
                hospitalFax: '', //医院传真
                hospitalEmail: '', //医院邮箱
                hospitalAddress: '', //医院地址
                hospitalRemark: '', //医院备注
                imageUrl: '', //
                headPortrait: '', //
                avatar: '', //
                logoAvatar: '', //logo地址
                id: '', //ID
                workingHours: '', //上班时间
                rushHour: '', //下班时间
                startLunchBreak: '', //午休开始时间
                endLunchBreak: '', //午休结束时间
                workInterval: '' //治疗工作时间间隔
            },
            dialogVisible: false,
            editStatus: true, // 修改按钮权限
            statisticsData: [], // 日常统计
            taskData: [], //待办任务
            commonUseData: [], // 常用功能
            statisticsConfig: {
                id: 'statistics',
                index: true,
                pagination: false,
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        slot: 'status',
                        label: '显示状态', // 必填
                    },
                    {
                        label: '排序',
                        prop: 'sort',
                        width: '80'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            taskConfig: {
                id: 'task',
                index: true,
                pagination: false,
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                     {
                        slot: 'status',
                        label: '显示状态', // 必填
                    },
                    {
                        label: '排序',
                        prop: 'sort',
                        width: '80'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            commonUseConfig: {
                id: 'commonUse',
                index: true,
                pagination: false,
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                     {
                        slot: 'status',
                        label: '显示状态', // 必填
                    },
                    {
                        label: '排序',
                        prop: 'sort',
                        width: '80'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            assessmentConfig: {
                id: 'assessment',
                index: true,
                pagination: false,
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '值', // 必填
                        prop: 'keyField' // 必填
                    }
                ]
            },
            typeName: 'statistics',
            checkObj: {},
            timeStamp: '', //工作台时间戳
            assessmentList: {
                all: [],
                choose: []
            } //评估基本信息数据
        }
    },
    watch: {},
    created() {
        this.editStatus = this.hasPermission('hos-edit')
        this.getPatientPackage()
    },
    mounted: function() {
        this.getHospitalData()
        this.getAllWorkbench()
    },
    methods: {
        //获取医院信息接口
        getHospitalData() {
            this.$store.dispatch('getHosInfo').then(res => {
                console.log('------getHosInfo------', res)
                //不是api,res不需要加大括号
                const {
                    appDownloadAddress,
                    hospitalAddress,
                    hospitalEmail,
                    hospitalFax,
                    hospitalName,
                    hospitalPhone,
                    hospitalPrincipal,
                    hospitalRemark,
                    systemName,
                    id,
                    logo,
                    workingHours,
                    rushHour,
                    startLunchBreak,
                    endLunchBreak,
                    scaleShowType,
                    workInterval
                } = res
                this.show = {
                    ...this.show,
                    scaleShowType: scaleShowType === "1" ? "问卷" : "模板",
                    hospitalAddress,
                    hospitalEmail,
                    hospitalFax,
                    hospitalName,
                    hospitalPhone,
                    hospitalPrincipal,
                    hospitalRemark,
                    systemName,
                    appDownloadAddress,
                    logo,
                    workingHours,
                    rushHour,
                    startLunchBreak,
                    endLunchBreak,
                    workInterval
                }
                if (appDownloadAddress) {
                    this.show.avatar = this.commonConfig.$fileUrl + appDownloadAddress
                }
                if (logo) {
                    this.show.logoAvatar = this.commonConfig.$fileUrl + logo
                }
            })
        },
        /**
         * @description: 过去默认工作台配置信息
         */
        getAllWorkbench() {
            getAllWorkbench().then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                for (let i = 0; i < res.length; i++) {
                    const ele = res[i]
                    switch (ele.value) {
                        case '0':
                            //日常统计
                            this.statisticsData = ele.list
                            break
                        case '1':
                            //待办任务
                            this.taskData = ele.list
                            break
                        case '2':
                            // 常用功能
                            this.commonUseData = ele.list
                            break
                        default:
                            break
                    }
                }
            })
        },
        /**
         * @description: 获取全局评估基础信息数据
         */

        getPatientPackage() {
            getPatientPackage(0).then(res => {
                const {all, choose} = res.res
                this.assessmentList = {
                    all,
                    choose: choose.sort((a,b) => a.sort - b.sort)
                }
            })
        },
        toAssConfig() {
            this.$refs.assConfig.updateVisible(true)
        },
        //编辑医院信息
        editHosp() {
            this.dialogVisible = true
        },
        //返回按钮
        back() {
            this.$router.back()
        },
        onSubmit(form) {
            this.dialogVisible = false
            const formData = new FormData()
            const formArray = [] // 放置文件数组
            const resArray = [] // 放置文件索引
            if (form.file) {
                // 如果app下载图片进行了上传
                // 传入app图片的文件 获取的index为0
                formArray.push(form.file)
                resArray.push(0)
            }
            if (form.logoFile) {
                // 如果logo图片进行了上传
                // 传入logo图片的文件 获取的index为1
                formArray.push(form.logoFile)
                resArray.push(1)
            }
            // 遍历formArray，将文件插入到formData中
            for (const i of formArray) {
                formData.append('files', i, i.name)
            }
            const {
                appDownloadAddress,
                hospitalAddress,
                hospitalEmail,
                hospitalFax,
                hospitalName,
                hospitalPhone,
                hospitalPrincipal,
                hospitalRemark,
                systemName,
                defaultPassword,
                workingHours,
                rushHour,
                startLunchBreak,
                endLunchBreak,
                workInterval,
                scaleShowType
            } = form

            const params = {
                appDownloadAddress,
                hospitalAddress,
                hospitalEmail,
                hospitalFax,
                hospitalName,
                hospitalPhone,
                hospitalPrincipal,
                hospitalRemark,
                systemName,
                workingHours,
                rushHour,
                startLunchBreak,
                endLunchBreak,
                workInterval,
                scaleShowType
            }
            console.log('------onSubmit------', params)
            if (form.changePwd === '1') {
                params.defaultPassword = defaultPassword
            }
            if (formArray.length) {
                // 有文件上传时，先走文件上传接口，再走医院信息更新接口
                uploadFiles(formData).then(({ res }) => {
                    if (resArray.includes(0)) {
                        // 如果resArray中包含0，则app图片id从res[0]中取出来
                        params.appDownloadAddress = res[0]
                        if (resArray.includes(1)) {
                            // 如果resArray中包含0且包含1，则logo图片id从res[1]中取出来
                            params.logo = res[1]
                        }
                    } else {
                        // 如果resArray中不包含0，则logo图片id从res[0]中取出来
                        params.logo = res[0]
                    }
                    // 根据上传图片的返回值确定参数，更新医院信息
                    this.$store.dispatch('saveHosInfo', params).then(({ res }) => {
                        this.getHospitalData()
                    })
                })
            } else {
                // 没有文件上传时，直接走医院信息更新接口
                // 根据上传图片的返回值确定参数，更新医院信息
                this.$store.dispatch('saveHosInfo', params).then(({ res }) => {
                    this.getHospitalData()
                })
            }
        },
        //修改停用、启用状态
        setStatus(scope,event){
            const param = {
                id:scope.data.row.id,
                sort:scope.data.row.sort,
                status:event
            }
            updateAllWorkbenchConfig(param, this.timeStamp).then(({ res }) => {
                 this.$message({ ...this.commonConfig.$handleSuccess })
                 this.getAllWorkbench();
            })
        },
        toConfig(scope, typeName) {
            this.typeName = typeName
            this.checkObj = scope.data.row
            this.$refs.config.updateVisible(true)
        },
        onCancel() {
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
.labelText {
    font-size: 20px;
    margin-bottom: 10px;
    align-items: flex-start;
}
.contentText {
    font-size: 20px;
}
.menu-item-img {
    width: 160px;
    height: 160px;
}
.table-btn-group {
    justify-content: flex-end;
}
</style>
