var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[(_vm.detailFlag)?_c('el-dialog',{attrs:{"width":"60%","title":"角色详情","visible":_vm.detailFlag,"close-on-click-modal":false,"close-on-press-escape":false,"show-close":false},on:{"update:visible":function($event){_vm.detailFlag=$event},"close":function($event){return _vm.clearTree()}}},[_c('el-descriptions',{staticClass:"margin-top",attrs:{"title":"基本信息","column":2}},[_c('el-descriptions-item',{attrs:{"label":"角色名称"}},[_vm._v(_vm._s(_vm.detailData.name || _vm.commonConfig.$nullData))]),_c('el-descriptions-item',{attrs:{"label":"角色编码"}},[_vm._v(_vm._s(_vm.detailData.roleEncoding || _vm.commonConfig.$nullData))]),_c('el-descriptions-item',{attrs:{"label":"角色类型"}},[_vm._v(_vm._s(_vm.permissionTypeList[_vm.detailData.permissionType] || _vm.commonConfig.$nullData))]),_c('el-descriptions-item',{attrs:{"label":"角色描述"}},[_vm._v(_vm._s(_vm.detailData.remark || _vm.commonConfig.$nullData))])],1),_c('div',{staticClass:"mdrcc-info-title"},[_vm._v("权限设置")]),_c('el-tabs',{attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"lazy":true,"label":"PC"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-tree',{ref:"tree",attrs:{"data":_vm.menuData,"props":{
                                children: 'children',
                                hasChildren: 'hasChildren',
                                label: 'name',
                                disabled:function () { return true; }
                            },"show-checkbox":"","default-expand-all":"","check-on-click-node":false,"expand-on-click-node":false,"node-key":"id","default-checked-keys":_vm.oldCheckedMenu}})],1)],1)],1),_c('el-tab-pane',{attrs:{"lazy":true,"label":"APP"}}),_c('el-tab-pane',{attrs:{"lazy":true,"label":"数据权限"}},[_c('el-row',[_c('el-col',{staticStyle:{"background-color":"#ffffff","margin-top":"10px"},attrs:{"span":24}},[_c('el-radio-group',{model:{value:(_vm.allFlag),callback:function ($$v) {_vm.allFlag=$$v},expression:"allFlag"}},[_c('el-radio',{attrs:{"disabled":"","label":"0"}},[_vm._v("全部数据")]),_c('el-radio',{attrs:{"disabled":"","label":"1"}},[_vm._v("自定义")])],1)],1),(_vm.allFlag == '1')?_c('el-col',{staticStyle:{"background-color":"#ffffff"},attrs:{"span":24}},[_c('el-checkbox',{attrs:{"disabled":""},model:{value:(_vm.self),callback:function ($$v) {_vm.self=$$v},expression:"self"}},[_vm._v("个人数据")])],1):_vm._e(),(_vm.allFlag == '1')?_c('el-col',{staticStyle:{"background-color":"#ffffff"},attrs:{"span":24}},[_c('el-checkbox',{attrs:{"disabled":""},model:{value:(_vm.dept),callback:function ($$v) {_vm.dept=$$v},expression:"dept"}},[_vm._v("本部门/科室数据")])],1):_vm._e(),(_vm.allFlag == '1')?_c('el-col',{attrs:{"span":24}},[_c('el-tree',{ref:"dataTree",staticStyle:{"height":"250px","overflow-y":"scroll"},attrs:{"disabled":"","data":_vm.orgTableData,"props":{
                                children: 'children',
                                hasChildren: 'hasChildren',
                                label: 'name',
                                disabled:function () { return true; }
                            },"show-checkbox":"","default-expand-all":"","expand-on-click-node":false,"node-key":"id","check-on-click-node":"","default-checked-keys":_vm.oldCheckedOrg}})],1):_vm._e()],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.changedetailFlag}},[_vm._v("取 消")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }