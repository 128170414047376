<template>
  <el-dialog
    width="70%"
    :title="'高级查询条件设置'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row>
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="查询命名:" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="32"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <group v-bind:conditions="conditions" :groupIndex="0" :options="options"></group>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail, getOptions } from '@/api/scientificData'
import group from '@/views/scientific/scientificData/group'

export default {
  components: {
    group
  },
  props: {
    id: {
      type: String
    },
    timeStamp: {
      type: String
    }
  },
  data() {
    return {
      checkId: '',
      indexs: 1000,
      form: {
        id: '',
        name: '',
        checkbox: ''
      },
      conditions: {
        id: 0,
        operator: '',
        negated: 'Y',
        group: 'group',
        conditions: []
      },
      total: 0,
      visible: false,
      options: [],
      rules: {
        name: [
          { required: true, message: '请输入查询命名', trigger: 'blur' },
          { min: 1, max: 32, message: '长度在 2 到 32 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail()
        }
        //...todo
      }
    }
  },
  created() {
    this.getOptions()
  },
  mounted() {
  },
  methods: {
    getOptions() {
      this.$nextTick(() => {
        getOptions().then(({ res, timeStamp }) => {
          this.options = res
        })
      })
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = {
            id: this.form.id,
            name: this.form.name,
            paramJson: JSON.stringify(this.conditions)
          }
          if (this.id === '') {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res)
            })
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res)
            })
          }
        }
      })
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess })
      this.updateVisible(false)
      this.$emit('submit')
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res
        this.conditions = JSON.parse(res.paramJson)
      })
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id }
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess })
        })
      })
    },
    updateVisible(val) {
      this.visible = val
      if (val === false) {
        this.$refs.insertForm.resetFields()
        this.form.id = ''
        this.conditions = {
          id: 0,
          operator: '',
          negated: 'Y',
          group: 'group',
          conditions: []
        }
      }
    }
  }
}
</script>
<style scoped lang='scss'>
</style>

