<!--
 * @Author: sixteen
 * @Date: 2021-12-21 16:10:49
 * @LastEditTime: 2022-01-04 10:58:17
 * @LastEditors: Please set LastEditors
 * @Description: 上传报告
 * @FilePath: /recovery_management/src/views/scale/patientAssessment/uploadReport.vue
-->


<template>
    <el-dialog
        width="60%"
        title="上传报告"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item label="评定分类" prop="scaleReportTypeId">
                        <el-cascader
                            v-model="form.scaleReportTypeId"
                            :options="reportList"
                            :show-all-levels="false"
                            placeholder="请选择"
                            collapse-tags
                            :props="{
                                checkStrictly: true,
                                label: 'label',
                                value: 'id',
                                emitPath: false,
                            }"
                            clearable
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="评定日期" prop="evaluateDate">
                        <el-date-picker
                            v-model="form.evaluateDate"
                            align="right"
                            type="date"
                            clear-icon="el-input__icon"
                            placeholder="选择日期"
                            :editable="true"
                            format="yyyy-MM-dd"
                            value-format="timestamp"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="报告名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            maxlength="20"
                            clearable
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="评定报告" prop="fileId">
                        <input
                            style="display: none"
                            type="file"
                            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-powerpoint,application/msword,image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            @change="changeModelFile($event)"
                            ref="ModelInput"
                        />
                        {{ file.name }}
                        <el-button type="primary" @click="setModelInput()">
                            添加文件
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertReport } from "@/api/patientAssessment";
import { uploadFile } from "@/api/common";
import moment from "moment";

export default {
    props: {
        reportList: {
            type: Array,
            required: true,
        },
        basicInfoId: {
            type: String,
        },
        scaleReportTypeId: {
            type: String,
        },
    },
    data() {
        return {
            checkId: "",
            tableData: [],
            evaluateOptions: [], //评定分类数据
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: "", //时间戳 用于修改数据或删除数据
            rules: {
                scaleReportTypeId: [
                    {
                        required: true,
                        message: "请选择评定分类类型",
                        trigger: "change",
                        // type: "change",
                    },
                ],
                evaluateDate: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入报告名称",
                        trigger: "blur",
                    },
                ],
                fileId: [
                    {
                        required: true,
                        message: "请上传文件",
                        trigger: "blur",
                    },
                ],
            },
            form: {
                name: "",
                evaluateDate: "",
                scaleReportTypeId: "",
                basicInfoId: "",
                fileId: "",
            },
            file: {},
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.form = {
                    ...this.form,
                    basicInfoId: this.basicInfoId,
                    evaluateDate: moment().valueOf(),
                    scaleReportTypeId: this.scaleReportTypeId,
                };
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * @description: 点击上传
         * @param {*}
         * @return {*}
         */
        setModelInput() {
            this.$refs.ModelInput.click();
        },
        /**
         * @description: 读取模板文件
         */
        changeModelFile(event) {
            let file = event.target.files[0];
            let fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
            let fileNameLength = file.name.length; //取到文件名长度
            let fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
            const { $docTypes, $imgTypes } = this.commonConfig;   //需要的格式
            if (
                !($docTypes.includes(fileFormat.toLowerCase()) ||
                $imgTypes.includes(fileFormat.toLowerCase()))
            ) {
                
                this.$message("请选择正常的文件");
                return;
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let formData = new FormData();
            formData.append("file", file, file.name);
            uploadFile(formData).then(({ res }) => {
                this.file = file;
                this.form.fileId = res;
            });
        },

        /**
         * @description: 确定
         * @param {*}
         * @return {*}
         */
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = { ...this.form };
                    insertReport(parm).then(({ res }) => {
                        this.submitSuccess(res);
                    });
                }
            });
        },

        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.$emit("refresh");
            this.updateVisible(false);
        },
        /**
         * @description: 关闭弹窗
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.file = {};
            }
        },
    },
};
</script>
<style scoped lang="scss"></style>
