/*
 * @LastEditors: Richardlv
 * @Author: Richardlv
 */


/**
 * 页面: 患者医嘱
 * pageName: advice
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者医嘱列表数据
 */
export function getList(data) {
    return request({
        url: 'patientAdviceInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增患者医嘱数据
 */
export function insertData(data) {
    return request({
        url: 'patientAdviceInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改患者医嘱数据
 */
export function editData(data) {
    return request({
        url: 'patientAdviceInfo/update',
        method: 'POST',
        data,
        showLoading: true
    })
}
            

/**
 * @description: 获取患者医嘱详情数据
 */
export function getDetail(data) {
    return request({
        url: 'patientAdviceInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 启用停用医嘱
 */
 export function ChangeStatus(data,timeStamp) {
    return request({
        url: 'patientAdviceInfo/enabledDisable',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}
            
/**
 * @description: 删除患者医嘱数据
 */
 export function deleteData(data,timeStamp) {
    return request({
        url: 'patientAdviceInfo/delete',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 删除患者医嘱数据
 */
 export function invalidAdvice(data,timeStamp) {
    return request({
        url: '/patientAdviceInfo/invalidAdvice',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 医嘱管理-患者列表
 */
 export function getPatientList(data) {
    return request({
        url: 'patientArchivesInfo/getAdviceList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 医嘱管理-操作项列表
 */
 export function getRuleOperationList(data) {
    return request({
        url: 'ruleOperation/getNoPageList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 医嘱管理-组套列表
 */
 export function getUserSetList(data) {
    return request({
        url: 'ruleSet/getUserSetList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 医嘱管理-新增医嘱
 */
 export function addPatientAdviceInfo(data) {
    return request({
        url: 'patientAdviceInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

        