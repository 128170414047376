<!--
 * @Author: haojw
 * @Date: 2021-11-24 09:27:09
 * @LastEditTime: 2022-02-07 14:01:06
 * @LastEditors: yzw
 * @Description: 量表分类新增编辑页
 * @FilePath: \recovery_management\src\views\scale\scaleType\addDialog.vue
-->
<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增量表分类' : '修改量表分类'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="分类名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from "@/api/scaleType";
export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      tableData: [],
      tableConfig: {},
      rules: {
        name: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
          {
            min: 0,
            max: 64,
            message: "长度在 0 到 64 个字符",
            trigger: "blur",
          },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      options: [
        {
          value: "0",
          label: "启用",
        },
        {
          value: "1",
          label: "禁用",
        },
      ],
      form: {
        checkbox: [],
      },
      total: 0,
      visible: false,
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = {
            ...this.form,
            id: this.id,
          };
          if (this.id === "") {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
        // this.timeStamp = timeStamp
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
