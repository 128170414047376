import _ from "lodash";
import router from "@/router";
import { login } from "@/api/login";
import { getInfo, getList } from "@/api/userInfo";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { constantRoutes, notFoundRouter, resetRouter } from "@/router";
import { flatten } from "@/utils/common";

const getDefaultState = () => {
  return {
    token: getToken(),
    userName: "",
    trueName: "",
    roles: constantRoutes || [],
    permissions: {},
    unReadMsgList: [],
    menuConfig : [], //用户所有菜单自定义查询配置
  };
};

// 将后台返回的json权限数据格式化（递归遍历子节点）
const filterAsyncRouter = asyncRouterMap => {
  // 遍历后台传来的路由字符串，转换为组件对象
  const filterType = asyncRouterMap.filter(item => item.type !== "2");
//   console.log('filterType', filterType)
  const accessedRouters = filterType.map(route => {
    if (route.status === '1') {
      return null
    }
    if (route.component[0] !== "/" && route.component !== "Layout") {
      return null;
    }
    if (route.name === null || route.name === undefined || route.name === "") {
      return null;
    }
    if (route.path[0] !== "/") {
      return null;
    }
    const menuObj = {};
    menuObj.component = null;
    try {
      menuObj.component = route.component === "Layout" ? require("@/layout").default : require("@/views" + route.component).default;
    } catch (error) {
      console.error('组件路径错误:', error)
      menuObj.component = require("@/views/404").default;
    }
    menuObj.name = _.trimStart(route.path, "/");
    menuObj.path = route.path;
    menuObj.type = route.type;
    menuObj.hidden = route.hidden !== "0";
    menuObj.redirect = route.redirect;
    let meta = {
        title: route.name || '',
        icon: route.icon || 'default',
        keepAlive: route.keepAlive === '0',
        breadcrumb: route.breadcrumb === '0'
    }
    if (route.component === '/scale/patientAssessment/patientScaleApp') {
        meta.content = 'width=device-width, initial-scale=0.5, minimum-scale=0.3, maximum-scale=5.0, user-scalable=yes'
        console.log('------APP scale route', JSON.stringify(route), JSON.stringify(meta))
    }
    menuObj.meta = meta
    if (route.children && route.children.length) {
      menuObj.children = filterAsyncRouter(route.children);
    }
    return menuObj;
  });
  accessedRouters.filter(item => item !== null);
  return accessedRouters;
};

// 将路由中 类型为2(按钮)的过滤出来
const filterPermission = asyncRouterMap => {
  const accessedRouters = asyncRouterMap.map(route => {
    const permissionList = [];
    permissionList.push(route.status === '0' ? route.permission : '');
    if (route.children && route.children.length) {
      const permission = filterPermission(route.children);
      permissionList.push(route.status === '0' ? permission : "");
    }
    return permissionList;
  });
  return accessedRouters;
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, userName) => {
    state.userName = userName;
  },
  SET_TRUENAME: (state, trueName) => {
    state.trueName = trueName;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles; // 菜单显示,404最后加入
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_UNREADMSGLIST: (state, unReadMsgList) => {
    state.unReadMsgList = unReadMsgList;
  },
  SET_MENU_CONFIG: (state, userConfigMenuList) => {
    state.menuConfig = userConfigMenuList
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username, password: password })
        .then(({ res }) => {
          commit("SET_TOKEN", res);
          console.log("------user login", res)
          setToken(res);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      console.log("请求用户信息"); // 不要删除
      getInfo(state.token)
        .then(({ res }) => {
          console.log("请求用户信息", res);
          const { aclTree, trueName, username, userConfigMenuList } = res;

          if (!aclTree || aclTree.length <= 0) {
            reject("没有菜单");
          }
          let menu = filterAsyncRouter(aclTree);
          menu = [...constantRoutes, ...menu, ...notFoundRouter];
          //生成 资源权限数据  利用路由递归 最终生成一维数组
          let permissions = filterPermission(aclTree);
          permissions = flatten(permissions);
          permissions = Array.from(new Set(permissions));
          permissions = permissions.filter(
            item => item !== "" && item !== null && item !== undefined
          );
          router.addRoutes(menu);

          resolve(menu);
          commit("SET_ROLES", menu);
          commit("SET_PERMISSIONS", permissions);
          commit("SET_MENU_CONFIG",userConfigMenuList)
          commit("SET_USERNAME", username);
          commit("SET_TRUENAME", trueName);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //获取用户未读消息
  getUnReadMsgList({ commit, state }) {
    return new Promise(resolve => {
      getList().then(({ res, timeStamp }) => {
        commit("SET_UNREADMSGLIST", res.records);
        resolve();
      });
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise(resolve => {
      removeToken(); // must remove  token  first
      resetRouter();
      commit("RESET_STATE");
      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};

export default {
  userNamespaced: true,
  state,
  mutations,
  actions
};
