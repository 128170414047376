/*
 * @Author: your name
 * @Date: 2021-12-20 11:01:27
 * @LastEditTime: 2022-02-21 17:17:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\patientAssessment.js
 */

/**
 * 页面: 患者评定
 * pageName: patientAssessment
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者评定列表数据
 */
export function getList(data) {
  return request({
    url: 'scalePatient/patientList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 获取导航数据
 */
export function scaleNavigation(data) {
  return request({
    url: 'scalePatient/scaleNavigation',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 新增患者评定数据
 */
export function insertData(data) {
  return request({
    url: '',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 修改患者评定数据
 */
export function editData(data, timeStamp) {
  return request({
    url: '',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}


/**
 * @description: 获取患者评定详情数据
 */
export function getDetail(data) {
  return request({
    url: '',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 删除患者评定数据
 */
export function deleteData(data, timeStamp) {
  return request({
    url: '',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/***********************************评估报告***************************************/

/**
 * @description: 获取评估报告树数据
 */
export function getScaleReportTree(data) {
  return request({
    url: '/scaleReport/typeTree',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 根据类型查询报告列表
 */
export function getScaleReportList(data) {
  return request({
    url: '/scaleReport/listByType',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 根据类型查询量表列表
 */
 export function getScaleListByType(data) {
  return request({
    url: '/scaleReport/scaleListByType',
    method: 'GET',
    params: data,
    showLoading: true
  })
}
/**
 * @description: 查询报告详情
 */
export function getScaleReportDetail(data) {
  return request({
    url: '/scaleReport/info',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 删除患者评定报告
 */
export function deleteReport(data, timeStamp) {
  return request({
    url: 'scaleReport/delete',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 新增患者评定报告
 */
export function insertReport(data) {
  return request({
    url: '/scaleReport/add',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 医生收藏量表列表
 */
 export function getUserScaleRes(data) {
  return request({
    url: '/userScaleRel/getUserScaleRes',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 删除医生收藏量表列表
 */
 export function deleteUserScaleRel(data,timeStamp) {
  return request({
    url: '/userScaleRel/deleteUserScaleRel',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 医生未收藏量表列表
 */
 export function getUserNotScaleRes(data) {
  return request({
    url: '/userScaleRel/notChoose',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 新增医生收藏量表列表
 */
 export function addUserScaleRel(data) {
  return request({
    url: '/userScaleRel/addUserScaleRel',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 评估历史列表
 */
 export function getScaleList(data) {
  return request({
    url: '/scalePatient/scaleList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 添加患者评估表
 */
export function insertScale(data) {
  return request({
    url: '/scalePatient/add',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 删除患者评估表量表
 */
 export function deleteScale(data, timeStamp) {
  return request({
    url: '/scalePatient/delete',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 修改患者评定
 */
 export function editScaleData(data, timeStamp) {
  return request({
    url: '/scalePatient/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 添加患者方案表
 */
 export function insertProgram(data) {
  return request({
    url: '/scaleProgramPatient/add',
    method: 'POST',
    data,
    showLoading: true
  })
}


/**
 * @description: 删除患者评估方案
 */
 export function deleteProgram(data, timeStamp) {
  return request({
    url: '/scaleProgramPatient/removeById',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 保存评估答案
 */
 export function savePatientAnswer(data, timeStamp) {
  return request({
    url: '/scalePatient/savePatientAnswer',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 量表结论规则
 */
 export function getRule(data) {
  return request({
    url: '/scaleInfo/getRule',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 计算excel公式
 */
 export function calculate(data) {
  return request({
    url: '/scalePatient/calculate',
    method: 'POST',
    data,
    showLoading: true,
  })
}


/**
 * @description: 评估id获取量表题目
 */
 export function getProgramScaleList(data) {
  return request({
    url: '/scaleProgramPatient/getScaleList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 评估提交-保存文件
 */
 export function answerProgram(data, timeStamp) {
  return request({
    url: '/scaleProgramPatient/answerProgram',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/***********************************评估分析***************************************/
/**
 * @description: 获取评估分析树数据
 */
 export function getAnalysisTree(data) {
  return request({
    url: '/analysisThe/treeList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description:根据树获取量表
 */
 export function getAnalysisList(data) {
  return request({
    url: '/analysisThe/scaleList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 趋势分析
 */
 export function getTrendAnalysis(data) {
  return request({
    url: '/analysisThe/trendAnalysis',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 影像资料
 */
 export function getAnalysisFile(data) {
  return request({
    url: '/analysisThe/getScaleFileList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}


/**
 * @description: 更新患者评估提示标识
 */
export function updateNoticeStatus(data, timeStamp) {
  return request({
    url:  '/scalePatient/updateNoticeStatus',
    method: 'POSt',
    data,
    showLoading: true,
    timeStamp
  })
}
