
/**
 * 页面: 模板管理
 * pageName: tempManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取模板管理列表数据
 */
export function getList(data) {
    return request({
        url: 'tempManage/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 禁用启用状态
 * @param {Object} data
 */
export function setStatus(data,timeStamp) {
    return request({
        url: 'tempManage/setStatus',
        method: 'POST',
        data,
        timeStamp
    })
} 
/**
 * @description: 检查信息中的检查项目
 */
export function getInspection(data) {
    return request({
        url: 'tempManage/getInspection',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 治疗项目中的治疗项目
 */
export function getOper(data) {
    return request({
        url: 'tempManage/getOper',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 校验模板名称是否重复
 */
export function messageName(data) {
    return request({
        url: 'tempManage/messageName',
        method: 'POST',
        data,
        showLoading: true
    })
}
/**
 * @description: 评估量表中的量表信息
 */
export function getScale(data) {
    return request({
        url: 'tempManage/getScale',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 新增模板管理数据
 */
export function insertData(data) {
    return request({
        url: 'tempManage/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改模板管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'tempManage/editData',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取模板管理详情数据
 */
export function getDetail(data) {
    return request({
        url: 'tempManage/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除模板管理数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: 'tempManage/delete',
        method: 'POST',
        data: data,
        showLoading: true,
        timeStamp
    })
}
/**
 * @description:  校验科研模板 点击编辑按钮弹出相关提示不允许编辑（在任务管理里被使用）
 */
export function notEditTask(data, timeStamp) {
    return request({
        url: 'tempManage/notEditTask',
        method: 'POST',
        data: data,
        showLoading: true,
        timeStamp
    })
}


        