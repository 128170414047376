
<!--
* @Description: 公共量表库页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"
        ><el-button type="primary" v-if="basicScaleInsert" @click="toInsert()"
          >新增</el-button
        ></el-row
      >
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="handle" slot-scope="scope">
          <el-button
            type="text"
            v-if="basicScaleDetail"
            @click="toDetail(scope)"
            >预览</el-button
          >
          <el-button type="text" v-if="basicScaleEdit" @click="toEdit(scope)"
            >编辑</el-button
          >
          <el-button type="text" v-if="basicScaleCopy" @click="toCopy(scope)"
            >复制</el-button
          >
          <el-button
            type="text"
            v-if="basicScaleIssued"
            @click="toIssued(scope)"
            >下发</el-button
          >
          <el-button
            type="text"
            v-if="basicScaleConfig"
            @click="toConfig(scope)"
          >汇总配置</el-button>
          <el-button
            type="text"
            v-if="basicScaleDel"
            class="delBtn"
            @click="toDel(scope)"
            >删除</el-button
          >
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" libFlag="publicLib" />
      <issuedDialog
        :id="checkId"
        :timeStamp="timeStamp"
        @submit="search"
        libFlag="publicLib"
        ref="issued"
      />
      <copyDialog :id="checkId" ref="copy" @submit="search" />
      <configDialog :id="checkId" ref="config" @submit="search" />
      <addDialog
        :id="checkId"
        ref="insert"
        libFlag="publicLib"
        :timeStamp="timeStamp"
        @submit="search"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getDict } from "@/api/common";
import { getList, deleteData } from "@/api/publicScaleLib";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/scale/publicScaleLib/addDialog";
import copyDialog from "@/views/scale/publicScaleLib/copyDialog";
import issuedDialog from "@/views/scale/publicScaleLib/issuedDialog";
import detailDialog from "@/views/scale/publicScaleLib/detailDialog";
import configDialog from "@/views/scale/publicScaleLib/configDialog";

export default {
  name: "PublicScaleLib",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    issuedDialog,
    copyDialog,
    addDialog,
    configDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入模板名称",
          key: "name",
          label: "模板名称",
          defaultVal: "",
          maxlength:30,
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "basicScaleType",
          filterable: true,
          multiple: false,
          label: "评定分类",
          options: [],
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入版本号",
          key: "versionNo",
          label: "版本",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "status",
          filterable: true,
          multiple: false,
          label: "是否下发",
          options: [],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "publicScaleLib",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "评定分类", // 必填
            prop: "basicScaleTypeLabel", // 必填
          },
          {
            label: "模板名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "版本", // 必填
            prop: "versionNo", // 必填
          },
          {
            label: "是否下发", // 必填
            prop: "statusLabel", // 必填
          },
          {
            label: "创建时间", // 必填
            sortable: false, // 不可排序
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            label: "修改时间", // 必填
            sortable: false, // 不可排序
            prop: "updateDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.updateDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "260", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      basicScaleDetail: true, //详情
      basicScaleEdit: true, //编辑
      basicScaleDel: true, //删除
      basicScaleIssued: true, //下发
      basicScaleInsert: true, //新增
      basicScaleCopy: true, //复制
      basicScaleConfig: true, // 汇总配置
    };
  },
  created() {
    this.getBasicScaleDict();
    this.getList();
    this.basicScaleDetail = this.hasPermission("basic-scale-detail");
    this.basicScaleEdit = this.hasPermission("basic-scale-edit");
    this.basicScaleDel = this.hasPermission("basic-scale-del");
    this.basicScaleIssued = this.hasPermission("basic-scale-issued");
    this.basicScaleInsert = this.hasPermission("basic-scale-insert");
    this.basicScaleCopy = this.hasPermission("basic-scale-copy");
    this.basicScaleConfig = this.hasPermission("basic-scale-config")
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.total = res.total;
          this.tableData = res.records;
          this.timeStamp = timeStamp;
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getList();
        });
      });
    },
    toIssued(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.issued.updateVisible(true);
    },
    toCopy(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.copy.updateVisible(true);
    },
    toConfig(scope){
      this.checkId = scope.data.row.id;
      this.$refs.config.updateVisible(true);
    },
    onClickRow(row) {
      console.log("点击行:", row);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
    getBasicScaleDict() {
      getDict({ type: "basic_scale_type,basic_scale_status" }).then(
        ({ res }) => {
          let typeCache = res.basic_scale_type.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          });
          typeCache = [{ value: "", label: "全部" }].concat(typeCache);
          this.searchOptions[1].options = typeCache;

          let statusCache = res.basic_scale_status.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          });
          statusCache = [{ value: "", label: "全部" }].concat(statusCache);
          this.searchOptions[3].options = statusCache;
        }
      );
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        