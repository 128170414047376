<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="60%"
        title="设备库详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions
            class="margin-top"
            title="设备信息"
            :column="2"
            
        >
            <el-descriptions-item label="设备名称">{{deviceName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="设备编号">{{deviceNumber || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗设备简码">{{deviceCode || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗区">{{consultingRoomLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="负责人">{{userName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{statusLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="备注">{{remark || commonConfig.$nullData}}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from "@/api/medicalCare";
import generalTable from "@/components/Table";
export default {
    components: {
        generalTable,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            userName: "",
            deviceName: "",
            consultingRoomLabel: "",
            deviceNumber: "",
            deviceCode: "",
            statusLabel: "",
            remark: "",
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.userName = res.userName;
                this.deviceName = res.deviceName;
                this.remark = res.remark;
                this.consultingRoomLabel = res.consultingRoomLabel;
                this.deviceNumber = res.deviceNumber;
                this.deviceCode = res.deviceCode;
                this.statusLabel = res.statusLabel;
            });
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
