<template>
    <el-dialog
        width="80%"
        :title="id === '' ? '新增量表' : '修改量表'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-steps :align-center="true" :active="active" finish-status="success">
            <el-step title="量表信息" :description="active == 0 ? '进行中' : '已完成'"></el-step>
            <el-step
                title="配置试题"
                :description="active == 0 ? '未开始' : active == 1 ? '进行中' : '已完成'"
            ></el-step>
            <el-step title="配置结论" :description="active == 2 ? '进行中' : '未开始'"></el-step>
        </el-steps>
        <div></div>
        <el-form
            v-if="this.active == 0"
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="量表名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入..." clearable />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="libFlag === 'publicLib'">
                    <el-form-item label="量表类型" prop="basicScaleType">
                        <el-select v-model="form.basicScaleType" clearable placeholder="请选择" :filterable="true">
                            <el-option
                                v-for="option in scaleTypeOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="libFlag === 'privateLib'">
                    <el-form-item label="量表类型" prop="scaleTypeId">
                        <el-select v-model="form.scaleTypeId" clearable placeholder="请选择" :filterable="true">
                            <el-option
                                v-for="option in scaleTypeOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="满分" prop="fullMarks">
                        <el-input-number v-model="form.fullMarks" :min="0" :max="999" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="版本" prop="versionNo">
                        <el-input v-model="form.versionNo" placeholder="请输入..." clearable maxlength="100" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="查看形式" prop="viewFlag">
                        <el-radio-group v-model="form.viewFlag">
                            <el-radio label="1">量表模板</el-radio>
                            <el-radio label="0">显示模板</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="libFlag === 'privateLib'">
                    <el-form-item label="所属机构" prop="deptIds">
                        <el-cascader
                            size="mini"
                            v-model="form.deptIds"
                            :options="officeTree"
                            :props="{
                                checkStrictly: true,
                                multiple: true,
                                label: 'name',
                                value: 'id'
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="量表模板" prop="scaleMouldPath">
                        <input
                            style="display: none"
                            type="file"
                            accept=".xls,.xlsx"
                            @change="changeModelFile($event)"
                            ref="ModelInput"
                        />
                        <el-button type="primary" @click="setModelInput()">上传</el-button>
                        {{ form.scaleMouldPath }}
                        <el-button type="primary" @click="downLoadModelFile()">下载</el-button>
                    </el-form-item>
                </el-col>
                <el-col v-if="form.viewFlag == 0" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="显示模板" prop="showPath">
                        <input
                            style="display: none"
                            type="file"
                            accept=".xls,.xlsx"
                            @change="changeShowFile($event)"
                            ref="ShowInput"
                        />
                        <el-button type="primary" @click="setShowInput()">上传</el-button>
                        {{ form.showPath }}
                        <el-button type="primary" @click="downLoadShowFile()">下载</el-button>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="使用说明" prop="useInstructions">
                        <el-input
                            type="textarea"
                            :rows="4"
                            v-model="form.useInstructions"
                            placeholder="请输入..."
                            clearable
                            maxlength="200"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row v-if="active == 1" :gutter="10" type="flex" justify="start">
            <el-col :sm="12" :md="12" :lg="8" :xl="8" class="typeBox" style="height:350px;overflow-y: scroll;">
                <el-tree
                    ref="tree"
                    :data="treeData"
                    node-key="id"
                    :props="{
                        label: 'name'
                    }"
                    default-expand-all
                    :expand-on-click-node="false"
                    @node-click="onClickTreeRow"
                >
                    <span
                        class="custom-tree-node"
                        :style="queTypeId == data.id && 'background-color:#f6f6f6'"
                        slot-scope="{ node, data }"
                    >
                        <div class="custom-tree-node-text">{{ data.name }}</div>
                        <span @click.stop>
                            <el-button type="text" size="mini" @click="() => toAddQueType(data)">
                                新增
                            </el-button>
                            <el-button
                                :disabled="node.level == 1"
                                type="text"
                                size="mini"
                                @click="() => toEditQueType(data)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                :disabled="node.level == 1"
                                type="text"
                                size="mini"
                                class="delBtn"
                                @click="() => toDelQueType(node, data)"
                            >
                                删除
                            </el-button>
                        </span>
                    </span>
                </el-tree>
            </el-col>
            <el-col :sm="12" :md="12" :lg="16" :xl="16">
                <el-button style="margin-bottom: 10px" type="primary" @click="toAddQue()">新增试题</el-button>
                <generalTable newHeight="300px" ref="queTable" :table-data="queList" :config="queTableConfig" total="0">
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="toEditQue(scope)">编辑</el-button>
                        <el-button type="text" @click="toCopyQue(scope)">复制</el-button>
                        <el-button type="text" class="delBtn" @click="toDelQue(scope)">删除</el-button>
                    </template>
                </generalTable>
            </el-col>
        </el-row>
        <el-form
            v-if="this.active == 2"
            ref="resultsForm"
            :model="resultsForm"
            label-position="right"
            label-width="120px"
            class="formBox"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="评分规则" prop="marksRule">
                        <el-input
                            v-model="resultsForm.marksRule"
                            placeholder="请输入..."
                            clearable
                            type="textarea"
                            :rows="4"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="结论规则" prop="resultsRule">
                        <el-input
                            v-model="resultsForm.resultsRule"
                            placeholder="请输入..."
                            clearable
                            type="textarea"
                            :rows="4"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="active == 0" type="primary" @click="submit()">下一项</el-button>
            <el-button v-if="active == 1" type="primary" @click="toResults()">下一项</el-button>
            <el-button v-if="active == 2" type="primary" @click="submitResults()">提 交</el-button>
            <el-button @click="updateVisible(false)">{{ active == 0 ? '取 消' : '关 闭' }}</el-button>
        </span>
        <addQueType ref="addQueType" @submit="submitType" :typeDetail="typeDetail" :treeData="treeData"></addQueType>
        <addQue
            ref="addQue"
            @submit="submitQueSuccess"
            :queDetail="queDetail"
            :treeData="treeData"
            :libFlag="libFlag"
            :addqueTypeId="addqueTypeId"
            :scaleInfoId="scaleId"
            :QueId="QueId"
            :QueDetailId="QueDetailId"
        ></addQue>
    </el-dialog>
</template>
<script>
import {
    insertData,
    editData,
    getDetail,
    getScaleType,
    addScaleType,
    updateScaleType,
    delScaleType,
    getBasicScaleQuestion,
    delBasicScaleQuestion,
    downloadScaleFile,
    getBlobFileType,
    submitResults,
    getScaleRule
} from '@/api/publicScaleLib'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'
import addQueType from '@/views/scale/publicScaleLib/addQueType'
import addQue from '@/views/scale/publicScaleLib/addQue'
import { getDeptTree } from '@/api/organization'
import {
    insertDataPrivate,
    updateDataPrivate,
    getDetailPrivate,
    getScaleTypeTreeList,
    addScaleTypePrivate,
    updateScaleTypePrivate,
    delScaleTypePrivate,
    getScaleQuestion,
    delScaleQuestion,
    getTypeScale,
    getPrivateScaleRule,
    addScaleConclusion
} from '@/api/privateScaleLib'

export default {
    props: {
        id: {
            type: String
        },
        libFlag: {
            type: String
        }
    },
    components: {
        generalTable,
        addQueType,
        addQue
    },
    data() {
        return {
            scaleId: '',
            officeTree: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入量表名称',
                        trigger: 'blur'
                    }
                ],
                versionNo: [
                    {
                        required: true,
                        message: '请输入版本号',
                        trigger: 'blur'
                    }
                ],
                fullMarks: [
                    {
                        required: true,
                        message: '请输入总分',
                        trigger: 'blur'
                    }
                ],
                deptIds: [
                    {
                        type: 'array',
                        required: true,
                        message: '请至少选择一个机构',
                        trigger: 'change'
                    }
                ],
                basicScaleType: [
                    {
                        required: true,
                        message: '请选择量表类型',
                        trigger: 'blur'
                    }
                ],
                scaleTypeId: [
                    {
                        required: true,
                        message: '请选择量表类型',
                        trigger: 'blur'
                    }
                ],
                scaleMouldPath: [
                    {
                        required: true,
                        message: '请选择模板文件',
                        trigger: 'change'
                    }
                ]
            },
            scaleTypeOptions: [],
            form: {
                name: '', //量表名称
                basicScaleType: '', //量表类型
                showForm: '', //展现形式
                viewFlag: '1', //查看形式
                fullMarks: '', //满分
                versionNo: '', //版本
                mouldPath: '', //模板后台文件名id
                scaleMouldPath: '', //模板文件名
                viewMouldPath: '', // 显示模板后台文件名id
                modelFile: {}, //模板文件
                showPath: '', //显示模板文件名
                showFile: {}, //模板文件
                useInstructions: '', //使用说明
                scaleTypeId: '' //量表类型(评估量表)
            },
            visible: false,
            newFlieFlag: false, //是否上传了新模板
            newShowFlieFlag: false, //是否上传了新模板
            active: 0, //步骤条
            timeStamp: '', //时间戳 用于修改数据或删除数据
            treeData: [],
            typeDetail: {},
            queList: [],
            queTableConfig: {
                id: 'publicScaleLib',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: '类别', // 必填
                        prop: 'answerTypeLabel' // 必填
                    },
                    {
                        label: '试题标题', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '标识', // 必填
                        prop: 'answerCoordinate' // 必填
                    },
                    {
                        label: '排序', // 必填
                        prop: 'sort' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            },
            queDetail: {},
            queTypeId: '',
            queTypeObj: {},
            addqueTypeId: [],
            QueId: '',
            QueDetailId: '',
            resultsForm: {
                marksRule: '',
                resultsRule: ''
            }
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                console.log('------this.libFlag------', this.libFlag, this.id)
                this.$store.dispatch('getHosInfo').then(res => {
                    this.form = {
                        ...this.form,
                        showForm: res.scaleShowType
                    }
                })
                if (this.id) {
                    if (this.libFlag === 'publicLib') {
                        this.getDetail()
                        this.scaleId = this.id
                    } else {
                        this.getDetailPrivate()
                        this.scaleId = this.id
                    }
                }
                //...todo
            }
        }
    },
    created() {
        if (this.libFlag === 'privateLib') {
            this.getTypeScale()
            this.loadTree()
        } else {
            this.get_basic_scale_type()
        }
    },
    mounted() {},
    methods: {
        /**
         * @description:提交结论
         * @param {*}
         * @return {*}
         */
        getScaleRule() {
            const params = {
                id: this.scaleId
            }
            getScaleRule(params).then(({ res, timeStamp }) => {
                console.log('------getScaleRule success------', res)
                this.resultsForm = {
                    marksRule: res.marksRule,
                    resultsRule: res.resultsRule
                }
            })
        },
        getPrivateScaleRule() {
            const params = {
                id: this.scaleId
            }
            console.log('------getPrivateScaleRule params------', params)
            getPrivateScaleRule(params).then(({ res, timeStamp }) => {
                console.log('------getPrivateScaleRule success------', res)
                this.resultsForm = {
                    marksRule: res.marksRule,
                    resultsRule: res.resultsRule
                }
            })
        },
        /**
         * @description:提交结论
         * @param {*}
         * @return {*}
         */
        submitResults() {
            const params = {
                id: this.scaleId,
                marksRule: this.resultsForm.marksRule,
                resultsRule: this.resultsForm.resultsRule
            }
            console.log('------submitResults------', this.libFlag, params)
            if (this.libFlag === 'publicLib') {
                submitResults(params).then(({ res, timeStamp }) => {
                    const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
                    this.$message({ ...$handleSuccess })
                    this.updateVisible(false)
                })
            } else {
                addScaleConclusion(params).then(({ res, timeStamp }) => {
                    const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
                    this.$message({ ...$handleSuccess })
                    this.updateVisible(false)
                })
            }
        },
        /**
         * @description:跳转提交结论
         * @param {*}
         * @return {*}
         */
        toResults() {
            console.log('------this.libFlag------', this.libFlag)
            this.active = 2
            if (this.libFlag === 'publicLib') {
                this.getScaleRule()
            } else {
                this.getPrivateScaleRule()
            }
        },
        /**
         * @description:获取评定类型(评估量表库)
         * @param {*}
         * @return {*}
         */
        getTypeScale() {
            const option = []
            getTypeScale().then(({ res, timeStamp }) => {
                res.records.forEach(element => {
                    const ele = {
                        value: element.id,
                        label: element.name
                    }
                    option.push(ele)
                })
                this.scaleTypeOptions = option
            })
        },
        /**
         * @description:获取所属机构(评估量表库)
         * @param {*}
         * @return {*}
         */
        loadTree() {
            getDeptTree().then(({ res }) => {
                this.officeTree = res
            })
        },
        /**
         * @description:获取量表类型树(评估量表库)
         * @param {*}
         * @return {*}
         */
        getScaleTypeTreeList() {
            getScaleTypeTreeList({ scaleInfoId: this.scaleId }).then(({ res, timeStamp }) => {
                this.treeData = res
                this.timeStamp = timeStamp
                this.getScaleQuestion()
            })
        },
        /**
         * @description:获取量表题目列表(评估量表库)
         * @param {*}
         * @return {*}
         */
        getScaleQuestion() {
            const params = {
                scaleInfoId: this.scaleId,
                scaleQuestionTypeId: this.queTypeId
            }
            getScaleQuestion(params).then(({ res, timeStamp }) => {
                this.queList = res
                this.timeStamp = timeStamp
            })
        },
        /**
         * @description: 获取模板基本信息(评估量表库)
         */
        getDetailPrivate() {
            getDetailPrivate({ id: this.id }).then(({ res, timeStamp }) => {
                console.log('------getDetailPrivate success------', res)
                const { name, versionNo, viewFlag } = res
                this.form = {
                    ...res,
                    showPath: viewFlag === '0' ? `${name}_${versionNo}_显示` : '',
                    scaleMouldPath: `${name}_${versionNo}_模板`,
                    deptIds: res.officeIdList
                        ? res.officeIdList.map((item, index) => {
                              return item.split(',')
                          })
                        : []
                }
                this.timeStamp = timeStamp
            })
        },

        submitQueSuccess() {
            if (this.libFlag === 'privateLib') {
                this.getScaleQuestion()
            } else {
                this.getBasicScaleQuestion()
            }
        },
        toAddQue() {
            this.addqueTypeId = this.queTypeObj.parentIds
                ? (this.queTypeObj.parentIds + this.queTypeId).split(',')
                : this.queTypeId.split(',')
            this.QueDetailId = ''
            this.QueId = ''
            this.$refs.addQue.updateVisible(true)
        },
        toEditQue(scope) {
            this.QueDetailId = scope.data.row.id
            this.QueId = scope.data.row.id
            this.addqueTypeId = []
            this.$refs.addQue.updateVisible(true)
        },
        toCopyQue(scope) {
            this.QueDetailId = scope.data.row.id
            this.QueId = ''
            this.addqueTypeId = []
            this.$refs.addQue.updateVisible(true)
        },
        toDelQue(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                if (this.libFlag === 'publicLib') {
                    delBasicScaleQuestion(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess })
                        this.queTypeId = ''
                        this.getScaleType()
                    })
                } else {
                    /**
                     * @description: 删除量表问题(评估量表库)
                     */
                    delScaleQuestion(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess })
                        this.queTypeId = ''
                        this.getScaleTypeTreeList()
                    })
                }
            })
        },
        toDelQueType(node, data) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: data.id }
                if (this.libFlag === 'publicLib') {
                    delScaleType(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess })
                        this.queTypeId = ''
                        this.getScaleType()
                    })
                } else {
                    /**
                     * @description: 删除模板类型(评估量表库)
                     */
                    delScaleTypePrivate(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess })
                        this.queTypeId = ''
                        this.getScaleTypeTreeList()
                    })
                }
            })
        },
        toEditQueType(data) {
            this.typeDetail = data
            this.$refs.addQueType.updateVisible(true)
        },
        toAddQueType(data) {
            this.typeDetail = { parentId: data.id }
            this.$refs.addQueType.updateVisible(true)
        },
        submitType(data) {
            if (this.libFlag === 'publicLib') {
                if (data.id) {
                    let params = Object.assign({ scaleInfoId: this.scaleId }, data)
                    updateScaleType(params, this.timeStamp).then(({ res }) => {
                        this.getScaleType()
                    })
                } else {
                    let params = Object.assign({ scaleInfoId: this.scaleId }, data)
                    addScaleType(params).then(({ res }) => {
                        this.getScaleType()
                    })
                }
            } else {
                /**
                 * @description:新增修改量表类型(评估量表库)
                 * @param {*}
                 * @return {*}
                 */
                if (data.id) {
                    let params = Object.assign({ scaleInfoId: this.scaleId }, data)
                    updateScaleTypePrivate(params, this.timeStamp).then(({ res }) => {
                        this.getScaleTypeTreeList()
                    })
                } else {
                    let params = Object.assign({ scaleInfoId: this.scaleId }, data)
                    addScaleTypePrivate(params).then(({ res }) => {
                        this.getScaleTypeTreeList()
                    })
                }
            }
        },
        getScaleType() {
            getScaleType({ scaleInfoId: this.scaleId }).then(({ res, timeStamp }) => {
                this.queTypeId = res[0].id
                this.queTypeObj = res[0]
                this.treeData = res
                this.timeStamp = timeStamp
                this.getBasicScaleQuestion()
            })
        },
        /**
         * @description: 点击模板问题分类树
         */
        onClickTreeRow(obj) {
            this.queTypeId = obj.id
            this.queTypeObj = obj
            if (this.libFlag === 'publicLib') {
                this.getBasicScaleQuestion()
            } else {
                /**
                 * @description:根据树分类获取题(评估量表库)
                 * @param {*}
                 * @return {*}
                 */
                this.getScaleQuestion()
            }
        },
        getBasicScaleQuestion() {
            const params = {
                scaleInfoId: this.scaleId,
                scaleQuestionTypeId: this.queTypeId
            }
            getBasicScaleQuestion(params).then(({ res, timeStamp }) => {
                this.queList = res
                this.timeStamp = timeStamp
            })
        },
        /**
         * @description: 获取量表分类字典
         */
        get_basic_scale_type() {
            getDict({ type: 'basic_scale_type' }).then(({ res }) => {
                this.scaleTypeOptions = res.basic_scale_type.map(item => {
                    return {
                        value: item.value,
                        label: item.label
                    }
                })
            })
        },
        /**
         * @description: 下载模板文件
         */
        downLoadShowFile() {
            const { name: scaleName } = this.form
            if (!this.form.viewMouldPath) {
                this.$message.warning("只能下载上提交过的量表文件!")
                return
            }
            getBlobFileType({ id: this.form.viewMouldPath }).then(({ res: bolbTypeRes }) => {
                downloadScaleFile({ id: this.form.viewMouldPath }).then(({ res }) => {
                    const name = `${scaleName}_显示`
                    const dom = document.createElement('a')
                    dom.style.display = 'none'
                    const blob = new Blob([res], {
                        type: bolbTypeRes
                    })
                    dom.setAttribute('download', name)
                    const url = window.URL.createObjectURL(blob)
                    dom.href = url
                    document.body.appendChild(dom)
                    dom.click()
                    URL.revokeObjectURL(dom.href) // 释放URL 对象
                    document.body.removeChild(dom)
                })
            })
        },
        /**
         * @description: 下载模板文件
         */
        downLoadModelFile() {
            const { name: scaleName } = this.form
            if (!this.form.mouldPath) {
                this.$message.warning("只能下载上提交过的量表文件!")
                return
            }
            getBlobFileType({ id: this.form.mouldPath }).then(({ res: bolbTypeRes }) => {
                downloadScaleFile({ id: this.form.mouldPath, name }).then(({ res }) => {
                    const name = `${scaleName}_模板`
                    const dom = document.createElement('a')
                    dom.style.display = 'none'
                    const blob = new Blob([res], {
                        type: bolbTypeRes
                    })
                    dom.setAttribute('download', name)
                    const url = window.URL.createObjectURL(blob)
                    dom.href = url
                    document.body.appendChild(dom)
                    dom.click()
                    URL.revokeObjectURL(dom.href) // 释放URL 对象
                    document.body.removeChild(dom)
                })
            })
        },
        setShowInput() {
            this.$refs.ShowInput.click()
        },
        setModelInput() {
            this.$refs.ModelInput.click()
        },
        /**
         * @description: 读取显示文件
         */
        changeShowFile(event) {
            let file = event.target.files[0]
            console.log(file)
            let formCache = { ...this.form }
            formCache.showFile = file
            formCache.showPath = file.name
            this.form = { ...formCache }
            this.newShowFlieFlag = true
        },
        /**
         * @description: 读取模板文件
         */
        changeModelFile(event) {
            let file = event.target.files[0]
            this.form.modelFile = file
            this.form.scaleMouldPath = file.name
            this.newFlieFlag = true
        },
        /**
         * @description: 提交模板信息 formData方式
         */
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    if (this.form.viewFlag === '0' && !this.form.showPath) {
                        this.$message.warning('请上传显示模板!')
                        return
                    }
                    let formData = new FormData()
                    if (this.newFlieFlag) {
                        formData.append('mouldFile', this.form.modelFile, this.form.scaleMouldPath)
                    }
                    if (this.newShowFlieFlag) {
                        formData.append('showFile', this.form.showFile, this.form.showPath)
                    }
                    formData.append('name', this.form.name || '')
                    formData.append('showForm', this.form.showForm || '')
                    formData.append('viewFlag', this.form.viewFlag || '')
                    formData.append('versionNo', this.form.versionNo || '')
                    formData.append('useInstructions', this.form.useInstructions || '')
                    formData.append('fullMarks', this.form.fullMarks || '')
                    if (this.libFlag === 'publicLib') {
                        formData.append('basicScaleType', this.form.basicScaleType)
                        if (this.id === '') {
                            insertData(formData).then(({ res }) => {
                                this.active = 1
                                this.scaleId = res
                                this.getScaleType()
                            })
                        } else {
                            formData.append('id', this.id)
                            editData(formData, this.timeStamp).then(({ res }) => {
                                this.active = 1
                                this.scaleId = this.id
                                this.getScaleType()
                            })
                        }
                    } else {
                        let arr = []
                        console.log(this.form.deptIds)
                        this.form.deptIds.forEach(ele => {
                            if (ele.length > 0) {
                                arr.push(ele[ele.length - 1])
                            }
                        })
                        /**
                         * @description:新增模板(评估量表库)
                         * @param {*}
                         * @return {*}
                         */
                        formData.append('scaleTypeId', this.form.scaleTypeId)
                        formData.append('deptIds', arr)
                        formData.append('sort', 1)
                        if (this.id === '') {
                            insertDataPrivate(formData).then(({ res }) => {
                                this.active = 1
                                this.scaleId = res
                                this.getScaleTypeTreeList()
                            })
                        } else {
                            formData.append('id', this.id)
                            updateDataPrivate(formData, this.timeStamp).then(({ res }) => {
                                this.active = 1
                                this.scaleId = this.id
                                this.getScaleTypeTreeList()
                            })
                        }
                    }
                }
            })
        },
        /**
         * @description: 获取模板基本信息
         */
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                const { name, versionNo, viewFlag } = res
                this.form = {
                    ...res,
                    showPath: viewFlag === '0' ? `${name}_${versionNo}_显示` : '',
                    scaleMouldPath: `${name}_${versionNo}_模板`
                }
                this.timeStamp = timeStamp
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$emit('submit')
                this.queTypeId = ''
                this.scaleId = ''
                this.form = {
                    name: '', //量表名称
                    basicScaleType: '', //量表类型
                    showForm: '', //展现形式
                    viewFlag: '1', //查看形式
                    fullMarks: '', //满分
                    versionNo: '', //版本
                    mouldPath: '', //模板文件名
                    modelFile: {}, //模板文件
                    scaleMouldPath: '', //模板文件名
                    viewMouldPath: '', // 显示模板后台文件名id
                    showPath: '', //模板文件名
                    showFile: {}, //模板文件
                    useInstructions: '', //使用说明
                    scaleTypeId: '' //量表类型（评估量表）
                }
                this.newFlieFlag = false
                this.newShowFlieFlag = false
                this.treeData = []
                this.queList = []
                this.active = 0
                this.$refs.insertForm && this.$refs.insertForm.resetFields()
                this.$refs.ModelInput.value = ""
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding-right: 8px;
}
.custom-tree-node-text {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.typeBox {
    border: 1px solid #dedede;
    border-radius: 5px;
}
</style>
