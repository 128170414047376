/*
 * @Author: your name
 * @Date: 2022-01-14 08:31:24
 * @LastEditTime: 2022-01-26 13:19:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\task.js
 */

/**
 * 页面: 任务管理
 * pageName: task
 **/
import request from '@/utils/request'

/**
 * @description: 获取任务管理列表数据
 */
export function getList(data) {
    return request({
        url: 'scientificResearchTask/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 生成文件
 */
export function reCreate(data) {
    return request({
        url: 'scientificResearchTask/reCreate',
        method: 'POST',
        data,
        showLoading: true
    })
}

        