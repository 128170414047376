/*
 * @Author: haojw
 * @Date: 2021-11-24 09:27:09
 * @LastEditTime: 2021-12-14 16:45:16
 * @LastEditors: Please set LastEditors
 * @Description: 量表分类
 * @FilePath: \recovery_management\src\api\scaleType.js
 */
import request from "@/utils/request";

/**
 * @description: 获取量表分类列表数据
 */
export function getList(data) {
  return request({
    url: "/scaleType/list",
    method: "GET",
    params: data,
    showLoading: true,
  });
}

/**
 * @description: 禁用/启用量表分类
 * @param {Object} data
 * @return {*}
 */
 export function disableScale(data, timeStamp) {
  return request({
    url: '/scaleType/disableScale',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}  

/**
 * @description: 新增量表类型数据
 */
export function insertData(data) {
  return request({
    url: "/scaleType/add",
    method: "POST",
    data,
    showLoading: true,
  });
}

/**
 * @description: 修改量表分类数据
 */
export function editData(data, timeStamp) {
  return request({
    url: "/scaleType/update",
    method: "POST",
    data,
    showLoading: true,
    timeStamp,
  });
}

/**
 * @description: 获取量表分类详情数据
 */
export function getDetail(data) {
  return request({
    url: "/scaleType/info",
    method: "GET",
    params: data,
    showLoading: true,
  });
}

/**
 * @description: 删除量表分类数据
 */
export function deleteData(data, timeStamp) {
  return request({
    url: "/scaleType/delete",
    method: "POST",
    data,
    showLoading: true,
    timeStamp,
  });
}
