/* eslint-disable no-undef */
/*
 * @Description: 公共方法
 * @Author: yzw
 * @Date: 2021-08-12 16:56:40
 * @LastEditTime: 2022-12-09 13:57:33
 * @LastEditors: ZF-WLY
 * @Reference:
 */
import store from "@/store";
import PinYin from "@/utils/PinYin";
import { getDict } from "@/api/common"
/**
 * @description: 多维数组合并为一维数组
 * @param {Array} arr 数组
 * @return {Array}
 */

export const flatten = arr => {
  return [].concat(...arr.map(x => (Array.isArray(x) ? flatten(x) : x)));
};

export const customQuery = (name = "user", staticCols = []) => {
    const menuConfig = store.state.user.menuConfig;
    const menuObj = menuConfig.filter(item => item.pageName === name);
    const colList = filterTableCol(menuObj[0].columnList)
    const searchList = null
    return {colList,searchList}
}

export const filterTableCol = columnList => {
    let colList = columnList.map(item => {
        for (let i = 0; i < staticCols.length; i++) {
            const ele = staticCols[i];
            item.label = ele.label || null;
            item.slot = ele.slot || null;
            item.prop = item.slot ? null : item.fieldName;
            item.formatter = ele.formatter || null;
            item.children = ele.children || null;
            item.expand = ele.expand || null;
            item.fixed = ele.fixed || null;
            item.width = ele.width || null;
            item.sortable = ele.sortable || null;
        }
        return item;
    });
    return colList
};

/**
 * @description: 验证是否有资源权限
 * @param {String} key 权限标识
 * @return {Boolean}
 */

export const hasPermission = key => {
  const { permissions } = store.state.user;
  return permissions.includes(key);
};

/** 汉字转拼音首字母
 * @description:
 *      替换掉输入框中所有空格
 *      遍历查找每一个字符
 *      如果是汉字，返回对应的英文大写首字母
 *      如果不是汉字，原样返回
 *      最后将数组连接起来，作为字符串返回
 * @param {String} str 传入的字符串
 * @return {String} 根据输入转换的拼音大写首字母
 */
export const CH2CYTPALL = str => {
  if (typeof str != "string") {
    throw new Error(
      -1,
      "\u51fd\u6570makePy\u9700\u8981\u5b57\u7b26\u4e32\u7c7b\u578b\u53c2\u6570!"
    );
  }
  str = str.replace(/\s/g, "");
  let arrResult = []; //保存中间结果的数组
  for (let i = 0, len = str.length; i < len; i++) {
    //获得unicode码
    let ch = str.charAt(i);
    //检查该unicode码是否在处理范围之内,在则返回该码对映汉字的拼音首字母,不在则调用其它函数处理
    // 汉字拼音首字母列表 本列表包含了20902个汉字,用于配合 ToChineseSpell
    //函数使用,本表收录的字符的Unicode编码范围为19968至40869, XDesigner 整理
    const strChineseFirstPY = PinYin;
    const uni = ch.charCodeAt(0);
    ch =
      uni > 40869 || uni < 19968 ? ch : strChineseFirstPY.charAt(uni - 19968);
    arrResult.push(ch);
  }
  arrResult = arrResult.map(item => item[0]);
  //将数组拼接成字符串
  return arrResult.join("");
};

/**
 * @description: 在sessionStorage中设置refreshFlag属性为true以在返回是刷新页面
 */

export const setRefreshFlag = () => {
  sessionStorage.setItem("refreshFlag", "true");
};

/**
 * @description: 返回在sessionStorage中查询的refreshFlag属性,并移除refreshFlag属性
 * @return {String|null}
 */

export const getRefreshFlag = () => {
  const refreshFlag = sessionStorage.getItem("refreshFlag");
  sessionStorage.removeItem("refreshFlag");
  return refreshFlag;
};

export const previewFile = () => {
  window.open("http://cn.createpdfonline.org/pdffiles/ee(20211216114159).pdf");
};


/**
 * 将数值转换成标签 eg:性别 0 1 --> 男 女
 * @param { Sting } dictType 字典类型
 * @param { String } val 字段表的数值
 */
export const valToLabel = (dictType, val) => {
    return new Promise((resolve, reject) => {
        getDict({ type: dictType }).then(({res}) => {
            const list = res[dictType]
            let dictMap = {}
            list.forEach(item => {
                dictMap[item.value] = item.label
            })
            resolve(dictMap[val])
        }).catch((error) => {
            reject(error)
        })
    })
    
}
