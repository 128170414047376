<!--
 * @LastEditors: Richardlv
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="85%"
        title='新增量表/方案'
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" />
        <generalTable
            ref="mainTable"
            :table-data="scaleList"
            :config="tableConfig"
            :newHeight="'300px'"
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>


import { getUserNotScaleRes, addUserScaleRel } from '@/api/patientAssessment'
import { getTypeScale, } from "@/api/privateScaleLib";
import searchForm from '@/components/SearchForm'
import generalTable from "@/components/Table";

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {
        basicInfoId: {
            type: String,
        },
        insertType: {
            type: Number,
        },
    },
    data() {
        return {
            scaleList: [],
            typeList: [],
            selectType: "",
            searchName: "",
            typeOptions: [],
            visible: false,
            timeStamp: "", //时间戳 用于修改数据或删除数据
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入量表名称',
                    key: 'name',
                    label: '量表名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'scaleTypeId',
                    filterable: true,
                    label: '量表分类',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'type',
                    filterable: true,
                    label: '类型',
                    options: [],
                    defaultVal: "0"
                },
            ],
            tableConfig: {
                id: "mainTable",
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: "评定分类", // 必填
                        prop: "typeName", // 必填
                    },
                    {
                        label: "名称", // 必填
                        prop: "name", // 必填
                    },
                ],
            },
            scaleTypeOptions:[]
            
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if(newVal){
                setTimeout(()=>{
                    this.search()
                },100)
            }
        },
    },
    created() {
        this.getTypeScale();
        let addScaleInfo = this.hasPermission('scale-info-add');
        let addScaleProgram = this.hasPermission('scale-program-add');
        let optionsCache = []
        if(addScaleInfo&&addScaleProgram){
            optionsCache=[
                {
                    value:"0",
                    label:"量表",
                },
                {
                    value:"1",
                    label:"方案",
                },
            ]
        }else if(addScaleInfo&&!addScaleProgram){
            optionsCache=[
                {
                    value:"0",
                    label:"量表",
                },
            ]
        }else if(!addScaleInfo&&addScaleProgram){
            optionsCache=[
                {
                    value:"1",
                    label:"方案",
                },
            ]
            this.searchOptions[2].defaultVal = "1"
        }
        this.searchOptions[2].options = optionsCache
    },
    mounted() {},
    methods: {
        search(){
            const param = this.getParam()
            getUserNotScaleRes(param).then(
                ({ res, timeStamp }) => {
                    this.scaleList = res.records
                    this.timeStamp = timeStamp
                }
            );
        },
        /**
         * @description:获取评定类型
         * @param {*}
         * @return {*}
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = {size:-1 , current:1}
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        getTypeScale() {
            getTypeScale().then(({ res, timeStamp }) => {
                this.scaleTypeOptions = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[1].options = this.scaleTypeOptions
            });
        },
        submit() {
            const paramObj = this.$refs.mainSearch.packageData()
            let checkedList = this.$refs.mainTable.getChecked();
            let param = checkedList = checkedList.map((item,index)=>{
                return{
                    type : paramObj.type,
                    scaleId:item.id,
                }
            })
            console.log(param)
            addUserScaleRel(param).then(({ res, timeStamp }) => {
                this.submitSuccess()
            });
        },
        submitSuccess() {
            this.$emit("submit");
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
        },
        
        updateVisible(val) {
            this.visible = val;
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        