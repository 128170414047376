<!--
* @Description: 编辑主诉
* @Reference:
-->

<template>
  <el-dialog
    width="78%"
    :title="'编辑主诉及病史信息'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
    >
      <el-form-item label="主诉" prop="chiefComplaint">
        <el-input
          v-model="form.chiefComplaint"
          rows="3"
          type="textarea"
          placeholder="请输入..."
          clearable
          maxlength="200"
        />
      </el-form-item>
      <el-form-item label="现病史" prop="medicalHistory">
        <el-input
          v-model="form.medicalHistory"
          rows="3"
          type="textarea"
          placeholder="请输入..."
          clearable
          maxlength="200"
        />
      </el-form-item>
      <el-form-item label="既往病史" prop="pastMedicalHistory">
        <el-input
          v-model="form.pastMedicalHistory"
          rows="3"
          type="textarea"
          placeholder="请输入..."
          clearable
          maxlength="200"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          rows="3"
          type="textarea"
          placeholder="请输入..."
          clearable
          maxlength="200"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { updateChiefComplaint } from '@/api/patient'
export default {
    components: {},
    props: {
        detail: Object,
        timeStamp:String,
    },
    data() {
        return {
            total: 0, // 总条数
            goals: '', // 阶段目标
            diseaseDiagnosis: '', // 疾病诊断
            visible: false,
            form: {}
        }
    },
	watch: {
      detail(oldValue, newValue) {
			this.form = {
				chiefComplaint: this.detail.chiefComplaint,
				medicalHistory: this.detail.medicalHistory,
				pastMedicalHistory: this.detail.pastMedicalHistory,
				remark: this.detail.remark
			}
        }
    },
    created() {
    },
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const param = Object.assign({ id: this.detail.basicInfoId }, this.form)
                    if (param.id !== '') {
                        updateChiefComplaint(param, this.timeStamp).then((res) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped>
</style>

