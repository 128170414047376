<!--
 * @Author: your name
 * @Date: 2022-09-07 08:49:14
 * @LastEditTime: 2022-09-07 15:33:37
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\workbook\addDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        title="登记记录"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-descriptions class="margin-top" title="患者信息" :column="4">
            <el-descriptions-item label="姓名">
                {{ commonConfig.$checkNullData(patient.name) }}
            </el-descriptions-item>
            <el-descriptions-item label="住院/就诊编号">
                {{ commonConfig.$checkNullData(patient.hosNum) }}
            </el-descriptions-item>
        </el-descriptions>
        <!-- wait / process / finish / error / success -->
        <!-- process -->
        <div class="mdrcc-info-title">变更记录</div>
        <div style="height: 400px; overflow-y: auto;">
            <el-steps direction="vertical" :active="0" :style='{height: + list.length * 70 + "px"}'>
                <el-step v-for="(item, index) in list" :key="index" 
                    :title="`${item.createBy} ${moment(item.createDate).format('YYYY-MM-DD HH:mm:ss')}`" 
                    :status="index === 0 ? 'finish' : 'process'" 
                    :description="item.updateReason"
                ></el-step>
            </el-steps>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="updateVisible(false)">关 闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import { getChargeView } from '@/api/treatmentWorkbook'
import { getDict } from '@/api/common'
export default {
    name: 'addWorkbook',
    components: {
        generalTable
    },
    props: {
        basicInfoId: String,
        useDate: Number // 登记时间
    },
    data() {
        return {
            checkedIds: [],
            updateReason: '',
            operTypeMap: {},
            visible: false,
            timeStamp: '',
            patient: {},
            list: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                saveSelected: true,
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType' // 必填
                    },
                    {
                        label: '操作项名称', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        slot: 'count', // 不必填 单列插槽
                        label: '登记次数', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '200' // 不必填
                    }
                ]
            }
        }
    },
    created() {
        console.log('workbook created------')
        this.getOperType()
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.getData()
            } else {
                this.checkedIds = []
                this.patient = {}
                this.list = []
                this.updateReason = ''
            }
        }
    },
    methods: {
        getOperType() {
            const type = 'oper_type'
            getDict({ type }).then(({ res }) => {
                console.log('------oper_type', res)
                let operTypeMap = {}
                res[type].forEach(item => {
                    operTypeMap[item.value] = item.label
                })
                this.operTypeMap = operTypeMap
            })
        },
        getData() {
            const param = {
                basicInfoId: this.basicInfoId,
                useDate: this.useDate
            }
            getChargeView(param).then(({ res }) => {
                console.log('------getChargeView', res)
                const { patient, list } = res
                this.list = list
                this.patient = patient
            })
        },

        /**
         * @description: 关闭
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style lang="scss" scoped></style>
