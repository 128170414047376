<!--
 * @Author: ligc
 * @Date: 2021-11-25 16:19:38
 * @LastEditTime: 2022-07-25 11:52:27
 * @LastEditors: ZF-WLY
 * @Description: 日常评估
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\dailyAssessment.vue
-->


<template>
    <el-main class="basic-main tab-wrapper">
      <div>
        <div class="menu-item">
          <el-row :column="4" :gutter="10">
            <el-col :sm="12" :md="8" :lg="6" :xl="4">
              <el-card class="box-card" style="height:137px;display:flex;justify-content: center;align-items: center;" shadow="hover">
                <div style="position: relative" @click="toAdd()">
                  <svg-icon
                    icon-class="add"
                    :style="{ color: '#80b9f6', 'font-size': '60px' }"
                  />
                </div>
              </el-card>
            </el-col>
            <el-col v-for="item in tableData" :key="item.id" :sm="12" :md="8" :lg="6" :xl="4">
              <el-card class="box-card" style="height:137px" shadow="hover">
                <div style="position: relative" @click="toDetail(item)">
                  <div style="display: flex; margin-bottom: 10px">
                    <div class="bedNumBox">{{commonConfig.$checkNullData(item.name)}}</div>
                  </div>
                  <div style="font-size:12px">最近评估日期：{{commonConfig.$checkNullData(item.createDate,"YYYY-MM-DD")}}</div>
                  <div v-if="item.tableType == 0" class="newBox">量表</div>
                  <div v-if="item.tableType == 1" class="newBox">方案</div>
                  <el-button @click.stop="del(item)" type="text" class="delBtn">删除</el-button>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <addDailyAssessment ref="addDailyAssessment" @submit="getList"/>
    </el-main>
</template>
<script>

import { getUserScaleRes, deleteUserScaleRel } from '@/api/patientAssessment'
import addDailyAssessment from "@/views/scale/patientAssessment/addDailyAssessment";
import { insertScale, insertProgram, } from "@/api/patientAssessment";

export default {
  name: "DailyAssessment",
  props: {
    basicInfoId: {
      type: String,
    },
  },
  components: {
    addDailyAssessment,
  },
  data() {
    return {
      activeName: "first",
      addScaleInfo:true,
      addScaleProgram:true,
      tableData: [
        
      ],
    };
  },
  created() {
    this.getList();
    this.addScaleInfo = this.hasPermission('scale-info-add');
    this.addScaleProgram = this.hasPermission('scale-program-add');
  },
  mounted: function () {},
  methods: {
    del(item){
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
          const param = { scaleId : item.id , type:item.tableType }
          deleteUserScaleRel(param,this.timeStamp).then(({res}) => {
              this.$message({...this.commonConfig.$handleSuccess})
              this.getList()
          })
      })
    },
    toDetail(item){
      if(item.status==0||item.status==1||item.status==2){
        if(item.tableType==0){
          this.$router.push({path:"/scale/patientScale",query:{patientScaleId:item.scalePatientId,flag:2}})
        }else{
          this.$router.push({path:"/scale/patientScaleProgram",query:{patientScaleId:item.scalePatientId,flag:2}})
        }
        
      }else{
        if(item.tableType==0){    //0量表 1方案
          this.addScale(item.id)
        }else{
          this.addProgram(item.id)
        }
      }
    },
    /**
     * @description: 新增量表
     * @param {*}
     * @return {*}
     */
    addScale(scaleInfoId) {
      const parm = {
          basicInfoId: this.basicInfoId, //患者ID
          scaleInfoId: [scaleInfoId],
      };
      insertScale(parm).then(({ res }) => {
        this.$router.push({path:"/scale/patientScale",query:{patientScaleId:res[0],flag:2}})
        this.getList();
      });
    },
    /**
     * @description: 新增方案
     * @param {*}
     * @return {*}
     */
    addProgram(scaleInfoId) {
      const parm = {
          basicInfoId: this.basicInfoId, //患者ID
          scaleProgramId: [scaleInfoId],
      };
      insertProgram(parm).then(({ res }) => {
          this.getList();
          this.$router.push({path:"/scale/patientScaleProgram",query:{patientScaleId:res[0],flag:2}})
      });
    },
    toAdd(){
      this.$refs.addDailyAssessment.updateVisible(true);
    },
    getList() {
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        const param =  {
          basicInfoId: this.basicInfoId,
        };
        getUserScaleRes(param).then(({ res, timeStamp }) => {
          this.tableData = res;
          this.timeStamp = timeStamp;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.tab-wrapper {
    height: calc(100vh - 216px);
    overflow-y: auto;
}
.el-col {
  border-radius: 4px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.newBox {
  font-size: 12px;
  background-color: $theme;
  color: #ffffff;
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  top: -15px;
  right: -15px;
}
.delBtn {
  position: absolute;
  top: 75px;
  right: -10px;
}
.box-card {
  margin-bottom: 10px;
  margin-right: 20px;
}
.bedNumBox {
  border-radius: 5px;
  background: #80b9f6;
  width: 250px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
}
</style>
