import { render, staticRenderFns } from "./workbrenchConfig.vue?vue&type=template&id=f2c1e7d0&scoped=true&"
import script from "./workbrenchConfig.vue?vue&type=script&lang=js&"
export * from "./workbrenchConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c1e7d0",
  null
  
)

export default component.exports