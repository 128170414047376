<template>
  <div class="navbar">
    <div class="hosInfo-box">
      <el-image
        class="hosLogo"
        :src="commonConfig.$fileUrl + hospitalInfo.logo"
        fit="cover"
      />
      <span class="hospitalName">{{ hospitalInfo.hospitalName|| commonConfig.$nullData }}</span>
      <span class="systemName">{{ hospitalInfo.systemName|| commonConfig.$nullData }}</span>
    </div>
    <div class="right-menu">
      <el-popover width="500" trigger="hover">
        <div class="right-menu-box" slot="reference" >
          <svg-icon icon-class="info" class="right-menu-icon" />
          <div class="right-menu-text">消息</div>
          <div class="red-num" v-if="unReadMsgList.length > 0">{{ unReadMsgList.length > 99 ? '99+' : unReadMsgList.length }}</div>
        </div>
        <div class="msg-list"  v-if="unReadMsgList.length == 0">
          <p class="msg-date">
            暂无消息
          </p>
        </div>
        <div class="msg-list">
          <div class="msg-item" v-for="item in unReadMsgList" :key="item.index">
            <p class="msg-date">
              {{ moment(item.noticeDate).format("yyyy-MM-DD HH:mm") || commonConfig.$nullData }}
            </p>
            <el-card shadow="hover" @click.native="toMsg(item)">
              <div class="msg-item-title">
                <span>{{ typeList[item.type]|| commonConfig.$nullData }}</span>
                <span>发起人:{{ item.trueName|| commonConfig.$nullData }}</span>
              </div>
              <p class="msg-item-content">
                {{ item.content || commonConfig.$nullData }}
              </p>
            </el-card>
          </div>
        </div>
      </el-popover>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="right-menu-box">
          <svg-icon icon-class="qrcode" class="right-menu-icon" />
          <div class="right-menu-text">二维码</div>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item
            ><el-image
              class="nav-qrcode"
              :src="commonConfig.$fileUrl + hospitalInfo.appDownloadAddress"
          /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="right-menu-box">
          <svg-icon icon-class="navUser" class="right-menu-icon" />
          <div class="right-menu-text">{{ trueName }}</div>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="dialogVisible = true">
            修改密码
          </el-dropdown-item>
          <el-dropdown-item @click.native="toPersonalSetting">
            个人设置
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :modal-append-to-body="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="formBox"
        :rules="rules"
      >
        <el-row class="formRow">
          <el-form-item label="旧密码" class="formItem" prop="oldPassword">
            <el-input v-model="form.oldPassword" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row class="formRow">
          <el-form-item label="新密码" class="formItem" prop="newPassword">
            <el-input v-model="form.newPassword" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row class="formRow">
          <el-form-item label="确认密码" class="formItem" prop="newPassword1">
            <el-input v-model="form.newPassword1" clearable show-password />
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit()">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {updateStatus } from "@/api/message";
import { getList,updatePassword } from "@/api/userInfo";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import { List } from 'echarts';
export default {

  
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword1: ""
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          { min: 6, max: 12, message: "请输入 6 - 12 个字符", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 12, message: "请输入 6 - 12 个字符", trigger: "blur" }
        ],
        newPassword1: [
          { validator: validatePassword, trigger: "blur", required: true }
        ]
      },
      typeList: [
        "系统分配通知",
        "治疗协同",
        "分配协同",
        "医嘱协同",
        "出院通知",
        "评估协同",
        "结束治疗",
        "医生分享治疗单"
      ],
      unReadNum: 0, //未读数量
      ws: null,
      isDestroyed: false, // 页面是否销毁
      lockReconnect: false, // 是否真正建立连接
      timeout: 45 * 1000, // 45秒一次心跳
      timeoutObj: null, // 心跳心跳倒计时
      serverTimeoutObj: null, // 心跳倒计时
      timeoutnum: null, // 断开 重连倒计时
    };
  },
  computed: {
    ...mapGetters(["trueName", "hospitalInfo",'unReadMsgList'])
  },
  created() {},
  mounted() {
    this.initWebSocket();
  },
  // 页面销毁
  beforeDestroy() {
    this.isDestroyed = true;
    this.timeoutnum && clearTimeout(this.timeoutnum);
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.ws.close(); // 关闭ws
  },
  methods: {
    async logout() {
      this.$router.push(`/login`);
      await this.$store.dispatch("logout");
      this.$router.push(`/login`);
    },
    toMsg(item) {
       const para = {id:item.id};
      updateStatus(para,this.timeStamp).then(({res,timeStamp}) =>{
        console.log("item.type",item.type);
          switch (item.type) {
                    case "0":
                        this.$router.push(`/treatment/myPatient`);
                        break;
                    case "1":
                        //治疗协同暂无或者已取消。
                        this.$router.push({
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(item.paramJson).id,
                                archivesInfoId: JSON.parse(item.paramJson).archivesInfoId,
                                basicInfoId:JSON.parse(item.paramJson).basicInfoId,
                            },
                        });
                        break;
                    case "2":
                        //分配通知->我的在治患者
                        this.$router.push(`/patient/myPatient`);
                        break;
                    case "3":
                        //协同详情页
                        this.$router.push({
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(item.paramJson).id,
                            },
                        });
                        break;
                    case "4":
                        //出院通知->患者一览,患者详情
                        this.$router.push({
                            path: "/patient/list/detail",
                            query: { id: item.paramJson },
                        });
                        break;
                    case "5":
                        this.$router.push({
                            //协同详情页
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(item.paramJson).id,
                            },
                        });
                        break;
                    case "6":
                        //结束治疗->患者一览,患者详情
                        this.$router.push({
                            path: "/patient/list/detail",
                            query: { id: item.paramJson },
                        });
                        break;
                    case "7":
                        //医生分享治疗单 
                        this.$router.push({
                            path: "/treatment/patientTreatment/treatmentSingle",
                            query: { param: item.paramJson },
                        });
                        break;
                    default:
                        console.error(item)
                        break;
                }
      })
      
    },
    toPersonalSetting() {
      this.$router.push(`/personalSetting`);
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updatePassword(this.form, new Date().getTime()).then(({ res }) => {
            this.$message({
              showClose: true,
              message: "修改成功",
              type: "success"
            });
            this.dialogVisible = false;
            this.form = {
              //重置修改密码输入框状态
              oldPassword: "",
              newPassword: "",
              newPassword1: ""
            };
          });
        } else {
          return false;
        }
      });
    },
    // 进入页面创建websocket连接
    initWebSocket() {
      // 判断页面有没有存在websocket连接
      const _this = this
      if (window.WebSocket) {
        const host = window.location.hostname;
        const auth = getToken();
        let authentication = auth.authentication
          .replace(/\//g, "@1@")
          .replace(/=/g, "@2@")
          .replace(/[+]/g, "@3@")
          .replace(/%/g, "@4@");
        this.ws = new WebSocket(
          `ws://${host}/rehabilitation/websocket/${auth.unique}/${authentication}`
        );
        this.ws.onopen = function(e) {
          // console.log("服务器连接成功");
          _this.start(); // 开启心跳
        };
        this.ws.onclose = function(e) {
          // console.log("服务器连接关闭");
          _this.reconnect(); // 重连
        };
        this.ws.onerror = function(e) {
          // console.log("服务器连接出错");
          _this.reconnect(); // 重连
        };
        this.ws.onmessage = function(e) {
          _this.reset(); // 心跳重置
          // 接收服务器返回的数据
          if (e.data !== 'pong') {
            if (e.data === 'scientificTask') {
              // ...todo
            } else {
              //获取未读消息
              _this.$store.dispatch('getUnReadMsgList')
            }
          }
        };
      }
    },
    // 重新连接
    reconnect() {
      var self = this;
      if (self.lockReconnect || self.isDestroyed) {
        return;
      }
      // console.log("重新连接。。。");
      self.lockReconnect = true;
      // 没连接上会一直重连，间隔5秒连接一次
      self.timeoutnum && clearTimeout(self.timeoutnum);
      self.timeoutnum = setTimeout(function() {
        // 新连接
        self.initWebSocket();
        self.lockReconnect = false;
      }, 5000);
    },
    // 重置心跳
    reset() {
      var self = this;
      // 清除时间
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      // 重启心
      self.start();
    },
    // 开启心跳
    start() {
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function() {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息
        if (self.ws.readyState === 1) {
          // 判断连接是否正常
          self.ws.send('ping');
        } else {
          // 否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function() {
          self.ws.close(); // 超过3秒没收到心跳消息关闭重连
        }, 3000);
      }, self.timeout);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.navbar {
  height: $navBarHeight;
  overflow: hidden;
  position: relative;
  background: $theme;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .right-menu {
    float: right;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &-box {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: auto;
      padding-right: 20px;
      cursor: pointer;
      position: relative;
      .red-num{
        position: absolute;
        left: 5px;
        top: -5px;
        padding: 0 5px;
        line-height: 18px;
        font-size: 12px;
        text-align: center;
        color: $white;
        border-radius: 5px;
        background: $danger;
      }
    }
    &-icon {
      color: $white;
      font-size: 22px;
      margin-right: 10px;
      margin-left: 15px;
    }
    &-text {
      color: $white;
      font-size: 16px;
      min-width: 50px;
    }
  }
  .hosInfo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $navBarHeight;
  }
  .hosLogo {
    width: auto;
    max-width: 70px;
    margin-left: 10px;
    margin-right: 10px;
    height: 38px;
  }
  .hospitalName {
    font-size: 28px;
    color: $white;
    font-weight: 600;
  }
  .systemName {
    font-size: 16px;
    color: $white;
    margin-left: 10px;
    margin-top: 11px;
  }
}
.user-dropdown {
  z-index: 100001 !important;
}
.nav-qrcode {
  width: 200px;
  height: 200px;
  margin-top: 10px;
}
.msg-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
}
.msg-item {
  width: 100%;
  margin: 5px 0;
  color: $titleColor;
  &-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  &-content {
    width: 100%;
    @include show_line(3);
    margin-bottom: 0;
  }
}
.msg-date {
  width: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin: 0;
  color: $text;
}
.el-card {
  cursor: pointer;
}
</style>
