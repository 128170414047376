<!--
 * @Author: mazihao mazihao@newpay.la
 * @Date: 2023-04-17 13:21:18
 * @LastEditors: mazihao mazihao@newpay.la
 * @LastEditTime: 2023-04-17 14:58:13
 * @FilePath: /recovery_management/src/views/demo/demoE/formList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-container>
    <el-main class="basic-main">
      <el-button :size="size"
                 @click="back()">返 回</el-button>
      <div class="mdrcc-info-title title">受试者身份鉴别代码表</div>
      <el-form ref="form"
               :model="form"
               label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="受试者姓名">
              <el-input v-model="form.name"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="拼音首字母缩写">
              <el-input v-model="form.name1"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证编号">
              <el-input v-model="form.name2"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="筛选序号">
              <el-input v-model="form.name3"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="随机号">
              <el-input v-model="form.name4"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="受试者住址">
              <el-input v-model="form.name5"
                        style="width:550px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <el-input v-model="form.name6"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他联络方式">
              <el-input v-model="form.name7"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮政编码">
              <el-input v-model="form.name8"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="mdrcc-info-title title">一般资料</div>
      <el-form ref="form"
               :model="form"
               label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="性别">
              <el-input v-model="form.name9"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="民族">
              <el-input v-model="form.name10"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄">
              <el-input v-model="form.name11"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="婚烟">
              <el-input v-model="form.name11"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职业">
              <el-input v-model="form.name12"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="文化程度">
              <el-input v-model="form.name13"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="既往史">
              <el-input v-model="form.name14"
                        style="width:350px"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <!-- <el-row>
          <el-col :span="8">
            <el-form-item label="药物过敏史">
              <el-input v-model="form.name14"
                        style="width:550px"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="8">
            <el-form-item label="个人史">
              <el-input v-model="form.name15"
                        style="width:550px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="吸烟史">
              <el-radio v-model="radio1"
                        label="1">是</el-radio>
              <el-radio v-model="radio2"
                        label="2">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="饮酒史">
              <el-radio v-model="radio3"
                        label="1">是</el-radio>
              <el-radio v-model="radio4"
                        label="2">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="药物过敏史">
              <el-radio v-model="radio5"
                        label="1">是</el-radio>
              <el-radio v-model="radio6"
                        label="2">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="家族更">
              <el-input v-model="form.name16"
                        style="width:550px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="mdrcc-info-title title">系统体格检查</div>
      <el-form ref="form"
               :model="form"
               label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="身高 (cm)">
              <el-input v-model="form.name17"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="体重 (kg)">
              <el-input v-model="form.name18"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="体温 (C)">
              <el-input v-model="form.name19"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="呼吸(次/分)">
              <el-input v-model="form.name20"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中压 (mmHg)">
              <el-input v-model="form.name21"
                        style="width:250px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      radio1: "1",
      radio2: "1",
      radio3: "1",
      radio4: "1",
      radio5: "1",
      radio6: "1",
      form: {
        name: "测试人",
        name1: "csr",
        name2: "1111222233333",
        name3: "12",
        name4: "33422",
        name5: "辽宁省沈阳市",
        name6: "1852*******11",
        name7: "1852*******11",
        name8: "111222",
        name9: "男",
        name10: "汉族",
        name11: "30",
        name12: "未婚",
        name13: "测试",
        name14: "大学本科",
        name15: "个人历史测试",
        name16: "家族变更数据",
        name17: "180",
        name18: "70",
        name19: "36.5",
        name20: "120",
        name21: "100",
      },
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style>
.title {
  margin-top: 15px;
}
</style>