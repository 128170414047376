
<!--
* @Description: 模板管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group">
        <el-button type="primary" v-if="addStatus" @click="toInsert()"
          >新增</el-button
        >
      </el-row>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
          <el-button type="text" v-if="editStatus" @click="toEdit(scope)"
            >编辑</el-button
          >
          <el-button
            type="text"
            v-if="delStatus"
            class="delBtn"
            @click="toDel(scope)"
            >删除</el-button
          >
        </template>
        <template slot="switch" slot-scope="scope">
          <el-switch
            v-if="switchStatus"
            v-model="scope.data.row.status"
            active-value="0"
            inactive-value="1"
            @change="setStatus(scope.data.row)"
          />
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog
        :id="checkId"
        :timeStamp="timeStamp"
        ref="insert"
        @submit="search"
      />
    </el-main>
  </el-container>
</template>
  
  <script>
import { getList, deleteData, setStatus , notEditTask} from "@/api/tempManage";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/scientific/tempManage/addDialog";
import detailDialog from "@/views/scientific/tempManage/detailDialog";
import { getDict } from "@/api/common";
import moment from "moment";
export default {
  name: "evaluationScheme",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入模板名称",
          key: "name",
          label: "模板名称",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "status",
          filterable: true,
          label: "状态",
          options: [],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "operation",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "模板名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "编辑时间", // 必填
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            label: "编辑人", // 必填
            prop: "trueName", // 必填
          },
          {
            slot: "switch", // 不必填 单列插槽
            label: "禁用/启用", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      addStatus: true, // 新增按钮权限
      editStatus: true, // 修改按钮权限
      delStatus: true, // 删除按钮权限
      switchStatus: true, // 启用禁用按钮权限
    };
  },
  created() {
    this.scaleProgramStatus();
    this.getList();
    this.addStatus = this.hasPermission("temp-add");
    this.editStatus = this.hasPermission("temp-edit");
    this.delStatus = this.hasPermission("temp-del");
    this.switchStatus = this.hasPermission("temp-status");
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },

    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      //校验科研模板 点击编辑按钮弹出相关提示不允许编辑（在任务管理里被使用）
      const parm = {
          id: scope.data.row.id,
        };
        notEditTask(parm, this.timeStamp).then(({ res }) => {
          if(res==false){
            this.$refs.insert.updateVisible(true);
          }else{
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.getList();
          }
        });
     
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const parm = {
          id: scope.data.row.id,
        };
        deleteData(parm, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
          this.getList();
        });
      });
    },
    setStatus(item) {
      //禁用/启用状态  status:禁用/启用状态
      const parm = {
        id: item.id,
        status: item.status,
      };
      setStatus(parm, this.timeStamp).then(({ res }) => {
        // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getList();
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
    scaleProgramStatus() {
      getDict({ type: "template_status" }).then(({ res }) => {
        this.searchOptions[1].options = res.template_status;
      });
    },
    onClickRow() {},
  },
};
</script>
  
  <style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>
  
          