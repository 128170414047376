<!--
 * @Author: mazihao 
 * @Date: 2023-04-17 13:12:50
 * @LastEditors: mazihao mazihao@newpay.la
 * @LastEditTime: 2023-04-17 14:59:00
 * @FilePath: /recovery_management/src/views/demo/demoE/tabList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-container>
    <el-main class="basic-main">
      <el-button :size="size"
                 style="margin:10px 0"
                 @click="back()">返 回</el-button>
      <generalTable ref="mainTable1"
                    :table-data="operResList1"
                    :config="tableConfig1">
        <template slot="handle"
                  slot-scope="scope">
          <el-button type="text"
                     @click="click(scope.data.row.operId)">查看</el-button>
        </template>
      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import generalTable from "@/components/Table";
export default {
  components: { generalTable },
  data() {
    return {
      operResList1: [
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
        {
          operName1: "多功能电动治疗床",
          operName2: "未开机",
          operName3: "康复一科",
          operName4: "二病区",
          operName5: "王医生",
          operName6: "患者1",
        },
      ],
      tableConfig1: {
        id: "operResList",
        align: "center", // 不必填 默认为center
        index: true, // 不必填 序号
        column: [
          {
            label: "各项明细", // 必填
            prop: "operName1", // 必填
            sortable: false,
          },
          {
            label: "设备状态", // 必填
            prop: "operName2", // 必填
            sortable: false,
          },
          {
            label: "所属科室", // 必填
            prop: "operName3", // 必填
            sortable: false,
          },
          {
            label: "所属病区", // 必填
            prop: "operName4", // 必填
            sortable: false,
          },
          {
            label: "责任治疗师", // 必填
            prop: "operName5", // 必填
            sortable: false,
          },
          {
            label: "在治患者", // 必填
            prop: "operName6", // 必填
            sortable: false,
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "150", // 不必填
          },
        ],
      },
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    click() {
      this.$router.push({
        path: "/demo/demoE/index",
      });
    },
  },
};
</script>

<style>
</style>