
<!--
* @Description: 任务管理页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
            <el-button type="text" @click="reCreate(scope.data.row)">生成文件</el-button>
            <el-button type="text" v-if="scope.data.row.status==2" @click="downLoad(scope.data.row)">下载</el-button>
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, reCreate } from '@/api/task'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import moment from "moment";
import { getDict, downloadFile } from '@/api/common'

export default {
    name: 'Task',
    components: {
        searchForm,
        generalTable
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '模板名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'status',
                    label: '状态',
                    options: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'createDate',
                    label: '任务创建时间',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'task',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '模板名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '状态', // 必填
                    prop: 'statusLabel' // 必填
                },
                {
                    label: '创建时间', // 必填
                    prop: 'createDate', // 必填
                    formatter: (row) => {
                        return (moment(row.createDate).format("YYYY-MM-DD HH:mm:ss"));
                    }
                },
                {
                    label: '完成时间', // 必填
                    prop: 'endTime', // 必填
                    formatter: (row) => {
                        return (row.endTime === null ?'-':moment(row.endTime).format("YYYY-MM-DD HH:mm:ss"));
                    }
                },
                {
                    label: '创建人', // 必填
                    prop: 'trueName' // 必填
                },
                {
                    label: '错误原因', // 必填
                    prop: 'exceptionReason' // 必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList();
        this.get_task_status();
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                })
            })
        },
        /** 文件下载
         * @description: 根据文件id获取下载的blob文件格式，将该格式文件下载到页面中
         * @param {*}
         * @return {*}
         */
        downLoad(row) {
            const parm = { id: row.fileId }
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement('a')
                dom.style.display = 'none'
                const blob = new Blob([res], {
                    type: row.contentType
                })
                const url = window.URL.createObjectURL(blob)
                dom.href = url
                // download后面必须设置为文件全名，即文件名+.+文件类型，否则当文件名中有.时，会导致文件类型出错
                dom.setAttribute('download', `${row.fileName}.${row.suffix}`)
                document.body.appendChild(dom)
                dom.click()
            })
        },
        get_task_status(){
            getDict({ type: 'task_status' }).then(({res}) => {
                this.searchOptions[1].options = res.task_status
            });
        },
        reCreate(row){
            reCreate({ id: row.id }).then(({res}) => {
                this.submitSuccess(res);
            });
        },
        submitSuccess(res) {
            this.$message({
                showClose: true,
                message: "操作成功",
                type: "success",
            });
            this.getList();
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        