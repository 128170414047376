<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->
<template>
    <el-dialog
        title="工作技能绑定"
        :visible.sync="visible"
        :close-on-click-modal="false"
        width="60%"
        :show-close="false"
    >
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search"/>
        <generalTable 
            ref="mainTable"
            :table-data="operResList"
            :config="tableConfig"
            :total="total"
            @updatePage="getUserOperRel"
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSubmit()">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>

import {getUserOperRel , addUserOperRel } from '@/api/personalSetting'
import { getDict } from "@/api/common";
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: "addSkill",
    components: {
        searchForm,
        generalTable,
    },
    props: {
        timeStamp:String,
    },
    data() {
        return {
            visible:false,
            operResList:[],
            tableConfig: {
                id: 'operResList',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '项目类型', // 必填
                        prop: 'careLabel'
                    },
                    {
                        label: '操作项名称', // 必填
                        prop: 'operName' // 必填
                    }
                ]
            },
            total:0,
            searchOptions: [
                {
                    type: 'select', // 必填 
                    placeholder: '请选择...', //非必填 默认为 请选择
                    key: 'operType', // 必填
                    label: '所属治疗', // 必填
                    options: [],
                    defaultVal: '' // 非必填 默认值
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '操作项目',
                    defaultVal: ''
                }
            ],
            paramObj: null,
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getOperType()
                this.getUserOperRel()
            }
        }
    },
    created(){},
    mounted() {},
    methods: {
        search(data) {
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getUserOperRel(){
            this.$nextTick(() => {
                const param = this.getParam()
                getUserOperRel(param).then(({res})=>{
                    this.operResList = res.records
                    this.total = res.total*1
                })
            })
        },
        /**
         * @description: 从字典项获取所属治疗
         * @param  {*}
         * @return {*}
         */        
        getOperType(){
            getDict({ type: "oper_type" }).then(({ res }) => {
                let typeOptions = [{ value: "", label: "全部" },...res.oper_type];
                this.searchOptions[0].options = typeOptions;
            });
        },
        updateVisible(val) {
            this.visible = val
        },
        onSubmit(){
            let checkList = this.$refs.mainTable.getChecked()
            if(checkList.length>0){
                let operIds = checkList.map((item,index)=>{
                    return item.operId
                })
                addUserOperRel(operIds).then(({res})=>{
                    this.submitSuccess()
                })
            }else{
                this.$message({
                    message: "请选择操作项",
                    type: "warning",
                });
            }
            
        },
        submitSuccess() {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>