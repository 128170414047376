/*
 * @Author: your name
 * @Date: 2021-12-01 16:19:39
 * @LastEditTime: 2022-02-21 11:47:13
 * @LastEditors: ZF-WLY
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_management/src/utils/print.js
 */
import printJS from "print-js";
/**
 * el表格由两个表格组成,打印时会出现串行,显示不全等问题. 通过提取出表头数据 手动放到新表格中 实现打印功能
 * @param {String} id //需要打印的id
 * @param {String} tableStyle // 表格样式重写
 */
export function print(id, tableStyle) {
    const html = document.querySelector('#' + id).innerHTML
    // 新建一个 DOM
    const div = document.createElement('div')
    const printDOMID = 'printDOMElement'
    div.id = printDOMID
    div.innerHTML = html

    // 提取第一个表格的内容 即表头
    const ths = div.querySelectorAll('.el-table__header-wrapper th')
    const ThsTextArry = []
    for (let i = 0, len = ths.length; i < len; i++) {
        if (ths[i].innerText !== '') ThsTextArry.push(ths[i].innerText)
    }

    // 删除多余的表头
    div.querySelector('.hidden-columns').remove()
    // 第一个表格的内容提取出来后已经没用了 删掉
    div.querySelector('.el-table__header-wrapper').remove()

    // 将第一个表格的内容插入到第二个表格
    let newHTML = '<tr>'
    for (let i = 0, len = ThsTextArry.length; i < len; i++) {
        newHTML += '<td style="font-weight: bold;color:#000">' + ThsTextArry[i] + '</td>'
    }

    newHTML += '</tr>'
    div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML)
    // 将新的 DIV 添加到页面 打印后再删掉
    document.querySelector('body').appendChild(div)

    printJS({
        printable: printDOMID,
        type: 'html',
        scanStyles: false,
        style: tableStyle || 'table tr td,th { border-collapse: collapse;border:1px #dedede solid;text-align:center;font-size:12px;color:#666;line-height:28px }' // 表格样式
    })

    div.remove()
}


/**
 * @description: 打印报告
 * @param {*} url
 * @return {*}
 */
export function printFile(url,type) {
    printJS({
        printable: url,
        type: type,
        scanStyles: false,
    })

} 

/**
 * @description: 打印报告 打印时，默认去掉input边框
 * @param {*} url
 * @return {*}
 */
 export function printHtml(id) {
    printJS({
        printable: id,
        type: 'html',
        style: `  
            table {
                border-collapse: collapse;
                border-spacing: 0;
            }
            textarea.scaleQue, input.scaleQue {
                border: none; 
                width: calc(100% - 20px); 
                resize: none;
            }
            input[type="checkbox"].scaleQue, 
            input[type="radio"].scaleQue {
                width: auto
            }
            .evaluate-zone input {
                border: none;
                width: auto;
            }`,
        scanStyles: false
    })

}  

 /**
  * @description: 打印带el-table的表格
  * @param {String} id 元素id
  * @param {String} style 页面的css样式, css部分怎么写, 这部分就怎么写
  * @return {*}
  */
 export function printHtmlTable(id, style) {
    printJS({
        printable: id,
        type: 'html',
        style,
        scanStyles: false,
    })
} 