<!--
 * @Author: your name
 * @Date: 2021-12-24 10:05:05
 * @LastEditTime: 2021-12-30 10:42:04
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\checkout.vue
-->

<template>
    <el-main>
        <el-row v-if="!dataArray.length">{{message}}</el-row>
        <el-row class="card-wrapper" v-for="item in dataArray" :key="item.id">
            <div class="mdrcc-info-title">{{item.rptTitle}}：</div>
            <el-row class="title-info">
                <el-col :span="12">样本类型：{{item.majorItemName}}</el-col>
                <el-col :span="12">检验时间：{{item.submissionDate}}</el-col>
            </el-row>
            <el-row class="table-wrapper">
                <!-- 根据数据长度自动计算表格高度 -->
                <generalTable class="table" :newHeight="item.resultRes.length*30+36" ref="mainTable" :table-data="item.resultRes" :config="tableConfig[item.id]" :total="0" @updatePage="() => {}" >
                    <template slot="itmna" slot-scope="scope">
                        <!-- 非正常值class绑定danger 正常值class绑定norm -->
                        <span :class="scope.data.row.isnormal === '1' ? 'norm' : 'danger'">{{scope.data.row.itmna || commonConfig.$nullData}}</span>
                    </template>
                    <template slot="printval" slot-scope="scope">
                        <!-- 非正常值class绑定danger 正常值class绑定norm -->
                        <span :class="scope.data.row.isnormal === '1' ? 'norm' : 'danger'">{{scope.data.row.printval || commonConfig.$nullData}}</span>
                        <span>&nbsp;{{scope.data.row.unit}}</span>
                        <!-- 标识为'H'时,显示向上箭头;标识为'L'时,显示向下箭头 -->
                        <span v-if="scope.data.row.imtFlag === 'H'" class="danger"><i class="el-icon-top"></i></span>
                        <span v-if="scope.data.row.imtFlag === 'L'" class="danger"><i class="el-icon-bottom"></i></span>
                    </template>
                    <template slot="referencerange" slot-scope="scope">
                        <!-- 非正常值class绑定danger 正常值class绑定norm -->
                        <span :class="scope.data.row.isnormal === '1' ? 'norm' : 'danger'">{{scope.data.row.referencerange || commonConfig.$nullData}}</span>
                    </template>
                </generalTable>
            </el-row>
        </el-row>
    </el-main>
</template>

<script>
import generalTable from "@/components/Table";
export default {
    name: 'checkoutPat360',
    components: {
        generalTable,
    },
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        dataArray: {
            type: Array
        }
    },
    watch:{
        /** 监听dataArray
         * @description: 根据dataArray数组内容配置tableConfig内容
         * @param {Array} newVal 新数据
         * @param {Array} orgVal 旧数据
         * @return {*}
         */        
        dataArray(newVal, orgVal){
            this.tableConfig = {}
            newVal.forEach(item => {
                this.tableConfig[item.id] = {
                    id: `resultRes-${item.id}`,
                    align: 'center', // 不必填 默认为center
                    index: true, // 不必填 序号
                    column: [
                    {
                        label: '标题1', // 必填
                        slot: 'itmna', // 必填
                        sortable: false
                    },
                    {
                        label: '结果', // 必填
                        slot: 'printval', // 必填
                        sortable: false,
                    },
                    {
                        label: '参考', // 必填
                        sortable: false,
                        slot: 'referencerange', 
                    },
                ]
                }
            })
        }
    },
    data() {
        return {
            tableConfig: {},
            message: "暂无数据"
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
// 表格前信息部分
.title-info {
    margin-bottom: 10px;
    font-size: 14px;;
}
// 各项目之间分开
.card-wrapper:not(:last-of-type) {
    .table-wrapper {
        margin-bottom: 20px;
    }
}
.table {
    // 非正常值,显示红字
    .danger {
        color: $danger;
    }
    // 正常值,显示正常颜色
    .nrom {
        color: $text;
    }
}
</style>