
/**
 * 页面: 评估方案管理
 * pageName: evaluationScheme
 **/
import request from '@/utils/request'

/**
 * @description: 获取评估方案管理列表数据
 */
export function getList(data) {
    return request({
        url: 'scaleProgram/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 获取评估方案评定分类
 */
 export function getScaleTypeList(data) {
    return request({
        url: 'scaleProgram/getScaleTypeList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增评估方案管理数据
 */
export function insertData(data) {
    return request({
        url: 'scaleProgram/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改评估方案管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'scaleProgram/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取评估方案管理详情数据
 */
export function getDetail(data) {
    return request({
        url: 'scaleProgram/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 禁用启用状态
 * @param {Object} data
 */
 export function setStatus(data,timeStamp) {
    return request({
        url: 'scaleProgram/setStatus',
        method: 'POST',
        data,
        timeStamp
    })
}  
/**
 * @description: 删除评估方案管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: 'scaleProgram/delete',
        method: 'POST',
        data: data,
        showLoading: true,
        timeStamp
    })
}
/**
 * @description: 获取notIds收费项目列表数据
 */
 export function getListByNotIds(data) {
    return request({
        url: 'scaleProgram/getListByNotIds',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 获取康复方案下的量表列表
 */
 export function getScaleList(data) {
    return request({
        url: 'scaleProgram/getScaleList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
        