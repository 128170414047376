<!--
 * @Author: ZF-WLY
 * @Date: 2021-11-04 15:36:07
 * @LastEditTime: 2022-12-08 15:41:54
 * @LastEditors: ZF-WLY
 * @Description: 患者360组件,收起时只展示一个按钮,打开时可切换展示该用户的各类数据
 * @FilePath: \recovery_management\src\views\patient360\index.vue
-->
<template>
    <div ref="drawerWrapper">
        <!-- 左上角患者小标签 -->
        <el-button type="text" class="pat-button" :style="{ right: right, top: top }" @click="closeOpen">
            <div class="iconBox">
                <svg-icon icon-class="pat360" class="pat-button-icon" />
            </div>
        </el-button>
        <el-drawer
            :style="{ height: patHeight, top: top }"
            ref="drawer"
            :visible.sync="showDrawer"
            :with-header="false"
            :size="patWidth"
            :before-close="closeOpen"
        >
            <el-container>
                <!-- 顶部患者信息部分 -->
                <el-header class="my-header">
                    <el-row>
                        <span class="name">{{ details.name || commonConfig.$nullData }}</span>
                        <span>{{ details.sex ? commonConfig.$sexList[details.sex] : commonConfig.$nullData }}</span>
                        <span>{{ details.age ? `${details.age}岁` : commonConfig.$nullData }}</span>
                        <span>{{ details.phone || commonConfig.$nullData }}</span>
                        <span>所属科室: {{ details.deptName || commonConfig.$nullData }}</span>
                    </el-row>
                    <el-row>
                        <span>主治医生: {{ details.impDocName || commonConfig.$nullData }}</span>
                        <span>就诊号: {{ details.serialNum || commonConfig.$nullData }}</span>
                    </el-row>
                </el-header>
                <el-divider></el-divider>
                <el-tabs
                    type="border-card"
                    class="drawer-tab"
                    v-model="type"
                    ref="drawerTab"
                    tab-position="left"
                    @tab-click="handleClick"
                >
                    <!-- information order assessment treat record       check checkout -->
                    <!-- 基本信息     医嘱  评定        治疗  康复治疗病史  检查   检验  -->
                    <!-- 循环this.tabInfo的key值,取出对应key值中的内容 -->
                    <el-tab-pane
                        v-for="item in Object.keys(tabInfo)"
                        :key="item"
                        class="tabs"
                        :label="tabInfo[item].label"
                        :name="item"
                        :lazy="true"
                    >
                        <!-- 如果有权限,按照key值展示组件,将相关内容插入到组件中 -->
                        <template v-if="tabInfo[item].permission">
                            <information v-if="type === 'information'" :data="tabInfo[item].data" @close="closeOpen" />
                            <order v-if="type === 'order'" :data="tabInfo[item].data" @close="closeOpen" />
                            <assessment v-if="type === 'assessment'" :data="tabInfo[item].data" @close="closeOpen" />
                            <treat v-if="type === 'treat'" :data="tabInfo[item].data" @close="closeOpen" />
                            <record v-if="type === 'record'" :data="tabInfo[item].data" @close="closeOpen" />
                            <check v-if="type === 'check'" :data="tabInfo[item].data" @close="closeOpen" />
                            <checkout v-if="type === 'checkout'" :dataArray="tabInfo[item].data" @close="closeOpen" />
                            <conclusion
                                v-if="type === 'summary'"
                                :basicInfoId="basicInfoId"
                                :dataArray="tabInfo[type].data"
                                @close="closeOpen"
                            />
                            <treatmentPlan
                                v-if="type === 'treatmentPlan'"
                                :basicInfoId="basicInfoId"
                                @close="closeOpen"
                            />
                        </template>
                        <!-- 如果没有对应权限,显示无权限提示信息 -->
                        <template v-else>{{ message }}</template>
                    </el-tab-pane>
                </el-tabs>
            </el-container>
        </el-drawer>
    </div>
</template>
<script>
import { getByIdCare } from '@/api/patient' // 获取患者基本信息
import { getAdviceList, getAssessmentList } from '@/api/patient360'
import { getTreatmentInfo, getInspectionList, getEmrList } from '@/api/patient'
import { getList as getSummaryList } from '@/api/patientTreatmentConclusion'
import { getList } from '@/api/treatedToday'
import generalTable from '@/components/Table'
import information from '@/views/other/Patient360/components/information' // 患者信息组件
import order from '@/views/other/Patient360/components/order' // 患者医嘱组件
import assessment from '@/views/other/Patient360/components/assessment' // 患者评估组件
import treat from '@/views/other/Patient360/components/treat' // 患者治疗组件
import record from '@/views/other/Patient360/components/record' // 患者康复治疗病史组件
import check from '@/views/other/Patient360/components/check' // 患者检查组件
import checkout from '@/views/other/Patient360/components/checkout' // 患者检验组件
import conclusion from '@/views/other/Patient360/components/summary' // 患者检验组件
import treatmentPlan from '@/views/other/Patient360/components/treatmentPlan' // 患者检验组件
import { getSummaryRuleConfig } from '@/api/summaryScale'
export default {
    components: {
        information,
        order,
        assessment,
        treat,
        record,
        check,
        checkout,
        conclusion,
        treatmentPlan
    },
    props: {
        // 组件计算前宽度,可接收数字或字符串
        width: {
            type: [Number, String],
            default: '500px',
            required: false
        },
        // 组件计算前高度,可接收数字或字符串
        height: {
            type: [Number, String],
            default: '960px',
            required: false
        },
        //患者basicInfoId,用于查询 医嘱、评定、排班、治疗、康复治疗病史、康复目标、检查、检验列表数据
        basicInfoId: String
    },
    updated() {},
    /** 页面创建时进行的操作
     * @description: 根据传入的宽高计算插件宽高
     *  如果获取不到患者的basicInfoId:
     *      则直接返回,不进行任何操作
     *  如果有患者basicInfoId:
     *      获取登录用户相关权限,
     *      将第一个tab页激活,
     *      按患者basicInfoId获取患者信息
     *      根据患者basicInfoId及tab页的type类型获取数据
     * @param {*}
     * @return {*}
     */

    created() {
        this.computedWrapper()
        // 获取不到basicInfoId时,阻止后台请求,否则会导致接口报错
        if (!this.basicInfoId) {
            return
        }
        this.getPermission()
        this.type = Object.keys(this.tabInfo)[0] // 使用第一个tab页对应的type作为默认展示的type
        this.getPatInfo(this.basicInfoId) // 根据患者basicInfoId获取患者基本信息
        this.getUserList(this.basicInfoId, this.type)
    },
    watch: {
        /**
         * 监听传入的basicInfoId值
         * @description: basicInfoId改变后,
         *      获取权限,
         *      将第一个tab页激活,
         *      按basicInfoId及type值获取数据
         * @param {String} newVal 新的患者basicInfoId
         * @param {String} oldVal 旧的患者basicInfoId
         * @return {*}
         */
        basicInfoId(newVal, oldVal) {
            this.getPermission()
            this.type = Object.keys(this.tabInfo)[0]
            newVal && this.getPatInfo(newVal)
            newVal && this.getUserList(newVal, this.type)
        },
        /**
         * 监听患者要获取的信息类型
         * @description: 当患者要查看的信息内容发生变化时,获取该信息的内容
         * @param {String} newVal 新的数据类型
         * @param {String} oldVal 旧的数据类型
         * @return {*}
         */
        type(newVal, oldVal) {
            newVal && this.getUserList(this.basicInfoId, newVal)
        },
        /** 组件高度
         * @description: 组件高度发生变化时,重新计算组件高度
         * @param {String|Number} newVal 新的组件高度
         * @param {String|Number} oldVal 旧的组件高度
         * @return {*}
         */

        height(newVal, oldVal) {
            this.computedWrapper()
        },
        /** 组件宽度
         * @description: 组件宽度发生变化时,重新计算组件宽度
         * @param {String|Number} newVal 新的组件宽度
         * @param {String|Number} oldVal 旧的组件宽度
         * @return {*}
         */
        width(newVal, oldVal) {
            this.computedWrapper()
        }
    },
    data() {
        return {
            patHeight: '', // 组件计算后高度
            patWidth: '', // 组件计算后高度
            top: '100px', // 默认组件距离页面顶部高度
            message: '暂无查看权限', // 无权限文字提示
            details: {}, // 患者基本信息
            showDrawer: false, // 是否显示抽屉组件
            right: 0, // 患者360按钮水平位置, 页面初始时,在最右侧
            type: 'information', // 患者要获取的表格数据类型,如：医嘱、评定等
            drawerHeight: 50, // 抽屉组件高度,百分比
            tableShow: false, // 用于表格是否显示,无权限时,不显示表格
            /**
             * 各个tab页数据
             * information order assessment treat record       check  checkout
             * 基本信息     医嘱  评定        治疗  康复治疗病史  检查   检验
             */
            tabInfo: {
                information: {
                    label: '基本信息', // tab页label值
                    data: {}, // 插件展示的数据
                    permission: true, // 权限标识
                    func: getTreatmentInfo, // 获取数据的函数
                    type: Object // data对应的数据类型
                },
                order: {
                    label: '医嘱', // tab页label值
                    data: [], // 插件展示的数据
                    permission: true, // 权限标识
                    func: getAdviceList, // 获取数据的函数
                    type: Array, // data对应的数据类型
                    params: {
                        // 获取数据时,除basicInfoId外,需要传入的参数,如果不需要额外传参,可不写该字段
                        showFlag: 1
                    }
                },
                assessment: {
                    label: '评定', // tab页label值
                    data: {}, // 插件展示的数据
                    permission: true, // 权限标识
                    func: getAssessmentList, // 获取数据的函数
                    type: Object // data对应的数据类型
                },
                treat: {
                    label: '治疗', // tab页label值
                    data: [], // 插件展示的数据
                    permission: true, // 权限标识
                    func: getList, // 获取数据的函数
                    type: Array, // data对应的数据类型
                    params: {
                        // 获取数据时,除basicInfoId外,需要传入的参数,如果不需要额外传参,可不写该字段
                        exportFlag: 1,
                        status: 0,
                        size: -1
                    }
                },
                record: {
                    label: '康复治疗病史', // tab页label值
                    data: [], // 插件展示的数据
                    permission: true // 权限标识
                },
                check: {
                    label: '检查', // tab页label值
                    data: [], // 插件展示的数据
                    permission: true, // 权限标识
                    func: getInspectionList, // 获取数据的函数
                    type: Array // data对应的数据类型
                },
                checkout: {
                    label: '检验',
                    data: [], // 插件展示的数据
                    permission: true, // 权限标识
                    func: getEmrList, // 获取数据的函数
                    type: Array // data对应的数据类型
                },
                summary: {
                    label: '治疗小结',
                    data: [], // 插件展示的数据
                    permission: true, // 权限标识
                    func: getSummaryList, // 获取数据的函数
                    type: Array // data对应的数据类型
                },
                treatmentPlan: {
                    label: '治疗计划',
                    data: [], // 插件展示的数据
                    permission: true // 权限标识
                }
            }
        }
    },
    methods: {
        /**
         * 患者360模块显隐
         * @description: 点击显示,再点击隐藏；显示/隐藏切换时,横向动态滚动
         * @param {*}
         * @return {*}
         */
        closeOpen() {
            console.log('open or close 360', this.type)
            this.showDrawer = !this.showDrawer
            this.right = this.showDrawer ? this.patWidth : 0
            const type = this.type
            if (this.showDrawer) {
                this.type = ''
                setTimeout(() => {
                    this.type = type
                }, 10)
            }
        },
        /** 计算百分比
         * @description: 根据传入的长度及总长度,将传入的长度计算成总长度的百分比
         *      默认计算结果为0%
         *      传入的长度为数字时,只接收>0的数据,其余内容不进行计算
         *      传入的长度为字符串类型时,只接受大于0的数字类型字符串、XXXpx、XXX%格式内容,其余内容均不进行计算
         * @param {String|Number} org 传入的长度
         * @param {Number} all 总长度
         * @return {String} 格式:'XXX%'
         */

        computedRate(org, all) {
            let newRate = '0%'
            // 如果为数值类型,如果传入值>1,默认传入类型为像素值,直接除以总长度,求百分比
            if (typeof org === 'number') {
                /**
                 * 传入类型为数值
                 * 如果传入值∈(1, ∞),默认传入类型为像素值,直接除以总长度,求百分比
                 * 如果传入值∈(0, 1],将数值直接转换成百分比
                 * 其余情况不计算
                 */

                if (org > 1) {
                    newRate = Number((org * 100) / all) + '%'
                } else if (org > 0) {
                    newRate = Number(org * 100) + '%'
                }
            } else {
                /**
                 * 传入类型为字符串
                 * 如果传入的字符串中有'%',且字符串为'数字+%'的格式,则将传入值直接输出
                 * 如果传入的字符串中有'px',且字符串为'数字+px'的格式,则去掉px后,将字符串转换成数字,除以总长度,求百分比
                 * 如果传入值为数值类型,将结果转换成数值,后与数值类型计算方法相同
                 * 其余情况不计算
                 */

                if (org.includes('%')) {
                    const height = Number(org.replace('%', ''))
                    if (!isNaN(height)) {
                        newRate = org
                    }
                } else if (org.includes('px')) {
                    const height = Number(org.replace('px', ''))
                    if (!isNaN(height)) {
                        newRate = Number((height * 100) / all) + '%'
                    }
                } else if (!isNaN(Number(org))) {
                    const height = Number(org)
                    if (height <= 1 && height >= 0) {
                        newRate = Number(height * 100) + '%'
                    } else if (height > 0) {
                        newRate = Number((height * 100) / all) + '%'
                    }
                }
            }
            return newRate
        },
        /** 计算患者360组件大小
         * @description: 获取组件父级容器大小,计算百分比
         * 宽度>容器宽度时,宽度取100%
         * 高度>容器高度时,高度取100%;高度与容器高度差值<100px时,高度设置为100%,剩余部分用top值撑开
         * @param {*}
         * @return {*}
         */

        computedWrapper() {
            this.$nextTick(() => {
                const dom = this.$refs.drawerWrapper.parentNode
                this.patHeight = this.computedRate(this.height, dom.offsetHeight)
                this.patWidth = this.computedRate(this.width, dom.offsetWidth)
                const height = (Number(this.patHeight.replace('%', '')) / 100) * dom.offsetHeight
                const width = (Number(this.patWidth.replace('%', '')) / 100) * dom.offsetWidth
                if (width > dom.offsetWidth) {
                    this.patWidth = '100%'
                }
                if (height < dom.offsetHeight && height > dom.offsetHeight - 100) {
                    this.patHeight = '100%'
                    this.top = dom.offsetHeight - height + 'px'
                } else if (height > dom.offsetHeight) {
                    this.patHeight = '100%'
                    this.top = '0px'
                }
            })
        },
        /** 获取权限
         * @description: 遍历tabInfo所有key值,将对应key值内容中的permission设置为权限结果
         * @param {*}
         * @return {*}
         */

        getPermission() {
            Object.keys(this.tabInfo).forEach(type => {
                // 如果没有权限,在页面中写出"暂无查看权限"字样
                if (!this.hasPermission(`patient-360-${type}`)) {
                    this.tabInfo[type].permission = false
                } else {
                    this.tabInfo[type].permission = true
                }
            })
        },
        /**
         * 获取患者基本信息
         * @description: 根据id获取患者基本信息,获取成功后,将信息放置于患者360模块的顶部
         * @param {String} basicInfoId 患者basicInfoId
         * @return {*}
         */
        getPatInfo(basicInfoId) {
            getByIdCare({ basicInfoId }).then(({ res }) => {
                this.$nextTick(() => {
                    this.details = { ...res }
                })
            })
        },
        /**获取患者对应类型的数据
         * @description: 根据患者basicInfoId及type获取相应的数据,将数据结果放入到tabInfo[type]的data中
         * @param {*} basicInfoId 患者basicInfoId
         * @param {*} type 要获取的信息类型,对应类型如下:
         * information order assessment treat record       check  checkout
         * 基本信息     医嘱  评定        治疗  康复治疗病史  检查   检验
         * @return {*}
         */
        getUserList(basicInfoId, type) {
            if (!this.tabInfo[type] || !this.tabInfo[type].permission) {
                return
            }
            // 根据类型锁定后台方法,将获取的数据放入插件
            if (typeof this.tabInfo[type].func === 'function') {
                let param = { basicInfoId }
                // 如果需要额外传参,将剩余参数拼接到params中
                if (this.tabInfo[type].params) {
                    param = {
                        ...param,
                        ...this.tabInfo[type].params
                    }
                }
                // 获取后台数据
                this.tabInfo[type].func(param).then(({ res }) => {
                    // 部分数据直接放在res中,部分数据放在了res.records中,
                    // 根据tabInfo[type]的type属性绑定的类型进行判断,哪个类型符合取哪个
                    if (res instanceof this.tabInfo[type].type) {
                        this.tabInfo[type].data = res
                    } else {
                        this.tabInfo[type].data = res.records
                    }
                    console.log(this.tabInfo[type])
                })
            }
        },
        /**
         * 点击tab标签触发
         * @description: 切换tab后，获取对应的type，根据type及id等获取对应的数据
         * @param {Object} tab tab页相关参数,tab.$options.propsData为页面绑定的属性值
         *      el-tab-pane插件中必须绑定name值,否则获取不到tab.$options.propsData.name信息
         * @return {*}
         */
        handleClick(tab) {
            const tabType = tab.$options.propsData.name
            this.type = tabType
            this.getUserList(this.basicInfoId, tabType)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
// 患者360 按钮样式
.pat-button {
    position: fixed;
    z-index: 19891015;
    width: 80px;
    transition: right 0.27s ease 1ms;
    width: 35px;
    height: 65px;
    border-radius: 5px 0 0 5px;
    background-color: $theme;
    &-icon {
        color: $menuText;
        font-size: 30px;
    }
}
// tab标签内部的内容部分
.el-container {
    background-color: $menuText;
    margin-top: 0;
}
// tab标签内部顶部的患者信息部分
.my-header {
    height: auto;
    margin: 15px 0;
    color: $titleColor;
    .el-row:not(:last-of-type) {
        margin-bottom: 10px;
    }
    span.name {
        font-size: 18px;
    }
    span {
        font-size: 15px;
    }
    span:not(:last-of-type) {
        margin-right: 30px;
    }
}
// tab页容器撑满高度
.drawer-tab {
    flex: 1;
    border: 0;
    box-shadow: none;
    display: flex;
}
// tab页内容高度限定
.tabs {
    flex: 1;
    overflow-y: auto;
    height: calc(100%);
    background-color: $menuText;
}
</style>
