
<!--
* @Description: 患者评定页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.data.row.id,scope.data.row.archivesInfoId)">评定</el-button>
              <el-button type="text" v-if="scope.data.row.noticeFlag == 1" @click="updateNoticeStatus(scope.data.row.id, '0')">不需要评定</el-button>
              <el-button type="text" v-if="scope.data.row.noticeFlag == 0" @click="updateNoticeStatus(scope.data.row.id, '1')">需要评定</el-button>
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, updateNoticeStatus} from '@/api/patientAssessment'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'
import { getDept } from '@/api/patient'

export default {
    name: 'PatientAssessment',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入患者姓名',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入床号',
                    key: 'bedNum',
                    label: '床号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'sex',
                    filterable: true,
                    label: '性别',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入患者住院号/就诊号',
                    key: 'recordNum',
                    label: '住院号/就诊号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'status',
                    filterable: true,
                    multiple: false,
                    label: '住院/门诊',
                    options: [],
                    defaultVal: "1"
                }
            ],
            tableConfig: {
                id: 'patientAssessment',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'name' // 必填
                    // formatter: (row, column) => {
                    //     return row.archivesInfoId.name
                    // }
                },
                {
                    label: '床号', // 必填
                    prop: 'bedNum' // 必填
                },
                {
                    label: '性别', // 必填
                    prop: 'sexLabel' // 必填
                },
                {
                    label: '主治医生', // 必填
                    prop: 'trueName' // 必填
                },
                {
                    label: '住院号/门诊号', // 必填
                    prop: 'recordNum', // 必填
                    formatter: (row, column) => {
                       return column.property=row.recordNum==null?row.serialNum:row.recordNum
                    }
                },
                {
                    label: '所属科室', // 必填
                    prop: 'deptName' // 必填
                },
                {
                    label: '评估次数', // 必填
                    prop: 'patientNum' // 必填
                },
                {
                    label: '报告次数', // 必填
                    prop: 'reportNum' // 必填
                },
                {
                    label: '入院/就诊日期', // 必填
                    prop: 'heDte', // 必填
                    formatter: (row, column) => {
                        return this.moment(row.heDte).format("YYYY-MM-DD");
                    }
                },
                {
                    label: '住院/门诊', // 必填
                    prop: 'statusLabel' // 必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList();
        this.getUserSexList();
        this.getPatientInHosStatusList();
        this.getDeptList();
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                    const tableList = this.tableData.map(item => {
                      if (item.noticeDisplayFlag=='1') {
                        item.className = 'failedItem'
                      } else {
                        item.className = ''
                      }
                      return item
                    })
                    this.tableData = tableList
                })
            })
        },
        toDetail(id,archivesInfoId){
            this.$router.push({ path: '/scale/patientAssessment/detail', query: { id: id,archivesInfoId:archivesInfoId}})
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        getUserSexList()  {
            getDict({ type: 'user_sex' }).then(({res}) => {
                this.searchOptions[2].options = res.user_sex;
            });
        },
        getPatientInHosStatusList()  {
            getDict({ type: 'patient_inHos_status' }).then(({res}) => {
                let statusCache = res.patient_inHos_status.map((item) => {
                    return {
                    value: item.value,
                    label: item.label,
                    };
                });
                this.searchOptions[5].options = statusCache;
            });
        },
         /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept({deptType:'4'}).then(({ res }) => {
                let deptList = res.map((item) => {
                    return {
                    value: item.id,
                    label: item.label,
                    };
                });
                this.searchOptions[4].options = deptList
            })
        },
      /**
       * @description: 获取所属科室
       * @param {*}
       * @return {*}
       */
      updateNoticeStatus(id, status) {
        updateNoticeStatus({basicInfoId:id, noticeFlag:status}).then(({ res }) => {
          this.getList();
        })
      },
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

