
<!--
* @Description: 用户管理页面
* @Reference:
-->

<template>
    <el-container>
        <el-aside>
            <el-input
                v-model="department"
                placeholder="请输入...部门名称"
                clearable
                maxlength="20"
                class="aside-input"
            />
            <el-tree
                ref="tree"
                class="user-tree"
                :data="treeData"
                default-expand-all
                :filter-node-method="filterTree"
                :expand-on-click-node="false"
                @node-click="onClickTreeRow"
            />
        </el-aside>
        <el-container class="inside-container">
            <el-header height="auto">
                <searchForm
                    ref="mainSearch"
                    :searchoptions="searchOptions"
                    :back-status="backStatus"
                    @search="search"
                />
            </el-header>
            <el-main class="basic-main">
                <el-row class="table-btn-group" v-if="addStatus">
                    <el-button type="primary" @click="toInsert()">新增</el-button>
                </el-row>
                <generalTable
                    ref="mainTable"
                    :table-data="tableData"
                    :config="tableConfig"
                    :total="total"
                    @updatePage="getUserList"
                >
                    <template slot="name" slot-scope="scope">
                        <a class="clickCell" @click="toDetail(scope)">{{ scope.data.row.trueName }}</a>
                    </template>
                    <template slot="status" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.status"
                            :disabled="scope.data.row.id === '1' || !userStatus"
                            inactive-value="1"
                            active-value="0"
                            @change="changeStatus(scope,$event)"
                        />
                    </template>
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" v-if="editStatus" @click="toEdit(scope)">编辑</el-button>
                        <el-button type="text" v-if="resetPasswordStatus" @click="resetPassword(scope)">重置密码</el-button>
                        <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope)">删除</el-button>
                    </template>
                </generalTable>
                <detailDialog :id="checkId" ref="detail" />
                <addDialog :id="checkId" ref="insert" @submit="resetSearch" />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import { getUserList, delUser, disableUser, resetUserPwd } from '@/api/user'
import { getOrgList } from '@/api/organization'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/user/addDialog'
import detailDialog from '@/views/system/user/detailDialog'
import { filterTableCol } from '@/utils/common'

export default {
    name: 'User',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    key: 'name',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    key: 'username',
                    label: '账号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    key: 'phone',
                    label: '手机号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'status',
                    filterable: true,
                    label: '状态',
                    options: [
                        {
                            label: '启用',
                            value: '0'
                        },
                        {
                            label: '禁用',
                            value: '1'
                        }
                    ],
                    defaultVal: '0'
                }
            ],
            tableConfig: {
                id: 'user',
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '姓名', // 必填
                        slot: 'name'
                    },
                    {
                        label: '工号', // 必填
                        prop: 'workNumber' // 必填
                    },
                    {
                        label: '账号', // 必填
                        prop: 'username' // 必填
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'phone' // 必填
                    },
                    {
                        label: '岗位', // 必填
                        prop: 'position' // 必填
                    },
                     {
                        label: '职位名称', // 必填
                        prop: 'jobTitle' // 必填
                    },
                    {
                        label: '禁用/启用', // 必填
                        slot: 'status' // 必填
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate).format(
                                'YYYY-MM-DD'
                            )
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            treeData: [],
            department: '', // 部门名称
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, // 返回按钮的隐藏显示
            timeStamp: '',
            addStatus: true, // 新增按钮权限
            editStatus: true, // 修改按钮权限
            delStatus: true, // 删除按钮权限
            resetPasswordStatus: true, // 重置密码按钮权限
            userStatus: true, // 用户状态权限
        }
    },
    watch: {
        department(val) {
            this.$refs.tree.filter(val)
        }
    },
    created() {
        this.addStatus = this.hasPermission('user-add')
        this.editStatus = this.hasPermission('user-edit')
        this.delStatus = this.hasPermission('user-del')
        this.resetPasswordStatus = this.hasPermission('user-resetPassword')
        this.userStatus = this.hasPermission('user-status')
        // filterTableCol('user',this.tableConfig.column)
        this.getOrgList()
        this.getUserList()
    },
    mounted: function () {},
    methods: {
        /**
         * 普通搜索
         * @description: 根据现有搜索条件搜索，并显示第一页数据
         * @param {Object} data 要与搜索栏进行捏合的搜索项，默认为空对象
         * @return {*}
         */        
        search(data = {}) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /**
         * 重置搜索
         * @description: 清空搜索框，并显示第一页数据
         */        
        resetSearch(){
            this.$refs.mainSearch.resetForm()
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /**
         * 获取用户数据
         * @description: 根据现有搜索添加及表格翻页信息进行用户列表显示
         *      后台获取数据时，记得实时更新timeStamp信息
         * @param {*}
         * @return {*}
         */        
        getUserList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getUserList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res
                    this.tableData = records
                    this.total = parseInt(total)
                    this.timeStamp = timeStamp
                })
            })
        },
        // 获取组织架构树
        getOrgList() {
            getOrgList({}).then(({ res }) => {
                this.treeData = [] // 新的树结构
                this.removeDisable(res, this.treeData) // 移除原先禁用的机构
                this.treeData = this.removeEmptyChild(this.treeData) // 移除空的子节点
            })
        },
        /**
         * 移除所有树结构中禁用的机构
         * @description: 使用status判断，status为“1”时，即为禁用的机构
         * @param {Array} orgData 原始数组结构
         * @param {Array} newData 过滤掉禁用机构之后的树结构
         * @return {*}
         */
        removeDisable(orgData, newData) {
            orgData.forEach((item) => {
                if (item.status !== '1') {
                    newData.push({ ...item, children: [] })
                }
                if (item.children) {
                    newData.length &&
                        this.removeDisable(
                            item.children,
                            newData[newData.length - 1].children
                        )
                }
            })
        },
        /**
         * 移除空的子节点
         * @description: 如果某个对象中，children的数组长度为0，则移除该层children；递归遍历查找每层children
         * @param {Array} orgData 原始树结构数组
         * @return {Array} 移除原始树结构中空children后的结果
         */
        removeEmptyChild(orgData) {
            orgData = orgData.map((item) => {
                let { children, ...temp } = item
                if (children && children.length) {
                    return {
                        ...temp,
                        children: this.removeEmptyChild(children) // 递归遍历，把新的过滤好的children插入到原先的children中
                    }
                } else if (children) {
                    return temp
                }
            })
            return orgData
        },

        // 修改用户状态
        changeStatus(scope, event) {
            // event switch更新前状态
            // index 数据序号
            const { id, status } = scope.data.row
            disableUser({ id, status: status }, this.timeStamp)
                .then(({ res }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                })
                .catch((err) => {
                    if (err) {
                        this.tableData[scope.data.$index].status =
                            event === 0 ? '1' : '0'
                    }
                })
        },
        resetPassword(scope) {
            const { $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm(
                '请确认是否重置密码',
                $delTitle,
                $messageBoxConfig
            ).then(() => {
                resetUserPwd({ id: scope.data.row.id }, this.timeStamp).then(
                    ({ res }) => {
                        this.$message({ ...this.commonConfig.$handleSuccess })
                    }
                )
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                delUser(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.getUserList()
                })
            })
        },
        // 左侧树结构点击事件
        onClickTreeRow(obj) {
            this.paramObj = Object.assign(this.paramObj, { deptId: obj.id })
            this.search()
        },
        // 通过部门名称过滤树结构
        filterTree(value, data) {
            if (!value) return true
            return data.label.indexOf(value) !== -1
        },
        // 获取参数
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.user-tree {
    border: 1px solid $tableColor;
    height: calc(100% - 124px);
    overflow-y: auto;
}
</style>

