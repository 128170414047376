<!--
 * @LastEditors: Richardlv
 * @Author: Richardlv
-->


<template>
    <el-dialog
        width="70%"
        :title="selectType=='multiple'?'选择治疗师':'选择负责人'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <searchForm
            ref="mainSearch"
            :searchoptions="searchOptions"
            :back-status="false"
            @search="search"
        />
        <generalTable
            v-if="visible"
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="getTherapistList"
        >
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getTherapistList } from "@/api/distribution";
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getDict } from "@/api/common";

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {
        selectType: {
            type: String,
        },
    },
    data() {
        return {
            checkId: "",
            tableData: [],
            dept: [], //部门数据
            searchOptions: [
                {
                    type: "select",
                    placeholder: "请选择岗位",
                    key: "position",
                    options: [],
                    label: "岗位",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "姓名",
                    key: "name",
                    label: "姓名",
                    defaultVal: "",
                },
            ],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: this.selectType=="multiple"?"multiple":"single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "所属机构", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "岗位", // 必填
                        prop: "position", // 必填
                    },
                    {
                        label: "姓名", // 必填
                        prop: "name", // 必填
                    },
                    {
                        label: "在治患者", // 必填
                        prop: "patientNums", // 必填
                    },
                ],
            },
            total: 0,
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: "", //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getTherapistList();
            }
        },
    },
    created() {
        this.getPosition();
    },
    mounted() {},
    methods: {
        /**
         * @description: 筛选查询
         * @param {*} data
         * @return {*}searchForm
         */
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },

        /**
         * @description: 获取岗位
         * @param {*}
         * @return {*}
         */
        getPosition() {
            getDict({ type: "position" }).then(({ res, timeStamp }) => {
                this.positionOptions = res.position.map((element) => {
                    const ele = {
                        value: element.value,
                        label: element.label,
                    };
                    return ele;
                });
                this.searchOptions[0].options = this.positionOptions;
            });
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const param = Object.assign({}, this.paramObj, paramObj);
            this.paramObj = param;
            return param;
        },
        /**
         * @description: 获取治疗师列表
         * @param {*}
         * @return {*}
         */
        getTherapistList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getTherapistList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    this.timeStamp = timeStamp;
                });
            });
        },

        /**
         * @description: 提交
         * @param {*}
         * @return {*}
         */
        submit() {
            if(this.selectType=="multiple"&&this.$refs.mainTable.getChecked().length>0){
                this.$emit("selectPerson",this.$refs.mainTable.getChecked()); 
                this.updateVisible(false);
            }else if(this.selectType=="single"&&this.$refs.mainTable.getChecked().length!=0){
                this.$emit("selectPerson",this.$refs.mainTable.getChecked()); 
                this.updateVisible(false);
            }else{
                this.$message({
                    message: '请选择治疗师',
                    type: 'warning'
                })
            }
            
        },
        /**
         * @description: 关闭弹窗
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val;
        },
    },
};
</script>
<style scoped lang="scss"></style>
