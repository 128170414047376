<!--
 * @Author: your name
 * @Date: 2022-11-22 16:33:13
 * @LastEditTime: 2022-12-12 09:07:07
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\treatmentPlan\planList.vue
-->
<template>
    <el-container ref="container">
        <el-header height="auto">
            <searchForm ref="mainSearch" :backStatus="true" :searchoptions="searchOptions" @search="search" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button v-if="hasPermission('treatment-plan-add')" type="primary" @click="toInsert()">
                    新增
                </el-button>
                <el-button v-if="hasPermission('treatment-plan-export')" type="primary" plain @click="toExport()">
                    导出
                </el-button>
            </el-row>
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                :newHeight="tableHeight"
            >
                <template v-if="hasPermission('plan-detail')" slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">详情</el-button>
                    <el-button v-if="hasPermission('treatment-plan-edit')" type="text" @click="toEdit(scope)">
                        编辑
                    </el-button>
                    <el-button v-if="hasPermission('treatment-plan-delete')" type="text" @click="toDelete(scope)">
                        删除
                    </el-button>
                </template>
            </generalTable>
        </el-main>
        <add-dialog ref="insert" :basicInfoId="basicInfoId" :id="checkId" @submit="getList" />
        <detail-dialog ref="detail" :basicInfoId="basicInfoId" :id="checkId" />
    </el-container>
</template>
<script>
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/treatment/treatmentPlan/addDialog'
import detailDialog from '@/views/treatment/treatmentPlan/detailDialog'
import { getPlanList, deleteData, exportPlan } from '@/api/treatmentPlan'
export default {
    components: {
        generalTable,
        searchForm,
        addDialog,
        detailDialog
    },
    created() {
        const { id } = this.$route.query
        this.basicInfoId = id
        this.getList()
    },
    data() {
        return {
            tableHeight: 0,
            basicInfoId: '',
            checkId: '',
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入计划名称',
                    key: 'planName',
                    label: '计划名称',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'createTime',
                    label: '计划创建日期',
                    defaultVal: []
                }
            ],
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '计划名称', // 必填
                        sortable: false,
                        prop: 'planName' // 必填
                    },
                    {
                        label: '创建人', // 必填
                        sortable: false,
                        prop: 'createName' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        sortable: false,
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate * 1).format('YYYY-MM-DD')
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        sortable: false,
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            timeStamp: ''
        }
    },
    methods: {
        /**
         * @description:通过手动获取container高度,再删减部分高度,赋值给表格组件, 否则会出现污染高度情况
         */
        getTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = this.$refs.container.$el.clientHeight - 16 - 150
            })
        },
        search(data) {
            this.paramObj = {
                ...this.paramObj,
                ...data
            }
            this.paramObj = Object.assign({}, this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = { basicInfoId: this.basicInfoId, ...this.getParam() }
                getPlanList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = res.total * 1
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toExport() {
            const param = { basicInfoId: this.basicInfoId, ...this.getParam() }
            exportPlan(param)
                .then(({ res }) => {
                    console.log('------exportPlan', res)
                    // const blob = new Blob([res], {
                    //     type: 'application/vnd.ms-excel'
                    // })
                    const fileName = '治疗计划.xls'
                    const linkNode = document.createElement('a')
                    linkNode.download = fileName //a标签的download属性规定下载文件的名称
                    linkNode.style.display = 'none'
                    linkNode.href = URL.createObjectURL(res) //生成一个Blob URL
                    document.body.appendChild(linkNode)
                    linkNode.click() //模拟在按钮上的一次鼠标单击

                    URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                    document.body.removeChild(linkNode)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDelete(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(res => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.getList()
                })
            })
        },
        getParam() {
            let page = this.$refs.mainTable.getPage()
            let paramObj = this.$refs.mainSearch.packageData()
            let param = Object.assign({}, page, { myPatient: this.selectFlag }, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>
