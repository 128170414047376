<!--
* @Description: 出院选择日期
* @Reference:
-->

<template>
  <el-dialog
    width="50%"
    title="选择出院时间"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-form-item label="出院时间" prop="leaveDate">
        <el-date-picker
          v-model="form.leaveDate"
          align="right"
          type="date"
          clear-icon="el-input__icon"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { stopTreatment } from "@/api/patient";
import { setRefreshFlag } from "@/utils/common";
export default {
  components: {},
  props: {
    endTheTreatmentData: Object,
    timeStamp: String,
  },
  data() {
    return {
      total: 0, // 总条数
      visible: false,
      form : {
        leaveDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      rules: {
        leaveDate: {
          required: true,
          message: "请选择出院时间",
          trigger: "blur",
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },  
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        const date = this.moment(this.form.leaveDate).format('YYYY-MM-DD')
        const parmObj = Object.assign({
          leaveDate: new Date(date + ' ' + '00:00:00').getTime(),
          id: this.endTheTreatmentData.id,
        });
        if (valid) {
          stopTreatment(parmObj, this.timeStamp).then(({ res, timeStamp }) => {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false)
            this.updateFlag = false
            setRefreshFlag();
            if (status === 0) {
              this.$router.back();
            } else {
              this.$router.back();
            }
          });
        }
      });
    },
    updateVisible(val) {
      this.visible = val;
    },
  },
};
</script>
<style scoped>
</style>

