<!--
 * @Author: your name
 * @Date: 2022-01-10 13:55:54
 * @LastEditTime: 2022-03-01 15:27:46
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\scale\publicScaleLib\configDialog.vue
-->
<template>
    <el-dialog
        width="80%"
        :title="'汇总配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">汇总类型</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :span="8">
                    <el-form-item label="汇总类型" prop="type">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="满分含义" prop="indicatorsFlag">
                        <el-radio-group v-model="form.indicatorsFlag">
                            <el-radio label="1">状况最优</el-radio>
                            <el-radio label="0">状况最差</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="图表展示" prop="words">
                        <el-radio-group v-model="form.words">
                            <el-radio label="0">有</el-radio>
                            <el-radio label="1">无</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                v-if="form.type === '2'"
                :gutter="10"
                type="flex"
                justify="start"
            >
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="量表模板" prop="mouldPath">
                        <input
                            style="display: none"
                            type="file"
                            accept=".xls, .xlsx"
                            @change="changeModelFile($event)"
                            ref="ModelInput"
                        />
                        <el-button type="primary" @click="setModelInput()"
                            >上传</el-button
                        >
                        {{ form.mouldPath }}
                    </el-form-item>
                </el-col>
            </el-row>
            <template v-else>
                <!-- 贯穿行信息 -->
                <div class="mdrcc-info-title">贯穿行信息</div>
                <el-row :gutter="10" type="flex" justify="start">
                    <el-col :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item
                            label="是否显示贯穿行"
                            prop="showThroughLine"
                        >
                            <el-radio-group
                                v-model="form.showThroughLine"
                                @change="
                                    (val) => {
                                        showThroughChange(
                                            val,
                                            throughLineOptions
                                        );
                                    }
                                "
                            >
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <template v-if="form.showThroughLine === '1'">
                    <el-row class="table-btn-group">
                        <el-button type="primary" @click="toInsertThrough()"
                            >新增</el-button
                        >
                    </el-row>
                    <generalTable
                        class="table-margin"
                        ref="mainTableThrough"
                        :table-data="form.throughData"
                        :config="throughConfig"
                        :total="0"
                        :newHeight="232"
                    >
                        <template slot="key" slot-scope="scope">
                            <el-select
                                class="input-margin"
                                @change="
                                    (val) => {
                                        selectChange(
                                            scope,
                                            form.throughData,
                                            val
                                        );
                                    }
                                "
                                v-model="scope.data.row.key"
                                placeholder="请选择关键字"
                            >
                                <el-option
                                    v-for="item in throughLineOptions"
                                    :key="item.value"
                                    :label="item.value"
                                    :value="{
                                        value: item.value,
                                        label: item.label,
                                    }"
                                    :name="item.label"
                                ></el-option>
                            </el-select>
                        </template>
                        <template slot="label" slot-scope="scope">
                            <el-input
                                class="input-margin"
                                v-model="scope.data.row.label"
                                placeholder="请输入标签"
                                clearable
                            />
                        </template>
                        <template slot="sort" slot-scope="scope">
                            <el-input
                                class="input-margin"
                                v-model="scope.data.row.sort"
                                placeholder="请输入排序"
                                clearable
                                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                            />
                        </template>
                        <template slot="handle" slot-scope="scope">
                            <el-popconfirm
                                confirm-button-text="确认"
                                cancel-button-text="取消"
                                icon="el-icon-info"
                                icon-color="red"
                                title="请确认是否删除此条数据？"
                                @confirm="
                                    toDel(scope.data.$index, form.throughData)
                                "
                            >
                                <el-button
                                    slot="reference"
                                    type="text"
                                    size="mini"
                                    class="delBtn"
                                >
                                    删除
                                </el-button>
                            </el-popconfirm>
                        </template>
                    </generalTable>
                </template>
                <!-- 贯穿行信息结束 -->
                <!-- 评估信息 -->
                <div class="mdrcc-info-title">评估信息</div>
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toInsertQues('1')"
                        >新增question</el-button
                    >
                    <el-button type="primary" @click="toInsertQues('2')"
                        >新增key</el-button
                    >
                </el-row>
                <generalTable
                    class="table-margin"
                    ref="mainTableQues"
                    :table-data="form.quesData"
                    :config="quesConfig"
                    :total="0"
                    :newHeight="232"
                >
                    <!-- quesOptions -->
                    <template slot="keyShow" slot-scope="scope">
                        <el-select
                            class="input-margin"
                            @change="
                                (val) => {
                                    selectChange(scope, form.quesData, val);
                                }
                            "
                            v-model="scope.data.row.keyShow"
                            placeholder="请选择关键字"
                        >
                            <el-option
                                v-for="item in scope.data.row.type === '1'
                                    ? quesOptions
                                    : rowKeyOptions"
                                :key="item.value"
                                :label="item.value"
                                :value="{
                                    value: item.value,
                                    label: item.label,
                                }"
                                :name="item.label"
                            ></el-option>
                        </el-select>
                    </template>
                    <template slot="label" slot-scope="scope">
                        <el-input
                            class="input-margin"
                            v-model="scope.data.row.label"
                            placeholder="请输入标签"
                            clearable
                        />
                    </template>
                    <template slot="sort" slot-scope="scope">
                        <el-input
                            class="input-margin"
                            v-model="scope.data.row.sort"
                            placeholder="请输入排序"
                            clearable
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                        />
                    </template>
                    <template slot="handle" slot-scope="scope">
                        <el-popconfirm
                            confirm-button-text="确认"
                            cancel-button-text="取消"
                            icon="el-icon-info"
                            icon-color="red"
                            title="请确认是否删除此条数据？"
                            @confirm="toDel(scope.data.$index, form.quesData)"
                        >
                            <el-button
                                slot="reference"
                                type="text"
                                size="mini"
                                class="delBtn"
                            >
                                删除
                            </el-button>
                        </el-popconfirm>
                    </template>
                </generalTable>
                <!-- 评估信息结束 -->
            </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {
    getSummaryRuleConfig,
    updateSummaryRule,
    getScaleInfoRuleConfig,
    updateScaleInfoRule,
} from "@/api/summaryScale";
import generalTable from "@/components/Table";
export default {
    name: "summaryScaleConfig",
    components: {
        generalTable,
    },
    props: {
        id: String,
        fromPrivate: Boolean, //是否来自评估量表库
    },
    data() {
        return {
            basicOptions: [],
            throughLineOptions: [],
            quesOptions: [],
            rowKeyOptions: [],
            options: [
                {
                    value: "1",
                    label: "类型1",
                },
                {
                    value: "2",
                    label: "类型2",
                },
            ],
            basicConfig: {
                id: "basicTable",
                align: "center", // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: "关键字", // 必填
                        slot: "key", // 必填
                        sortable: false,
                    },
                    {
                        label: "标签", // 必填
                        slot: "label", // 必填
                        sortable: false,
                    },
                    {
                        label: "排序", // 必填
                        slot: "sort", // 必填
                        sortable: false,
                    },
                    {
                        label: "操作", // 必填
                        slot: "handle", // 必填
                        sortable: false,
                    },
                ],
            },
            throughConfig: {
                id: "throughTable",
                align: "center", // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: "关键字", // 必填
                        slot: "key", // 必填
                        sortable: false,
                    },
                    {
                        label: "标签", // 必填
                        slot: "label", // 必填
                        sortable: false,
                    },
                    {
                        label: "排序", // 必填
                        slot: "sort", // 必填
                        sortable: false,
                    },
                    {
                        label: "操作", // 必填
                        slot: "handle", // 必填
                        sortable: false,
                    },
                ],
            },
            quesConfig: {
                id: "quesTable",
                align: "center", // 不必填 默认为center
                index: true, // 不必填 序号
                timeStamp: "",
                column: [
                    {
                        label: "关键字", // 必填
                        slot: "keyShow", // 必填
                        sortable: false,
                    },
                    {
                        label: "标签", // 必填
                        slot: "label", // 必填
                        sortable: false,
                    },
                    {
                        label: "排序", // 必填
                        slot: "sort", // 必填
                        sortable: false,
                    },
                    {
                        label: "操作", // 必填
                        slot: "handle", // 必填
                        sortable: false,
                    },
                ],
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: "请选择汇总类型",
                        trigger: "change",
                    },
                ],
            },
            form: {
                indicatorsFlag:"1",
                words:"1",
                type: "1",
                showThroughLine: "0",
                throughData: [],
                quesData: [],
                newFlieFlag: false,
                mouldPath: "", //模板文件名
                modelFile: {}, //模板文件
            },
            visible: false,
        };
    },
    created() {},
    watch: {
        visible(newVal, oldVal) {
            if (!newVal) {
                (this.form = {
                    type: "1",
                    showThroughLine: "0",
                    indicatorsFlag:this.form.indicatorsFlag,
                    words:this.form.words,
                    throughData: [],
                    quesData: [],
                    mouldPath: "", //模板文件名
                    modelFile: {}, //模板文件
                }),
                    this.$refs.insertForm.resetFields();
            } else {
                this.fromPrivate == true
                    ? this.getScaleInfoRuleConfig()
                    : this.getSummaryRuleConfig();
            }
        },
    },
    methods: {
        /**
         * @description: 获取公共量表库配置
         * @param {*}
         * @return {*}
         */
        getSummaryRuleConfig() {
            getSummaryRuleConfig({ id: this.id }).then(({ res, timeStamp }) => {
                console.log("------公共量表库配置------", res);
                this.timeStamp = timeStamp;
                const {
                    requiredQuestionList,
                    throughLineList,
                    summaryMouldPath,
                    summaryRule,
                    summaryFlag,
                    questionList,
                    rowKeyList,
                } = res;
                this.basicOptions = [...throughLineList];
                this.throughLineOptions = [...requiredQuestionList];
                this.quesOptions = [...questionList];
                this.rowKeyOptions = [...rowKeyList];
                console.log("------quesOptions------", this.quesOptions);
                if (!summaryMouldPath && !summaryRule) {
                    this.initPage(res);
                } else if (summaryMouldPath && summaryRule) {
                    // summaryFlag “1”取规则 “0”取模板
                    summaryFlag === "1" && this.getSummaryRule(summaryRule);
                    summaryFlag === "0" &&
                        this.getSummaryPath(summaryMouldPath, res);
                } else if (summaryMouldPath) {
                    this.getSummaryPath(summaryMouldPath, res);
                } else if (summaryRule) {
                    this.getSummaryRule(summaryRule);
                }
            });
        },
        /**
         * @description: 获取评估量表库配置
         * @param {*}
         * @return {*}
         */
        getScaleInfoRuleConfig() {
            getScaleInfoRuleConfig({ id: this.id }).then(
                ({ res, timeStamp }) => {
                    console.log("------评估量表库配置------", res);
                    this.timeStamp = timeStamp;
                    const {
                        requiredQuestionList,
                        throughLineList,
                        summaryMouldPath,
                        summaryRule,
                        summaryFlag,
                        questionList,
                        rowKeyList,
                        indicatorsFlag,
                        words,
                    } = res;
                    this.basicOptions = [...throughLineList];
                    this.throughLineOptions = [...requiredQuestionList];
                    this.quesOptions = [...questionList];
                    this.rowKeyOptions = [...rowKeyList];
                    this.form.indicatorsFlag = indicatorsFlag;
                    this.form.words = words;
                    console.log("------quesOptions------", this.quesOptions);
                    if (!summaryMouldPath && !summaryRule) {
                        this.initPage(res);
                    } else if (summaryMouldPath && summaryRule) {
                        // summaryFlag “1”取规则 “0”取模板
                        summaryFlag === "1" && this.getSummaryRule(summaryRule);
                        summaryFlag === "0" &&
                            this.getSummaryPath(summaryMouldPath, res);
                    } else if (summaryMouldPath) {
                        this.getSummaryPath(summaryMouldPath, res);
                    } else if (summaryRule) {
                        this.getSummaryRule(summaryRule);
                    }
                }
            );
        },
        initPage(res) {
            const {
                questionList,
                requiredQuestionList,
                rowKeyList,
                throughLineList,
            } = res;
            this.basicOptions = [...throughLineList];
            this.throughLineOptions = [...requiredQuestionList];
            const questionData = questionList.map((item) => {
                return {
                    label: item.label,
                    answerCoordinate: item.value,
                    sort: "",
                };
            });
            const rowKeyData = rowKeyList.map((item) => {
                return {
                    label: item.label,
                    key: item.value,
                    sort: "",
                };
            });
            // this.form.quesData = [...rowKeyData, ...questionData]
        },
        getSummaryPath(summaryPath, res) {
            this.form.type = "2";
            this.form.mouldPath = summaryPath;
            this.initPage(res);
        },
        getSummaryRule(orgSummaryRule) {
            const { patientInfo, rowData, throughLine } =
                JSON.parse(orgSummaryRule);
            console.log(
                "------getSummaryRule------",
                patientInfo,
                rowData,
                throughLine
            );
            console.log(
                "------options------",
                this.basicOptions,
                this.throughLineOptions,
                this.quesOptions
            );
            this.form.quesData = rowData.map((item) => {
                const { name, answerCoordinate, key, ...others } = item;
                return {
                    ...others,
                    label: name,
                    type: answerCoordinate ? "1" : "2",
                    keyShow: key || answerCoordinate,
                };
            });
            if (throughLine.length) {
                this.form.showThroughLine = "1";
                this.form.throughData = throughLine.map((item) => {
                    const { name, answerCoordinate, sort } = item;
                    return {
                        key: answerCoordinate,
                        label: name,
                        sort,
                    };
                });
            }
        },
        selectChange(scope, tableData, selectVal) {
            tableData[scope.data.$index].label = selectVal.label;
        },
        toInsertThrough() {
            this.form.throughData = [
                ...this.form.throughData,
                {
                    key: "",
                    label: "",
                    sort: "",
                },
            ];
        },
        toInsertQues(type) {
            this.form.quesData = [
                ...this.form.quesData,
                {
                    type,
                    showKey: "",
                    label: "",
                    sort: "",
                },
            ];
        },
        toDel(index, tableData) {
            tableData.splice(index, 1);
        },
        updateVisible(val) {
            this.visible = val;
        },
        /** 提交前校验
         * @description: 对类型1、类型2汇总分别进行校验
         *  类型1：需要添加基本信息及题目信息，如果设置了显示贯穿行，还要添加贯穿行信息
         *  类型2：上传文件即可
         * @param {*} form
         * @return {*}
         */
        checkBeforeSubmit(form) {
            // type === "2" 文件名
            if (form.type === "2") {
                const mouldKey = {
                    mouldPath: "请上传模板文件！",
                    newFlieFlag: "尚未上传新模板！",
                };
                for (let k in mouldKey) {
                    if (!form[k]) {
                        this.$message({
                            type: "warning",
                            message: mouldKey[k],
                        });
                        return false;
                    }
                }
                return true;
            }
            // type === "1" 题目信息 / 贯穿行信息+题目信息
            if (form.showThroughLine === "1") {
                // 如果显示贯穿行
                // 空数组情况
                if (!form.throughData.length) {
                    this.$message({
                        type: "warning",
                        message: "贯穿行至少添加一项！",
                    });
                    return false;
                }
                // 缺字段情况
                for (let item of form.throughData) {
                    // key label sort 必填
                    const throughKey = {
                        key: "请选择关键字！",
                        label: "请输入标签！",
                        sort: "请输入排序！",
                    };
                    for (let k in throughKey) {
                        if (!item[k]) {
                            this.$message({
                                type: "warning",
                                message: throughKey[k],
                            });
                            return false;
                        }
                    }
                }
            }
            for (let item of form.quesData) {
                // sort 必填
                const quesKey = {
                    keyShow: "请选择关键字！",
                    label: "请输入标签！",
                    sort: "请输入排序！",
                };
                for (let k in quesKey) {
                    if (!item[k]) {
                        this.$message({
                            type: "warning",
                            message: quesKey[k],
                        });
                        return false;
                    }
                }
            }
            return true;
        },
        showThroughChange(newVal, options) {
            if (newVal === "1" && (!options || !options.length)) {
                this.$message({
                    type: "warning",
                    message: "无必填项，无法添加贯穿行！",
                });
                this.form.showThroughLine = "0";
            }
        },
        submit() {
            const {
                throughData,
                quesData,
                modelFile,
                showThroughLine,
                mouldPath,
                type,
                indicatorsFlag,
                words,
                ...others
            } = this.form;
            // 如果校验不通过，直接截断，不往下进行
            if (!this.checkBeforeSubmit({ ...this.form })) {
                return;
            }
            let formData = new FormData();
            formData.append("id", this.id);
            formData.append("indicatorsFlag", this.form.indicatorsFlag);
            formData.append("words", this.form.words);

            // 通过校验后
            if (type === "2") {
                formData.append("summaryFile", modelFile, mouldPath);
            } else {
                const summaryRule = {
                    throughLine:
                        showThroughLine === "0"
                            ? []
                            : throughData.map((item) => {
                                  const { key, label, sort } = item;
                                  return {
                                      name: label,
                                      answerCoordinate: key.value || key,
                                      sort,
                                  };
                              }),
                    rowData: quesData.map((item) => {
                        const {
                            label,
                            sort,
                            keyShow,
                            key,
                            answerCoordinate,
                            type,
                        } = item;
                        console.log(
                            "-------label, sort,keyShow, key, answerCoordinate------",
                            label,
                            sort,
                            keyShow,
                            key,
                            answerCoordinate
                        );
                        let obj = {
                            name: label,
                            sort,
                        };
                        if (type === "1") {
                            obj.answerCoordinate = keyShow.value || keyShow;
                        } else {
                            obj.key = keyShow.value || keyShow;
                        }
                        return obj;
                    })
                };
                formData.append("summaryRule", JSON.stringify(summaryRule));
            }
            if (this.fromPrivate == true) {
                updateScaleInfoRule(formData, this.timeStamp)
                    .then(({ res }) => {
                        this.$message({ ...this.commonConfig.$handleSuccess });
                        this.updateVisible(false);
                        this.$emit("submit");
                    })
                    .catch((error) => {
                        this.$message({
                            type: "warning",
                            message: "出错了，请稍后刷新重试！",
                        });
                    });
            } else {
                updateSummaryRule(formData, this.timeStamp)
                    .then(({ res }) => {
                        this.$message({ ...this.commonConfig.$handleSuccess });
                        this.updateVisible(false);
                        this.$emit("submit");
                    })
                    .catch((error) => {
                        this.$message({
                            type: "warning",
                            message: "出错了，请稍后刷新重试！",
                        });
                    });
            }
        },

        /**
         * @description: 读取模板文件
         */
        changeModelFile(event) {
            let file = event.target.files[0];
            this.form.modelFile = file;
            this.form.mouldPath = file.name;
            this.form.newFlieFlag = true;
        },
        setModelInput() {
            this.$refs.ModelInput.click();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.table-margin {
    margin-bottom: 10px;
}
.input-margin {
    margin: 10px 0;
}
</style>
