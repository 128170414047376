<!--
 * @Author: your name
 * @Date: 2022-11-22 15:44:12
 * @LastEditTime: 2022-11-23 09:18:59
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\treatmentPlan\patientList.vue
-->
<template>
    <el-container :style="{marginTop: 0}">
        <el-header height="auto">
            <searchForm ref="mainSearch" :backStatus="true" :searchoptions="searchOptions" @search="search" />
        </el-header>
        <el-main class="basic-main">
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                :newHeight="tableHeight - 60"
            >
                <template v-if="hasPermission('plan-detail')" slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">详情</el-button>
                </template>
            </generalTable>
        </el-main>
    </el-container>
</template>
<script>
import { getPatientList } from '@/api/treatmentPlan'
import searchForm from "@/components/SearchForm";
import generalTable from '@/components/Table'
import { getDept } from "@/api/patient"; //获取科室
import { getDict } from "@/api/common";

export default {
    name: 'AdvicePatient',
    components: {
        generalTable,
        searchForm
    },
    props: {
        selectFlag: {
            type: Number,
            required: true
        },
        tableHeight: {
            type: Number | String,
            required: true,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            activeName: 'first',
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: "select",
                    key: "status",
                    filterable: true,
                    label: "在院状态",
                    options: [],
                    defaultVal: "",
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'serialNum',
                    label: '就诊号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'recordNum',
                    label: '住院号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'bedNum',
                    label: '床号',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'heDte',
                    label: '入院日期',
                    defaultVal: []
                }
            ],
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'serialNum' // 必填
                    },
                    {
                        label: '住院号', // 必填
                        prop: 'recordNum' // 必填
                    },
                    {
                        label: '主治医生', // 必填
                        prop: 'impDocName' // 必填
                    },
                    {
                        label: '管床医生', // 必填
                        prop: 'bedDocName' // 必填
                    },
                    {
                        label: '床号', // 必填
                        prop: 'bedNum' // 必填
                    },
                    {
                        label: '入院日期', // 必填
                        prop: 'heDte', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.heDte).format('YYYY-MM-DD')
                        }
                    },
                    {
                        label: '计划数量', // 必填
                        prop: 'planNum', // 必填
                    },
                    {
                        label: '在院状态', // 必填
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            return ['出院', '入院', '门诊', '结束治疗'][row.status]
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            insertId: '', //新增医嘱/组套的患者id
            insertType: 1,
        }
    },
    created() {
        this.getDeptList();
        this.getPatientStatus();
        this.getList()
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = {
                ...this.paramObj,
                ...data
            }
            this.paramObj = Object.assign({}, this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1);
        },
        /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept({ deptType: "4" }).then(({ res, timeStamp }) => {
                this.deptOptions = res.map((item) => {
                    const ele = {
                        value: item.id,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions = this.searchOptions.map(item => {
                    if (item.key === "deptId") {
                        item.options = this.deptOptions
                    }
                    return item
                })
            });
        },
        /**
         * @description: 获取在院状态
         * @param {*}
         * @return {*}
         */
        getPatientStatus() {
            getDict({ type: "patient_inHos_status" }).then(({ res }) => {
                this.patientOptions = res.patient_inHos_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions = this.searchOptions.map(item => {
                    if (item.key === "status") {
                        item.options = this.patientOptions
                    }
                    return item
                })
            });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getPatientList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description:详情
         * @param {*}
         * @return {*}
         */
        toDetail(scope) {
            const id = scope.data.row.id
            this.$router.push({ path: '/treatment/treatmentPlan/planList', query: { id: id } })
        },
        getParam() {
            let page = this.$refs.mainTable.getPage()
            let paramObj = this.$refs.mainSearch.packageData();
            let param = Object.assign({}, page, { myPatient: this.selectFlag }, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>