
<template>
    <el-dialog
        width="70%"
        :title="insertType ? '新增报告分类管理' : '修改报告分类管理'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="detailData"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="上级分类" prop="parentId">
                        <el-cascader
                            v-model="detailData.parentId"
                            :options="levelTree"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类名称" prop="name">
                        <el-input
                            v-model="detailData.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="255"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="detailData.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="8"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-select
                            v-model="detailData.status"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in status"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="所属类型" prop="type">
                        <el-select
                            v-model="detailData.type"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in type"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="detailData.type==1">
                <el-col :sm="120" :md="120" :lg="80" :xl="60">
                    <el-form-item label="评定量表" prop="scale">
                        <el-tag
                            :key="scale.scaleInfoId"
                            v-for="scale in detailData.scales"
                            closable
                            @close="handleClose(scale)"
                        >
                            {{ scale.scaleInfoName }}
                        </el-tag>
                        <el-button
                            class="button-new-tag"
                            size="small"
                            @click="addScale"
                            >+ 添加</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :sm="120" :md="120" :lg="80" :xl="60">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="detailData.remark"
                            type="textarea"
                            resize="none"
                            maxlength="50"
                            :rows="4"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="cancelSuccess()">取 消</el-button>
        </span>
        <addScale :orgList="detailData.scales" ref="addScale" @submit="chooseScale" />
    </el-dialog>
</template>
<script>
import { insertData, editData, getScaleList } from "@/api/reportClassify";
import addScale from "@/views/scale/evaluationScheme/addScale";

export default {
    components: {
        addScale,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        tableData: Array,
        // eslint-disable-next-line vue/require-default-prop
        status: Array,
        type: Array,
        // eslint-disable-next-line vue/require-default-prop
        detailData: Object,
        insertType: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        timeStamp: String,
    },
    data() {
        return {
            checkId: "",
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入分类名称",
                        trigger: "blur",
                    },
                    { min: 3, message: "至少需要 3 个字符", trigger: "blur" },
                ],
            },
            levelTree: [],
            reportStatus: [],
            reportType: [],
            form: {
                checkbox: [],
            },
            total: 0,
            visible: false,
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if(this.detailData.id){
                    this.getScaleList();
                }
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    let parm = {}
                    if (typeof this.detailData.parentId === 'string') {
                        parm = this.detailData
                    } else {
                        parm = this.detailData
                        parm.parentId =
                            parm.parentId[parm.parentId.length - 1]
                    }
                    //判断类型，类型为报告 评定量表列应为空
                    if(this.detailData.type == '0'){
                        parm.scales = [];
                    }
                    if (!this.detailData.id) {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        cancelSuccess() {
            this.updateVisible(false);
            this.$emit("submit");
        },
        /**
         * 移除所有树结构中禁用的报告
         * @description: 使用status判断，status为“1”时，即为禁用的报告
         * @param {Array} orgData 原始数组结构
         * @param {Array} newData 过滤掉禁用报告之后的树结构
         * @return {*}
         */
        removeDisable(orgData, newData) {
            orgData.forEach((item) => {
                if (item.status !== "1") {
                    newData.push({ ...item, children: [] });
                }
                if (item.children) {
                    newData.length &&
                        this.removeDisable(
                            item.children,
                            newData[newData.length - 1].children
                        );
                }
            });
        },
        /**
         * 移除空的子节点
         * @description: 如果某个对象中，children的数组长度为0，则移除该层children；递归遍历查找每层children
         * @param {Array} orgData 原始树结构数组
         * @return {Array} 移除原始树结构中空children后的结果
         */
        removeEmptyChild(orgData) {
            orgData = orgData.map((item) => {
                let { children, ...temp } = item;
                if (children && children.length) {
                    return {
                        ...temp,
                        children: this.removeEmptyChild(children), // 递归遍历，把新的过滤好的children插入到原先的children中
                    };
                } else if (children) {
                    return temp;
                }
            });
            return orgData;
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，
         *      当模态框隐藏时，清空rowData表单中所有的内容
         *      当模态框显示时，移除掉原先树报告中禁用的报告
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
            } else {
                this.levelTree = []; // 新的树结构
                this.removeDisable(this.tableData, this.levelTree); // 移除原先禁用的报告
                this.levelTree = this.removeEmptyChild(this.levelTree); // 移除空的子节点
                this.detailData.scales = []; //清空量表选项
            }
        },

        getScaleList() {
            const parm = {
                id: this.detailData.id
            }
            getScaleList(parm).then(({ res }) => {
                this.detailData.scales = res;
                this.$forceUpdate()
            }); 
        },

        /**
         * @description: 跳转选择量表弹窗
         * @param {*}
         * @return {*}
         */
        addScale() {
            this.$refs.addScale.updateVisible(true);
        },
        /**
         * @description: 回调赋值
         * @param {*} data
         * @return {*}
         */
        chooseScale(data) {
            this.detailData.scales = data;
            this.$forceUpdate()
        },
        /**
         * @description: 删除选择量表
         * @param {*} tag
         * @return {*}
         */        
        handleClose(tag) {
            this.detailData.scales.splice(this.detailData.scales.indexOf(tag), 1);
            this.$forceUpdate()
        },
    },
};
</script>
<style scoped lang='scss'>
.el-tag {
    margin-right:10px;
}
.button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

</style>
      
        