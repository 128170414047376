
<template>
    <el-container class="doc-detail">
        <el-row class="page-header">
            <el-button :size="size" @click="back()">返 回</el-button>
        </el-row>
        <el-descriptions class="margin-top" title="我的文档详情" :column="3" :size="size">
            <el-descriptions-item label="名称">{{ detail.name|| commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ detail.status|| commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="分类">{{ detail.typeName|| commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="病种">{{ detail.diseases|| commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="患者">{{ detail.basicInfoName|| commonConfig.$nullData }}</el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <el-descriptions class="margin-top" title="文件附件" :column="3" :size="size">
            <el-descriptions-item v-for="(item) in detail.fileInterfaceList" :key="item.id" :label="item.fileType">
                <span class="file-name">{{`${item.name}.${item.suffix}`}}</span>
                <el-button @click.stop="downLoad(item)" type="primary" plain circle icon="el-icon-download"></el-button>
            </el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <div class="mdrcc-info-title">正文</div>
        <tinymce :disabled="true" :height="500" name="content" class="content" v-model="detail.content" id="tinymce"></tinymce>
    </el-container>
</template>
<script>
import { getDetail } from '@/api/myDocument'
import { getDict, downloadFile } from '@/api/common'
import Tinymce from '@/components/Tinymce' // 富文本插件
export default {
    components: {
        Tinymce
    },
    data() {
        return {
            size: '',
            detail: {}, // 详情数据
        }
    },
    /** 页面创建执行
     * @description: 获取文档信息
     * @param {*}
     * @return {*}
     */    
    created() {
        this.getDetail(this.$route.query.id)
    },
    mounted() {},
    methods: {
        /** 获取文档信息
         * @description: 将从后台获取的信息显示到页面中
         * @param {*} id
         * @return {*}
         */        
        getDetail(id) {
            getDetail({ id }).then(({ res }) => {
                const { 
                    fileId, // 文件id，逗号拼接
                    typeId, // 类型id，逗号拼接
                    status, // 状态 --> 0: '公开', 1: '私有'
                    fileInterfaceList, // 文件列表
                    ...info // 其余信息
                } = res
                // 标签信息
                const statusMap = {
                    0: '公开',
                    1: '私有'
                }
                this.$nextTick(() => {
                    this.detail = {
                        ...this.detail,
                        ...info,
                        status: statusMap[status], // 将状态码转换为标签显示内容
                        fileId: fileId ? fileId.split(',') : [], // 将文件id拆分成数组
                        typeId: typeId ? typeId.split(',') : [], // 将类型拆分成数组
                        fileInterfaceList: fileInterfaceList // 将扩展名对应文件类型放入到文件列表中
                            ? fileInterfaceList.map((item) => {
                                  return {
                                      ...item,
                                      fileType: this.processType(item.suffix)
                                  }
                              })
                            : []
                    }
                    // 显示病种库标签
                    this.getDiseaseLabel()
                })
            })
        },
        /**获取病种标签
         * @description: 获取病种库列表，根据this.detail.diseases信息筛选出对应ite目并显示item中的label
         * @param {*}
         * @return {*}
         */        
        getDiseaseLabel(){
            const type = 'disease_species'
            getDict({ type }).then(({ res }) => {
                const diseases = res[type].filter((item) => item.value === this.detail.diseases)
                if (diseases.length) {
                    this.detail.diseases = diseases[0].label
                }
            })
        },
        /** 返回上一页
         * @description: 路由返回
         * @param {*} 
         * @return {*}
         */        
        back() {
            this.$router.back()
        },
        /** 处理类型数据
         * @description: 根据this.commonConfig相应的后缀名显示对应的类型名
         * @param  {String} type 文件后缀名
         * @return {String} typeName 返回类型名称
         */
        processType(type) {
            const { $docTypes, $imgTypes, $audioTypes, $videoTypes } = this.commonConfig
            let typeName = ''
            if ($docTypes.includes(type.toLowerCase())) {
                typeName = '文本'
            } else if ($imgTypes.includes(type.toLowerCase())) {
                typeName = '图片'
            } else if ($audioTypes.includes(type.toLowerCase())) {
                typeName = '音频'
            } else if ($videoTypes.includes(type.toLowerCase())) {
                typeName = '视频'
            }
            return typeName
        },
        /** 文件下载
         * @description: 根据文件id获取下载的blob文件格式，将该格式文件下载到页面中
         * @param {*}
         * @return {*}
         */
        downLoad(row) {
            const parm = { id: row.id }
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement('a')
                dom.style.display = 'none'
                const blob = new Blob([res], {
                    type: row.contentType
                })
                const url = window.URL.createObjectURL(blob)
                dom.href = url
                // download后面必须设置为文件全名，即文件名+.+文件类型，否则当文件名中有.时，会导致文件类型出错
                dom.setAttribute('download', `${row.name}.${row.suffix}`)
                document.body.appendChild(dom)
                dom.click()
            })
        }
    }
}
</script>
<style scoped lang='scss'>
@import '~@/styles/variables.scss';
.page-header {
    height: auto;
    text-align: right;
}
.doc-detail {
    display: block;
    background-color: $menuText;
    overflow-y: auto;
}
.file-name {
    margin-right: 10px;
}
</style>
