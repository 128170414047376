<!--
 * @Author: ZF-WLY
 * @Date: 2021-03-23 09:50:35
 * @LastEditTime: 2021-11-02 11:05:23
 * @LastEditors: ZF-WLY
 * @Description: 机构详情弹出层
 * @FilePath: \recovery_management\src\views\system\organization\detailDialog.vue
-->

<template>
  <el-dialog
    width="1100px"
    title="查看"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="基本信息" :column="3">
      <el-descriptions-item label="机构名称">{{ detail.name || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="排序">{{ detail.sort || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="类型">{{ changeDeptType[detail.deptType] || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="上级机构">{{ detail.parentsLable || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{ detail.deptHeadsPhone || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{ detail.status === '0' ? '启用' : '禁用' }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ detail.createDate || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="备注" :span="4">{{ detail.remark || commonConfig.$nullData }}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tableData: Array, // 表格数据，用于显示父级机构层级
    // eslint-disable-next-line vue/require-default-prop
    changeDeptType: Array, // 机构类型，用于根据机构类型值显示对应机构类型名称
    // eslint-disable-next-line vue/require-default-prop
    detailData: Object // 机构信息
  },
  data() {
    return {
      visible: false, // 弹窗层可见性
      parentsLables: [], // 父级数组标签
      detail: {
        ...this.detailData,
        parentsLable: '' // 父级层级标签}
      }
    }
  },
  watch: {
    visible(newVal, oldVal) {
      // 监听模块显示状态，显示时，自动获取信息
      if (newVal) this.getDetail()
    }
  },
  mounted() {},
  methods: {
    /** 获取父级菜单层级标签
     * @description:
     *  根据传入的父级id数组以及菜单树数据，
     *  循环递归，生成父级菜单级联标签，用"/"分隔
     * @param {Array}parentIds 父级id数组
     * @param {Array}tableArray 菜单树数据
     * @return {*}
     */
    getParentLabels(parentIds, tableArray) {
      tableArray.forEach((item) => {
        if (parentIds.includes(item.id)) {
          this.parentsLables.push(item.name)
        }
        if (item.children) {
          this.getParentLabels(parentIds, item.children)
        }
      })
    },
    /**
     * 更新可见性
     * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空rowData表单中所有的内容
     * @param {Boolean} val true时显示；false时隐藏
     * @return {*}
     */
    updateVisible(val) {
      this.visible = val
    },
    /** 获取菜单详情
     * @description:
     *  获取菜单详情
     *  情况原有父级级联标签
     *  根据parentIds数组生成父级菜单级联标签， 以"/"分隔
     *  根据type字段及菜单列表，显示出对应的菜单类型名
     *  将"0"、"1"数据转换成对应的文字
     * @param {*}
     * @return {*}
     */
    getDetail() {
      this.detail = { ...this.detail, ...this.detailData }
      this.parentsLables = []
      // 根据parentIds数组生成父级菜单级联标签， 以"/"分隔
      this.getParentLabels(
        this.detail.parentIds
          .substring(0, this.detail.parentIds.length - 1)
          .split(','),
        this.tableData
      )
      this.detail.parentsLable = this.parentsLables.join('/')
    }
  }
}
</script>
