<!--
* @Description: 医治沟通页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <el-tabs type="border-card" style="width: 100%" v-model="tabValue">
                <el-tab-pane
                    :lazy="true"
                    name="1"
                    label="协同记录"
                ></el-tab-pane>
                <!-- 协同工作,入口为首页时，只保留“协同记录”部分 -->
                <el-tab-pane
                    v-if="$route.query.entry !== 'home'"
                    :lazy="true"
                    name="2"
                    label="发起历史"
                ></el-tab-pane>
                <el-tab-pane v-if="$route.query.entry !== 'home'" :lazy="true" name="0" label="全部"></el-tab-pane>
            </el-tabs>
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" @click="toInsert()">发起</el-button>
            </el-row>
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
            >
                <template slot="subject" slot-scope="scope">
                    <el-tooltip class="item" effect="light"  placement="top" :content="scope.data.row.subject">
                        <el-button @click="toDetail(scope)" type="text" >{{
                            scope.data.row.subject
                        }}</el-button>
                    </el-tooltip>
                </template>
                <template slot="status" slot-scope="scope">
                    <el-tag v-if="scope.data.row.status == '0'" type="warning"
                        >{{ scope.data.row.statusLabel }}
                    </el-tag>
                    <el-tag v-if="scope.data.row.status == '1'" type="success"
                        >{{ scope.data.row.statusLabel }}
                    </el-tag>
                    <el-tag v-if="scope.data.row.status == '2'"
                        >{{ scope.data.row.statusLabel }}
                    </el-tag>
                    <el-tag v-if="scope.data.row.status == '3'" type="danger"
                        >{{ scope.data.row.statusLabel }}
                    </el-tag>
                    <el-tag v-if="scope.data.row.status == '4'" type="info"
                        >{{ scope.data.row.statusLabel }}
                    </el-tag>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)" v-if="detailStatus"
                        >查看
                    </el-button>
                    <el-button
                        type="text"
                        @click="toPublish(scope)"
                        :disabled="!scope.data.row.publishable"
                        >发布
                    </el-button>
                    <el-button
                        type="text"
                        @click="toCooperative(scope)"
                        :disabled="!scope.data.row.suggestable"
                        >协同
                    </el-button>
                    <el-button
                        type="text"
                        @click="toEdit(scope)"
                        :disabled="!scope.data.row.editable"
                        >编辑
                    </el-button>
                    <el-button
                        type="text"
                        @click="toStart(scope)"
                        :disabled="!scope.data.row.starteable"
                        >开始
                    </el-button>
                    <el-button
                        type="text"
                        @click="toEnd(scope)"
                        :disabled="!scope.data.row.endable"
                        >结束
                    </el-button>
                    <el-button
                        type="text"
                        class="delBtn"
                        @click="toDel(scope)"
                        :disabled="!scope.data.row.deletable"
                        >删除
                    </el-button>
                </template>
            </generalTable>
            <addDialog
                :id="checkId"
                ref="insert"
                :timeStamp="timeStamp"
                @submit="search"
            />
        </el-main>
    </el-container>
</template>

<script>
import { deleteData, end, getList, publish, start } from "@/api/communication";
import { getDict } from "@/api/common";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/teamwork/communication/addDialog";

export default {
    name: "Communication",
    components: {
        searchForm,
        generalTable,
        addDialog,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入",
                    key: "subject",
                    label: "协同主题",
                    defaultVal: "",
                },
                {
                    type: "daterange",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    key: "publishTime",
                    label: "发布日期",
                    defaultVal: [],
                },
                {
                    type: "daterange",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    key: "usesTime",
                    label: "计划开始日期",
                    defaultVal: [],
                },
                {
                    type: "input",
                    placeholder: "请输入",
                    key: "patientName",
                    label: "患者姓名",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "status",
                    filterable: true,
                    label: "状态",
                    options: [],
                    defaultVal: '',
                },
            ],
            tableConfig: {
                id: "communication",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "协同类型", // 必填
                        prop: "typeLabel", // 必填
                    },
                    {
                        label: "协同主题", // 必填
                        slot: "subject", // 必填
                    },
                    {
                        label: "患者", // 必填
                        prop: "patientName", // 必填
                    },
                    {
                        label: "发起人", // 必填
                        prop: "userName", // 必填
                    },
                    {
                        label: "协同人员", // 必填
                        prop: "cooperativeUsersName", // 必填
                    },
                    {
                        label: "发布时间", // 必填
                        prop: "publishTime", // 必填
                        formatter: (row, column) => {
                            return row.publishTime
                                ? this.moment(row.publishTime).format(
                                      "YYYY-MM-DD HH:mm"
                                  )
                                : this.commonConfig.$nullData;
                        },
                    },
                    {
                        label: "计划开始时间", // 必填
                        prop: "usesTime", // 必填
                        formatter: (row, column) => {
                            return this.moment(row.usesTime).format(
                                "YYYY-MM-DD HH:mm"
                            );
                        },
                    },
                    {
                        label: "状态", // 必填
                        slot: "status", // 必填
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "280", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            tabValue: "1",
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
            detailStatus: true, //详情按钮权限
        };
    },
    created() {
        // 协同工作,入口为首页时，列表数据仅显示状态为进行中的记录
        this.searchOptions[this.searchOptions.length - 1].defaultVal = this.$route.query.entry === 'home' ? '2' : ''
        this.getList();
        this.getCommunicationStatus();
        this.detailStatus = this.hasPermission('communicationDetail')
    },
    mounted: function () {},
    watch: {
        tabValue(newVal, oldVal) {
            this.tabValue = newVal;
            this.getList();
        },
    },
    methods: {
        /**
         * @description: 获取协同状态
         * @param {*}
         * @return {*}
         */
        getCommunicationStatus() {
            getDict({ type: "cooperative_status" }).then(({ res }) => {
                this.patientOptions = res.cooperative_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions[4].options = this.patientOptions;
            });
        },
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.total;
                });
            });
        },
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        /**
         * @description: 查看
         * @param {*} scope
         * @return {*}
         */        
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$router.push({
                path: "/teamwork/communication/detail",
                query: {
                    id: scope.data.row.id,
                    archivesInfoId: scope.data.row.archivesInfoId,
                    basicInfoId:scope.data.row.basicInfoId
                },
            });
        },
        /**
         * @description: 协同
         * @param {*}
         * @return {*}
         */        
        toCooperative(scope) {
            this.checkId = scope.data.row.id;
            this.$router.push({
                path: "/teamwork/communication/detail",
                query: {
                    id: scope.data.row.id,
                    archivesInfoId: scope.data.row.archivesInfoId,
                    basicInfoId:scope.data.row.basicInfoId,
                    edit:true,
                },
            });
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.insert.updateVisible(true);
        },
        toPublish(scope) {
            publish({ id: scope.data.row.id }, this.timeStamp).then(
                ({ res }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess });
                    this.getList();
                }
            );
        },
        toStart(scope) {
            start({ id: scope.data.row.id }, this.timeStamp).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getList();
            });
        },
        toEnd(scope) {
            end({ id: scope.data.row.id }, this.timeStamp).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getList();
            });
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess });
                    this.getList();
                });
            });
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj, {
                tabFlag: this.tabValue,
            });
            this.paramObj = param;
            return param;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

