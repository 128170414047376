<!--
 * @Author: your name
 * @Date: 2022-01-17 11:42:36
 * @LastEditTime: 2022-02-25 15:08:04
 * @LastEditors: Please set LastEditors
 * @Description: 按配置汇总组件
 * @FilePath: \recovery_management\src\views\scale\summary\tableMode.vue
-->
<template>
    <el-row class="scale-html-wrapper" id="table-wrapper">
        <el-descriptions :column="5">
            <el-descriptions-item
                v-for="item in patientInfo"
                :key="item"
                :label="item.name"
                >{{ item.descriptions }}</el-descriptions-item
            >
        </el-descriptions>
        <div class="table-div" :style="{width: printFlag ? '890px' : '100%'}">
        <!-- 如果throughLineInfo不为空:量表名+基本信息+贯穿行+评估内容 -->
        <el-table
            v-if="throughLineInfo"
            :data="tableData"
            :style="tableStyle"
            :row-class-name="tableRowClassName"
            :header-cell-class-name="headerCellClassName"
        >
            <!-- 量表名 -->
            <el-table-column :label="scaleName" header-align="center">
                <!--基本信息-->
                <!-- <el-table-column :label="patInfo" header-align="center"> -->
                    <!-- 贯穿行信息 -->
                    <el-table-column :label="throughLineInfo" header-align="center">
                        <!-- 表格主体 -->
                        <el-table-column prop="label" label=" " width="300"></el-table-column>
                        <el-table-column
                            v-for="item in colArray"
                            :key="item.key"
                            :prop="item.key"
                            :label="item.label"
                        ></el-table-column>
                        <!-- 表格主体结束 -->
                    </el-table-column>
                <!-- </el-table-column> -->
            </el-table-column>
        </el-table>
        <!-- 如果throughLineInfo为空:量表名+基本信息+评估内容 -->
        <el-table
            v-else
            :data="tableData"
            :style="tableStyle"
            :row-class-name="tableRowClassName"
            :header-cell-class-name="headerCellClassName"
        >
            <!-- 量表名 -->
            <el-table-column :label="scaleName" header-align="center">
                <!--基本信息-->
                <el-table-column :label="patInfo" header-align="center">
                    <!-- 表格主体 -->
                    <el-table-column prop="label" label=" " width="300"></el-table-column>
                    <el-table-column
                        v-for="item in colArray"
                        :key="item.key"
                        :prop="item.key"
                        :label="item.label"
                    ></el-table-column>
                    <!-- 表格主体结束 -->
                </el-table-column>
            </el-table-column>
        </el-table>

        </div>
    </el-row>
</template>

<script>
import { getTreatmentInfo } from '@/api/patient'
import { printHtmlTable } from '@/utils/print'
import { tableBorder } from '@/config/tablePrintStyle' // 打印表格公共样式
const splitLen = 3
export default {
    name: 'assessSummaryTable',
    props: {
        // 患者的basicInfoId
        basicInfoId: {
            type: String
        },
        // 评估数据数组
        dataArray: {
            type: Array
        },
        // 汇总规则
        summaryRule: {
            type: Object
        },
        // 问题列表
        questionList: {
            type: Array
        },
        // 量表名
        scaleName: {
            type: String
        },
        patientInfo:{
            type: Array
        }

    },
    data() {
        return {
            tableStyle: { width: '100%', margin: '0 auto' }, // 表格样式
            tableData: [], // 表格数据
            colArray: [], // 表格评估数据列
            patInfo: '', // 患者信息
            throughLineInfo: '', // 贯穿行信息
            printFlag: false
        }
    },
    /** 页面创建
     * @description:
     *      将评估数据转换成要显示的表格数据
     *      获取患者信息及贯穿行信息
     *      生成表格评估数据列
     * @param {*}
     * @return {*}
     */
    created() {
        this.tableData = this.createTableArray(this.dataArray, this.summaryRule, splitLen)
        this.headInfo(this.summaryRule.patientInfo, this.summaryRule.throughLine)
        this.colArray = this.createColArray(splitLen, this.dataArray)
    },
    methods: {
        /** 创建评估表格列
         * @description: 按len长度生成表格评估列数据
         * @param {Number} len 每行要显示的评估次数
         * @param {Array} dataArray 数组，数据长度与len一致，
         *      每个元素格式为{key: `assessment+ 第几次评估`,label: `评估+第几次评估`}
         *      数组长度小于len，即评估数据不足一行时,搞过dataArray.length的部分，label为" ",必须有空格，不然页面会显示"--"
         * @return {*}
         */

        createColArray(len, dataArray) {
            len = len || 3
            let colArray = []
            for (let i = 0; i < len; i++) {
                colArray.push({
                    key: `assessment${i + 1}`, // assessment1, assessment2...
                    label: i >= dataArray.length ? ' ' : `评估${i + 1}` // 评估1, 评估2...
                })
            }
            return colArray
        },
        /** 获取标题信息
         * @description: 将throughKeys内的数据按name:data的形式进行拼接
         *      获取患者详细数据，根据basicKeys中要显示的字段进行显示，没有数据的部分用commonConfig.$nullData填充
         * @param {Array} basicKeys 基本信息关键字数组
         * @param {Array} throughKeys 贯穿行关键字数组
         * @return {*}
         */

        headInfo(basicKeys, throughKeys) {
            this.throughLineInfo = throughKeys.map(item => `${item.name}: ${item.data}`).join('\t')
            // getTreatmentInfo({ basicInfoId: this.basicInfoId }).then(({ res }) => {
            //     const { $nullData } = this.commonConfig
            //     this.patInfo = basicKeys
            //         .map(element => `${element.name}: ${res[element.key] ? res[element.key] : $nullData}`)
            //         .join('\t')
            // })
        },
        /** 生成标题行class名
         * @description: 
         *      如果throughLineInfo不为空，标题行共3行，分别为'scale-header', 'basic-header', 'through-header'
         *      如果throughLineInfo为空，标题行共2行，分别为'scale-header', 'basic-header'
         * @param {Number} rowIndex 当前为第几行(从0开始计数)
         * @return {*}
         */

        headerCellClassName({ rowIndex }) {
            const rowHeader = this.throughLineInfo
                ? ['scale-header', 'basic-header', 'through-header'] // 如果有throughLineInfo,3个标题行
                : ['scale-header', 'basic-header'] // 如果没有throughLineInfo,2个标题行
            return rowHeader[rowIndex] || '' // 返回的字符串即为对应的class名
        },
        /** 生成表格主体行class名
         * @description: 
         *      如果行数据中有rowClass关键字，取rowClass内容;
         *      如果没有，返回空字符串
         * @param {Object} row 当前行数据
         * @return {*}
         */        
        tableRowClassName({ row }) {
            if (row.rowClass) {
                return row.rowClass
            }
            return ''
        },
        /** 将评估数组数据转换成评估表格数据
         * @description: len为0或者不传时,取3
         *      根据len及评估数组数据生成表格
         *      第1列数据为对应summaryRule.rowData数组对象中的key或answerCoordinate,有哪个字段取哪个字段
         *      eg: len为3; summaryRule.rowData要显示的数据为:X1,X2; sourceArray长度为5
         *      tableData数据为:[{
         *          label: "X1",
         *          assessment1: 第1次评估X1结果,
         *          assessment2: 第2次评估X1结果,
         *          assessment3: 第3次评估X1结果
         *      },{
         *          label: "X2",
         *          assessment1: 第1次评估X2结果,
         *          assessment2: 第2次评估X2结果,
         *          assessment3: 第3次评估X2结果
         *      },{
         *          label: " ",
         *          rowClass: 'table-head', --> 这个字段用来显示一个类似于标题的表格行
         *          assessment1: "评估4",
         *          assessment2: "评估5",
         *          assessment3: " " --> 评估数据一共就5次,这一列整个数据都是空的
         *      },{
         *          label: "X1",
         *          assessment1: 第4次评估X1结果,
         *          assessment2: 第5次评估X1结果,
         *          assessment3: " " --> 评估数据一共就5次,这一列整个数据都是空的
         *      },{
         *          label: "X2",
         *          assessment1: 第4次评估X2结果,
         *          assessment2: 第5次评估X2结果,
         *          assessment3: " " --> 评估数据一共就5次,这一列整个数据都是空的
         *      },]
         *      
         * @param {Array} sourceArray 评估数组数据
         * @param {Object} summaryRule 汇总规则
         * @param {Number} len 每行显示的评估次数
         * @return {*}
         */        
        createTableArray(sourceArray, summaryRule, len) {
            len = len || 3 // len默认为3
            let tableData = [] // 表格数组数据
            for (let i = 0; i < sourceArray.length; i += len) {
                // 取出sourceArray第i~i+len项
                const resData = sourceArray.slice(i, i + len)
                const rowData = summaryRule.rowData.map((rowItem, rowIndex) => {
                    let obj = {}
                    // 内容行数据的label为遍历summaryRule.rowData某项中的key或answerCoordinate, 有哪个字段显示哪个字段
                    // obj.label = rowItem.key || rowItem.answerCoordinate
                       obj.label = rowItem.name 
                    // 遍历0~len-1, 
                    // 如果rowData[0~len-1]存在:    obj[assessment 1~len] = rowData[0~len-1][对应rowData索引]
                    // 如果rowData[0~len-1]不存在:  obj[assessment 1~len] = " "
                    for (let resIndex = 0; resIndex < len; resIndex++) {
                        obj[`assessment${resIndex + 1}`] = resData[resIndex] ? resData[resIndex][rowIndex] : ' '
                    }
                    return obj
                })
                // 将原来的tableData和rowData拼接
                tableData = [...tableData, ...rowData]
                // 在中间插入伪标题行
                if (i + len < sourceArray.length) {
                    let obj = { label: ' ', rowClass: 'table-head' }
                    for (let resIndex = 0; resIndex < len; resIndex++) {
                        // 如果超出了评估范围, 对应的内容显示为" "
                        obj[`assessment${resIndex + 1}`] = sourceArray[resIndex + len + i]
                            ? `评估${resIndex + len + i + 1}`
                            : ' '
                    }
                    tableData.push(obj)
                }
            }
            return tableData
        },
        /** 打印
         * @description: 按样式进行打印
         *      tableBorder为从公共配置中的带边框表格样式
         * @param {*}
         * @return {*}
         */        
        print() {
            this.printFlag = true
            // 表格样式
            let style = `
                ${tableBorder}
                .table-div {
                    width: 890px !important;
                }
                .scale-header {
                    font-size: 28px;
                }
                .basic-header {
                    font-size: 16px;
                }
                .through-header {
                    font-size: 14px;
                }
                .page-header {
                    height: auto !important;
                    text-align: right;
                    padding: 15px 20px;
                }
                .table-head td {
                    font-weight: 700;
                    text-align: center;
                }

            `
            this.$nextTick(() => {
                setTimeout(() => {
                    printHtmlTable('table-wrapper', style)
                    this.printFlag = false
                }, 10)
            })
        }
    }
}
</script>
<style lang="scss">
@import '~@/styles/variables.scss';
.table-width {
    width: 100%;
    // max-width: 890px;
}
.scale-header {
    font-size: 28px;
}
.basic-header {
    font-size: 16px;
}
.through-header {
    font-size: 14px;
}
.page-header {
    height: auto !important;
    text-align: right;
    padding: 15px 20px;
}
// 伪标题行样式
.table-head {
    background-color: $dialogHeader !important;
    color: $noticeDate;
    font-weight: 700;
}
</style>
