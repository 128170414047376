<!--
* @Description: 消息列表页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                @onClickRow="onClickRow"
            >
                <template slot="status" slot-scope="scope">
                    <div class="textBox">
                        <div v-if="scope.data.row.status == 1" class="stopText">
                            已读
                        </div>
                        <div
                            v-if="scope.data.row.status == 0"
                            class="enableText"
                        >
                            未读
                        </div>
                    </div>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toCheck(scope)"
                        >查看</el-button
                    >
                </template>
            </generalTable>
        </el-main>
    </el-container>
</template>

<script>
import { getList, updateStatus } from "@/api/message";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import commonConfig from "@/config/common";
// import { Model } from 'echarts';

export default {
    name: "Message",
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "messageType",
                    filterable: true,
                    multiple: false,
                    label: "消息类型",
                    options: [
                        {
                            value: "0",
                            label: "系统分配通知",
                        },
                        {
                            value: "1",
                            label: "治疗协同",
                        },
                        {
                            value: "2",
                            label: "分配协同",
                        },
                        {
                            value: "3",
                            label: "医嘱协同",
                        },
                        {
                            value: "4",
                            label: "出院通知",
                        },
                        {
                            value: "5",
                            label: "评估协同",
                        },
                        {
                            value: "6",
                            label: "结束治疗",
                        },
                        {
                            value: "7",
                            label: "医生分享治疗单",
                        },
                    ],
                    defaultVal: [],
                },
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "trueName",
                    label: "发布人",
                    defaultVal: "",
                },
                {
                    type: "datetimerange",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    key: "noticeDate",
                    label: "通知时间",
                    defaultVal: [],
                },
            ],
            tableConfig: {
                id: "message",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "消息类型", // 必填
                        prop: "messageType", // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                0: "系统分配通知",
                                1: "治疗协同",
                                2: "分配协同",
                                3: "医嘱协同",
                                4: "出院通知",
                                5: "评估协同",
                                6: "结束治疗",
                                7: "医生分享治疗单",
                            };
                            return stampContainer[row.messageType];
                        },
                        width: "200",
                    },
                    {
                        label: "提示内容", // 必填
                        prop: "content", // 必填
                    },
                    {
                        label: "发布人", // 必填
                        prop: "trueName", // 必填
                    },
                    {
                        label: "通知时间", // 必填
                        prop: "noticeDate", // 必填
                        formatter: (row, column) => {
                            return this.moment(row.noticeDate).format(
                                "YYYY-MM-DD HH:mm"
                            );
                        },
                        width: "200",
                    },
                    {
                        label: "消息状态", // 必填
                        slot: "status", // 必填
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "220", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
        };
    },
    created() {
        this.getList();
    },
    mounted: function () {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.pageData.records;
                    this.total = parseInt(res.pageData.total);
                    this.timeStamp = timeStamp;
                });
            });
        },

        toCheck(scope) {
            const para = { id: scope.data.row.id };
            updateStatus(para, this.timeStamp).then(({ res, timeStamp }) => {
                 switch (scope.data.row.messageType) {
                    case "0":
                        this.$router.push(`/treatment/myPatient`);
                        break;
                    case "1":
                        //治疗协同暂无或者已取消。
                        this.$router.push({
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(scope.data.row.paramJson).id,
                                archivesInfoId: JSON.parse(scope.data.row.paramJson).archivesInfoId,
                                basicInfoId:JSON.parse(scope.data.row.paramJson).basicInfoId,
                            },
                        });
                        break;
                    case "2":
                        //分配通知->我的在治患者
                        this.$router.push(`/treatment/myPatient`);
                        break;
                    case "3":
                        //协同详情页
                        this.$router.push({
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(scope.data.row.paramJson).id,
                            },
                        });
                        break;
                    case "4":
                        //出院通知->患者一览,患者详情
                        this.$router.push({
                            path: "/patient/list/detail",
                            query: { id: scope.data.row.paramJson },
                        });
                        break;
                    case "5":
                        this.$router.push({
                            //协同详情页
                            path: "/teamwork/communication/detail",
                            query: {
                                id: JSON.parse(scope.data.row.paramJson).id,
                            },
                        });
                        break;
                    case "6":
                        //结束治疗->患者一览,患者详情
                        this.$router.push({
                            path: "/patient/list/detail",
                            query: { id: scope.data.row.paramJson },
                        });
                        break;
                    case "7":
                        //医生分享治疗单 
                        this.$router.push({
                            path: "/treatment/patientTreatment/treatmentSingle",
                            query: { param: scope.data.row.paramJson },
                        });
                        break;
                    default:
                        console.error(item)
                        break;
                }
            });

           
        },
        onClickRow(row) {
            console.log("点击行:", row);
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj);
            this.paramObj = param;
            return param;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.textBox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.stopText {
    width: 40px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $tableColor;
    border-radius: 5px;
    background-color: $disable;
    color: $arrowActive;
}
.enableText {
    width: 40px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $theme;
    border-radius: 5px;
    background-color: $active;
    color: $theme;
    font-size: 12px;
}
</style>
