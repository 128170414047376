
<!--
* @Description: 分配管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="handle" slot-scope="scope" v-if="detailButton">
          <el-button type="text" @click="toDetail({userId:scope.data.row.userId})">详情</el-button>
        </template>
      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import { getList } from "@/api/distribuitionManagement";
import { getUserInfo } from "@/api/user";
import { getDict } from "@/api/common";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";

export default {
  name: "DistribuitionManagement",
  components: {
    searchForm,
    generalTable,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "cascader",
          placeholder: "机构名称",
          key: "deptId",
          label: "机构名称",
          options: [],
          defaultVal: [],
          props: {
              checkStrictly: true,
              label: "label",
              value: "id",
              emitPath: false,
              // multiple: true
          },
      },
        {
          type: "input",
          placeholder: "请输入",
          key: "userName",
          label: "姓名",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "position",
          filterable: true,
          label: "岗位",
          options: [],
          defaultVal: [],
        },
      ],

      tableConfig: {
        id: "distribuitionManagement",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "机构名称", // 必填
            prop: "deptName", // 必填
          },
          {
            label: "姓名", // 必填
            prop: "userName", // 必填
          },
          {
            label: "岗位", // 必填
            prop: "positionLabel", // 必填
          },
          {
            label: "在治患者", // 必填
            prop: "inCurePatientsCount", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      deptOptions: [], //机构
      posionOptions: [], //岗位
      detailButton:true,//详情权限
    };
  },
  created() {
    this.getDepartment();
    this.getDictList();
    this.getList();
    this.detailButton = this.hasPermission('distribuitionManagement-detail')
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    /**
     * @description:查询详情
     * @param {*} query
     * @return {*}
     */
    toDetail(query) {
      this.$router.push({path:"/patient/myPatient",query:query})
    },
    /**
       * @description: 获取机构
       * @param {*}
       * @return {*}
       */
      getDepartment() {
          const param = {};
          getUserInfo(param).then(({ res, timeStamp }) => {
              this.searchOptions[0].options = res.deptLevelTreeList;
          });
      },
    /**
     * @description: 获取岗位
     * @param {*} 字典表：position类型
     * @return {*}
     */
    getDictList() {
      getDict({ type: "position" }).then(({ res, timeStamp }) => {
        res.position.forEach((element) => {
          const ele = {
            value: element.value,
            label: element.label,
          };
          this.posionOptions.push(ele);
        });
        this.searchOptions[2].options = this.posionOptions;
      });
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    back() {
      this.$router.back();
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        