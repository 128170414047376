
<!--
* @Description: 今日已治疗页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <el-row class="table-btn-group">
            <el-button style="float:right;margin-top:15px" plain @click="back()">返回</el-button>
        </el-row>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :total="total" :config="tableConfig" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
            <el-button type="text" @click="toDetail(scope)">详情</el-button>
            </template>
        </generalTable>
        <detailDialog :id="checkId" ref="detail" />
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/treatedToday'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import detailDialog from "@/views/treatment/arrangeTreatment/detailDialog";

export default {
    name: 'TreatedToday',
    components: {
        searchForm,
        generalTable,
        detailDialog
    },
    props:{
        dateType: {
            type: String,
            default: 'today'
        }
    },
    data() {
        return {
            tableData: [],
            tableConfig: {
                id: 'treatedToday',
                pagination:true,
                index:true,
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'basicInfoName', // 必填
                    width: 160,
                },
                {
                    label: '住院号', // 必填
                    prop: 'recordNum', // 必填
                    width: 200,
                },
                {
                    label: '就诊号', // 必填
                    prop: 'serialNum', // 必填
                    width: 200,
                },
                {
                    label: '治疗师', // 必填
                    prop: 'therapyName', // 必填
                    width: 200,
                },
                {
                    label: '医嘱类型', // 必填
                    prop: 'doctorOrderTypeLabel', // 必填
                },
                {
                    label: '所属治疗', // 必填
                    prop: 'operTypeLabel', // 必填
                    width: 150,
                },
                {
                    label: '治疗项目', // 必填
                    prop: 'operName', // 必填
                    width: 200,
                },
                {
                    label: '治疗时间', // 必填
                    width: 200,
                    prop: 'treatmentDate', // 必填
                    formatter: (row, column) => {
                        return this.moment(row.treatmentDate).format(
                            'YYYY-MM-DD HH:mm'
                        )
                    }
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '120' // 不必填
                }
                ]
            },
            total: 0, // 总条数
            backStatus: true, //返回按钮的隐藏显示
            paramObj : {
                treatmentDateStartTime:'', // 治疗开始时间
                treatmentDateEndTime:'', // 治疗结束时间
                status: 0, //状态0 已治疗 1已作废
                allPatientFlag: 1, // 查询全部治疗患者/当前登录用户治疗患者 0 全部 1 当前登录用户
                operName: '', //操作项目
                basicInfoName: '', //患者姓名
                recordNum: '', //档案 住院号
                serialNum: '', //流水 就诊号
                therapyName: '', //治疗师姓名
                userId: '', //用户ID
                basicInfoId: '', //患者ID
            },
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
        }
    },
    created() {
        let startDate = '';
        let endDate = '';
        switch (this.$route.query.dateType) {
            case 'today':
                startDate = this.moment(this.moment().format("YYYY-MM-DD") + " 00:00:00").valueOf();
                endDate = this.moment(this.moment().format("YYYY-MM-DD") + " 23:59:59").valueOf();
                break;
            case 'week':
                startDate = this.moment(this.moment().weekday(1).format("YYYY-MM-DD") + " 00:00:00").valueOf();
                endDate = this.moment(this.moment().weekday(7).format("YYYY-MM-DD") + " 23:59:59").valueOf();
                break;
            default:
                startDate = '';
                endDate = '';
                break;
        }
        Object.assign(this.paramObj,{treatmentDateStartTime:startDate,treatmentDateEndTime:endDate})
        this.getList()
    },
    mounted: function() {},
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res
                    this.tableData = records
                    this.total = total
                })
            })
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        back() {
            this.$router.back();
        },
        // 获取参数
        getParam() {
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        