
<!--
* @Description: 表格筛选项配置页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="getList" :backStatus="backStatus"/>
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <addDialog :menuId="menuId" :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="getList" />
        </el-main>
    </el-container>
</template>

<script>
import { getMenuSearchList, deleteMenuSearchData } from '@/api/customCollocation'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/system/customCollocation/dispose/filterItems/addDialog'

export default {
    name: 'FilterItems',
    components: {
        searchForm,
        generalTable,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'fieldName',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'type',
                    label: '类型',
                    options: this.commonConfig.$typeOptions
                }
            ],
            tableConfig: {
                id: 'filterItems',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '字段名称', // 必填
                        prop: 'fieldName' // 必填
                    },
                    {
                        label: '默认值', // 必填
                        prop: 'defaultValue' // 必填
                    },
                    {
                        label: '排序', // 必填
                        prop: 'defaultSort' // 必填
                    },
                    {
                        label: '备注', // 必填
                        prop: 'remark' // 必填
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function() {},
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getMenuSearchList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteMenuSearchData(param,this.timeStamp).then(({res}) => {
                    this.$message($handleSuccess)
                })
            })
        },
        onClickRow(row){
            console.log('点击行:',row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.el-header{
    margin-bottom: 0 !important;
}
.el-divider--horizontal{
    margin-bottom: 0;
}
</style>

        