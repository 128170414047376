<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->

<template>
    <el-container>
        <el-dialog
            width="70%"
            :title="
                this.editFlag == 0
                    ? '新增角色'
                    : this.editFlag == 1
                    ? '修改角色'
                    : '修改权限'
            "
            :visible.sync="insertFlag"
            @close="closeDialog()"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <el-form
                ref="detailData"
                :model="detailData"
                label-position="right"
                label-width="120px"
                class="formBox"
                :rules="insertRules"
            >
                <div
                    v-if="editFlag == 1 || editFlag == 0"
                    class="mdrcc-info-title"
                >
                    基本信息
                </div>
                <el-row
                    v-if="editFlag == 1 || editFlag == 0"
                    :gutter="10"
                    type="flex"
                    justify="start"
                >
                    <el-col :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="角色名称" prop="name">
                            <el-input
                                clearable
                                maxlength="20"
                                placeholder="请输入..."
                                v-model="detailData.name"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="角色编码" prop="roleEncoding">
                            <el-input
                                clearable
                                maxlength="40"
                                placeholder="请输入..."
                                v-model="detailData.roleEncoding"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="角色类型" prop="permissionType">
                            <el-select
                                v-model="detailData.permissionType"
                                clearable
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in typeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item label="角色描述" prop="remark">
                            <el-input
                                v-model="detailData.remark"
                                type="textarea"
                                maxlength="200"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div
                    v-if="editFlag == 2 || editFlag == 0"
                    class="mdrcc-info-title"
                >
                    权限设置
                </div>
                <el-tabs type="border-card" v-if="editFlag != 1">
                    <el-tab-pane :lazy="true" label="PC">
                        <el-row>
                            <el-col :span="24">
                                <el-tree
                                    style="height: 250px; overflow-y: scroll"
                                    :data="menuData"
                                    :props="{
                                        children: 'children',
                                        hasChildren: 'hasChildren',
                                        label: 'name',
                                    }"
                                    show-checkbox
                                    default-expand-all
                                    :expand-on-click-node="false"
                                    @check="selectMenu"
                                    node-key="id"
                                    check-on-click-node
                                    :default-checked-keys="oldCheckedMenu"
                                    ref="tree"
                                >
                                </el-tree>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane :lazy="true" label="APP"> </el-tab-pane>
                    <el-tab-pane :lazy="true" label="数据权限">
                        <el-row>
                            <el-col
                                :span="24"
                                style="
                                    background-color: #ffffff;
                                    margin-top: 10px;
                                "
                            >
                                <el-form-item label="数据权限">
                                    <el-radio-group
                                        v-model="allFlag"
                                        @change="selectDeptOrSelf"
                                    >
                                        <el-radio label="0">全部数据</el-radio>
                                        <el-radio label="2">个人数据</el-radio>
                                        <el-radio label="3">本部门/科室数据</el-radio>
                                        <el-radio label="1">自定义</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col
                                :span="24"
                                v-if="allFlag == '1'"
                                style="background-color: #ffffff"
                            >
                                <el-form-item>
                                    <el-checkbox
                                        v-model="self"
                                        @change="selectDeptOrSelf"
                                        >个人数据</el-checkbox
                                    >
                                </el-form-item>
                            </el-col> -->
                            <!-- <el-col
                                :span="24"
                                v-if="allFlag == '1'"
                                style="background-color: #ffffff"
                            >
                                <el-form-item>
                                    <el-checkbox
                                        v-model="dept"
                                        @change="selectDeptOrSelf"
                                        >本部门/科室数据</el-checkbox
                                    >
                                </el-form-item>
                            </el-col> -->
                                  <el-col :span="12" v-if="allFlag == '1'">
                                <el-form-item label="自定义本部门/科室" label-width="125">
                                    <el-tree
                                        style="
                                            height: 250px;
                                            overflow-y: scroll;
                                        "
                                        :data="orgTableData"
                                        :props="{
                                            children: 'children',
                                            hasChildren: 'hasChildren',
                                            label: 'name',
                                        }"
                                        show-checkbox
                                        default-expand-all
                                        :expand-on-click-node="false"
                                        @check="departmentSelectOrg"
                                        node-key="id"
                                        check-on-click-node
                                        :default-checked-keys="departmentOldCheckedOrg"
                                        ref="departmentDataTree"
                                    >
                                    </el-tree>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="allFlag == '1'">
                                <el-form-item label="自定义会诊科室">
                                    <el-tree
                                        style="
                                            height: 250px;
                                            overflow-y: scroll;
                                        "
                                        :data="orgTableData"
                                        :props="{
                                            children: 'children',
                                            hasChildren: 'hasChildren',
                                            label: 'name',
                                        }"
                                        show-checkbox
                                        default-expand-all
                                        :expand-on-click-node="false"
                                        @check="consultationSelectOrg"
                                        node-key="id"
                                        check-on-click-node
                                        :default-checked-keys="consultationOldCheckedOrg"
                                        ref="consultationDataTree"
                                    >
                                    </el-tree>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit()">确 定</el-button>
                <el-button @click="changeInsertFlag">取 消</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>
<script>
import {
    roleInsert,
    roleMenu,
    roleEdit,
    rolePermissions,
    getRolePermissions,
} from "@/api/character";
export default {
    props: {
        detailData: Object,
        insertFlag: Boolean,
        // 0-新增 1-编辑 2-权限
        editFlag: Number,
        oldCheckedMenu: Array,
        timeStamp: String,
        menuData: {
            type: Array,
        },
        orgTableData: {
            type: Array,
        },
    },
    data() {
        return {
            insertRules: {
                name: [
                    {
                        required: true,
                        message: "请输入角色名称",
                        trigger: "blur",
                    },
                    { min: 3, message: "至少需要 3 个字符", trigger: "blur" },
                ],
                roleEncoding: [
                    {
                        required: true,
                        message: "请输入角色编码",
                        trigger: "blur",
                    },
                    { min: 3, message: "至少需要 3 个字符", trigger: "blur" },
                ],
                permissionType: [
                    {
                        required: true,
                        message: "请选择角色类型",
                        trigger: "blur",
                    },
                ],
            },
            typeOptions: [
                {
                    value: "1",
                    label: "功能权限",
                },
                {
                    value: "2",
                    label: "数据权限",
                },
            ],
            changeFlag: false,
            changeOrgFlag: false,
            allFlag: "",//0全部 1 自定义
            self: false, //0选中 1 未选
            dept: false, //0选中 1 未选
            // 自定义本部门/科室 接口回显
            departmentOldCheckedOrg:[],
            // 自定义会诊科室 接口回显
            consultationOldCheckedOrg:[],  
            //自定义会诊科室
            consultationCheckedOrg:[],    
             // 自定义本部门
            departmentCheckedOrg:[],
            // oldCheckedOrg: [],
            // checkedOrg:[],
        };
    },
    created() {
        console.log('--------------------%%--------------------')
        this.checkedMenu = this.oldCheckedMenu;
        if (this.editFlag == 2) {
            this.getRoleData();
        }
    },
    mounted() {},

    methods: {
        /**
         * @description: 获取选中数据权限 树
         * @param {*}
         * @return {*}
         */        
        getRoleData() {
            const parm = { roleId: this.detailData.id };
            getRolePermissions(parm).then(({ res }) => {
                if(res!==null){
                    if (res.selectDept !== "") {
                        this.departmentOldCheckedOrg = res.selectDept.split(",");
                        this.departmentCheckedOrg=res.selectDept.split(",");
                        // this.checkedOrg = res.selectDept.split(",");
                        }
                    if(res.selectMdtDept!== null){
                        this.consultationOldCheckedOrg=res.selectMdtDept.split(",");
                        this.consultationCheckedOrg=res.selectMdtDept.split(",");
                    }
                        this.allFlag = res.allFlag;
                        this.dept = res.dept == "0" ? true : false;
                        this.self = res.self == "0" ? true : false;
                }else{
                    this.allFlag ="";
                    this.dept=false;
                    this.self=false;
                }
            });
        },
        closeDialog() {
            this.$refs.detailData.resetFields();
            this.$refs.tree.setCheckedKeys([]);
            this.$refs.dataTree.setCheckedKeys([]);
            this.$emit("closeDialog");
        },
        changeInsertFlag() {
            this.$emit("changeInsertFlag");
            this.changeFlag = false;
            this.changeOrgFlag = false;
        },
        getTableData() {
            this.$emit("getTableData");
        },
        submit() {
            //新增角色
            this.$refs.detailData.validate((valid) => {
                if (valid) {
                    if (this.editFlag == 0) {
                        //新增角色
                        let parm = {};
                        parm = this.detailData;
                        parm.status = 0;
                        if(this.allFlag ==""){
                            this.allFlag = "1"
                        }
                        (parm.aclList = this.checkedMenu),
                            (parm.sysRolePermissions = {
                                allFlag: this.allFlag,
                                self: this.self == true ? "0" : "1",
                                dept: this.dept == true ? "0" : "1",
                                selectDept: this.departmentCheckedOrg.join(","),
                                selectMdtDept: this.consultationCheckedOrg.join(","),  
                            });
                        // console.log("新增===parm===", parm);
                        roleInsert(parm).then(({ res }) => {
                            this.$message({
                                ...this.commonConfig.$handleSuccess,
                            });
                            this.changeInsertFlag();
                            this.getTableData();
                        });
                    } else if (this.editFlag == 2) {
                        // this.changeOrgFlag 为true 表示编辑数据权限
                        // this.changeFlag 为true 表示编辑pc
                        if (this.changeFlag || this.changeOrgFlag) {
                            let parm = {
                                aclIdList: this.checkedMenu,
                                roleId: this.detailData.id,
                            };
                            // console.log("修改权限===parm===", parm);
                            if (this.changeFlag) {
                                roleMenu(parm, this.timeStamp).then(
                                    ({ res }) => {
                                        if (this.changeOrgFlag) {
                                            this.dataAccessFun();
                                        } else {
                                            this.$message({
                                                ...this.commonConfig
                                                    .$handleSuccess,
                                            });
                                            this.changeInsertFlag();
                                            this.getTableData();
                                        }
                                    }
                                );
                            } else {
                                // 在这里！修改 权限设置 这个方法里提交！！！！！
                                this.dataAccessFun();
                            }
                        } else {
                            // console.log("没有改变修改权限======");
                            this.changeInsertFlag();
                        }
                    } else if (this.editFlag == 1) {
                        //修改角色
                        let parm = {};
                        parm = this.detailData;
                        // console.log("修改角色===parm===", parm);
                        roleEdit(parm, this.timeStamp).then(({ res }) => {
                            this.$message({
                                ...this.commonConfig.$handleSuccess,
                            });
                            this.changeInsertFlag();
                            this.getTableData();
                        });
                    }
                }
            });
        },
        selectMenu(data, checkedData) {
            this.checkedMenu = checkedData.checkedKeys.concat(
                checkedData.halfCheckedKeys
            );
            this.changeFlag = true;
        },
        /**
         * @description: 选择树 点击
         * @param {*} data
         * @param {*} checkedData
         * @return {*}
         */
        consultationSelectOrg(data, checkedData){
            // 自定义会诊科室 所选数据
            this.consultationCheckedOrg = this.$refs.consultationDataTree.getCheckedKeys()
            this.changeOrgFlag = true;
        },
        departmentSelectOrg(data, checkedData){
            // 自定义本部门/科室 所选数据
            this.departmentCheckedOrg = this.$refs.departmentDataTree.getCheckedKeys()
            this.changeOrgFlag = true;
        },
        // selectOrg(data, checkedData) {
        //     // this.checkedOrg = checkedData.checkedKeys.concat(
        //     //     checkedData.halfCheckedKeys
        //     // );
        //     this.checkedOrg = this.$refs.dataTree.getCheckedKeys()
        //     this.changeOrgFlag = true;
        // },
        selectDeptOrSelf() {
            if(this.allFlag == '2'){
                this.self=true
                this.dept=false
            }else if(this.allFlag == '3'){
                this.dept=true
                this.self=false
            }else{
                this.dept=false
                this.self=false
            }
            // 验证两个 变量是否为单一true
            // console.log(this.self,'this.self=true')
            // console.log(this.dept,'this.self=true')
            this.changeOrgFlag = true;
        },
        //数据权限封装方法
        dataAccessFun() {
            let param = {
                allFlag: this.allFlag,
                self: this.self == true ? "0" : "1",
                dept: this.dept == true ? "0" : "1",
                selectDept: this.departmentCheckedOrg.join(","),
                selectMdtDept: this.consultationCheckedOrg.join(","),    
                roleId: this.detailData.id,
            };
            rolePermissions(param, this.timeStamp).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.changeInsertFlag();
                this.getTableData();
            });
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";

.el-row {
    background-color: white;
}
</style>