<!--
 * @Author: Richardlv
 * @Date: 2022-01-10 13:54:32
 * @LastEditTime: 2022-08-23 09:02:51
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\patientScaleProgram.vue
-->
<template>
    <el-container>
        <el-main class="basic-main">
            <el-row>
                <el-col :sm="4" :md="4" :lg="3" :xl="3" v-if="scaleType != 3">
                    <el-button
                        size="small"
                        type="primary"
                        plain
                        @click="upload"
                        v-if="!analysis"
                    >
                        <i class="el-icon-plus"></i>
                        选择文件
                    </el-button>
                    <input
                        v-if="fileInput"
                        style="display: none"
                        type="file"
                        accept="image/*,video/*,audio/*"
                        @change="changeImageUrl($event)"
                        ref="uploadInput"
                    />
                    <div v-for="(item, index) in fileList" :key="item.id">
                        <div class="fileItem">
                            <el-image
                                v-if="item.type.indexOf('image') > -1"
                                style="width: 75px; height: 75px"
                                :preview-src-list="[
                                    commonConfig.$fileUrl + item.id,
                                ]"
                                :src="commonConfig.$fileUrl + item.id"
                                fit="contain"
                            />
                            <svg-icon
                                v-if="item.type.indexOf('video') > -1"
                                icon-class="video"
                                :style="{
                                    color: '#469cf8',
                                    'font-size': '70px',
                                }"
                            />
                            <svg-icon
                                v-if="item.type.indexOf('audio') > -1"
                                icon-class="audio"
                                :style="{
                                    color: '#469cf8',
                                    'font-size': '70px',
                                }"
                            />
                            <div class="btnBox">
                                <el-button
                                    size="small"
                                    type="text"
                                    class="delBtn"
                                    @click="delFile(index)"
                                >
                                    删除
                                </el-button>
                                <el-button
                                    size="small"
                                    type="text"
                                    style="margin-left: 0px"
                                    @click="downLoad(item)"
                                >
                                    下载
                                </el-button>
                            </div>
                        </div>
                        <div class="fileText">{{ item.name }}</div>
                    </div>
                </el-col>
                <el-col :sm="20" :md="20" :lg="21" :xl="21">
                    <el-row type="flex" justify="space-between">
                        <div style="display: flex">
                            <el-button
                                v-if="scaleType == 2 && status != 2"
                                type="primary"
                                @click="submit(1)"
                            >
                                保存
                            </el-button>
                            <el-button
                                v-if="scaleType == 2"
                                type="primary"
                                @click="submit(2)"
                                >提交</el-button
                            >
                            <el-button
                                v-if="showtype !== '1' && scaleType != 2"
                                type="primary"
                                @click="printHtml()"
                            >
                                打印
                            </el-button>
                        </div>
                        <el-button v-if="scaleType != 4" @click="back()"
                            >返回</el-button
                        >
                    </el-row>
                    <el-row :gutter="20" style="margin-top:20px">
                        <!-- <el-col :span="6"> -->
                        <div class="slider-menu">
                            <el-tree
                                ref="tree"
                                class="user-tree"
                                node-key="scaleInfoId"
                                :data="scaleList"
                                default-expand-all
                                :filter-node-method="filterTree"
                                :expand-on-click-node="false"
                                :highlight-current="true"
                                @node-click="onClickTreeRow"
                            >
                                <span
                                    class="el-tree-node__label"
                                    slot-scope="{ data }"
                                >
                                    <span :title="data.scaleInfoName">
                                        {{ data.scaleInfoName }}
                                    </span>
                                </span>
                            </el-tree>
                        </div>

                        <div class="slider-info" @scroll="scrollEvent" :style="preview ? {maxHeight: '60vh'}: {}">
                            <div id="scaleListBox">
                                <div
                                    v-for="(item, index) in scaleList"
                                    :key="item.scalePatientId"
                                    :label="item.scaleInfoName"
                                    class="pat-scale-pane"
                                >
                                    <patientScale
                                        ref="patient-scale"
                                        :key="item.scaleInfoId"
                                        :scaleId="item.scaleInfoId"
                                        libFlag="privateLib"
                                        :flag="$route.query.flag"
                                        showMould="mouldCode"
                                        patientBasicInfoId=""
                                        :patientScaleId="item.scalePatientId"
                                        :analysis="analysis"
                                        :program="true"
                                        :showHeader="index == 0 ? true : false"
                                    />
                                </div>
                            </div>
                        </div>
                    </el-row>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import scaleIndex from "@/views/scale/scale/index.vue";
import questionnaires from "@/views/scale/scale/Questionnaires.vue";
import patientScale from "@/views/scale/patientAssessment/patientScale";
import {
    getProgramScaleList,
    answerProgram,
    calculate,
    savePatientAnswer,
} from "@/api/patientAssessment";
import { uploadFile, downloadFile } from "@/api/common";
import { printHtml } from "@/utils/print";
// 0 模板 1问卷
const typeMap = {
    0: "scaleIndex",
    1: "questionnaires",
};
export default {
    name: "patientScaleProgram",
    components: {
        scaleIndex,
        questionnaires,
        patientScale,
    },
    props: {
        patientScaleId: {
            type: String,
        },
        flag: {
            type: String,
        },
        evaluationSchemeId: {
            type: String,
            required: false,
        },
        scaleIdList: {
            type: Array,
            required: false,
        },
        analysis: {
            type: Boolean,
            required: false,
        },
        preview: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        $route(newVal, oldVal) {
            this.scaleType = this.$route.query.flag;
        },
        flag(newVal, oldVal) {
            this.scaleType = this.flag;
        },
        scaleIdList(newVal, oldVal) {
            newVal && this.showViewProgram();
        },
        patientScaleId(newVal, oldVal) {
            this.id = this.patientScaleId;
        },
        /** 监听量表索引
         * @description:
         *      如果索引为0，代表循环提交尚未开始，不做任何动作
         *      如果索引与scaleIndex插件长度一致，代表循环结束，将索引置0
         *      其余情况，代表循环进行中，进行循环提交
         * @param {*} newVal
         * @param {*} oldVal
         * @return {*}
         */

        scaleSubmitIndex(newVal, oldVal) {
            if (!newVal) {
                return;
            }
            if (this.scaleSubmitIndex === this.$refs["patient-scale"].length) {
                this.scaleSubmitIndex = 0;
                this.$message({
                    message: this.submitType === 1 ? "保存成功" : "提交成功",
                    type: "success",
                });
                if (this.submitType === 1) {
                    this.scaleSubmitIndex = 0;
                    this.getProgramScaleList();
                } else {
                    this.back();
                }
                return;
            }
            if (newVal && newVal < this.$refs["patient-scale"].length) {
                this.scaleSubmit(this.scaleSubmitIndex, this.submitType);
            }
        },

    },
    data() {
        return {
            showFlag: false,
            id: "",
            showtype: "0", // 0 模板 1问卷
            scaleSubmitIndex: 0,
            form: {
                evaluateByName: "",
                evaluateDate: new Date().getTime(),
            },
            rules: {
                evaluateByName: [
                    {
                        required: true,
                        message: "请输入评估人",
                        trigger: "blur",
                    },
                ],
                evaluateDate: [
                    {
                        required: true,
                        message: "请选择评估时间",
                        trigger: "change",
                    },
                ],
            },
            marksRule: "",
            resultsRule: "",
            scaleType: "",
            fileList: [],
            fileInput: true,
            status: "0",
            scaleList: [],

            timeStamp: "",
            submitType: "",
            catalogCur: 0,
            scroll: "",
        };
    },
    created() {
        this.showFlag = true;
        if (this.analysis) {
            this.showViewProgram();
            return;
        }
        this.id = this.$route.query.patientScaleId;
        this.scaleType = this.$route.query.flag;
        this.$store.dispatch("getHosInfo").then((res) => {
            this.showtype = res.scaleShowType;
        });
        this.getProgramScaleList();
    },
    mounted: function () {
        window.addEventListener("scroll", this.dataScroll, true);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.dataScroll, true);
    },
    methods: {
        showViewProgram() {
            this.$store.dispatch("getHosInfo").then((res) => {
                this.showtype = res.scaleShowType;
                this.showFlag = true;
                this.scaleType = "4";
                this.scaleList = [...this.scaleIdList];
                console.log(
                    "------scaleIdList------",
                    this.showFlag,
                    this.showtype,
                    this.scaleIdList
                );
            });
        },
        /**
         * @description: 获取方案量表列表/文件列表
         * @param {*}
         * @return {*}
         */
        getProgramScaleList() {
            if (this.preview) {
                this.scaleList = [];
            }
            getProgramScaleList({ id: this.id }).then(({ res, timeStamp }) => {
                this.scaleList = res.scaleList;
                this.timeStamp = timeStamp;
                this.fileList = res.fileList.map((item, index) => {
                    return {
                        name: item.name,
                        id: item.id,
                        type: item.contentType,
                    };
                });
            });
        },
        printHtml() {
            printHtml("scaleListBox");
        },
        downLoad(row) {
            const parm = { id: row.id };
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement("a");
                dom.style.display = "none";
                const blob = new Blob([res], {
                    type: row.type,
                });
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                dom.setAttribute("download", row.name);
                document.body.appendChild(dom);
                dom.click();
            });
        },
        delFile(index) {
            this.fileList.splice(index, 1);
        },
        upload() {
            this.$refs.uploadInput.click();
        },
        /**
         * @description: 设置从scaleIndex传回的评估人/评估时间
         * @param {*}
         * @return {*}
         */
        setevaluate(evaluateByName, evaluateDate, status, fileList) {
            this.form.evaluateByName = evaluateByName;
            this.form.evaluateDate = evaluateDate;
            this.status = status;
        },
        changeImageUrl(event) {
            this.fileInput = false;
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let formData = new FormData();
            formData.append("file", file, file.name);
            uploadFile(formData).then(({ res }) => {
                this.fileInput = true;
                console.log(file);
                this.fileList.push({
                    id: res,
                    name: file.name,
                    type: file.type,
                });
            });
        },
        back() {
            this.showFlag = false;
            setTimeout(() => {
                this.$router.back();
            }, 50);
        },
        /**
         * @description: 提交之前检查评估人和评估时间,然后提交方案的文件
         * @param {*}
         * @return {*}
         */
        submit(type) {
            this.submitType = type;
            this.saveProgram(type);
        },
        /** 保存/提交方案信息
         * @description: 方案信息保存/提交成功后，保存/提交第一张量表
         * @param {*} type
         * @return {*}
         */

        saveProgram(type) {
            let params = {
                fileList: this.fileList.map((item) => {
                    return item.id;
                }),
                id: this.id,
                status: type,
            };
            answerProgram(params, this.timeStamp).then(({ res }) => {
                // 0 模板 1问卷
                this.scaleSubmit(this.scaleSubmitIndex, type);
            });
        },
        scaleSubmit(scaleSubmitIndex, type) {
            console.log("------scale submit------", scaleSubmitIndex, type);
            this.$refs["patient-scale"][scaleSubmitIndex].submit(
                type,
                // 该量表提交/保存成功后,进入下一个量表提交
                () => {
                    this.scaleSubmitIndex = scaleSubmitIndex + 1;
                },
                // 该量表提交/保存失败后,回到第一个量表提交,重新获取量表信息
                () => {
                    const subMap = {1: "保存", 2: "提交"}
                    console.log(`-------${subMap[type]}失败`, this.scaleSubmitIndex)
                    this.scaleSubmitIndex = 0;
                    this.getProgramScaleList();
                }
            );
        },

        // 通过名称过滤树结构
        filterTree(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        /**
         * @description: 左侧树结构点击事件
         * @param {*}
         * @return {*}
         */
        onClickTreeRow(obj) {
            let checkIndex = this.scaleList.findIndex((value) => value == obj);
            // this.jump(checkIndex)
            this.catalogCur = checkIndex;
            let jump = document.querySelectorAll(".pat-scale-pane");
            jump[checkIndex].scrollIntoView({
                block: "start",
                behavior: "smooth",
            });
        },

        /**
         * @description: 监听滚动
         * @param {*}
         * @return {*}
         */
        scrollEvent(e) {
            console.log("scrollEvent==", e);
            let scrollItems = document.querySelectorAll(".pat-scale-pane");
            for (let i = scrollItems.length - 1; i >= 0; i--) {
                // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
                let judge =
                    e.target.scrollTop >=
                    scrollItems[i].offsetTop - scrollItems[0].offsetTop;
                if (judge) {
                    this.catalogCur = i;
                    break;
                }
            }
            //滚动条触底了
            if (
                e.srcElement.scrollTop + e.srcElement.offsetHeight ==
                e.srcElement.scrollHeight
            ) {
                this.catalogCur = this.articleName.length - 1;
            }
            //console.log(e.srcElement.scrollTop)//滚动条高度
            //console.log(e.srcElement.offsetHeight)//屏幕高度
            //console.log(e.srcElement.scrollHeight)//内容高度
            //'下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
        },
        /**
         * @description:监听滚动 
         * @param {*} e
         * @return {*}
         */
        dataScroll(e) {
            let scrollItems = document.querySelectorAll(".pat-scale-pane");
            for (let i = scrollItems.length - 1; i >= 0; i--) {
                // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
                let judge =
                    e.target.scrollTop >=
                    scrollItems[i].offsetTop - scrollItems[0].offsetTop;
                if (judge) {
                    this.catalogCur = i;
                    this.$refs.tree.setCurrentKey(this.scaleList[i].scaleInfoId);
                    break;
                }
            }
            //滚动条触底了
            if (
                e.srcElement.scrollTop + e.srcElement.offsetHeight ==
                e.srcElement.scrollHeight
            ) {
                this.catalogCur = this.scaleList.length - 1;
                this.$refs.tree.setCurrentKey(this.scaleList[this.scaleList.length - 1].scaleInfoId);
            }

        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.fileItem {
    display: flex;
    align-items: center;
}
.btnBox {
    width: min-content;
}
.fileText {
    font-size: 14px;
    color: #333333;
}
.pat-scale-pane {
    // height: 540px;
    overflow: auto;
    margin-top: -30px;
}

.slider-menu {
    position: fixed;
    left: auto;
    width: 230px;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    height: 70%;
}

.slider-info {
    margin-left: 280px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 192px);
    overflow-y: auto;
}
</style>
