<!--
 * @Author: your name
 * @Date: 2022-11-24 09:59:06
 * @LastEditTime: 2022-11-25 13:43:29
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\summary.vue
-->
<template>
    <el-main class="wrapper">
        <div class="summary">
            <el-timeline :reverse="reverse">
                <el-timeline-item
                    v-for="summary in dataArray"
                    :key="summary.id"
                    :timestamp="moment(summary.createDate).format('YYYY-MM-DD')"
                    placement="top"
                >
                    <!-- {{ activity.content }} -->
                    <div class="summary-line">
                        <div class="summary-type">
                            <div>
                                {{ summary.conclusionTypeLabel }}：
                            </div>
                            <div class="summary-btn-group">
                                <i class="el-icon-view" @click="toDetail(summary.id)"></i>
                            </div>
                        </div>

                        <div class="summary-content">
                            {{ summary.conclusionContent }}
                        </div>
                    </div>
                </el-timeline-item>
            </el-timeline>
        </div>
        <detail-dialog ref="detail" :id="checkedId" :basicInfoId="basicInfoId" @close="openPat360()"/>
    </el-main>
</template>
<script>
import { getList } from '@/api/patientTreatmentConclusion'
import detailDialog from '@/views/patient/summary/detailDialog'
export default {
    components: {
        detailDialog
    },
    props: {
        basicInfoId: String,
        dataArray: Array
    },
    data() {
        return {
            reverse: false,
            checkedId: '',
            summaryList: [],
            timeStamp: ''
        }
    },
    created() {
        // this.getList()
    },
    methods: {
        getList() {
            getList({ basicInfoId: this.basicInfoId }).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                this.summaryList = [...res]
            })
        },
        toDetail(id){
            this.checkedId = id
            this.$emit("close");
            this.$refs.detail.updateVisible(true)
        },
        /** 显示患者360组件
         * @description: 触发父组件`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        openPat360(){
            this.$emit("close")
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.wrapper {
    overflow-y: auto;
    height: 100%;
    padding: 15px 0;
}
.summary {
    height: calc(100% - 46px);
    width: 100%;
    &-line {
        background-color: $background;
        width: calc(100% - 40px);
        padding: 15px;
        border-radius: 8px;
    }
    &-type {
        font-size: 17px;
        color: $titleColor;
        margin-bottom: 0.5em;
        display: flex;
        justify-content: space-between;
    }
    &-content {
        font-size: 13px;
        color: $titleColor;
    }
    &-btn-group {
        color: #469cf8;
        i {
            margin-right: 10px;
            cursor: pointer;
            font-size: 18px;
        }
    } 
}
</style>
