/*
 * @Author: your name
 * @Date: 2021-11-18 10:45:34
 * @LastEditTime: 2021-11-18 16:32:11
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\distribuitionManagement.js
 */

/**
 * 页面: 分配管理
 * pageName: distribuitionManagement
 **/
import request from '@/utils/request'

/**
 * @description: 获取分配管理列表数据
 */
export function getList(data) {
    return request({
        url: '/patientUserRel/distribuitionManagementList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 删除分配管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        