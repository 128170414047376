/**
 * 页面: 科研数据管理
 * pageName: scientificData
 **/
import request from '@/utils/request'

/**
 * @description: 获取科研数据管理列表数据
 */
export function getList(data) {
  return request({
    url: 'srPatient/get',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 获取科研数据管理列表数据(高级查询)
 */
export function getByParamId(data) {
  return request({
    url: 'srPatient/getByParamId',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 获取科研数据管理列表数据
 */
export function getOptions() {
  return request({
    url: 'srPatient/getOptions',
    method: 'GET',
    showLoading: true
  })
}

/**
 * @description: 新增科研数据高级查询参数
 */
export function insertData(data) {
  return request({
    url: 'srPatient/saveParam',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 修改科研数据高级查询参数
 */
export function editData(data, timeStamp) {
  return request({
    url: 'srPatient/saveParam',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 获取科研数据管理详情数据
 */
export function getDetail(data) {
  return request({
    url: 'srPatient/getSeniorParamById',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 获取科研数据管理高级查询参数
 */
export function getSeniorParams() {
  return request({
    url: 'srPatient/getSeniorParams',
    method: 'GET',
    showLoading: true
  })
}

/**
 * @description: 删除科研数据管理高级查询参数
 */
export function delParam(data, timeStamp) {
  return request({
    url: 'srPatient/removeSeniorParamById',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 导出
 */
export function createTask(data, timeStamp) {
  return request({
    url: 'scientificResearchTask/create',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
