
<template>
  <el-dialog
    width="70%"
    class="dialog"
    :title="id === '' ? '新增操作项目' : '修改操作项目'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      style="max-height: calc(100vh - 200px);"
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              clearable
              maxlength="20"
              @blur="setNamePinyinHead"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="所属治疗" prop="operType">
            <el-select
              v-model="form.operType"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="operType in operTypes"
                :key="operType.value"
                :label="operType.label"
                :value="operType.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="医嘱类型" prop="doctorOrderType">
            <el-select
              v-model="form.doctorOrderType"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="doctorOrderType in doctorOrderTypes"
                :key="doctorOrderType.value"
                :label="doctorOrderType.label"
                :value="doctorOrderType.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="是否生效" prop="charge">
            <el-select
              v-model="form.charge"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="charge in charges"
                :key="charge.value"
                :label="charge.label"
                :value="charge.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="操作时间时间片" prop="times">
            <el-select
              v-model="form.times"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="times in timess"
                :key="times.value"
                :label="times.label"
                :value="times.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="名称简称" prop="namePinyinHead" v-show=false>
            <el-input
              v-model="form.namePinyinHead"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <!-- doctorOrder -->
        <el-col :sm="120" :md="120" :lg="80" :xl="60">
          <el-form-item label="嘱托" prop="ruleOperOrderS">
            <el-button
              class="doctor-order-add"
              type="primary"
              icon="el-icon-plus"
              @click="addDoctorOrder"
              >添加</el-button
            >
            <div
              v-for="item in form.ruleOperOrderS"
              :key="item.id"
              class="doctor-order-div"
            >
              <el-input
                class="doctor-order-input"
                v-model="item.doctorOrder"
                type="textarea"
                resize="none"
                maxlength="50"
                :rows="3"
              />
              <el-button
                class="doctor-order-button"
                icon="el-icon-delete"
                circle
                @click.stop="removeDoctorOrder(item.id)"
                :autofocus="false"
              ></el-button>
            </div>
          </el-form-item>
        </el-col>
        <el-col :sm="120" :md="120" :lg="80" :xl="60">
          <el-form-item label="备注" prop="input">
            <el-input
              v-model="form.remark"
              type="textarea"
              resize="none"
              :rows="4"
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="mdrcc-info-title">关联收费项目</div>
      <el-row :gutter="10">
        <div class="table-btn-group">
          <el-button type="primary" @click="toggleModal()">新增</el-button>
        </div>
        <generalTable
          ref="mainTable"
          :table-data="tableData"
          :config="tableConfig"
          :total="total"
          :newHeight="'200'" 
        >
          <template slot="createCount" slot-scope="scope">
            <el-input
              v-model="scope.data.row.createCount"
              maxlength="2"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              @change="countChange(scope)"
            >
              <template slot="prepend">
                <el-button icon="el-icon-minus" @click="minus(scope)"></el-button>
              </template>
              <template slot="append">
                <el-button icon="el-icon-plus" @click="plus(scope)"></el-button>
              </template>
            </el-input>
          </template>
          <template slot="handle" slot-scope="scope">
            <el-button type="text" class="delBtn" @click="toDel(scope)"
              >删除</el-button
            >
          </template>
        </generalTable>
        <addOperDialog :orgList="tableData" ref="addOper" @submit="add" />
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail, getOrderList, getFeeItemsList } from "@/api/operation";
import addOperDialog from '@/views/businessRules/operation/addOperDialog'
import generalTable from "@/components/Table";
import moment from "moment";
import { CH2CYTPALL } from '@/utils/common'
import { getDict } from '@/api/common'

export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  components: {
    generalTable,
    addOperDialog
  },
  // 数据更新生命周期函数
  updated() {},
  data() {
    return {
      checkId: "",
      tableData: [
      ],
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label: "收费项目名称", // 必填
            prop: "name"
          },
          {
            label: "单次收费金额", // 必填
            prop: "price", // 必填
          },
          {
            label: "医保级别", // 必填
            prop: "level", // 必填
          },
          {
            label: "自付百分比", // 必填
            prop: "percent", // 必填
          },
          {
            label: "物价标准编码", // 必填
            prop: "standCode", // 必填
          },
          {
            label: "次数", // 必填
            slot: 'createCount', // 必填
            width: "180", // 不必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right",
            width: "100", // 不必填
          },
        ],
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
        operType: [
          { required: true, message: "请选择所属治疗", trigger: "blur" },
        ],
      },
      operTypes: [],
      doctorOrderTypes: [],
      charges: [],
      timess: [],
      form: {
        checkbox: [],
        ruleOperOrderS: [
          {
            doctorOrder: "",
            id: moment().valueOf(),
          },
        ],
        namePinyinHead: '',
        operType:"",
        doctorOrderType:"1",
        charge:"1",
        times:"10",
        ruleOperChargeS: []
        // tableData:[]
      },
      total: 0,
      visible: false,
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.id && this.getDetail();
        if (!this.id) {
          this.form = {
            ...this.form,
            doctorOrderType:"1",
            charge:"1",
            times:"10",
          }
        }
        //...todo
      } else {
        this.form = {
          checkbox: [],
          ruleOperOrderS: [
            {
              doctorOrder: "",
              id: moment().valueOf(),
            },
          ],
        };
        this.tableData = []
      }
    },

  },
  created() {
      this.getOperTypeList();
      this.getDoctorOrderTypeList();
      this.getChargeList();
      this.getTimesList();
  },
  mounted() {},
  methods: {
    add(newOpers){
      const newData = [...this.tableData, ...newOpers];
      this.tableData = newData.map(item => {
        return {
          ...item,
          createCount: item.createCount ? item.createCount : '1',
          chargeId: item.id
        }
      })
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = {...this.form, ruleOperChargeS: this.tableData};
          if (!parm.id) {
            insertData(parm).then(({ res }) => {// success
              this.submitSuccess(true);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(true);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({
        showClose: true,
        message: "操作成功",
        type: "success",
      });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      this.getOrderList();
      this.getFeeItemsList();
      getDetail(param).then(({ res, timeStamp }) => {
          console.log("------oper detail", res)
        this.form = Object.assign({},this.form,res);
        this.timeStamp = timeStamp
      });
    },
    /**
     * 查询嘱托列表
     */
    getOrderList(){
      const param = {};
      if (this.id) {
        param.operId = this.id;
      }
      getOrderList(param).then(({ res, timeStamp }) => {
        this.form = {...this.form,ruleOperOrderS:res}
      });
    },
    /**
     * 查询关联收费列表
     */
    getFeeItemsList(){
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      getFeeItemsList(param).then(({ res, timeStamp }) => {
        this.tableData = res;
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        this.tableData = this.tableData.filter(item => item.id !== scope.data.row.id)
      });
    },
    updateVisible(val) {
      if (val === false) {
        this.$refs.insertForm.resetFields()
      }
      this.visible = val;
    },
    removeDoctorOrder(id) {
      if (this.form.ruleOperOrderS.length === 1) {
        this.$message({
          type: "warning",
          message: "至少需要一条嘱托!",
        });
        return false;
      }
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        this.form = {
          ...this.form,
          ruleOperOrderS: this.form.ruleOperOrderS.filter(
            (element) => element.id !== id
          ),
        };
      });
    },
    addDoctorOrder() {
      const order = {
            doctorOrder: "",
            id: moment().valueOf(),
          }
      let doctorOrderList = this.form.ruleOperOrderS || []
      doctorOrderList = [...doctorOrderList,order]
      this.form = {
        ...this.form,
        ruleOperOrderS : doctorOrderList
      }
    },
    toggleModal(){
      this.$refs.addOper.updateVisible(true)
    },
    minus(scope){
      if (scope.data.row.createCount == 1) {
        this.$message({
          type: "warning",
          message: "次数不能小于1!"
        })
        return false
      }
      const newCount = Number(scope.data.row.createCount) - 1
      let ruleOperChargeS = [...this.tableData]
      ruleOperChargeS[scope.data.$index].createCount = newCount
      this.form = {
        ...this.form,
        ruleOperChargeS
      }
      // this.tableData = [...ruleOperChargeS]
    },
    plus(scope) {
      if (scope.data.row.createCount == 99) {
        this.$message({
          type: "warning",
          message: "次数不能大于99!"
        })
        return false
      }
      const newCount = Number(scope.data.row.createCount) + 1
      let ruleOperChargeS = [...this.tableData]
      ruleOperChargeS[scope.data.$index].createCount = newCount
      // this.tableData = [...tableData]
      this.form = {
        ...this.form,
        ruleOperChargeS
      }
    },
    countChange(scope){
      if (Number(scope.data.row.createCount) === 0) {
        this.$message({
          type: "warning",
          message: "次数不能为0!"
        })
        let ruleOperChargeS = [...this.tableData]
        ruleOperChargeS[scope.data.$index].createCount = '1'
        // this.tableData = [...tableData]
        this.form = {
          ...this.form,
          ruleOperChargeS
        }
        return false
      }
      let ruleOperChargeS = [...this.tableData]
      ruleOperChargeS[scope.data.$index].createCount = Number(scope.data.row.createCount).toFixed(0)
      // this.tableData = [...tableData]
      this.form = {
        ...this.form,
        ruleOperChargeS
      }
    },
    /**
     * 操作项简称赋值
     * 根据操作名称自动获取组套的拼音首字母
     */
    setNamePinyinHead()  {
      this.form.namePinyinHead = CH2CYTPALL(this.form.name);
    },
    getOperTypeList()  {
      getDict({ type: 'oper_type' }).then(({res}) => {
        this.operTypes = res.oper_type;
      });
    },
    getDoctorOrderTypeList()  {
      getDict({ type: 'doctor_order_type' }).then(({res}) => {
        this.doctorOrderTypes = res.doctor_order_type;
      });
    },
    getChargeList()  {
      getDict({ type: 'oper_charge' }).then(({res}) => {
        this.charges = res.oper_charge;
      });
    },
    getTimesList()  {
      getDict({ type: 'times' }).then(({res}) => {
        this.timess = res.times;
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.mdrcc-info {
  height: calc(75vh - 400px);
  overflow-y: auto;
}
.doctor-order-add {
  margin-bottom: 15px;
}
.doctor-order-div {
  position: relative;
  margin-bottom: 15px;
}
.doctor-order-input {
  width: calc(100% - 48px);
  margin-right: 15px;
}
.doctor-order-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
      
        