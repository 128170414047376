
<!--
* @Description: 治疗项目创收统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @onClickRow="onClickRow">
            <template slot="treatPatientCount" slot-scope="scope">
                <a class="clickCell" @click="gotoPatientRehabilitation(scope,'')">{{scope.data.row.treatPatientCount}}</a>
            </template>
            <template slot="treatInpatientCount" slot-scope="scope">
                <a class="clickCell" @click="gotoPatientRehabilitation(scope,'\'0\',\'1\'')">{{scope.data.row.treatInpatientCount}}</a>
            </template>
            <template slot="treatOutpatientCount" slot-scope="scope">
                <a class="clickCell" @click="gotoPatientRehabilitation(scope,'\'2\',\'3\'')">{{scope.data.row.treatOutpatientCount}}</a>
            </template>
            <template slot="treatCount" slot-scope="scope">
              <a class="clickCell" @click="gotoTreatStatisticsDetail(scope)">{{scope.data.row.treatCount}}</a>
          </template>

        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/createIncome'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'


export default {
    name: 'CreateIncome',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'empName',
                    label: '治疗师姓名',
                    defaultVal: ''
                },
                {
                  type: 'input',
                  placeholder: '请输入...',
                  key: 'operName',
                  label: '操作项目名称',
                  defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择...',
                    key: 'operType',
                    filterable: true,
                    multiple: false,
                    label: '治疗所属',
                    options: [ ],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'treatDate',
                    label: '治疗日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'createIncome',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '治疗项目', // 必填
                    prop: 'operName' // 必填
                },
                {
                    label: '治疗所属', // 必填
                    prop: 'operType' // 必填
                },
                {
                    label: '治疗患者总数(人)', // 必填
                    slot: 'treatPatientCount' // 必填
                },
                {
                    label: '治疗住院患者(人)', // 必填
                    slot: 'treatInpatientCount' // 必填
                },
                {
                    label: '治疗门诊患者(人)', // 必填
                    slot: 'treatOutpatientCount' // 必填
                },
                {
                    label: '治疗总次数', // 必填
                    slot: 'treatCount' // 必填
                },
                {

                  label: '营收总金额', // 必填
                  prop: 'price' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            operTypeOptions:[],//在院状态
            jumpParam: null, //其他页面跳转传过来参数
            searchBtnFlag : false, //点击查询按钮标识
            startDate: '', //当前月第一天
            endDate: '', //当前月最后一天
            monthDate: [], //当前月集合
        }
    },
    created() {
        this.getList();
        this.getBToCares();
        this.jumpParam = {
          deptId: this.$route.query.deptId,
          empId: this.$route.query.empId,
          treatDateStartTime: this.$route.query.treatDateStartTime,
          treatDateEndTime: this.$route.query.treatDateEndTime,
          dateStartTime: this.$route.query.dateStartTime,
          dateEndTime: this.$route.query.dateEndTime,
          basicInfoId: this.$route.query.basicInfoId
        }
        if(typeof(this.$route.query.treatDateStartTime) == "undefined"){
            this.getCurrentMonthFirst()
            this.getCurrentMonthLast()
            this.getMonthDate()
            this.jumpParam = {
                deptId: this.$route.query.deptId,
                empId: this.$route.query.empId,
                dateStartTime: this.$route.query.dateStartTime,
                dateEndTime: this.$route.query.dateEndTime,
                basicInfoId: this.$route.query.basicInfoId
            }
        }
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.searchBtnFlag = true;
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                let param = Object.assign({},this.getParam(), this.jumpParam)
                if (this.searchBtnFlag) {
                  param = this.getParam();
                }
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                    this.searchBtnFlag = false;
                })
            })
        },
          //给查询条件赋值为当前月份
          getMonthDate() {
           this.monthDate.push(this.startDate,this.endDate)
            this.searchOptions[3].defaultVal = this.monthDate
            
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },
        /**
        　* 跳转患者康复统计
        　* @Description:
          * @Param  {*} scope
          * @Param status 状态（住院）0 出院 1 入院 （门诊）2 在治 3 结束治疗
          * @Return
        　* @Author wangjh
        　* @Date 2021/12/29 13:56
        　*/
        gotoPatientRehabilitation (scope, status) {
            const query = Object.assign({},{
              operId: scope.data.row.operId,
              status: status,
              treatDateStartTime: this.paramObj.treatDateStartTime || this.$route.query.treatDateStartTime,
              treatDateEndTime: this.paramObj.treatDateEndTime || this.$route.query.treatDateEndTime
            })
            this.$router.push({ path: '/statistics/patientRehabilitation', query: query})
        },

        /**
         　* 跳转治疗统计治疗详情
         　* @Description:
           * @Param  {*} scope
           * @Return
         　* @Author wangjh
         　* @Date 2021/12/29 13:56
         　*/
        gotoTreatStatisticsDetail (scope) {
            const query = Object.assign({},{
              operId: scope.data.row.operId,
              empId: this.$route.query.empId,
              deptId: this.$route.query.deptId,
              treatDateStartTime: this.paramObj.treatDateStartTime || this.$route.query.treatDateStartTime,
              treatDateEndTime: this.paramObj.treatDateEndTime || this.$route.query.treatDateEndTime
            })
            this.$router.push({ path: '/statistics/therapistWorkload/personal', query: query})
        },
        onClickRow(row){
            console.log('点击行:',row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },

      /**
       　* 获取所属治疗列表
       　* @Description:
       　* @Author wangjh
       　* @Date 2021/11/11 9:52
       　*/
      getBToCares() {
        getDict({ type: 'oper_type' }).then(({res}) => {
          this.operTypeOptions = res.oper_type.map((element) => {
            return {
              value: element.value,
              label: element.label,
              id: element.id
            }
          });
          this.searchOptions[2].options = this.operTypeOptions
        });
      },
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

