
<template>
  <el-dialog
    width="60%"
    title="模板管理详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
  <el-descriptions class="margin-top" title="" :column="3" >
    <el-descriptions-item label="模板名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
    <el-descriptions-item label="状态">{{detail.statusLabel||commonConfig.$nullData}}</el-descriptions-item>
  </el-descriptions>
  <el-divider/>
  <el-descriptions class="margin-top" title="基础信息" :column="3" >
    <el-descriptions-item label="基础信息">{{checkedName||commonConfig.$nullData}}</el-descriptions-item>
  </el-descriptions>
  <el-divider/>
  <el-descriptions class="margin-top" title="诊断信息" :column="3" >
    <el-descriptions-item label="诊断信息">{{diagnosisName||commonConfig.$nullData}}</el-descriptions-item>
  </el-descriptions>
  <el-divider/>
  <div class="mdrcc-info-title">
    检查信息
  </div>
  <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="":newHeight="'200'" />
  
  <div class="mdrcc-info-title">
    治疗项目
  </div>
  <generalTable ref="mainTable" :table-data="treatmentTableData" :config="treatmentTableConfig" :total="total" @updatePage="":newHeight="'200'" />
  <el-divider/>
  <el-divider/>
  <div class="mdrcc-info-title">
    评估量表 
    
  </div>
  <el-row :gutter="10" >{{derivedName||commonConfig.$nullData}}</el-row>
  
  <generalTable ref="mainTable" :table-data="scaleTableData" :config="scaleTableConfig" :total="total" @updatePage="":newHeight="'200'" />
  <el-divider/>
  <template slot="footer" class="dialog-footer">
    <el-button @click="updateVisible(false)">取 消</el-button>
  </template>
</el-dialog>
</template>
<script>
import {getDetail} from '@/api/tempManage'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    generalTable
  },
  data() {
    return {
      visible: false,
      detail: [], // 详情数据
      tableData: [],
      tableConfig: {
        align: 'center', // 不必填 默认为center
        selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
           {
                  label: '检查项目', // 名称
                  prop: 'checkName' // 
              },
              {
                  label: '检查时间', // 所属治疗
                  prop: 'checkTime' // 
              }
        ]
      },
      treatmentTableData: [],
      treatmentTableConfig: {
        align: 'center', // 不必填 默认为center
        selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
           {
                  label: '治疗项目', // 名称
                  prop: 'treatmentName' // 
              },   
        ]
      },
      scaleTableData: [],
      scaleTableConfig: {
        align: 'center', // 不必填 默认为center
        selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
           {
                  label: '量表名称', // 名称
                  prop: 'scaleName' // 
              },
              {
                  label: '范围', // 所属治疗
                  prop: 'range' // 
              }
        ]
      },
      stand:[],
      checkedName:"",
      checkTime:"",
      checkTimeList:[],
      treatmentName:"",
      derivedName:"",
      diagnosisName:"",
      total: 0 // 总条数
    }
  },
  watch: {
    visible(newVal, oldVal) {
      newVal && this.id && this.getDetail()
    }
  },
  created() {
    this.getCheckTime()
  },
  mounted() {
  },
 
  methods: {
       /**
   * @description: 检查信息中的检查时间
   * @param {*}
   * @return {*}
   */
   getCheckTime(){
      getDict({ type: 'check_time'}).then(({res}) => {
          this.checkTimeList= res.check_time;
      });
    },
    /**
    　* 科研模板详情
    　* @Description: 科研模板详情
      * @Param null
      * @Return null
    　* @Author wangz
    　* @Date 2022/1/17 9:29
    　*/
    getDetail() {
      const param = {}
      //科研模板id
      if (this.id) {
        param.id = this.id
      }

      getDetail(param).then(({res}) => {
        this.detail = res;
        if(res.checkedName == undefined || res.checkedName.length <= 0 ){
            this.checkedName=res.checkedName 
        }else{
          this.checkedName=res.checkedName .join(",")
        }
        if(res.diagnosisName == undefined || res.diagnosisName.length <= 0 ){
            this.diagnosisName=res.diagnosisName
        }else{
          this.diagnosisName=res.diagnosisName.join(",")
        }
        this.tableData=res.tableData
        if(res.derived==0){
            this.derivedName="按结果导出"
          }else if(res.derived==1){
            this.derivedName="按量表明细导出"
          }else if(res.derived==2){
            this.derivedName="按结果与量表明细导出"
          }
          
      
        this.treatmentTableData=res.treatmentTableData
        this.scaleTableData=res.scaleTableData
        this.tableData.forEach(element => {
          this.checkTimeList.forEach(i => {
            if(i.value == element.checkTime){
              element.checkTime = i.label;
            }
          })
           
          })
        
      
    })},
   
    updateVisible(flag) {
      this.visible = flag
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
