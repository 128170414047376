/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2021-10-14 16:39:41
 * @LastEditors: yzw
 * @Reference:
 */
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @description 验证是否为手机号
 * @param {Number} path
 * @returns {Boolean}
 */
export function validatorPhone(rule, value, callback) {
  console.log(value)
  const exp = /^1[0-9]{10}$/
  if (value === '' || value === null || value === undefined) {
    callback(new Error('请输入手机号'))
  } else {
    if (exp.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的手机号'))
    }
  }
}
