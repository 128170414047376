/*
 * @Author: your name
 * @Date: 2021-11-15 11:53:18
 * @LastEditTime: 2022-01-25 09:33:26
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\api\patient360.js
 */
import request from '@/utils/request'

/**
 * @description: 获取患者医嘱列表数据
 */
export function getAdviceList(data) {
    return request({
        url: 'patientAdviceInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取评定列表数据
 */
export function getAssessmentList(data) {
    return request({
        url: 'scalePatient/assessmentMap',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
