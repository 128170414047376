<!--
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
-->
<template>
    <el-row class="table-btn-group" :id="app ? 'app-scale' : ''">
        <div v-if="!app" style="display: flex;line-height: 30px;flex-wrap: wrap;justify-content: flex-start;align-items: center;column-gap: 20px;margin:15px 0;">
            <div style="font-size: 16px;" v-for="basicInfoItem in basicInfoList" :key="basicInfoItem.id" v-show="showHeader">
                {{ basicInfoItem }}
            </div>
        </div>
        <div :id="id" v-html="scaleHtml"></div>
    </el-row>
</template>

<script>
import {
    getMouldCode,
    getAnswerQuestions,
    getMouldCodeByScalePatientId, // 获取量表模板
    getViewMouldCodeByScalePatientId, // 获取显示模板
    getScaleInfoQuestionByPatientScaleId,
    lastTimePatientAnswer,
    getDetail // 公共量表库基础信息
} from '@/api/publicScaleLib'
import {
    getDetailPrivate // 评估量表库基础信息
} from '@/api/privateScaleLib'
import { savePatientAnswer, getRule, calculate } from '@/api/patientAssessment'
import { getTreatmentInfo } from '@/api/patient'
import { getPatientPackage } from '@/api/common'
import $ from 'jquery'

export default {
    name: 'scaleIndex',
    props: {
        app: {
            type: Boolean,
            default: false
        },
        scaleId: {
            type: String,
            required: false
        },
        libFlag: {
            type: String,
            required: false
        },
        id: {
            type: String
        },
        basicInfoId: {
            type: String
        },
        scaleType: {
            type: String
        },
        evaluateByName: {
            type: String
        },
        evaluateDate: {
            type: Number
        },
        fileList: {
            type: Array
        },
        showMould: {
            // 量表模板:mouldCode, 显示模板: viewMouldCode
            type: String,
            required: false
        },
        showHeader: {//是否显示表头
            type: Boolean,
            required: false
        },
    },
    components: {},
    data() {
        return {
            queList: [],
            scaleHtml: '',
            answerList: [],
            marksRule: '',
            resultsRule: '',
            basicInfoList: []
        }
    },
    watch: {
        id(newVal, oldVal) {
            if (newVal) {
                this.getRule()
                if (this.scaleType === '2') {
                    this.getScaleInfoQuestionByPatientScaleId()
                } else {
                    this.getAnswerQuestions()
                }
            } else {
                this.updateScaleByMould()
            }
        },
        scaleId(newVal, oldVal) {
            if (newVal && !this.id) {
                this.updateScaleByMould()
            }
        },
        scaleType(newVal, oldVal) {
            if (newVal) {
                this.updateScaleByMould()
            }
        }
    },
    created() {
        if (this.scaleId && !this.id) {
            this.updateScaleByMould()
            return
        }
        this.getRule()
        if (this.scaleType === '2') {
            this.id && this.getScaleInfoQuestionByPatientScaleId()
        } else {
            this.id && this.getAnswerQuestions()
        }
    },
    mounted(){
    },
    methods: {
        updateScaleByMould() {
            if (this.libFlag === 'publicLib') {
                // 公共量表库
                getDetail({ id: this.scaleId }).then(({ res }) => {
                    this.updateScaleHtml(res)
                })
            } else {
                // 评估量表库
                getDetailPrivate({ id: this.scaleId }).then(({ res }) => {
                    this.updateScaleHtml(res)
                })
            }
        },
        updateScaleHtml(res) {
            console.log("------updateScaleHtml", res)
            const { mouldCode, viewMouldCode } = res
            let scaleHtml = res[this.showMould] || mouldCode
            if(this.app) {
                scaleHtml = scaleHtml.replace(`<title></title>`, `<title></title><meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=3.0, user-scalable=yes" />`)
            }
            const regex = /\[(.+?)\]/g // [] 中括号
            const queCoordArray = scaleHtml.match(regex)
            queCoordArray && queCoordArray.forEach(queContent => {
                if (queContent.includes('_')) {
                    scaleHtml = scaleHtml.replace(
                        queContent,
                        `<input class="scaleQue" type="checkbox" onclick="return false" />`
                    )
                } else {
                    scaleHtml = scaleHtml.replace(queContent, `<input class="scaleQue" type="text" readonly />`)
                }
            })
            this.scaleHtml = scaleHtml
        },
        getPatientPackage() {
            if (!this.basicInfoId) {
                return
            }
            getPatientPackage(0).then(({ res }) => {
                // this.basicInfoList = res.res
                getTreatmentInfo({ basicInfoId: this.basicInfoId }).then(({ res: treatInfoRes }) => {
                    const { choose } = res
                    this.basicInfoList = choose.sort((a,b) => a.sort-b.sort).map(chooseItem => {
                        let content = ""
                        if (treatInfoRes[chooseItem.keyField] instanceof Array) {
                            content = treatInfoRes[chooseItem.keyField].map(item => item.content).join("。")
                        } else {
                            content = chooseItem.keyField === "sex" ? this.commonConfig.$sexList[treatInfoRes[chooseItem.keyField]] : treatInfoRes[chooseItem.keyField]
                        }
                        return `${chooseItem.name}:${content || this.commonConfig.$nullData}`
                    })
                })
            })
        },
        /**
         * @description: 复制上一次提交的答案
         * @param {*}
         * @return {*}
         */
        lastTimePatientAnswer() {
            lastTimePatientAnswer({ id: this.id }).then(({ res, timeStamp }) => {
                if (res.id) {
                    this.$emit('setevaluate', res.evaluateByName, res.evaluateDate, res.status, res.fileList)
                    this.queList = res.questionList
                    this.answerList = res.answerList
                    this.scaleHtml = ''
                    this.getMouldCodeByScalePatientId()
                } else {
                    this.$message({
                        message: '无可复制量表',
                        type: 'warning'
                    })
                }
            })
        },
        /** 回填答案
         * @description: 问答和小结回填结果; 单选和多选回选选中项
         *  answerType: "0" => 单选 ; "1" => 多选 ; "2" => 问答 ; "3" => 小结 ; "4" => 填空
         * @param {*}
         * @return {*}
         */

        fillAnswer() {
            $(`#${this.id} input[type='radio']`).prop('checked', false)
            $(`#${this.id} input[type='checkbox']`).prop('checked', false)
            this.$nextTick(() => {
                this.answerList.map((item, index) => {
                    if (['0'].includes(item.answerType) && this.queList[index].pcShow === '0') {
                        $(`#${this.id} #${item.answerCoordinate}`).val(item.answerLsn)
                    } else if (['0', '1'].includes(item.answerType)) {
                        item.scaleQuestionOptionId &&
                            item.scaleQuestionOptionId.split(',').map((item1, index) => {
                                $(`#${this.id} .id_${item1}`).prop('checked', 'true')
                            })
                    } else {
                        $(`#${this.id} #${item.answerCoordinate}`).val(item.answerContent)
                    }
                })
            })
        },
        /**
         * @description: 提交/保存答案
         * @param {String} type 1保存/2提交
         * @return {*}
         */
        submit(type, success, failure) {
            let data = type === 1 ? this.clickText(false) : this.clickText(true)
            if(data) {
                this.calculate(data, type, success, failure)
            } else {
                this.$message({
                    message: '请填写必填项',
                    type: 'warning'
                })
                failure && typeof failure === 'function' && failure()
            }
        },
        /**
         * @description: 
         * @param {*}
         * @return {*}
         */        
        getSubData(type, cb){

            let data = type === 1 ? this.clickText(false) : this.clickText(true)
            if (!data) {
                cb(false)
            }
            calculate({
                formula: this.marksRule,
                data: data.subData
            })
                .then(({ res: markRes, timeStamp }) => {
                    if (this.marksRule && typeof eval(markRes) !== 'number') {
                        this.$message({
                            type: 'warning',
                            message: '分数书写格式有误,请校验规则后,重新修改!'
                        })
                        return
                    }
                    calculate({
                        formula: this.resultsRule,
                        data: data.subData
                    })
                        .then(({ res: resultRes, timeStamp }) => {
                            this.fullMarks = eval(markRes) || 0
                            this.evaluationResults = eval(resultRes) || ''
                            const params = {
                                answerDetail: data.answerDetail,
                                evaluateByName: this.evaluateByName,
                                evaluationResults: this.evaluationResults,
                                evaluateDate: new Date(this.evaluateDate).getTime(),
                                scalePatientId: this.id,
                                // fileList: this.fileList.map(item => {
                                //     return item.id
                                // }),
                                status: type, //1保存 2提交
                                fullMarks: this.fullMarks
                            }
                            cb({
                                type,
                                params
                            })
                        })
                        .catch(error => {
                            cb(false)
                        })
                })
                .catch(error => {
                    cb(false)
                })
        },
        /** 计算评分及规则
         * @description: 先计算评分再计算规则，计算成功后更新答案
         * @param {*} data
         * @param {*} type
         * @return {*}
         */

        calculate(data, type, success, failure) {
            calculate({
                formula: this.marksRule,
                data: data.subData
            })
                .then(({ res: markRes, timeStamp }) => {
                    if (this.marksRule && typeof eval(markRes) !== 'number') {
                        this.$message({
                            type: 'warning',
                            message: '分数书写格式有误,请校验规则后,重新修改!'
                        })
                        return
                    }
                    calculate({
                        formula: this.resultsRule,
                        data: data.subData
                    })
                        .then(({ res: resultRes, timeStamp }) => {
                            this.fullMarks = eval(markRes) || 0
                            this.evaluationResults = eval(resultRes) || ''
                            const params = {
                                answerDetail: data.answerDetail,
                                evaluateByName: this.evaluateByName,
                                evaluationResults: this.evaluationResults,
                                evaluateDate: new Date(this.evaluateDate).getTime(),
                                scalePatientId: this.id,
                                fileList: this.fileList.map(item => {
                                    return item.id
                                }),
                                status: type, //1保存 2提交
                                fullMarks: this.fullMarks
                            }
                            savePatientAnswer(params, timeStamp).then(({ res }) => {
                                if (success && typeof success === "function") {
                                    success()
                                } else {
                                    this.$message({
                                        message: type == 1 ? '保存成功' : '提交成功',
                                        type: 'success'
                                    })
                                    type !== 1 && this.$emit('success')
                                }
                            })
                        })
                        .catch(error => {
                            failure && typeof failure === 'function' && failure()
                        })
                })
                .catch(error => {
                    failure && typeof failure === 'function' && failure()
                })
        },
        /**
         * @description: 获取评分规则,在提交之前使用
         * @param {*}
         * @return {*}
         */
        getRule() {
            this.id &&
                getRule({ scalePatientId: this.id }).then(({ res }) => {
                    this.marksRule = res.marksRule
                    this.resultsRule = res.resultsRule
                })
            this.basicInfoId && this.getPatientPackage()
        },
        /**
         * @description: 获取量表问题/评估人/评估时间/文件列表并返回父级
         * @param {*}
         * @return {*}
         */
        getScaleInfoQuestionByPatientScaleId() {
            getScaleInfoQuestionByPatientScaleId({ id: this.id }).then(({ res, timeStamp }) => {
                this.$emit('setevaluate', res.evaluateByName, res.evaluateDate, res.status, res.fileList)
                this.queList = res.questionList
                this.answerList = res.answerList
                this.getMouldCodeByScalePatientId()
            })
        },
        /**
         * @description: 获取量表模板HTML
         * @param {*}
         * @return {*}
         */
        getMouldCodeByScalePatientId() {
            if (!this.id) {
                return
            }
            getMouldCodeByScalePatientId({ id: this.id }).then(({ res, timeStamp }) => {
                this.getMouldCodeSuccess(res, timeStamp)
            })
        },
        /**
         * @description: 获取量表问题(模板预览)
         * @param {*}
         * @return {*}
         */
        getAnswerQuestions() {
            getScaleInfoQuestionByPatientScaleId({ id: this.id }).then(({ res, timeStamp }) => {
                this.$emit('setevaluate', res.evaluateByName, res.evaluateDate, res.status, res.fileList)
                this.queList = res.questionList
                this.answerList = res.answerList
                this.getMouldCode()
            })
        },
        /**
         * @description: 获取量表模板HTML(模板预览)
         * @param {*}
         * @return {*}
         */
        getMouldCode() {
            getViewMouldCodeByScalePatientId({ id: this.id }).then(({ res, timeStamp }) => {
                this.getMouldCodeSuccess(res, timeStamp)
            })
        },
        /**
         * @description: 获取量表模板HTML后插入Input
         * @param {*}
         * @return {*}
         */
        getMouldCodeSuccess(res, timeStamp) {
            let scaleHtmlCache = res
            for (let i = 0; i < this.queList.length; i++) {
                const { answerCoordinate, answerType, optionList, pcShow } = this.queList[i]
                let validRule = this.queList[i].validRule ? $.parseJSON('{' + this.queList[i].validRule + '}') : {}
                let IdCache = answerCoordinate
                let moreAttr = '' //moreAttr配置类型,最大值,最小值等属性
                const keyList = ['type', 'max', 'min', 'step']
                keyList.forEach(key => {
                    if (validRule[key]) {
                        moreAttr += `${key}="${validRule[key]}"`
                    }
                })
                if (validRule.readonly) {
                    moreAttr += `readonly="readonly"`
                }
                if (validRule.validRuleArray) {
                    moreAttr += `array="${validRule.validRuleArray}"`
                }
                if (validRule.validRuleCount) {
                    moreAttr += `rule=${validRule.validRuleCount}`
                }
                // answerType:'0'->'单选';'1'->'多选';'2'->'问笞';'3'->'小结';'4'->"填空';
                if (['3', '4'].includes(answerType)) {
                    // 小结/填空
                    scaleHtmlCache = scaleHtmlCache.replace(
                        `[${IdCache}]`,
                        `<input class="scaleQue" placeholder="请输入..." id="${IdCache}" ${moreAttr} />`
                    )
                } else if (['2'].includes(answerType)) {
                    // 问答
                    scaleHtmlCache = scaleHtmlCache.replace(
                        `[${IdCache}]`,
                        `<textarea rows="4" class="scaleQue" placeholder="请输入..." id="${IdCache}" ${moreAttr} ></textarea>`
                    )
                } else if (['0'].includes(answerType) && pcShow === '0') {
                    // 单选且按填空展示
                    const lsnArray = String(optionList.map(item => `\"${item.lsn || ''}\"`))
                    moreAttr += `array='${lsnArray}'`
                    scaleHtmlCache = scaleHtmlCache.replace(
                        `[${IdCache}]`,
                        `<input class="scaleQue" placeholder="请输入..." id="${IdCache}" ${moreAttr} />`
                    )
                } else if (['0', '1'].includes(answerType)) {
                    // 多选及未设置填空展示的单选
                    for (let n = 0; n < optionList.length; n++) {
                        let option = optionList[n]
                        let optionIdCache = IdCache + '_' + (n + 1)
                        scaleHtmlCache = scaleHtmlCache.replace(
                            '[' + optionIdCache + ']',
                            `<input 
                                class="scaleQue 
                                ${IdCache}Option id_${option.id}" 
                                value="${option.mark || 0}" 
                                data-id="${option.id}" 
                                lsn="${option.lsn || ''}"
                                type="${answerType === '1' ? 'checkbox' : 'radio'}" 
                                name="${IdCache}" 
                                id="${optionIdCache}"
                            />`
                        )
                    }
                }
            }
            this.scaleHtml = scaleHtmlCache
            this.$nextTick(() => {
                this.updateAnswer()
                let that = this
                $(`#${this.id} input`).on('change', function() {
                    that.inputChange(this)
                })
                $(`#${this.id} textarea`).on('change', function() {
                    that.inputChange(this)
                })
            })
        },
        updateAnswer() {
            if (this.scaleType === '2') {
                this.$nextTick(() => {
                    $(`#${this.id} .scaleQue`).change(() => {
                        this.clickText()
                    })
                    this.fillAnswer()
                    if (this.scaleType === '3') {
                        $(`#${this.id} input`).attr('data-id', '4')
                        $(`#${this.id} textarea`).attr('data-id', '2')
                    }
                })
            } else {
                this.$nextTick(() => {
                    this.fillAnswer()
                    $(`#${this.id} input`).attr('readonly', 'readonly')
                    $(`#${this.id} textarea`).attr('readonly', 'readonly')
                    $(`#${this.id} :checkbox`).attr('onclick', 'return false')
                    $(`#${this.id} :radio`).attr('onclick', 'return false')
                })
            }
        },
        inputChange(event) {
            const min = Number($(event).attr('min'))
            const max = Number($(event).attr('max'))
            const answerCoordinate = $(event).attr('id')
            const currentQue = this.queList.filter(item => item.answerCoordinate === answerCoordinate)[0]
            const validRule = currentQue && currentQue.validRule ? JSON.parse(`{${currentQue.validRule}}`) : {}
            const rule = validRule.validRuleCount || ''
            const resArray = $(event).attr('array') ? eval(`[${$(event).attr('array')}]`).map(item => String(item)) : []
            const val = Number($(event).val() || $(event).attr('value'))
            let flag = ''
            let messageMap = {
                min: {
                    message: `最小值为${min}`,
                    res: min
                },
                max: {
                    message: `最大值为${max}`,
                    res: max
                },
                error: {
                    message: `数值范围与逻辑表达式冲突,请重新配置题目规则`,
                    res: ''
                }
            }
            if ((min || min === 0) && min > val) {
                flag = rule ? 'error' : 'min'
            } else if ((max || max === 0) && max < val) {
                flag = rule ? 'error' : 'max'
            } else if (resArray.length && !resArray.includes($(event).val())) {
                flag = 'error'
                messageMap[flag].message = `请输入${$(event).attr('array')}任意一项！`
            }
            if (!flag) {
                return
            }
            this.$message({
                type: 'warning',
                message: messageMap[flag].message
            })
            !rule && $(event).val(messageMap[flag].res)
        },
        /**
         * @description: 获取量表模板HTML后插入Input
         * answerType: "0" => 单选 ; "1" => 多选 ; "2" => 问答 ; "3" => 小结 ; "4" => 填空
         * @param {String} type true检查必填项/不检查必填项
         * @return {Object} 保存用数据answerDetail 计算分数用数据subData
         */
        clickText(type) {
            let subData = {
                count: (souceArray, val) => {
                    let countArray = souceArray.map(item => {
                        return Number($(`#${this.id} #${item}`).val()) === val ? 1 : 0
                    })
                    return countArray.length ? countArray.reduce((a, b) => a + b) : 0
                }
            }
            let focusFlag = true
            let sumList = []
            let answerDetail = []
            for (let i = 0; i < this.queList.length; i++) {
                const { answerCoordinate, answerType, id, pcShow, optionList } = this.queList[i]
                let validRule = this.queList[i].validRule ? $.parseJSON(`{${this.queList[i].validRule}}`) : {}
                let IdCache = answerCoordinate
                let queValue = ''
                if (['2', '3', '4'].includes(answerType)) {
                    queValue = $(`#${IdCache}`).val()
                    if (!queValue && validRule && validRule.required && type) {
                        $(`#${IdCache}`).css('borderColor', '#ff0000')
                        if (focusFlag) {
                            $(`#${IdCache}`).focus()
                            focusFlag = false
                            return false
                        }
                    } else {
                        $(`#${IdCache}`).css('borderColor', '')
                    }
                    answerDetail.push({
                        questionId: id,
                        answerContent: queValue,
                        pcShow,
                        answerCoordinate,
                        validRule,
                        answerType
                    })
                    subData[IdCache] = queValue
                    validRule.validRuleCount &&
                        sumList.push({
                            id: id,
                            IdCache: IdCache,
                            validRuleCount: validRule.validRuleCount
                        })
                } else if (answerType == '1') {
                    let checkedId = ''
                    let checkedIdArray = []
                    let queValArray = []
                    let lsnArray = []
                    $(`input[name='${answerCoordinate}'].scaleQue:checked`).each(function() {
                        checkedIdArray.push($(this).attr('data-id'))
                        queValArray.push($(this).attr('value') * 1)
                        lsnArray.push($(this).attr('lsn'))
                    })
                    queValue = queValArray.length ? queValArray.reduce((a, b) => a + b) : 0
                    answerDetail.push({
                        questionId: id,
                        answerMark: queValue,
                        optionId:(checkedIdArray && checkedIdArray.length) ? checkedIdArray.join() : "",
                        answerLsn:(lsnArray && lsnArray.length) ? lsnArray.join() : "",
                        pcShow,
                        answerCoordinate,
                        validRule,
                        answerType
                    })
                    subData[IdCache] = queValue
                } else if (answerType == '0') {
                    // 单选
                    // pcShow: '0'->填空 '1'->选择
                    if (pcShow === '0') {
                        if (!$(`#${answerCoordinate}`).val()) {
                            if (validRule && validRule.required && type) {
                                $(`#${IdCache}`).css('border-color', '#ff0000')
                                if (focusFlag) {
                                    $(`#${IdCache}`).focus()
                                    focusFlag = false
                                    return false
                                }
                            }
                            subData[IdCache] = 0
                        } else {
                            const currentSelect = optionList.filter(
                                item => item.lsn === $(`#${answerCoordinate}`).val()
                            )[0]
                            answerDetail.push({
                                questionId: id,
                                answerMark: currentSelect.mark || 0,
                                optionId: currentSelect.id,
                                answerLsn: $(`#${answerCoordinate}`).val(),
                                pcShow,
                                answerCoordinate,
                                validRule,
                                answerType
                            })
                            subData[IdCache] = Number(currentSelect.mark) || 0
                        }
                    } else {
                        queValue = 0
                        for (let n = 0; n < optionList.length; n++) {
                            let optionIdCache = IdCache + '_' + (n + 1)
                            if ($(`#${optionIdCache}`).prop('checked')) {
                                answerDetail.push({
                                    questionId: id,
                                    answerMark: queValue,
                                    optionId: $(`#${optionIdCache}`).attr('data-id'),
                                    answerLsn: optionList[n].lsn || '',
                                    pcShow,
                                    answerCoordinate,
                                    validRule,
                                    answerType
                                })
                                queValue = $(`#${optionIdCache}`).attr('value') * 1
                            }
                        }
                        subData[IdCache] = queValue
                    }
                }
                // else if (answerType == '3') {
                //     validRule.validRuleCount &&
                //         sumList.push({
                //             id,
                //             IdCache: IdCache,
                //             validRuleCount: validRule.validRuleCount
                //         })
                // }
                if (i == this.queList.length - 1) {
                    if (!type) {
                        for (var n = 0; n < sumList.length; n++) {
                            let data = subData
                            // validRuleCount 可能是数字也可能是字符
                            let validRuleCount = eval(sumList[n].validRuleCount)
                            if (typeof validRuleCount === 'number') {
                                // 整数显示整数，小数显示1位有效数字
                                validRuleCount =
                                    parseInt(validRuleCount) === validRuleCount
                                        ? validRuleCount.toFixed(0)
                                        : validRuleCount.toFixed(1)
                            }
                            queValue = validRuleCount
                            $(`#${this.id} #${sumList[n].IdCache}`).val(queValue)
                            subData[sumList[n].IdCache] = queValue
                            answerDetail.push({
                                questionId: sumList[n].id,
                                answerContent: queValue,
                                pcShow,
                                answerCoordinate,
                                validRule,
                                answerType
                            })
                        }
                    } else {
                        //在循环最后检查sumList中的小结类型
                        for (var n = 0; n < sumList.length; n++) {
                            queValue = $(`#${this.id} #${sumList[n].IdCache}`).val()
                            answerDetail.push({
                                questionId: sumList[n].id,
                                answerContent: queValue,
                                pcShow,
                                answerCoordinate,
                                validRule,
                                answerType
                            })
                            subData[sumList[n].IdCache] = queValue
                        }
                    }
                    if (focusFlag) {
                        return { answerDetail: answerDetail, subData: subData }
                    } else {
                        return false
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
