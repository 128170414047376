/*
 * @Author: ZF-WLY
 * @Date: 2021-10-20 09:57:32
 * @LastEditTime: 2021-11-01 11:44:07
 * @LastEditors: ZF-WLY
 * @Description: 菜单相关接口
 *      修改、更新状态及删除接口需要传入一个timeStamp，该属性在获取列表时获得；
 *      因此每次修改、更新、删除、插入操作后都需要重新获取一次列表以便获得最新的timeStamp，否则会出现更新不成功的情况
 * @FilePath: \recovery_management\src\api\menu.js
 */

/**
 * 页面: 菜单管理
 * pageName: menu
 **/
import request from '@/utils/request'

/**
 * @description: 获取菜单管理列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/acl/tree',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增菜单管理数据
 */
export function insertData(data) {
    return request({
        url: '/admin/acl/insert',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改菜单管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/acl/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取菜单管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/acl/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除菜单管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/acl/del',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 禁用启用权限
 */
 export function changeMenuStatus(data, timeStamp) {
    return request({
        url: '/admin/acl/disable',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
