
<!--
* @Description: 科室工作量统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" :exportStatus="exportStatus" @exportFun="toExport"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @onClickRow="onClickRow">
          <template slot="patientCount" slot-scope="scope">
            <a class="clickCell" @click="topaTientRehabilitation(scope)">{{scope.data.row.patientCount}}</a>
          </template>
          <template slot="createCount" slot-scope="scope">
            <a class="clickCell" @click="toCreateIncome(scope)">{{scope.data.row.createCount}}</a>
          </template>
            <template slot="handle" slot-scope="scope">
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList,toExport, deleteData } from '@/api/departmentWorkload'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import {getDept} from "@/api/patient";



export default {
    name: 'DepartmentWorkload',
    components: {
        searchForm,
        generalTable,


    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    dateStartTime: '开始日期',
                    dateEndTime: '结束日期',
                    key: 'date',
                    label: '治疗日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'departmentWorkload',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '科室', // 必填
                    prop: 'deptName' // 必填
                    // formatter: (row, column) => {
                    //     return row.archivesInfoId.name
                    // }
                },
                {
                    label: '治疗患者总数（人）', // 必填
                    slot: 'patientCount' // 必填
                },
                {
                    label: '治疗总次数', // 必填
                    slot: 'createCount' // 必填
                },
                {
                    label: '营收总金额', // 必填
                    prop: 'amountCount' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            exportStatus: true, // 导出按钮的隐藏/显示 true显示 false隐藏
            timeStamp: '', //时间戳 用于修改数据或删除数据
            deptOptions: [], // 所属科室
            startDate: '', //当前月第一天
            endDate: '', //当前月最后一天
            monthDate: [], //当前月集合
        }
    },
    created() {
        this.getList()
        this.getOptions()
        this.getCurrentMonthFirst()
        this.getCurrentMonthLast()
        this.getMonthDate()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total);
                })
            })
        },
          //给查询条件赋值为当前月份
          getMonthDate() {
           this.monthDate.push(this.startDate,this.endDate)
            this.searchOptions[1].defaultVal = this.monthDate
            
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },
      /**
       * @description:康复治疗情况统计
       * @param {*} item
       * @return {*}
       */
      topaTientRehabilitation(scope) {
        const paramObj = this.$refs.mainSearch.packageData()
        const query = Object.assign({
          deptId:scope.data.row.deptId,
          treatDateStartTime: paramObj.dateStartTime,
          treatDateEndTime: paramObj.dateEndTime
        })
        console.log("康复治疗情况统计",query)
        this.$router.push({ path: '/statistics/patientRehabilitation', query: query })
      },
      /**
       * @description:治疗项目创收统计
       * @param {*} item
       * @return {*}
       */
      toCreateIncome(scope) {
        const paramObj = this.$refs.mainSearch.packageData()
        const query = Object.assign({
          deptId:scope.data.row.deptId,
          treatDateStartTime:paramObj.dateStartTime,
          treatDateEndTime:paramObj.dateEndTime
        })
        console.log("治疗项目创收统计",query)
        this.$router.push({ path: '/statistics/createIncome', query: query })
      },
      /**
       * @description: 获取所属科室
       * @param {*}
       * @return {*}
       */
      getOptions() {
        getDept({deptType:'4'}).then(({ res, timeStamp }) => {
          res.forEach((element) => {
            const ele = {
              value: element.id,
              label: element.label
            }
            this.deptOptions.push(ele)
          })
          this.searchOptions[0].options = this.deptOptions
        })
      },
        onClickRow(row){
            console.log('点击行:',row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        /**导出
         * @description: 点击搜索组件中的导出按钮触发的函数
         * @param {*}
         * @return {*}
         */        
        toExport(){
            const param = this.getParam()
            toExport(param).then(({ res }) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              var aData = new Date();
              const currentDate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              const fileName = "科室工作量统计-"+currentDate+".xlsx";
              const linkNode = document.createElement("a");
              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击
    
              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            })
            .catch((err) => {
              console.log(err);
            });
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

