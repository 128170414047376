<!--
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="80%"
        :title="libFlag === 'publicLib' ? '公共量表库预览' : '评估量表库预览'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <div class="pat-scale-pane" v-if="showtype === '1'">
            <patientScale
                :scaleId="scaleId"
                :libFlag="libFlag"
                flag="4"
                patientBasicInfoId=""
                patientScaleId=""
                :analysis="true"
                showMould="mouldCode"
            />
        </div>
        <template v-if="showtype === '0'">
            <el-tabs tab-position="left" v-model="activeName" @tab-click="flagChange">
                <el-tab-pane label="量表模板" name="2" class="pat-scale-pane">
                    <patientScale
                        :key="`${id}_2`"
                        :scaleId="scaleId"
                        :libFlag="libFlag"
                        flag="4"
                        showMould="mouldCode"
                        patientBasicInfoId=""
                        patientScaleId=""
                        :analysis="true"
                    />
                </el-tab-pane>
                <el-tab-pane label="显示模板" name="4" class="pat-scale-pane">
                    <patientScale
                        :key="`${id}_4`"
                        :scaleId="scaleId"
                        :libFlag="libFlag"
                        flag="4"
                        showMould="viewMouldCode"
                        patientBasicInfoId=""
                        patientScaleId=""
                        :analysis="true"
                    />
                </el-tab-pane>
            </el-tabs>
        </template>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import patientScale from '@/views/scale/patientAssessment/patientScale'
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        libFlag: {
            type: String,
            required: true
        }
    },
    components: {
        patientScale
    },
    data() {
        return {
            visible: false,
            detail: {}, // 详情数据
            showtype: '2', // 0 模板 1问卷 默认值给个错误的，放置渲染的时候弹跳
            activeName: '2',
            scaleId: ''
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) {
                this.scaleId = this.id
                this.$store.dispatch('getHosInfo').then(res => {
                    this.showtype = res.scaleShowType
                })
            }
            if (!newVal) {
                this.activeName = '2'
                this.scaleId = ''
            }
        }
    },
    created() {
        this.scaleId = this.id
    },
    mounted() {},
    methods: {
        flagChange(tab, event) {
            console.log('------flagChange------', tab, event)
            this.activeName = tab.name
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang="scss">
.pat-scale-pane {
    height: 600px;
    overflow: auto;
}
</style>
