<!--
 * @Author: your name
 * @Date: 2021-12-24 10:00:37
 * @LastEditTime: 2022-02-28 16:56:32
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\assessment.vue
-->
<template>
    <el-container>
        <el-main class="no-padding">
            <!-- 量表评定部分 -->
            <div class="mdrcc-info-title">量表评定</div>
            <el-row v-if="!data.scaleByBasicId || !data.scaleByBasicId.length" class="card-row">{{message}}</el-row>
            <el-row v-for="item in data.scaleByBasicId" :key="item.id" class="card-row">
                <el-col :span="18">
                    <div class="name">{{item.name}}</div>
                    <div class="date">{{item.evaluateDate ? moment(item.evaluateDate).format("yyyy-MM-DD HH:mm") : commonConfig.$nullData}}</div>
                </el-col>
                <el-col :span="6" class="score">
                    <div>{{item.marks ? `${item.marks}分` : commonConfig.$nullData}}</div>
                    <i class="el-icon-arrow-right more" @click="showScaleDetail(item)"></i>
                </el-col>
            </el-row>
            <!-- 方案评定部分 -->
            <div class="mdrcc-info-title">方案评定</div>
            <el-row v-if="!data.programByBasicId || !data.programByBasicId.length" class="card-row">{{message}}</el-row>
            <el-row v-for="item in data.programByBasicId" :key="item.id" class="card-row">
                <el-col :span="18">
                    <div class="name">{{item.name}}</div>
                    <div class="date">{{item.evaluateDate ? moment(item.evaluateDate).format("yyyy-MM-DD HH:mm") : commonConfig.$nullData}}</div>
                </el-col>
                <el-col :span="6" class="score">
                    <div>{{item.marks ? `${item.marks}分` : commonConfig.$nullData}}</div>
                    <i class="el-icon-arrow-right more" @click="showProgramDetail(item)"></i>
                </el-col>
            </el-row>
            <!-- 评定报告部分 -->
            <div class="mdrcc-info-title">评定报告</div>
            <el-row v-if="!data.reportByBasicId || !data.reportByBasicId.length" class="card-row">{{message}}</el-row>
            <el-row v-for="item in data.reportByBasicId" :key="item.id" class="card-row">
                <el-col :span="18">
                    <div class="name">{{item.name}}</div>
                    <div class="date">{{item.evaluateDate ? moment(item.evaluateDate).format("yyyy-MM-DD HH:mm") : commonConfig.$nullData}}</div>
                </el-col>
                <el-col :span="6" class="score">
                    <div></div>
                    <i class="el-icon-arrow-right more" @click="showReportDetail(item)"></i>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'assessmentPat360',
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Object
        }
    },
    data(){
        return {
            message: "暂无数据"
        }
    },
    methods: {
        /** 显示量表评定详情
         * @description: 关闭患者360组件,跳转至对应详情页
         * @param {Object} item 当前评定信息
         * @return {*}
         */        
        showScaleDetail(item){
            console.log("------showScaleDetail------", item)
            this.$emit("close");
            // 跳量表页 flag 1查看量表模板  2编辑评估  3查看评估
            this.$router.push({
                path: '/scale/patientScale',
                query: { patientScaleId: item.scalePatientId, flag: 3 }
            });
        },
        /** 显示方案评定详情
         * @description: 关闭患者360组件,跳转至对应详情页(当前页面尚未完成,暂时进行消息提示)
         * @param {Object} item 当前评定信息
         * @return {*}
         */     
        showProgramDetail(item){
            console.log("------showProgramDetail------", item)
            this.$emit("close");
            // 跳量表方案 flag 1查看量表模板  2编辑评估  3查看评估
            this.$router.push({
                path: '/scale/patientScaleProgram',
                query: { patientScaleId: item.scalePatientId, flag: 3 }
            });
        },
        /** 显示报告评定详情
         * @description: 关闭患者360组件,跳转至对应详情页
         * @param {Object} item 当前评定信息
         * @return {*}
         */      
        showReportDetail(item) {
            this.$emit("close");
            // 跳报告页
            this.$router.push({
                path: "/other/Patient360/assessmentReport",
                query:{id:item.scalePatientId,printFlag:"0"}
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.no-padding {
    padding: 0;
}
.card-row {
    display: flex;
    align-items: center;
    background-color: $dialogHeader;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.name {
    font-size: 18px;
}
.date {
    color: $noticeDate;
    font-size: 14px;
    margin-top: 5px;
}
.more {
    cursor: pointer;
    margin-left: 10px;
    &:hover {
        color: $arrowActive;
    }
}
.score {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
