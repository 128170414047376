/*
 * @Author: magy
 * @Date: 2021-12-29 11:36:02
 * @LastEditTime: 2021-12-30 10:17:44
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\equipmentUsage.js
 */

/**
 * 页面: 设备使用情况统计
 * pageName: equipmentUsage
 **/
import request from '@/utils/request'

/**
 * @description: 获取设备使用情况统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/deviceUsage/index',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除设备使用情况统计数据
 */
 export function listDetail(data) {
    return request({
        url: 'statistics/deviceUsage/detail',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 设备使用情况统计导出
 */
export function toExport(data) {
    return request({
        url: 'statistics/deviceUsage/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}
        