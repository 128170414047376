
<!--
* @Description: 患者评定统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @onClickRow="onClickRow">
            <template slot="pgcs" slot-scope="scope">
                <el-button @click="toPatientDetail(scope)" type="text" >{{
                    scope.data.row.pgcs
                }}</el-button>
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/patientJudge'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: 'PatientJudge',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'paName',
                    label: '姓名',
                    defaultVal: ''
                },
                
            ],
            tableConfig: {
                id: 'patientJudge',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'paName' // 必填
                    // formatter: (row, column) => {
                    //     return row.archivesInfoId.name
                    // }
                },
                {
                    label: '患者所属', // 必填
                    prop: 'deptName' // 必填
                },
                {
                    label: '评估次数', // 必填
                    slot: 'pgcs' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            id:'',
            evaluateDateStartTime: '',
            evaluateDateEndTime: '',
        }
    },
    created() {
        this.getList()
        this.doctorId=this.$route.query.id
        this.evaluateDateStartTime=this.$route.query.evaluateDateStartTime
        this.evaluateDateEndTime=this.$route.query.evaluateDateEndTime
        
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
           
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
      
        onClickRow(row){
            console.log('点击行:',row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page,  { doctorId: this.doctorId},{evaluateDateStartTime: this.evaluateDateStartTime},
                   { evaluateDateEndTime: this.evaluateDateEndTime })
            this.paramObj = param
            return param
        },
         /**
         * @description: 查看患者评定统计详情
         * @param {*} scope
         * @return {*}
         */        
         toPatientDetail(scope) {
            this.$router.push({
                path: "/statistics/therapistWorkload/patient/detail",
                
                query: {
                    pbId: scope.data.row.pbId,
                    evaluateDateStartTime: this.evaluateDateStartTime,
                    evaluateDateEndTime: this.evaluateDateEndTime,
                    doctorId: this.doctorId
                },
            });
        },
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        