<!--
 * @Author: your name
 * @Date: 2021-12-24 14:06:20
 * @LastEditTime: 2022-07-29 15:07:35
 * @LastEditors: ligc 951906427@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\treatDetail.vue
-->
<template>
    <el-dialog width="60%" title="治疗安排详情" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="基本信息" :column="4" >
            <el-descriptions-item label="患者姓名">{{detail.basicInfoName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="床号">{{detail.bedNum || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="住院号">{{detail.recordNum || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="就诊号">{{detail.serialNum || commonConfig.$nullData}}</el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <el-descriptions class="margin-top" title="医嘱信息" :column="4" >
            <el-descriptions-item label="所属治疗">{{detail.operTypeLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="医嘱">{{detail.operName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="医嘱类型">{{detail.doctorOrderTypeLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="总次数">{{detail.doctorOrderType === '1' ? '长期' : detail.createCount}}</el-descriptions-item>
            <el-descriptions-item label="单次数量">{{detail.schCount || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗频次">{{detail.frequencyCount ? `${detail.frequencyCount}次/${detail.frequencyTime}${detail.frequencyUnitLabel}` : commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="医嘱嘱托">{{detail.doctorOrder || commonConfig.$nullData}}</el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <el-descriptions class="margin-top" title="治疗信息" :column="4" >
            <el-descriptions-item label="治疗师">{{detail.therapyName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="计划时间">{{detail.planTime || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗时间">{{detail.treatmentDate || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗设备">{{detail.deviceName || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{detail.statusLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗区">{{detail.consultingRoomLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="治疗反应">{{detail.responseTreatment || commonConfig.$nullData}}</el-descriptions-item>
            <!-- 状态为“已作废”时，才显示“作废操作员”、“作废时间”、“作废原因” -->
            <template v-if="detail.status === '1'">
                <el-descriptions-item label="作废操作员">{{detail.nullifyName || commonConfig.$nullData}}</el-descriptions-item>
                <el-descriptions-item label="作废时间">{{detail.nullifyDate || commonConfig.$nullData}}</el-descriptions-item>
                <el-descriptions-item label="作废原因">{{detail.nullifyReason || commonConfig.$nullData}}</el-descriptions-item>
            </template>
        </el-descriptions>
        <el-divider />
        <el-descriptions class="margin-top" title="签字" :column="4" >
            <el-descriptions-item label="患者签字">
                <el-image v-if="detail.pictureSignature" :src="detail.pictureSignature" fit="contain" style="width:150px;height:100px">
                    <div slot="error" class="image-slot">
                        <div class="my-image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </div>
                </el-image>
            </el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <template slot="footer" class="dialog-footer">
            <el-button  @click="close()">关 闭</el-button>
        </template>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import { getarrangeTreatmentInfo } from '@/api/arrangeTreatment'
export default {
    props: {
        id: {
            type: String | Array,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            detail: {} // 详情数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            // 页面显示，并传入id时，获取治疗安排信息
            newVal && this.id && this.getarrangeTreatmentInfo(this.id)
        }
    },
    created() {},
    mounted() {},
    methods: {
        /** 获取治疗安排信息
         * @description: 计划时间为开始时间HH:mm格式-结束时间HH:mm，治疗时间及作废时间要转换成YYYY-MM-DD HH:mm格式
         * @param {String} id 患者id
         * @return {*}
         */
        getarrangeTreatmentInfo(id) {
            getarrangeTreatmentInfo({ id }).then(({ res }) => {
                const { usesTime, usedTime, treatmentDate, nullifyDate, ...others } = res
                this.detail = {
                    // 治疗时间、作废时间: 时间戳 --> "YYYY-MM-DD HH:mm"格式
                    treatmentDate: treatmentDate ? `${moment(treatmentDate).format('YYYY-MM-DD HH:mm')}` : null,
                    nullifyDate: nullifyDate ? `${moment(nullifyDate).format('YYYY-MM-DD HH:mm')}` : null,
                    // 计划时间 --> 开始时间(HH:mm)-结束时间(HH:mm)
                    planTime: usesTime && usedTime ? `${moment(usesTime).format('HH:mm')}-${moment(usedTime).format('HH:mm')}` : null,
                    // 其余部分原样展示
                    ...others
                }
            })
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空rowData表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        },
        /** 关闭
         * @description: 关闭当前组件,触发父级`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        close(){
            this.updateVisible(false);
            this.$emit('close');
        }
    }
}
</script>
<style scoped lang='scss'>
@import '~@/styles/variables.scss';
// 页面加载错误时，占位样式
.my-image-slot {
    width: 150px;
    height: 100px;
    background-color: $dialogHeader;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        font-size: 20px;
    }
}
</style>
