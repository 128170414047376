/*
 * @Author: SixTeen
 * @Date: 2021-11-25 15:22:24
 * @LastEditTime: 2021-11-29 10:29:16
 * @LastEditors: SixTeen
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_management/src/api/scheduleSummary.js
 */

/**
 * 页面: 排班汇总
 * pageName: scheduleSummary
 **/
import request from '@/utils/request'

/**
 * @description: 获取排班汇总列表数据
 */
export function getList(data) {
    return request({
        url: '/patientSchedule/pool',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增排班汇总数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改排班汇总数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取排班汇总详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除排班汇总数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        