/*
 * @Description: 通用数据/字典项数据
 * @Author: yzw
 * @Date: 2021-02-22 15:46:27
 * @LastEditTime: 2023-04-04 09:15:30
 * @LastEditors: wangly wly574523605@163.com
 * @Reference:
 */

import moment from "moment";

const updateSearchOptions = (searchOptions, key, keyData) => {
    return searchOptions.map(item => {
      if (item.key === key) {
        item.options = keyData
      }
      return item
    })
  }
  
  const updateSearchDefault = (searchOptions, key, value) => {
    return searchOptions.map(item => {
      if (item.key === key) {
        item.defaultVal = value
      }
      return item
    })
  }
  

/**
 * @description: 文件路径前缀
 */
const fileUrl = "/rehabilitation/file/show/";

const nullData = "--";

/**
 * @description: 文档类型
 */
const docTypes = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "pdf"];
/**
 * @description: 图片类型
 */
const imgTypes = ["jpg", "jpeg", "bmp", "gif", "png", "webp"];
/**
 * @description: 音频类型
 */
const audioTypes = ["wav", "mp3", "flac", "ape", "alac", "wavpack", "mp3", "aac", "opus"];
/**
 * @description: 视频类型
 */
const videoTypes = ["wmv", "asf", "asx", "rm", "rmvb", "mp4", "3gp", "mov", "m4v", "avi", "dat", "mkv", "flv", "vob"];

//根据星期的号码得出对应星期几
const dayMap = {
	1: '星期一',
	2: '星期二',
	3: '星期三',
	4: '星期四',
	5: '星期五',
	6: '星期六',
	7: '星期日',
}

const checkNullData = (value, formatType) => {
  if (value || value == 0) {
    if (formatType) {
      return moment(value).format(formatType);
    } else {
      return value;
    }
  } else {
    return nullData;
  }
};

// 页面条数
const pageSizes = [20, 30, 50, 100];

// 删除提示语
const delMsg = "此操作将永久删除该数据,是否继续?";
const delTitle = "提示";
const messageBoxConfig = {
  type: "warning",
  cancelButtonClass: "btn-custom-cancel"
};

// 成功提示语
// 引用时，最好使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
// eg: this.$message({...this.commonConfig.$handleSuccess})
const handleSuccess = {
  message: "操作成功",
  type: "success",
  duration: 2000
};

// 性别value转label
const sexList = ['男', '女']
// 性别下拉框
const sexOptions = [{
  value: '0',
  label: '男'
},
{
  value: '1',
  label: '女'
}]

const disposeItemsOptions = [
  {
    label: '输入框',
    value: 0
  },
  {
    label: '时间',
    value: 1
  },
  {
    label: '单选',
    value: 2
  },
  {
    label: '多选',
    value: 3
  },
  {
    label: '下拉菜单',
    value: 4
  },
  {
    label: '多级下拉菜单',
    value: 5
  },
]

// 时间插件快捷日期数据
const datePickerOptions = {
  shortcuts: [
    {
      text: "昨天",
      onClick(picker) {
        const start = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const end = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "今天",
      onClick(picker) {
        const start = moment().format("YYYY-MM-DD");
        const end = moment().format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "近三天",
      onClick(picker) {
        const start = moment()
          .subtract(3, "days")
          .format("YYYY-MM-DD");
        const end = moment().format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本周",
      onClick(picker) {
        const start = moment()
          .weekday(1)
          .format("YYYY-MM-DD");
        const end = moment()
          .weekday(7)
          .format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本月",
      onClick(picker) {
        const start = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        const end = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本年",
      onClick(picker) {
        const start = moment()
          .startOf("year")
          .format("YYYY-MM-DD");
        const end = moment()
          .endOf("year")
          .format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      }
    }
  ]
};
// 时间插件快捷日期数据
const datetimePickerOptions = {
  shortcuts: [
    {
      text: "昨天",
      onClick(picker) {
        const start =
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD") + " 00:00:00";
        const end =
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "今天",
      onClick(picker) {
        const start = moment().format("YYYY-MM-DD") + " 00:00:00";
        const end = moment().format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "近三天",
      onClick(picker) {
        const start =
          moment()
            .subtract(3, "days")
            .format("YYYY-MM-DD") + " 00:00:00";
        const end = moment().format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本周",
      onClick(picker) {
        const start =
          moment()
            .weekday(1)
            .format("YYYY-MM-DD") + " 00:00:00";
        const end =
          moment()
            .weekday(7)
            .format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本月",
      onClick(picker) {
        const start =
          moment()
            .startOf("month")
            .format("YYYY-MM-DD") + " 00:00:00";
        const end =
          moment()
            .endOf("month")
            .format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "本年",
      onClick(picker) {
        const start =
          moment()
            .startOf("year")
            .format("YYYY-MM-DD") + " 00:00:00";
        const end =
          moment()
            .endOf("year")
            .format("YYYY-MM-DD") + " 23:59:59";
        picker.$emit("pick", [start, end]);
      }
    }
  ]
};


// 路由白名单
const whiteList = [
    "/login",
    "/outSystem/treatmentPlan",
    "/outSystem/largeScreenShow",
    "/outSystem/treatmentRecord",
  ]; // no redirect whitelist

const commonConfig = {
  $datePickerOptions: datePickerOptions,
  $datetimePickerOptions: datetimePickerOptions,
  $fileUrl: fileUrl,
  $pageSizes: pageSizes,
  $sexList: sexList, // 性别value转label
  $sexOptions: sexOptions, // 性别下拉框
  $delMsg: delMsg,
  $delTitle: delTitle,
  $messageBoxConfig: messageBoxConfig,
  $handleSuccess: handleSuccess,
  $nullData: nullData,
  $checkNullData: checkNullData,
  $docTypes: docTypes,
  $imgTypes: imgTypes,
  $audioTypes: audioTypes,
  $videoTypes: videoTypes,
  $dayMap: dayMap,
  $whiteList: whiteList,
  $updateSearchOptions: updateSearchOptions,
  $updateSearchDefault: updateSearchDefault

}

export default commonConfig;
