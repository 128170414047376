/*
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
 */
import request from '@/utils/request'

/**
 * @description: 获取用户配置
 */
 export function getPersonalInfo() {
    return request({
        url: 'userConfig/info',
        method: 'GET',
        showLoading: true
    })
}

/**
 * @description: 保存用户配置
 */
 export function savePersonalInfo(data,timeStamp) {
    return request({
        url: 'userConfig/save',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 用户未绑定技能列表
 */
 export function getUserOperRel(data) {
    return request({
        url: 'userOperRel/unboundedList',
        method: 'GET',
        showLoading: true,
        params: data,
    })
}

/**
 * @description: 用户未绑定设备列表
 */
export function getUserNotChooseArmarium(data){
    return request({
        url: 'ruleArmarium/getUserNotChooseArmarium',
        method: 'GET',
        showLoading: true,
        params: data,
    })

}

/**
 * @description: 添加用户绑定设备
 */
 export function addUserArmariumRel(data) {
    return request({
        url: 'ruleArmarium/addUserArmariumRel',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 删除用户绑定设备
 */
 export function removeUserArmariumRel(data) {
    return request({
        url: 'ruleArmarium/removeUserArmariumRel',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 添加操作项绑定
 */
 export function addUserOperRel(data) {
    return request({
        url: 'userOperRel/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 添加操作项绑定
 */
 export function delUserOperRel(data,timeStamp) {
    return request({
        url: 'userOperRel/delete',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}