<!--
 * @Author: your name
 * @Date: 2022-09-01 13:46:38
 * @LastEditTime: 2022-09-15 09:55:17
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\statistics\index.vue
-->
<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-header height="auto" style="padding: 10px;">
            <el-row type="flex">
                <el-button type="primary" plain @click="expandTree()">
                    展开
                </el-button>
                <el-button type="primary" plain @click="foldTree()">
                    折叠
                </el-button>
            </el-row>
        </el-header>
        <el-main class="basic-main">
            <!-- 治疗统计 -->
            <el-tree
                :data="list"
                :props="defaultProps"
                @node-click="handleNodeClick"
                @node-expand="nodeExpand"
                @node-collapse="nodeCollapse"
                ref="treeNode"
                class="treatment-statistics-tree"
            >
                <div
                    v-if="data.level === 1"
                    class="custom-tree-node level1"
                    slot-scope="{ data }"
                >
                    <div class="statistics-table-title">
                        <span class="name">{{ data.empName }}</span>
                        <span>治疗数量 {{ data.count }}</span>
                        <span>金额 {{ data.price }}</span>
                        <span>其他治疗师数量 {{ data.otherCount }}</span>
                        <span>其他治疗师金额 {{ data.otherPrice }}</span>
                    </div>
                </div>
                <div
                    v-else-if="data.level === 2 && expandIds.includes(data.id)"
                    class="custom-tree-node level2"
                    slot-scope="{ data }"
                >
                    <div class="statistics-table-title">
                        <span class="name">{{ data.name }}</span>
                        <span>治疗数量 {{ data.count }}</span>
                        <span>金额 {{ data.price }}</span>
                        <span>其他治疗师数量 {{ data.otherCount }}</span>
                        <span>其他治疗师金额 {{ data.otherPrice }}</span>
                    </div>
                    <div style="padding: 10px 0;">
                        <el-table :data="data.operList" stripe border class="statistics-table">
                            <el-table-column prop="empName" label="治疗师" width="180"></el-table-column>
                            <el-table-column prop="operName" label="治疗项目"></el-table-column>
                            <el-table-column prop="count" label="数量" width="180"></el-table-column>
                            <el-table-column prop="price" label="金额" width="180"></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div v-else style="height: 0;"></div>
            </el-tree>
        </el-main>
    </el-container>
</template>
<script>
import searchForm from '@/components/SearchForm'
import { getList } from '@/api/treatmentStatistics'
export default {
    name: 'treatmentStatistics',
    components: {
        searchForm
    },
    data() {
        const datetimePickerOptions = {
            disabledDate(time) {
                return time.getTime() > Date.now();
            },
            ...this.commonConfig.$datetimePickerOptions
        }
        return {
            backStatus: true, //返回按钮的隐藏显示
            list: [],
            paramObj: {},
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入患者姓名',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入治疗师姓名',
                    key: 'empName',
                    label: '治疗师姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入治疗项',
                    key: 'operName',
                    label: '治疗项',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    datetimePickerOptions,
                    key: 'treatment',
                    label: '治疗时间',
                    defaultVal: []
                }
            ],
            defaultProps: {
                // children: 'scheduleVoList',
                label: 'name'
            },
            expandIds: [],
            expandRoots: []
        }
    },
    created() {
        console.log(
            '------moment',
            this.moment()
                .date(1)
                .format('YYYY-MM-DD'),
            this.moment().format('YYYY-MM-DD')
        )
        this.searchOptions = this.searchOptions.map(item => {
            if (item.key === 'treatment' && item.type === 'daterange') {
                return {
                    ...item,
                    defaultVal: [
                        this.moment()
                            .date(1)
                            .format('YYYY-MM-DD'),
                        this.moment().format('YYYY-MM-DD')
                    ]
                }
            } else {
                return {
                    ...item
                }
            }
        })
        const param = {
            treatmentStartTime: this.moment().date(1).valueOf(),
            treatmentEndTime: this.moment().valueOf()
        }
        this.search(param)
    },
    methods: {
        /**
         * @description:搜索
         * @param {*} data
         * @return {*}
         */
        search(data) {
            Object.assign(this.paramObj, data)
            console.log('------paramObj',data, this.paramObj)
            this.getList()
        },
        getList() {
            getList(this.paramObj).then(({ res }) => {
                console.log('------list', res)
                this.list = res.map(item => {
                    const { scheduleVoList, ...others } = item
                    const children = scheduleVoList.map(patItem => {
                        return {
                            ...patItem,
                            level: 2
                        }
                    })
                    return {
                        children,
                        ...others,
                        level: 1
                    }
                })
                console.log('------list', this.list)
            })
            
        },
        handleNodeClick(data) {
            console.log('------handleNodeClick', data)
        },
        nodeExpand(data) {
            if (data.level === 1) {
                this.expandIds = [...this.expandIds, ...data.children.map(item => item.id)]
            }
            console.log('------nodeExpand', this.expandIds)
        },
        nodeCollapse(data) {
            if (data.level === 1) {
                this.expandIds = this.expandIds.filter(item => !data.children.map(level => level.id).includes(item.id))
            }
            console.log('------nodeCollapse', this.expandIds)
        },
        //展开树
        expandTree() {
            this.$refs.treeNode.store.defaultExpandAll = true
            this.expandIds = this.list.map(item => item.children.map(leaf => leaf.id)).flat()
            const list = [...this.list]
            this.list = []
            setTimeout(() => {
                this.list = [...list]
            })
        },
        //折叠树
        foldTree() {
            this.$refs.treeNode.store.defaultExpandAll = false
            const list = [...this.list]
            this.list = []
            this.expandIds = []
            setTimeout(() => {
                this.list = [...list]
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.level1 {
    font-size: 14px;
    color: $titleColor;
    padding: 10px 0;
}
.level2 {
    font-size: 13px;
    color: $titleColor;
    padding-top: 10px;
    width: 100%;
    .name {
        font-weight: 700;
    }
    .statistics-table-title {
        width: 100%;
        padding: 10px;
        background-color: #edf5fe;
        color: $theme;
    }
}
.level1,
.level2 {
    span {
        margin-right: 10px;
    }
}
</style>
