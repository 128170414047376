
<!--
* @Description: 质控指标一览页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"
                @yearChange="yearChange" />
        </el-header>
        <el-main class="basic-main">
            <el-row :gutter="20">
                <el-col :xl="6" v-for="item in tableData" :key="item.qualityControlId">
                    <el-card class="card">
                        <div class="card-header">
                            <span>{{ item.name }}</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="card-body">
                            <div class="card-explain">
                                {{ item.description }}
                            </div>
                            <div class="card-data">
                                <div class="card-data-content">
                                    {{ commonConfig.$checkNullData(item.data) }}
                                </div>
                                <div class="card-data-icon" :class="{ enabled: item.state === '0' }"
                                    @click="toEdit(item)">
                                    <i class="el-icon-edit"></i>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="search" />
        </el-main>
    </el-container>
</template>

<script>
import { currentMonth, currentYear, getMonths, getYears } from "@/utils/qualityControl"
import { getList, getSelectList } from '@/api/indicatorSummary'
import searchForm from '@/components/SearchForm'
import addDialog from '@/views/quality/indicatorSummary/addDialog'

export default {
    name: 'IndicatorSummary',
    components: {
        searchForm,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'year',
                    label: '年份',
                    changeTrigger: true,
                    options: getYears(),
                    defaultVal: currentYear
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'month',
                    label: '月份',
                    options: getMonths(),
                    defaultVal: currentMonth
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'qualityControlId',
                    filterable: true,
                    clearable: false,
                    label: '指标',
                    options: [],
                    defaultVal: ""
                },
            ],
            paramObj: {
                year: currentYear,
                month: currentMonth,
                qualityControlId: ''
            }, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    async created() {
        const QCIds = await this.getSelectList(currentYear)
        this.searchOptions = this.searchOptions.map(item => {
            if (item.key === 'qualityControlId') {
                item.options = QCIds
            }
            return item
        })
        this.getList()
    },
    mounted: function () { },
    methods: {
        getSelectList(year) {
            return new Promise((resolve) => {
                getSelectList({ year }).then(({ res }) => {
                    resolve(res.map(item => ({ value: item.id, label: item.name })))
                })
            })
        },
        async yearChange(year) {
            const QCIds = await this.getSelectList(year)
            this.searchOptions = this.searchOptions.map(item => {
                if (item.key === 'qualityControlId') {
                    item.options = QCIds
                    item.defaultVal = ""
                }
                return item
            })
            this.paramObj = {
                ...this.paramObj,
                year,
                qualityControlId: ""
            }
            this.$refs.mainSearch.updataSearchData(this.paramObj)
        },
        search() {
            this.getList()
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        toEdit(row) {
            this.checkId = row.qualityControlId
            this.$refs.insert.updateVisible(true)
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const param = Object.assign({}, this.paramObj, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.card {
    &-header {
        width: 100%;
        @include show_line(1);
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    &-explain {
        width: 100%;
        height: 48px;
        @include show_line(2);
        line-height: 1.5;
    }

    &-data {
        display: flex;
        margin-top: 10px;
        height: 50px;

        &-content {
            height: 100%;
            flex: 1;
            display: flex;
            background-color: $dialogHeader;
            border-radius: 5px;
            align-items: center;
            font-size: 18px;
            justify-content: center;
            color: $text;
        }

        &-icon {
            display: flex;
            height: 100%;
            width: 50px;
            justify-content: center;
            align-items: center;
            color: $arrow;
            font-size: 18px;
        }

        &-icon.enabled {
            color: $theme;
            cursor: pointer;
        }
    }
}
</style>

