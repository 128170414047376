<!--
 * @Author: your name
 * @Date: 2022-11-18 08:52:51
 * @LastEditTime: 2022-12-09 16:37:15
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\folder\addDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        :title="id ? '修改病历夹' : '新增病历夹'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="病历夹名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入名称" maxlength="30"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="form.status" placeholder="请选择状态">
                            <el-option
                                v-for="item in statusOpts"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="描述" prop="content">
                        <el-input  maxlength="49" show-word-limit type="textarea" v-model="form.content" placeholder="请输入描述"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertCasesCollect, updateCasesCollect, casesCollectInfo } from '@/api/casesCollect'
import { casesTemplateInfo } from '@/api/caseReport'
export default {
    props: {
        id: String
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.id && this.getCasesCollectInfo()
            } else {
                this.$refs.insertForm.resetFields()
            }
        }
    },
    data() {
        return {
            timeStamp: '',
            form: {
                name: '',
                status: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入病历夹名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                status: [{ required: true, message: '请选择病历夹状态', trigger: 'change' }]
            },
            // 状态 0 启用 1禁用
            statusOpts: [
                { value: '0', label: '启用' },
                { value: '1', label: '禁用' }
            ],
            visible: false
        }
    },
    methods: {
        getCasesCollectInfo() {
            casesCollectInfo({ id: this.id }).then(({ res, timeStamp }) => {
                const { name, status, content } = res
                this.form = { name, status, content }
                this.timeStamp = timeStamp
            })
        },
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (!valid) {
                    return
                }
                let param = this.form
                if (this.id) {
                    param.id = this.id
                    updateCasesCollect(param, this.timeStamp).then(res => {
                        this.submitSuccess(res)
                    })
                } else {
                    insertCasesCollect(param).then(res => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
