/*
 * @Description: Api
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2021-12-27 13:20:40
 * @LastEditors: yzw
 * @Reference:
 */
import request from "@/utils/request";

/**
 * @description: 获取工作台列表
 * @param {Object} data
 */
export function getWorkbenchList(data) {
  return request({
    url: "/configWorkbench/getUserWorkbenchResult",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 获取工作台配置信息
 * @param {Object} data
 */
export function getWorkbenchInfo(data) {
  return request({
    url: "/configWorkbench/userConfigWorkbench",
    method: "GET",
    params: data,
    showLoading: true
  });
}

/**
 * @description: 更新工作台配置信息
 * @param {Object} data
 */
export function updateWorkbenchConfig(data, timeStamp) {
  return request({
    url: "/configWorkbenchUserRel/coverWorkbench",
    method: "POST",
    data,
    showLoading: true,
    timeStamp
  });
}

/**
 * @description: 获取默认工作台配置信息
 * @param {Object} data
 */
export function getAllWorkbench(data, timeStamp) {
  return request({
    url: "/configWorkbench/getAllWorkbench",
    method: "GET",
    params: data,
    timeStamp
  });
}