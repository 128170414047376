<!--
 * @Author: your name
 * @Date: 2021-12-29 13:54:24
 * @LastEditTime: 2022-08-19 13:38:53
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\patientScale.vue
-->
<template>
    <el-container :style="app ? {margin: 0, padding: 0} : {}">
        <el-main class="basic-main">
            <el-row>
                <el-col :sm="4" :md="4" :lg="3" :xl="3" v-if="scaleType === '2' && !app">
                    <el-button size="small" type="primary" plain @click="upload">
                        <i class="el-icon-plus"></i>
                        选择文件
                    </el-button>
                    <input
                        v-if="fileInput"
                        style="display: none"
                        type="file"
                        accept="image/*,video/*,audio/*"
                        @change="changeImageUrl($event)"
                        ref="uploadInput"
                    />
                    <div v-for="(item, index) in fileList" :key="item">
                        <div class="fileItem">
                            <el-image
                                v-if="item.type.indexOf('image') > -1"
                                style="width: 75px; height: 75px"
                                :preview-src-list="[commonConfig.$fileUrl + item.id]"
                                :src="commonConfig.$fileUrl + item.id"
                                fit="contain"
                            />
                            <svg-icon
                                v-if="item.type.indexOf('video') > -1"
                                icon-class="video"
                                :style="{
                                    color: '#469cf8',
                                    'font-size': '70px'
                                }"
                            />
                            <svg-icon
                                v-if="item.type.indexOf('audio') > -1"
                                icon-class="audio"
                                :style="{
                                    color: '#469cf8',
                                    'font-size': '70px'
                                }"
                            />
                            <div class="btnBox">
                                <el-button size="small" type="text" class="delBtn" @click="delFile(index)">
                                    删除
                                </el-button>
                                <el-button size="small" type="text" style="margin-left: 0px" @click="downLoad(item)">
                                    下载
                                </el-button>
                            </div>
                        </div>
                        <div class="fileText">{{ item.name }}</div>
                    </div>
                </el-col>
                <el-col :sm="20" :md="20" :lg="21" :xl="21">
                    <el-row type="flex" justify="space-between">
                        <div style="display: flex">
                            <template v-if="scaleType === '2' && !program">
                                <el-button v-if="status != 2" type="primary" @click="submit(1)">
                                    保存
                                </el-button>
                                <el-button type="primary" @click="submit(2)">提交</el-button>
                                <el-button v-if="!app" type="primary" @click="lastTimePatientAnswer()">复制</el-button>
                            </template>
                            <el-button v-if="showtype !== '1' && scaleType != 2 && !program && !app" type="primary" @click="printHtml()">
                                打印
                            </el-button>
                        </div>
                        <el-button v-if="scaleType != 4 && !program && !app" @click="back()">返回</el-button>
                    </el-row>
                    <div id="scaleIndex" v-if="showFlag" :style="app? {marginTop: '54px'}:{}">
                        <!-- 0 模板 1问卷 -->
                        <scaleIndex
                            ref="scaleIndex"
                            v-if="showtype === '0' && (!app || (app && apptoken))"
                            :app="app && apptoken"
                            :id="id"
                            :basicInfoId="basicInfoId"
                            :scaleType="scaleType"
                            :evaluateByName="form.evaluateByName"
                            :evaluateDate="form.evaluateDate"
                            :scaleId="scaleId"
                            :patientScaleId="patientScaleId"
                            @setevaluate="setevaluate"
                            @success="back()"
                            :fileList="fileList"
                            :libFlag="libFlag"
                            :showMould="showMould"
                            :showHeader ="showHeaderFlag"
                        />
                        <questionnaires
                            ref="questionnaires"
                            v-else
                            :id="id"
                            :basicInfoId="basicInfoId"
                            :scaleType="scaleType"
                            :evaluateByName="form.evaluateByName"
                            :evaluateDate="form.evaluateDate"
                            @setevaluate="setevaluate"
                            @success="back()"
                            :fileList="fileList"
                            :scaleId="scaleId"
                            :libFlag="libFlag"
                            :showMould="showMould"
                            :showHeader ="showHeaderFlag"
                        />
                        <!-- 由于打印插件无法获取elementUI css样式，因此评估人、评估时间打印部分要写行内样式 -->
                        <el-footer class="evaluate-zone" height="40px" v-if="!app">
                            <el-form
                                ref="evaluateInfo"
                                :model="form"
                                label-width="0px"
                                style="display: flex; column-gap: 15px"
                            >
                                <el-form-item label="">
                                    <div
                                        style="
                                            display: flex;
                                            align-items: center;
                                        "
                                    >
                                        <div style="margin-right: 15px">
                                            评估人
                                        </div>
                                        <el-input
                                            v-model="form.evaluateByName"
                                            placeholder="输入评估人"
                                            clearable
                                            maxlength="20"
                                            size="mini"
                                            style="width: 200px"
                                            :readonly="scaleType !== '2'"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="">
                                    <div
                                        style="
                                            display: flex;
                                            align-items: center;
                                        "
                                    >
                                        <div style="margin-right: 15px">
                                            评估时间
                                        </div>
                                        <el-date-picker
                                            v-model="form.evaluateDate"
                                            align="right"
                                            type="date"
                                            clear-icon="el-input__icon"
                                            placeholder="选择评估时间"
                                            :editable="false"
                                            style="width: 200px"
                                            :readonly="scaleType !== '2'"
                                        />
                                    </div>
                                </el-form-item>
                            </el-form>
                        </el-footer>
                    </div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import scaleIndex from '@/views/scale/scale/index.vue'
import questionnaires from '@/views/scale/scale/Questionnaires.vue'
import { uploadFile, downloadFile } from '@/api/common'
import { printHtml } from '@/utils/print'
import Cookies from 'js-cookie'
import uni from '@/utils/uniview'
export default {
    name: 'patientScale',
    components: {
        scaleIndex,
        questionnaires
    },
    props: {
        analysis: {
            type: Boolean,
            required: false
        },
        libFlag: {
            type: String,
            required: false
        },
        scaleId: {
            type: String,
            default: "",
            required: false
        },
        patientScaleId: {
            type: String
        },
        patientBasicInfoId: {
            type: String
        },
        flag: {
            type: String,
            required: false
        },
        showMould: {
            type: String,
            required: false
        },
        program: {//方案
            type: Boolean,
            required: false
        },
        showHeader: {// 是否显示表头
            type: Boolean,
            required: false
        },
        analysis: {
            type: Boolean,
            required: false
        },
    },
    watch: {
        $route(newVal, oldVal) {
            if (this.analysis) {
                return
            }
            const { flag, basicInfoId } = this.$route.query
            this.scaleType = this.analysis ? this.flag : flag
            this.basicInfoId = basicInfoId
            this.showFlag = true
        },
        patientScaleId(newVal, oldVal) {
            this.id = this.program ? this.scaleId : this.patientScaleId
            if (this.analysis) {
                this.scaleType = this.flag
                this.showFlag = true
            }
        },
        patientBasicInfoId() {
            this.basicInfoId = this.patientBasicInfoId
        },
        scaleId(newVal){
            if (newVal) {
                this.id = this.program ? this.scaleId : this.patientScaleId
                this.showScalePreview()
            }
        },
        showHeader(newVal){
            if (newVal) {
                this.showHeaderFlag = this.program ? this.showHeader : this.showHeaderFlag
            }
        }
    },
    data() {
        return {
            showFlag: false,
            app: true,
            apptoken: false,
            id: '',
            showtype: '2', // 0 模板 1问卷
            form: {
                evaluateByName: '',
                evaluateDate: new Date().getTime()
            },
            basicInfoId: '',
            rules: {
                evaluateByName: [
                    {
                        required: true,
                        message: '请输入评估人',
                        trigger: 'blur'
                    }
                ],
                evaluateDate: [
                    {
                        required: true,
                        message: '请选择评估时间',
                        trigger: 'change'
                    }
                ]
            },
            marksRule: '',
            resultsRule: '',
            fullMarks: '',
            scaleType: '',
            fileList: [],
            fileInput: true,
            status: '0', // 状态 0未开始 1未完成 2完成提交
            showHeaderFlag:true,
        }
    },
    beforeCreate(){
    },
    created() {
        console.log("------patient scale created")
        this.showFlag = true
        const {menuhide, publicKey, unique} = this.$route.query
        if (menuhide === "true" && publicKey && unique) {
            this.showtype = '0'
            const { flag, basicInfoId, patientScaleId } = this.$route.query
            this.id = patientScaleId
            this.showHeaderFlag = true
            this.scaleType = flag
            this.basicInfoId = basicInfoId
                // this.showScalePreview()
            // setTimeout(() => {
                this.app = true
                this.apptoken = true
                // this.$store.dispatch('getHosInfo').then(res => {
                // if (this.scaleId && this.analysis) {
                //     this.showScalePreview()
                //     return
                // }
                // this.id = this.program ? this.patientScaleId : patientScaleId
                
                // })
            // }, 500)
        } else {
            this.app = false
            this.$store.dispatch('getHosInfo').then(res => {
                if (this.scaleId && this.analysis) {
                    this.showScalePreview()
                    return
                }
                this.showtype = res.scaleShowType
                const { flag, basicInfoId, patientScaleId } = this.$route.query
                // this.id = this.program ? this.patientScaleId : patientScaleId
                this.id = this.analysis?this.patientScaleId: this.program ? this.patientScaleId : patientScaleId
                this.showHeaderFlag = this.program ? this.showHeader : this.showHeaderFlag
                this.scaleType = this.analysis ? this.flag : flag
                this.basicInfoId = this.analysis ? this.patientBasicInfoId : basicInfoId
                
            })
        }
        
    },
    mounted() {
        // uni.postMessage({
        //     data: {
        //         type: 'loaded',
        //     }
        // })
    },
    updated(){
        console.log("-----updated")
    },
    methods: {
        showScalePreview(){
            const { flag, basicInfoId } = this.$route.query
            this.scaleType = this.analysis ? this.flag : flag
            this.$store.dispatch('getHosInfo').then(res => {
                this.showtype = res.scaleShowType
                this.id = this.analysis?this.patientScaleId: this.program ? this.scaleId : this.patientScaleId
                this.showHeaderFlag = this.program ? this.showHeader : this.showHeaderFlag
                this.basicInfoId = this.scaleType === "2" ? basicInfoId : ""
                this.form.evaluateByName = ""
                this.form.evaluateDate = 0 
                this.fileList  = []
                this.showFlag = true
            })
        },
        printHtml() {
            printHtml('scaleIndex')
        },
        downLoad(row) {
            const parm = { id: row.id }
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement('a')
                dom.style.display = 'none'
                const blob = new Blob([res], {
                    type: row.type
                })
                const url = window.URL.createObjectURL(blob)
                dom.href = url
                dom.setAttribute('download', row.name)
                document.body.appendChild(dom)
                dom.click()
            })
        },
        delFile(index) {
            this.fileList.splice(index, 1)
        },
        upload() {
            this.$refs.uploadInput.click()
        },
        /**
         * @description: 设置从scaleIndex传回的评估人/评估时间/文件列表
         * @param {*}
         * @return {*}
         */
        setevaluate(evaluateByName, evaluateDate, status, fileList) {
            this.form.evaluateByName = evaluateByName
            this.form.evaluateDate = evaluateDate
            this.status = status
            this.fileList = fileList.map((item, index) => {
                return {
                    name: item.name,
                    id: item.id,
                    type: item.contentType
                }
            })
        },
        changeImageUrl(event) {
            this.changePcImage(event)
        },
        changePcImage(event){
            this.fileInput = false
            let file = event.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            let formData = new FormData()
            formData.append('file', file, file.name)
            uploadFile(formData).then(({ res }) => {
                this.fileInput = true
                this.fileList.push({
                    id: res,
                    name: file.name,
                    type: file.type
                })
            })
        },
        back() {
            if (this.app) {
                return
            }
            this.showFlag = false
            setTimeout(() => {
                this.$router.back()
            }, 10)
        },
        lastTimePatientAnswer() {
            this.$refs.scaleIndex && this.$refs.scaleIndex.lastTimePatientAnswer()
            this.$refs.questionnaires && this.$refs.questionnaires.lastTimePatientAnswer()
        },
        quesToMap({answerList, questionList}){
            let answerMap = {}
            let questionListMap = {}
            answerList.forEach(item => {
                answerMap[item.scaleQuestionId]= item
            })
            questionList = questionList.map(item => {
                const validRuleJSON = JSON.parse(`{${item.validRule}}`) // 规则
                // PC show  0 输入框 1 box
                if (!answerMap[item.id]) {
                    return {...item, selectedIndexs: [], selectedIds: [], validRule: validRuleJSON}
                }
                if (["0"].includes(item.answerType) && item.pcShow === "0") {
                    return {
                        ...item, 
                        answerContent: answerMap[item.id].answerLsn,
                        validRule: validRuleJSON
                    }
                }
                if (["0", "1"].includes(item.answerType)) {
                    const answerLsn = answerMap[item.id].answerLsn ? answerMap[item.id].answerLsn.split(",") : []
                    const optionMap = {}
                    item.optionList.forEach((option, optIndex) => {
                        optionMap[option.lsn] = {...option, index: (optIndex + 1)}
                    })
                    const selectedIndexs = answerLsn.map(lsn => {
                        return optionMap[lsn].index
                    })
                    const selectedIds = answerLsn.map(lsn => {
                        return optionMap[lsn].id
                    })
                    return {
                        ...item, 
                        selectedIndexs,
                        selectedIds,
                        validRule: validRuleJSON
                    }
                } 
                return {
                    ...item, 
                    answerContent: answerMap[item.id].answerContent,
                    validRule: validRuleJSON
                }
            })
            questionList.forEach(item => {
                questionListMap[item.answerCoordinate] = item
            })
            return questionListMap
        },
        /**
         * @description: 提交之前检查评估人和评估时间
         * @param {*}
         * @return {*}
         */
        submit(type, success, failure) {
            this.$refs.evaluateInfo.validate(valid => {
                if (!valid) {
                    return
                }
                this.$refs.scaleIndex && this.$refs.scaleIndex.submit(type, success, failure)
                this.$refs.questionnaires && this.$refs.questionnaires.submit(type, success, failure)
                // if (this.app) {
                //     this.$refs.scaleIndex && this.$refs.scaleIndex.getSubData(type, (resData) => {
                //         if (!resData) {
                //             return
                //         }
                //         uni.postMessage({
                //             data: {
                //                 type: 'submit',
                //                 flag: type,
                //                 resData
                //             }
                //         })
                //     })
                // } else {

                // }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.fileItem {
    display: flex;
    align-items: center;
}
.btnBox {
    width: min-content;
}
.fileText {
    font-size: 14px;
    color: #333333;
}
.app-button {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    z-index: 10;
    width: 100vw;
    background-color: #ffffff;
    margin-top: 0;
    top: 0;
    left: 0;
    padding-right: 10px;
    border-bottom: 1px solid #dedede;
    box-sizing: border-box;
}
</style>
