
<!--
* @Description: 我的文档页面
* @Reference:
-->

<template>
    <el-container>
        <el-aside>
            <el-input v-model="name" placeholder="请输入目录信息" clearable maxlength="20" class="aside-input" />
            <el-tree ref="tree" class="doc-tree" node-key="id" :highlight-current="true" :data="treeData" default-expand-all :filter-node-method="filterTree" :expand-on-click-node="false" @node-click="onClickTreeRow">
                <span class="custom-tree-node" slot-scope="{node, data}">
                    <span>{{ node.label }}</span>
                    <!-- 知识库：status为3，不管是否配置权限都只显示添加按钮 -->
                    <!-- 系统分类：status为2， 配置权限的显示添加按钮 -->
                    <!-- 除系统分类外的系统菜单：status为0，配置权限的显示添加/修改/删除按钮 -->
                    <!-- 个人菜单：status为1，显示添加/修改/删除按钮 -->
                    <span class="row-icon">
                        <template v-if="data.status === '3'">
                            <el-button class="tree-icon" type="text" icon="el-icon-plus" @click="() => appendNode(data)" />
                        </template>
                        <template v-else-if="data.status === '2' && baseTypeAdd">
                            <el-button class="tree-icon" type="text" icon="el-icon-plus" @click="() => appendNode(data)" />
                        </template>
                        <template v-else-if="(data.status === '0' && baseTypeAdd) || data.status === '1'">
                            <el-button class="tree-icon" type="text" icon="el-icon-minus" @click="() => removeNode(node, data)" />
                            <el-button class="tree-icon" type="text" icon="el-icon-plus" @click="() => appendNode(data)" />
                            <el-button class="tree-icon" type="text" icon="el-icon-edit-outline" @click="() => editNode(node, data)" />
                        </template>
                    </span>
                </span>
            </el-tree>
        </el-aside>
        <el-container class="inside-container">
            <el-header height="auto">
                <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
            </el-header>
            <el-main class="basic-main">
                <el-row class="table-btn-group">
                    <el-button :disabled="!canAdd" type="primary" @click="toInsert()">新增</el-button>
                </el-row>
                <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" v-if="myDocumentDetail" @click="toDetail(scope)">详情</el-button>
                        <el-button type="text" v-if="myDocumentAdd" @click="toEdit(scope)">编辑</el-button>
                        <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                    </template>
                </generalTable>
                <!-- <detailDialog :id="checkId" ref="detail" /> -->
                <addNodeDialog :nodeInfo="nodeInfo" ref="insertNode" :timeStamp="timeStamp" @submit="getTreeList" />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import moment from 'moment'
import { getList, getTreeList, deleteData, insertTreeNode, deleteNodeData } from '@/api/myDocument'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addNodeDialog from '@/views/knowledgeBase/myDocument/addNodeDialog'
// import detailDialog from '@/views/knowledgeBase/myDocument/detailDialog'

export default {
    name: 'MyDocument',
    components: {
        searchForm,
        generalTable,
        // detailDialog,
        addNodeDialog
    },
    data() {
        return {
            tableData: [], // 表格数据
            treeData: [], // 左侧树结构数据
            nodeInfo: {}, // 要操作的节点信息
            name: '', // 目录名称
            searchOptions: [ // 搜索选项
                {
                    type: 'input',
                    placeholder: '请输入名称...',
                    key: 'name',
                    label: '名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择状态',
                    key: 'status',
                    filterable: true,
                    multiple: false,
                    label: '状态',
                    options: [
                        {
                            value: '',
                            label: '全部'
                        },
                        {
                            value: '0',
                            label: '公开'
                        },
                        {
                            value: '1',
                            label: '私有'
                        },
                    ],
                    defaultVal: []
                },
            ],
            checkNode: {}, // 选中节点
            tableConfig: {// 表格配置
                id: 'myDocument',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return moment(row.createDate).format(
                                'YYYY-MM-DD HH:mm:ss'
                            )
                        }
                    },
                    {
                        label: '分类', // 必填
                        prop: 'typeName' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            // 状态（0公开、1私有）
                            const statusMap = {
                                "0": "公开",
                                "1": "私有"
                            }
                            return statusMap[row.status]
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            myDocumentDetail: false, // 文档详情权限
            myDocumentAdd: false, // 文档添加权限
            canAdd: true, // 所选类型是否可新增文档
            baseTypeAdd: false, // 系统类型可添加权限
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            treeTimeStamp: '' // 目录树时间戳
        }
    },
    watch: {
        /** 监听name值变化
         * @description: 发生变化时，执行树插件的前台过滤函数
         * @param {String} newVal 变化后的值
         * @param {String} orgVal 变化钱的值
         * @return {*}
         */        
        name(newVal, orgVal) {
            this.$refs.tree.filter(newVal) // 树插件中filter-node-method绑定的函数
        }
    },
    /** 页面创建时的操作
     * @description: 获取该用户权限，获取左侧类型树数据
     * @param {*}
     * @return {*}
     */    
    created() {
        this.baseTypeAdd = this.hasPermission('base-type-add') // 系统菜单可新增文档权限
        this.myDocumentDetail = this.hasPermission('myDocumentDetail') // 文档详情权限
        this.myDocumentAdd = this.hasPermission('myDocumentAdd') // 文档添加权限
        this.getTreeList()
    },
    mounted: function () {},
    methods: {
        /** 右侧表格搜索
         * @description: 根据右侧搜索选项内容获取数据，获取数据时，使用第一页参数
         * @param {*} data
         * @return {*}
         */        
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /** 获取类型树列表
         * @description: 获取成功后，将返回数据绑定到类型树，保存时间戳，将第一节点作为选中节点，获取节点对应的文档
         * @param {*}
         * @return {*}
         */        
        getTreeList() {
            getTreeList().then(({ res, timeStamp }) => {
                this.treeData = [...res]
                this.treeTimeStamp = timeStamp
                const {children, ...checkNode} = res[0]
                this.checkNode = checkNode
                this.getList()
            })
        },
        /** 获取文档列表
         * @description: 根据选中类型的id获取列表，将数据同步到表格插件中
         * @param {*}
         * @return {*}
         */        
        getList() {
            this.$nextTick(() => {
                const param = this.getParam(this.checkNode.id)
                getList(param).then(({ res, timeStamp}) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = Number(res.total)
                })
            })
        },
        /** 新增子类型
         * @description: 在所选类型下新增类型；
         *      将要添加节点的status、parentId及parentIds放入到nodeInfo中
         *      如果所选节点为知识库，则为个人类型(status="1")；
         *      如果所选节点为一级系统菜单，则为系统类型(status="0")；
         *      其余节点与父级类型保持一致
         *      parentId为选中节点的id
         *      parentIds为选中节点的parentIds后拼接id
         *      放入后显示添加类型弹窗
         * @param {Object} data 要添加子节点的节点
         * @return {*}
         */        
        appendNode(data) {
            const {id, parentIds, status} = data
            this.nodeInfo.parentId = id
            let newStatus = ""
            // status: 0系统、1个人
            if (status === "3") {// 知识库
                newStatus = "1"
            } else if (status === "2") {// 系统分类
                newStatus = "0"
            } else { // 其余系统或个人节点
                newStatus = status
            }
            this.nodeInfo.status = newStatus
            this.nodeInfo.parentIds = `${parentIds}${parentIds ? "," : ""}${id}`
            this.nodeInfo.id = ""
            this.$refs.insertNode.updateVisible(true)
        },
        /** 编辑类型
         * @description: 所选节点的status、parentId及parentIds绑定到nodeInfo中
         *      放入后显示添加类型弹窗
         * @param {Object} node 要编辑的节点
         * @param {Object} data 要编辑的节点对应的数据
         * @return {*}
         */        
        editNode(node, data) {
            const {parentId, parentIds, status, id} = data
            this.nodeInfo = {parentId, parentIds, status, id}
            this.$refs.insertNode.updateVisible(true)
        },
        /** 移除类型
         * @description: 移除前进行弹窗确认，确认后调用删除接口，成功后显示成功提示并重新获取树节点
         *      注：如果该节点下有文档，则会导致节点删除不成功
         * @param {*} node
         * @param {*} data
         * @return {*}
         */        
        removeNode(node, data) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: data.id }
                deleteNodeData(param, this.treeTimeStamp).then((res) => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.getTreeList()
                })
            })
        },
        /**左侧树结构点击事件
         * @description: 点击节点后，将节点数据更新到checkNode中，
         *      根据节点状态更新新增文档按钮是否可点击，并获取对应的文档列表
         * @param {Object} obj 选中的类型数据
         * @return {*}
         */        
        onClickTreeRow(obj) {
            this.checkNode = obj
            // 无添加权限的用户，无法在系统菜单下添加文档
            if (!this.baseTypeAdd && ["0", "2"].includes(obj.status)) {
                this.canAdd = false
            } else {
                this.canAdd = true
            }
            // 根据所选节点显示右侧表格
            this.getList()
        },
        // 通过部门名称过滤树结构
        /** 过滤树节点
         * @description: 对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
         * 如果内容为空则直接放回true，不进行过滤；
         * 如果内容不为空，按label进行查找，找到内容的返回true，找不到的返回false
         * @param {String} value
         * @param {*} data
         * @return {*}
         */        
        filterTree(value, data) {
            if (!value) return true
            return data.label.indexOf(value) !== -1
        },
        /** 添加文档
         * @description: 
         *      如果有选中类型的id，则将该[id]作为路由参数的parentId，传入到页面中，进行跳转
         *      如果没有选中类型的id，则进行消息提示并阻止页面跳转
         * @param {*}
         * @return {*}
         */        
        toInsert() {
            if (!this.checkNode.id) {
                this.$message({
                    message: '请选择要添加的目录！',
                    type: 'warning'
                })
                return false
            }
            this.$router.push({
                path: '/knowledgeBase/myDocument/add',
                query: {
                    parentId: [this.checkNode.id]
                }
            })
        },
        /** 文档详情
         * @description: 将选中类型的id以及文档id，传入到页面中，进行跳转
         * @param {Object} scope 插槽参数，scope.data.row为当前行对应的数据
         * @return {*}
         */        
        toDetail(scope) {
            this.$router.push({
                path: '/knowledgeBase/myDocument/detail',
                query: {
                    parentId: [this.checkNode.id],
                    id: scope.data.row.id,
                }
            })
        },
        /** 编辑文档
         * @description: 将选中类型的id、文档id以及时间戳，传入到页面中，进行跳转
         * @param {Object} scope 插槽参数，scope.data.row为当前行对应的数据
         * @return {*}
         */ 
        toEdit(scope) {
            this.$router.push({
                path: '/knowledgeBase/myDocument/add',
                query: {
                    parentId: [this.checkNode.id],
                    id: scope.data.row.id,
                    timeStamp: this.timeStamp
                }
            })
        },
        /** 删除文档
         * @description: 删除前进行删除确认
         *      确认后，调用删除文档接口
         *      删除成功后，进行消息提示并重新获取所选类型下的文档列表
         * @param {*} scope
         * @return {*}
         */        
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.getList()
                })
            })
        },
        /** 获取参数
         * @description: 将传入的类型id、搜索框及表格插件翻页信息拼接到一起，将结果进行返回
         * @param {String} typeId 类型id
         * @return {Object} 要发送的参数
         */        
        getParam(typeId) {
            const paramObj = this.$refs.mainSearch.packageData() // 搜索框参数
            const page = this.$refs.mainTable.getPage() // 表格翻页参数
            const param = {
                ...this.paramObj,
                ...page,
                ...paramObj,
                typeId
            }
            this.paramObj = param
            return param
        },
        /** 自定义返回按钮
         * @description: 自定义返回路径,可为空
         */     
        customBack(){
            this.$router.replace('/knowledgeBase')
        }
    }
}
</script>



<style lang='scss' scoped>
@import '~@/styles/variables.scss';
.doc-tree {
    border: 1px solid $tableColor;
    height: calc(100% - 124px);
    overflow-y: auto;
    .row-icon {
        padding-left: 10px;
        // 左侧树功能按钮间隔
        .tree-icon:not(:first-of-type) {
            margin-left: 15px;
        }
    }
}
</style>

        