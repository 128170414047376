/*
 * @Author: your name
 * @Date: 2022-11-18 11:15:06
 * @LastEditTime: 2022-11-18 11:18:47
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\casesCollectTree.js
 */

import request from '@/utils/request'

/**
 * @description: 病历夹树列表
 * @param {Object} data
 */
export function getCollectTree(data) {
    return request({
        url: '/casesCollectTree/getCollectTree',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 新增病例夹树信息
 * @param {Object} data
 */
export function insertCasesCollectTree(data) {
    return request({
        url: 'casesCollectTree/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 更新病例夹树信息
 * @param {Object} data
 */
export function updateCasesCollectTree(data, timeStamp) {
    return request({
        url: 'casesCollectTree/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改病历夹树状态
 * @param {Object} data
 */
export function updateCasesCollectTreeStatus(data, timeStamp) {
    return request({
        url: 'casesCollectTree/updateStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除病例夹树节点
 * @param {Object} data
 */
export function deleteCasesCollectTree(data, timeStamp) {
    return request({
        url: 'casesCollectTree/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
