
<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增菜单管理' : '修改菜单管理'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      :rules="rules"
    >
      <div class="mdrcc-info-title">菜单信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="路由标题(CN)" class="formItem" prop="name">
            <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="页面名称(EN)" class="formItem" prop="pageName">
            <el-input v-model="form.pageName" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="组件(import)" class="formItem" prop="component">
            <el-input v-model="form.component" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="重定向" class="formItem" prop="redirect">
            <el-input v-model="form.redirect" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="类型" class="formItem" prop="type">
            <el-select v-model="form.type" clearable placeholder="请选择">
              <el-option v-for="item in menuType" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="上级目录" class="formItem" prop="parentId">
            <el-cascader
              v-model="form.parentId"
              :options="tableData"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
              }"
              clearable
              filterable
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="跳转路径(path)" class="formItem" prop="path">
            <el-input v-model="form.path" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="权限标识" class="formItem" prop="permission">
            <el-input v-model="form.permission" placeholder="请输入..." clearable maxlength="70" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="排序" class="formItem" prop="sort">
            <el-input v-model="form.sort" placeholder="请输入..." clearable maxlength="20" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="图标" class="formItem" prop="icon">
            <el-input v-model="form.icon" placeholder="请输入..." clearable maxlength="50" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="是否缓存" class="formItem" prop="keepAlive">
            <el-switch
              v-model="form.keepAlive"
              name="keepAlive"
              active-text="是"
              active-value="0"
              inactive-text="否"
              inactive-value="1"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="是否隐藏" class="formItem" prop="hidden">
            <el-switch
              v-model="form.hidden"
              name="hidden"
              active-text="是"
              active-value="1"
              inactive-text="否"
              inactive-value="0"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="显示面包屑导航" class="formItem" prop="breadcrumb">
            <el-switch
              v-model="form.breadcrumb"
              name="breadcrumb"
              active-text="是"
              active-value="0"
              inactive-text="否"
              inactive-value="1"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" class="formItem" prop="status">
            <el-switch
              v-model="form.status"
              name="status"
              active-text="启用"
              active-value="0"
              inactive-text="禁用"
              inactive-value="1"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="备注" class="formItem" prop="remark">
            <el-input v-model="form.remark" type="textarea" maxlength="200"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/menu'
export default {
    props: {
        id: String,
        parentId: String,
        tableData: Array,
        timeStamp: String
    },
    data() {
        return {
            rules: {
                name: [
                    // 中文菜单名
                    {
                        message: '请输入菜单中文名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在 2 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                type: [
                    // 菜单类型
                    {
                        required: true,
                        message: '请选择菜单类型',
                        trigger: 'change'
                    }
                ],
                icon: [
                    // 图标
                    {
                        message: '请输入菜单图标',
                        trigger: 'blur'
                    }
                ],
            },
            form: {
                name: '', // 菜单中文名
                pageName: '', // 菜单英文名
                component: '', // 模块路径
                redirect: '', // 页面重定向
                type: '', // 菜单类型
                id: '', // 当前数据id
                parentId: '', // 父级id
                path: '', // 路由路径
                permission: '', // 权限
                sort: 0, // 排序 数字
                icon: '', // 图标
                keepAlive: '1', // 是否缓存 0缓存 1不缓存
                hidden: '0', // 是否隐藏 0显示 1隐藏
                status: '0', // 禁用/启用状态 0启用 1禁用
                remark: '' // 备注
            },
            visible: false, // 可见性， 默认不可见
            menuType: [
                // 菜单列表
                { value: '1', label: '菜单' },
                { value: '2', label: '按钮' },
                { value: '3', label: '其他' },
            ]
        }
    },
    watch: {
        /** 监听模块可见性
         * @description:
         *      显示模块时，查看该模块是否传入了parentId，如果传入，同步到form.parentId中
         *      隐藏模块时，清空所有表单内容
         * @param {*} newVal 当前值
         * @param {*} oldVal 上一状态值
         * @return {*}
         */
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.parentId) {
                    this.form.parentId = this.parentId
                }
                if (this.id) {
                    this.getDetail()
                }
            } else {
                this.$refs.insertForm.resetFields()
            }
        }
    },
    created() {
      
    },
    mounted() {},
    methods: {
        /** 提交
         * @description:
         *      如果parentId为数组，则将parentId更新为数组最后一项
         *      如果传入了id值，则进入编辑菜单接口；否则进入新增菜单接口
         *      成功后，进入提交成功函数
         * @param {*}
         * @return {*}
         */
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = {
                        ...this.form,
                        id: this.id
                    }
                    if (typeof parm.parentId !== 'string') {
                        parm.parentId = parm.parentId[parm.parentId.length - 1]
                    }
                    if (!this.id) {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        /** 提交成功函数
         * @description:
         *      进行提交成功信息提示
         *      隐藏当前弹窗
         *      触发父级模块'@submit'绑定的函数
         * @param {*} res
         * @return {*}
         */
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        /** 菜单详情
         * @description: 将获取的菜单详情信息同步到form数据中
         * @param {*}
         * @return {*}
         */
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.form = res
            })
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        },
    }
}
</script>
<style scoped>
</style>

