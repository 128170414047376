
<!--
* @Description: 评估量表库页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row v-if="scaleInsert" class="table-btn-group"
        ><el-button type="primary" @click="toInsert()">新增</el-button></el-row
      >
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="sort" slot-scope="scope">
          <el-input
            type="text"
            v-model="scope.data.row.sort"
            class="cell-input"
            style="width: 90px"
            name
            @blur="changeSort(scope, $event.target.value)"
          />
        </template>
        <template slot="status" slot-scope="scope">
          <el-switch
            v-model="scope.data.row.status"
            inactive-value="1"
            active-value="0"
            v-if="scaleDisable"
            @change="changeStatus(scope, $event)"
          />
        </template>
        <template slot="handle" slot-scope="scope">
          <el-button v-if="scaleListDetail" type="text" @click="toDetail(scope)"
            >预览</el-button
          >
          <el-button
            v-if="scaleListEdit"
            :disabled="scope.data.row.numberUses != 0"
            type="text"
            @click="toEdit(scope)"
            >编辑</el-button
          >
          <el-button v-if="scaleListCopy" type="text" @click="toCopy(scope)"
            >复制</el-button
          >
          <el-button
            type="text"
            v-if="basicScaleConfig"
            @click="toConfig(scope)"
          >汇总配置</el-button>
          <el-button
            v-if="scaleListDel"
            :disabled="scope.data.row.numberUses != 0"
            type="text"
            class="delBtn"
            @click="toDel(scope)"
            >删除</el-button
          >
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" libFlag="privateLib" />
      <addDialog
        :id="checkId"
        libFlag="privateLib"
        ref="insert"
        :timeStamp="timeStamp"
        @submit="search"
      />
      <issuedDialog
        :id="checkId"
        ref="issued"
        libFlag="privateLib"
        @submit="search"
      />
      <configDialog :id="checkId" ref="config" :fromPrivate="true" @submit="search" />
    </el-main>
  </el-container>
</template>

<script>
import searchForm from "@/components/SearchForm";
import addDialog from "@/views/scale/publicScaleLib/addDialog";
import issuedDialog from "@/views/scale/publicScaleLib/issuedDialog";
import detailDialog from "@/views/scale/publicScaleLib/detailDialog";
import generalTable from "@/components/Table";
import {
  getList,
  getTypeScale,
  disableScale,
  deleteData,
  changeSort,
} from "@/api/privateScaleLib";
import { getUserInfo } from "@/api/user";
import configDialog from "@/views/scale/publicScaleLib/configDialog";

export default {
  name: "PrivateScaleLib",
  components: {
    addDialog,
    detailDialog,
    searchForm,
    generalTable,
    issuedDialog,
    configDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入模板名称...",
          key: "name",
          label: "模板名称",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "全部",
          key: "scaleTypeId",
          filterable: true,
          multiple: false,
          label: "评定分类",
          options: [],
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入版本号...",
          key: "versionNo",
          label: "版本",
          defaultVal: "",
        },
        {
          type: "cascader",
          placeholder: "机构名称",
          key: "deptId",
          label: "机构名称",
          options: [],
          defaultVal: [],
          props: {
            checkStrictly: true,
            label: "label",
            value: "id",
            emitPath: false,
            // multiple: true
          },
        },
      ],
      tableConfig: {
        id: "privateScaleLib",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "评定分类", // 必填
            prop: "scaleTypeName", // 必填
          },
          {
            label: "模板名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "版本", // 必填
            prop: "versionNo", // 必填
          },
          {
            label: "排序", // 必填
            slot: "sort", // 必填
          },
          {
            label: "所属机构", // 必填
            prop: "deptName", // 必填
          },
          {
            label: "创建时间", // 必填
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            label: "修改时间", // 必填
            prop: "updateDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.updateDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            label: "状态", // 必填
            slot: "status", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      deptOptions: [], //所属科室
      scaleTypeOptions: [], //评定分类
      scaleListDetail: true, //详情权限
      scaleListEdit: true, //编辑权限
      scaleListDel: true, //删除权限
      scaleDisable: true, //停用启用权限
      scaleInsert: true, //新增量表模板
      scaleListCopy:true,//复制
      basicScaleConfig: true, // 汇总配置
    };
  },
  created() {
    this.getList();
    this.getDepartment();
    this.getTypeScale();
    this.scaleListDetail = this.hasPermission("scale-list-detail");
    this.scaleListEdit = this.hasPermission("scale-list-edit");
    this.scaleListDel = this.hasPermission("scale-list-del");
    this.scaleDisable = this.hasPermission("scale-list-disable");
    this.scaleInsert = this.hasPermission("scale-list-insert");
    this.scaleListCopy = this.hasPermission("scale-list-copy");
    this.basicScaleConfig = this.hasPermission("basic-scale-config")
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = res.total;
          this.timeStamp = timeStamp;
        });
      });
    },
    /**
     * @description:获取评定类型
     * @param {*}
     * @return {*}
     */
    getTypeScale() {
      getTypeScale().then(({ res, timeStamp }) => {
        res.records.forEach((element) => {
          const ele = {
            value: element.id,
            label: element.name,
          };
          this.scaleTypeOptions.push(ele);
        });
        this.searchOptions[1].options = this.scaleTypeOptions;
      });
    },
    /**
     * @description: 获取机构
     * @param {*}
     * @return {*}
     */
    getDepartment() {
      const param = {};
      getUserInfo(param).then(({ res, timeStamp }) => {
        this.searchOptions[3].options = res.deptLevelTreeList;
      });
    },
    /**
     * @description:修改量表模板状态
     * @param {*}
     * @return {*}
     */
    changeStatus(scope, event) {
      // event switch更新前状态
      // index 数据序号
      const { id, status } = scope.data.row;
      disableScale({ id, status: status }, this.timeStamp)
        .then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        })
        .catch((err) => {
          if (err) {
            this.tableData[scope.data.$index].status = event === 0 ? "1" : "0";
          }
        });
    },
    /**
     * 排序验证修改
     * @description: 排序字段必须为数字
     * @param {*} event 要验证的数据
     * @return {*}
     */
    changeSort(scope, event) {
      const param = {
        id: scope.data.row.id,
        sort: event,
      };
      if (/^[0-9]+$/.test(event) === false) {
        this.$message({ type: "warning", message: "请输入数字" });
      } else {
        changeSort(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
          this.submitSuccrss();
        });
      }
    },
    /**
     * @description:删除
     * @param {*}
     * @return {*}
     */
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.submitSuccrss();
        });
      });
    },
    /**
     * @description:复制
     * @param {*}
     * @return {*}
     */
    toCopy(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.issued.updateVisible(true);
    },
    submitSuccrss() {
      this.getList();
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toConfig(scope){
      this.checkId = scope.data.row.id;
      this.$refs.config.updateVisible(true);
    },
     toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    // console.log("------preview------", scope.data.row)
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.textBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stopText {
  width: 40px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $tableColor;
  border-radius: 5px;
  background-color: $disable;
  color: $arrowActive;
}
.enableText {
  width: 40px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $theme;
  border-radius: 5px;
  background-color: $active;
  color: $theme;
  font-size: 12px;
}
</style>

        