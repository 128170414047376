
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增操作项目' : '修改操作项目'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
              <el-col :sm="12" :md="12" :lg="8" :xl="12">
                  <el-form-item label="所属治疗" prop="operType">
                    <el-select
                        v-model="form.operType"
                        clearable
                        placeholder="请选择"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        style="width:100%"
                        @change='selectBToCare($event)'
                    >
                        <el-option
                          v-for="option in bToCares"
                          :key="option.id"
                          :label="option.label"
                          :value="option.value"
                        />
                    </el-select>
                  </el-form-item>
              </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="12">
                  <el-form-item label="操作项目" prop="operId">
                      <el-select
                          v-model="form.operId"
                          clearable
                          placeholder="请选择"
                          :filterable="true"
                          :multiple="false"
                          :collapse-tags="true"
                          style="width:100%"
                          @change='selectOperation($event)'
                      >
                          <el-option
                            v-for="option in operations"
                            :key="option.value"
                            :label="option.label"
                            :value="option.value"
                          />
                      </el-select>
                  </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
               <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="建议分钟数" prop="propMin">
                      <el-input
                        v-model="form.propMin"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                      />
                    </el-form-item>
               </el-col>
               <el-col :sm="12" :md="12" :lg="8" :xl="12">
                  <el-form-item label="单次数量" prop="schCount">
                      <el-input
                        v-model="form.schCount"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                      />
                  </el-form-item>
               </el-col>
            </el-row>
            <el-divider />
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">关 闭</el-button>
            <el-button type="primary" @click="submit()">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getOperations} from '@/api/ruleSet'
import { getDict } from '@/api/common'

export default {
    props: {
        id: {
            type: String
        }
    },
    components: {

    },
    data() {
        return {
            checkBtoCares:'',
            rules: {
                operType: [
                    { required: true, message: '请择所属治疗', trigger: 'change' }
                ],
                operId: [
                    { required: true, message: '请择操作项目', trigger: 'change' }
                ],
                propMin: [
                    {required: true, message: '请输入建议分钟数', trigger: 'blur' }
                ],
                schCount: [
                    {required: true, message: '请输入单次数量', trigger: 'blur' }
                ]
            },
            bToCares: [],
            operations: [],
            form: {
                checkbox: [],
                operType: "",
                operId: "",
                propMin: "",
                schCount: "",
                operName: "",
                operTypeName: ""
            },
            total: 0,
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
          }
        }
    },
    created() {
        this.getBToCares();
    },
    mounted() {},
    methods: {
        submit() {
          this.$refs.insertForm.validate((valid) => {
              if (valid) {
                this.$emit("add", this.form);
                this.updateVisible(false);
              }
          })
        },

        /**
        　* 根据所属治疗获取操作项目
        　* @Description: 所属治疗下拉框change事件响应方法,选择所属治疗时，保存选择的所属治疗名称和值，并且根据所属治疗获取操作项目
          * @Param 所属治疗选择值
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:47
        　*/
        selectBToCare(e) {
            const selectObj = this.bToCares.filter(element => element.value === e)[0];
            this.form.operTypeName = selectObj.label
            this.checkBtoCares=e;
            this.getOperations();
        },

        /**
        　* 选择操作项目时设置操作项目名称
        　* @Description: 操作项目下拉框change事件响应方法
          * @Param  操作项目选择值
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:50
        　*/
        selectOperation(e) {
            const selectObj = this.operations.filter(element => element.value === e)[0];
            this.form.operName = selectObj.label
        },

        /**
          　* 获取操作项目
          　* @Description: 所属治疗为空时获取全部操作项目，不为空时，根据所属治疗获取操作项目，病区渲染操作项目下拉框
            * @Param
            * @Return
          　* @Author wangjh
          　* @Date 2021/11/11 9:51
          　*/
        getOperations() {
            this.operations = [];
            let params = {};
            if (this.checkBtoCares) {
              params.operType=this.checkBtoCares;
            }
            getOperations(params).then(({ res, timeStamp }) => {
              this.operations = res.map((element) => {
                  return {
                        value: element.id,
                        label: element.name,
                        id: element.id
                  }
              });
              if (this.operations[0]) {
                this.form.operId = this.operations[0].value;
                this.form.operName = this.operations[0].label;
              } else {
                this.form.operId ='';
                this.form.operName='';
              }
            })
        },

        /**
        　* 获取所属治疗列表
        　* @Description:
          * @Param null
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:52
        　*/
        getBToCares() {
            getDict({ type: 'oper_type' }).then(({res}) => {
                this.bToCares = res.oper_type.map((element) => {
                  return {
                    value: element.value,
                    label: element.label,
                    id: element.id
                  }
                });
                this.form.operType = this.bToCares[0].value;
                this.checkBtoCares = this.bToCares[0].value;
                this.form.operTypeName = this.bToCares[0].label;
                this.getOperations();
            });
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            } else {
              this.getBToCares();
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
