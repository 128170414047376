
/**
 * 页面: 收费项目
 * pageName: feeItems
 **/
import request from '@/utils/request'

/**
 * @description: 获取收费项目列表数据
 */
export function getList(data) {
    return request({
        url: 'ruleCharge/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 查询收费项目下所绑定的操作项目列表
 */
 export function getOperationList(data) {
    return request({
        url: 'ruleCharge/listOperation',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增收费项目数据
 */
export function insertData(data) {
    return request({
        url: 'ruleCharge/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改收费项目数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'ruleCharge/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取收费项目详情数据
 */
export function getDetail(data) {
    return request({
        url: 'ruleCharge/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 批量删除收费项目数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: 'ruleCharge/deleteData',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

        
/**
 * @description: 获取notIds收费项目列表数据
 */
 export function getListByNotIds(data) {
    return request({
        url: 'ruleCharge/getListByNotIds',
        method: 'GET',
        params: data,
        showLoading: true
    })
}