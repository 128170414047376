<!--
 * @Description: 系统日志
 * @Author: yzw
 * @Date: 2021-02-03 10:49:05
 * @LastEditTime: 2022-02-09 09:11:00
 * @LastEditors: yzw
 * @Reference:
-->

<template>
    <el-scrollbar style="height: 100%; width: 100%">
        <el-container>
            <el-header height="auto">
                    <searchForm
                        ref="mainSearch"
                        :searchoptions="searchOptions"
                        :back-status="true"
                        @search="getTableData"
                    />
            </el-header>
            <el-main class="basic-main">
				<generalTable
                    ref="mainTable"
                    :table-data="tableData"
                    :config="tableConfig"
					:total="totalNum"
                    @updatePage="getTableData"
                >
					<template slot="handle" slot-scope="scope">
						<el-button
							type="text"
							
							style="margin-right: 15px"
							@click="toDetail(scope.data.row)"
							>详情</el-button
						>
					</template>
				</generalTable>
            </el-main>
            <myDialog
                :dialog-title="'日志详情'"
                :dialog-visible="dialogVisible"
                :form="form"
                @show="showDialog"
            />
        </el-container>
    </el-scrollbar>
</template>

<script>
import { getRecordList } from "@/api/record";
import moment from "moment";
import myDialog from "@/views/system/record/dialog";
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
export default {
    name: "Record",
    components: {
        myDialog,
		searchForm,
		generalTable,
    },
    data() {
        return {
            pickerOptions: this.commonConfig.$datePickerOptions,
            totalNum: 0, // 总条数
            tableData: [], // 表格数据
            dialogVisible: false,
            detail: {},
            form: {
                executorDevice: "",
                executorTime: "",
                executorUser: "",
                methodName: "",
                paramJson: "",
                sqlInfo: "",
            },
			searchOptions:[
				{
                    type: 'input',
                    key: 'trueName',
                    label: '操作人',
                    defaultVal: ''
                },
                {
                    type: 'datetimerange',
                    key: 'date',
                    label: '时间',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    key: 'searchStr',
                    label: '详情',
                    defaultVal: ''
                },
			],
			tableConfig:{
				id: "user",
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "traceId", // 必填
                        prop: "traceId", // 不必填 单列插槽
                    },
                    {
                        label: "操作人", // 必填
                        prop: "executorUser", // 不必填 单列插槽
                    },
					{
                        label: "操作时间", // 必填
                        prop: "executorTime", // 不必填 单列插槽
						formatter: (row, column) => {
                            return this.moment(row.executorTime).format('YYYY-MM-DD HH:mm:ss')
                        }
                    },
					{
                        label: "操作设备", // 必填
                        prop: "executorDevice", // 不必填 单列插槽
                    },
					{
                        label: "详情", // 必填
                        formatter: (row, column) => {
                            return row.controllerTag+'-'+row.methodDescribed
                        }
                    },
					{
                        label: "URL", // 必填
                        prop: "uri", // 不必填 单列插槽
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "120", // 不必填
                    },
                ],
			}
        };
    },
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        getTableData() {
            const info = this.$refs.mainSearch.packageData();
            if (info.endTime&&info.startTime) {
                // endDate	结束时间	query	false	string(date-time)
                // startDate	开始时间	query	false	string(date-time)
                info.startDate =
                    moment(info.startTime).unix().toString() + "000";
                info.endDate =
                    moment(info.endTime).unix().toString() + "000";
            } else {
                info.startDate && delete info.startDate;
                info.endDate && delete info.endDate;
            }
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({},info,page);
            getRecordList(param).then(({res}) => {
                const { total, records } = res;
                this.tableData = records;
                this.totalNum = parseInt(total);
            });
        },
        showDialog(ifShow) {    //显示弹出框
            this.dialogVisible = ifShow;
        },
        toDetail(row) {     //打开详情
            if (row) {
                this.form.id = row.id;
                this.form = row;
				this.dialogVisible = true;
            }
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
