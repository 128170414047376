<!--
 * @Author: SixTeen
 * @Date: 2021-11-25 15:22:24
 * @LastEditTime: 2022-09-01 09:33:25
 * @LastEditors: ZF-WLY
 * @Description: 排班汇总
 * @FilePath: /recovery_management/src/views/treatment/scheduleSummary/index.vue
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-table
                ref="schedulTable"
                border
                :data="tableData.list"
                :cell-style="cellStyle"
                header-row-class-name="table-header"
            >
                <el-table-column
                    fixed
                    label="序号"
                    prop="date"
                    width="50"
                    align="center"
                    type="index"
                >
                </el-table-column>
                <el-table-column
                    fixed
                    label="所属机构"
                    prop="date"
                    width="150"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.user.deptName }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed
                    label="姓名"
                    prop="date"
                    width="150"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button @click="toDetail(scope)" type="text">{{
                            scope.row.user.userName
                        }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed
                    label="已排数量"
                    prop="date"
                    width="100"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.user.scheduleNum }}</span>
                    </template>
                </el-table-column>
                <template v-for="(time, column) in tableData.timeList">
                    <el-table-column
                        width="150"
                        align="center"
                        :key="time[0]"
                        :label="time[0] + '-' + time[1]"
                        :column-key="time[2]"
                    >
                        <template
                            slot-scope="scope"
                            v-if="scope.row.timeList[column].length > 0"
                        >
                            <div
                                v-for="item in scope.row.timeList[column]"
                                :key="item.id"
                            >
                                <el-popover trigger="hover" placement="top">
                                    <div class="popverBox">
                                        <div class="popoverName">
                                            {{ item.patientName }}
                                        </div>
                                        <p v-if="item.bedNum">
                                            {{ item.bedNum }}床
                                        </p>
                                    </div>
                                    <p class="popverP">
                                        {{ item.serialNum }}
                                    </p>
                                    <p class="popverP">
                                        计划时间:{{ item.scheduleTime }}-{{
                                            item.scheduleEndTime
                                        }}
                                    </p>
                                    <p class="popverP" v-if="item.scheduleType==='0'">
                                        治疗项目/设备:{{ item.operName }}
                                    </p>
                                    <p class="popverP" v-else>
                                        治疗项目/设备:{{ item.armariumName }}
                                    </p>
                                    <div slot="reference" class="name-wrapper">
                                        <div size="medium" class="patientBox">
                                            {{ item.patientName }}
                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </template>
                        <template v-else></template>
                    </el-table-column>
                </template>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
import { getList } from "@/api/scheduleSummary";
import searchForm from "@/components/SearchForm";
import { getUserInfo } from "@/api/user";

export default {
    name: "ScheduleSummary",
    components: {
        searchForm,
    },
    data() {
        return {
            timeList: [],
            tableData: [],
            searchOptions: [
                {
                    type: "cascader",
                    placeholder: "机构名称",
                    key: "deptId",
                    label: "机构名称",
                    options: [],
                    defaultVal: [],
                    props: {
                        checkStrictly: true,
                        label: "label",
                        value: "id",
                        emitPath: false,
                        // multiple: true
                    },
                },
                {
                    type: "input",
                    placeholder: "请输入工作者姓名",
                    key: "userName",
                    label: "姓名",
                    defaultVal: "",
                },
            ],
            paramObj: null, // 筛选项
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
        };
    },
    created() {
        this.getDepartment(); //获取机构
        this.getList();
    },
    mounted: function () {},
    methods: {
        /**
         * @description: 动态修改非工作时间
         * @param {*} row
         * @return {*}
         */
        cellStyle(row) {
            if (row.column.columnKey == 1) {
                return "background-color:#f8f8f8";
            }
        },
        /**
         * @description:搜索
         * @param {*} data
         * @return {*}
         */
        search(data) {
            Object.assign(this.paramObj, data);
            this.getList();
        },
        /**
         * @description: 获取机构
         * @param {*}
         * @return {*}
         */
        getDepartment() {
            const param = {};
            getUserInfo(param).then(({ res, timeStamp }) => {
                this.searchOptions[0].options = res.deptLevelTreeList;
            });
        },
        /**
         * @description: 获取列表数据
         * @param {*}
         * @return {*}
         */
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    this.timeStamp = timeStamp;
                });
            });
        },

        /**
         * @description: 跳转医生 日程
         * @param {*} scope
         * @return {*}
         */
        toDetail(scope) {
            this.$router.push({
                path: "/treatment/schedule/my",
                query: { userId: scope.row.user.userId },
            });
        },
        /**
         * @description: 搜索参数整合
         * @param {*}
         * @return {*}
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const param = Object.assign({}, this.paramObj, paramObj);
            this.paramObj = param;
            return param;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";

.table-header {
    background-color: $tableColor !important;
    color: $black;
}

.patientBox {
    background-color: #e9f1fb;
    color: $theme;
    height: 30px;
    line-height: 30px;
    margin: 5px 0px;
}
.popverBox {
    width: 200px;
    height: 30px;
    padding: 0 10px;
    background-color: $dialogHeader;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popoverName {
    font-weight: bold;
}
.popverP {
    padding: 0 10px;
}
.dataBox {
    width: 100%;
    height: 100%;
    background-color: #123456;
}
</style>