<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '发起协同' : '编辑协同'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="协同主题" prop="subject">
            <el-input
              v-model="form.subject"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="协同类型" prop="type">
            <el-select
              v-model="form.type"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in cooperativeType"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="计划开始时间" prop="usesTime">
            <el-date-picker
              v-model="form.usesTime"
              align="right"
              type="datetime"
              placeholder="选择时间"
              :editable="true"
              style="width: 100%"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="计划结束时间" prop="usedTime">
            <el-date-picker
              v-model="form.usedTime"
              align="right"
              type="datetime"
              placeholder="选择时间"
              :editable="true"
              style="width: 100%"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="协作人员" prop="joinId">
            <el-select
              v-model="form.joinId"
              clearable
              placeholder="请选择"
              :filterable="true"
              :multiple="true"
              :collapse-tags="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in users"
                :key="option.id"
                :label="option.trueName"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="患者" prop="patientName">
            <el-input
              v-model="form.patientName"
              placeholder="请搜索..."
              clearable
              maxlength="20"
              disabled=""
              class="searchInput"
            />
            <el-button type="primary" plain @click="toChoosePatient()"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="描述" prop="description">
            <el-input
              v-model="form.description"
              rows="3"
              type="textarea"
              placeholder="请输入"
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="附件：">
            <input
              style="display: none"
              type="file"
              @change="changeFile($event)"
              ref="fileInput"
            />
            <el-button size="small" type="primary" @click="setFileInput()">点击上传</el-button>
            <div v-for="o in form.files" class="text item">
              {{ o.name }}
              <el-button type="text" class="delBtn" @click="toDelFile(o.id)"
              >删除</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <patients ref="patients" @submit="checkedPatient" />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">保存</el-button>
      <el-button type="primary" plain @click="saveAndPublis()">保存并发布</el-button>
      <el-button @click="updateVisible(false)">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { editData, getDetail, insertData } from "@/api/communication";
import { getUserList } from "@/api/user";
import { uploadFile, getDict } from "@/api/common";
import patients from "@/views/treatment/mySchedule/addDialog";
import moment from "moment";

export default {
  props: {
    id: {
      type: String
    },
    timeStamp: {
      type: String
    }
  },
  components: {
    patients
  },
  data() {
    const validateUseTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择时间"));
      } else if (
        moment(this.form.usesTime).isAfter(moment(this.form.usedTime))
      ) {
        callback(new Error("开始时间应早于结束时间"));
      } else {
        callback();
      }
    };
    return {
      checkId: "",
      rules: {
        description:[
          { max: 200, message: "至多200字", trigger: "blur" }
        ],
        subject: [
          { required: true, message: "请输入协同主题", trigger: "blur" },
          { min: 1, max: 32, message: "长度在 2 到 32 个字符", trigger: "blur" }
        ],
        usesTime: [
          {
            required: true,
            trigger: 'change',
            validator: validateUseTime
          }
        ],
        usedTime: [
          {
            required: true,
            trigger: 'change',
            validator: validateUseTime
          }
        ],
        type: [
          { required: true, message: "请选择协同类型", trigger: "change" }
        ],
        joinId: [
          {
            required: true,
            message: "请至少选择一个协作人员",
            trigger: "change"
          }
        ],
        patientName: [
          { required: true, message: "请选择患者", trigger: "change" }
        ]
      },
      form: {
        subject:'',
        usesTime:'',
        usedTime:'',
        type:'',
        joinId:[],
        patientName:'',
        basicInfoId:'',
        fileId:[],
        files:[],
        publishAfterSubmit:false
      },
      total: 0,
      visible: false,
      cooperativeType: [],
      users: []
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    }
  },
  created() {
    this.getUserList();
    this.getCooperativeType();
  },
  mounted() {},
  methods: {
    toDelFile(fid){
      this.form.files.splice(this.form.files.findIndex(f=>f.id==fid),1)
    },
    setFileInput() {
      this.$refs.fileInput.click();
    },
    changeFile(event){
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append("file", file, file.name);
      uploadFile(formData).then(({ res }) => {
        const f = {
          name: file.name,
          id: res
        }
        this.form.files.push(f);
      });
    },
    /**
     * 选择患者后的回调函数
     * */
    checkedPatient(patient) {
      this.form = {
        ...this.form,
        patientName: patient.patientName,
        basicInfoId: patient.basicInfoId
      };
    },
    getUserList() {
      const param = {pageFlag:1,status:0}
      getUserList(param).then(({ res, timeStamp }) => {
        this.users = res.records;
      });
    },
    /**获取协同类型列表
     * @description:
     * @param {*}
     * @return {*}
     * */
    getCooperativeType() {
      getDict({ type: "cooperative_type" }).then(({ res }) => {
        this.cooperativeType = res.cooperative_type.map(item => {
          const ele = {
            value: item.value,
            label: item.label
          };
          return ele;
        });
      });
    },
    /** 显示患者弹窗
     * @description:
     * @param {*}
     * @return {*}
     */
    toChoosePatient() {
      this.$refs.patients.updateVisible(true);
    },

    saveAndPublis() {
      this.form.publishAfterSubmit = true;
      this.submit();
    },
    submit() {
      this.$refs.insertForm.validate(valid => {
        const param = this.form;
        if (valid) {
          let fileId = [];
          param.files.map(f=>{{
            fileId.push(f.id)
          }})
          const parm = Object.assign({},param, {
            usesTime: moment(param.usesTime).valueOf(),
            usedTime: moment(param.usedTime).valueOf(),
            fileId: fileId
          });
          if (this.id === "") {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    /** 更新弹出层显示状态
     * @description: 关闭时，清空form中的所有内容
     * @param {Boolean} val true时显示；false时隐藏
     * @return {*}
     */
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
        this.form.files = []
        this.form.publishAfterSubmit = false
      }
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        let joinId = [];
        res.cooperativeUsers.map(user => {
          joinId.push(user.id);
        });
        let files = [];
        res.files.map(file=>{
          files.push({name:file.name+"."+file.suffix,id:file.id})
        })
        this.form.files = files;
        let formObj = {
          usesTime: moment(res.usesTime).format("YYYY-MM-DD HH:mm"),
          usedTime: moment(res.usedTime).format("YYYY-MM-DD HH:mm"),
          joinId,
          files
        }
        this.form = {...res,...formObj}
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
