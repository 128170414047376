<!--
 * @Author: your name
 * @Date: 2021-12-24 10:03:45
 * @LastEditTime: 2021-12-28 14:34:58
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\check.vue
-->

<template>
    <el-main>
        <el-row v-if="!data.length">{{message}}</el-row>
        <el-row class="card-wrapper" v-for="item in data" :key="item.id">
            <div class="mdrcc-info-title">{{item.name}}：</div>
            <div class="card-row">
                <div>检查所见：{{item.checkSee}}</div>
                <div>检查结果：{{item.checkResult}}</div>
                <div>检查时间：{{item.checkTime ?moment(item.checkTime).format("YYYY-MM-DD") : commonConfig.$nullData}}</div>
            </div>
        </el-row>
    </el-main>
</template>

<script>
export default {
    name: 'checkPat360',
    props: {
        type: {
            type: String
        },
        timeStamp: {
            type: String
        },
        data: {
            type: Array
        }
    },
    data(){
        return {
            message: "暂无数据"
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.card-row {
    background-color: $dialogHeader;
    padding: 10px;
    border-radius: 4px;
    &>div {
        margin-bottom: 10px;
    }
}
.card-wrapper:not(:last-of-type) {
    .card-row {
        margin-bottom: 20px;
    }
}
</style>