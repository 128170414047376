<!--
* @Description: 患者一览
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :backStatus="true" :searchoptions="searchOptions" @search="search" />
        </el-header>
        <el-main class="basic-main">
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
            >
                <template slot="handle" slot-scope="scope">
                    <el-button @click="toDetail(scope.data.row.id)" type="text">详情</el-button>
                </template>
            </generalTable>
        </el-main>
    </el-container>
</template>

<script>
import { getList, getDept } from '@/api/patient'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: 'Patient',
    components: {
        searchForm,
        generalTable
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'sex',
                    filterable: true,
                    label: '性别',
                    options: this.commonConfig.$sexOptions,
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phone',
                    label: '手机号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'idCard',
                    label: '身份证号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'serialNum',
                    label: '就诊号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'recordNum',
                    label: '住院号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '性别', // 必填
                        prop: 'sex', // 必填
                        formatter: (row, column) => {
                            return this.commonConfig.$sexList[row.sex]
                        },
                        width: '50' // 不必填
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'phone' // 必填
                    },
                    {
                        label: '身份证号', // 必填
                        prop: 'idCard' // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'serialNum' // 必填
                    },
                    {
                        label: '住院号', // 必填
                        prop: 'recordNum' // 必填
                    },
                    {
                        label: '所属科室', // 必填
                        prop: 'deptName' // 必填
                    },
                    {
                        label: '门诊次数', // 必填
                        prop: 'mzCount', // 必填
                        width: '80' // 不必填
                    },
                    {
                        label: '住院次数', // 必填
                        prop: 'zyCount', // 必填
                        width: '80' // 不必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '80' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            deptOptions: [] // 所属科室
        }
    },
    created() {
        this.getList()
        this.getOptions()
    },
    mounted: function() {},
    methods: {
        toDetail(id) {
            this.$router.push({ path: '/patient/list/detail', query: { id: id } })
        },
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /**
         * @description:获取表格数据
         * @param {*}
         * @return {*}
         */
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getOptions() {
            getDept({ deptType: '4' }).then(({ res, timeStamp }) => {
                res.forEach(element => {
                    const ele = {
                        value: element.id,
                        label: element.label
                    }
                    this.deptOptions.push(ele)
                })
                this.searchOptions[6].options = this.deptOptions
            })
        },
        /**
         * @description: 获取查询参数
         * @param {*}
         * @return {*}
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
