<!--
 * @Description:
 * @Author: yzw
 * @Date: 2021-10-13 09:50:37
 * @LastEditTime: 2022-02-25 09:40:31
 * @LastEditors:  yzw
 * @Reference:
-->

<template>
    <el-dialog
        width="60%"
        title="用户详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions class="margin-top" title="基本信息" :column="4">
            <el-descriptions-item label="用户姓名">{{ detail.trueName || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="工号">{{ detail.workNumber || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="登录账号">{{ detail.username || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ detail.phone || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="出生日期">{{ detail.birthday ? moment(detail.birthday).format("YYYY-MM-DD") : commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="岗位" >{{ detail.positionLabels[0] || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="职位名称" >{{ detail.jobTitleLabels[0] || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="所属机构" >{{ departmentList ? departmentList.join("，") : commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="角色" >{{ detail.roleLabel ? detail.roleLabel.join("，") : commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="治疗区" >{{ detail.consultingRoomLabels[0] || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="是否登录">{{ detail.login === '0' ? '是' : '否' }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ detail.status === '0' ? '启用' : '禁用' }}</el-descriptions-item>
            <el-descriptions-item label="备注" :span="4">{{ detail.remark || commonConfig.$nullData }}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import { getUserInfo } from '@/api/user'
import { getDict } from '@/api/common'
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            departmentList: [],
            detail: {
                positionLabels: [],
                consultingRoomLabels: [],
                deptList: [],
                jobTitleLabels:[],
                departmentList: null,
                roleLabel: null
            } // 详情数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) {
                this.getUserInfo()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        getUserInfo() {
            getUserInfo({ id: this.id }).then(({ res }) => {
                this.detail = { ...this.detail, ...res.sysUserDetailsRes }
                this.detail.roleLabel = this.getRoleLables(
                    this.detail.roleList,
                    res.roleResList
                )
                this.departmentList = []
                this.getDepLables(this.detail.deptList, res.deptLevelTreeList)
                getDict({ type: 'position' }).then(({res: docRes}) => {
                    this.detail.positionLabels = this.getDocLabels(
                        this.detail.position,
                        docRes.position
                    )
                })
                //获取治疗区名称字典项
                getDict({ type: 'consulting_room' }).then(({res: docRes}) => {
                    this.detail.consultingRoomLabels = this.getRoomLabels(
                        this.detail.consultingRoom,
                        docRes.consultingRoom
                    )
                })
                //获取职位名称字典项
                getDict({ type: 'job_title' }).then(({res: docRes}) => {
                    this.detail.jobTitleLabels = this.getDocLabels(
                        this.detail.jobTitle,
                        docRes.job_title
                    )
                })
            })
        },
        // 通过返回数据 处理 对应权限id显示的文本
        getRoleLables(roleList, roleResList) {
            const roleLabel = []
            for (const i of roleResList) {
                if (roleList.includes(i.id)) {
                    roleLabel.push(i.name)
                }
            }
            return roleLabel
        },
        // 通过返回数据 处理 对应机构id显示的文本
        getDepLables(depList, depTreeArray) {
            depTreeArray && depTreeArray.forEach((item) => {
                if (depList.includes(item.id)) {
                    this.departmentList.push(item.name)
                }
                if (item.children) {
                    this.getDepLables(depList, item.children)
                }
            })
        },
        // 通过返回数据 处理 对应岗位id显示的文本
        getDocLabels(doctorPosition, doctorArray) {
            const positionLabels = []
            for (const item of doctorArray) {
                if (doctorPosition.includes(item.value)) {
                    positionLabels.push(item.label)
                }
            }
            return positionLabels
        },
        updateVisible(flag) {
            this.visible = flag
        },
        // 通过返回数据 处理 对应岗位id显示的文本
        getRoomLabels(doctorPosition, doctorArray) {
            const consultingRoomLabels = []
            for (const item of doctorArray) {
                if (doctorPosition.includes(item.value)) {
                    consultingRoomLabels.push(item.label)
                }
            }
            return consultingRoomLabels
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped>
</style>
