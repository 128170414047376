<!--
 * @LastEditors: ligc 951906427@qq.com
 * @Author: Richardlv
-->

<template>
    <el-container class="page-main">
        <el-header ref="header" height="auto">
            <div style="display:flex;margin:10px 0 5px 0">
                <el-descriptions class="margin-top" :column="5">
                    <el-descriptions-item label="姓名">
                        {{ details.name || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">{{ commonConfig.$sexList[details.sex] }}</el-descriptions-item>
                    <el-descriptions-item label="年龄">
                        {{ details.age || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号">
                        {{ details.idCard || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号码">
                        {{ details.phone || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="本人住址">
                        {{ details.address || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="家属姓名">
                        {{ details.faName || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="家属手机号">
                        {{ details.faPhone || commonConfig.$nullData }}
                    </el-descriptions-item>
                    <el-descriptions-item label="与患者关系">
                        {{ details.faRelation || commonConfig.$nullData }}
                    </el-descriptions-item>
                </el-descriptions>
                <div style="display:flex;align-items: center;margin-bottom:5px">
                    <!-- <el-button v-if="editStatus" class="edit-btn" type="primary" plain @click="toEdit()">
                        编辑
                    </el-button> -->
                    <el-button type="default" @click="back()">返回</el-button>
                </div>
            </div>
        </el-header>
        <div ref="time" class="timeLineAllBox">
            <div v-if="timeLineButtun" class="buttonBox" @click="changeTest(1)">
                <i class="el-icon-arrow-left"></i>
            </div>
            <div ref="timeLineBox" class="timeLineBox">
                <div ref="timeLine" class="timeLine" :style="{ left: xOffset + 'px' }">
                    <div v-for="item in timeLineList" :key="item.id" class="timeLineItem" @click="selectItem(item)">
                        <div
                            v-if="selectId == item.id"
                            class="timeLineTitle"
                            :style="{
                                color: timeLineTextColor[item.status],
                                'background-color': timeLineBGColor[item.status]
                            }"
                        >
                            {{ timeLineTextList[item.status] }}
                        </div>
                        <div v-if="selectId != item.id" class="timeLineTitle">{{ timeLineTextList[item.status] }}</div>
                        <div class="timeLineLine">
                            <div
                                v-if="selectId == item.id"
                                class="timeLinePoint"
                                :style="{
                                    color: timeLineTextColor[item.status],
                                    'border-color': timeLineTextColor[item.status]
                                }"
                            />
                            <div v-if="selectId != item.id" class="timeLinePoint" />
                        </div>
                        <div class="timeLineTime">
                            {{ moment(item.heDte).format('YYYY-MM-DD') }}至{{
                                item.leaveDate ? moment(item.leaveDate).format('YYYY-MM-DD') : commonConfig.$nullData
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="timeLineButtun" class="buttonBox" @click="changeTest(-1)">
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <el-tabs class="tabs" ref="tabs" v-model="activeName" style type="border-card" tab-position="left">
            
            <el-tab-pane class="tab-pane" :label="timeLineTextList[selectStatus] + '信息'" name="0">
                <div class="tab1Box">
                    <div class="mdrcc-info-title">{{ timeLineTextList[selectStatus] }}</div>
                    <el-descriptions :column="4">
                        <el-descriptions-item label="所属科室">
                            {{ commonConfig.$checkNullData(treatmentInfo.deptName) }}
                        </el-descriptions-item>
                        <el-descriptions-item label="床号">
                            {{ commonConfig.$checkNullData(treatmentInfo.bedNum) }}
                        </el-descriptions-item>
                        <el-descriptions-item label="主治医生">
                            {{ commonConfig.$checkNullData(treatmentInfo.impDocName) }}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="selectStatus == 0 || selectStatus == 1" label="管床医生">
                            {{ commonConfig.$checkNullData(treatmentInfo.bedDocName) }}
                        </el-descriptions-item>
                        <el-descriptions-item :label="selectStatus == 0 || selectStatus == 1 ? '入院日期' : '就诊时间'">
                            {{ commonConfig.$checkNullData(treatmentInfo.heDte, 'YYYY-MM-DD') }}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="selectStatus == 0 || selectStatus == 1" label="住院编号">
                            {{ commonConfig.$checkNullData(treatmentInfo.recordNum) }}
                        </el-descriptions-item>
                        <el-descriptions-item label="就诊编号">
                            {{ commonConfig.$checkNullData(treatmentInfo.serialNum) }}
                        </el-descriptions-item>
                    </el-descriptions>

                    <div class="mdrcc-info-title">疾病诊断</div>
                    <el-row class="treat-row" v-for="item in treatmentInfo.diagnosisList" :key="item.id">
                        <div class="treat-label el-descriptions__body">
                            {{ moment(item.dateTime).format('YYYY-MM-DD HH:mm') }}
                        </div>
                        <div class="treat-content el-descriptions-item__content">{{ item.content }}</div>
                    </el-row>

                    <div class="mdrcc-info-title">阶段目标</div>
                    <el-row class="treat-row" v-for="item in treatmentInfo.targetList" :key="item.id">
                        <div class="treat-label el-descriptions__body">
                            {{ moment(item.dateTime).format('YYYY-MM-DD HH:mm') }}
                        </div>
                        <div class="treat-content el-descriptions-item__content">{{ item.content }}</div>
                    </el-row>

                    <div class="mdrcc-info-title">主诉</div>
                    <el-descriptions :column="4" :colon="false">
                        <el-descriptions-item labelStyle="display:none">
                            {{ commonConfig.$checkNullData(treatmentInfo.chiefComplaint) }}
                        </el-descriptions-item>
                    </el-descriptions>

                    <div class="mdrcc-info-title">现病史</div>
                    <el-descriptions :column="4" :colon="false">
                        <el-descriptions-item labelStyle="display:none">
                            {{ commonConfig.$checkNullData(treatmentInfo.medicalHistory) }}
                        </el-descriptions-item>
                    </el-descriptions>

                    <div class="mdrcc-info-title">既往病史</div>
                    <el-descriptions :column="4" :colon="false">
                        <el-descriptions-item labelStyle="display:none">
                            {{ commonConfig.$checkNullData(treatmentInfo.pastMedicalHistory) }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-tab-pane>
            <el-tab-pane class="tab-pane" label="会诊记录" name="1">
                <record class="tab1Box" v-if="selectId" :id="selectId"/>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="康复医嘱" name="2">
                <generalTable
                    ref="adviceTable"
                    :table-data="adviceTableData"
                    :config="adviceTableConfig"
                    :total="adviceTotal"
                    :newHeight="tableHeight"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button v-if="adviceListDetail" type="text" @click="toAdviceDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="康复评定" name="3">
                <generalTable
                    ref="assessmentTable"
                    :table-data="assessmentTableData"
                    :config="assessmentTableConfig"
                    :total="assessmentTotal"
                    :newHeight="tableHeight"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button
                            type="text"
                            v-if="scope.data.row.patientScaleType === '0'"
                            @click="toAssessmentDetailByScale(scope)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            v-if="scope.data.row.patientScaleType === '2'"
                            @click="toAssessmentDetailByReport(scope)"
                        >
                            查看
                        </el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="治疗排程" name="4">治疗排程</el-tab-pane>
            <el-tab-pane :lazy="true" label="治疗记录" name="5">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(0)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="treatmentTableData"
                    :config="treatmentTableConfig"
                    :total="treatmentTotal"
                    @updatePage="getTreatMentList"
                    :newHeight="tableHeight - 58"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="toTreatmentDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane>
            <el-tab-pane label="治疗小结" name="6">
                <conclusion v-if="selectId" clas6s="tab1Box" :basicInfoId="selectId" />
            </el-tab-pane>
            <el-tab-pane label="治疗计划" name="7">
                <treatmentPlan v-if="selectId" class="tab1Box" :basicInfoId="selectId" />
            </el-tab-pane>
            <el-tab-pane label="病历夹" name="8">
                <caseReport v-if="selectId" class="tab1Box" :basicInfoId="selectId" />
            </el-tab-pane>
            <!--<el-tab-pane :lazy="true" label="住院查房" name="7">住院查房</el-tab-pane>
            <el-tab-pane :lazy="true" label="出院指导" name="8">出院指导</el-tab-pane>
            <el-tab-pane :lazy="true" label="康复随访" name="9">康复随访</el-tab-pane>
            <el-tab-pane :lazy="true" label="检查" name="10">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(1)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="inspectionTableData"
                    :config="inspectionTableConfig"
                    :total="inspectionTotal"
                    @updatePage="getInspectionList"
                    :newHeight="tableHeight - 58"
                ></generalTable>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="检验" name="11">
                <el-row class="table-btn-group">
                    <el-button type="primary" @click="toExportTreatMent(2)">导出</el-button>
                </el-row>
                <generalTable
                    ref="treatmentTable"
                    :table-data="emrTableData"
                    :config="emrTableConfig"
                    :total="emrTotal"
                    @updatePage="getEmrList"
                    :newHeight="tableHeight - 58"
                >
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="getEmrDetail(scope)">详情</el-button>
                    </template>
                </generalTable>
            </el-tab-pane> -->
        </el-tabs>
        <addDialog :id="id" ref="insert" :timeStamp="timeStamp" :form="details" @closeDialog="getDetailById" />
        <adviceDetailDialog :id="adviceCheckId" ref="adviceDetail" />
        <treatmentDetailDialog :id="TreatmentCheckId" ref="TreatmentDetail" />
        <!-- </el-main> -->
    </el-container>
</template>

<script>
import { getDetail } from '@/api/patient'
import generalTable from '@/components/Table'
import addDialog from '@/views/patient/patient/addDialog'
import moment from 'moment'
import adviceDetailDialog from '@/views/advice/detailDialog'
import conclusion from '@/views/patient/summary'
import {
    getTreatmentInfo,
    getInspectionList,
    exportInspection,
    getEmrList,
    exportEmr,
    getAssessmentList
} from '@/api/patient'
import record from '@/components/Record'
import { getTreatMentList } from '@/api/arrangeTreatment'
import treatmentDetailDialog from '@/views/treatment/arrangeTreatment/detailDialog'
import { exportTreat } from '@/api/patientTreatment'
import { getList as getAdviceList } from '@/api/advice'
import caseReport from '@/views/caseReport/tree/index'
import treatmentPlan from '@/views/patient/patient/treatmentPlan' // 患者治疗小结组件

export default {
    // 组件注册
    components: {
        generalTable,
        addDialog,
        adviceDetailDialog,
        treatmentDetailDialog,
        record,
        conclusion,
        caseReport,
        treatmentPlan
    },
    props: {},
    data() {
        return {
            contentHeight: 0,
            visible: false,
            details: [], // 详情数据
            tableHeight: 10,
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '标题一', // 必填
                        prop: 'first', // 必填
                        formatter: (row, column, cellValue, index) => {
                            return row.first + index
                        }
                    }
                ]
            },
            checkedId: '',
            total: 0, // 总条数
            timeStamp: '',
            xOffset: 0, // x轴
            offsetValue: 190, // 时间线单个节点宽度
            timeLineList: [], // 患者就诊纪录数组
            timeLineBGColor: ['#EBF5FF', '#EBF5FF', '#E6FAF0', '#E6FAF0'],
            timeLineTextColor: ['#469CF8', '#469CF8', '#1BB763', '#1BB763'],
            timeLineTextList: ['住院', '住院', '门诊', '门诊'],
            selectId: '', // 选中的时间id
            selectStatus: 0,
            timeLineButtun: false,
            id: '',
            editStatus: true,
            activeName: '0',
            adviceTableData: [],
            adviceTotal: 0,
            adviceTableConfig: {
                id: 'advice',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType' // 必填
                    },
                    {
                        label: '操作项目名称', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        label: '医嘱类型', // 必填
                        prop: 'doctorOrderType', // 必填
                        formatter: (row, column) => {
                            return ['', '长期医嘱', '限定医嘱'][row.doctorOrderType]
                        }
                    },
                    {
                        label: '总次数', // 必填
                        prop: 'createCount', // 必填
                        formatter: (row, column) => {
                            if ('1' === row.doctorOrderType) {
                                return '长期'
                            } else {
                                return row.createCount
                            }
                        }
                    },
                    {
                        label: '单次数量', // 必填
                        prop: 'schCount' // 必填
                    },
                    {
                        label: '治疗频次', // 必填
                        prop: 'frequencyCount', // 必填
                        formatter: (row, column) => {
                            return (
                                row.frequencyCount+"次/" +
                                row.frequencyTime +
                                ["天", "周", "月", "年"][row.frequencyUnit]
                            )
                        }
                    },
                    {
                        label: '执行次数', // 必填
                        prop: 'usedCount' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'statusLabel' // 必填
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'adviceDate', // 必填
                        width: '150', // 不必填
                        formatter: (row, column) => {
                            return moment(row.adviceDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        // fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
            adviceListDetail: true, //详情权限
            adviceCheckId: '',
            treatmentTableData: [],
            treatmentTableConfig: {
                id: 'treatmentTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'basicInfoName' // 必填
                    },
                    {
                        label: '住院号', // 必填
                        prop: 'recordNum' // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'serialNum' // 必填
                    },
                    {
                        label: '治疗师', // 必填
                        prop: 'therapyName' // 必填
                    },
                    {
                        label: '医嘱类型', // 必填
                        prop: 'doctorOrderTypeLabel' // 必填
                    },
                    {
                        label: '所属治疗', // 必填
                        prop: 'operTypeLabel' // 必填
                    },
                    {
                        label: '治疗项目', // 必填
                        prop: 'operName' // 必填
                    },
                    {
                        label: '治疗时间', // 必填
                        prop: 'treatmentDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.treatmentDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                    },
                    {
                        label: '状态', // 必填
                        prop: 'statusLabel' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
            treatmentTotal: 0,
            TreatmentCheckId: '',
            treatmentInfo: {},
            inspectionTableData: [], //检查数据
            inspectionTableConfig: {
                id: 'inspectionTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '检查名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '检查所见', // 必填
                        prop: 'checkSee' // 必填
                    },
                    {
                        label: '检查结果', // 必填
                        prop: 'checkResult' // 必填
                    },
                    {
                        label: '检查时间', // 必填
                        prop: 'therapyName', // 必填
                        formatter: (row, column) => {
                            return moment(row.therapyName).format('YYYY-MM-DD HH:mm:ss')
                        }
                    }
                ]
            }, //检查表格信息
            inspectionTotal: 0, //检查数据总条数
            emrTableData: [], //检验数据
            emrTableConfig: {
                id: 'emrTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '检验名称', // 必填
                        prop: 'rptTitle' // 必填
                    },
                    {
                        label: '样本类型', // 必填
                        prop: 'majorItemName' // 必填
                    },
                    {
                        label: '检查时间', // 必填
                        prop: 'submissionDate' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            }, //检验表格信息
            emrTotal: 0, //检验数据总条数
            emrDetailId: '', //检验ID
            assessmentTableData: [], //评定数据
            assessmentTableConfig: {
                id: 'emrTable',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类', // 必填
                        prop: 'patientScaleTypeLabel' // 必填
                    },
                    {
                        label: '量表名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '评定人', // 必填
                        prop: 'userName' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'patientScaleStatusLabel' // 必填
                    },
                    {
                        label: '评定日期', // 必填
                        prop: 'evaluateDate', // 必填
                        formatter: (row, column) => {
                            return moment(row.evaluateDate).format('YYYY-MM-DD')
                        }
                    },

                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            }, //评定表格数据
            assessmentTotal: 0, //评定总条数
            basicInfoId: ""
        }
    },
    watch: {
        selectId(newVal, oldVal) {
            this.getTreatMentList()
            this.getAdviceList()
            this.getTreatmentInfoById()
            this.getAssessmentList()
        },
        activeName(newVal, oldVal) {
            this.$nextTick(() => {
                const dom = this.$refs.tabs.$el
                this.tableHeight = dom.offsetHeight
            })
        }
    },
    created() {
        this.editStatus = this.hasPermission('patient-detail-edit')
        this.getTabContentHeight()
    },
    mounted() {
        this.getDetailById()
    },
    methods: {
        /**
         * @description: 获取详情信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}患者信息
         */
        getTreatmentInfoById() {
            const parm = { basicInfoId: this.selectId }
            if (this.selectId) {
                getTreatmentInfo(parm).then(({ res, timeStamp }) => {
                    this.treatmentInfo = res
                })
            }
        },
        getTabContentHeight(){
            this.$nextTick(() => {
                this.contentHeight = this.$refs.tabs.$el.clientHeight - 10
            })
        },
        toExportTreatMent(flag) {
            const obj = {
                basicInfoId: this.selectId
            }
            if (flag === 0) {
                exportTreat(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '治疗记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (flag === 1) {
                //导出检查
                exportInspection(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '检查记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (flag === 2) {
                //导出检验
                exportEmr(obj)
                    .then(({ res }) => {
                        const blob = new Blob([res], {
                            type: 'application/vnd.ms-excel'
                        })
                        const fileName = '检验记录.xlsx'
                        const linkNode = document.createElement('a')
                        linkNode.download = fileName //a标签的download属性规定下载文件的名称
                        linkNode.style.display = 'none'
                        linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
                        document.body.appendChild(linkNode)
                        linkNode.click() //模拟在按钮上的一次鼠标单击

                        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                        document.body.removeChild(linkNode)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        toTreatmentDetail(scope) {
            this.TreatmentCheckId = scope.data.row.id
            this.$refs.TreatmentDetail.updateVisible(true)
        },
        getTreatMentList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = Object.assign(
                    this.$refs.treatmentTable ? this.$refs.treatmentTable.getPage() : { current: 1, size: 20 },
                    {
                        basicInfoId: this.selectId,
                        allPatientFlag: '0' //不分页
                    }
                )
                getTreatMentList(param).then(({ res, timeStamp }) => {
                    this.treatmentTableData = res.records
                    this.treatmentTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 查看评估详情
         * @param {*}评估量表id
         * @return {*}评估详情
         */
        toAssessmentDetailByScale(scope) {
            this.$router.push({
                path: '/scale/patientScale',
                query: { patientScaleId: scope.data.row.scalePatientId, flag: 3 }
            })
        },
        /**
         * @description: 查看报告详情
         * @param {*}报告id
         * @return {*}报告详情
         */
        toAssessmentDetailByReport(scope) {
            this.$router.push({
                path: '/other/Patient360/assessmentReport',
                query: { id: scope.data.row.scalePatientId, printFlag: '1' }
            })
        },
        /**
         * @description: 获取检验信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}检验信息
         */
        getEmrList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.$route.query.id,
                    allPatientFlag: '1'
                }
                getEmrList(param).then(({ res, timeStamp }) => {
                    this.emrTableData = res.records
                    this.emrTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取检查信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}检查信息
         */
        getInspectionList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.$route.query.id,
                    allPatientFlag: '1'
                }
                getInspectionList(param).then(({ res, timeStamp }) => {
                    this.inspectionTableData = res.records
                    this.inspectionTotal = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 获取评估列表信息
         * @param {*}basicInfoId 患者住院id
         * @return {*}评估列表
         */
        getAssessmentList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.selectId
                }
                getAssessmentList(param).then(({ res, timeStamp }) => {
                    this.assessmentTableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        toAdviceDetail(scope) {
            this.adviceCheckId = scope.data.row.id
            this.$refs.adviceDetail.updateVisible(true)
        },
        getAdviceList() {
            this.$nextTick(() => {
                getAdviceList({ basicInfoId: this.selectId }).then(({ res, timeStamp }) => {
                    this.adviceTableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * @description: 查询患者信息
         * @param {*}
         * @return {*}
         */
        getDetailById() {
            this.selectId = '' // 清除时间点选中状态
            this.timeLineList = [] // 设置初始值
            this.details = {} // 设置初始值
            this.id = this.$route.query.id
            const parm = { id: this.$route.query.id }
            if (this.$route.query.id !== '') {
                getDetail(parm).then(({ res, timeStamp }) => {
                    this.details = res
                    this.timeLineList = res.list || []
                    if (this.timeLineList.length) {
                        this.checkedId = this.timeLineList[0].id
                    }
                    if (res.list && res.list[0]) {
                        this.selectId = res.list[0].id
                        this.selectStatus = res.list[0].status
                    }
                    this.basicInfoId = res.basicInfoId
                    console.log("-----getDetailById", this.basicInfoId, this.checkedId)
                    this.timeStamp = timeStamp
                    setTimeout(() => {
                        this.istimeLineButtun()
                    }, 10)
                })
            }
        },
        updateVisible(flag) {
            this.visible = flag
        },
        /**
         * @description: 编辑患者信息
         * @param {*}
         * @return {*}
         */
        toEdit() {
            this.$refs.insert.getMessageByDetail(true, this.details, false, false)
        },
        /**
         * @description: 返回按钮
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back()
        },
        changeTest(i) {
            // i==1 时间轴向右移动 i-1时间轴向左移动 offsetValue是单个时间点宽度也是移动距离
            if (this.$refs.timeLine.clientWidth - this.$refs.timeLineBox.clientWidth > 0) {
                if (i === 1 && this.xOffset > this.offsetValue * -1) {
                    this.xOffset = 0
                } else if (
                    i === -1 &&
                    this.xOffset * -1 >
                        this.$refs.timeLine.clientWidth - this.$refs.timeLineBox.clientWidth - this.offsetValue
                ) {
                    this.xOffset = -1 * (this.$refs.timeLine.clientWidth - this.$refs.timeLineBox.clientWidth)
                } else {
                    this.xOffset = this.xOffset + i * this.offsetValue
                }
            }
        },
        /**
         * @description: 选中时间
         * @param {*} id
         * @return {*}
         */
        selectItem(item) {
            // 选中时间轴上的时间点 id是选中时间点的id
            this.selectId = item.id
            console.log("------select line", item.id)
            this.selectStatus = item.status
        },
        istimeLineButtun() {
            if (this.$refs.timeLine.clientWidth > this.$refs.timeLineBox.clientWidth) {
                this.timeLineButtun = true
            } else {
                this.timeLineButtun = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.page-main {
    display: flex;
    flex-direction: column;
    .pat-info {
        background-color: $white;
        margin-bottom: 15px;
        .name-wrapper {
            margin-left: 10px;
            padding: 3px 0;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
        }
        .btn-wrapper {
            display: flex;
            .edit-btn {
                margin-left: 10px;
            }
        }
    }
}
.timeLineAllBox {
    width: 100%;
    height: 122px;
    display: flex;
    justify-content: space-between;
    background-color: $white;
}
.timeLineBox {
    position: relative;
    overflow-x: hidden;
    flex: 1;
}
.buttonBox {
    height: 120px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tableColor;
    font-size: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timeLine {
    transition: 0.5s left ease;
    display: flex;
    width: max-content;
    position: absolute;
}
.timeLineItem {
    width: 190px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}
.timeLineTitle {
    width: 100px;
    height: 32px;
    background-color: $lineTitle;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $text;
    font-weight: 500;
}
.timeLineLine {
    height: 1px;
    width: 100%;
    background-color: $tableColor;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.timeLinePoint {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 4px $line solid;
    background-color: $white;
}
.timeLineTime {
    font-size: 14px;
    color: $titleColor;
}

.tab1Box {
    background-color: $white;
    height: calc(100vh - 314px);
    width: 100%;
    overflow-y: auto;
}
.mdrcc-info-title {
    margin-bottom: 10px;
    margin-top: 15px;
}
.table-btn-group {
    padding-top: 15px;
}
.tabs {
    flex: 1;
    margin-top: 10px;
    border: 1px solid $tableColor;
    box-shadow: none;
    display: flex;
    .tab-pane {
        flex: 1;
        height: 100%;
    }
}
.treat-row {
    display: flex;
    padding: 5px 15px;
    font-size: 12px;
    .treat-label {
        width: 10em;
    }
    .treat-content {
        flex: 1;
        white-space: pre-wrap;
    }
}
</style>
