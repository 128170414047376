/*
 * @Author: your name
 * @Date: 2021-12-09 17:00:11
 * @LastEditTime: 2022-07-14 16:30:57
 * @LastEditors: ligc 951906427@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\message.js
 */

/**
 * 页面: 消息列表
 * pageName: message
 **/
import request from '@/utils/request'

/**
 * @description: 获取消息列表列表数据
 */
export function getList(data) {
    return request({
        url: '/sysMessage/userMsgList',
        method: 'GET',
        params: data,
    })
}


/**
 * @description: 修改消息状态
 */
    export function updateStatus(data, timeStamp) {
    return request({
        url: '/sysMessage/updateStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        