
<template>
    <el-dialog width="60%" title="任务详情" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="任务信息" :column="3">
            <el-descriptions-item label="任务名称">
                {{ commonConfig.$checkNullData(detail.name) }}
            </el-descriptions-item>
            <el-descriptions-item label="患者姓名">
                {{ commonConfig.$checkNullData(detail.patientName) }}
            </el-descriptions-item>
            <el-descriptions-item label="住院号">
                {{ commonConfig.$checkNullData(detail.recordNum) }}
            </el-descriptions-item>
            <el-descriptions-item label="任务详情" :span="3">
                {{ commonConfig.$checkNullData(detail.content) }}
            </el-descriptions-item>
            <el-descriptions-item label="日期">
                {{ commonConfig.$checkNullData(detail.taskDate) }}
            </el-descriptions-item>
            <el-descriptions-item label="状态">
                {{ commonConfig.$checkNullData(stateMap[detail.state]) }}
            </el-descriptions-item>
            <el-descriptions-item label="处理人">
                {{ commonConfig.$checkNullData(detail.handleUserName) }}
            </el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from '@/api/recoveryTask'
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // 状态 0未处理 1已处理
            stateMap: {
                "0": "未处理", 
                "1": "已处理",
            },
            visible: false,
            detail: {}, // 详情数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail()
        }
    },
    created() { },
    mounted() {
    },
    methods: {
        getDetail() {
            getDetail({id: this.id}).then(({ res }) => {
                const {taskDate : taskDateStamp, ...others} = res
                const taskDate = this.moment(taskDateStamp).format("YYYY-MM-DD HH:mm:ss")
                this.detail = { taskDate, ...others }
            })
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang='scss'>

</style>
