/*
 * @Author: your name
 * @Date: 2021-12-28 16:34:34
 * @LastEditTime: 2022-03-02 10:24:40
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\patientRehabilitation.js
 */

/**
 * 页面: 患者康复治疗统计
 * pageName: patientRehabilitation
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者康复治疗统计列表数据
 */
export function getList(data) {
    return request({
        url: '/statistics/patientRehabilitation/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 导出列表数据
 */
 export function toExport(data) {
    return request({
        url: 'statistics/patientRehabilitation/getExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

