
<template>
  <el-dialog
    width="60%"
    title="科研数据管理详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="基本信息" :column="4" >
      <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
      <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
      <el-descriptions-item label="备注">
        <el-tag size="small">学校</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <el-descriptions class="margin-top" title="在院信息" :column="4" >
      <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
      <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
      <el-descriptions-item label="备注">
        <el-tag size="small">学校</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <div class="mdrcc-info-title">
      评估信息
    </div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" />
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from '@/api/scientificData'
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            tableData: [
                {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }
            ],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '标题一', // 必填
                        prop: 'first', // 必填
                        formatter: (row, column, cellValue, index) => {
                            return row.first + index
                        }
                    },
                    {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    },
                    {
                        label: '标题三', // 必填
                        prop: 'third' // 必填
                    },
                    {
                        label: '标题四', // 必填
                        prop: 'fourth' // 必填
                    }
                ]
            },
            total: 0 // 总条数
        }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal && this.id) this.getDetail()
      }
    },
    created() {},
    mounted() {
    },
    methods: {
      getDetail() {
        getDetail(this.id).then(({res}) => {
          this.detail = res
        })
      },
      updateVisible(flag) {
        this.visible = flag
      }
    }
}
</script>
<style scoped lang='scss'>
</style>
