/*
 * @Author: your name
 * @Date: 2022-01-20 09:54:23
 * @LastEditTime: 2022-02-07 11:17:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\etlLog.js
 */
import request from '@/utils/request'

/**
 * @description: 列表
 */
export function getList(params) {
  return request({
    url: '/etlLog/list',
    method: 'GET',
    params
  })
}

/**
 * @description: 查看
 */
 export function getDetail(data) {
  return request({
      url: '/etlLog/info',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description: 删除
 */
 export function deleteData(data) {
  return request({
      url: '/etlLog/delete',
      method: 'POST',
      data,
      showLoading: true
  })
}