<!--
 * @Author: your name
 * @Date: 2022-08-11 08:56:33
 * @LastEditTime: 2022-08-22 09:52:30
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\scale\patientAssessment\patientScaleApp.vue
-->
<template>
    <div style="width: 100vw; height: 100vh; over-flow: auto;">
        <el-row v-if="buttonShow" type="flex" justify="end" style="padding: 20px">
            <div class="app-button">
                <el-button v-if="status != 2" type="primary" @click="submit(1)">
                    保存
                </el-button>
                <el-button type="primary" @click="submit(2)">提交</el-button>
            </div>
        </el-row>
        <div id="app-scale">
            <div v-html="scaleHtml"></div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import uni from '@/utils/uniview'
import {
    getMouldCode,
    getAnswerQuestions,
    getMouldCodeByScalePatientId, // 获取量表模板
    getViewMouldCodeByScalePatientId, // 获取显示模板
    getScaleInfoQuestionByPatientScaleId,
    lastTimePatientAnswer,
    getDetail // 公共量表库基础信息
} from '@/api/publicScaleLib'
let submit = null
export default {
    name: "scaleApp",
    data(){
        return {
            scaleHtml: "",
            basicInfoId: "",
            patientScaleId: "",
            questionListMap: {},
            status: '',
            flag: '',
            buttonShow: false,
            coordArray: []
        }
    },
    created(){
        const {publicKey, unique, basicInfoId, patientScaleId, status, flag, button} = this.$route.query
        this.flag = flag
        this.basicInfoId = basicInfoId
        this.patientScaleId = patientScaleId
        this.status = status
        this.buttonShow = Boolean(button)
        console.log("------APP端量表")
        this.initPage()
    },
    mounted(){
        submit = this.submit
        window.submit = this.submit
        uni.postMessage({
            data: {
                type: 'loaded',
            }
        })
    },
    watch:{
    },
    methods:{
        async initPage(){
            let mould = await this.getMould()
            let {answerList, questionList} = await this.getQuesAndAnswer()
            this.coordArray = questionList.map(item => item.answerCoordinate)
            this.updateHtml(mould, questionList, answerList)
            this.flag !== '1' && this.$nextTick(() => {
                const that = this
                $(`input[type="radio"]`).on('click', function() {
                    that.checkChange(this)
                })
                $(`input[type="checkbox"]`).on('click', function() {
                    that.checkChange(this)
                })
                $(`input[type="text"]`).on('change', function() {
                    that.contentChange(this)
                })
                $(`textarea`).on('change', function() {
                    that.contentChange(this)
                })
            })
        },
        submit(type){
            uni.postMessage({
                data: {
                    type: 'submit',
                    flag: type,
                    resData: this.questionListMap
                }
            })
        },
        getQuesAndAnswer(){
            return new Promise(resolve => {
                getScaleInfoQuestionByPatientScaleId({id: this.patientScaleId}).then(({res}) => {
                    resolve(res)
                })
            })
        },
        getMould(){
            return new Promise(resolve => {
                getMouldCodeByScalePatientId({id: this.patientScaleId}).then(({res}) => {
                    resolve(res)
                })
            })
        },
        checkChange(dom){
            const coordIndex = $(dom).attr("id").split("_")
            const quesId = coordIndex[0]
            const optionList = this.questionListMap[quesId].optionList
            let selectedIndexs = []
            $(`.${quesId}:checked`).each(function(){
                // eg: xa_1 index为0 xa_2 index为1 
                const selectedOptIndex = $(this).attr("id").split("_")[1] * 1 - 1
                selectedIndexs.push(selectedOptIndex)
            })
            const selectedIds = selectedIndexs.map(index => optionList[index].id)
            const markArray = selectedIndexs.map(index => optionList[index].mark)
            const mark = markArray.length ? markArray.reduce((a,b) => (a*1+b*1)) : 0
            console.log("------check change", selectedIds, markArray, mark)
            let questionListMap = this.questionListMap
            questionListMap[quesId] = {
                ...questionListMap[quesId],
                selectedIds,
                mark,
                selectedIndexs
            }
            this.questionListMap = {...questionListMap}
            this.valueChange(quesId)
        },
        contentChange(dom){
            const quesId = $(dom).attr("id")
            let questionListMap = this.questionListMap
            questionListMap[quesId].answerContent = $(dom).val()
            this.questionListMap = {...questionListMap}
            console.log("------content change", quesId, questionListMap[quesId], this.questionListMap)
            this.valueChange(quesId)
            // if (!this.checkContent(quesId)) {
            //     return
            // }
            this.checkContent(quesId)
        },
        checkContent(coord){
            console.log("-------checkContent", coord)
            let questionListMap = this.questionListMap
            const {answerContent, validRule, optionList, answerType, pcShow} = questionListMap[coord]
            if (answerType === "0" && pcShow === "0") {
                const validRuleArray = optionList.map(item => item.lsn)
                console.log("optionList", validRuleArray, answerContent, validRuleArray.includes(answerContent))
                if (!validRuleArray.includes(answerContent)) {
                    this.$confirm(`请输入：${validRuleArray.join("、")} 之中的一项`, '警告', {
                        customClass: 'app-modal-box',
                        showCancelButton: false,
                        type: 'warning',
                        center: true
                    }).then(() => {
                        questionListMap[coord].answerContent = ""
                        $(`#${coord}`).val("")
                        $(`#${coord}`).focus()
                        this.questionListMap = {...questionListMap}
                        this.valueChange(coord)
                    })
                    return false
                }
            }
            if (validRule.validRuleArray) {
                const validRuleArray = eval(`[${validRule.validRuleArray}]`).map(item => item + "")
                console.log("validRuleArray", validRuleArray)
                if (!validRuleArray.includes(answerContent)) {
                    // this.$message({
                    //     message: `请输入：${validRuleArray.join("、")} 之中的一项`,
                    //     type: 'warning'
                    // });
                    this.$confirm(`请输入：${validRuleArray.join("、")} 之中的一项`, '警告', {
                        customClass: 'app-modal-box',
                        showCancelButton: false,
                        type: 'warning',
                        center: true
                    }).then(() => {
                        questionListMap[coord].answerContent = ""
                        $(`#${coord}`).val("")
                        $(`#${coord}`).focus()
                        this.questionListMap = {...questionListMap}
                        this.valueChange(coord)
                    })
                    return false
                }
            }
            if (validRule.type === "number" && isNaN(answerContent * 1)) {
                this.$confirm(`请输入数字`, '警告', {
                    customClass: 'app-modal-box',
                    showCancelButton: false,
                    type: 'warning',
                    center: true
                }).then(() => {
                    questionListMap[coord].answerContent = ""
                    $(`#${coord}`).val("")
                    $(`#${coord}`).focus()
                    this.questionListMap = {...questionListMap}
                    this.valueChange(coord)
                })
                return false
            }
            if ((validRule.min || validRule.max) && isNaN(answerContent * 1)) {
                this.$confirm(`请输入数字`, '警告', {
                    customClass: 'app-modal-box',
                    showCancelButton: false,
                    type: 'warning',
                    center: true
                }).then(() => {
                    questionListMap[coord].answerContent = ""
                    $(`#${coord}`).val("")
                    $(`#${coord}`).focus()
                    this.questionListMap = {...questionListMap}
                    this.valueChange(coord)
                })
               return false
            } else if (validRule.min || validRule.max) {
                validRule.min = validRule.min || -Infinity
                validRule.min *= 1
                validRule.max = validRule.max  || Infinity
                validRule.max *= 1
                if (answerContent * 1 > validRule.max || answerContent * 1 < validRule.min) {
                    // uni.showModal({
                    //     title: "输入错误",
                    //     content: "请按说明输入内容",
                    //     showCancel: false
                    // })
                    // this.$message({
                    //     message: `请输入${validRule.min}至${validRule.max}范围内的数字`,
                    //     type: 'warning'
                    // });
                    this.$confirm(`请输入${validRule.min}至${validRule.max}范围内的数字`, '警告', {
                        customClass: 'app-modal-box',
                        showCancelButton: false,
                        type: 'warning',
                        center: true
                    }).then(() => {
                        questionListMap[coord].answerContent = ""
                        $(`#${coord}`).val("")
                        $(`#${coord}`).focus()
                        this.questionListMap = {...questionListMap}
                        this.valueChange(coord)
                    })

                    return false
                }
            }
            if(validRule.step && isNaN(answerContent * 1)) {
                this.$confirm(`请输入数字`, '警告', {
                    customClass: 'app-modal-box',
                    showCancelButton: false,
                    type: 'warning',
                    center: true
                }).then(() => {
                    questionListMap[coord].answerContent = ""
                    $(`#${coord}`).val("")
                    $(`#${coord}`).focus()
                    this.questionListMap = {...questionListMap}
                    this.valueChange(coord)
                })
                return false
            } else if (validRule.step === "1") { // 输入整数
                questionListMap[coord].answerContent = (questionListMap[coord].answerContent * 1 ).toFixed(0) * 1 + ""
            } else if (validRule.step === "0.1") { // 输入整数
                questionListMap[coord].answerContent = (questionListMap[coord].answerContent * 1 ).toFixed(1) * 1 + ""
            } else if (validRule.step === "0.01") { // 输入小数
                questionListMap[coord].answerContent = (questionListMap[coord].answerContent * 1 ).toFixed(2) * 1 + ""
            }
            this.questionListMap = {...questionListMap}
            this.valueChange(coord)
            return true
        },
        // 将题目结果转换成对象
        getRes() {
            let quesList = this.coordArray.map(item => this.questionListMap[item])
            // let quesList = Object.keys(this.questionListMap).map(key => this.questionListMap[key])
            let data = {
                count: (souceArray, val) => {
                    const countArray = quesList.filter(item => souceArray.includes(item.answerCoordinate))
                    const sum = countArray.length
                        ? countArray
                                .map(item => {
                                    if (item.answerContent !== undefined && item.answerContent * 1 === val) {
                                        return 1
                                    } else if (item.mark !== undefined && item.mark === val) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .reduce((a, b) => a + b)
                        : 0
                    return sum
                }
            }
            console.log("-----quesList", quesList)
            quesList.forEach(queItem => {
                const { answerCoordinate, answerType, selectedIds, answerContent, optionList, mark, pcShow } = queItem
                if (['0'].includes(answerType) && pcShow === "0") {
                    let optMap = {}
                    optionList.forEach(opt => {
                        optMap[opt.lsn] = opt
                    })
                    data[answerCoordinate] = answerContent ? optMap[answerContent].mark : 0
                } else if (['0', '1'].includes(answerType)) {
                    data[answerCoordinate] = mark
                } else {
                    data[answerCoordinate] = answerContent * 1
                }
                data[answerCoordinate] = data[answerCoordinate] || 0
            })
            console.log("-----data", data)
            
            return data
        },
        valueChange(answerCoordinate){
            let data = this.getRes()
            let quesList = Object.keys(this.questionListMap).map(key => this.questionListMap[key])
            quesList = quesList.map((item, index) => {
                if (!item.validRule || !item.validRule.validRuleCount) {
                    // 没有规则
                    return item
                } else if (item.answerCoordinate === answerCoordinate && ['0'].includes(answerType) && item.pcShow === "0") {
                    // 当前输入框 单选且显示形式为填空
                    return item
                } else if (item.answerCoordinate === answerCoordinate && !['0', '1'].includes(answerType)) {
                    // 当前输入框 非单选和多选
                    return item
                } else {
                    console.log("------validRuleCount",item, item.validRule.validRuleCount)
                    console.log(eval(item.validRule.validRuleCount))
                    const validRuleCount = eval(item.validRule.validRuleCount)
                    const answerContent =  typeof validRuleCount === 'number'
                                ? (validRuleCount * 1).toFixed(1) * 1 + ""
                                : validRuleCount
                    $(`#${item.answerCoordinate}`).val(answerContent)
                    return {
                        ...item,
                        answerContent
                    }
                }
            })
            const questionListMap = {}
            quesList.forEach(item => {
                questionListMap[item.answerCoordinate] = item
            })
            this.questionListMap = questionListMap
        },
        updateHtml(html, questionList, answerList){
            const answerMap = {}
            const questionListMap = {}
            answerList.forEach(item => {
                answerMap[item.scaleQuestionId]= item
            })
            questionList = questionList.map(item => {
                const validRuleJSON = JSON.parse(`{${item.validRule}}`) // 规则
                // PC show  0 输入框 1 box
                if (!answerMap[item.id]) {
                    return {...item, selectedIndexs: [], selectedIds: [], validRule: validRuleJSON}
                }
                if (["0"].includes(item.answerType) && item.pcShow === "0") {
                    return {
                        ...item, 
                        answerContent: answerMap[item.id].answerLsn,
                        validRule: validRuleJSON
                    }
                }
                if (["0", "1"].includes(item.answerType)) {
                    const answerLsn = answerMap[item.id].answerLsn ? answerMap[item.id].answerLsn.split(",") : []
                    const optionMap = {}
                    item.optionList.forEach((option, optIndex) => {
                        optionMap[option.lsn] = {...option, index: (optIndex + 1)}
                    })
                    const selectedIndexs = answerLsn.map(lsn => {
                        return optionMap[lsn].index
                    })
                    const selectedIds = answerLsn.map(lsn => {
                        return optionMap[lsn].id
                    })
                    const markArray = answerLsn.map(lsn => {
                        return optionMap[lsn].mark
                    })
                    const mark = markArray.length ? markArray.reduce((a,b) => (a+b) * 1) : 0
                    return {
                        ...item, 
                        selectedIndexs,
                        selectedIds,
                        validRule: validRuleJSON,
                        mark
                    }
                } 
                return {
                    ...item, 
                    answerContent: answerMap[item.id].answerContent,
                    validRule: validRuleJSON
                }
            })
            questionList.forEach(item => {
                questionListMap[item.answerCoordinate] = item
            })
            this.questionListMap = questionListMap
            questionList.forEach(answerItem => {
                const {answerType, answerCoordinate, answerContent, pcShow, lsnArray, optionList, selectedIndexs, selectedIds, validRule} = answerItem
                const readonly = (validRule.readonly || this.flag === '1') ? 'readonly' : ''
                const click = (validRule.readonly || this.flag === '1') ?  'onclick="return false"' : ''
                if (['2'].includes(answerType)) {
                    html = html.replace(
                    `[${answerCoordinate}]`, 
                    `<textarea id="${answerCoordinate}" rows="4" ${readonly} style="resize:none;width:100%;">${answerContent || ''}</textarea>`)
                } else if (["1"].includes(answerType)) {
                    for (let optIndex in optionList) {
                        html = html.replace(
                        `[${answerCoordinate}_${optIndex * 1 + 1}]`, 
                        `<input id="${answerCoordinate}_${optIndex * 1 + 1}" class="${answerCoordinate}" type="checkbox" ${selectedIds.includes(optionList[optIndex].id) ? 'checked' : ''} style="margin-right: 5px;" ${click} />`)
                    }
                    
                } else if (["0"].includes(answerType) && pcShow !== "0") {
                    for (let optIndex in optionList) {
                        html = html.replace(
                        `[${answerCoordinate}_${optIndex * 1 + 1}]`, 
                        `<input type="radio" id="${answerCoordinate}_${optIndex * 1 + 1}" class="${answerCoordinate}" name="${answerCoordinate}" ${selectedIds.includes(optionList[optIndex].id) ? 'checked' : ''} style="margin-right: 5px;" ${click} />`)
                    }
                } else if (["0"].includes(answerType)) {
                    const answerLsn = answerContent || ""
                    html = html.replace(
                    `[${answerCoordinate}]`, 
                    `<input type="text" id="${answerCoordinate}" ${readonly} style="width:100%;" value="${answerLsn}" />`)
                } else {
                    html = html.replace(
                    `[${answerCoordinate}]`, 
                    `<input type="text" id="${answerCoordinate}" ${readonly} style="width:100%;" value="${answerContent || ''}" />`)
                }
            })
            this.scaleHtml =  html
        },
    },

}
</script>
<style lang="scss" scoped>
    .scale {
        padding: 20px;
    }
    #app-scale {
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
        // margin-top: 60px;
    }
    
    .app-button {
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        z-index: 10;
        width: 100vw;
        background-color: #ffffff;
        margin-top: 0;
        top: 0;
        left: 0;
        padding-right: 10px;
        border-bottom: 1px solid #dedede;
        box-sizing: border-box;
    }
</style>