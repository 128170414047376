<!--
 * @Description: 首页
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2023-04-17 11:25:29
 * @LastEditors: mazihao mazihao@newpay.la
 * @Reference:
-->

<template>
  <el-container>
    <el-main>
      <div class="box-layout"
           ref="home-row1">
        <div class="home-title"
             slot="header">
          <div class="home-title-info">
            <svg-icon icon-class="statistics" />
            日常统计
          </div>
          <div class="home-config"
               @click="toConfig('statistics')">
            <svg-icon icon-class="customConfig" />
          </div>
        </div>
        <el-row :gutter="20"
                type="flex"
                justify="start">
          <el-col :sm="4"
                  :md="4"
                  :lg="4"
                  :xl="4"
                  v-for="item in statistics"
                  :key="item.id">
            <div :class="item.jumpUrl ? 'pointer statistics-item-card' : 'statistics-item-card'"
                 v-if="item.status === '0'"
                 @click="item.jumpStatus && toPageOfData(item)">
              <div class="statistics-item-title">
                {{ item.name || commonConfig.$nullData }}
                <el-popover placement="top-start"
                            title="功能介绍"
                            width="200"
                            trigger="hover"
                            :content="item.remark">
                  <i slot="reference"
                     class="badge">i</i>
                </el-popover>
              </div>
              <div class="statistics-item-content">
                <div :class="
                                        item.jumpStatus
                                            ? 'statistics-item-num statistics-item-url'
                                            : 'statistics-item-num'
                                    ">
                  {{ item.result || 0 }}
                </div>
                <div class="statistics-item-icon"
                     :style="{ backgroundColor: item.color }">
                  <svg-icon :icon-class="item.icon" />
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="box-layout"
           ref="home-row2">
        <div class="home-title">
          <div class="home-title-info">
            <svg-icon icon-class="task" />
            待办任务
          </div>
          <div class="home-config"
               @click="toConfig('task')">
            <svg-icon icon-class="customConfig" />
          </div>
        </div>
        <el-row :gutter="20"
                type="flex"
                justify="start">
          <el-col :sm="4"
                  :md="4"
                  :lg="4"
                  :xl="4"
                  v-for="item in task"
                  :key="item.id">
            <div :class="item.jumpUrl ? 'pointer task-item' : 'task-item'"
                 @click="toPageOfData(item)">
              <div class="task-item-icon">
                <svg-icon :icon-class="item.icon" />
              </div>
              <div class="task-item-title">{{ item.name }}</div>
              <div class="task-item-number">{{ item.result || 0 }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 最后一行并排显示常用功能+公告通知时，加row-two class名；只显示常用功能时，去掉row-two class名 -->
      <div class="row-two autoHeight"
           ref="commonUseBox">
        <div class="box-layout"
             :style="commonBoxStyle">
          <div class="home-title">
            <div class="home-title-info">
              <svg-icon icon-class="commonUse" />
              常用功能
            </div>
            <div class="home-config"
                 @click="toConfig('commonUse')">
              <svg-icon icon-class="customConfig" />
            </div>
          </div>
          <el-row :gutter="10"
                  type="flex"
                  justify="start">
            <el-col ref='commonUse'
                    :style="commonUseStyle"
                    :sm="3"
                    :md="3"
                    :lg="3"
                    :xl="3"
                    v-for="item in commonUse"
                    :key="item.id"
                    class="commom-use">
              <div :class="item.jumpUrl ? 'pointer use-item' : 'use-item'"
                   @click="toPageOfData(item)">
                <el-image :src="require(`@/assets/btnImg/${item.icon}.png`)"
                          :fit="'contain'" />
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="box-layout"
             ref="notice">
          <div class="home-title">
            <div class="home-title-info">
              <svg-icon icon-class="notice" />
              公告通知
            </div>
          </div>
          <el-row :gutter="10"
                  type="flex"
                  justify="start"
                  class="sys-notice"
                  :style="{ height: noticeHeight}">
            <el-col ref="notice1">
              <div class="notice-item">
                <div class="notice-item-head">
                  <div class="notice-item-type">系统通知</div>
                  <div class="notice-item-auth">发布人:系统管理员</div>
                </div>
                <p class="notice-item-msg">此处暂无内容,现内容为占位符</p>
                <div class="notice-item-date">发布时间:2021-02-12 12:09</div>
              </div>
              <div class="notice-item">
                <div class="notice-item-head">
                  <div class="notice-item-type">系统通知</div>
                  <div class="notice-item-auth">发布人:系统管理员</div>
                </div>
                <p class="notice-item-msg">此处暂无内容,现内容为占位符</p>
                <div class="notice-item-date">发布时间:2021-02-12 12:09</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <configDialog :typeName="typeName"
                    ref="config"
                    @submit="getData" />
      <inhosAddDialog ref="inHos"
                      @submit="toPage('/patient/inHospital')" />
      <outhosAddDialog ref="outHos"
                       @submit="toPage('/patient/outHospital')" />
      <communicateAddDialog :id="''"
                            ref="communicate"
                            @submit="toPage('/teamwork/communication')" />
      <fastRegisterDialog ref="arrangeTreatment"
                          @submit="toPage('/treatment/arrangeTreatment')" />
    </el-main>
  </el-container>
</template>

<script>
import configDialog from "@/views/home/configDialog.vue";
import inhosAddDialog from "@/views/patient/patient/addDialog.vue";
import outhosAddDialog from "@/views/patient/outHospital/addDialog.vue";
import communicateAddDialog from "@/views/teamwork/communication/addDialog";
import fastRegisterDialog from "@/views/treatment/arrangeTreatment/fastRegisterDialog";
import { getWorkbenchList } from "@/api/home";

export default {
  name: "Home",
  components: {
    configDialog,
    inhosAddDialog,
    outhosAddDialog,
    communicateAddDialog,
    fastRegisterDialog,
  },
  data() {
    return {
      typeName: "statistics", //类型名称
      fileKey: "", // 文件key
      filetype: "", // 文件类型
      statistics: [], //日常统计
      task: [], //待办任务
      commonUse: [], //常用功能
      treatedTodayStatus: true, //今日治疗量统计 资源权限
      treatedWeekStatus: true, //本周治疗量统计 资源权限
      noticeHeight: "auto",
      noticeStyle: {
        overflowY: "auto",
      },
      commonUseStyle: {},
      commonBoxStyle: {},
    };
  },
  created() {
    this.treatedTodayStatus = this.hasPermission("treatedToday");
    this.treatedWeekStatus = this.hasPermission("treatedWeek");
    this.getData();
  },
  methods: {
    /**
     * @description: 获取工作台列表
     */
    getData() {
      getWorkbenchList().then(({ res }) => {
        for (let i = 0; i < res.length; i++) {
          const ele = res[i];
          const statisticsEle = [];
          switch (ele.value) {
            case "0":
              //日常统计
              this.statistics = ele.list.map((item) => {
                if (this.hasPermission("workbench-statistics-" + item.icon)) {
                  console.log("statisticsEle", item);
                  statisticsEle.push(item);
                }
              });
              this.statistics = statisticsEle.map((item) => {
                let url = [];
                try {
                  url = item.jumpUrl.split("?");
                } catch (error) {
                  url[0] = item.jumpUrl;
                }
                if (url[0] === "" || url[0] === null) {
                  item.jumpStatus = false;
                } else if (url[0] === "/statistics/treatedToday") {
                  if (this.treatedTodayStatus) {
                    item.jumpStatus = true;
                  } else {
                    item.jumpStatus = false;
                  }
                } else if (url[0] === "/statistics/treatedWeek") {
                  if (this.treatedWeekStatus) {
                    item.jumpStatus = true;
                  } else {
                    item.jumpStatus = false;
                  }
                } else {
                  item.jumpStatus = false;
                }
                return item;
              });
              break;
            case "1":
              //待办任务
              const taskEle = [];
              ele.list.map((item) => {
                if (this.hasPermission("workbench-task-" + item.icon)) {
                  taskEle.push(item);
                }
              });
              this.task = taskEle;
              break;
            case "2":
              // 常用功能
              const commonUseEle = [];
              ele.list.map((item) => {
                if (this.hasPermission("workbench-commonUse-" + item.icon)) {
                  commonUseEle.push(item);
                }
              });
              this.commonUse = commonUseEle;
              break;
            default:
              break;
          }
        }
        this.$nextTick(() => {
          if (!this.$refs.commonUse) {
            return;
          }
          // 计算最后一行剩余高度
          const lastRowHeight =
            document.documentElement.clientHeight -
            110 -
            this.$refs["home-row1"].clientHeight -
            this.$refs["home-row2"].clientHeight -
            10;
          if (this.$refs.notice1) {
            // 计算公告通知模块高度
            const orgHeight = this.$refs.notice1.$el.offsetHeight + 90;
            // 如果公告通知高度高于最后一行高度，重新设置公告通知高度；否则高度为auto
            if (orgHeight > lastRowHeight - 120) {
              this.noticeHeight = lastRowHeight - 84 + "px";
            } else {
              this.noticeHeight = "auto";
            }
            console.log("------last row", lastRowHeight, orgHeight);
          }
          this.commonBoxStyle = {
            height: lastRowHeight - 5 + "px",
          };
          // 如果常用功能单行高度高于最后一行高度的一半，并且最后一行高度小于icon的宽度，对常用功能计算压缩
          this.commonUseStyle = {
            width:
              Math.min(
                lastRowHeight - 85,
                this.$refs.commonUse[0].$el.clientWidth
              ) + "px",
          };
          console.log(
            "------common",
            lastRowHeight,
            this.$refs.commonUseBox.clientHeight
          );
        });
      });
    },
    /**
     * @description: 通过jumptype确定弹窗还是跳转,并交互
     * @param {*} data
     */

    toPageOfData(data) {
      console.log(data);
      if (data.jumpUrl) {
        let url = [];
        let urlData = {};
        try {
          url = data.jumpUrl.split("?");
          urlData = this.packageUrlData(url[1]);
        } catch (error) {
          url[0] = data.jumpUrl;
        }
        if (data.jumpType === "0") {
          this.$router.push({ path: url[0], query: urlData });
        } else {
          this.$refs[url[0]].updateVisible(true);
        }
      }
    },

    toPage(url, query) {
      this.$router.push({ path: url, query });
    },

    toConfig(typeName) {
      this.typeName = typeName;
      this.$refs.config.updateVisible(true);
    },

    /**
     * @description: 分割url后面的参数
     * @param {String} url
     * @return {Object} 参数对象
     */
    packageUrlData(urlData) {
      const params = urlData.split("&");
      let paramObj = {};
      for (let i = 0; i < params.length; i++) {
        const ele = params[i].split("=");
        paramObj[ele[0]] = ele[1];
      }
      return paramObj;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.row-two {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  & > .box-layout {
    width: calc(50% - 10px);
  }
}

.el-main {
  background: rgb(246, 246, 246);
  padding: 0;
}

.box-layout {
  margin: 0 0 10px;
  background: $white;
  padding: 10px 21px 17px;
  border-radius: 10px;
}

.home-title {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  margin-top: 8px;
  margin-bottom: 8px;
  .home-config {
    position: absolute;
    right: 0;
    top: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #e6f0fa;
    border-radius: 5px;
    & > svg {
      font-size: 20px;
    }
  }
  &-info {
    font-size: 16px;
    font-weight: 700;
    color: $titleColor;
    margin-bottom: 9px;
    & > svg {
      font-size: 20px;
      margin-right: 15px;
    }
  }
}

.statistics-item {
  &-card {
    background-color: $dialogHeader;
    border-radius: 10px;
    padding: 10px 16px 8px 24px;
    margin: 5px 0;
  }
  &-title {
    font-size: 14px;
    color: $text;
    margin-bottom: 8px;
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &-num {
    font-size: 20px;
    color: $titleColor;
    font-weight: bold;
  }
  &-url {
    color: $theme;
  }
  &-icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      font-size: 28px;
    }
  }
}

.task-item {
  max-width: 255px;
  height: 89px;
  border: 1px solid $tableColor;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  position: relative;
  padding: 0 15px;
  &-icon {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: $taskIcon;
    border-radius: 50%;
    margin-right: 16px;
  }
  &-number {
    width: 27px;
    background: url("../../assets/img/flag.png") no-repeat left top;
    font-size: 12px;
    color: $white;
    padding: 9px 5px 11px;
    position: absolute;
    right: 23px;
    top: 0;
    text-align: center;
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: $text;
  }
}

.use-item {
  width: 100%;
}

.notice-item {
  background-color: $dialogHeader;
  border-radius: 10px;
  padding: 20px 25px;
  &-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &-type {
    font-size: 16px;
    color: $titleColor;
    font-weight: bold;
  }
  &-auth {
    font-size: 14px;
    color: $text;
  }
  &-msg {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $text;
  }
  &-date {
    font-size: 12px;
    color: $noticeDate;
  }
}

.notice-item:not(:last-of-type) {
  margin-bottom: 15px;
}

.pointer {
  cursor: pointer;
}

.autoHeight {
  .box-layout {
    max-height: 100%;
    margin-bottom: 0;
  }
}
.sys-notice {
  overflow-y: auto;
  row-gap: 21px;
}
.commom-use {
  // min-height: 50px;
  min-width: 70px;
  max-width: 170px;
}
</style>
