
<template>
  <el-dialog
    width="90%"
    title="查看评定方案"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top"  :column="4" >
      <el-descriptions-item label="方案名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="评定分类">{{detail.stName||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <patientScaleProgram patientScaleId="" flag="4" :scaleIdList="scaleList" :analysis="true" :preview="true"/>
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail, getScaleList } from '@/api/evaluationScheme'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'
import patientScaleProgram from '@/views/scale/patientAssessment/patientScaleProgram'
export default {
    name: 'viewScaleProgram',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
      patientScaleProgram
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            scaleList: [],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类', // 必填
                        prop: 'sctyName', // 必填
                    },
                    {
                        label: '量表名称', // 必填
                        prop: 'scaleInfoName' // 必填
                    },
                    {
                        label: '排序', // 必填
                        prop: 'sort' // 必填
                    },
                ]
            },
            operTypes: [],
            total: 0 // 总条数
        }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal && this.id) this.getDetail()
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      getDetail() {
        const param = {}
        //评定方案id
        if (this.id) {
          param.id = this.id
        }
        this.getScaleList();
        getDetail(param).then(({res}) => {
          this.detail = res
          this.operTypes.forEach(element => {
            if(element.value == this.detail.operType){
              this.detail.operType = element.label;
            }
          });
        })
      },
      /**
       * 获取评定方案绑定的量表
       */
      getScaleList() {
        this.$nextTick(() => {
          const param = {}
          //操作项id
          if (this.id) {
            param.id = this.id
          } else {
            return;
          }
          getScaleList(param).then(({res}) => {
            this.scaleList = res;
          })
        })
      },
      updateVisible(flag) {
        this.visible = flag
      },
    }
}
</script>
<style scoped lang='scss'>
</style>
