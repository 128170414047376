
/**
 * 页面: 患者评定统计
 * pageName: patientJudge
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者评定统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/patientjudge/index',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取患者评定统计列表数据详情
 */
export function toPatientDetail(data) {
    return request({
        url: 'statistics/patientjudge/toPatientDetail',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

        