<!--
 * @Author: sixteen
 * @Date: 2021-12-20 18:35:27
 * @LastEditTime: 2022-02-24 09:03:14
 * @LastEditors: ZF-WLY
 * @Description: 评估历史
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\dailyAssessment.vue
-->
<template>
    <div>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" v-if="addScaleInfo" @click="addScaleInfoClick()">新增量表</el-button>
                <el-button type="primary" v-if="addScaleProgram" @click="addProgramClick()">新增方案</el-button>
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">详情</el-button>
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" :disabled="scope.data.row.patientScaleStatus == 2" @click="toDel(scope)" class="delBtn">
                        删除
                    </el-button>
                    <el-button type="text" :disabled="scope.data.row.patientScaleStatus != 2" @click="toPrint(scope)">打印</el-button>
                    <el-button v-if="hasPermission('scale-info-summary') && scope.data.row.patientScaleType !== '1'" type="text" :disabled="scope.data.row.patientScaleStatus != 2" @click="toSummarizing(scope)">汇总</el-button>
                </template>
            </generalTable>
            <addScaleDialog ref="insert" @popData="addScale" />
            <addProgramDialog ref="insertProgram" @popData="addProgram" />
        </el-main>
    </div>
</template>

<script>
import generalTable from '@/components/Table';
import addScaleDialog from '@/views/scale/patientAssessment/addScaleDialog';
import addProgramDialog from '@/views/scale/patientAssessment/addProgramDialog';
import { getScaleSummary } from '@/api/summaryScale'
import { getScaleList, insertScale, deleteScale, editScaleData, insertProgram, deleteProgram } from '@/api/patientAssessment';

export default {
    name: 'assessHistory',
    components: {
        generalTable,
        addScaleDialog,
        addProgramDialog
    },
    props: {
        basicInfoId: {
            type: String
        },
    },
    data() {
        return {
            tableData: [],
            addScaleInfo: true,
            addScaleProgram: true,
            tableConfig: {
                id: 'assessHistory',
                align: 'center', // 不必填 默认为center
                selection: '', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类', // 必填
                        prop: 'classification' // 必填
                    },
                    {
                        label: '类型', // 必填
                        prop: 'patientScaleTypeLabel' // 必填
                    },
                    {
                        label: '量表/方案名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '评定日期', // 必填
                        prop: 'evaluateDate', // 必填
                        formatter: (row, column) => {
                            return row.evaluateDate ? this.moment(row.evaluateDate).format('YYYY-MM-DD') : '--';
                        }
                    },
                    {
                        label: '评定人', // 必填
                        prop: 'evaluateByName' // 必填
                    },
                    {
                        label: '状态', // 必填
                        prop: 'patientScaleStatusLabel' // 必填
                    },
                    {
                        label: '创建人', // 必填
                        prop: 'createByName' // 必填
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate).format('YYYY-MM-DD');
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '' // 单条数据id 用于详情或编辑请求数据用
        };
    },
    created() {
        //按钮权限
        this.addScaleInfo = this.hasPermission('scale-info-add');
        this.addScaleProgram = this.hasPermission('scale-program-add');
        this.getList();
    },
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.basicInfoId
                };
                getScaleList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    // this.total = parseInt(res.total);
                    this.timeStamp = timeStamp;
                });
            });
        },

        /**
         * @description: 查看id 传评估id或者量表id   flag 1查看量表模板  2编辑评估  3查看评估 4打印
         * @param {*} scope patientScaleType =0 量表 =1 方案
         * @return {*}
         */
        toDetail(scope) {
            this.toScaleByFlag(scope, '3')
        },

        /**
         * @description: 编辑 flag 1查看量表模板  2编辑评估  3查看评估 4打印
         * @param {*} patientScaleType =0 量表 =1 方案
         * @return {*}
         */
        toEdit(scope) {
            this.toScaleByFlag(scope, '2')
        },

        /**
         * @description:删除  patientScaleType =0 量表 =1 方案
         * @param {*} scope
         * @return {*}
         */
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };

                if (scope.data.row.patientScaleType == '0') {
                    deleteScale(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess });
                        this.getList();
                    });
                } else {
                    deleteProgram(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess });
                        this.getList();
                    });
                }
            });
        },

        /**
         * @description: 打印 1查看量表模板  2编辑评估  3查看评估 4打印
         * @param {*}  patientScaleType =0 量表 =1 方案
         * @return {*}
         */
        toPrint(scope) {
            this.toScaleByFlag(scope, '3')
        },
        /** 根据flag，显示不同量表及方案页面
         * @description: 
         * @param {*} scope 传入的行数据
         * @param {*} flag 显示模式"2":编辑 "3":显示/打印
         * @return {*}
         */        
        toScaleByFlag(scope, flag) {
            const basicInfoId = this.basicInfoId
            if (scope.data.row.patientScaleType == '0') {
                this.$router.push({
                    path: '/scale/patientScale',
                    query: { patientScaleId: scope.data.row.id, flag, basicInfoId }
                });
            } else {
                this.$router.push({
                    path: '/scale/patientScaleProgram',
                    query: { patientScaleId: scope.data.row.id, flag, basicInfoId }
                });
            }
        },

        /**
         * @description: 汇总
         * @param {*}
         * @return {*}
         */
        toSummarizing(scope) {
            console.log("------scale------", scope.data.row)
            getScaleSummary({id: scope.data.row.id}).then(({res}) => {
                console.log("-------get summary------", res)
                if (!res.msg) {
                    this.$router.push({
                        path: '/scale/patientAssessment/patientScaleSummary',
                        query: {
                            id: scope.data.row.id,
                            basicInfoId: this.basicInfoId
                        }
                    });
                }
            })
        },
        /**
         * @description: 新增量表
         * @param {*}
         * @return {*}
         */
        addScaleInfoClick() {
            this.$refs.insert.updateVisible(true);
        },

        /**
         * @description:新增量表回掉
         * @param {*}
         * @return {*}
         */
        addScale(value) {
            const scaleInfoId = value.map(item => {
                return item.id;
            });
            const parm = {
                basicInfoId: this.basicInfoId, //患者ID
                scaleInfoId: scaleInfoId
            };
            insertScale(parm).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getList();
                this.$emit('refreshData')
            });
        },

        /**
         * @description: 添加方案
         * @param {*}
         * @return {*}
         */
        addProgramClick() {
            this.$refs.insertProgram.updateVisible(true);
        },
        /**
         * @description: 新增方案回掉
         * @param {*}
         * @return {*}
         */
        addProgram(value) {
            const scaleInfoId = value.map(item => {
                return item.id;
            });
            const parm = {
                basicInfoId: this.basicInfoId, //患者ID
                scaleProgramId: scaleInfoId
            };
            insertProgram(parm).then(({ res }) => {
                this.$message({ ...this.commonConfig.$handleSuccess });
                this.getList();
                this.$emit('refreshData')
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
