<!--
 * @Author: mazihao
 * @Date: 2023-04-17 09:55:42
 * @LastEditors: mazihao mazihao@newpay.la
 * @LastEditTime: 2023-04-17 13:36:10
 * @FilePath: /recovery_management/src/views/businessRules/demo/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-container>
    <el-header height="auto">
      <!-- <searchForm ref="mainSearch"
                  :searchoptions="searchOptions" /> -->
    </el-header>
    <el-main class="basic-main">
      <div class="mdrcc-info-title title">上肢运动状态</div>
      <div id='myEcharts'
           style=""
           class="echartsBox"></div>
      <div style="display:flex">
        <div style="width:50%">
          <div class="mdrcc-info-title title">各项明细</div>
          <generalTable ref="mainTable1"
                        :table-data="operResList1"
                        :config="tableConfig1"
                        :newHeight="'185px'">
          </generalTable>
        </div>
        <div style='width:50%'>
          <div class="mdrcc-info-title title">上肢运动数据统计</div>
          <generalTable ref="mainTable2"
                        :table-data="operResList2"
                        :config="tableConfig2"
                        :newHeight="'185px'">
          </generalTable>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import * as echarts from "echarts";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";

export default {
  components: {
    searchForm,
    generalTable,
  },
  data() {
    return {
      operResList1: [
        { operName: "肩" },
        { operName: "肘前臂" },
        { operName: "肩肘前臂" },
        { operName: "腕" },
        { operName: "手" },
        { operName: "手" },
      ],
      operResList2: [
        { careLabel: "2023-04-11", operName: "26", operName1: "22" },
        { careLabel: "2023-04-12", operName: "20", operName1: "27" },
        { careLabel: "2023-04-13", operName: "22", operName1: "23" },
        { careLabel: "2023-04-14", operName: "23", operName1: "21" },
        { careLabel: "2023-04-14", operName: "23", operName1: "21" },
        { careLabel: "2023-04-14", operName: "23", operName1: "21" },
      ],
      form: {},
      value1: "",
      tableConfig1: {
        id: "operResList",
        align: "center", // 不必填 默认为center
        index: true, // 不必填 序号
        column: [
          {
            label: "各项明细", // 必填
            prop: "operName", // 必填
            sortable: false,
          },
        ],
      },
      tableConfig2: {
        id: "operResList",
        align: "center", // 不必填 默认为center
        column: [
          {
            label: "日期", // 必填
            prop: "careLabel",
            sortable: false,
          },
          {
            label: "均值", // 必填
            prop: "operName", // 必填
            sortable: false,
          },
          {
            label: "最大值", // 必填
            prop: "operName1", // 必填
            sortable: false,
          },
        ],
      },
      searchOptions: [
        {
          type: "daterange",
          key: "treatmentDate",
          label: "检测时间",
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "材料名称",
          defaultVal: "",
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "serialNum",
          label: "质控品批号",
          defaultVal: "",
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "项目",
          defaultVal: "",
        },
      ],
    };
  },
  mounted() {
    this.echartsFunc();
  },
  methods: {
    search() {},
    echartsFunc() {
      let myChart = echarts.init(document.getElementById("myEcharts"));
      let option = {
        title: {
          left: "center",
          text: "",
        },
        xAxis: {
          type: "category",
          data: [
            "8:00",
            "8:30",
            "9:00",
            "9:30",
            "10:00",
            "10:30",
            "11:00",
            "11:30",
            "12:00",
            "12:30",
            "13:00",
            "13:30",
            "14:00",
            "14:30",
            "15:00",
            "15:30",
            "16:00",
            "16:30",
            "17:00",
          ],
          boundaryGap: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "",
            type: "line",
            data: [
              "1",
              "3",
              "5",
              "7",
              "9",
              "6",
              "7",
              "8",
              "9",
              "10",
              "15",
              "12",
              "13",
              "11",
              "15",
              "13",
              "15",
              "14",
              "13",
              "20",
            ],
            smooth: true,
            areaStyle: {},
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.echartsBox {
  box-sizing: border-box;
  border: 1px solid #aeaeae;
  border-radius: 10px;
  margin: 20px;
  height: 300px;
  width: 95%;
}
.tableStyle {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: 20px;
}
.one {
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 350px;
  width: 32%;
}
.two {
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 350px;
  width: 27%;
}
.three {
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 350px;
  width: 32%;
}
.title {
  margin-top: 15px;
}
</style>