
<!--
* @Description: 治疗记录页面
* @Reference:
-->

<template>
    <div style="height:100%">
        <el-header height="auto">
            <searchForm ref="mainSearch" style="padding-top:15px" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" v-if="voidStatus" @click="toBatchVoid()">批量作废</el-button>
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" :selectable="selectable">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click.stop="toDetail(scope)">详情</el-button>
                </template>
            </generalTable>
            <detailDialog :id="checkId" ref="detail" />
            <batchVoidDialog :ids="checkId" ref="batch" :timeStamp="timeStamp" @submit="search" />
        </el-main>
    </div>
</template>

<script>
import { getTreatMentList, deleteData } from '@/api/arrangeTreatment'
import { getDict } from '@/api/common'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import detailDialog from '@/views/treatment/arrangeTreatment/detailDialog'
import batchVoidDialog from '@/views/treatment/arrangeTreatment/batchVoidDialog'

export default {
    name: 'arrangeTreatment',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        batchVoidDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'basicInfoName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'operName',
                    label: '治疗项目',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'treatmentDate',
                    label: '治疗日期',
                    defaultVal: [this.moment().format('YYYY-MM-DD'),this.moment().format('YYYY-MM-DD')]
                },
                {
                    type: 'select', // 必填 
                    placeholder: '请选择...', //非必填 默认为 请选择
                    key: 'status', // 必填
                    label: '状态', // 必填
                    options: [],
                    defaultVal: '0' // 非必填 默认值
                }
            ],
            tableConfig: {
                id: 'arrangeTreatment',
                align: 'center', // 不必填 默认为center
                pagination: true, // 不必填 分页 默认为false
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'basicInfoName', // 必填
                    width: 80,
                },
                {
                    label: '住院号', // 必填
                    prop: 'recordNum', // 必填
                    width: 130,
                },
                {
                    label: '就诊号', // 必填
                    prop: 'serialNum', // 必填
                    width: 130,
                },
                {
                    label: '治疗师', // 必填
                    prop: 'therapyName', // 必填
                    width: 100,
                },
                {
                    label: '医嘱类型', // 必填
                    prop: 'doctorOrderTypeLabel', // 必填
                    width: 80,
                },
                {
                    label: '所属治疗', // 必填
                    prop: 'operTypeLabel', // 必填
                    width: 100,
                },
                {
                    label: '治疗项目', // 必填
                    prop: 'operName' // 必填
                },
                {
                    label: '治疗时间', // 必填
                    prop: 'treatmentDate', // 必填
                    width: 150,
                    formatter: (row, column) => {
                        return this.moment(row.treatmentDate).format('YYYY-MM-DD HH:mm:ss')
                    },
                },
                {
                    label: '状态', // 必填
                    prop: 'statusLabel', // 必填
                    width: 80,
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: 100 // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            voidStatus: true, // 批量作废按钮状态
        }
    },
    created() {
        this.getList()
        this.getDict()
        this.voidStatus = this.hasPermission('batchVoid')
    },
    mounted: function() {},
    methods: {
        selectable(row,index){
            return row.patientStatus==1||row.patientStatus==2
        },
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getTreatMentList( Object.assign(param, {allPatientFlag:'1'})).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        getDict(){
            this.$nextTick(() => {
                getDict({type:'treatment_status'}).then(({res}) => {
                    this.searchOptions[this.searchOptions.length - 1].options = res.treatment_status
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        /**
         * 批量作废
         * @description: 打开批量作废弹出层
         */ 
        toBatchVoid(){
            this.checkId = this.$refs.mainTable.getChecked()
            if (this.checkId.length === 0) {
                this.$message({
                    message: '请至少选择一项',
                    type: 'warning'
                });
                return false
            }
            this.$refs.batch.updateVisible(true)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        