/*
 * @Author: your name
 * @Date: 2021-11-23 13:20:34
 * @LastEditTime: 2021-11-23 16:28:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\dictionaryManagement.js
 */

/**
 * 页面: 字典管理
 * pageName: dictionaryManagement
 **/
import request from '@/utils/request'

/**
 * @description: 获取字典管理列表数据
 */
export function getList(data) {
    return request({
        url: '/dict/pageList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取字典类型数据
 */
 export function getdictTypeList(data) {
    return request({
        url: '/dict/dictType',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增字典管理数据
 */
export function insertData(data) {
    return request({
        url: '/dict/addDict',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 删除字典管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/dict/deleteDict',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改字典排序
 */
export function updateSort(data, timeStamp) {
  return request({
    url: '/dict/updateSort',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

