<!--
 * @Author: your name
 * @Date: 2022-11-18 13:31:14
 * @LastEditTime: 2022-12-09 16:48:01
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\folder\addNodeDialog.vue
-->
<template>
    <el-dialog
        width="800px"
        :title="source.id ? '修改节点' : '新增节点'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-col :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="名称" class="formItem" prop="name">
                    <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="70" />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="上级目录" class="formItem" prop="parentId">
                    <el-cascader
                        v-model="form.parentId"
                        :options="parentNode"
                        :props="{
                            checkStrictly: true,
                            label: 'name',
                            value: 'id'
                        }"
                        clearable
                        filterable
                    />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="状态" class="formItem" prop="status">
                    <el-select v-model="form.status" placeholder="请选择状态">
                        <el-option
                            v-for="item in statusOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="类型" class="formItem" prop="type">
                    <el-select v-model="form.type" placeholder="请选择类型">
                        <el-option
                            v-for="item in typeOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col v-if="form.type === '1'" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="关联模板" class="formItem" prop="templateId">
                    <el-select v-model="form.templateId" filterable placeholder="请选择模板">
                        <el-option v-for="item in templateList" :key="item.id" :label="item.name" :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.labelName }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="说明" class="formItem" prop="remark">
                    <el-input
                        v-model="form.remark"
                        type="textarea"
                        placeholder="请输入..."
                        clearable
                        maxlength="50"
                        rows="4"
                    />
                </el-form-item>
            </el-col>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { caseTemplateList } from '@/api/caseReport'
import { insertCasesCollectTree, updateCasesCollectTree } from '@/api/casesCollectTree'
export default {
    props: {
        collectId: String,
        tableData: Array,
        parentId: String,
        source: Object
    },
    watch: {
        visible(newVal) {
            if (!newVal) {
                this.$refs.insertForm.resetFields()
            } else {
                this.parentNode = this.removeEmptyChild(this.tableData)
                caseTemplateList({ size: -1, status: '0' }).then(({ res }) => {
                    this.templateList = res.records
                })
                if (this.source.id) {
                    this.form = { ...this.source }
                    console.log('------edit', this.form, this.tableData, this.parentNode)
                } else {
                    this.form = {
                        ...this.form,
                        parentId: this.parentId
                    }
                }
            }
        }
    },
    data() {
        return {
            visible: false,
            parentNode: [],
            rules: {
                name: [
                    { required: true, message: '请输入模板/文件夹名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                parentId: [{ required: true, message: '请选择上级目录', trigger: 'change' }],
                status: [{ required: true, message: '请选择模板/文件夹状态', trigger: 'change' }],
                type: [{ required: true, message: '请选择节点类型', trigger: 'change' }]
            },
            // 类型 0 文件夹 1病例模板
            typeOpts: [
                { value: '0', label: '文件夹' },
                { value: '1', label: '病例模板' }
            ],
            // 状态 0 启用 1 禁用
            statusOpts: [
                { value: '0', label: '启用' },
                { value: '1', label: '禁用' }
            ],
            templateList: [],
            form: {
                name: '',
                type: '',
                parentId: '',
                templateId: '',
                status: '0',
                remark: ''
            }
        }
    },
    created() {},
    methods: {
        /** 菜单详情
         * @description: 将获取的菜单详情信息同步到form数据中
         * @param {*}
         * @return {*}
         */
        getList() {
            getCollectTree({ collectId: this.id }).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                console.log('-----get tree', res)
                this.tableData = res
            })
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        },
        submit() {
            let rules = { ...this.rules }
            if (this.form.type === '1') {
                rules.templateId = [{ required: true, message: '请选择关联模板', trigger: 'change' }]
            } else {
                delete rules.templateId
            }
            this.rules = { ...rules }
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    let parm = { collectId: this.collectId, ...this.form }
                    if (typeof this.form.parentId === 'string') {
                        let parentId = this.form.parentId.split(',')
                        parentId = parentId[parentId.length - 1]
                        parm = { ...parm, parentId }
                    } else {
                        let parentId = [...this.form.parentId]
                        parentId = parentId[parentId.length - 1]
                        parm = { ...parm, parentId }
                    }
                    if (!this.form.id) {
                        insertCasesCollectTree(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        updateCasesCollectTree(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        /**
         * 移除空的子节点
         * @description: 如果某个对象中，children的数组长度为0，则移除该层children；递归遍历查找每层children
         * @param {Array} orgData 原始树结构数组
         * @return {Array} 移除原始树结构中空children后的结果
         */
        removeEmptyChild(orgData) {
            const tempData = orgData.map(item => {
                let { children, ...temp } = item
                let disabled = this.source.id && this.source.id === item.id
                if (children && children.length) {
                    return {
                        disabled,
                        ...temp,
                        children: this.removeEmptyChild(children) // 递归遍历，把新的过滤好的children插入到原先的children中
                    }
                } else {
                    return { disabled, ...temp }
                }
            })
            return tempData
        }
    }
}
</script>
