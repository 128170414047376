<!--
 * @Author: SixTeen
 * @Date: 2021-11-18 10:56:35
 * @LastEditTime: 2023-02-24 15:38:28
 * @LastEditors: wangly wly574523605@163.com
 * @Description: 我的日程
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\mySchedule\index.vue
-->

<template>
	<el-container>
		<el-header height="auto">
			<div class="nameBox" v-text="userName"></div>
			<div class="backBtn">
				<el-button type="primary" @click="scheduleAllSelects()">一键排班</el-button>
				<el-button type="default" @click="back()">返回</el-button>
			</div>
		</el-header>
		<el-main class="basic-main">
			<table v-if="tableData.length > 0">
				<thead>
					<tr>
						<th>
							<div class="patientBoxItem"></div>
						</th>
						<th v-for="item in columns" :key="item.id">
							<div class="patientBoxItem">
								<span>{{ item.operName }}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(itemTime, comlum) in timeList[0]" :key="itemTime.times[0]">
						<td style="width: 120px">
							<div class="timeText">{{ itemTime.times[0] }}-{{ itemTime.times[1] }}</div>
						</td>
						<td
							:class="itemTime.times[2] === '1' ? '' : 'dataBox'"
							v-for="(data, index) in tabList(comlum)"
							:key="index"
							@click="toInsert(itemTime.times[0], index)"
						>
							<div class="patientBoxItem">
								<div @click.stop>
									<div
										v-for="patient in data"
										:key="patient.id"
										class="patientBox"
										@click="delStatus && toDel(patient)"
									>
										{{ patient.patientName }}
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<el-empty v-else description="请在个人设置中配置对应的治疗项目或设备"></el-empty>
			<addDialog ref="insert" @submit="submit" :entry="$route.query.entry" />
			<addPatients ref="insertPats" @submit="allSelectdPats" />
		</el-main>
	</el-container>
</template>

<script>
import { getList, deleteData, insertData } from '@/api/mySchedule'
import { autoSchedule } from '@/api/allSchedule'
import addDialog from '@/views/treatment/mySchedule/addDialog'
import addPatients from '@/views/treatment/mySchedule/addPatients.vue'

export default {
	name: 'MySchedule',
	components: {
		addDialog,
		addPatients,
	},
	data() {
		return {
			tableData: [],
			paramObj: null, // 筛选项
			userName: '', //名称
			checkId: '', // 单条数据id 用于详情或编辑请求数据用
			scheduleTime: '', //行 时间
			timeStamp: '', //时间戳 用于修改数据或删除数据
			columns: Array, //操作项数据
			timeList: Array, //时间数据
			userId: '', //用户id
			delStatus: true, // 删除按钮权限
		}
	},

	created() {
		//删除权限
		this.delStatus = this.hasPermission('mySchedule_del')
		this.userId = this.$route.query.userId
		this.getList()
	},
	mounted: function () {},
	methods: {
		/**
		 * @description: 获取数据
		 * @param {*}
		 * @return {*}
		 */
		getList() {
			this.$nextTick(() => {
				const param = { userId: this.userId }
				getList(param).then(({ res, timeStamp }) => {
					this.columns = res.grid.map((item) => {
						//操作项目
						return item.oper
					})
					this.timeList = res.grid.map((item) => {
						//时间段
						return item.timeList
					})
					this.tableData = res.grid
					;(this.userName = res.userName), (this.timeStamp = timeStamp)
				})
			})
		},
		/**
		 * @description: 数据处理
		 * @param {*} index 行数
		 * @return {*}
		 */
		tabList(index) {
			return this.timeList.map((val) => {
				let temp = []
				temp = val[index].patients
				return temp
			})
		},
		scheduleAllSelects() {
			this.$refs.insertPats.updateVisible(true)
		},
		/**
		 * @description: 新增我的日程 弹窗
		 * @param {*} patient
		 * @return {*}
		 */
		toInsert(time, oper) {
			this.checkId = this.columns[oper].operId
			this.scheduleTime = time
			this.scheduleType = this.columns[oper].scheduleType
			this.$refs.insert.updateVisible(true)
		},

		/**
		 * @description: 删除我的日程
		 * @param {*} scope
		 * @return {*}
		 */
		toDel(scope) {
			const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
			this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
				const param = { id: scope.id }
				deleteData(param, this.timeStamp).then(({ res }) => {
					this.$message({ ...$handleSuccess })
					this.getList()
				})
			})
		},
		allSelectdPats(basicInfoIds) {
			let param = { basicInfoIds }
			if (this.userId) {
				param.status = '2'
				param.userId = this.userId
			} else {
				param.status = '1'
			}
			autoSchedule(param).then(({ res }) => {
                const {message, list} = res
                const messageContent = message.split("\n").map(item => `<p>${item}</p>`).join("")
                let tableContent = ""
                const divStyle = "max-width: 500px; overflow-y: auto;"
                const tableStyle = "border-collapse: collapse; width: 100%;"
                const tdStyle = "border: solid thin #dedede;padding: 5px;"
                list.forEach(element => {
                    const {name} = element
                    element.scheduleList.forEach(sch => {
                        const {operName, scheduleTime} = sch
                        tableContent += `<tr>
                            <td style="${tdStyle}">${name}</td>
                            <td style="${tdStyle}">${operName}</td>
                            <td style="${tdStyle}">${scheduleTime}</td>
                        </tr>`
                    })
                });
				this.$alert(`${messageContent}<div style="${divStyle}"><table style="${tableStyle}">${tableContent}</table></div>`, '提示', {
					dangerouslyUseHTMLString: true,
                    callback:() => {
                        this.getList()
                    }
				})
			})
		},
		/**
		 * @description: 新增提交
		 * @param {*} data
		 * @return {*}
		 */
		submit(data) {
			const parm = {
				basicInfoId: data.basicInfoId, //患者ID
				scheduleTime: this.scheduleTime, //排程时间
				scheduleType: this.scheduleType, //日程类型 0 手法 1 设备
				userId: this.userId,
			}
			let parms
			if (this.scheduleType == 0) {
				//0 手法 1设备
				parms = Object.assign({ operId: this.checkId }, parm) //操作项ID 手法
			} else {
				parms = Object.assign({ armariumId: this.checkId }, parm) //设备
			}
			insertData(parms).then(({ res }) => {
				this.$message({ ...this.commonConfig.$handleSuccess })
				this.getList()
			})
		},
		/**
		 * @description: 返回
		 * @param {*}
		 * @return {*}
		 */
		back() {
			this.$router.back()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
table {
	border-radius: 3px;
	background-color: $tableColor;
	font-size: 14px;
	width: 100%;
	border-collapse: collapse;
}

th {
	background-color: $background;
	color: $titleColor;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

th,
td {
	text-align: center;
	color: $titleColor;
	background-color: $background;
	padding: 0;
	border-spacing: 1px;
}
thead th {
	border-width: 0;
	border-top: 1px solid $tableColor;
	border-bottom: 1px solid $tableColor;
	border-left: 1px solid $tableColor;
}

thead th:last-of-type {
	border-right: 1px solid $tableColor;
}

tbody td {
	border-left: 1px solid $tableColor;
	border-bottom: 1px solid $tableColor;
}

tbody tr td:last-of-type {
	border-right: 1px solid $tableColor;
}

.dataBox {
	background-color: $menuText;
}
.patientBoxItem {
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	min-width: 130px;
	//max-height: 250px;
	min-height: 40px;
}
.patientBox {
	width: 110px;
	background-color: #e9f1fb;
	color: $theme;
	height: 30px;
	line-height: 30px;
	margin: 5px 0px;
	justify-content: center;
	align-items: center;
}
.el-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.backBtn {
	display: flex;
	justify-content: right;
	margin: 4px;
}
</style>
