
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增全文检索' : '修改全文检索'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
            基本信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="活动名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="请输入..."
                    clearable
                    maxlength="20"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="角色单选" prop="select">
                <el-select
                    v-model="form.select"
                    clearable
                    placeholder="请选择"
                    :filterable="true"
                >
                    <el-option
                    v-for="option in options"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                    />
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="角色多选" prop="select2">
                <el-select
                    v-model="form.select2"
                    clearable
                    placeholder="请选择"
                    :filterable="true"
                    :multiple="true"
                    :collapse-tags="true"
                    style="width:100%"
                >
                    <el-option
                    v-for="option in options"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                    />
                </el-select>
                </el-form-item>
            </el-col>
            </el-row>
            <el-divider />
            <div class="mdrcc-info-title">
            治疗信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="固定时间点(开始)" prop="fixedStartTime">
                <el-time-select
                    v-model="form.fixedStartTime"
                    :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '18:30',
                    maxTime: form.fixedEndTime
                    }"
                    placeholder="选择时间"
                    clearable
                    style="width:100%"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="固定时间点(结束)" prop="fixedEndTime">
                <el-time-select
                    v-model="form.fixedEndTime"
                    :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '18:30',
                    minTime: form.fixedStartTime
                    }"
                    placeholder="选择时间"
                    clearable
                    style="width:100%"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="日期" prop="date">
                <el-date-picker
                    v-model="form.date"
                    type="daterange"
                    :picker-options="commonConfig.$datePickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :editable="false"
                    style="width:100%"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="状态" prop="status">
                <el-switch
                    v-model="form.status"
                    name="status"
                    active-text="启用"
                    inactive-text="禁用"
                    inactive-value="0"
                    active-value="1"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="计数器" prop="number">
                <el-input-number v-model="form.number" name="number" :min="1" :max="99" label="计数器" />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="单选框组" prop="radio">
                <el-radio-group v-model="form.radio">
                    <el-radio :label="3">备选项</el-radio>
                    <el-radio :label="6">备选项</el-radio>
                    <el-radio :label="9">备选项</el-radio>
                </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="12">
                <el-form-item label="多选框组" prop="checkbox">
                <el-checkbox-group v-model="form.checkbox">
                    <el-checkbox label="复选框 A" />
                    <el-checkbox label="复选框 B" />
                    <el-checkbox label="复选框 C" />
                    <el-checkbox label="禁用" disabled />
                    <el-checkbox label="选中且禁用" disabled />
                </el-checkbox-group>
                </el-form-item>
            </el-col>
            </el-row>
            <el-divider />
            <div class="mdrcc-info-title">
            评估信息
            </div>
            <el-row :gutter="10">
            <div class="table-btn-group">
                <el-button type="primary" @click="toggleModal()">新增</el-button>
            </div>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total">
                <template slot="test">
                    <el-input
                        v-model="form.name"
                        placeholder="请输入..."
                        clearable
                        maxlength="20"
                    />
                </template>
                <template slot="handle" slot-scope="scope">
                <el-button type="text" @click="toDetail(scope)">详情</el-button>
                <el-button type="text" @click="toEdit(scope.row.id)">编辑</el-button>
                <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData,getDetail } from '@/api/fullSearch'
import generalTable from '@/components/Table'
          
export default {
    props: {
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    data() {
        return {
            checkId: '',
            tableData: [
                {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }, {
                    first: '内容一',
                    second: '内容二',
                    third: '内容三',
                    fourth: '内容四'
                }
            ],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '标题一', // 必填
                        prop: 'first', // 必填
                        formatter: (row, column, cellValue, index) => {
                            return row.first + index
                        }
                    },
                    {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    }, {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    }, {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    }, {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    }, {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    }, {
                        label: '标题二', // 必填
                        prop: 'second' // 必填
                    },
                    {
                        label: '标题三', // 必填
                        prop: 'third' // 必填
                    },
                    {
                        label: '标题四', // 必填
                        prop: 'fourth' // 必填
                    },
                    {
                        slot: 'test', // 不必填 单列插槽
                        label: '操作111', // 必填
                        width: '220' // 不必填
                    }, {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
                date1: [
                    { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                ],
                date2: [
                    { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
                ],
                type: [
                    { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
                ],
                resource: [
                    { required: true, message: '请选择活动资源', trigger: 'change' }
                ],
                desc: [
                    { required: true, message: '请填写活动形式', trigger: 'blur' }
                ]
            },
            options: [
                {
                    value: '1',
                    label: '角色1'
                },
                {
                    value: '2',
                    label: '角色2'
                },
                {
                    value: '3',
                    label: '角色3'
                },
                {
                    value: '4',
                    label: '角色4'
                },
                {
                    value: '5',
                    label: '角色5'
                }
            ],
            form: {
                checkbox: []
            },
            total: 0,
            visible: false,
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
            //...todo
          }
        }
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                const parm = this.form
                if (this.id === '') {
                    insertData(parm).then(({res}) => {
                        this.submitSuccess(res)
                    })
                } else {
                    editData(parm,this.timeStamp).then(({res}) => {
                        this.submitSuccess(res)
                    })
                }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail(){
            getDetail({id:this.id}).then(({res,timeStamp}) => {
                this.form = res
                // this.timeStamp = timeStamp
            })
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...this.commonConfig.$handleSuccess})
                })
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        