/*
 * @Description: 
 * @Author: yzw
 * @Date: 2021-11-12 09:20:10
 * @LastEditTime: 2021-11-12 15:28:14
 * @LastEditors: yzw
 * @Reference: 
 */

/**
 * 页面: 自定义配置
 * pageName: customCollocation
 **/
import request from '@/utils/request'

/**
 * @description: 获取自定义配置列表数据
 */
export function getList(data) {
    return request({
        url: '/configMenu/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增自定义配置数据
 */
export function insertData(data) {
    return request({
        url: '/configMenu/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改自定义配置数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/configMenu/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取自定义配置详情数据
 */
export function getDetail(data) {
    return request({
        url: '/configMenu/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除自定义配置数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/configMenu/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 获取表格列-列表数据
 */
export function getMenuColumnList(data) {
    return request({
        url: '/configMenuColumn/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增表格列数据
 */
export function insertMenuColumnData(data) {
    return request({
        url: '/configMenuColumn/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改表格列数据
 */
export function editMenuColumnData(data, timeStamp) {
    return request({
        url: '/configMenuColumn/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取表格列详情数据
 */
export function getMenuColumnDetail(data) {
    return request({
        url: '/configMenuColumn/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除表格列数据
 */
export function deleteMenuColumnData(data, timeStamp) {
    return request({
        url: '/configMenuColumn/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取筛选项-列表数据
 */
export function getMenuSearchList(data) {
    return request({
        url: '/configMenuSearch/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增筛选项数据
 */
export function insertMenuSearchData(data) {
    return request({
        url: '/configMenuSearch/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改筛选项数据
 */
export function editMenuSearchData(data, timeStamp) {
    return request({
        url: '/configMenuSearch/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取筛选项详情数据
 */
export function getMenuSearchDetail(data) {
    return request({
        url: '/configMenuSearch/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除筛选项数据
 */
export function deleteMenuSearchData(data, timeStamp) {
    return request({
        url: '/configMenuSearch/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}