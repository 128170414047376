/*
 * @Author: your name
 * @Date: 2022-01-20 09:54:43
 * @LastEditTime: 2022-02-08 13:56:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\kettle.js
 */
import request from '@/utils/request'

/**
 * @description: 获取列表数据
 */
 export function getList(data) {
  return request({
      url: '/etlConfig/list',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description: 获取详情数据
 */
 export function getDetail(data) {
  return request({
      url: '/etlConfig/info',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description: 新增
 */
 export function insertData(data) {
  return request({
      url: '/etlConfig/add',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 编辑
 */
export function editData(data) {
  return request({
      url: '/etlConfig/update',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 删除
 */
 export function deleteData(data) {
  return request({
      url: '/etlConfig/delete',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 修改任务状态
 */
export function modifyScheduleStatus(data) {
  return request({
    url: '/etlConfig/updateScheduleStatus',
    method: 'POST',
    data,
    showLoading: true
  })
}

/**
 * @description: 执行任务
 */
export function runJobArticle(data) {
  return request({
    url: '/etlConfig/syncByJobKey',
    method: 'post',
    data,
    showLoading: true
  })
}


