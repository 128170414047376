<!--
 * @Description: 数据接口平台
 * @Author: hjw
 * @Date: 2022-01-25 10:49:05
 * @LastEditTime: 2022-01-27 14:08:06
 * @LastEditors: [you name]
 * @Reference:
-->

<template>
  <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from "vuex";
import MenuBox from "@/components/MenuBox";
export default {
  name: "dataPlatForm",
  components: { MenuBox },
  data() {
    return {
      secondMenu: [],
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  created() {
    const { roles } = this.$store.getters;
    for (let i = 0; i < roles.length; i++) {
      const element = roles[i];
      if (element.name === "dataPlatFormMain") {
        this.secondMenu = element.children.filter(
          (obj) =>
            obj.type === "1" &&
            obj.hidden === false &&
            element.redirect !== obj.path
        );
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
