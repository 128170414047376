
<!--
* @Description: 治疗师工作量统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" :exportStatus="exportStatus" @exportFun="toExport"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="treatPatNum" slot-scope="scope">
                <el-button @click="toPersonal(scope)" type="text" >{{
                    scope.data.row.treatPatNum
                }}</el-button>
            </template>
            <template slot="treatNum" slot-scope="scope">
                <el-button @click="toCreateIncome(scope)" type="text" >{{
                    scope.data.row.treatNum
                }}</el-button>
            </template>
            <!--   需求变更---去掉评定统计  @date: 2022-3-1
             <template slot="scalePatNum" slot-scope="scope">
                <el-button @click="toPatient(scope)" type="text" >{{
                    scope.data.row.scalePatNum
                }}</el-button>
            </template>
            <template slot="scaleNum" slot-scope="scope">
                <el-button @click="toJudgeItems(scope)" type="text" >{{
                    scope.data.row.scaleNum
                }}</el-button>
            </template> -->
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList,toExport } from '@/api/therapistWorkload'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getDept } from '@/api/patient'

export default {
    name: 'TherapistWorkload',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '治疗师姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    label: '所属机构',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'treatDate',
                    label: '治疗日期',
                    defaultVal: []
                },
                // 需求变更---去掉评定统计  @date: 2022-3-1
                // {
                //     type: 'daterange',
                //     startPlaceholder: '开始日期',
                //     endPlaceholder: '结束日期',
                //     key: 'evaluateDate',
                //     label: '评定日期',
                //     defaultVal: []
                // }
            ],
            tableConfig: {
                id: 'therapistWorkload',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '治疗师姓名', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '所属机构', // 必填
                    prop: 'deptName' // 必填
                },
                {
                    label: '职位名称', // 必填
                    prop: 'jobTitleLabel' // 必填
                },
                {
                    label: '治疗患者总数（人）', // 必填
                    slot: 'treatPatNum' // 必填
                },
                {
                    label: '治疗总次数', // 必填
                    slot: 'treatNum' // 必填
                },
                // 需求变更---去掉评定统计  @date: 2022-3-1
                // {
                //     label: '评定患者总数（人）', // 必填
                //     slot: 'scalePatNum' // 必填
                // },
                // {
                //     label: '评定次数', // 必填
                //     slot: 'scaleNum' // 必填
                // },
                {
                    label: '营收总金额', // 必填
                    prop: 'amount' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            exportStatus: true, // 导出按钮的隐藏/显示 true显示 false隐藏
            startDate: '', //当前月第一天
            endDate: '', //当前月最后一天
            monthDate: [], //当前月集合
        }
    },
    created() {
        this.getDeptList();
        this.getList();
        this.getCurrentMonthFirst()
        this.getCurrentMonthLast()
        this.getMonthDate()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                })
            })
        },
           //给查询条件赋值为当前月份
           getMonthDate() {
           this.monthDate.push(this.startDate,this.endDate)
            this.searchOptions[2].defaultVal = this.monthDate
            
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },
        /**
         * @description: 查看治疗统计治疗详情
         * @param {*} scope
         * @return {*}
         */        
        toPersonal(scope) {
            const param = this.getParam()
            this.$router.push({
                path: "/statistics/patientRehabilitation",
                query: {
                    empId: scope.data.row.id,
                    treatDateStartTime: param.treatDateStartTime,
                    treatDateEndTime: param.treatDateEndTime
                },
            });
        },
        /**
         * @description: 查看治疗项目创收统计
         * @param {*} scope
         * @return {*}
         */        
        toCreateIncome(scope) {
            const param = this.getParam()
            this.$router.push({
                path: "/statistics/createIncome",
                query: {
                    empId: scope.data.row.id,
                    treatDateStartTime: param.treatDateStartTime,
                    treatDateEndTime: param.treatDateEndTime
                },
            });
        },
        // 需求变更---去掉评定统计  @date: 2022-3-1
        // /**
        //  * @description: 查看患者评定统计
        //  * @param {*} scope
        //  * @return {*}
        //  */        
        // toPatient(scope) {
        //     const param = this.getParam()
        //     this.$router.push({
        //         path: "/statistics/therapistWorkload/patient",
        //         query: {
        //             id: scope.data.row.id,
        //             evaluateDateStartTime: param.evaluateDateStartTime,
        //             evaluateDateEndTime: param.evaluateDateEndTime
        //         },
        //     });
        // },
        // /**
        //  * @description: 查看评定项目统计
        //  * @param {*} scope
        //  * @return {*}
        //  */        
        // toJudgeItems(scope) {
        //     const param = this.getParam()
        //     this.$router.push({
        //         path: "/statistics/judgeItems",
        //         query: {
        //             id: scope.data.row.id,
        //             evaluateDateStartTime: param.evaluateDateStartTime,
        //             evaluateDateEndTime: param.evaluateDateEndTime
        //         },
        //     });
        // },
        /**
         * @description: 获取所属机构
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept().then(({ res }) => {
                let deptList = res.map((item) => {
                    return {
                    value: item.id,
                    label: item.label,
                    };
                });
                this.searchOptions[1].options = deptList
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        /**导出
         * @description: 点击搜索组件中的导出按钮触发的函数
         * @param {*}
         * @return {*}
         */        
        toExport(){
            // const paramObj = this.$refs.mainSearch.packageData()
            const param = this.getParam()
            toExport(param).then(({ res }) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              var aData = new Date();
              const currentDate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              const fileName = "治疗师工作量统计-"+currentDate+".xlsx";
              const linkNode = document.createElement("a");
              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击
    
              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            })
            .catch((err) => {
              console.log(err);
            });
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        