<!--
 * @Author: your name
 * @Date: 2022-12-07 14:54:26
 * @LastEditTime: 2022-12-07 16:14:27
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\tree\changeDialog.vue
-->
<template>
    <el-dialog
        width="600px"
        title="变更模板"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-col :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="模板" class="formItem" prop="templateId">
                    <el-select v-model="form.templateId" filterable placeholder="请选择模板">
                        <el-option v-for="item in templateList" :key="item.id" :label="item.name" :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.labelName }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { caseTemplateList } from '@/api/caseReport'
export default {
    watch: {
        visible(newVal) {
            if (!newVal) {
                this.$refs.insertForm.resetFields()
                this.templateList = []
            } else {
                caseTemplateList({ size: -1 }).then(({ res }) => {
                    this.templateList = res.records
                })
            }
        }
    },
    data() {
        return {
            visible: false,
            rules: {
                templateId: [{ required: true, message: '请选择模板', trigger: 'change' }]
            },
            templateList: [],
            form: {
                templateId: '',
            }
        }
    },
    created() {},
    methods: {
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        },
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    this.$emit("updateTemplate", this.form.templateId)
                    this.updateVisible(false)
                }
            })
        },
    }
}
</script>
