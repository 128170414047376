<!--
 * @Author: your name
 * @Date: 2022-12-06 15:38:24
 * @LastEditTime: 2023-03-16 13:13:42
 * @LastEditors: wangly wly574523605@163.com
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\tree\index.vue
-->
<template>
	<el-main class="tree">
		<div class="tree-left">
			<el-button type="primary" plain @click="updateCase()">更新</el-button>
			<el-tree
				v-if="treeData.length"
				node-key="id"
				id="report-tree"
				ref="report-tree"
				class="report-tree"
				:data="treeData"
				:props="defaultProps"
				:highlight-current="true"
				@node-click="handleNodeClick"
			>
				<span class="custom-tree-node" slot-scope="{ node, data }">
					<span class="tree-left-title">
						<i v-if="data.type === '1'" class="el-icon-tickets"></i>
						<i v-else class="el-icon-folder"></i>
						{{ node.label }}
					</span>
				</span>
			</el-tree>
		</div>
		<div class="tree-right" ref="report">
			<template v-if="templateData">
				<el-row type="flex" justify="end">
					<div class="search-btn-group tree-right-btn">
						<el-button type="primary" @click="updateInputData">保存</el-button>
						<!-- <el-button type="primary" @click="updateContent()" plain>
                            更新
                        </el-button> -->
						<el-button type="primary" @click="changeTemplate()" plain>变更模板</el-button>
					</div>
				</el-row>
				<div style="display: none">
					<div id="printMe" ref="printMe" v-html="templateData"></div>
				</div>
				<tinymce
					:resize="false"
					:disabled="false"
					ref="tinymce"
					:height="$refs.report.clientHeight - 40"
					style="width: 890px"
					name="content"
					class="content"
					v-model="templateData"
					id="tinymce"
					:toolbar="'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | print | fullscreen'"
					:plugins="'table autolink link image lists charmap  media wordcount print fullscreen'"
				></tinymce>
			</template>
			<p class="notice" v-else>选中病例后，可现实病例内容</p>
		</div>
		<change-dialog ref="change" @updateTemplate="updateTemplate" />
	</el-main>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import changeDialog from '@/views/caseReport/tree/changeDialog'

import { casesTemplateInfo } from '@/api/caseReport'
import { print } from '@/utils/print' // 引入公共方法中的打印方法
import {
	getPatientTree,
	getPatientTemplateBasicData,
	updateInputData,
	updateCasesTemplate,
	clearPatientCases,
} from '@/api/caseReportTree'
export default {
	props: {
		basicInfoId: String,
	},
	components: {
		Tinymce,
		changeDialog,
	},
	data() {
		return {
			treeData: [],
			caseData: {},
			defaultProps: {
				children: 'children',
				label: 'name',
			},
			templateData: '',
			id: '',
		}
	},
	watch: {
		basicInfoId(newVal) {
			if (newVal) {
				this.getPatientTree()
			}
		},
	},
	created() {
		if (this.basicInfoId) {
			this.getPatientTree()
		}
	},
	methods: {
		getPatientTree() {
			getPatientTree({ basicInfoId: this.basicInfoId }).then(({ res }) => {
				this.initTreeData(res)
			})
		},
		handleNodeClick(data, Node, VueComponent) {
			console.log('tree click', data)
			const { id, inputData, templateData } = data
			this.id = id
			this.caseData = data
			if (inputData) {
				this.templateData = inputData
				return
			}
			this.updateContent()
		},
		updateContent() {
			const { templateData } = this.caseData
			const id = this.id
			getPatientTemplateBasicData({ id }).then(({ res }) => {
				this.templateData = this.getTemplateData(templateData, res)
			})
		},
		updateCase() {
			clearPatientCases({ id: this.basicInfoId }).then(({ res }) => {
				this.initTreeData(res)
			})
		},
        initTreeData(res){
            this.treeData = [...res]
				const caseList = res.filter((item) => item.type === '1')
				if (caseList.length) {
					this.id = caseList[0].id
				}
				this.id &&
					this.$nextTick(() => {
						this.$refs['report-tree'].setCurrentKey(this.id)
                        if (caseList.length) {
                            setTimeout(() => {
                                this.handleNodeClick(caseList[0])
                            }, 200)
                        }
					})
        },
		changeTemplate() {
			this.$refs.change.updateVisible(true)
		},
		getTemplateData(orgTemplate, basicData) {
			const infoArray = basicData.filter((item) => item.type === '0').map((item) => item.data)
			const tablesArray = basicData.filter((item) => item.type === '1')

			let basicInfo = {}
			let newTemplate = orgTemplate
			newTemplate = this.replaceKeys(infoArray, newTemplate)
			newTemplate = this.replaceTables(tablesArray, newTemplate)
			return newTemplate
		},
		updateInputData() {
			const inputData = tinyMCE.editors[0].getContent()
			console.log('------inputData', this.id, inputData)
			updateInputData({ id: this.id, inputData }).then(({ res }) => {
				console.log('------updateInputData success', res)
				this.$message({ ...this.commonConfig.$handleSuccess })
				this.getPatientTree()
			})
		},
		updateByFull(newTemplate) {
			this.templateData = newTemplate
		},
		toPrint() {
			this.$nextTick(() => {
				print(
					'printMe',
					`.nameAndNum{
                        display: flex;
                        justify-content: space-around; 
                    }
                    table{
                        border-collapse: collapse;
                        border-spacing: 0;
                    }
                    table tr td,th { border:thin #dedede solid;text-align:center;font-size:12px;color:#666;line-height:28px }
                    `
				)
			})
		},
		getCasesTemplate(templateId) {
			return new Promise((resolve) => {
				updateCasesTemplate({ id: this.id, templateId }).then(({ res }) => {
					console.log('------new template', res)
					resolve(res)
				})
			})
		},
		getTemplateInfo(id) {
			return new Promise((resolve) => {
				casesTemplateInfo({ id }).then(({ res }) => {
					console.log('------new template', res)
					resolve(res.templateData)
				})
			})
		},
		async updateTemplate(templateId) {
			await this.getCasesTemplate(templateId)
			const templateData = await this.getTemplateInfo(templateId)
			getPatientTemplateBasicData({ id: this.id }).then(({ res }) => {
				console.log('BasicData', templateData, res)
				this.templateData = this.getTemplateData(templateData, res)
			})
		},
		replaceKeys(infoArray, orgTemplate) {
			let basicInfo = {}
			let newTemplate = orgTemplate
			infoArray.forEach((info) => {
				basicInfo = { ...basicInfo, ...info }
			})
			Object.keys(basicInfo).forEach((patKey) => {
				let reg = new RegExp(`【${patKey}】`, 'g')
				newTemplate = newTemplate.replace(reg, basicInfo[patKey] || '')
			})
			return newTemplate
		},
		replaceTables(tableArray, orgTemplate) {
			let newTemplate = orgTemplate
			tableArray.forEach((item) => {
				const tdSt = `style="border: thin #666666 solid;"`
				let reg = new RegExp('<table id="' + item.uuid + '"[^>]*>(.|\n)*?</table>', 'g')
				let $tbody = item.data
					.map(($tr) => {
						const tds = $tr.map((td) => `<td ${tdSt}>${td || ''}</td>`).join('')
						return `<tr>${tds}</tr>`
					})
					.join('')
				const $table = `<table style="width: 100%;" id="${item.uuid}">${$tbody}</table>`
				console.log('------table info', item.uuid, item.data, $table)
				newTemplate = newTemplate.replace(reg, $table)
			})
			return newTemplate
		},
	},
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.report-tree {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.tree {
	width: 100%;
	height: 100%;
	display: flex;
	&-left {
		width: 300px;
		height: calc(100% - 60px);
		&-title {
			color: $text;
			font-size: 13px;
			i {
				color: $arrow;
			}
		}
	}
	&-right {
		width: calc(100% - 300px);
		height: 100%;
		&-btn {
			margin-bottom: 15px;
		}
	}
}
</style>
