
<!--
* @Description: 新增住院患者
* @Reference:
-->
<template>
  <el-dialog
    width="78%"
    :title="id === '' || id === undefined ? '新增患者' : '编辑患者档案'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      style="height: calc(100vh - 200px);"
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6" >
          <el-form-item label="身份证" prop="idCard">
            <el-input  :disabled="updateFlag"
              v-model="form.idCard"
              placeholder="请输入..."
              clearable
              maxlength="20"
              @change="getDetailByIdCare()"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="姓名" prop="name">
            <el-input :disabled="updateFlag"
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
              @blur="setNamePinyinHead"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="出生年月日" prop="birthday">
            <el-date-picker :disabled="updateFlag"
              v-model="form.birthday"
              align="right"
              type="date"
              clear-icon="el-input__icon"
              placeholder="选择日期"
              :editable="false"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="手机号" prop="phone">
            <el-input :disabled="updateFlag"
              v-model="form.phone"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" >
          <el-form-item prop="sex">
            <el-radio :disabled="updateFlag" v-model="form.sex" label="0">男</el-radio>
            <el-radio  :disabled="updateFlag" v-model="form.sex" label="1">女</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="住址" prop="address">
            <el-input :disabled="updateFlag"
              v-model="form.address"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider />
      <div class="mdrcc-info-title">家属信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="家属姓名" prop="faName">
            <el-input :disabled="updateFlag"
              v-model="form.faName"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="与患者关系" prop="faRelation">
            <el-input :disabled="updateFlag"
              v-model="form.faRelation"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="家属手机号" prop="faPhone">
            <el-input :disabled="updateFlag"
              v-model="form.faPhone"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider />

      <div v-if="show && this.form.status==='1'" class="mdrcc-info-title">在院信息</div>
      <div v-if="show && this.form.status==='2'" class="mdrcc-info-title">就诊信息</div>
      <el-row v-if="show" :gutter="10">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="在院状态" prop="status" v-if="this.form.status==='1' && show && showList">
            <el-select :disabled="true"
              v-model="form.status"
              :filterable="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="住院号" prop="recordNum" v-if="this.form.status==='1' && !showList">
            <el-input
              v-model="form.recordNum"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
          <el-form-item label="就诊号" prop="serialNum" v-if="!showList">
            <el-input :disabled="updateFlag"
              v-model="form.serialNum"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="所属科室" prop="deptId">
            <el-select
              v-model="form.deptId"
              clearable
              placeholder="请选择"
              :filterable="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in dept"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='2'">
          <el-form-item label="主治医生" prop="impDocId">
            <el-select
              v-model="form.impDocId"
              clearable
              placeholder="请选择"
              :filterable="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in impDoc"
                :key="option.id"
                :label="option.trueName"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1'">
          <el-form-item label="床号" prop="bedNum">
            <el-input
              v-model="form.bedNum"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show" :gutter="10">
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='2'">
          <el-form-item label="押金情况" prop="deposit">
            <el-input
              v-model="form.deposit"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1'">
          <el-form-item label="主治医生" prop="impDocId">
            <el-select
              v-model="form.impDocId"
              clearable
              placeholder="请选择"
              :filterable="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in impDoc"
                :key="option.id"
                :label="option.trueName"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1'">
          <el-form-item label="管床医生" prop="bedDocId">
            <el-select
              v-model="form.bedDocId"
              clearable
              placeholder="请选择"
              :filterable="true"
              style="width: 100%"
            >
              <el-option
                v-for="option in impDoc"
                :key="option.id"
                :label="option.trueName"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1'">
          <el-form-item label="押金情况" prop="deposit">
            <el-input
              v-model="form.deposit"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show" :gutter="10" >
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1'&& showList">
          <el-form-item label="住院号" prop="recordNum">
            <el-input
              v-model="form.recordNum"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="this.form.status==='1' && showList">
          <el-form-item label="就诊号" prop="serialNum">
            <el-input :disabled="updateFlag"
              v-model="form.serialNum"
              placeholder="请输入"
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="疾病诊断" prop="diseaseDiagnosis">
            <el-input
              v-model="form.diseaseDiagnosis"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="阶段目标" prop="goals">
            <el-input
              v-model="form.goals"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="主诉" prop="chiefComplaint">
            <el-input
              v-model="form.chiefComplaint"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="现病史" prop="medicalHistory">
            <el-input
              v-model="form.medicalHistory"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="既往病史" prop="pastMedicalHistory">
            <el-input
              v-model="form.pastMedicalHistory"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="show && showList" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              rows="3"
              type="textarea"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  getDept,
  insertData,
  editData,
  getByIdCare
} from '@/api/patient'
import { CH2CYTPALL } from '@/utils/common'
import { getUserList } from '@/api/user'
export default {
  components: {
  },
  props: {
    id: {
      type: String
    },
    timeStamp:{
      type: String
    }
  },
  data() {
    return {
      total: 0, // 总条数
      goals: '', // 阶段目标
      diseaseDiagnosis: '', // 疾病诊断
      rules: {
        name: [{ required: true, message: '请输入患者姓名', trigger: 'blur' }],
        birthday: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        phone: [{ required: true, message: '请输入电话号', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择患者性别' }],
        status: [{ required: true, message: '请选择患者在院状态' }],
        deptId: [{ required: true, message: '请选择患者所属科室' }],
        serialNum:[{ required: true, message: '请输入就诊号' }],
        recordNum:[{ required: true, message: '请输入住院号' }],
        remark: [ {max: 200, message: "请输入 0 - 200 个字符", trigger: "blur" }]
      },
      dept: [], // 所属科室
      impDoc: [], // 医生姓名
      options: [
        {
          value: '1',
          label: '在院'
        },
        {
          value: '2',
          label: '门诊'
        }, 
      ], // 患者入院状态 
      form: { 
        sex: '1', 
        status:'1' 
      }, 
      visible: false, 
      show: true, // 是否显示在院信息 
      showList:true,//是否显示疾病诊断等列表
      updateFlag: false // 是否可修改样式
    }
  },
  created() {
    this.getDeptList()
    this.getImpDoc()
  },
  mounted() {

  },
  methods: {
    getTableData() {
      this.$emit('getTableData')
    },
    /**
     * @description: 获取所属科室
     * @param {*}deptType 部门类型 4=>科室
     * @return {*}
     */
    getDeptList() {
      getDept({deptType:'4'}).then(({ res, timeStamp }) => {
        this.dept = res
      })
    },
    /**
     * @description: 获取医生
     * @param {*}position 岗位类型 ：3医生 status：启用状态 是否分页标识pageFlag
     * @return {*}
     */
    getImpDoc() {
      const parm = {  position: '3',status:'1',pageFlag:'1' }
      getUserList(parm).then(({ res, timeStamp }) => {
       this.impDoc = res.records
      })
    },
    /**
     * 患者姓名首字母
     * 根据患者姓名自动获取患者姓名的拼音首字母
     */
    setNamePinyinHead()  {
      this.form.namePinyinHead = CH2CYTPALL(this.form.name);
    },
    /**
     * @description: 根据身份证号查询患者住院信息
     * @param {*} idCard 身份证号
     * @return {*}
     */
    getDetailByIdCare() {
      const parm = { idCard: this.form.idCard }
      if (this.form.idCard !== '') {
        getByIdCare(parm).then(({ res, timeStamp }) => {
          if (res != null && res !== undefined) {
              this.form = {
              idCard: res.idCard,
              name: res.name,
              sex: res.sex,
              birthday: res.birthday,
              phone: res.phone,
              address: res.address,
              faName: res.faName,
              faRelation: res.faRelation,
              faPhone: res.faPhone,
              serialNum: res.serialNum,
              archivesInfoId: res.id,
              status:'1',
              namePinyinHead:res.namePinyinHead
            }
            this.updateFlag = true
          }
        })
      }
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form
          const time = new Date(parm.birthday).getTime()
          parm.birthday = time
          const list = []

          if (parm.goals !== '' && parm.goals !== undefined) {
            const goals = {
              status: '1',
              content: parm.goals
            }
            list.push(goals)
          }
          if (
            parm.diseaseDiagnosis !== '' &&
            parm.diseaseDiagnosis !== undefined
          ) {
            const diseaseDiagnosis = {
              status: '0',
              content: parm.diseaseDiagnosis
            }
            list.push(diseaseDiagnosis)
          }
          parm.list = list
          if (parm.id === '' || parm.id === undefined) {
            insertData(parm).then((res) => {
              this.submitSuccess(res)
            })
          } else {
            editData(parm, this.timeStamp).then((res) => {
              console.log("parm",parm);
              this.submitSuccess(res)
              this.$emit("closeDialog");
            })
          }
        }
      })
    },
    submitSuccess(res) {
      this.$message({...this.commonConfig.$handleSuccess})
      this.updateVisible(false)
      this.updateFlag = false
      this.$emit('submit')
    },
    updateVisible(val) {
      this.visible = val
      this.updateFlag = false
      this.form = {
         sex: '1',
        status:'1'
      }
      if (val === false) {
          this.$refs.insertForm.resetFields()
      }
    },
    /**
     * @description:获取详情信息
     * @param {*} visible
     * @param {*} details
     * @param {*} isShow 是否显示在院信息
     * @param {*} showList 是否显示疾病诊断等列表
     * @return {*}
     */
    getMessageByDetail(visible, details, isShow,showList) {
      this.visible = visible
      if (details != null && details !== '') {
        this.form = details
      }
      this.show = isShow
      this.showList = showList
    }
  }
}
</script>
<style scoped>
</style>

