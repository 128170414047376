/*
 * @Author: your name
 * @Date: 2021-10-27 15:19:11
 * @LastEditTime: 2021-11-02 10:45:03
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\api\inHospital.js
 */
/**
 * 页面: 在院患者管理
 * pageName: inHospital
 **/
import request from '@/utils/request'

/**
 * @description: 获取在院患者管理列表数据
 */
export function getList(data) {
    return request({
        url: '/patientArchivesInfo/patientList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
