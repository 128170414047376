<!--
 * @Author: haojw
 * @Date: 2021-11-24 09:27:09
 * @LastEditTime: 2021-12-29 13:31:16
 * @LastEditors: yzw
 * @Description: 量表分类详情页
 * @FilePath: \recovery_management\src\views\scale\scaleType\detailDialog.vue
-->
<template>
  <el-dialog
    width="50%"
    title="量表分类详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" :column="2">
      <el-descriptions-item label="分类名称">{{
        detail.name || commonConfig.$nullData
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{
        detail.status === "0" ? "启用" : "禁用"
      }}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from "@/api/scaleType";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      detail: [], // 详情数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && this.id) this.getDetail();
    },
  },
  created() {},
  mounted() {},
  methods: {
    getDetail() {
      getDetail({ id: this.id }).then(({ res }) => {
        this.detail = res;
      });
    },
    updateVisible(flag) {
      this.visible = flag;
    },
  },
};
</script>
<style scoped lang="scss"></style>
