<!--
 * @Author: your name
 * @Date: 2022-11-17 18:40:53
 * @LastEditTime: 2022-12-09 16:58:08
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\folder\list.vue
-->
<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="casesCollectList"
            >
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">查看</el-button>
                    <el-button type="text" @click="toEdit(scope)">修改</el-button>
                    <el-button type="text" @click="toConfig(scope)">配置病例</el-button>
                    <el-button type="text" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
        </el-main>
        <addDialog ref="insert" :id="checkId" @submit="search" />
        <configDialog ref="config" :id="checkId" @submit="search" />
        <detailDialog ref="detail" :id="checkId" />
    </el-container>
</template>
<script>
import { casesCollectList, deleteCasesCollect } from '@/api/casesCollect'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/caseReport/folder/addDialog'
import detailDialog from '@/views/caseReport/folder/detailDialog'
import configDialog from '@/views/caseReport/folder/configDialog'
export default {
    components: {
        searchForm,
        generalTable,
        addDialog,
        detailDialog,
        configDialog
    },
    data() {
        return {
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '病历夹名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'status',
                    filterable: true,
                    label: '状态',
                    options: [
                        {
                            value: '',
                            label: '全部'
                        },
                        {
                            value: '0',
                            label: '启用'
                        },
                        {
                            value: '1',
                            label: '禁用'
                        }
                    ],
                    defaultVal: []
                }
            ],
            tableData: [],
            tableConfig: {
                id: 'casesCollectList',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '病历夹名称', // 必填
                        sortable: false,
                        prop: 'name' // 必填
                    },
                    {
                        label: '说明', // 必填
                        sortable: false,
                        prop: 'content', // 必填
                        formatter: (row, column) => {
                            let content = ''
                            if (!row.content) {
                                content = '--'
                            } else if (row.content.length <= 10) {
                                content = row.content
                            } else {
                                content = row.content.substring(0, 10) + '...'
                            }
                            return content
                        }
                    },
                    {
                        label: '创建时间', // 必填
                        sortable: false,
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            let createDate = row.createDate
                                ? this.moment(row.createDate * 1).format('YYYY-MM-DD HH:mm:ss')
                                : '--'
                            return createDate
                        }
                    },
                    {
                        label: '状态', // 必填
                        sortable: false,
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            const statusMap = { '0': '启用', '1': '禁用' }
                            return statusMap[row.status]
                        }
                    },
                    {
                        label: '创建人', // 必填
                        sortable: false,
                        prop: 'createName' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '240' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '' //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.casesCollectList()
    },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        casesCollectList() {
            this.$nextTick(() => {
                const param = this.getParam()
                casesCollectList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = res.total
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toConfig(scope) {
            this.checkId = scope.data.row.id
            this.$refs.config.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                deleteCasesCollect({ id: scope.data.row.id }).then(res => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.$refs.mainSearch.resetForm()
                    this.search()
                })
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>
