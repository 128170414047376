<template>

  <el-dialog
    width="70%"
    :title="'模板选择'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="选择模板" prop="modelId">
            <el-select
              v-model="form.modelId"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in models"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">导 出</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    models: []
  },
  data() {
    return {
      visible: false,
      form: {
        modelId: ''
      },
      rules: {
        modelId: { required: true, message: '选择模板', trigger: 'change' }
      }
    }
  },
  methods: {
    updateVisible(val) {
      this.visible = val
      if (val === false) {
        this.$refs.insertForm.resetFields()
      }
    },
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.form.modelId)
          this.updateVisible(false)
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
