<!--
 * @Author: your name
 * @Date: 2021-11-22 15:09:41
 * @LastEditTime: 2022-07-25 10:35:46
 * @LastEditors: ZF-WLY
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\advice\myPatientList.vue
-->

<template>
    <el-container :style="{marginTop: 0}">
        <el-header height="auto">
            <searchForm ref="mainSearch" :backStatus="true" :searchoptions="searchOptions" @search="search" />
        </el-header>
        <el-main class="basic-main">
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                :newHeight="tableHeight - 60"
            >
                <template v-if="checkStatus" slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">详情</el-button>
                    <el-button v-if="addAdvice" type="text" @click="toInsert(scope, 1)">新增医嘱</el-button>
                    <el-button v-if="addSet" type="text" @click="toInsert(scope, 2)">新增组套</el-button>
                </template>
            </generalTable>
            <addDialog :basicInfoId="insertId" ref="insert" @submit="submitSuccrss" :insertType="insertType" />
        </el-main>
    </el-container>
</template>
<script>
import { getPatientList } from '@/api/advice'
import searchForm from "@/components/SearchForm";
import generalTable from '@/components/Table'
import addDialog from '@/views/advice/addDialog'
import { getDept } from "@/api/patient"; //获取科室
import { getDict } from "@/api/common";

export default {
    name: 'AdvicePatient',
    components: {
        generalTable,
        addDialog,
        searchForm
    },
    props: {
        selectFlag: {
            type: String,
            required: true
        },
        tableHeight: {
            type: Number | String,
            required: true,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            activeName: 'first',
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: "select",
                    key: "status",
                    filterable: true,
                    label: "在院状态",
                    options: [],
                    defaultVal: "",
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'deptId',
                    filterable: true,
                    label: '所属科室',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'serialNum',
                    label: '就诊号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'recordNum',
                    label: '住院号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'bedNum',
                    label: '床号',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'treatmentDate',
                    label: '入院日期',
                    defaultVal: []
                }
            ],
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'serialNum' // 必填
                    },
                    {
                        label: '住院号', // 必填
                        prop: 'recordNum' // 必填
                    },
                    {
                        label: '治疗医嘱数', // 必填
                        prop: 'zyCount' // 必填
                    },
                    {
                        label: '主治医生', // 必填
                        prop: 'impDocName' // 必填
                    },
                    {
                        label: '管床医生', // 必填
                        prop: 'bedDocName' // 必填
                    },
                    {
                        label: '床号', // 必填
                        prop: 'bedNum' // 必填
                    },
                    {
                        label: '入院/就诊日期', // 必填
                        prop: 'heDte', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.heDte).format('YYYY-MM-DD')
                        }
                    },
                    {
                        label: '在院状态', // 必填
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            return ['出院', '入院', '门诊', '结束治疗'][row.status]
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '180' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkStatus: true,
            insertId: '', //新增医嘱/组套的患者id
            insertType: 1,
            addAdvice: true,
            addSet: true
        }
    },
    created() {
        this.checkStatus = this.hasPermission('advice-detail-patientList')
        this.addAdvice = this.hasPermission('advice-list-add-advice')
        this.addSet = this.hasPermission('advice-list-add-set')
        this.getDeptList();
        this.getPatientStatus();
        this.getList()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept({ deptType: "4" }).then(({ res, timeStamp }) => {
                this.deptOptions = res.map((item) => {
                    const ele = {
                        value: item.id,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions = this.searchOptions.map(item => {
                    if (item.key === "deptId") {
                        item.options = this.deptOptions
                    }
                    return item
                })
            });
        },
        /**
         * @description: 获取在院状态
         * @param {*}
         * @return {*}
         */
        getPatientStatus() {
            getDict({ type: "patient_inHos_status" }).then(({ res }) => {
                this.patientOptions = res.patient_inHos_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions = this.searchOptions.map(item => {
                    if (item.key === "status") {
                        item.options = this.patientOptions
                    }
                    return item
                })
                // this.searchOptions[1].options = this.patientOptions;
            });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getPatientList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = parseInt(res.total)
                    this.timeStamp = timeStamp
                })
            })
        },
        submitSuccrss() {
            this.getList()
        },
        /**
         * @description:新增医嘱/组套
         * @param {*}
         * @return {*}
         */
        toInsert(scope, type) {
            const id = scope.data.row.id
            this.insertId = id
            this.insertType = type
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        /**
         * @description:详情
         * @param {*}
         * @return {*}
         */
        toDetail(scope) {
            const id = scope.data.row.id
            this.$router.push({ path: '/patient/advice', query: { id: id } })
        },
        getParam() {
            let page = this.$refs.mainTable.getPage()
            let paramObj = this.$refs.mainSearch.packageData();
            let param = Object.assign({}, page, { selectFlag: this.selectFlag }, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
