
<!--
* @Description: 文档库页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
            <el-button type="text" v-if="documentLibDetail" @click="toDetail(scope)">详情</el-button>
            </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import moment from 'moment'
import { getList, deleteData } from '@/api/documentLib'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/knowledgeBase/documentLib/addDialog'
import detailDialog from '@/views/knowledgeBase/documentLib/detailDialog'

export default {
    name: 'DocumentLib',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [], // 表格数据
            searchOptions: [ // 搜索框信息
                {
                    type: 'input',
                    placeholder: '请输入名称...',
                    key: 'name',
                    label: '名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入创建人...',
                    key: 'userName',
                    label: '创建人',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: "请选择",
                    multiple: false,  // 非必填 多选
                    key: 'status',
                    label: '状态',
                    // 0公开、1私有
                    options: [
                        {
                            value: '',
                            label: '全部'
                        },
                        {
                            value: '0',
                            label: '公开'
                        },
                        {
                            value: '1',
                            label: '私有'
                        }
                    ],
                    defaultVal: "",
                }
            ],
            tableConfig: {// 表格配置
                id: 'documentLib',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name' // 必填
                    },
                    {
                        label: '创建人',
                        prop: "userName"
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return moment(row.createDate).format(
                                'YYYY-MM-DD HH:mm:ss'
                            )
                        }
                    },
                    {
                        label: '分类', // 必填
                        prop: 'typeName' // 必填
                    },
                    {
                        label: '类型', // 必填
                        prop: 'type', // 必填
                        formatter: (row, column) => {
                            return row.type === "1" ? "文档" : "未知"
                        }
                    },
                    {
                        label: '状态', // 必填
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            return row.status === "1" ? "私有" : "公开"
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            documentLibDetail: this.hasPermission('documentLibDetail'), // 文档库详情权限
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    /** 页面创建
     * @description: 获取文档库列表
     * @param {*}
     * @return {*}
     */    
    created() {
        this.getList()
    },
    mounted: function() {},
    methods: {
        /** 搜索框结果
         * @description: 获取搜索参数后,按第一页搜索
         * @param {*} data
         * @return {*}
         */        
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /** 获取列表信息
         * @description: 按getParam获取参数,获取列表、时间戳及总条数参数
         * @param {*}
         * @return {*}
         */        
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = Number(res.total)
                })
            })
        },
        /** 文档库详情
         * @description: 跳转至文档库详情页,将id放入到页面参数中
         * @param {Object} scope 插槽信息,scope.data.row为当前行数据
         * @return {*}
         */        
        toDetail(scope) {
            this.$router.push({
                path: '/knowledgeBase/documentLib/detail',
                query: {
                    id: scope.data.row.id,
                }
            })
        },
        /** 获取参数
         * @description: 拼合搜索框及表格翻页参数
         * @param {*}
         * @return {Object} 返回拼接的参数结果
         */        
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        