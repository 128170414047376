<!--
 * @Author: your name
 * @Date: 2022-11-23 15:47:03
 * @LastEditTime: 2022-12-08 15:00:46
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\other\Patient360\components\treatmentPlanDialog.vue
-->
<template>
    <el-dialog
        width="1100px"
        :title="'计划详情'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
        id="plan-detail"
    >
        <el-descriptions class="margin-top" title="计划信息" :column="3">
            <el-descriptions-item label="计划名称">{{ form.planName || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="计划开始日期">
                {{ form.startTime ? moment(form.startTime).format("YYYY-MM-DD") : commonConfig.$nullData }}
            </el-descriptions-item>
            <el-descriptions-item label="计划结束日期">
                {{ form.endTime ? moment(form.endTime).format("YYYY-MM-DD") : commonConfig.$nullData }}
            </el-descriptions-item>
            <el-descriptions-item label="医嘱" :span="3">
                <generalTable ref="mainTable-datail" id="mainTable-datail" :table-data="tableData" :config="tableConfig" :newHeight="tableData.length * 30 + 36.5">
                </generalTable>
            </el-descriptions-item>
            <el-descriptions-item label="计划内容" :span="3">
                <template v-if="form.planContent">
                    <div v-for="(item, index) in form.planContent.split('\n')" :key="'planContent' + index">{{item}}</div>
                </template>
                <template v-else>{{commonConfig.$nullData}}</template>
            </el-descriptions-item>
            <el-descriptions-item label="注意事项" :span="3">
                <template v-if="form.precautions">
                    <div v-for="(item, index) in form.precautions.split('\n')" :key="'precautions' + index">{{item}}</div>
                </template>
                <template v-else>{{commonConfig.$nullData}}</template>
            </el-descriptions-item>
            <el-descriptions-item label="阶段目标" :span="3">
                <template v-if="form.stageGoal">
                    <div v-for="(item, index) in form.stageGoal.split('\n')" :key="'stageGoal' + index">{{item}}</div>
                </template>
                <template v-else>{{commonConfig.$nullData}}</template>
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="3">
                <template v-if="form.remark">
                    <div v-for="(item, index) in form.planContent.split('\n')" :key="'remark' + index">{{item}}</div>
                </template>
                <template v-else>{{commonConfig.$nullData}}</template>
            </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()">关 闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import { getDetail } from '@/api/treatmentPlan'
export default {
    props: {
        id: String,
        basicInfoId: String
    },
    components: {
        generalTable,
    },
    data() {
        return {
            timeStamp: '',
            tableHeight: 0,
            checkId: '',
            form: {
                planName: '',
                planContent: '',
                precautions: '',
                stageGoal: '',
                remark: ''
            },
            visible: false, // 可见性， 默认不可见
            content: '',
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        sortable: false,
                        prop: 'operTypeLabel' // 必填
                    },
                    {
                        label: '操作项目名称', // 必填
                        sortable: false,
                        prop: 'operName', // 必填
                        width: 180
                    },
                    {
                        label: '医嘱类型', // 必填
                        sortable: false,
                        prop: 'doctorOrderType', // 必填
                        formatter: (row, column) => {
                            const typeMap = { '1': '长期医嘱', '2': '限定医嘱' }
                            return typeMap[row.doctorOrderType]
                        }
                    },
                    {
                        label: '总次数', // 必填
                        sortable: false,
                        prop: 'createCount', // 必填
                        formatter: (row, column) => {
                            return row.doctorOrderType === '1' ? '长期' : row.createCount
                        }
                    },
                    {
                        label: '单次数量', // 必填
                        sortable: false,
                        prop: 'schCount' // 必填
                    },
                    {
                        label: '治疗频次', // 必填
                        sortable: false,
                        prop: 'frequencyCount', // 必填
                        formatter: (row, column) => {
                            return `${row.frequencyCount}次/${row.frequencyTime}${row.frequencyUnitLabel}`
                        }
                    },
                    {
                        label: '执行次数', // 必填
                        sortable: false,
                        prop: 'usedCount' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        sortable: false,
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate * 1).format('YYYY-MM-DD')
                        }
                    }
                ]
            }
        }
    },
    watch: {
        /** 监听模块可见性
         * @description:
         *      显示模块时，查看该模块是否传入了parentId，如果传入，同步到form.parentId中
         *      隐藏模块时，清空所有表单内容
         * @param {*} newVal 当前值
         * @param {*} oldVal 上一状态值
         * @return {*}
         */
        visible(newVal, oldVal) {
            if (newVal) {
                this.getDetail()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /** 菜单详情
         * @description: 将获取的菜单详情信息同步到form数据中
         * @param {*}
         * @return {*}
         */
        getDetail() {
            getDetail({ id: this.id, basicInfoId: this.basicInfoId }).then(({ res, timeStamp }) => {
                const { patientTreatmentPlanBasicInfo, ...others } = res
                const { adviceList, contentList } = patientTreatmentPlanBasicInfo
                this.form = {
                    ...others,
                    time: [
                        this.moment(res.startTime).format('YYYY-MM-DD'),
                        this.moment(res.endTime).format('YYYY-MM-DD')
                    ].join("  至  ")
                }
                this.timeStamp = timeStamp
                this.tableData = [...adviceList]
                this.content = [...contentList]
                    .map(item => {
                        return `${this.moment(item.dateTime).format('YYYY-MM-DD')} ${item.content}`
                    })
                    .join('\n')
                this.tableHeight = 36.5 + this.tableData.length * 30
            })
        },
        /** 更新弹窗显隐
         * @description: visible与flag同步
         * @param {Boolean} flag true时显示,false时隐藏
         * @return {*}
         */        
        updateVisible(flag) {
            this.visible = flag
        },
        /** 关闭按钮
         * @description: 隐藏当前弹窗,触发父级组件绑定的`@close`绑定的函数
         * @param {*}
         * @return {*}
         */        
        close() {
            this.updateVisible(false)
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.content-wrapper {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}
#plan-detail ::v-deep .el-descriptions-item__container {
    align-items: flex-start;
    .el-descriptions-item__label {
        justify-content: flex-end;
    }
}
#plan-detail ::v-deep td,
#plan-detail ::v-deep th {
    padding: 0;
}
</style>
