/*
 * @Author: your name
 * @Date: 2022-11-17 18:44:57
 * @LastEditTime: 2022-11-18 09:12:04
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\casesCollectInfo.js
 */

import request from '@/utils/request'

/**
 * @description: 病历夹列表
 * @param {Object} data
 */
export function casesCollectList(data) {
    return request({
        url: '/casesCollectInfo/list',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 病历夹详情
 * @param {Object} data
 */
export function casesCollectInfo(data) {
    return request({
        url: '/casesCollectInfo/info',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 新增病例夹信息
 * @param {Object} data
 */
export function insertCasesCollect(data) {
    return request({
        url: 'casesCollectInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 更新病例夹信息
 * @param {Object} data
 */
export function updateCasesCollect(data, timeStamp) {
    return request({
        url: 'casesCollectInfo/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除病例夹信息
 * @param {Object} data
 */
export function deleteCasesCollect(data, timeStamp) {
    return request({
        url: 'casesCollectInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
