/*
 * @Author: your name
 * @Date: 2022-01-12 10:43:52
 * @LastEditTime: 2022-02-15 10:13:09
 * @LastEditors: Please set LastEditors
 * @Description: 量表汇总接口
 * @FilePath: \recovery_management\src\api\summaryScale.js
 */
import request from '@/utils/request'

/**
 * @description: 获取公共量表库量表配置
 */
export function getSummaryRuleConfig(data) {
    return request({
        url: 'basicScaleInfo/getSummaryRuleConfig',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取评估量表库量表配置
 */
 export function getScaleInfoRuleConfig(data) {
    return request({
        url: 'scaleInfo/getSummaryRuleConfig',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 更新公共量表库量表配置
 */
export function updateSummaryRule(data, timeStamp) {
    return request({
        url: 'basicScaleInfo/updateSummaryRule',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 更新评估量表库量表配置
 */
 export function updateScaleInfoRule(data, timeStamp) {
    return request({
        url: 'scaleInfo/updateSummaryRule',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表汇总数据
 */
export function getScaleSummary(data) {
    return request({
        url: 'scaleSummary/getScaleSummary',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
