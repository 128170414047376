<!--
 * @Author: yzw
 * @Date: 2021-12-08 14:25:09
 * @LastEditTime: 2021-12-23 11:56:28
 * @LastEditors: yzw
 * @Description: 
-->

<!--
* @Description: 治疗安排页面
* @Reference:
-->

<template>
<el-container>
    <el-tabs type="border-card" style="width:100%" @tab-click="tabChange" value="unTreated">
        <el-tab-pane :lazy="true" label="今日待治疗" name="unTreated"><unTreated v-if="tabValue === 'unTreated'"/></el-tab-pane>
        <el-tab-pane :lazy="true" v-if="$route.query.entry !== 'home'" label="治疗记录" name="treated"><treated v-if="tabValue === 'treated'" /></el-tab-pane>
    </el-tabs>
</el-container>
</template>

<script>
import unTreated from '@/views/treatment/arrangeTreatment/unTreated.vue'
import treated from '@/views/treatment/arrangeTreatment/treated.vue'

export default {
    name: 'arrangeTreatment',
    components:{
        unTreated,
        treated
    },
    data() {
        return {
            tabValue: 'unTreated'
        }
    },
    created() {},
    mounted: function() {},
    methods: {
        tabChange(event){
            this.tabValue = event.name
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        