/*
 * @Author: your name
 * @Date: 2022-04-21 09:07:23
 * @LastEditTime: 2022-04-22 13:49:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\treatmentWithout.js
 */
/**
 * 页面: 无医嘱治疗
 * pageName: patientTreatment
 **/
 import request from "@/utils/request";

 /**
  * @description: 获取治疗安排-今日待治疗-列表数据
  */
 export function treatmentWithoutAdvice(data) {
   return request({
     url: "treatmentWithoutAdvice/registration",
     method: "POST",
     data,
     showLoading: true
   });
  }
  /**
  * @description: 无医嘱登记治疗
  */
  export function getUserBindOper(data){
    return request({
      url: "ruleOperation/getUserBindOper",
      method: "GET",
      params: data,
      showLoading: true
    });
  }