<!--
* @Description: webservice数据接口日志页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
    </el-main>
  </el-container>
</template>

<script>
import { getList } from "@/api/webserviceLog";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";

import detailDialog from "@/views/dataPlatForm/webserviceLog/detailDialog";
const statusOptions = [
  {
    value: "0",
    label: "成功",
  },
  {
    value: "1",
    label: "失败",
  },
];
const statusMap = statusOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label;
  return acc;
}, {});

export default {
  name: "WebserviceLog",
  components: {
    searchForm,
    generalTable,
    detailDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "接口名称",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "status",
          filterable: true,
          multiple: false,
          label: "状态",
          options: statusOptions,
          defaultVal: [],
        },
        {
          type: "datetimerange",
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
          key: "createDate",
          label: "时间",
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "webserviceLog",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "接口名称", // 必填
            prop: "name", // 必填
            // formatter: (row, column) => {
            //     return row.archivesInfoId.name
            // }
          },
          {
            label: "参数", // 必填
            prop: "param", // 必填
          },
          {
            label: "结果", // 必填
            prop: "result", // 必填
          },
          {
            label: "状态", // 必填
            prop: "status", // 必填
            formatter: (row, column) => {
              return statusMap[row.status];
            },
          },
          {
            label: "时间", // 必填
            prop: "createDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
    };
  },
  created() {
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.timeStamp = timeStamp;
          this.total = parseInt(res.total);
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.detail.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
        });
      });
    },
    onClickRow(row) {
      console.log("点击行:", row);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
