<!--
 * @Author: your name
 * @Date: 2022-01-26 11:10:32
 * @LastEditTime: 2022-01-26 11:36:52
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\system\record\detail.vue
-->
<template>
    <el-dialog
        :title="label"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :modal="false"
        width="60%"
    >
        <el-input v-model="content" type="textarea" disabled :rows="4" resize="none" />
		<span slot="footer" class="dialog-footer">
			<el-button @click="updateVisible(false)">关 闭</el-button>
		</span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        label: String,
        content: String
    },
    data(){
        return {
            dialogVisible: false
        }
    },
    methods: {
        updateVisible(flag){
            this.dialogVisible = flag
        }
    }
}
</script>