<!--
 * @Author: your name
 * @Date: 2022-11-16 13:35:34
 * @LastEditTime: 2022-12-09 14:07:54
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\template\labelsDialog.vue
-->
<template>
    <el-dialog
        width="60%"
        title="插入标签"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-form ref="labelform" :model="form" label-width="120px" class="formBox">
            <template v-for="item in source">
                <div :key="'title-' + item.id" class="mdrcc-info-title">
                    {{ item.name }}
                </div>
                <el-row :key="'label-' + item.id" :gutter="10" type="flex" justify="start">
                    <el-col :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item :prop="item.id" label="可选标签">
                            <el-checkbox-group v-model="form[item.id]">
                                <el-checkbox v-for="key in opts[item.id]" :label="key.id" :key="key.id">
                                    {{ key.label }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row
                    :key="'scope-' + item.id"
                    v-if="item.type === '1' && form[item.id].length"
                    :gutter="10"
                    type="flex"
                    justify="start"
                >
                    <el-col :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item :prop="item.id" label="显示内容">
                            <el-select
                                v-model="dataScope[item.id]"
                                placeholder="请选择"
                                :multiple="false"
                                style="width:100%"
                            >
                                <el-option
                                    v-for="option in scopeOpt"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="['1', '2'].includes(dataScope[item.id])" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item :prop="item.id" label="数目">
                            <el-input
                                v-model="dataCount[item.id]"
                                clearable
                                placeholder="请输入"
                                onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
        </el-form>
        <template slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getCasesProperty } from '@/api/caseReport'
export default {
    data() {
        return {
            visible: false,
            scopeOpt: [
                { value: '0', label: '全部' },
                { value: '1', label: '从前往后' },
                { value: '2', label: '从后往前' }
            ],
            source: [],
            sourceMap: {},
            opts: {},
            form: {},
            dataScope: {},
            dataCount: {}
        }
    },
    created() {
        this.getCasesProperty()
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.initForm(this.source)
            } else {
                this.$refs.labelform.resetFields()
            }
        }
    },
    methods: {
        getCasesProperty() {
            getCasesProperty().then(({ res }) => {
                console.log('------case property', res)
                this.source = [...res]
                this.initForm(res)
            })
        },
        initForm(res) {
            let form = {}
            let sourceMap = {}
            let opts = {}
            let dataScope = {}
            let dataCount = {}
            res.forEach(item => {
                form[item.id] = []
                opts[item.id] = item.list
                sourceMap[item.id] = item
                if (item.type === '1') {
                    dataScope[item.id] = ''
                    dataCount[item.id] = ''
                }
            })
            this.form = { ...form }
            this.sourceMap = { ...sourceMap }
            this.opts = { ...opts }
            this.dataScope = { ...dataScope }
            this.dataCount = { ...dataCount }
        },
        getExtData() {
            /**
             * 校验逻辑
             * 非表格 直接把选中项插入不用管有没有数据
             * 表格类型数据 如果选了展示关键字，必须选择内容；如果内容为1或2，必须有datacount
             * 1. 有关键字 & 有内容 & 内容为全部 --> 通过校验
             * 2. 有关键字 & 有内容 & 内容不为全部 & 没有datacount --> 提示datacount至少为1 (return)
             * 3. 有关键字 & 有内容 & 内容不为全部 & 有datacount --> 通过校验
             */
            let extData = []
            for (let id in this.form) {
                const list = this.form[id]
                const dataScope = this.dataScope[id]
                const dataCount = this.dataCount[id] ? this.dataCount[id].replace(/[^\d]/g,'') : ''
                const currentItem = this.sourceMap[id]
                const listMap = this.arrayToMap(currentItem.list, { label: 'label', key: 'id' })
                const uuidNode = require('node-uuid')
                const uuid = uuidNode.v1()
                // 非表格 有数据插入数据
                if (currentItem.type === '0' && list.length) {
                    extData.push({
                        id,
                        uuid,
                        list: list.map(item => listMap[item])
                    })
                    continue
                }
                // 表格
                // 有关键字 & 无内容 --> 提示要选择内容
                if (list.length && !dataScope) {
                    this.$message({
                        type: 'warning',
                        message: '请选择要展示的内容'
                    })
                    return false
                }
                // 1. 有关键字 & 有内容 & 内容为全部 --> 通过校验
                if (list.length && dataScope === '0') {
                    extData.push({ id, uuid, list: list.map(item => listMap[item]), dataScope })
                    continue
                }
                // 2. 有关键字 & 内容不为全部 & 没有datacount --> 提示datacount至少为1 (return)
                if (list.length && dataScope !== '0' && !dataCount) {
                    this.$message({
                        type: 'warning',
                        message: '至少展示一条数据！'
                    })
                    return false
                }
                // 3. 有关键字 & 内容不为全部 & 有datacount --> 通过校验
                if (list.length && dataScope !== '0' && dataCount) {
                    extData.push({ id, uuid, list: list.map(item => listMap[item]), dataScope, dataCount })
                    continue
                }
            }
            return extData
        },
        submit() {
            const allData = this.getExtData()
            const extData = allData.map(item => {
                const { id, uuid, list, dataScope, dataCount } = item
                if (dataCount) {
                    return {
                        id,
                        uuid,
                        dataScope,
                        dataCount,
                        list: list.map(data => data.id)
                    }
                }
                if (dataScope) {
                    return {
                        id,
                        uuid,
                        dataScope,
                        list: list.map(data => data.id)
                    }
                }
                return {
                    id,
                    uuid,
                    list: list.map(data => data.id)
                }
            })
            if (!allData) {
                return
            }
            this.$emit('updateLabel', allData, extData)
            this.updateVisible(false)
        },
        updateVisible(flag) {
            this.visible = flag
        },
        arrayToMap(list, config = { label: 'label', key: 'value' }) {
            let map = {}
            list.forEach(item => {
                map[item[config.key]] = item
            })
            return map
        }
    }
}
</script>
