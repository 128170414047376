
<template>
  <el-dialog
    width="60%"
    title="患者医嘱详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions
      class="margin-top"
      title="医嘱信息"
      :column="4"
      
    >
      <el-descriptions-item label="所属治疗">{{
        commonConfig.$checkNullData(detail.operType)
      }}</el-descriptions-item>
      <el-descriptions-item label="操作项名称">{{
        commonConfig.$checkNullData(detail.operName)
      }}</el-descriptions-item>
      <el-descriptions-item label="医嘱类型">{{
        detail.doctorOrderType === "1" ? "长期医嘱" : "限定医嘱"
      }}</el-descriptions-item>
      <el-descriptions-item label="开单科室">{{
        detail.deptName || commonConfig.$nullData
      }}</el-descriptions-item>
      <el-descriptions-item label="开单医生">{{
        commonConfig.$checkNullData(detail.billedDoctorName)
      }}</el-descriptions-item>
      <el-descriptions-item label="执行科室">{{
        detail.executeDeptName || commonConfig.$nullData
      }}</el-descriptions-item>
      <el-descriptions-item label="总次数">{{
        detail.doctorOrderType === "1" ? "长期" : detail.createCount
      }}</el-descriptions-item>
      <el-descriptions-item label="单次数量">{{
        commonConfig.$checkNullData(detail.schCount)
      }}</el-descriptions-item>
      <el-descriptions-item label="频次">{{
        detail.frequencyCount +
        "次/" +
        detail.frequencyTime +
        ["天", "周", "月", "年"][detail.frequencyUnit]
      }}</el-descriptions-item>
      <el-descriptions-item label="医嘱状态">{{
        advice_statusList[detail.status]
      }}</el-descriptions-item>
      <el-descriptions-item v-if="detail.doctorOrderType==2" label="废弃次数">{{
        commonConfig.$checkNullData(detail.abandonCount)
      }}</el-descriptions-item>
      <el-descriptions-item v-if="detail.doctorOrderType==2" label="剩余次数">{{
        commonConfig.$checkNullData(detail.liveCount)
      }}</el-descriptions-item>
      <el-descriptions-item v-if="detail.status==3" label="删除医生">{{
        commonConfig.$checkNullData(detail.delBy)
      }}</el-descriptions-item>
      <el-descriptions-item v-if="detail.status==3" label="删除时间">{{
        commonConfig.$checkNullData(detail.delDate,"YYYY-MM-DD HH:mm:ss")
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="mdrcc-info-title">嘱托</div>
    <div class="margin-top" title="嘱托" :column="1" >
      <div :key="item" v-for="item in orderList">{{ item || commonConfig.$nullData }}</div>
    </div>
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>

import { getDetail } from "@/api/advice";
import { getDict } from '@/api/common'
import moment from "moment";

export default {
  components: {
    moment
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      detail: [], // 详情数据
      orderList: [],
      advice_statusList:[],
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && this.id) this.getDetail();
      if(!newVal){
        this.orderList = []
      }
    },
  },
  created() {
    this.get_advice_status()
  },
  mounted() {},
  methods: {
    get_advice_status(){
      getDict({ type: 'advice_status' }).then(({res}) => {
        let advice_statusList = []
        res.advice_status.map((item) => {
          advice_statusList[item.value] = item.label
        })
        this.advice_statusList = advice_statusList
      });
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res }) => {
        this.detail = res;
        if (res.doctorOrder) {
          this.orderList = res.doctorOrder && res.doctorOrder.split("@@");
        }
      });
    },
    updateVisible(flag) {
      this.visible = flag;
    },
  },
};
</script>
<style scoped lang='scss'>
</style>
