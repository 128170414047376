<!--
 * @Author: your name
 * @Date: 2021-12-03 13:47:36
 * @LastEditTime: 2021-12-29 13:31:17
 * @LastEditors: yzw
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\scale\reportClassify\detailDialog.vue
-->

<template>
  <el-dialog
    width="60%"
    title="报告分类管理详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="基本信息" :column="2" >
      <el-descriptions-item label="分类名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="排序">{{detail.sort||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="所属类型">{{detail.typeLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="状态">{{detail.statusLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1" label="评定量表">{{detail.scale||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{detail.remark||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail, getScaleList } from '@/api/reportClassify'
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            total: 0 // 总条数
        }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal && this.id){
          this.getDetail(); 
          this.getScaleList();
        } 
      }
    },
    created() {},
    mounted() {
    },
    methods: {
      getDetail() {
        const param = {}
        //报告id
        if (this.id) {
          param.id = this.id
        }
        getDetail(param).then(({res}) => {
          this.detail = res
        })
      },
      updateVisible(flag) {
        this.visible = flag
      },
      getScaleList() {
          const param = {}
          //报告id
          if (this.id) {
            param.id = this.id
          }
          getScaleList(param).then(({ res }) => {
              this.detail.scale = res.map((item) => item.scaleInfoName).join()
              this.$forceUpdate()
          }); 
      },
    }
}
</script>
<style scoped lang='scss'>
</style>
