
<template>
    <el-container>
        <el-main>
            <div class="backGroundBox">
                <template>
                    <div class="topHeader">
                        <div class="titleBox">
                            <div class="title">{{ detail.subject }}</div>
                            <div>发起人：{{ detail.userName }}</div>
                            <div>
                                状态：
                                <el-tag
                                    v-if="detail.status == 0"
                                    type="warning"
                                    >{{ detail.statusLabel }}</el-tag
                                >
                                <el-tag
                                    v-else-if="detail.status == 1"
                                    type="success"
                                    >{{ detail.statusLabel }}</el-tag
                                >
                                <el-tag v-else-if="detail.status == 2">{{
                                    detail.statusLabel
                                }}</el-tag>
                                <el-tag
                                    v-else-if="detail.status == 3"
                                    type="danger"
                                    >{{ detail.statusLabel }}</el-tag
                                >
                                <el-tag v-else type="info">{{
                                    detail.statusLabel
                                }}</el-tag>
                            </div>
                        </div>
                        <div class="backBtn">
                            <el-button type="default" @click="back()"
                                >返回</el-button
                            >
                        </div>
                    </div>
                </template>
                <template>
                    <div class="peopleBox">
                        <el-input
                            :placeholder="detail.description ? detail.description: '暂无描述'"
                            :disabled="true"
                            type="textarea"
                            :rows="3"
                            maxlength="200"
                        >
                        </el-input>
                    </div>
                </template>
                <template>
                    <div class="timeBox">
                        <div>
                            计划开始时间：{{
                                moment(detail.usesTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )
                            }}
                        </div>
                        <div>
                            计划结束时间：{{
                                moment(detail.usedTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )
                            }}
                        </div>
                        <div v-if="detail.publishTime">
                            发布时间：{{
                                moment(detail.publishTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )
                            }}
                        </div>
                    </div>
                </template>
                <template>
                    <div class="timeBox">
                        <div>参与人员：{{ participants }}</div>
                        <div v-if="detail.status == 3">
                            实际参与人员：{{ Actualparticipants }}
                        </div>
                    </div>
                </template>
            </div>
            <el-row :gutter="20">
                <el-col :span="14" v-if="detail.status == 2 && edit">
                    <div class="backGroundBox" style="height: 310px">
                        <div class="suggest">
                            <div class="inputDeep">
                                <el-input
                                    v-model="textarea"
                                    type="textarea"
                                    clearable
                                    :rows="6"
                                    maxlength="200"
                                    placeholder="请填写康复建议"
                                >
                                </el-input>
                            </div>
                            <input
                                style="display: none"
                                type="file"
                                accept="*"
                                @change="changeModelFile($event)"
                                ref="ModelInput"
                            />

                            <div class="uploadFileBox" v-if="fileList">
                                <el-tag
                                    closable
                                    v-for="(item, index) in fileList"
                                    :key="item.name"
                                    @close="handleClose(index)"
                                >
                                    {{ item.name }}
                                </el-tag>
                            </div>
                            <div class="uploadFileBtn">
                                <el-button
                                    type="primary"
                                    @click="setModelInput()"
                                    >上传
                                </el-button>
                                <el-button type="primary" @click="submit()"
                                    >提交
                                </el-button>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="10">
                    <div class="backGroundBox">
                        <div>
                            <template>
                                <el-descriptions title="附件">
                                </el-descriptions>
                            </template>
                            <div>
                                <el-tabs
                                    type="border-card"
                                    style="width: 100%"
                                    v-model="tabValue"
                                >
                                    <el-tab-pane
                                        :lazy="true"
                                        name="1"
                                        label="发起人上传"
                                    >
                                        <template>
                                            <el-table
                                                :data="tableData"
                                                style="
                                                    width: 100%;
                                                    margin-top: 10px;
                                                "
                                                :show-header="false"
                                                :height="200"
                                            >
                                                <el-table-column
                                                    prop="createUser"
                                                    label="姓名"
                                                    width="100"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    label="类型"
                                                    width="50"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <div class="tabTag">
                                                            <el-tag
                                                                effect="dark"
                                                                >{{
                                                                    scope.row
                                                                        .suffixname
                                                                }}</el-tag
                                                            >
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    prop="name"
                                                    label="文件名"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    label="操作"
                                                    width="150"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <el-button
                                                            @click="
                                                                handleClick(
                                                                    scope.row
                                                                )
                                                            "
                                                            type="text"
                                                            size="small"
                                                            >查看
                                                        </el-button>
                                                        <el-button
                                                            type="text"
                                                            size="small"
                                                            @click="
                                                                fileUpload(
                                                                    scope.row
                                                                )
                                                            "
                                                        >
                                                            下载
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    label="日期"
                                                    width="120"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <div>
                                                            {{
                                                                moment(
                                                                    scope.row
                                                                        .createDate
                                                                ).format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                    </el-tab-pane>
                                    <el-tab-pane
                                        :lazy="true"
                                        name="2"
                                        label="协同人员上传"
                                    >
                                        <template>
                                            <el-table
                                                :data="tableSource"
                                                style="
                                                    width: 100%;
                                                    margin-top: 10px;
                                                "
                                                :show-header="false"
                                                :height="200"
                                            >
                                                <el-table-column
                                                    prop="createUser"
                                                    label="姓名"
                                                    width="100"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    label="类型"
                                                    width="50"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <div class="tabTag">
                                                            <el-tag
                                                                effect="dark"
                                                                >{{
                                                                    scope.row
                                                                        .suffixname
                                                                }}</el-tag
                                                            >
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    prop="name"
                                                    label="文件名"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    label="操作"
                                                    width="150"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <el-button
                                                            @click="
                                                                handleClick(
                                                                    scope.row
                                                                )
                                                            "
                                                            type="text"
                                                            size="small"
                                                            >查看
                                                        </el-button>
                                                        <el-button
                                                            type="text"
                                                            size="small"
                                                            @click="
                                                                fileUpload(
                                                                    scope.row
                                                                )
                                                            "
                                                        >
                                                            下载
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    label="日期"
                                                    width="120"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <div>
                                                            {{
                                                                moment(
                                                                    scope.row
                                                                        .createDate
                                                                ).format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <div class="backGroundBox" v-if="activities.length > 0">
                <template>
                    <el-descriptions title="医治交流动态"></el-descriptions>
                </template>
                <template>
                    <div class="block">
                        <el-timeline>
                            <el-timeline-item
                                v-for="(activity, index) in activities"
                                :key="index"
                            >
                                <div class="timeLineTitle">
                                    <span class="timeLineText">{{
                                        activity.username
                                    }}</span>
                                    <span class="timeLineTime">{{
                                        moment(activity.createDate).format(
                                            "YYYY-MM-DD HH:mm"
                                        )
                                    }}</span>
                                </div>
                                <div
                                    v-if="activity.files.length > 0"
                                    class="timeTag"
                                >
                                    <el-tag
                                        v-for="(name, index) in activity.files"
                                        :key="index"
                                        type="info"
                                        >{{ name }}</el-tag
                                    >
                                </div>
                                <p>{{ activity.content }}</p>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </template>
            </div>
            <previewFile
                :fileKey="fileKey"
                :type="filetype"
                ref="previewFile"
            />
        </el-main>
        <patient360 width="700px" height='500px' :id='$route.query.archivesInfoId' :basicInfoId="$route.query.basicInfoId"/>
    </el-container>
</template>
<script>
import { getInfoDetail, addSuggestion } from "@/api/communication";
import patient360 from "@/views/other/Patient360";
import { uploadFile, downloadFile } from "@/api/common";
import previewFile from "@/components/previewFile";

export default {
    props: {},
    components: {
        patient360,
        previewFile,
    },
    data() {
        return {
            visible: false,
            detail: {}, // 详情数据
            tableData: [], // 发起人上传
            tableSource: [], //协同人员上传
            fileList: [], //上传文件
            activities: [], // 医治交流动态时间线
            files: [], // 提交文件
            reverse: true,
            textarea: "",
            total: 0, // 总条数
            dialogVisible: false,
            tabValue: "1",
            participants: "", //参与人员
            Actualparticipants: "", //实际参与人员
            loading: true,
            fileKey: "", // 文件key
            filetype: "", // 文件类型
            archivesInfoId: "",  //患者id
            basicInfoId:"",//住院id
            id: "",           
            edit:false,
        };
    },

    created() {
        this.id = this.$route.query.id;
        this.archivesInfoId = this.$route.query.archivesInfoId;
        this.basicInfoId = this.$route.query.basicInfoId;
        if(this.$route.query.edit){
            this.edit = this.$route.query.edit
        }         
        this.getDetail();
    },
    mounted() {},
    computed: {},
    methods: {
        getDetail() {
            const parm = { id: this.id };
            getInfoDetail(parm).then(({ res }) => {
                this.detail = res;
                this.tableData = res.files.map((item) => {
                    let {
                        name,
                        suffix,
                        createUser,
                        createDate,
                        suffixname,
                        id,
                    } = item;
                    name = name + "." + suffix;
                    suffixname = this.processType(suffix);
                    return {
                        name,
                        suffix,
                        createUser,
                        createDate,
                        suffixname,
                        id,
                    };
                });
                this.tableSource = res.cooperativeFiles.map((item) => {
                    let {
                        name,
                        suffix,
                        suffixname,
                        createUser,
                        createDate,
                        id,
                        suffixType,
                    } = item;
                    name = name + "." + suffix;
                    suffixname = this.processType(suffix);
                    return {
                        name,
                        suffixname,
                        suffix,
                        createUser,
                        createDate,
                        id,
                    };
                });

                this.activities = res.cooperativeSuggestions.map((item) => {
                    let {
                        content,
                        cooperativeWorkId,
                        createDate,
                        userId,
                        username,
                        files,
                    } = item;
                    files = files.map((el) => {
                        let name = el.name + "." + el.suffix;
                        return name;
                    });
                    return {
                        content,
                        cooperativeWorkId,
                        createDate,
                        userId,
                        username,
                        files,
                    };
                });
                let userPlan = [];
                let user = [];
                res.cooperativeUsers.map((item) => {
                    let { trueName, status } = item;
                    userPlan.push(trueName);
                    if (status == 0) {
                        user.push(trueName);
                    }
                });
                this.Actualparticipants = user.join(",");
                this.participants = userPlan.join(",");
            });
        },

        /**
         * @description: 提交
         * @param {*}
         * @return {*}
         */
        submit() {
            if(!this.textarea){
                this.$message('康复建议不能为空')
                return;
            }
            const parm = {
                cooperativeWorkId: this.detail.id,
                content: this.textarea,
                userId: this.detail.userId,
                files: this.files,
            };
            addSuggestion(parm).then(({ res }) => {
                (this.textarea = ""),
                    (this.fileList = []),
                    (this.files = []),
                    this.getDetail();
            });
        },
        /**
         * @description: 处理类型数据
         * @param  {String} type
         * @return {String} typeName 返回类型名称
         */
        processType(type) {
            const { $docTypes, $imgTypes, $audioTypes, $videoTypes } =
                this.commonConfig;
            let typeName = "";
            if ($docTypes.includes(type)) {
                typeName = "P";
            } else if ($imgTypes.includes(type)) {
                typeName = "图";
            } else if ($audioTypes.includes(type)) {
                typeName = "音";
            } else if ($videoTypes.includes(type)) {
                typeName = "视";
            }
            return typeName;
        },
        /**
         * @description: 删除文件
         * @param {*} tag
         * @return {*}
         */
        handleClose(tag) {
            this.fileList.splice(tag, 1);
            this.files.splice(tag, 1);
        },
        /**
         * @description: 点击上传
         * @param {*}
         * @return {*}
         */
        setModelInput() {
            this.$refs.ModelInput.click();
        },
        /**
         * @description: 读取模板文件
         */
        changeModelFile(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            let formData = new FormData();
            formData.append("file", file, file.name);
            uploadFile(formData).then(({ res }) => {
                this.fileList.push(file);
                this.files.push(res);
            });
        },
        /**
         * @description:查看文件
         * @param {*}
         * @return {*}
         */
        handleClick(row) {
            this.filetype = row.suffix;
            this.fileKey = row.id;
            this.$refs.previewFile.updateVisible(true);
        },
        /**
         * @description: 文件下载
         * @param {*}
         * @return {*}
         */
        fileUpload(row) {
            const parm = { id: row.id };
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement("a");
                dom.style.display = "none";
                const blob = new Blob([res], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                dom.setAttribute("download", row.name);
                document.body.appendChild(dom);
                dom.click();
            });
        },

        /**
         * @description: 返回
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back();
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";
.el-main {
    background-color: $background;
}
.backGroundBox {
    background-color: $menuText;
    padding: 15px;
    margin: 0px 0px 10px;
}
.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px 10px;
    width: 50%;
    color: $titleColor;
}
.title {
    font-size: 20px;
    font-weight: bold;
}
.timeBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    color: $titleColor;
    &:last-child {
        margin-bottom: 0px;
    }
}
.timeBox div {
    width: 300px;
}
.peopleBox {
    color: $titleColor;
    margin: 10px;
}
.peopleBox >>> .el-textarea__inner {
    border: 0px;
    resize: none;
}

.inputDeep >>> .el-textarea__inner {
    border: 0;
    resize: none;
}

.suggest {
    height: 280px;
    position: relative;
    border: 1px solid $tableColor;
}
.uploadFileBox {
    height: 120px;
    overflow-y: auto;
}
.uploadFileBox .el-tag {
    margin: 10px 10px 10px 0;
}
.uploadFileBtn {
    display: flex;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.timeLineTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.timeLineText {
    margin-right: 15px;
    font-weight: bold;
}
.timeLineTime {
    color: $text;
}

.timeTag .el-tag {
    margin: 10px 10px 0px 0px;
    font-size: 13px;
}
/* 去掉中间数据的分割线 */
.el-table__row > td {
    border: none;
}
/* 去掉上面的线 */
.el-table th.is-leaf {
    border: none;
}
/* 去掉最下面的那一条线 */
.el-table::before {
    height: 0px;
}
.tabTag .el-tag {
    width: 25px;
    height: 25px;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 25px;
}

.upload .el-upload-list__item {
    width: 70px;
    height: 70px;
}
.menu-item-img {
    width: 160px;
    height: 160px;
}
.topHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
