<!--
 * @Author: your name
 * @Date: 2022-04-20 16:10:11
 * @LastEditTime: 2022-07-19 08:43:08
 * @LastEditors: ligc 951906427@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\treatment\treatmentWithout\unTreated.vue
-->

<!--
* @Description: 今日待治疗页面
* @Reference:
-->

<template>
    <div>
        <el-header height="auto">
            <searchForm ref="mainSearch" style="padding-top:15px" :searchoptions="searchOptions" @search="getList" :backStatus="backStatus"/>
        </el-header>
        <el-main class="basic-main basic-none">
            <el-row class="table-btn-group">
                <el-button type="primary" v-if="allStatus" @click="toAllRegister()">一键登记</el-button>
                <el-button type="primary" v-if="fastStatus && $route.query.entry !== 'home'" @click="toFastRegister()">快速登记</el-button>
            </el-row>
            <div class="patient-scroll">
                <el-scrollbar :noresize="false">
                        <el-col v-for="item in tableData" :key="item.id" :sm="6" :md="6" :lg="6" :xl="3">
                            <el-card class="patient-card" shadow="hover" :body-style="{padding: 0}">
                                <div class="patient-title">
                                    
                                    <el-tooltip class="item" effect="light" :content="item.patientName || commonConfig.$nullData" placement="top-start">
                                        <div class="patient-name">{{item.patientName || commonConfig.$nullData}}</div>
                                    </el-tooltip>
                                    <div class="patient-title-info">
                                        <p>{{item.bedNum || commonConfig.$nullData}}床</p>
                                        <p>{{item.serialNum || commonConfig.$nullData}}</p>
                                    </div>
                                </div>
                                <div class="patient-content">
                                    <p>计划时间:{{item.scheduleTime}}-{{item.scheduleEndTime}}</p>
                                    <el-tooltip class="item" effect="light" :content="item.operName || item.armariumName || commonConfig.$nullData" placement="top-start">
                                        <p>治疗项目/设备:{{item.operName || item.armariumName || commonConfig.$nullData}}</p>
                                    </el-tooltip>
                                </div>
                                <div class="patient-btn" @click.stop="toRegister(item)">
                                    <el-button type="text" >治疗登记</el-button>
                                </div>
                            </el-card>
                        </el-col>
                </el-scrollbar>
            </div>
            <allRegisterDialog ref="allRegister" :timeStamp="timeStamp" @submit="getList"  />
            <fastRegisterDialog ref="fastRegister" :timeStamp="timeStamp" @submit="getList"  />
            <registerDialog ref="register" :info="detailInfo" :timeStamp="timeStamp" @submit="getList"  />
        </el-main>
    </div>
</template>

<script>
import { getUnTreatMentList, deleteData } from '@/api/arrangeTreatment'
import searchForm from '@/components/SearchForm'
import allRegisterDialog from '@/views/treatment/treatmentWithout/allRegisterDialog'
import fastRegisterDialog from '@/views/treatment/treatmentWithout/fastRegisterDialog'
import registerDialog from '@/views/treatment/treatmentWithout/registerDialog'

export default {
    name: 'arrangeTreatment',
    components: {
        searchForm,
        allRegisterDialog,
        fastRegisterDialog,
        registerDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'numSearch',
                    label: '住院号/就诊号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'operName',
                    label: '治疗项目',
                    defaultVal: ''
                },
            ],
            detailInfo: {},
            paramObj: null, // 筛选项
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            allStatus: true, //一键登记 按钮状态
            fastStatus: true, // 快速登记 按钮状态
        }
    },
    created() {
        this.allStatus = this.hasPermission('allRegister')
        this.fastStatus = this.hasPermission('fastRegister')
        this.getList()
    },
    mounted: function() {},
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getUnTreatMentList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                })
            })
        },
        /**
         * 一键登记
         * @description: 打开一键登记弹出层
         */       
        toAllRegister(){
            this.$refs.allRegister.updateVisible(true)
        },
        /**
         * 快速登记
         * @description: 打开快速登记弹出层
         */       
        toFastRegister(){
            this.$refs.fastRegister.updateVisible(true)
        },
        /**
         * 登记
         * @description: 打开登记弹出层
         */       
        toRegister(item){
            this.detailInfo = item
            this.$refs.register.updateVisible(true)
        },
        getParam() {
            const param = this.$refs.mainSearch.packageData()
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.el-scrollbar__wrap{
  overflow-x: hidden;
}
.patient-card{
    margin: 5px;
    border-width: 0;
}
.patient-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 10px;
}

.patient-name{
    line-height: 50px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: $text;
    padding: 0 10px;
    border-radius: 5px;
    background: $dialogHeader;
    @include show_line();
}

.patient-title-info{
    font-size: 14px;
    color: $titleColor;
    p{
        margin: 5px;
    }
}
.patient-content{
    font-size: 14px;
    color: $text;
    padding: 0 10px;
    p{
        @include show_line();
        margin: 5px;
    }
}
.patient-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    background: $active;
    color: $theme;
    height: 35px;
    cursor: pointer;
    *{
        font-weight: 600;
    }
}
.patient-scroll{
    width: 100%;
    padding: 0 0 10px 0;
    height: calc(100vh - 270px);
    &-bar{
        overflow-x: hidden;
    }
}
</style>

        