
<!--
* @Description: 评定项目统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @onClickRow="onClickRow">
            <template slot="assessmentNum" slot-scope="scope">
             <a v-if="!$route.query.basicInfoId" class="clickCell" @click="toEvaluateDetail(scope) ">{{scope.data.row.assessmentNum}}</a>
             <span v-else>{{scope.data.row.assessmentNum}}</span>
        </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData } from '@/api/judgeItems'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import moment from 'moment'

export default {
    name: 'JudgeItems',
    components: {
        searchForm,
        generalTable,
        
        
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'assessmentName',
                    label: '评定名称',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'patientScaleType',
                    filterable: true,
                    multiple: false,
                    label: '评定类型',
                    options: [
                        {
                            value: '0',
                            label: '量表'
                        },
                        {
                            value: '1',
                            label: '方案'
                        }
                    ],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'evaluationTime',
                    label: '日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'judgeItems',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '评定名称', // 必填
                    prop: 'assessmentName' // 必填
                },
                {
                    label: '评定类型', // 必填
                    prop: 'patientScaleTypeLabel' // 必填
                },
                {
                    label: '评估次数', // 必填
                    slot: 'assessmentNum' // 必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            startDate: '', //当前月第一天
            endDate: '', //当前月最后一天
            monthDate: [], //当前月集合
        }
    },
    created() {
        this.getList()
        this.getCurrentMonthFirst()
        this.getCurrentMonthLast()
        this.getMonthDate()
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = res.total
                })
            })
        },
          //给查询条件赋值为当前月份
          getMonthDate() {
            this.searchOptions[2].defaultVal = [
                this.$route.query.dateStartTime ?  this.moment(this.$route.query.dateStartTime*1).format("YYYY-MM-DD") : this.startDate,
                this.$route.query.dateEndTime? this.moment(this.$route.query.dateEndTime * 1).format("YYYY-MM-DD") : this.endDate
            ]
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },

            /**
        　* 跳转评定项目统计详情
        　* @Description:
          * @Param
          * @Return
        　* @Author magy
        　* @Date 2022/07/20 11:41
        　*/
        toEvaluateDetail(scope) {
            const query = Object.assign({
                scaleProgramId:scope.data.row.assessmentId,
                patientScaleType:scope.data.row.patientScaleType,
                evaluationTimeStartTime:this.paramObj.evaluationTimeStartTime,
                evaluationTimeEndTime:this.paramObj.evaluationTimeEndTime
            })
            this.$router.push({ path: '/statistics/judgeItems/detail', query: query })
        },
        onClickRow(row){
            console.log('点击行:',row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const basicInfoId = {basicInfoId:this.$route.query.basicInfoId!==undefined?this.$route.query.basicInfoId:''}
            const param = Object.assign({}, this.paramObj, page, paramObj,basicInfoId)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        