<template>
  <el-dialog
    width="60%"
    title="查看治疗组套"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="组套信息" :column="4" >
      <el-descriptions-item label="组套名称">{{detail.name || commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="组套简称">{{detail.namePinyinHead || commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="组套类型">{{detail.hospitalPrivateLabel || commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="是否启用">{{detail.statusLabel || commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="备注"> {{detail.remark || commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider/>
    <div class="mdrcc-info-title">
      关联信息
    </div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getOperationList"/>
    <el-divider/>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import {getDetail, getOperationList} from '@/api/ruleSet'
  import generalTable from '@/components/Table'

  export default {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    components: {
      generalTable
    },
    data() {
      return {
        visible: false,
        detail: [], // 详情数据
        tableData: [],
        tableConfig: {
          align: 'center', // 不必填 默认为center
          selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
          index: true, // 不必填 序号
          pagination: false, // 不必填 分页 默认为false
          column: [
            {
              label: '操作项目', // 必填
              prop: 'operName', // 必填
            },
            {
              label: '所属治疗', // 必填
              prop: 'operTypeName' // 必填
            },
            {
              label: '建议分钟数', // 必填
              prop: 'propMin' // 必填
            },
            {
              label: '单次数量', // 必填
              prop: 'schCount' // 必填
            }
          ]
        },
        total: 0 // 总条数
      }
    },
    watch: {
      visible(newVal, oldVal) {
        newVal && this.id && this.getDetail()
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      /**
      　* 获取组套详情
      　* @Description: 获取组套信息和组套关联操作项目
        * @Param null
        * @Return null
      　* @Author wangjh
      　* @Date 2021/11/11 9:29
      　*/
      getDetail() {
        const param = {}
        //组套id
        if (this.id) {
          param.id = this.id
        }

        this.getOperationList();
        getDetail(param).then(({res}) => {
          this.detail = res;
        })
      },

      /**
     　* 获取组套关联操作项目列表
     　* @Description: this.id为组套id,为空时直接返回，不为空时根据组套id获取组套关联的操作项目
       * @Param  null
       * @Return null
     　* @Author wangjh
     　* @Date 2021/11/11 9:20
     　*/
      getOperationList() {
        this.$nextTick(() => {
          const param = {};
          if (this.id) {
            param.id = this.id
          } else {
            return;
          }
          getOperationList(param).then(({res, timeStamp}) => {
            this.tableData = res;
            this.timeStamp = timeStamp;
          })
        })
      },
      updateVisible(flag) {
        this.visible = flag
      }
    }
  }
</script>
<style scoped lang='scss'>
</style>
