<!--
 * @Author: yzw
 * @Date: 2021-12-15 13:33:08
 * @LastEditTime: 2022-04-26 13:28:04
 * @LastEditors: Please set LastEditors
 * @Description: 工作台配置
-->
<template>
  <el-dialog
    width="50%"
    :title="typeName ? typeConfig[typeName].title + '配置' : '配置'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-main class="basic-main">
      <el-transfer
        target-order="unshift"
        class="my-transfer"
        v-model="choose"
        :titles="[
          typeName && `${typeConfig[typeName].title}(${all.length})`,
          `已选择(${choose.length}/${typeConfig[typeName].maxNum})`
        ]"
        :format="{ noChecked: ` `, hasChecked: ` ` }"
        :props="{
          key: 'id',
          label: 'name',
          disable: 'status'
        }"
        :data="all"
        @right-check-change="rightItemHandle"
      >
        <div class="option-item" slot-scope="{ option }">
          <span>{{ option.name }}</span>
          <el-popover
            v-if="typeName !== 'commonUse'"
            placement="top-start"
            title="功能介绍"
            width="200"
            trigger="hover"
            :content="option.remark">
              <i slot="reference" class="badge">i</i>
          </el-popover>
        </div>
        <div slot="right-footer" class="transfer-btn-group">
            <el-button @click="handleUp()">上移</el-button>
            <el-button @click="handleDown()">下移</el-button>
        </div>
      </el-transfer>
    </el-main>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getWorkbenchInfo, updateWorkbenchConfig } from "@/api/home.js";
export default {
  name: "ConfigDialog",
  props: {
    typeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      typeConfig: {
        statistics: {
          title: "日常统计",
          type: 0,
          maxNum: 6
        },
        task: {
          title: "待办任务",
          type: 1,
          maxNum: 6
        },
        commonUse: {
          title: "常用功能",
          type: 2,
          maxNum: 6
        }
      },
      all: [], //所有数据
      choose: [], //选中的数据
      readyMove:[],//准备移动的数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        this.getWorkbenchInfo();
      }
    }
  },
  methods: {
    getWorkbenchInfo() {
      getWorkbenchInfo({ type: this.typeConfig[this.typeName].type }).then(
        ({ res }) => {
          const statisticsEle = [];
          const chooseEle = [];
          switch (this.typeName) {
             //日常统计
               case 'statistics':
                  res.all.map(item => {
                    if(this.hasPermission("workbench-statistics-"+item.icon)){
                        statisticsEle.push(item);
                    } 
                  })
                  res.choose.map(item => {
                    if(this.hasPermission("workbench-statistics-"+item.icon)){
                        chooseEle.push(item.id);
                    } 
                  })
                    this.all = statisticsEle;
                    this.choose = chooseEle;
                    break
                case 'task': 
                  res.all.map(item => {
                    if(this.hasPermission("workbench-task-"+item.icon)){
                        statisticsEle.push(item);
                    } 
                  })
                  res.choose.map(item => {
                    if(this.hasPermission("workbench-task-"+item.icon)){
                        chooseEle.push(item.id);
                    } 
                  })
                    this.all = statisticsEle;
                    this.choose = chooseEle;
                    break
                case 'commonUse': 
                  res.all.map(item => {
                    if(this.hasPermission("workbench-commonUse-"+item.icon)){
                        statisticsEle.push(item);
                    } 
                  })
                  res.choose.map(item => {
                    if(this.hasPermission("workbench-commonUse-"+item.icon)){
                        chooseEle.push(item.id);
                    } 
                  })
                    this.all = statisticsEle;
                    this.choose = chooseEle;
                    break             
          }        
        }
      );
    },
    submit() {
      const maxNum = this.typeConfig[this.typeName].maxNum;
      if (this.choose.length === 0) {
        this.$message({
          message: `请至少选择一项`,
          type: "warning"
        });
        return false;
      }
      if (this.choose.length > maxNum ) {
        this.$message({
          message: `选择的数量不能超过${maxNum}`,
          type: "warning"
        });
        return false;
      }
      updateWorkbenchConfig({type:this.typeConfig[this.typeName].type,workbenchId:this.choose}).then(({res}) => {
          this.submitSuccess()
      })
    },
    submitSuccess() {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.$emit("submit");
      this.updateVisible(false);
    },
    updateVisible(val) {
      this.visible = val;
      this.all = [];
      this.choose = [];
    },
    /**
     * @description: 右侧数据选中
     * @param {Array} list 右侧选中的数组
     */    
    rightItemHandle(list){
        this.readyMove = list;
    },
    /**
     * @description: 上移
     */    
    handleUp() {
      if (this.readyMove.length > 1) {
        this.$message({
          message: `只能选择一条数据进行操作`,
          type: "warning"
        });
        return false
      }
        for (let i = 0; i < this.readyMove.length; i++) {
            const ele = this.readyMove[i];
            let index = 0
            this.choose.map((item,j) => {
                if (ele === item) {
                    index = j
                }
            })
            if(index!= 0){
                this.swapArray(index, index-1);
            } else{
                this.swapArray(index, 0);
            }
        }
    },
    /**
     * @description: 下移
     */    
    handleDown() {
      if (this.readyMove.length > 1) {
        this.$message({
          message: `只能选择一条数据进行操作`,
          type: "warning"
        });
        return false
      }
        for (let i = 0; i < this.readyMove.length; i++) {
            const ele = this.readyMove[0];
            let index = 0
            this.choose.map((item,j) => {
                if (ele === item) {
                    index = j
                }
            })
            if(index+1 != this.choose.length){
                this.swapArray(index, index+1);
            }
        }
    },
    /**
     * @description: 数据挪位
     * @param {Number} index1 第几个元素
     * @param {Number} index2 至第几个元素
     */    
    swapArray( index1, index2) {
        let sortList = this.choose
        sortList[index1] = this.choose.splice(index2, 1, this.choose[index1])[0];
        this.choose = sortList
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.basic-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-transfer-panel .el-transfer-panel__footer{
    height: 34px !important;
}
.transfer-btn-group{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}
.option-item{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

</style>
