
<template>
	<el-dialog
		width="70%"
		:title="id === '' ? '新增大屏配置' : '修改大屏配置'"
		:visible.sync="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		append-to-body
	>
		<el-form
			ref="insertForm"
			:model="form"
			label-position="right"
			label-width="220px"
			class="formBox"
			:rules="rules"
		>
			<div class="mdrcc-info-title">基本信息</div>
			<el-row :gutter="10" type="flex" justify="start">
				<el-col :xl="12">
					<el-form-item label="大屏名称" prop="name">
						<el-input v-model="form.name" placeholder="请输入..." clearable maxlength="20" />
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="地址" prop="deviceIp">
						<el-input :value="commonConfig.$checkNullData(deviceIp)" readonly />
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="开始时间~结束时间" prop="time">
						<el-time-picker
							style="width: 100%"
                            format='HH:mm'
                            value-format='HH:mm'
							is-range
							v-model="form.time"
							range-separator="至"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
							placeholder="选择时间范围"
						></el-time-picker>
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="午休开始时间~结束时间" prop="noonBreak">
						<el-time-picker
							style="width: 100%"
                            format='HH:mm'
                            value-format='HH:mm'
							is-range
							v-model="form.noonBreak"
							range-separator="至"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
							placeholder="选择时间范围"
						></el-time-picker>
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="时间间隔（分钟）" prop="timeInterval">
						<el-input v-model="form.timeInterval" placeholder="请输入1~60的整数" clearable maxlength="20"/>
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="显示范围" prop="viewRange">
						<el-select v-model="form.viewRange" placeholder="请选择" style="width: 100%">
							<el-option
								v-for="option in viewRangeOptions"
								:key="option.value"
								:label="option.label"
								:value="option.value"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="是否显示治疗项目" prop="viewOper">
						<el-select v-model="form.viewOper" placeholder="请选择" style="width: 100%">
							<el-option
								v-for="option in viewOperOptions"
								:key="option.value"
								:label="option.label"
								:value="option.value"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="治疗师" prop="userIds">
						<el-select v-model="form.userIds" multiple filterable placeholder="请选择" style="width: 100%">
							<el-option
								v-for="option in userOptions"
								:key="option.value"
								:label="option.label"
								:value="option.value"
							/>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submit()">提 交</el-button>
			<el-button @click="updateVisible(false)">取 消</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/largeScreenConfig'
import { getUserList } from "@/api/user"

export default {
	props: {
		id: {
			type: String,
		},
	},
	data() {
		const validateTimeInterval = (rule, value, callback) => {
			if (!/^[0-9]+$/.test(value) || value * 1 > 60 || value * 1 < 1) {
				callback(new Error('请输入1~60的整数！'))
			} else {
				callback()
			}
		}

		const validateTime = (rule, value, callback) => {
			if (value.length < 1 || !value instanceof Array) {
				callback(new Error('请选择开始时间及结束时间！'))
			} else {
				callback()
			}
		}

		const validateNoonBreak = (rule, value, callback) => {
			if (value.length < 1 || !value instanceof Array) {
				callback(new Error('请选择午休时间段！'))
			} else {
				callback()
			}
		}

		const validateUserIds = (rule, value, callback) => {
			if (value.length > 6) {
				callback(new Error('最多选择6个治疗师！'))
			} else if (value.length < 1) {
				callback(new Error('最少选择1个治疗师！'))
			} else {
				callback()
			}
		}
		return {
            deviceIp: '',
			checkId: '',
			rules: {
				name: [{ required: true, message: '请输入大屏名称', trigger: 'blur' }],
				time: [{ required: true, validator: validateTime, trigger: 'change' }],
				noonBreak: [{ required: true, validator: validateNoonBreak, trigger: 'change' }],
				timeInterval: [{ required: true, validator: validateTimeInterval, trigger: 'blur' }],
				viewRange: [{ required: true, message: '请选择显示范围', trigger: 'change' }],
				viewOper: [{ required: true, message: '请选择是否显示治疗项目', trigger: 'change' }],
				userIds: [{ required: true, validator: validateUserIds, trigger: 'change' }],
			},
			// 显示范围 0 全天 1 未过期
			viewRangeOptions: [
				{ value: '0', label: '全天' },
				{ value: '1', label: '未过期' },
			],
			// 是否显示治疗项目 0 显示 1 不显示
			viewOperOptions: [
				{ value: '0', label: '显示' },
				{ value: '1', label: '不显示' },
			],
			// 治疗师
			userOptions: [],
			form: {
				name: '',
				time: ["08:00", "17:00"],
				noonBreak: ["12:00", "13:00"],
				timeInterval: 5,
				viewRange: '0',
				viewOper: '0',
				userIds: [],
			},
			total: 0,
			visible: false,
			timeStamp: '', //时间戳 用于修改数据或删除数据
		}
	},
	watch: {
		visible(newVal, oldVal) {
			if (newVal === true) {
				if (this.id) {
					this.getDetail()
				}
				//...todo
			}
		},
	},
	created() {
        this.getUserList()
    },
	mounted() {},
	methods: {
		submit() {
			this.$refs.insertForm.validate((valid) => {
				if (valid) {
					const {time, noonBreak, ...others} = this.form
                    // startTime endTime
                    const today = this.moment().format("YYYY-MM-DD")
                    const startTime = this.moment(today + " " + time[0]).valueOf()
                    const endTime = this.moment(today + " " + time[1]).valueOf()
                    // noonBreakStart noonBreakEnd
                    const noonBreakStart = this.moment(today + " " + noonBreak[0]).valueOf()
                    const noonBreakEnd = this.moment(today + " " + noonBreak[1]).valueOf()
                    const parm = {
                        ...others,
                        startTime,
                        endTime,
                        noonBreakEnd,
                        noonBreakStart
                    }
                    this.updateVisible(false)
					if (this.id === '') {
						insertData(parm).then(({ res }) => {
							this.submitSuccess(res)
						})
					} else {
						editData(parm, this.timeStamp).then(({ res }) => {
							this.submitSuccess(res)
						})
					}
				}
			})
		},
		submitSuccess(res) {
			this.$message({ ...this.commonConfig.$handleSuccess })
			this.updateVisible(false)
			this.$emit('submit')
		},
        getUserList(){
            getUserList({position: '4',status: 0, size: -1}).then(({res}) => {
                this.userOptions = res.records.map(item => {
                    return {
                        label: item.trueName,
                        value: item.id
                    }
                })
            })
        },
		getDetail() {
			getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                const {deviceIp, startTime, endTime, noonBreakStart, noonBreakEnd,userList, ...others} = res
                this.deviceIp = deviceIp
                this.form = {
                    ...others,
                    userIds: userList.map(item => item.id),
                    time: [this.moment(startTime).format("HH:mm"), this.moment(endTime).format("HH:mm")],
                    noonBreak: [this.moment(noonBreakStart).format("HH:mm"), this.moment(noonBreakEnd).format("HH:mm")]
                }
				// this.form = res
				this.timeStamp = timeStamp
			})
		},
		updateVisible(val) {
			this.visible = val
			if (val === false) {
				this.$refs.insertForm.resetFields()
			}
		},
	},
}
</script>
<style scoped lang='scss'>
</style>
      
