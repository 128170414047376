<!--
 * @Author: ZF-WLY
 * @Date: 2021-10-18 11:18:31
 * @LastEditTime: 2021-12-29 13:36:57
 * @LastEditors: yzw
 * @Description: 新增/编辑机构弹窗
 * @FilePath: \recovery_management\src\views\system\organization\addDialog.vue
-->

<template>
    <el-dialog
        width="70%"
        :title="insertType ? '新增机构' : '编辑机构'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="rowData"
            :model="detailData"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">机构信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="机构名称" prop="name">
                        <el-input
                            v-model="detailData.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="detailData.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="上级机构" prop="parentId">
                        <el-cascader
                            v-model="detailData.parentId"
                            :options="levelTree"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="类型" prop="deptType">
                        <el-select v-model="detailData.deptType" clearable placeholder="请选择">
                            <el-option
                                v-for="item in deptType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="负责人" prop="deptHeads">
                        <el-input
                            v-model="detailData.deptHeads"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="电话" prop="deptHeadsPhone">
                        <el-input
                            v-model="detailData.deptHeadsPhone"
                            placeholder="请输入..."
                            clearable
                            maxlength="11"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="detailData.remark"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { orgUpdate, orgInsert } from '@/api/organization'
export default {
    name: 'InsertOrg', // 组件名
    props: {
        // eslint-disable-next-line vue/require-default-prop
        tableData: Array,
        // eslint-disable-next-line vue/require-default-prop
        deptType: Array,
        // eslint-disable-next-line vue/require-default-prop
        detailData: Object,
        insertType: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        timeStamp: String
    },
    data() {
        /**
         * 排序验证
         * @description: 排序字段必须为数字
         * @param {*} rule 验证规则
         * @param {*} value 要验证的数据
         * @param {*} callback 回调
         * @return {*}
         */
        const validatorSort = (rule, value, callback) => {
            if (/^[0-9]+$/.test(value) === false) {
                callback(new Error('请输入数字'))
            } else {
                callback()
            }
        }
        return {
            // 验证规则
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入机构名称',
                        trigger: 'blur'
                    },
                    { min: 3, message: '至少需要 3 个字符', trigger: 'blur' }
                ],
                deptType: [
                    {
                        required: true,
                        message: '请选择机构类型',
                        trigger: 'change'
                    }
                ],
                sort: [
                    {
                        required: true,
                        validator: validatorSort,
                        trigger: 'blur'
                    }
                ],
                parentId: [
                    {
                        required: true,
                        message: '请选择上级机构',
                        trigger: 'blur'
                    }
                ]
            },
            levelTree: [],
            visible: false // 弹出层显隐
        }
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * 插入/更新组织机构
         * @description: detailData.id为空时，进行插入；不为空时，进行更新。
         *               parentId为字符类型时，原样传入参数中；parentId为字符类型时，查找数组中最后一项，作为parentId传入参数中
         *               成功后，进入成功回调函数
         * @param {*}
         * @return {*}
         */
        submit() {
            this.$refs.rowData.validate((valid) => {
                if (valid) {
                    if (!this.detailData.id) {
                        let parm = {}
                        if (typeof this.detailData.parentId === 'string') {
                            parm = this.detailData
                        } else {
                            parm = this.detailData
                            parm.parentId =
                                parm.parentId[parm.parentId.length - 1]
                        }
                        orgInsert(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        let parm = {}
                        if (typeof this.detailData.parentId === 'string') {
                            parm = this.detailData
                        } else {
                            parm = this.detailData
                            parm.parentId =
                                parm.parentId[parm.parentId.length - 1]
                        }
                        orgUpdate(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        /**
         * 提交成功回调函数
         * @description: 提交（添加/更新）成功后，展示成功信息，并触发父级"@submit"中绑定的函数，隐藏该弹出层
         * @param {Object} res 后台请求成功后，返回的数据
         * @return {*}
         */
        submitSuccess(res) {
            // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        /**
         * 移除所有树结构中禁用的机构
         * @description: 使用status判断，status为“1”时，即为禁用的机构
         * @param {Array} orgData 原始数组结构
         * @param {Array} newData 过滤掉禁用机构之后的树结构
         * @return {*}
         */        
        removeDisable(orgData, newData) {
            orgData.forEach((item) => {
                if (item.status !== '1') {
                    newData.push({ ...item, children: [] })
                }
                if (item.children) {
                    newData.length &&
                        this.removeDisable(
                            item.children,
                            newData[newData.length - 1].children
                        )
                }
            })
        },
        /**
         * 移除空的子节点
         * @description: 如果某个对象中，children的数组长度为0，则移除该层children；递归遍历查找每层children
         * @param {Array} orgData 原始树结构数组
         * @return {Array} 移除原始树结构中空children后的结果
         */         
        removeEmptyChild(orgData) {
            orgData = orgData.map((item) => {
                let {children, ...temp} = item
                if (children && children.length) {
                    return {
                        ...temp,
                        children: this.removeEmptyChild(children) // 递归遍历，把新的过滤好的children插入到原先的children中
                    }
                } else if (children) {
                    return temp
                }
            })
            return orgData
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，
         *      当模态框隐藏时，清空rowData表单中所有的内容
         *      当模态框显示时，移除掉原先树机构中禁用的机构
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.rowData.resetFields()
            } else {
                this.levelTree = [] // 新的树结构
                this.removeDisable(this.tableData, this.levelTree) // 移除原先禁用的机构
                this.levelTree = this.removeEmptyChild(this.levelTree) // 移除空的子节点
            }
        }
    }
}
</script>
<style scoped>
</style>

