<!--
 * @Author: your name
 * @Date: 2022-11-23 09:45:36
 * @LastEditTime: 2022-12-09 16:15:41
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\treatmentPlan\addDialog.vue
-->
<template>
    <el-dialog
        width="1100px"
        :title="id === '' ? '新增计划' : '修改计划'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" :rules="rules">
            <div class="mdrcc-info-title">计划信息</div>
            <el-row :gutter="10" type="flex" justify="start" class="content-wrapper">
                <el-col :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item label="计划名称" class="formItem" prop="planName">
                        <el-input v-model="form.planName" placeholder="请输入计划名称" clearable maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="16" :xl="16">
                    <el-form-item label="计划日期" class="formItem" prop="time">
                        <el-date-picker
                            v-model="form.time"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="疾病诊断" class="formItem" prop="">
                        <el-input
                            type="textarea"
                            readonly
                            :autosize="{ minRows: 2 }"
                            v-model="content"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="医嘱" class="formItem" prop="">
                        <generalTable
                            v-if="visible"
                            ref="mainTable-add"
                            :table-data="tableData"
                            :config="tableConfig"
                            :newHeight="tableHeight"
                        >
                            <template slot="handle" slot-scope="scope">
                                <el-button type="text" @click="toDetail(scope)">详情</el-button>
                            </template>
                        </generalTable>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="计划内容" class="formItem" prop="planContent">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            v-model="form.planContent"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="注意事项" class="formItem" prop="precautions">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            v-model="form.precautions"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="阶段目标" class="formItem" prop="stageGoal">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            v-model="form.stageGoal"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" class="formItem" prop="remark">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            v-model="form.remark"
                            maxlength="50"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <detail-dialog ref="detail" :id="checkId" />
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import detailDialog from '@/views/advice/detailDialog'
import { insertData, editData, getDetail, getBasicInfo } from '@/api/treatmentPlan'
export default {
    props: {
        id: String,
        basicInfoId: String
    },
    components: {
        generalTable,
        detailDialog
    },
    data() {
        return {
            timeStamp: '',
            tableHeight: 0,
            checkId: '',
            rules: {
                planName: [
                    {
                        required: true,
                        message: '请输入计划名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在 2 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                time: [
                    // 菜单类型
                    {
                        required: true,
                        message: '请选择计划时间',
                        trigger: 'change'
                    }
                ],
                planContent: [
                    {
                        required: true,
                        message: '请输入计划内容',
                        trigger: 'blur'
                    }
                ],
                stageGoal: [
                    {
                        required: true,
                        message: '请输入阶段目标',
                        trigger: 'blur'
                    }
                ]
            },
            form: {
                planName: '',
                time: '',
                planContent: '',
                precautions: '',
                stageGoal: '',
                remark: ''
            },
            visible: false, // 可见性， 默认不可见
            content: '',
            tableData: [],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        sortable: false,
                        prop: 'operTypeLabel' // 必填
                    },
                    {
                        label: '操作项目名称', // 必填
                        sortable: false,
                        prop: 'operName', // 必填
                        width: 180
                    },
                    {
                        label: '医嘱类型', // 必填
                        sortable: false,
                        prop: 'doctorOrderType', // 必填
                        formatter: (row, column) => {
                            const typeMap = { '1': '长期医嘱', '2': '限定医嘱' }
                            return typeMap[row.doctorOrderType]
                        }
                    },
                    {
                        label: '总次数', // 必填
                        sortable: false,
                        prop: 'createCount', // 必填
                        formatter: (row, column) => {
                            return row.doctorOrderType === '1' ? '长期' : row.createCount
                        }
                    },
                    {
                        label: '单次数量', // 必填
                        sortable: false,
                        prop: 'schCount' // 必填
                    },
                    {
                        label: '治疗频次', // 必填
                        sortable: false,
                        prop: 'frequencyCount', // 必填
                        formatter: (row, column) => {
                            return `${row.frequencyCount}次/${row.frequencyTime}${row.frequencyUnitLabel}`
                        }
                    },
                    {
                        label: '执行次数', // 必填
                        sortable: false,
                        prop: 'usedCount' // 必填
                    },
                    {
                        label: '创建日期', // 必填
                        sortable: false,
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            return this.moment(row.createDate * 1).format('YYYY-MM-DD')
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        sortable: false,
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '80' // 不必填
                    }
                ]
            }
        }
    },
    watch: {
        /** 监听模块可见性
         * @description:
         *      显示模块时，查看该模块是否传入了parentId，如果传入，同步到form.parentId中
         *      隐藏模块时，清空所有表单内容
         * @param {*} newVal 当前值
         * @param {*} oldVal 上一状态值
         * @return {*}
         */
        visible(newVal, oldVal) {
            if (newVal) {
                if (this.id) {
                    this.getDetail()
                } else {
                    this.getBasicInfo()
                }
            } else {
                this.$refs.insertForm.resetFields()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /** 提交
         * @description:
         *      如果parentId为数组，则将parentId更新为数组最后一项
         *      如果传入了id值，则进入编辑菜单接口；否则进入新增菜单接口
         *      成功后，进入提交成功函数
         * @param {*}
         * @return {*}
         */
        submit() {
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    let param = {
                        ...this.form,
                    }
                    param.startTime = this.moment(param.time[0]).valueOf()
                    param.endTime = this.moment(param.time[1]).valueOf()
                    delete param.time
                    delete param.id
                    if (this.id) {
                        param.id = this.id
                        editData(param, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        param.basicInfoId = this.basicInfoId
                        insertData(param).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        /** 提交成功函数
         * @description:
         *      进行提交成功信息提示
         *      隐藏当前弹窗
         *      触发父级模块'@submit'绑定的函数
         * @param {*} res
         * @return {*}
         */
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        /** 患者诊断及医嘱数据
         *
         */
        getBasicInfo() {
            getBasicInfo({ basicInfoId: this.basicInfoId }).then(({ res }) => {
                this.tableData = [...res.adviceList]
                this.content = [...res.contentList]
                    .map(item => {
                        return `${this.moment(item.dateTime).format('YYYY-MM-DD')} ${item.content}`
                    })
                    .join('\n')
                this.tableHeight = 36 + this.tableData.length * 30
            })
        },
        /** 计划详情
         * @description: 将获取的菜单详情信息同步到form数据中
         * @param {*}
         * @return {*}
         */
        getDetail() {
            getDetail({ id: this.id, basicInfoId: this.basicInfoId }).then(({ res, timeStamp }) => {
                const {patientTreatmentPlanBasicInfo, basicInfoId, ...others} = res
                const {adviceList, contentList} = patientTreatmentPlanBasicInfo
                this.form = {...others, time:[this.moment(res.startTime).format("YYYY-MM-DD"), this.moment(res.endTime).format("YYYY-MM-DD")]}
                this.timeStamp = timeStamp
                this.tableData = [...adviceList]
                this.content = [...contentList]
                    .map(item => {
                        return `${this.moment(item.dateTime).format('YYYY-MM-DD')} ${item.content}`
                    })
                    .join('\n')
                this.tableHeight = 36 + this.tableData.length * 30
            })
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style lang="scss" scoped>
.content-wrapper {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}
</style>
