
<!--
* @Description: 治疗组套管理页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true"/>
    </el-header>
    <el-main class="basic-main">
        <el-row class="table-btn-group">
            <el-button type="primary" @click="toInsert()" v-if="addStatus">新增</el-button>
            <el-button type="primary" @click="toMultipleDel()" v-if="batchDelStatus">批量删除</el-button>
         </el-row>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" :checkIds="checkIds" @updateSelectedIds="updateSelectedIds" >
            <template slot="handle" slot-scope="scope">
              <el-button type="text" @click.stop="toDetail(scope)">详情</el-button>
              <el-button type="text" v-if="editStatus" @click.stop="toEdit(scope)">编辑</el-button>
              <el-button type="text" v-if="delStatus" class="delBtn" @click.stop="toDel(scope)">删除</el-button>
            </template>
        </generalTable>
        <detailDialog :id="checkId" ref="detail" />
        <addDialog :id="checkId" ref="insert" @submit="search" />
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData,batchDeleteData} from '@/api/ruleSet'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/businessRules/ruleSet/addDialog'
import detailDialog from '@/views/businessRules/ruleSet/detailDialog'
import { getDict } from '@/api/common'

export default {
    name: 'RuleSet',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [],
            checkIds: [],// 所有页选中的id
            searchOptions: [
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'hospitalPrivate',
                    filterable: true,
                    multiple: false,
                    label: '组套类型',
                    options: [],
                    defaultVal: []
                } ,
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '组套名称',
                    defaultVal: ''
                }
            ],
            tableConfig: {
                id: 'ruleSet',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                saveSelected: true, // 不必填 翻页时，是否保存选中项
                column: [
                {
                    label: '组套名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '组套简称', // 必填
                    prop: 'namePinyinHead' // 必填
                },
                {
                    label: '组套类型', // 必填
                    prop: 'hospitalPrivateLabel'
                },
                {
                    label: '创建者', // 必填
                    prop: 'createUserName' // 必填
                },
                {
                    label: '是否启用', // 必填
                    prop: 'statusLabel'
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            addStatus: true, // 新增按钮权限
            editStatus: true, // 修改按钮权限
            delStatus: true, // 删除按钮权限
            batchDelStatus: true, // 批量删除按钮权限
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.addStatus = this.hasPermission('ruleSet-add');
        this.editStatus = this.hasPermission('ruleSet-edit');
        this.delStatus = this.hasPermission('ruleSet-del');
        this.batchDelStatus = this.hasPermission('ruleSet-batchDel');
        this.getList();
        this.getRuleSetTypes();
    },
    mounted: function() {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
            this.checkIds= [];
        },

        /**
          * 获取组套数据列表
          * @Description:
          * @Param null
          * @Return null
          * @Author wangjh
          * @Date 2021/11/11 9:37
          */
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res;
                    this.tableData = records;
                    this.timeStamp = timeStamp;
                    this.total = parseInt(total);
                })
            })
        },

        /**
          *  跳转新增页面
          * @Description:
          * @Param null
          * @Return null
          * @Author wangjh
          * @Date 2021/11/11 9:38
          */
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },

        /**
        　*  跳转详情页面
        　* @Description:
          * @Param {*} scope
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:42
        　*/
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },

        /**
        　* 跳转编辑页面
        　* @Description:
          * @Param {*} scope
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:43
        　*/
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },

        /**
        　* 删除方法
        　* @Description: 删除选择组套后，刷新列表
          * @Param {*} scope
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:43
        　*/
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess});
                    this.getList();
                });
            })
        },
        /**
         * 更新选中id
         * @description: 根据更新的ids进行新的回显
         * @param {Array} checkIds 选中项的id
         * @return {*}
         */        
        updateSelectedIds(checkIds){
            this.checkIds = checkIds
        },
        /**
        　* 批量删除
        　* @Description: 判断是否选择删除组套，未选择直接返回，删除后，刷新列表
          * @Param null
          * @Return null
        　* @Author wangjh
        　* @Date 2021/11/11 9:44
        　*/
        toMultipleDel() {
            if (!this.checkIds.length) {
                this.$message("请先选择删除的组套");
                return;
            }
            const {$delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                batchDeleteData(this.checkIds, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess});
                    this.checkIds = []
                    this.getList();
                });
            });
        },

        /**
       　 * 获取组套类型列表
       　 * @Description: 从字典表中获取组套类型（全院1/私有2），字典表启用状态类型为 rule_set_type
          * @Param null
          * @Return null
       　 * @Author wangjh
       　 * @Date 2021/11/11 9:35
        　*/
        getRuleSetTypes() {
          getDict({ type: 'rule_set_type' }).then(({res}) => {
            this.searchOptions[0].options = res.rule_set_type;
          });
        },

        /**
          *  获取查询参数
          * @Description:
          * @Param null
          * @Return 参数对象
          * @Author wangjh
          * @Date 2021/11/11 9:46
          */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

