<!--
 * @Author: your name
 * @Date: 2022-11-24 10:30:49
 * @LastEditTime: 2022-11-25 11:23:04
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\patient\summary\addDialog.vue
-->

<template>
    <el-dialog
        width="78%"
        :title="id === '' ? '新增治疗小结' : '修改治疗小结'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <el-row class="table-btn-group">
            <el-button type="primary" @click="insertAssessment()">关联评估记录</el-button>
            <el-button type="primary" @click="insertTreatment()">关联治疗记录</el-button>
        </el-row>
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
                评估信息
            </div>
            <el-row :gutter="10" class="mg-bt-15">
                <generalTable ref="mainTable" :table-data="scaleArray" :config="scaleConfig" :newHeight="200">
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" class="delBtn" @click="toDelScale(scope)">删除</el-button>
                    </template>
                </generalTable>
            </el-row>
            <div class="mdrcc-info-title">
                治疗信息
            </div>
            <el-row :gutter="10" class="mg-bt-15">
                <generalTable ref="mainTable" :table-data="treatmentArray" :config="treatmentConfig" :newHeight="200">
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" class="delBtn" @click="toDelTreatment(scope)">删除</el-button>
                    </template>
                </generalTable>
            </el-row>
            <div class="mdrcc-info-title">
                其他信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="小结类型" prop="conclusionType">
                        <el-select
                            v-model="form.conclusionType"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            :collapse-tags="true"
                            style="width:100%"
                        >
                            <el-option
                                v-for="option in conclusionList"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="小结内容" prop="conclusionContent">
                        <el-input
                            v-model="form.conclusionContent"
                            type="textarea"
                            resize="none"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <add-treatment-dialog
            ref="treatment"
            :basicInfoId="basicInfoId"
            :orgList="treatmentArray"
            @submit="updateTreatment"
        />
        <add-assessment-dialog
            ref="assessment"
            :basicInfoId="basicInfoId"
            :orgList="scaleArray"
            @submit="updateAssessment"
        />
    </el-dialog>
</template>
<script>
import { getDict } from '@/api/common'
import { insertData, editData, getDetail } from '@/api/patientTreatmentConclusion'
import generalTable from '@/components/Table'
import addTreatmentDialog from '@/views/patient/summary/addTreatmentDialog'
import addAssessmentDialog from '@/views/patient/summary/addAssessmentDialog'
export default {
    components: {
        generalTable,
        addTreatmentDialog,
        addAssessmentDialog
    },
    props: {
        id: String,
        basicInfoId: {
            type: String
        }
    },
    watch:{
        visible(newVal){
            if (newVal) {
                this.id && this.getDetail()
            } else {
                this.$refs.insertForm.resetFields()
                this.scaleArray = []
                this.treatmentArray = []
            }
        }
    },
    data() {
        return {
            treatmentArray: [],
            treatmentConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '治疗师',
                        prop: 'therapyName'
                    },
                    {
                        label: '医嘱类型',
                        prop: 'doctorOrderTypeLabel'
                    },
                    {
                        label: '所属治疗',
                        prop: 'operTypeLabel'
                    },
                    {
                        label: '治疗项目',
                        prop: 'operName'
                    },
                    {
                        label: '治疗时间',
                        prop: 'treatmentDate',
                        formatter: (row, column) => {
                            return row.treatmentDate ? this.moment(row.treatmentDate).format('YYYY-MM-DD') : '--'
                        }
                    },
                    {
                        label: '状态',
                        prop: 'statusLabel'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '120' // 不必填
                    }
                ]
            },
            scaleArray: [],
            scaleConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类',
                        prop: 'typeName'
                    },
                    {
                        label: '类型',
                        prop: 'patientScaleTypeLabel'
                    },
                    {
                        label: '量表/方案名称',
                        prop: 'name'
                    },
                    {
                        label: '评定日期',
                        prop: 'evaluateDate',
                        formatter: (row, column) => {
                            return row.evaluateDate ? this.moment(row.evaluateDate).format('YYYY-MM-DD') : '--'
                        }
                    },
                    {
                        label: '评定人',
                        prop: 'userName'
                    },
                    {
                        label: '状态',
                        prop: 'patientScaleStatusLabel'
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '120' // 不必填
                    }
                ]
            },
            conclusionList: [],
            form: {},
            total: 0, // 总条数
            rules: {
                conclusionType: [{ required: true, message: '请选择小结类型', trigger: 'change' }],
                conclusionContent: [
                    { required: true, message: '请输入小结内容', trigger: 'blur' },
                    { min: 3, message: '至少输入 3 个字符', trigger: 'blur' }
                ]
            },
            visible: false,
            timeStamp: ''
        }
    },
    created() {
        this.getDict()
    },
    mounted() {},
    methods: {
        getDict() {
            const type = 'treatment_conclusion'
            getDict({ type }).then(({ res }) => {
                this.conclusionList = res[type].map(item => {
                    return {
                        value: item.value,
                        label: item.label
                    }
                })
            })
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                console.log('------getDetail', res)
                const { assessmentResList, treatmentResList, ...others } = res
                this.form = { ...others }
                this.scaleArray = [...assessmentResList]
                this.treatmentArray = [...treatmentResList]
                this.timeStamp = timeStamp
            })
        },
        updateTreatment(treatmentArray) {
            this.treatmentArray = [...treatmentArray]
        },
        updateAssessment(scaleArray) {
            this.scaleArray = [...scaleArray]
        },
        submit() {
            if (!this.treatmentArray.length && !this.scaleArray.length) {
                this.$message({
                    type: 'warning',
                    message: '至少关联一项评估项或治疗项'
                })
                return
            }
            this.$refs.insertForm.validate(valid => {
                if (valid) {
                    let parm = {
                        ...this.form, 
                        assessmentIds: this.scaleArray.map(item => item.id).join(),
                        treatmentIds: this.treatmentArray.map(item => item.id).join(),
                    }
                    if (!this.id) {
                        parm.basicInfoId = this.basicInfoId
                        insertData({...parm, basicInfoId: this.basicInfoId,}).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        parm.id = this.id
                        delete parm.basicInfoId
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        insertTreatment() {
            this.$refs.treatment.updateVisible(true)
        },
        insertAssessment() {
            this.$refs.assessment.updateVisible(true)
        },
        toDelScale(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                this.scaleArray = this.scaleArray.filter(item => item.id !== scope.data.row.id)
            })
        },
        toDelTreatment(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                this.treatmentArray = this.treatmentArray.filter(item => item.id !== scope.data.row.id)
            })
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style scoped>
.mg-bt-15 {
    margin-bottom: 15px;
}
</style>
