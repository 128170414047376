<!--
 * @Author: your name
 * @Date: 2022-11-18 09:49:18
 * @LastEditTime: 2022-12-09 09:48:18
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\folder\configDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        title="配置模板"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <generalTable
            ref="mainTable"
            :newHeight="600"
            :table-data="tableData"
            :config="tableConfig"
            @updatePage="getList"
        >
            <!-- 切换禁用/启用状态 设置机构禁用/启用 -->
            <template slot="status" slot-scope="scope">
                <el-switch
                    v-if="scope.data.row.id !== '0'"
                    v-model="scope.data.row.status"
                    inactive-value="1"
                    active-value="0"
                    @change="setStatus(scope.data.row)"
                />
                <template v-else>--</template>
            </template>
            <template slot="handle" slot-scope="scope">
                <el-button
                    type="text"
                    v-if="scope.data.row.type === '0'"
                    @click="insertChild(scope)"
                >
                    新增子级
                </el-button>
                <el-button type="text" v-if="scope.data.row.id !== '0'" @click="toEdit(scope)">编辑</el-button>
                <el-button type="text" v-if="scope.data.row.id !== '0'" class="delBtn" @click="toDel(scope)">
                    删除
                </el-button>
            </template>
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="updateVisible(false)">关 闭</el-button>
        </span>
        <add-node-dialog
            ref="insert"
            :timeStamp="timeStamp"
            :collectId="id"
            :tableData="tableData"
            :parentId="parentId"
            :source="checkItem"
            @submit="getList"
        />
    </el-dialog>
</template>
<script>
import { getCollectTree, deleteCasesCollectTree, updateCasesCollectTreeStatus } from '@/api/casesCollectTree'
import moment from 'moment' // moment插件
import generalTable from '@/components/Table'
import addNodeDialog from '@/views/caseReport/folder/addNodeDialog'
import AddNodeDialog from '@/views/knowledgeBase/myDocument/addNodeDialog.vue'
export default {
    props: {
        id: String
    },
    components: {
        generalTable, // 表格控件
        addNodeDialog,
        AddNodeDialog
    },
    watch: {
        visible(newVal) {
            if (!newVal) {
                this.tableData = []
            } else {
                this.getList()
            }
        }
    },
    data() {
        return {
            visible: false,
            tableData: [],
            tableConfig: {
                id: 'menu',
                align: 'left', // 不必填 默认为center
                index: true, // 不必填 序号
                expandOpen: true, // 默认展开
                column: [
                    {
                        label: '名称', // 必填
                        prop: 'name', // 必填
                        width: 230
                    },
                    {
                        label: '类型', // 必填
                        prop: 'type', // 必填
                        sortable: false, // 非必填 默认为true
                        width: 120,
                        formatter: (row, column) => {
                            // 类型 0 文件夹 1病例模板
                            const typeMap = {
                                0: '文件夹',
                                1: '病例模板'
                            }
                            return typeMap[row.type]
                        }
                    },
                    {
                        label: '关联模板', // 必填
                        prop: 'templateName', // 必填
                        sortable: false // 非必填 默认为true
                    },
                    {
                        label: '状态', // 必填
                        slot: 'status', // 必填
                        width: 80,
                        align: 'center'
                    },
                    {
                        label: '创建时间', // 必填
                        prop: 'createDate', // 必填
                        formatter: (row, column) => {
                            // 将时间戳转换成YYYY-MM-DD HH:mm:ss格式时间
                            return row.createDate ? moment(row.createDate * 1).format('YYYY-MM-DD HH:mm:ss') : '--'
                        },
                        sortable: false // 非必填 默认为true
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: {}, // 筛选项
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            checkItem: {}, // 单条数据对象 用于详情或编辑请求数据用
            parentId: '', // 父级菜单id 用于显示父级层级
            timeStamp: '' // 时间戳
        }
    },
    methods: {
        /** 菜单详情
         * @description: 将获取的菜单详情信息同步到form数据中
         * @param {*}
         * @return {*}
         */
        getList() {
            getCollectTree({ collectId: this.id }).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                console.log('-----get tree', res)
                this.tableData = res
            })
        },
        insertChild(scope) {
            console.log('parent id', scope.data.row.id)
            this.parentId = scope.data.row.id
            this.checkItem = {}
            this.$refs.insert.updateVisible(true)
        },
        toEdit(scope) {
            this.parentId = scope.data.row.parentId
            this.checkItem = scope.data.row
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                deleteCasesCollectTree({ id: scope.data.row.id }).then(res => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.getList()
                })
            })
        },
        setStatus(row) {
            updateCasesCollectTreeStatus({ ...row, collectId: this.id }).then(res => {
                this.$message({ ...this.commonConfig.$handleSuccess })
                this.getList()
            })
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，当模态框隐藏时，清空表单中所有的内容
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
