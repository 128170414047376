<!--
 * @Author: yzw
 * @Date: 2021-12-23 19:18:42
 * @LastEditTime: 2022-03-25 09:48:39
 * @LastEditors: Please set LastEditors
 * @Description: 
-->

<template>
    <el-dialog
        width="70%"
        :title="'工作台配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="formData"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">{{this.typeConfig[this.typeName].title}}</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            disabled
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-switch
                            v-model="form.status"
                            inactive-value="1"
                            active-value="0"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { updateAllWorkbenchConfig } from '@/api/hospital'
export default {
    name: 'workbrenchConfig', // 组件名
    props: {
        detailData: Object,
        timeStamp: String,
        typeName: String
    },
    data() {
        /**
         * 排序验证
         * @description: 排序字段必须为数字
         * @param {*} rule 验证规则
         * @param {*} value 要验证的数据
         * @param {*} callback 回调
         * @return {*}
         */
        const validatorSort = (rule, value, callback) => {
            if (/^[0-9]+$/.test(value) === false) {
                callback(new Error('请输入数字'))
            } else {
                callback()
            }
        }
        return {
            typeConfig: {
                statistics: {
                    title: "日常统计",
                    type: 0,
                },
                task: {
                    title: "待办任务",
                    type: 1,
                },
                commonUse: {
                    title: "常用功能",
                    type: 2,
                }
            },
            rules: { // 验证规则
                sort: [
                    {
                        required: true,
                        validator: validatorSort,
                        trigger: 'blur'
                    }
                ],
            },
            visible: false, // 弹出层显隐
            form:{
                id:'',
                name:'',
                sort: 0,
                status: '0'
            }
        }
    },
    created() {},
    mounted() {},
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.form = this.detailData
            } else{
                this.form = {
                    id:'',
                    name:'',
                    sort: 0,
                    status: '0'
                }
            }
        }
    },
    methods: {
        submit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    updateAllWorkbenchConfig(this.form, this.timeStamp).then(({ res }) => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        /**
         * 提交成功回调函数
         * @description: 提交（添加/更新）成功后，展示成功信息，并触发父级"@submit"中绑定的函数，隐藏该弹出层
         * @param {Object} res 后台请求成功后，返回的数据
         * @return {*}
         */
        submitSuccess(res) {
            // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐，
         *      当模态框隐藏时，清空formData表单中所有的内容
         *      当模态框显示时，移除掉原先树机构中禁用的机构
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            console.log("val",val);
            this.visible = val
            if (val === false) {
               // this.$refs.formData.resetFields()
                this.form = {
                    id:'',
                    name:'',
                    sort: 0,
                    status: '0'
                }
            }
        }
    }
}
</script>
<style scoped>
</style>

