/*
 * @Author: your name
 * @Date: 2021-11-18 11:49:36
 * @LastEditTime: 2021-11-23 15:46:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_management/src/api/mySchedule.js
 */

/**
 * 页面: 我的日程
 * pageName: mySchedule
 **/
import request from '@/utils/request'

/**
 * @description: 获取我的日程列表数据 小黑板
 */
export function getList(data) {
    return request({
        url: '/patientSchedule/grid',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 获取我的患者
 * @param {*} data
 * @return {*}
 */
export function getMyPatientList(data) {
    return request({
        url: '/patientSchedule/myPatient',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取全部患者
 * @param {*} data
 * @return {*}
 */
 export function getAllPatientList(data) {
    return request({
        url: '/patientSchedule/allPatient',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增我的日程数据
 */
export function insertData(data) {
    return request({
        url: '/patientSchedule/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改我的日程数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除我的日程数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/patientSchedule/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
