<!--
 * @Author: yzw
 * @Date: 2023-04-21 09:20:56
 * @LastEditTime: 2023-04-23 10:21:49
 * @LastEditors: yzw
 * @Description: 
-->

<template>
    <el-container>
        <el-main class="basic-main">
            <div class="date">{{ moment().format('YYYY-MM-DD') }}  星期{{ week[parseInt(moment().format('E')) - 1] }}</div>
            <div class="section">
                <div class="left">
                    <div class="left-top">
                        <span>当前排队数量</span>
                        <div class="number-box">
                            <img src="@/assets/img/flow-number.png">
                            <span>{{ queuesNum || 0 }}</span>
                        </div>
                    </div>
                    <div class="left-bottom">
                        <el-table
                        :data="tableData"
                        style="width: 100%;"
                        stripe
                        :cell-style = "{fontSize:'22px',padding:'8px', height: '20px', overflow: 'hidden'}"
                        :header-cell-style="{fontSize:'22px'}"
                        :max-height="320"
                        empty-text="暂无排队"
                        >
                            <el-table-column
                                prop="name"
                                label="治疗项"
                                width="180"
                                align="center">
                            </el-table-column>
                            <el-table-column
                                prop="minSeq"
                                label="当前号"
                                 align="center">
                            </el-table-column>
                            <el-table-column
                                prop="count"
                                label="排队数"
                                 align="count">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="center">
                    <div class="center-top">
                        <div class="center-top-box">
                            <img src="@/assets/img/flow-icon.png">
                            流程预约说明
                        </div>
                    </div>
                    <img src="@/assets/img/appointment-flow.png" class="center-img">
                </div>
                <div class="right">
                    <div class="right-top">
                        <img src="@/assets/img/appointment-treat.png">
                        预约治疗
                    </div>
                    <div v-if="infoStatus" style="width:100%">
                        <div class="input-group">
                            <input v-model="id" placeholder="请输入身份证号/住院号/门诊号"/>
                            <input v-model="phone" placeholder="请输入手机号"/>
                        </div>
                        <div class="info-box" v-if="patientInfoStatus">
                            <div class="info-box-text">
                                患者姓名：{{patientInfo.name || '查无此人'}}<br/>可用医嘱：{{ patientInfo.adviceList.length || 0 }}
                            </div>
                            <p>*请确认患者信息是否为待预约治疗信息，如无误请点击预约治疗进行预约</p>
                        </div>
                    </div>
                    <div v-else style="width:100%;border-radius: 5px;border: 4px solid #4c90db;">
                        <el-table
                        :data="patientInfo.adviceList"
                        style="width: 100%;"
                        ref="multipleTable"
                        stripe
                        :cell-style = "{fontSize:'22px',padding:'8px', height: '20px', overflow: 'hidden'}"
                        :header-cell-style="{fontSize:'22px'}"
                        :max-height="400"
                        empty-text="暂无信息"
                        @selection-change="changeSelect()"
                        >
                            <el-table-column
                            type="selection"
                            width="60"
                            align="center">
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="治疗项"
                                width="250"
                                align="center">
                            </el-table-column>
                            <el-table-column
                                prop="schCount"
                                label="数量"
                                 align="center">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="right-bottom" v-if="infoStatus">
                        <el-button class="right-bottom-btn" size="medium" round>扫描腕带</el-button>
                        <el-button class="right-bottom-btn" size="medium" round type="primary" @click="getPatientAdvice()">信息查询</el-button>
                        <el-button class="right-bottom-btn" size="medium" round :type="btnStatus" @click="showAdviceList()">预约治疗</el-button>
                    </div>
                    <div class="right-bottom" v-else>
                        <el-button class="right-bottom-btn" size="medium" round @click="back()">取消预约</el-button>
                        <el-button class="right-bottom-btn" size="medium" round type="primary" @click="submit()">提交</el-button>
                    </div>
                </div>

            </div>
        </el-main>
    </el-container>
</template>
<script>
import { getList, getPatientAdvice,submit } from '@/api/reservation'
// import { addTouchMoveListener } from 'public/sdeEditor/dialogs/jsoneditor/jsoneditor';

export default {
    name: 'Reservation',
    components: {
    },
    data() {
        return {
            week: ['一', '二', '三', '四', '五', '六', '日'],
            queuesNum: 0, //排队数量
            tableData: [],
            infoStatus: true,
            patientInfoStatus:false,
            id: '',
            phone: '',
            patientInfo: {
                name: '',
                adviceList:[],
            },
            btnStatus:'info'
             
        }
    },
    created() {
        this.getList()
        // 实现轮询
        this.timer = window.setInterval(() => {
            setTimeout(this.getList(), 0);
        }, 10 * 1000);
    },
    destroyed() {
        window.clearInterval(this.timer)
    },
    methods: {
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                getList().then(({ res, timeStamp }) => {
                    let num = 0
                    for (let i = 0; i < res.length; i++) {
                        const ele = res[i];
                        num += ele.count * 1
                        
                    }
                    this.queuesNum = num
                    this.tableData = res
                })
            })
        },
        getPatientAdvice() {
            if (this.id == '' && this.phone == '') {
                this.$message({
                    message: '请输入完整信息',
                    type: 'error'
                });
                return false
            }
            let param = {
                idCard: this.id,
                phone: this.phone
            }
            getPatientAdvice(param).then(({ res, timeStamp }) => {
                this.patientInfoStatus = true
                this.patientInfo = res
                this.btnStatus = 'primary'
            })
        },
        showAdviceList() {
            if (!this.patientInfoStatus) {
                this.$message({
                    message: '请先进行信息查询',
                    type: 'error'
                });
                return false
            }
            this.infoStatus = false
        },
        back() {
            this.infoStatus = true
            this.patientInfoStatus = false
            this.id = ''
            this.phone = ''
            this.btnStatus = 'info'
        },
        submit() {
            if (this.$refs.multipleTable.selection.length == 0) {
                this.$message({
                    message: '请至少选择一条',
                    type: 'error'
                });
                return false
            }
            
            let adviceStr = ''
            
            for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
                const ele = this.$refs.multipleTable.selection[i];
                adviceStr += ele.id + ','
            }
            
            adviceStr = adviceStr.substring(0,adviceStr.length - 1)
            submit({adviceInfoIds:adviceStr}).then(({ res, timeStamp }) => {
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
                this.back()
            })
        },
        changeSelect(ele) {
            console.log(ele)
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.basic-main{
    background: url('../../../assets/img/appointment.jpg') no-repeat 100% 100%;
}
.date{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 26px;
    margin-bottom: 35px;
}
.section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > div{
        width: 32%;
        border-radius: 10px;
        padding: 35px 25px;
        height: 75vh;
    }
}
.left{
    background: #fff;
    &-top{
        height: 25vh;
        width: 100%;
        background-color: rgba(224, 236, 249, 0.7);
        border: solid 4px #c7e1fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        > span{
            font-size: 55px;
            font-weight: bold;
            color: #4c90db;
            margin-top: 15px;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
        .number-box{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img{
                width: 35px;
                margin-right: 20px;
            }
            span{
                font-size: 55px;
                font-weight: bold;
                color: #facd4f;
            }
        }
    }
    &-bottom{
        width: 100%;
        margin-top: 25px;
        flex: 1;
        background-color: #e9f2fb;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        border: solid 4px #c7e1fc;
        overflow: hidden;
    }
}
.center{
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &-img{
        margin-top: 45px;
        width: 89.8%;
    }
    &-top{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-items: center;
        justify-content: center;
        &-box{
            width: 294px;
            height: 88px;
            background-color: #609dde;
            border-radius: 10px;
            position: relative;
            top: -36px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            column-gap: 16px;
            justify-content: center;
            align-items: flex-start;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img{
                width: 30px;
            }
        }
    }
}

.right{
    background-color: rgba(28, 65, 126, 0.54);
    padding: 36px 0 42px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &-top{
        width: 100%;
        background: url('../../../assets/img/treat-title-bg.png') center no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        img{
            width: 24px;
            margin-right: 10px;
        }
        font-size: 27px;
        font-weight: bold;
        color: #fff;
    }
    &-bottom{
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &-btn{
            font-size: 18px;
            font-weight: bold;
        }
    }
    .input-group {
        width: 100%;
        input{
            width: 100%;
            height: 6.9vh;
            display: inline-block;
            background-color: #d6e3f0;
            border-radius: 1.2vh;
            outline: none;
            border: none;
            padding: 1.5vh 0 1.5vh 2vh;
            font-size: 15px;
            color: #666;
            font-weight: normal;
            margin: 10px 0;
        }
    }
    .info-box{
        margin-top: 10px;
        &-text{
            width: 100%;
            height: 100px;
            background-color: #609dde;
            border-radius: 1.2vh;
            font-size: 22px;
            color: #fff;
            line-height: 39px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        p{
            color: #eec44a;
        }
    }
}
</style>