<!--
 * @Author: your name
 * @Date: 2021-12-20 18:52:00
 * @LastEditTime: 2022-03-03 11:13:02
 * @LastEditors: Please set LastEditors
 * @Description: 评估分析
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\evaluationAnalysis.vue
-->

<template>
    <el-container>
        <template v-if="type == 0">
            <el-aside>
                <el-tree
                    ref="tree"
                    class="user-tree"
                    node-key="id"
                    :data="treeData"
                    default-expand-all
                    :filter-node-method="filterTree"
                    :expand-on-click-node="false"
                    :highlight-current="true"
                    @node-click="onClickTreeRow"
                >
                    <span
                        class="el-tree-node__label"
                        slot-scope="{ node, data }"
                    >
                        <!-- <template> -->
                        <span
                            style="color: green"
                            v-if="
                                data.directionArrow &&
                                data.directionArrow == 'up'
                            "
                        >
                            ⬆
                        </span>
                        <span
                            style="color: red"
                            v-else-if="
                                data.directionArrow &&
                                data.directionArrow == 'down'
                            "
                        >
                            ⬇
                        </span>
                        <span :title="node.label"
                            >{{ node.label}}
                        </span>
                    </span>
                </el-tree>
            </el-aside>

            <!-- <div > -->
            <el-main class="basic-main">
                <div class="headerBox">
                    <div class="reportListBox">
                        <el-tag
                            v-for="item in reportList"
                            :key="item.id"
                            @click="onClickScaleTag(item.id)"
                            :effect="item.id == checkId ? 'dark' : 'light'"
                        >
                            <div class="tagInfo">
                                <span>{{
                                    item.evaluateDate
                                        ? moment(item.evaluateDate).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "--"
                                }}</span>
                            </div>
                        </el-tag>
                    </div>
                    <div v-if="reportList.length > 0" style="min-width: 200px">
                        <el-button
                            v-if="trendAnalysis_rules"
                            type="primary"
                            @click="echarsClick()"
                        >
                            趋势分析
                        </el-button>
                        <el-button
                            v-if="videosData_rules"
                            type="primary"
                            @click="videoClick()"
                        >
                            影像资料
                        </el-button>
                    </div>
                </div>
                <div class="reportFileBox" ref="printFile">
                    <scaleFile
                        :patientScaleId="scaleId"
                        :patientBasicInfoId="basicInfoId"
                        :flag="'4'"
                        :childBool="true"
                        :analysis="true"
                        v-if="scaleId"
                    />
                </div>
            </el-main>
        </template>
        <videosData
            ref="videosData"
            v-else-if="type == 1"
            :basicInfoId="basicInfoId"
            :scaleInfoId="scaleInfoId"
            @popData="pooClick()"
        />
        <trendAnalysis
            ref="trendAnalysis"
            v-else-if="type == 2"
            :basicInfoId="basicInfoId"
            :scaleInfoId="scaleInfoId"
            @popData="pooClick()"
        />
    </el-container>
</template>

<script>
import scaleFile from "@/views/scale/patientAssessment/patientScale.vue";
import { getAnalysisTree, getAnalysisList } from "@/api/patientAssessment";
import videosData from "@/views/scale/patientAssessment/videosData.vue"; //影视资料
import trendAnalysis from "@/views/scale/patientAssessment/trendAnalysis.vue"; //趋势分析

export default {
    data() {
        return {
            search: "", //树搜索
            trendAnalysis_rules: true, //趋势分析权限
            videosData_rules: true, //影像资料权限
            scaleInfoId: "", //量表类型 ID
            treeData: [],
            reportList: [],
            checkId: "",
            scaleId: "",
            type: 0,
        };
    },
    components: {
        scaleFile,
        videosData,
        trendAnalysis,
    },
    props: {
        basicInfoId: {
            type: String,
        },
    },
    watch: {
        search(val) {
            this.$refs.tree.filter(val);
        },
    },
    created() {
        /**
         * @description: 按钮 现实权限 趋势分析权限 影像资料权限
         * @param {*}
         * @return {*}
         */
        this.trendAnalysis_rules = this.hasPermission("trendAnalysis_rules");
        this.videosData_rules = this.hasPermission("videosData_rules");
        this.getAnalysisTree();
    },
    methods: {
        /**
         * @description: 获取分类树
         * @param {*}
         * @return {*}
         */
        getAnalysisTree() {
            this.$nextTick(() => {
                const param = { basicInfoId: this.basicInfoId };
                getAnalysisTree(param).then(({ res, timeStamp }) => {
                    this.treeData = res; // 新的树结构
                });
            });
        },

        /**
         * @description: 量表列表
         * @param {*}
         * @return {*}
         */
        getAnalysisList() {
            this.$nextTick(() => {
                const param = {
                    scaleInfoId: this.scaleInfoId,
                    basicInfoId: this.basicInfoId,
                };
                getAnalysisList(param).then(({ res, timeStamp }) => {
                    this.reportList = res;
                    this.timeStamp = timeStamp;
                });
            });
        },
        /**
         * @description: 左侧树结构点击事件
         * @param {*}
         * @return {*}
         */
        onClickTreeRow(obj) {
            this.checkId = "";
            this.scaleId = "";
            this.scaleInfoId = obj.id;
            this.getAnalysisList();
        },
        // 通过名称过滤树结构
        filterTree(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },

        /**
         * @description: 点击获取量表详情
         * @param {*} id
         * @return {*}
         */
        onClickScaleTag(id) {
            this.checkId = id;
            this.scaleId = this.checkId;
        },
        /**
         * @description: 趋势分析
         * @param {*}
         * @return {*}
         */
        echarsClick() {
            this.scaleId = "";
            this.type = 2;
        },
        /**
        /**
         * @description: 影像资料
         * @param {*}
         * @return {*}
         */
        videoClick() {
            this.scaleId = "";
            this.type = 1;
        },
        /**
         * @description: 返回
         * @param {*}
         * @return {*}
         */
        pooClick() {
            this.type = 0;
            this.scaleId = this.checkId;
            this.$nextTick(() => {
                this.$refs.tree.setCurrentKey(this.scaleInfoId);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.user-tree {
    border: 1px solid $tableColor;
    height: calc(100% - 30px);
    max-height: calc(100% - 100px);
    overflow-y: auto;
    width: 100%;
    overflow-x: auto;
}

.el-tree > .el-tree-node {
    display: inline-block !important;
    width: 100%;
}

.el-container {
    margin-top: 0;
}
.headerBox {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 60px;
    flex-direction: row;
    justify-content: space-between;
    .reportListBox {
        display: flex;
        overflow-x: auto;
        margin-right: 20px;
    }
    .reportListBox .el-tag {
        height: 50px;
        padding: 5px;
        margin: 5px;
        display: flex;
        align-items: center;
        .tagInfo {
            flex-direction: column;
            display: flex;
        }
    }
}
.reportFileBox {
    height: calc(100% - 100px);
}
</style>
