<!--
 * @Author: your name
 * @Date: 2022-09-02 10:43:34
 * @LastEditTime: 2022-09-15 10:06:26
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\workbook\index.vue
-->
<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
        </el-header>
        <el-main class="basic-main">
            <el-row type="flex" style="padding-bottom: 10px;">
                <el-button type="primary" plain @click="submit()">
                    审核
                </el-button>
            </el-row>
            <generalTable
                ref="mainTable"
                :table-data="list"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
            >
            <!-- 是否异常 0异常 1正常 -->
                <template slot="isAbnormal" slot-scope="scope">
                    <span v-if="scope.data.row.isAbnormal === '1'">正常</span>
                    <span v-else style="color: #E44D42;">异常</span>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button @click.stop="toDetail(scope.data.row.basicInfoId)" type="text">查看</el-button>
                </template>
            </generalTable>
        </el-main>
        <detailDialog ref="insert" :useDate="useDate" :basicInfoId="basicInfoId" />
    </el-container>
</template>
<script>
import {getDict} from "@/api/common"
import {getList, updateCheckStatus} from "@/api/treatmentVerification"
import detailDialog from "@/views/treatment/verification/detailDialog"
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
export default {
    name: "treatmentVerification",
    components: {
        searchForm,
        generalTable,
        detailDialog
    },
    data(){
        const today = new Date().getTime()
        return {
            total: 0,
            list: [],
            useDate: 0,
            basicInfoId: "",
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: "select",
                    placeholder: '请选择在院状态',
                    key: 'statusIn',
                    filterable: true,
                    label: '在院状态',
                    options: [],
                    defaultVal: []
                },
                {
                    type: "select",
                    placeholder: '请选择是否异常',
                    key: 'isAbnormal',
                    filterable: true,
                    label: '是否异常',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'date',
                    placeholder: '登记日期',
                    key: 'useDate',
                    label: '登记时间',
                    defaultVal: today,
                    datetimePickerOptions: {},
                }
            ],
            tableConfig: {
                id: 'id',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者', // 必填
                        prop: 'name', // 必填
                    },
                    {
                        label: '在院状态', // 必填
                        prop: 'statusIn', // 必填
                        formatter:(row, column) => {
                            const resMap = {
                                "1" : "在院",
                                "2" : "门诊"
                            }
                            return resMap[row.statusIn]
                        }
                    },
                    {
                        label: '床号', // 必填
                        prop: 'bedNum', // 必填
                    },
                    {
                        label: '就诊号', // 必填
                        prop: 'hosNum', // 必填
                    },
                    {
                        label: '治疗数量', // 必填
                        prop: 'treatmentCount', // 必填
                    },
                    {
                        label: '登记数量', // 必填
                        prop: 'markCount', // 必填
                    },
                    {
                        label: '登记状态', // 必填
                        slot: 'isAbnormal', // 必填
                    },
                    {
                        label: '审核状态', // 必填
                        prop: 'checkStatus', // 必填
                        formatter:(row, column) => {
                            // 审核状态(0未审核1审核完成)
                            const resMap = {
                                "0" : "未审核",
                                "1" : "审核完成"
                            }
                            return resMap[row.checkStatus]
                        }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
        }
    },
    async created(){
        const dictMap = { 
            statusIn: await this.getDict("patient_inHos_status"), 
            isAbnormal: await this.getDict("register_abnormal")
        }
        this.initSearchOptions(dictMap)
        this.getList()
    },
    methods: {
        /**
         * @description:搜索
         * @param {*} data
         * @return {*}
         */
        search(data) {
            console.log("------search data", data)
            let paramObj = this.getParam(data)
            console.log("------getParam", this.paramObj)
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getParam(data) {
            let page = this.$refs.mainTable.getPage()
            let paramObj = this.$refs.mainSearch.packageData();
            let param = Object.assign({}, page, paramObj)
            data && Object.assign(param, data)
            this.useDate = param.useDate
            this.paramObj = param
            return param
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                let param = this.getParam()
                console.log("------param", param)
                this.useDate = param.useDate
                getList(param).then(({ res, timeStamp }) => {
                    this.list = res.records.map(item => {
                        return {
                            ...item,
                            canNotSelect: !Boolean(item.id)
                        }
                    })
                    this.total = res.total * 1
                    this.timeStamp = timeStamp
                })
            })
        },
        toDetail(basicInfoId){
            this.basicInfoId = basicInfoId
            this.$refs.insert.updateVisible(true)
        },
        submit(){
            const selected = this.$refs.mainTable.getChecked()
            if (!selected || !selected.length) {
                return
            }
            console.log("------selected", selected, selected.filter(item => item.isAbnormal === '0'))
            const abnormal = selected.filter(item => item.isAbnormal === '0').length
            let htmlStr = ""
            if (abnormal) {
                htmlStr = `<p>存在${abnormal}条<span style="color: #E44D42;">异常</span>，是否审核通过？</p>`
            } else {
                htmlStr = `<p>是否审核通过？</p>`
            }
            this.$confirm(htmlStr, '提示', {
                dangerouslyUseHTMLString: true,
            }).then(() => {
                // this.$message('点击了确定！');
                const checkedId = selected.map(item => item.id).join()
                updateCheckStatus({checkedId}, this.timeStamp).then(res => {
                    this.$message({
                        type: "success",
                        message: "审核成功！"
                    })
                    this.$refs.mainSearch.resetForm()
                })
            });
        },
        initSearchOptions(dictMap){
            this.searchOptions = this.searchOptions.map(item => {
                if (Object.keys(dictMap).includes(item.key)) {
                    return {
                        ...item,
                        options: dictMap[item.key]
                    }
                }
                return {
                    ...item
                }
            })
            console.log(`------searchOptions`, this.searchOptions)
        },
        getDictMap(key){
            return new Promise(resolve => {
                getDict({ type: key }).then(({ res }) => {
                    let resMap = {}
                    res[key].forEach(item => {
                        resMap[item.value] = item.label
                    })
                    resolve(resMap)
                });
            })
        },
        getDict(key){
            return new Promise(resolve => {
                getDict({ type: key }).then(({ res }) => {
                    resolve(res[key])
                });
            })
        },
    }
}
</script>
<style lang="scss" scoped>

</style>