<!--
 * @Author: SixTeen
 * @Date: 2021-11-18 11:49:36
 * @LastEditTime: 2022-09-09 09:35:05
 * @LastEditors: ZF-WLY
 * @Description: 我的日程-选择患者
 * @FilePath: /recovery_management/src/views/treatment/mySchedule/addDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        :title="'选择患者'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-tabs
            type="border-card"
            style="width: 100%"
            v-model="tabValue"
            @tab-click="clickTab"
        >
            <el-tab-pane :lazy="true" name="1" label="我的患者">
                <searchForm
                    ref="myPatient_mainSearch"
                    :searchoptions="searchMyOptions"
                    :back-status="false"
                    @search="myPatient_search"
                    style="padding: 0px 10px"
                />
                <generalTable
                    ref="myPatient__mainTable"
                    :table-data="tableData"
                    :config="tableConfig"
                    :total="total"
                    @updatePage="getMyPatientList"
                    @onClickRow="onMyTabClickRow"
                    @onDoubleClickRow="onDoubleClickRow"
                    :newHeight="'447px'"
                >
                </generalTable>
            </el-tab-pane>
            <el-tab-pane
                :lazy="true"
                name="2"
                label="全部患者"
                v-if="allPatientStatus"
            >
                <searchForm
                    ref="allPatient_mainSearch"
                    :searchoptions="searchOptions"
                    :back-status="false"
                    @search="allPatient_search"
                    style="padding: 0px 10px"
                />
                <generalTable
                    ref="allPatient_mainTable"
                    :table-data="tableAllData"
                    :config="allTableConfig"
                    :total="allTotal"
                    @updatePage="getAllPatientList"
                    @onClickRow="onAllTabClickRow"
                    @onDoubleClickRow="onAllDoubleTabClickRow"
                    :newHeight="'400px'"
                >
                </generalTable>
            </el-tab-pane>
        </el-tabs>

        <span slot="footer" class="dialog-footer">
            <el-button v-if="addStatus" type="primary" @click="submit()"
                >添 加</el-button
            >
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getMyPatientList, getAllPatientList } from "@/api/mySchedule";
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getDept } from "@/api/patient"; //获取科室
import { getDict } from "@/api/common";

export default {
    components: {
        generalTable,
        searchForm,
    },
    props: {
        //入口为首页时,显示-已收藏患者、分配患者未在我的日程中进行排班的患者
        enrty:{
            type: String
        }
    },
    data() {
        return {
            searchMyOptions: [
                //我的患者搜索筛选
                {
                    type: "input",
                    placeholder: "请输入",
                    key: "patientName",
                    label: "搜索",
                    defaultVal: "",
                },
            ],
            searchOptions: [
                //全部患者搜索筛选
                {
                    type: "input",
                    placeholder: "请输入姓名",
                    key: "patientName",
                    label: "姓名",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "住院/就诊号",
                    key: "serialNum",
                    label: "住院/就诊号",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择科室",
                    key: "deptId",
                    filterable: true,
                    label: "所属科室",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "select",
                    placeholder: "请选择状态",
                    key: "patientStatus",
                    filterable: true,
                    label: "在院状态",
                    options: [],
                    defaultVal: [],
                },
            ],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "住院号/门诊号", // 必填
                        prop: "serialNum", // 必填
                    },
                    {
                        label: "所属科室", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "姓名", // 必填
                        prop: "patientName", // 必填
                    },
                ],
            },
            tableData: [],
            total: 0, // 总条数

            allTableConfig: {
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "住院号/门诊号", // 必填
                        prop: "serialNum", // 必填
                    },
                    {
                        label: "所属科室", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "姓名", // 必填
                        prop: "patientName", // 必填
                    },
                ],
            },
            tableAllData: [], //全部患者数据
            allTotal: 0, // 总条数
            allParamObj: null, // 筛选项
            status: 1,
            visible: false,
            tabValue: "1", //1 我的患者 2 全部患者
            basicInfo: {}, //选择患者数据
            addStatus: true, // 添加按钮权限
            allPatientStatus: true, // 添加按钮权限
            // timeStamp: "" //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getMyPatientList();
            }
        },
        tabValue(newVal, oldVal) {
            if (newVal == "1") {
                this.getMyPatientList();
            } else {
                this.getAllPatientList();
            }
        },
    },
    created() {
        //添加
        this.addStatus = this.hasPermission("mySchedule_add");
        this.allPatientStatus = this.hasPermission("mySchedule_allPatient");
        this.getDeptList();
        this.getPatientStatus();
    },
    mounted() {},
    methods: {
        /**
         * @description: tab切换
         * @param {*} data
         * @return {*}
         */
        clickTab(data) {
            // console.log(data.index);
        },
        /**
         * @description: 获取所属科室
         * @param {*}
         * @return {*}
         */
        getDeptList() {
            getDept({ deptType: "4" }).then(({ res, timeStamp }) => {
                this.deptOptions = res.map((element) => {
                    const ele = {
                        value: element.id,
                        label: element.label,
                    };
                    return ele;
                });
                this.searchOptions[2].options = this.deptOptions;
            });
        },
        /**
         * @description: 获取在院状态
         * @param {*}
         * @return {*}
         */
        getPatientStatus() {
            getDict({ type: "patient_inHos_status" }).then(({ res }) => {
                this.patientOptions = res.patient_inHos_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions[3].options = this.patientOptions;
            });
        },
        /**
         * @description: 获取我的患者列表
         * @param {*}
         * @return {*}
         */
        getMyPatientList() {
            setTimeout(() => {
                let param = this.$refs.myPatient_mainSearch.packageData();
                if (this.$route.query.entry === 'home') {
                    Object.assign(param,{noSchedule:'home'})
                }
                getMyPatientList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    this.timeStamp = timeStamp;
                });
            }, 50);
        },
        /**
         * @description: 点击我的患者行
         * @param {*}
         * @return {*}
         */
        onMyTabClickRow(scope) {
            this.basicInfo = scope;
        },
        /**
         * @description:双击我的患者行 
         * @param {*}
         * @return {*}
         */
        onDoubleClickRow(scope){
            this.basicInfo = scope;
            if (scope.basicInfoId) {
                this.updateVisible(false);
                this.$emit("submit", scope);
            } else {
                this.$message("请选择患者");
            }
        },
        /**
         * @description: 我的患者 模糊查询
         * @param {*} data
         * @return {*}
         */
        myPatient_search(data) {
            this.$refs.myPatient__mainTable.handleCurrentChange(1);
        },

        /**
         * @description: 获取全部患者列表
         * @param {*}
         * @return {*}
         */
        getAllPatientList() {
            setTimeout(() => {
                const param = this.getParam();
                getAllPatientList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res;
                    this.tableAllData = records;
                    this.allTotal = parseInt(total);
                    this.timeStamp = timeStamp;
                });
            }, 100);
        },
        /**
         * @description: 全部患者-筛选查询
         * @param {*} data
         * @return {*}
         */
        allPatient_search(data) {
            Object.assign(this.allParamObj, data);
            this.$refs.allPatient_mainTable.handleCurrentChange(1);
        },

        getParam() {
            const paramObj = this.$refs.allPatient_mainSearch.packageData();
            const page = this.$refs.allPatient_mainTable.getPage();
            const param = Object.assign({}, this.allParamObj,page, paramObj);
            this.allParamObj = param;
            return param;
        },
        /**
         * @description: 点击行
         * @param {*}
         * @return {*}
         */
        onAllTabClickRow(scope) {
            this.basicInfo = scope;
        },

        onAllDoubleTabClickRow(scope){
            this.basicInfo = scope;
            if (scope.basicInfoId) {
                this.updateVisible(false);
                this.$emit("submit", scope);
            } else {
                this.$message("请选择患者");
            }
        },

        /**
         * @description: 添加 回传
         * @param {*}
         * @return {*}
         */
        submit() {
            if (this.basicInfo.basicInfoId) {
                this.updateVisible(false);
                this.$emit("submit", this.basicInfo);
            } else {
                this.$message("请选择患者");
            }
        },
        /**
         * @description: 关闭
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val;
        },
    },
};
</script>
<style scoped lang="scss"></style>
