<!--
 * @LastEditors: ZF-WLY
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="70%"
        :title="QueId ? '编辑问题' : '新增问题'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item label="所属分类" prop="scaleQuestionTypeId">
                        <el-cascader
                            v-model="form.scaleQuestionTypeId"
                            :options="treeData"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                value: 'id'
                            }"
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="试题标题" prop="name">
                        <el-input v-model="form.name" placeholder="请输入" clearable maxlength="1000" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="试题排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入"
                            clearable
                            maxlength="20"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="标识" prop="answerCoordinate">
                        <el-input v-model="form.answerCoordinate" placeholder="请输入" clearable maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col v-if="['0'].includes(form.answerType)" :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="PC端展示形式" prop="pcShow">
                        <el-radio-group v-model="form.pcShow">
                            <el-radio :key="'0'" :label="'0'">填空</el-radio>
                            <el-radio :key="'1'" :label="'1'">选择</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="图片" prop="imageData">
                        <el-upload
                            action="#"
                            list-type="picture-card"
                            :file-list="fileList"
                            class="avatar-uploader"
                            :class="uploadDisabled"
                            :on-remove="handleRemove"
                            :before-upload="beforeAvatarUpload"
                            :http-request="httpRequest"
                            :before-remove="beforeRemove"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="问题类型" prop="answerType">
                        <!-- '0'->'单选';'1'->'多选';'2'->'问笞';'3'->'小结';'4'->"填空'; -->
                        <el-radio-group v-model="form.answerType">
                            <template v-for="item in questionTypeOptions">
                                <el-radio :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                            </template>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col v-if="['0', '1'].includes(form.answerType)" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-button style="margin-bottom: 10px" type="primary" @click="toAddOption()">新增选项</el-button>
                    <generalTable ref="queTable" :table-data="form.optionList" :config="optionTableConfig" total="0">
                        <template slot="name" slot-scope="scope">
                            <el-input v-model="scope.data.row.name" placeholder="请输入名称" clearable maxlength="1000" />
                        </template>
                        <template slot="lsn" slot-scope="scope">
                            <el-input v-model="scope.data.row.lsn" placeholder="请输入选项" clearable maxlength="20" />
                        </template>
                        <template slot="mark" slot-scope="scope">
                            <el-input v-model="scope.data.row.mark" placeholder="请输入分数" clearable maxlength="20" />
                        </template>
                        <template slot="sort" slot-scope="scope">
                            <el-input
                                v-model="scope.data.row.sort"
                                placeholder="请输入排序"
                                clearable
                                maxlength="20"
                                onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            />
                        </template>
                        <template slot="handle" slot-scope="scope">
                            <el-button type="text" class="delBtn" @click="toDelOption(scope.data.$index)">
                                删除
                            </el-button>
                        </template>
                    </generalTable>
                </el-col>

                <el-col :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item label="评估分析" prop="analyseFlag">
                        <el-radio-group v-model="form.analyseFlag">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item label="量表汇总" prop="typeFlag">
                        <el-radio-group v-model="form.typeFlag">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="验证规则" prop="validRule">
                        <el-checkbox-group v-model="validRuleList">
                            <el-checkbox label='"required":true'>必填</el-checkbox>
                            <el-checkbox label='"readonly":true'>只读</el-checkbox>
                            <el-checkbox label='"type":"number"'>仅能输入数字</el-checkbox>
                            <el-checkbox
                                label='"step":"1"'
                                :disabled="stepFlag && stepFlag != 0"
                                @change="
                                    val => {
                                        changeStepFlag(val, '0')
                                    }
                                "
                            >
                                整数
                            </el-checkbox>
                            <el-checkbox
                                label='"step":"0.1"'
                                :disabled="stepFlag && stepFlag != 1"
                                @change="
                                    val => {
                                        changeStepFlag(val, '1')
                                    }
                                "
                            >
                                一位小数
                            </el-checkbox>
                            <el-checkbox
                                label='"step":"0.01"'
                                :disabled="stepFlag && stepFlag != 2"
                                @change="
                                    val => {
                                        changeStepFlag(val, '2')
                                    }
                                "
                            >
                                两位小数
                            </el-checkbox>
                        </el-checkbox-group>
                        <el-input
                            style="width:150px;margin-right:10px"
                            v-model="validRuleMin"
                            placeholder="最小值"
                            clearable
                            maxlength="100"
                        />
                        <el-input
                            style="width:150px;margin-right:10px"
                            v-model="validRuleMax"
                            placeholder="最大值"
                            clearable
                            maxlength="100"
                        />
                        <el-input
                            type="textarea"
                            style="margin-top:10px"
                            v-model="validRuleCount"
                            placeholder="计算规则"
                            clearable
                        />
                        <el-input
                            type="textarea"
                            style="margin-top:10px"
                            v-model="validRuleArray"
                            placeholder="可选内容 (结果校验)"
                            clearable
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="!QueId" type="primary" @click="submit(1)">保 存</el-button>
            <el-button type="primary" @click="submit(2)">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { addBasicScaleQuestion, getBasicScaleQuestionInfo, updateBasicScaleQuestion } from '@/api/publicScaleLib'
import { uploadFile, getDict } from '@/api/common'
import generalTable from '@/components/Table'
import { addScaleQuestion, getScaleQuestionInfo, updateScaleQuestion } from '@/api/privateScaleLib'
import $ from 'jquery'

export default {
    components: {
        generalTable
    },
    props: {
        queDetail: {
            type: Object
        },
        treeData: {
            type: Array
        },
        addqueTypeId: {
            type: Array
        },
        scaleInfoId: {
            type: String
        },
        QueId: {
            type: String
        },
        QueDetailId: {
            type: String
        },
        libFlag: {
            type: String
        }
    },
    data() {
        return {
            uploadVisible: false,
            uploadDisabled: '',
            form: {
                name: '',
                scaleQuestionTypeId: '',
                sort: '',
                answerCoordinate: '',
                imageData: '', //模板文件名
                answerType: '0',
                optionList: [],
                analyseFlag: '1',
                typeFlag: '1',
                pcShow: '1',// "0" 填空 "1" 选择
                validRule: ''
            },
            timeStamp: '',
            typeList: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入试题标题',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        message: '请输入试题排序',
                        trigger: 'blur'
                    }
                ],
                answerCoordinate: [
                    {
                        required: true,
                        message: '请输入标识',
                        trigger: 'blur'
                    }
                ],
                scaleQuestionTypeId: [
                    {
                        required: true,
                        message: '请选择分类',
                        trigger: 'blur'
                    }
                ],
                pcShow: [
                    {
                        required: true,
                        message: '请选择PC端展示形式',
                        trigger: 'blur'
                    }
                ]
            },
            fileList: [],
            visible: false,
            questionTypeOptions: [],
            optionTableConfig: {
                id: 'publicScaleLib',
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: '名称', // 必填
                        slot: 'name' // 必填
                    },
                    {
                        label: '选项', // 必填
                        slot: 'lsn' // 必填
                    },
                    {
                        label: '分数', // 必填
                        slot: 'mark' // 必填
                    },
                    {
                        label: '排序', // 必填
                        slot: 'sort' // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
            validRuleList: [],
            stepFlag: false,
            validRuleMax: '',
            validRuleMin: '',
            validRuleCount: '',
            validRuleArray: ''
        }
    },
    created() {
        this.get_question_type()
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.addqueTypeId) {
                    this.form.scaleQuestionTypeId = this.addqueTypeId
                }
                if (this.QueDetailId) {
                    if (this.libFlag === 'publicLib') {
                        this.getBasicScaleQuestionInfo()
                    } else {
                        this.getScaleQuestionInfo()
                    }
                }
            }
        }
    },
    mounted() {},
    methods: {
        changeStepFlag(val, index) {
            if (val) {
                this.stepFlag = index
            } else {
                this.stepFlag = val
            }
        },
        getBasicScaleQuestionInfo() {
            getBasicScaleQuestionInfo({ id: this.QueDetailId }).then(({ res, timeStamp }) => {
                this.fillAnswer(res, timeStamp)
            })
        },
        /**
         * @description:获取试题详情(评估量表库)
         * @param {*}
         * @return {*}
         */
        getScaleQuestionInfo() {
            getScaleQuestionInfo({ id: this.QueDetailId }).then(({ res, timeStamp }) => {
                this.fillAnswer(res, timeStamp)
            })
        },
        fillAnswer(res, timeStamp, type) {
            this.timeStamp = timeStamp
            this.form = {
                name: res.name || '',
                scaleQuestionTypeId: res.scaleQuestionTypeId || '',
                sort: res.sort || '',
                answerCoordinate: res.answerCoordinate || '',
                imageData: res.imageData || '', //模板文件名
                answerType: res.answerType || '0',
                optionList: res.optionList || [],
                analyseFlag: res.analyseFlag || '1',
                typeFlag: res.typeFlag || '1',
                validRule: res.validRule || '',
                pcShow: res.pcShow || '1'
            }
            if (this.form.imageData) {
                this.fileList = [
                    {
                        name: 'image',
                        url: res.imageData
                    }
                ]
                this.uploadDisabled = 'disabled'
            }
            let validRuleCache = res.validRule ? JSON.parse('{' + res.validRule + '}') : {}
            if (validRuleCache.max) {
                this.validRuleMax = validRuleCache.max
                delete validRuleCache.max
            }
            if (validRuleCache.min) {
                this.validRuleMin = validRuleCache.min
                delete validRuleCache.min
            }
            if (validRuleCache.validRuleCount) {
                this.validRuleCount = validRuleCache.validRuleCount
                delete validRuleCache.validRuleCount
            }
            if (validRuleCache.validRuleArray) {
                this.validRuleArray = validRuleCache.validRuleArray
                delete validRuleCache.validRuleArray
            }
            validRuleCache = JSON.stringify(validRuleCache)
            validRuleCache = validRuleCache.slice(1, validRuleCache.length - 1)
            this.validRuleList = res.validRule.split(',')
        },
        toDelOption(index) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                let optionListCache = this.form.optionList
                optionListCache.splice(index, 1)
                this.form.optionList = optionListCache
            })
        },
        toAddOption() {
            let obj = {
                name: '',
                mark: '',
                lsn: '',
                sort: this.form.optionList.length + 1
            }
            this.form.optionList = [...this.form.optionList, obj]
        },
        /**
         * @description: 获取问题分类字典
         */
        get_question_type() {
            getDict({ type: 'question_type' }).then(({ res }) => {
                console.log('-------ques type------', res)
                this.questionTypeOptions = res.question_type.map(item => {
                    return {
                        value: item.value,
                        label: item.label
                    }
                })
            })
        },
        handleRemove(file, fileList) {
            this.fileList = []
            this.form.imageData = ''
            this.uploadDisabled = ''
        },
        beforeAvatarUpload(file) {
            const { $fileUrl, $imgTypes } = this.commonConfig
            const imgTypes = $imgTypes.map(item => `image/${item}`)
            const isJPG = imgTypes.includes(file.type)
            if (!isJPG) {
                this.$message.error('请上传图片格式文件!')
                return isJPG
            }
            return isJPG
        },
        beforeRemove(file, fileList) {
            if (file && file.status === 'success') {
                return this.$confirm(`确定移除该图片？`)
            }
        },
        httpRequest(data) {
            this.fileList = []
            let _this = this // 这里要转一下是因为在下面的function里 this的指向发生了变化
            let rd = new FileReader()
            let file = data.file
            rd.readAsDataURL(file)
            rd.onloadend = function(e) {
                _this.form.imageData = this.result
                _this.fileList.push({
                    name: file.name,
                    url: this.result
                })
                _this.uploadDisabled = 'disabled'
            }
        },
        /**
         * @description: 获取验证规则
         * 校验必填项
         * 对于选择题，必须有选项，且选项中所有列内容不能为空
         * 校验通过后，公共量表库题目新增/保存至公共量表库；评估量表库题目新增/保存至评估量表库
         * @param {Number} type 1 保存 2 提交
         * @return {*}
         */

        submit(type) {
            // 获取规则
            let validRuleListCache = this.validRuleList
            let validRuleMap = JSON.parse(`{${validRuleListCache.filter(item => item).join(',')}}`)
            console.log("------other rules", validRuleMap)
            // 重置最大值、最小值、计算规则、可选内容
            // 有内容重置内容，没有内容删除字段
            if (this.validRuleMax) {
                validRuleMap.max = this.validRuleMax
            } else {
                delete validRuleMap.max
            }
            if (this.validRuleMin) {
                validRuleMap.min = this.validRuleMin
            } else {
                delete validRuleMap.min
            }
            if (this.validRuleCount) {
                validRuleMap.validRuleCount = this.validRuleCount
            } else {
                delete validRuleMap.validRuleCount
            }
            if (this.validRuleArray) {
                validRuleMap.validRuleArray = this.validRuleArray
            } else {
                delete validRuleMap.validRuleArray
            }
            // 获取规则对象字符串
            this.form.validRule = JSON.stringify(validRuleMap)
                .replace('{', '')
                .replace('}', '')
            console.log("------validRule",validRuleMap, this.form.validRule, this.form.imageData)
            // return
            if (this.form.scaleQuestionTypeId instanceof Array) {// 使用instanceof来判断数组类型
                this.form.scaleQuestionTypeId = this.form.scaleQuestionTypeId[this.form.scaleQuestionTypeId.length - 1]
            }
            this.$refs.insertForm.validate(valid => {
                if (!valid) {
                    return
                }
                // 选择题必须有选项
                if (['0', '1'].includes(this.form.answerType) && !this.form.optionList.length) {
                    this.$message({
                        message: '请添加选项',
                        type: 'warning'
                    })
                    return false
                }
                // 选择题所有内容列不能为空
                if (['0', '1'].includes(this.form.answerType)) {
                    const columns = this.optionTableConfig.column.slice(0, -1)
                    for (let optItem of this.form.optionList) {
                        for (let column of columns) {
                            if (!optItem[column.prop || column.slot] && optItem[column.prop || column.slot] !== 0) {
                                const labels = this.optionTableConfig.column
                                    .slice(0, -1)
                                    .map(item => item.label)
                                    .join('、')
                                this.$message.warning(`选项的${labels},均不能为空`)
                                return
                            }
                        }
                    }
                } else {
                    this.form = {...this.form, optionList: []}
                }
                // 通过验证后，后台提交
                /**
                 * @description:获取试题详情(评估量表库)
                 * @param {*}
                 * @return {*}
                 */
                let param = this.QueId
                    ? Object.assign({ scaleInfoId: this.scaleInfoId, id: this.QueId }, this.form)
                    : Object.assign({ scaleInfoId: this.scaleInfoId }, this.form)
                console.log('------add que------', param)
                if (this.libFlag === 'privateLib') {
                    if (this.QueId) { // 评估量表 题目更新
                        updateScaleQuestion(param, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(type)
                        })
                    } else {// 评估量表 题目新增
                        addScaleQuestion(param).then(({ res }) => {
                            this.submitSuccess(type)
                        })
                    }
                } else {
                    if (this.QueId) { // 公共量表 题目更新
                        updateBasicScaleQuestion(param, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(type)
                        })
                    } else { // 公共量表 题目新增
                        addBasicScaleQuestion(param).then(({ res }) => {
                            this.submitSuccess(type)
                        })
                    }
                }
            })
        },
        submitSuccess(type) {
            this.$emit('submit')
            if (type == 2) {
                this.$refs.insertForm.resetFields()
                this.updateVisible(false)
            }
            
            const { $handleSuccess } = this.commonConfig
            this.$message({ ...$handleSuccess })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.optionList = []
                this.validRuleList = []
                this.validRuleCount = ''
                this.validRuleMax = ''
                this.validRuleMin = ''
                this.validRuleTarget = ''
                this.validRuleArray = ''
                this.stepFlag = false
                this.form = {
                    name: '',
                    scaleQuestionTypeId: '',
                    sort: '',
                    answerCoordinate: '',
                    imageData: '', //模板文件名
                    answerType: '0',
                    optionList: [],
                    analyseFlag: '1',
                    typeFlag: '1'
                }
                this.fileList = []
                this.uploadDisabled = ''
                this.$refs.insertForm && this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import '~@/styles/variables.scss';
.imgBox {
    display: flex;
    align-items: flex-start;
}
.QueImage {
    width: 75px;
    height: 75px;
    border: 1px solid $line;
}
</style>
