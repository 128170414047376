<!--
 * @Author: your name
 * @Date: 2022-11-24 09:59:06
 * @LastEditTime: 2022-12-09 15:53:39
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\patient\summary\index.vue
-->
<template>
    <el-main class="wrapper">
        <el-row class="table-btn-group">
            <el-button type="primary" @click="insertSummary()">新增治疗小结</el-button>
        </el-row>
        <div class="summary">
            <el-timeline :reverse="reverse">
                <el-timeline-item
                    v-for="summary in summaryList"
                    :key="summary.id"
                    :timestamp="moment(summary.createDate).format('YYYY-MM-DD')"
                    placement="top"
                >
                    <!-- {{ activity.content }} -->
                    <div class="summary-line">
                        <div class="summary-type">
                            <div>
                                {{ summary.conclusionTypeLabel }}：
                            </div>
                            <div class="summary-btn-group">
                                <i class="el-icon-view" @click="toDetail(summary.id)"></i>
                                <i class="el-icon-edit" @click="toEdit(summary.id)"></i>
                                <i class="el-icon-delete" @click="toDel(summary.id)"></i>
                            </div>
                        </div>

                        <div class="summary-content">
                            {{ summary.conclusionContent }}
                        </div>
                    </div>
                </el-timeline-item>
            </el-timeline>
        </div>
        <add-dialog ref="insert" :id="checkedId" :basicInfoId="basicInfoId" @submit="getList" />
        <detail-dialog ref="detail" :id="checkedId" :basicInfoId="basicInfoId" />
    </el-main>
</template>
<script>
import { getList, deleteData } from '@/api/patientTreatmentConclusion'
import addDialog from '@/views/patient/summary/addDialog'
import detailDialog from '@/views/patient/summary/detailDialog'
export default {
    components: {
        addDialog,
        detailDialog
    },
    props: {
        height: Number,
        basicInfoId: String
    },
    data() {
        return {
            reverse: false,
            checkedId: '',
            summaryList: [],
            timeStamp: ''
        }
    },
    watch:{
       basicInfoId(newVal){
           this.getList()
       } 
    },
    created() {
        this.getList()
    },
    methods: {
        insertSummary() {
            this.checkedId = ''
            this.$refs.insert.updateVisible(true)
        },
        getList() {
            getList({ basicInfoId: this.basicInfoId }).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                this.summaryList = [...res]
            })
        },
        toDetail(id){
            this.checkedId = id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(id){
            this.checkedId = id
            this.$refs.insert.updateVisible(true)
        },
        toDel(id){
           const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                deleteData({id}, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess})
                    this.getList()
                })
            }) 
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.wrapper {
    overflow-y: auto;
    height: 100%;
    padding: 15px 0;
}
.summary {
    height: calc(100% - 46px);
    width: 100%;
    overflow: auto;
    &-line {
        background-color: $background;
        width: calc(100% - 40px);
        padding: 15px;
        border-radius: 8px;
    }
    &-type {
        font-size: 17px;
        color: $titleColor;
        margin-bottom: 0.5em;
        display: flex;
        justify-content: space-between;
    }
    &-content {
        font-size: 13px;
        color: $titleColor;
    }
    &-btn-group {
        color: #469cf8;
        i {
            margin-right: 10px;
            cursor: pointer;
            font-size: 18px;
        }
    } 
}
</style>
