<!--
 * @Author: your name
 * @Date: 2022-01-20 11:05:45
 * @LastEditTime: 2022-02-08 15:13:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\kettle\detailDialog.vue
-->
<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增' : '编辑'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
    <el-form-item label="任务名称" prop="jobName">
      <el-input v-model="form.jobName" />
    </el-form-item>
    <el-form-item label="任务标识" prop="jobKey">
      <el-input v-model="form.jobKey" />
    </el-form-item>
    <el-form-item label="执行状态" prop="runStatus">
      <el-select v-model="form.runStatus" class="filter-item" placeholder="请选择">
        <el-option v-for="item in runStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item label="执行频率" prop="scheduleCron">
      <el-input v-model="form.scheduleCron" />
    </el-form-item>
    <el-form-item label="上次执行时间" prop="lastUpdateDate">
      <el-date-picker
          v-model="form.lastUpdateDate"
          align="right"
          type="datetime"
          clear-icon="el-input__icon"
          placeholder="选择日期"
          :editable="true"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
      />
    </el-form-item>
    <el-form-item label="文件路径" prop="etlPath">
      <el-input v-model="form.etlPath" />
    </el-form-item>
  </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
      <el-button type="primary" @click="submit()">提 交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {getDetail,editData,insertData } from "@/api/kettle";
export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      pickerOptions: this.commonConfig.$datePickerOptions,
      checkId: "",
      tableData: [],
      tableConfig: {},
      form: {},
      total: 0,
      visible: false,
      runStatusOptions:[
          {
          value: '0',
          label: '待执行'
          },
          {
          value: '1',
          label: '执行中'
          },
        ],//执行状态
      rules: {
        jobName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
       jobKey: [
          { required: true, message: "请输入任务标识", trigger: "blur" },
        ],
        runStatus: [
          { required: true, message: "请选择执行状态", trigger: "change" },
        ],
        scheduleCron: [
          { required: true, message: "请输入执行频率", trigger: "blur" },
        ],
        lastUpdateDate: [
          { required: true, message: "请选择上次执行时间", trigger: "blur" },
        ],
        etlPath: [
          { required: true, message: "请输入文件路径", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        const date = this.moment(this.form.lastUpdateDate).format('YYYY-MM-DD')
        const parmObj = Object.assign(this.form,{
          lastUpdateDate: new Date(this.form.lastUpdateDate).getTime(),
        });
        if (valid) {
          if (this.id === "") {
         const parm = Object.assign(parmObj,{scheduleStatus:0},);
            insertData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parmObj, this.timeStamp).then((res) => {
              this.submitSuccess(res)
            })
          }
          this.$emit("closeDialog");
        }
      })
    },
    submitSuccess(res) {
      this.$emit("submit");
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
    },
    getDetail() {
        getDetail({ id: this.id }).then(({res}) => {
          this.form = res
        })
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>