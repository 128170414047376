
<!--
* @Description: 字典管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"
        ><el-button type="primary" @click="toInsert()">新增</el-button></el-row
      >
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="handle" slot-scope="scope">
          <el-button type="text" class="delBtn" @click="toDel(scope)"
            >删除</el-button
          >
        </template>
        <template slot="updateSort" slot-scope="scope">
          <input type="number" :value="scope.data.row.sort" @blur="updateSortById(scope.data.row.id, $event)"/>
        </template>
      </generalTable>
      <addDialog
        :id="checkId"
        ref="insert"
        :timeStamp="timeStamp"
        @submit="search"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getList, getdictTypeList,deleteData,updateSort } from "@/api/dictionaryManagement";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/system/dictionaryManagement/addDialog";

export default {
  name: "DictionaryManagement",
  components: {
    searchForm,
    generalTable,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "select",
          placeholder: "请选择",
          key: "type",
          filterable: true,
          label: "类型",
          options: [],
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "description",
          label: "描述",
          defaultVal: "",
        },
      ],
      tableConfig: {
        id: "dictionaryManagement",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "标签名", // 必填
            prop: "label", // 必填
            width: "200",
          },
          {
            label: "数据值", // 必填
            prop: "value", // 必填
            width: "100",
          },
          {
            label: "类型", // 必填
            prop: "type", // 必填
            width: "220",
          },
          {
            label: "排序", // 必填
            width: "200",
            slot: "updateSort"
          },
          {
            label: "描述", // 必填
            prop: "description", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      typeOption: [], //类型数组
      addButton:true,//新增权限
      deleteButton:true,//删除权限
    };
  },
  created() {
    this.getList();
    this.getDictType();
    this.addButton = this.hasPermission('add-dictionary')
    this.deleteButton = this.hasPermission('delete-dictionary')
  },
  mounted: function () {},
  methods: {
    updateSortById(id, obj){
      updateSort({id,sort:Math.ceil(obj.target.value)}, this.timeStamp).then(({ res }) => {
        this.$message({ ...this.commonConfig.$handleSuccess });
        this.getList();
      });
    },
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
     /**
     * @description: 获取字典类型
     * @param {*}
     * @return {*}
     */
    getDictType() {
      getdictTypeList().then(({ res, timeStamp }) => {
        res.forEach((element) => {
          const ele = {
            value: element,
            label: element,
          };
          this.typeOption.push(ele);
        });
        this.searchOptions[0].options = this.typeOption;
      });
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getList();
        });
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

