
<!--
* @Description: 患者康复治疗统计页面
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" @click="alert('1')" :backStatus="backStatus" :exportStatus="exportStatus" @exportFun="toExport"/>
    </el-header>
    <el-main class="basic-main">
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
        <template slot="treatCount" slot-scope="scope">
             <a class="clickCell" @click="toCreateIncome(scope)">{{scope.data.row.treatCount}}</a>
        </template>
        <template slot="assessmentCount"  slot-scope="scope">
             <a class="clickCell" @click="toAssessmentProject(scope)">{{scope.data.row.assessmentCount}}</a>
        </template>
        </generalTable>
    </el-main>
</el-container>
</template>

<script>

import { getList,toExport } from '@/api/patientRehabilitation'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getDict } from "@/api/common";

export default {
    name: 'patientRehabilitation',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: "select",
                    key: "status",
                    filterable: true,
                    label: "在院状态",
                    options: [],
                    defaultVal: "",
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'date',
                    label: '入院/就诊日期',
                    placeholder: '入院/就诊日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'doctorWorkload',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '患者姓名', // 必填
                    prop: 'basicInfoName' // 必填
                },
                {
                    label: '患者性别', // 必填
                    prop: 'sexLabel' // 必填
                },
                {
                    label: '住院号', // 必填
                    prop: 'recordNum' // 必填
                },
                {
                    label: '就诊号', // 必填
                    prop: 'serialNum' // 必填
                },
                {
                    label: '在院状态', // 必填
                    prop: 'statusLabel' // 必填
                },
                {
                    label: '所属科室', // 必填
                    prop: 'deptName' // 必填
                },
                {
                    label: '入院/就诊日期', // 必填
                    prop: "heDte", // 必填
                    formatter: (row, column) => {
                        return this.moment(row.heDte).format("YYYY-MM-DD");
                    },
                },
                {
                    label: '床号', // 必填
                    prop: 'bedNum' // 必填
                },
                {
                    label: '治疗总数', // 必填
                    slot: 'treatCount' // 必填
                },
                {
                    label: '评估总数', // 必填
                    slot: 'assessmentCount' // 必填
                },
                {
                    label: '营收总金额', // 必填
                    prop: 'price' // 必填
                },
               
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            exportStatus: true, // 导出按钮的隐藏/显示 true显示 false隐藏
            patientOptions:[],//在院状态
            jumpParam: null, //其他页面跳转传过来参数
            searchBtnFlag : false,
            startDate: '', //当前月第一天
            endDate: '', //当前月最后一天
            monthDate: [], //当前月集合
        }
    },
    created() {
        this.getList();
        this.getOptions()
        this.jumpParam = {
          deptId: this.$route.query.deptId,
          status: this.$route.query.status,
          operId: this.$route.query.operId,
          empId: this.$route.query.empId,
          treatDateStartTime: this.$route.query.treatDateStartTime,
          treatDateEndTime: this.$route.query.treatDateEndTime
        }
        if(typeof(this.$route.query.treatDateStartTime) == "undefined"){
            this.getCurrentMonthFirst()
            this.getCurrentMonthLast()
            this.getMonthDate()
        }
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.searchBtnFlag = true;
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1);

        },
        getList() {
            this.$nextTick(() => {
              //跳转时页面参数 + 传递参数
              let param = Object.assign({},this.getParam(), this.jumpParam)
              //点击查询按钮时，页面参数
            //   if (this.searchBtnFlag) {
            //     param = this.getParam();
            //   }
              getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total);
                    this.searchBtnFlag = false;
                })
            })
        },
          //给查询条件赋值为当前月份
          getMonthDate() {
           this.monthDate.push(this.startDate,this.endDate)
            this.searchOptions[2].defaultVal = this.monthDate
            
        },
        //获取当前月份的第一天
        getCurrentMonthFirst(){
                var date = new Date();
                date.setDate(1);
                var month = parseInt(date.getMonth()+1);
                var day = date.getDate();
                if (month < 10) {
                month = '0' + month
                }
                if (day < 10) {
                day = '0' + day
                }
                this.startDate= date.getFullYear() + '-' + month + '-' + day;
        },
       // 获取当前月份的最后一天

            getCurrentMonthLast(){
            var date=new Date();
            var currentMonth=date.getMonth();
            var nextMonth=++currentMonth;
            var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
            var oneDay=1000*60*60*24;
            var lastTime = new Date(nextMonthFirstDay-oneDay);
            var month = parseInt(lastTime.getMonth()+1);
            var day = lastTime.getDate();
            if (month < 10) {
            month = '0' + month
            }
            if (day < 10) {
            day = '0' + day
            }
            this.endDate= date.getFullYear() + '-' + month + '-' + day;
            },
        /**
         * @description: 获取在院状态
         * @param {*}
         * @return {*}
         */
        getPatientStatus() {
            getDict({ type: "patient_basic_status" }).then(({ res }) => {
                this.patientOptions = res.patient_inHos_status.map((item) => {
                    const ele = {
                        value: item.value,
                        label: item.label,
                    };
                    return ele;
                });
                this.searchOptions[1].options = this.patientOptions;
            });
        },
        /**
        　* 跳转评定项目统计
        　* @Description:
          * @Param
          * @Return
        　* @Author wangjh
        　* @Date 2021/12/30 11:41
        　*/
        toAssessmentProject(scope) {
            const query = Object.assign({
                basicInfoId:scope.data.row.basicInfoId,
                archivesInfoId: scope.data.row.archivesInfoId,
                dateStartTime:this.paramObj.dateStartTime,
                dateEndTime:this.paramObj.dateEndTime
            })
            this.$router.push({ path: '/statistics/judgeItems', query: query })
        },

        /**
        　* 跳转治疗统计治疗详情
        　* @Description:
          * @Param    {*} scope
          * @Return
        　* @Author wangjh
        　* @Date 2021/12/30 9:36
        　*/
        toCreateIncome(scope) {
          const query = Object.assign({
            archivesInfoId: scope.data.row.archivesInfoId,
            empId : this.$route.query.empId,
            operId : this.$route.query.operId,
            treatDateStartTime: this.$route.query.treatDateStartTime,
            treatDateEndTime: this.$route.query.treatDateEndTime,
            dateStartTime: this.paramObj.dateStartTime,
            dateEndTime: this.paramObj.dateEndTime
          })
          console.log("**************************",query)
          this.$router.push({ path: '/statistics/therapistWorkload/personal', query: query })
        },

        /**
         * @description:获取在院状态
         * @param {*}patient_basic_status 在院状态
         * @return {*}
         */
        getOptions() {
            getDict({ type: 'patient_basic_status' }).then(({ res, timeStamp }) => {
                this.patientOptions = res.patient_basic_status.map((element) => {
                  return {
                    value: element.value,
                    label: element.label,
                    id: element.id
                  }
                });
                this.searchOptions[1].options = this.patientOptions
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        /**导出
         * @description: 点击搜索组件中的导出按钮触发的函数
         * @param {*}
         * @return {*}
         */        
        toExport(){
            const param = this.getParam()
            toExport(param).then(({ res }) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              var aData = new Date();
              const currentDate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              const fileName = "患者康复治疗情况统计-"+currentDate+".xlsx";
              const linkNode = document.createElement("a");
              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击
    
              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            })
            .catch((err) => {
              console.log(err);
            });
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        

