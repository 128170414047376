/*
 * @Author: your name
 * @Date: 2022-11-22 15:24:41
 * @LastEditTime: 2022-12-08 13:22:36
 * @LastEditors: ZF-WLY
 * @Description: 治疗计划
 * @FilePath: \recovery_mdrcc_4.0\src\api\treatmentPlan.js
 */

import request from '@/utils/request'

/**
 * @description: 获取治疗计划患者列表数据
 */
export function getPatientList(data) {
    return request({
        url: 'patientTreatmentPlan/patientList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取治疗计划列表数据
 */
export function getPlanList(data) {
    return request({
        url: 'patientTreatmentPlan/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取患者诊断及医嘱数据
 */
export function getBasicInfo(data) {
    return request({
        url: 'patientTreatmentPlan/getPatientTreatmentPlanBasicInfo',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取治疗计划详情数据
 */
export function getDetail(data) {
    return request({
        url: 'patientTreatmentPlan/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 导出治疗计划
 */
export function exportPlan(data) {
    return request({
        url: '/patientTreatmentPlan/getListExport',
        method: 'GET',
        params: data,
        responseType: 'blob',
        showLoading: true
    })
}

/**
 * @description: 新增治疗计划
 */
export function insertData(data) {
    return request({
        url: 'patientTreatmentPlan/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗计划
 */
export function editData(data, timeStamp) {
    return request({
        url: 'patientTreatmentPlan/update',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}

/**
 * @description: 删除治疗计划
 */
export function deleteData(data, timeStamp) {
    return request({
        url: 'patientTreatmentPlan/delete',
        method: 'POST',
        data,
        timeStamp,
        showLoading: true
    })
}
