
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增自定义配置' : '修改自定义配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">
            菜单信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="菜单名称(CN)" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="页面名称(EN)" prop="pageName">
                        <el-input
                            v-model="form.pageName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input 
                            type="textarea" 
                            v-model="form.remark"
                            rows="3"
                            placeholder="请输入..."
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData,getDetail } from '@/api/customCollocation'
export default {
    props: {
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    data() {
        return {
            checkId: '',
            rules: {
                name: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                ],
                pageName: [
                    { required: true, message: '请输入页面名称', trigger: 'blur' },
                ],
            },
            options: [
                {
                    value: '1',
                    label: '角色1'
                },
                {
                    value: '2',
                    label: '角色2'
                },
                {
                    value: '3',
                    label: '角色3'
                },
                {
                    value: '4',
                    label: '角色4'
                },
                {
                    value: '5',
                    label: '角色5'
                }
            ],
            form: {},
            visible: false,
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
            //...todo
          }
        },
    },
    created() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                const parm = this.form
                if (this.id === '') {
                    insertData(parm).then(({res}) => {
                        this.submitSuccess(res)
                    })
                } else {
                    editData(parm,this.timeStamp).then(({res}) => {
                        this.submitSuccess(res)
                    })
                }
                }
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.$emit('submit')
            this.updateVisible(false)
        },
        getDetail(){
            getDetail({id:this.id}).then(({res,timeStamp}) => {
                this.form = res
            })
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param,this.timeStamp).then(({res}) => {
                    this.$message({...this.commonConfig.$handleSuccess})
                })
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        