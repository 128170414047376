<!--
 * @Author: your name
 * @Date: 2022-02-16 16:53:46
 * @LastEditTime: 2022-02-23 16:20:18
 * @LastEditors: Please set LastEditors
 * @Description: 影像资料
 * @FilePath: /recovery_management/src/views/scale/patientAssessment/videosData.vue
-->

<template>
    <el-container>
        <el-main class="basic-main">
            <div class="topHeader">
                <span>{{ name }}</span>
                <div class="backBtn">
                    <el-button type="default" @click="back()">返回</el-button>
                </div>
            </div>
            <el-row :gutter="20">
                <el-col :span="6" v-for="item in scaleFileList" :key="item">
                    <el-card :body-style="{ height: '200px', padding: '0px' }">
                        <el-image
                            v-if="processType(item.file.suffix) == 'img'"
                            :src="commonConfig.$fileUrl + item.file.id"
                            :fit="'contain'"
                            :preview-src-list="[
                                commonConfig.$fileUrl + item.file.id,
                            ]"
                        >
                        </el-image>
                        <div
                            class="mediumBox"
                            v-if="processType(item.file.suffix) == 'audio'"
                        >
                            <basicAudio
                                ref="audio"
                                :url="commonConfig.$fileUrl + item.file.id"
                            />
                        </div>
                        <div
                            class="mediumBox"
                            v-if="processType(item.file.suffix) == 'video'"
                        >
                            <video
                                :src="commonConfig.$fileUrl + item.file.id"
                                controls
                                :ref="video"
                                width="100%"
                                height="150px"
                            ></video>
                        </div>
                        <div class="bottom clearfix">
                            <time class="time">{{
                                "上传时间：" +
                                moment(item.createDate).format(
                                    "YYYY-MM-DD HH:MM"
                                )
                            }}</time>
                            <el-button
                                type="text"
                                class="button"
                                @click="downloadClick(item.file)"
                                >下载</el-button
                            >
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import { getAnalysisFile } from "@/api/patientAssessment";
import basicAudio from "@/components/audio";
import { downloadFile } from "@/api/common";

export default {
    props: {
        basicInfoId: {
            type: String,
        },
        scaleInfoId: {
            type: String,
        },
    },
    components: {
        basicAudio,
    },
    data() {
        return {
            name: "",
            scaleFileList: [],
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.basicInfoId,
                    scaleInfoId: this.scaleInfoId,
                };
                getAnalysisFile(param).then(({ res, timeStamp }) => {
                    this.scaleFileList = res.scaleFileList;

                    this.name = res.name;
                    this.timeStamp = timeStamp;
                });
            });
        },
        /**
         * @description: 处理类型数据
         * @param  {String} type
         * @return {String} typeName 返回类型名称
         */
        processType(type) {
            const { $docTypes, $imgTypes, $audioTypes, $videoTypes } =
                this.commonConfig;
            let typeName = "";
            if ($docTypes.includes(type.toLowerCase())) {
                typeName = "pdf";
            } else if ($imgTypes.includes(type.toLowerCase())) {
                typeName = "img";
            } else if ($audioTypes.includes(type.toLowerCase())) {
                typeName = "audio";
            } else if ($videoTypes.includes(type.toLowerCase())) {
                typeName = "video";
            }
            return typeName;
        },
        /**
         * @description: 下载
         * @param {*}
         * @return {*}
         */
        downloadClick(file) {
            const parm = { id: file.id };
            downloadFile(parm).then(({ res }) => {
                const dom = document.createElement("a");
                dom.style.display = "none";
                const blob = new Blob([res], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                dom.setAttribute("download", file.name + "." + file.suffix);
                document.body.appendChild(dom);
                dom.click();
            });
        },

        /**
         * @description: 返回
         * @param {*}
         * @return {*}
         */
        back() {
            this.$emit("popData", 0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backBtn {
    display: flex;
    justify-content: right;
    margin: 4px;
}

.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}
.time {
    font-size: 13px;
    color: #999;
}

.bottom {
    line-height: 12px;
    padding: 15px;
}

.button {
    padding: 0;
    float: right;
}

.mediumBox {
    width: 100%;
    height: 150px;
}
.el-image {
    width: 100%;
    height: 150px;
}
video::-webkit-media-controls-timeline {
    display: none;
}
</style>