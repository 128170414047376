<template>
    <el-dialog
        width="50%"
        title="量表/方案基础信息配置"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <generalTable ref="mainTable" :table-data="tableData" :checkIds="choose" :config="tableConfig">
            <template slot="name" slot-scope="scope">
                <div @click.stop>
                    <el-input v-model="scope.data.row.name" placeholder="请输入名称..." clearable></el-input>
                </div>
            </template>
            <template slot="sort" slot-scope="scope">
                <div @click.stop>
                    <el-input-number v-model="scope.data.row.sort" clearable :max="9999" :min="1" />
                </div>
            </template>
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getPatientPackage, savePatientPackage } from '@/api/common'
import generalTable from '@/components/Table'
export default {
    name: 'AssConfig',
    components: {
        generalTable
    },
    data() {
        return {
            visible: false,
            choose: [], //选中的数据
            tableData: [],
            tableConfig: {
                id: 'assessment',
                selection: 'multiple',
                index: true,
                pagination: false,
                saveSelected: true,
                column: [
                    {
                        label: '名称', // 必填
                        slot: 'name', // 必填
                        width: '180'
                    },
                    {
                        label: '值',
                        prop: 'keyField'
                    },
                    {
                        label: '排序',
                        slot: 'sort',
                        width: '180'
                    }
                ]
            }
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.getPatientPackage()
            } else {
                this.choose = []
                this.tableData = []
            }
        }
    },
    methods: {
        /**
         * @description: 获取全局评估基础信息数据
         */
        getPatientPackage() {
            getPatientPackage(0).then(res => {
                const { all, choose } = res.res
                const chooseKeys = choose.sort((a,b) => a.sort-b.sort).map(item => item.keyField)
                const unchoose = all
                    .filter(item => !chooseKeys.includes(item.value))
                    .map(ele => {
                        return {
                            name: ele.label,
                            keyField: ele.value,
                            id: ele.value,
                            sort: ele.sort || 1
                        }
                    })
                let chooseArray = choose.map(item => {
                    return {
                        ...item,
                        id: item.keyField
                    }
                })
                chooseArray.sort((a, b) => Number(a.sort) - Number(b.sort))
                // 选中项在前，未选中项在后
                this.tableData = [...chooseArray, ...unchoose]
                // 选中项id数组
                this.choose = [...chooseKeys]
            })
        },
        submit() {
            const param = this.packageParam()
            if (param instanceof Array) {
                savePatientPackage({ type: 0, data: param }).then(({ res }) => {
                    this.submitSuccess()
                })
            }
        },
        submitSuccess() {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        /**
         * @description: 封装请求数据
         * @return {Object} 返回封装好的请求数据
         */

        packageParam() {
            let param = this.$refs.mainTable.getChecked()
            if (param.length === 0) {
                this.$message({
                    message: `请至少选择一项`,
                    type: 'warning'
                })
                return false
            }
            return param
        },
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.basic-main {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
