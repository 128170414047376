
<template>
    <el-dialog
        width="70%"
        :title="id ? '修改收费项目' : '新增收费项目'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
          
            <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="收费名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="请输入"
                    clearable
                    maxlength="64"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="单次收费金额(元)" prop="price">
                <el-input
                    v-model="form.price"
                    placeholder="请输入"
                    clearable
                    maxlength="8"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                    oninput="this.value=this.value.replace(/[^\d.]/g,'')"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="医保级别" prop="level">
                <el-input
                    v-model="form.level"
                    placeholder="请输入"
                    clearable
                    maxlength="8"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="自付百分比(%)" prop="percent">
                <el-input
                    v-model="form.percent"
                    placeholder="请输入"
                    clearable
                    maxlength="8"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                    oninput="this.value=this.value.replace(/[^\d.]/g,'')"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="物价标准编码" prop="standCode">
                <el-input
                    v-model="form.standCode"
                    placeholder="请输入"
                    clearable
                    maxlength="8"
                />
                </el-form-item>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="是否物价标准" prop="select">
                <el-select
                    v-model="form.stand"
                    clearable
                    placeholder="请选择"
                    :filterable="true"
                >
                    <el-option
                    v-for="stands in stand"
                    :key="stands.value"
                    :label="stands.label"
                    :value="stands.value"
                    />
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :sm="192" :md="120" :lg="880" :xl="666">
                <el-form-item label="备注" prop="remark">
                <el-input
                    v-model="form.remark"
                    placeholder="请输入"
                    clearable
                    type="textarea"
                    :rows="3"
                    resize="none"
                    maxlength="200"
                />
                </el-form-item>
            </el-col>
            </el-row>
            <el-divider />
           
          
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData ,getDetail} from '@/api/feeItems'
import { getDict } from '@/api/common'

export default {
    props: {
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    updated(){
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入收费名称', trigger: 'blur' }
                ],
                 price: [
                    { required: true, message: '请输入单次收费金额(元)', trigger: 'blur' }
                ]
            },
            form: {
                name: "",
                price: "",
                level: "",
                percent: "",
                standCode: "",
                select: "",
                remark: "",
                id: "",
                stand:"0",
            },
             stand: [],
            visible: false,
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                    if (this.id) {
                        this.getDetail()
                    }
            }
        }
    },
    created() {
        this.getStand();
    },
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = {...this.form}
                    if (!this.id) {
                        insertData(parm).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        parm.id=this.id
                        editData(parm,this.timeStamp).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({
                showClose: true,
                message: '操作成功',
                type: 'success'
            })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getDetail(){
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getDetail(param).then(({res,timeStamp}) => {
                const {id, ...temp} = res
                this.form = temp
            })
        },
        
     /**
     　* 获取是否物价标准
     　* @Description: 从字典表中获取是否物价标准状态（是0/否1），字典表启用状态类型为 prices_stand
       * @Param null
       * @Return null
     　* @Author wangz
     　* @Date 2021/11/18 16:34
     　*/
        getStand() {
          getDict({ type: 'prices_stand' }).then(({res}) => {
            this.stand = res.prices_stand;
          });
        },
        updateVisible(val) {
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.form = {
                    name: "",
                    price: "",
                    level: "",
                    percent: "",
                    standCode: "",
                    select: "",
                    remark: "",
                    id: "",
                    stand:"0",
                }
            }
            this.visible = val
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
      
        