<!--
 * @Author: your name
 * @Date: 2022-02-16 16:52:07
 * @LastEditTime: 2023-04-17 10:56:40
 * @LastEditors: mazihao mazihao@newpay.la
 * @Description: 趋势分析
 * @FilePath: /recovery_management/src/views/scale/patientAssessment/trendAnalysis.vue
-->

<template>
    <el-container>
        <el-main class="basic-main">
            <el-row :gutter="20">
                <template v-if="words == 1">
                    <el-col :span="8">
                        <generalTable
                            ref="mainTable"
                            :table-data="tableData"
                            :config="tableConfig"
                            @updatePage="getList"
                        >
                        </generalTable>
                    </el-col>
                    <el-col :span="16">
                        <div class="topHeader">
                            <div>
                                <el-button type="primary" @click="lineClick()">
                                    折线图
                                </el-button>
                                <el-button
                                    type="primary"
                                    @click="histogramClick()"
                                >
                                    柱状堆叠图
                                </el-button>
                            </div>
                            <div class="backBtn">
                                <el-button type="default" @click="back()"
                                    >返回</el-button
                                >
                            </div>
                        </div>
                        <div id="echarts" class="echartsBox"></div>
                    </el-col>
                </template>
                <template v-else>
                    <div class="topHeader">
                        <div>
                            <span>{{ name }}</span>
                        </div>
                        <div class="backBtn">
                            <el-button type="default" @click="back()"
                                >返回</el-button
                            >
                        </div>
                    </div>
                    <el-col>
                        <generalTable
                            ref="mainTable"
                            :table-data="tableData"
                            :config="tableConfig_noEcharts"
                            @updatePage="getList"
                        >
                        </generalTable>
                    </el-col>
                </template>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import generalTable from "@/components/Table";
import { getTrendAnalysis } from "@/api/patientAssessment";
import * as echarts from "echarts";

export default {
    props: {
        basicInfoId: {
            type: String,
        },
        scaleInfoId: {
            type: String,
        },
    },
    components: {
        generalTable,
    },
    watch: {
        // scaleInfoId(newVal, oldVal) {
        //     console.log("scaleInfoId========", this.scaleInfoId);
        //     this.getList();
        // },
    },
    created() {
        //   console.log('created')
        this.getList();
    },
    data() {
        return {
            name: "",
            tableData: [],
            tableConfig: {
                id: "trendAnalysis",
                align: "center", // 不必填 默认为center
                selection: "", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "评定日期", // 必填
                        prop: "evaluateDate", // 必填
                        formatter: (row, column) => {
                            return row.evaluateDate
                                ? this.moment(row.evaluateDate).format(
                                      "YYYY-MM-DD"
                                  )
                                : "--";
                        },
                    },
                    {
                        label: "评估人", // 必填
                        prop: "evaluateByName", // 必填
                    },
                    {
                        label: "结果", // 必填
                        prop: "fullMarks", // 必填
                    },
                ],
            },
            tableConfig_noEcharts: {
                id: "trendAnalysis",
                align: "center", // 不必填 默认为center
                selection: "", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "评定日期", // 必填
                        prop: "evaluateDate", // 必填
                        formatter: (row, column) => {
                            return row.evaluateDate
                                ? this.moment(row.evaluateDate).format(
                                      "YYYY-MM-DD"
                                  )
                                : "--";
                        },
                    },
                    {
                        label: "评估人", // 必填
                        prop: "evaluateByName", // 必填
                    },
                    {
                        label: "结果", // 必填
                        prop: "evaluationResults", // 必填
                    },
                ],
            },
            echartsType: "bar", //柱状图  line 折线图
            words: "", //得分
        };
    },

    methods: {
        getList() {
            this.$nextTick(() => {
                const param = {
                    basicInfoId: this.basicInfoId,
                    scaleInfoId: this.scaleInfoId,
                };
                getTrendAnalysis(param).then(({ res, timeStamp }) => {
                    this.tableData = res.scaleList;
                    this.name = res.name;
                    this.words = res.words;
                    this.timeStamp = timeStamp;
                    this.$nextTick(() => {
                        if (res.words == "1") {
                            this.echarts_histogram();
                        }
                    });
                });
            });
        },

        /**
         * @description: 柱状图echarts
         * @param {*}
         * @return {*}
         */
        echarts_histogram() {
            let myChart = echarts.init(document.getElementById("echarts"));

            let title = [];
            let data = [];
            this.tableData.map((item) => {
                title.push(this.moment(item.evaluateDate).format("YYYY-MM-DD"));
                data.push(item.fullMarks);
            });
            // 指定图表的配置项和数据
            let option = {
                title: {
                    text: this.name,
                },
                tooltip: {},
                legend: {
                    data: ["评估结果"],
                },
                xAxis: {
                    data: title,
                },
                yAxis: {},
                series: [
                    {
                        name: "评估结果",
                        type: this.echartsType,
                        data: data,
                        barWidth: 30,
                    },
                ],
            };
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },

        /**
         * @description: 折线图
         * @param {*}
         * @return {*}
         */
        lineClick() {
            this.echartsType = "line";
            this.echarts_histogram();
        },

        /**
         * @description: 柱状图
         * @param {*}
         * @return {*}
         */
        histogramClick() {
            this.echartsType = "bar";
            this.echarts_histogram();
        },

        back() {
            this.$emit("popData", 0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backBtn {
    display: flex;
    justify-content: right;
    margin: 4px;
}
.echartsBox {
    margin: 20px;
    height: 600px;
    width: 100%;
}
</style>