
<!--
* @Description: 患者评定统计详情页面
* @Reference:
-->

<template>
    <el-container>
       
        <el-main class="basic-main">
            <el-form class="searchForm" :inline="true">
                <div class="search-btn-group">
                    <el-form-item>      
                    </el-form-item>
                <el-form-item>
                    <el-button type="default" @click="back()">返回</el-button>
                </el-form-item>
                </div>
            </el-form>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="toPatientDetail" @onClickRow="onClickRow">
            </generalTable>
        </el-main>
    </el-container>
    </template>
    
    <script>
    import { toPatientDetail, deleteData } from '@/api/patientJudge'
    import generalTable from '@/components/Table'
    
    export default {
        name: 'PatientJudge',
        components: {
            generalTable,
        },
        data() {
            return {
                tableData: [],
                searchOptions: [
                ],
                tableConfig: {
                    id: 'patientJudge',
                    align: 'center', // 不必填 默认为center
                    selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                    index: true, // 不必填 序号
                    pagination: true, // 不必填 分页 默认为false
                    column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'paName' // 必填
                    },
                    {
                        label: '评定类型', // 必填
                        prop: 'pdType' // 必填
                    },
                    {
                        label: '评定名称', // 必填
                        prop: 'pdName' // 必填
                    },
                    {
                        label: '评定医生', // 必填
                        prop: 'pdDoctor' // 必填
                    }
                    ]
                },
                paramObj: null, // 筛选项
                total: 0, // 总条数
                checkId: '', // 单条数据id 用于详情或编辑请求数据用
                backStatus: true, //返回按钮的隐藏显示
                timeStamp: '', //时间戳 用于修改数据或删除数据
                pbId: '',
                evaluateDateStartTime: '',
                evaluateDateEndTime: '',
                doctorId: '',
            }
        },
        created() {
            this.toPatientDetail()
            this.pbId=this.$route.query.pbId
            this.evaluateDateStartTime=this.$route.query.evaluateDateStartTime
            this.evaluateDateEndTime=this.$route.query.evaluateDateEndTime
            this.doctorId=this.$route.query.doctorId
        },
        mounted: function() {},
        methods: {
            search(data) {
                Object.assign(this.paramObj, data)
                this.$refs.mainTable.handleCurrentChange(1)
            },
            toPatientDetail() {
                this.$nextTick(() => {
                    const param = this.getParam()
                    toPatientDetail(param).then(({ res, timeStamp }) => {
                        this.tableData = res.records
                        this.total = parseInt(res.total)
                        this.timeStamp = timeStamp
                    })
                })
            },
            back() {
                this.$router.back()
            },
            getParam() {
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page,  { paId: this.pbId },{evaluateDateStartTime: this.evaluateDateStartTime},
                   { evaluateDateEndTime: this.evaluateDateEndTime },{ doctorId: this.doctorId})
            this.paramObj = param
            return param
        },
            onClickRow(row){
                console.log('点击行:',row)
            }
        }
    }
    </script>
    
    <style lang='scss' scoped>
 
    @import "~@/styles/variables.scss";
    .search-btn-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    </style>
    
            