/*
 * @Author: your name
 * @Date: 2022-12-06 13:48:37
 * @LastEditTime: 2023-02-28 11:24:55
 * @LastEditors: wangly wly574523605@163.com
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\caseReportTree.js
 */

import request from '@/utils/request'

/**
 * @description: 病历夹树接口
 * @param {Object} data
 */
export function getPatientTree(data) {
    return request({
        url: '/casesCollectPatientTree/getPatientTree',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 获取患者病例模板基础数据
 * @param {Object} data
 */
export function getPatientTemplateBasicData(data) {
    return request({
        url: '/casesCollectPatientTree/getPatientTemplateBasicData',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 更新病例模板
 * @param {Object} data
 */
export function updateCasesTemplate(data) {
    return request({
        url: 'casesCollectPatientTree/updateCasesTemplate',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 更新病例标签
 * @param {Object} data
 */
export function updateInputData(data) {
    return request({
        url: 'casesCollectPatientTree/updatePatientInputData',
        method: 'POST',
        data,
        showLoading: true,
    })
}

/**
 * @description: 清除患者病历夹
 * @param {Object} data
 */
export function clearPatientCases(data) {
    return request({
        url: '/casesCollectPatientTree/clearPatientCases',
        method: 'POST',
        data,
        showLoading: true,
    })
}