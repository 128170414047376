<!--
 * @Description: 角色管理
 * @Author: Richardlv
 * @Date: 2021-02-03 10:49:05
 * @LastEditTime: 2022-03-10 14:59:22
 * @LastEditors: Please set LastEditors
 * @Reference:
-->

<template>
    <el-scrollbar style="height: 100%; width: 100%">
        <el-container>
            <el-header height="auto">
                <searchForm ref="mainSearch" :searchoptions="searchOptions" :back-status="true" @search="getTableData" />
            </el-header>
            <el-main class="basic-main">
				<el-row class="table-btn-group">
					<el-button type="primary" v-if="addStatus" @click="toInsert()">新增</el-button>
				</el-row>
                <generalTable
                    :table-data="tableData"
                    :config="tableConfig"
					:total="2"
                >
					<template slot="name" slot-scope="scope">
						<span
							class="clickCell"
							@click="nameClick(scope.data.row)"
						>
							{{ scope.data.row.name }}
						</span>
					</template>
					<template slot="switch" slot-scope="scope">
						<el-switch
							v-model="scope.data.row.status"
							active-value="0"
							inactive-value="1"
                            :disabled="scope.data.row.id === '1' || !characterStatus"
							@change="setStatus(scope.data.row)"
						/>
					</template>
                    <template slot="handle" slot-scope="scope">
                        <el-button
                            type="text"
                            v-if="editStatus"
                            @click="toEdit(1, scope.data.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            v-if="rolesStatus"
                            @click="toEdit(2, scope.data.row)"
                            >权限</el-button
                        >
                        <el-button
                            type="text"
                            v-if="apportionStatus"
                            @click="toaddUser(scope.data.row)"
                            >分配用户</el-button
                        >
                        <el-button type="text" v-if="delStatus" class="delBtn" @click="toDel(scope.data.row.id)">删除</el-button>
                    </template>
                </generalTable>
            </el-main>
            <insertDialog
                v-if="insertFlag"
                :detail-data="detailData"
                :insert-flag="insertFlag"
                :edit-flag="editFlag"
                :old-checked-menu="oldCheckedMenu"
                @closeDialog="closeDialog"
                @changeInsertFlag="changeInsertFlag"
                @getTableData="getTableData"
                :time-stamp="timeStamp"
                :menuData="menuData"
                :orgTableData="orgTableData"
            />
            <addUserDialog
                :detail-data="detailData"
                :add-user-flag="addUserFlag"
                @changeAddUserFlag="changeAddUserFlag"
            />
            <detailDialog
                :v-if="detailFlag"
                :detail-data="detailData"
                :detail-flag="detailFlag"
                :old-checked-menu="oldCheckedMenu"
                :menuData="menuData"
                :orgTableData="orgTableData"
                @changedetailFlag="changedetailFlag"
            />
        </el-container>
    </el-scrollbar>
</template>

<script>
import { roleList, roleDisable, getAclList, roleDel,getRolePermissions } from "@/api/character";
import { getList } from "@/api/menu";
import { getOrgUserList  } from "@/api/organization"; // 获取组织机构列表
import _ from "lodash";
import moment from "moment";
import insertDialog from "@/views/system/character/insertDialog";
import addUserDialog from "@/views/system/character/addUserDialog";
import detailDialog from "@/views/system/character/detailDialog";
import generalTable from '@/components/Table'
import searchForm from '@/components/SearchForm'
export default {
    name: "Character",
    components: {
        insertDialog,
        addUserDialog,
        detailDialog,
		generalTable,
		searchForm,
    },
    data() {
        return {
			searchOptions: [
                {
                    type: 'input',
                    key: 'name',
                    label: '角色名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    key: 'roleEncoding',
                    label: '角色编码',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'permissionType',
                    filterable: true,
                    label: '权限类型',
                    options: [
                        {
							value: "1",
							label: "功能权限",
						},
						{
							value: "2",
							label: "数据权限",
						},
                    ],
                    defaultVal: ''
                },
				{
                    type: 'select',
                    key: 'status',
                    filterable: true,
                    label: '状态',
                    options: [
                        {
							value: "0",
							label: "启用",
						},
						{
							value: "1",
							label: "禁用",
						},
                    ],
                    defaultVal: ''
                }
            ],

            searchForm: {
                permissionType: "",
                status: "",
                name: "",
                roleEncoding: "",
            },
            tableData: [],
            permissionTypeList: ["", "功能权限", "数据权限"],
            insertFlag: false,
            detailData: {
                name: "",
                permissionType: "",
                remark: "",
                roleEncoding: "",
                id: "",
            },
            oldCheckedMenu: [],
            oldCheckedOrg:[],
            allFlag:"0",//全部权限
            dept:"1",//本科室
            self:"1",//本人
            editFlag: 0, // 0-新增 1-编辑 2-权限
            addUserFlag: false,
            detailFlag: false,
            tableConfig: {
                id: "user",
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                column: [
                    {
                        label: "角色名称", // 必填
                        slot: "name", // 不必填 单列插槽
                    },
					{
                        label: "角色编码", // 必填
                        prop: "roleEncoding",
                    },
                    {
                        label: "权限类型", // 必填
                        prop: "permissionType",
                        formatter: (row, column) => {
                            return this.permissionTypeList[row.permissionType];
                        },
                    },
                    {
                        label: "用户数量", // 必填
                        prop: "userCount",
                    },
					{
                        slot: "switch", // 不必填 单列插槽
                        label: "禁用/启用", // 必填
                    },
                    {
                        label: "创建时间", // 必填
                        prop: "createDate", // 必填
						width: "180", // 不必填
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "260", // 不必填
                    },
                ],
            },
            timeStamp:"",
            addStatus: true, // 新增按钮权限
            editStatus: true, // 修改按钮权限
            delStatus: true, // 删除按钮权限
            rolesStatus: true, // 分配权限按钮权限
            apportionStatus: true, // 分配用户按钮权限
            characterStatus: true, // 启用/禁用按钮权限
            menuData:[],       //菜单数据
            orgTableData:[],   //组织架构数据
        };
    },
    created() {
        this.addStatus = this.hasPermission('character-add')
        this.editStatus = this.hasPermission('character-edit')
        this.delStatus = this.hasPermission('character-del')
        this.rolesStatus = this.hasPermission('character-roles')
        this.apportionStatus = this.hasPermission('character-apportion')
        this.characterStatus = this.hasPermission('character-status')
        this.getTableData();
    },
    mounted() {
        this.getMenuData(); //获取菜单
        this.getOrg();      //获取组织架构
    },
    methods: {
        nameClick(item) {       //点击角色打开详情
            let detailDataCrash = {};
            detailDataCrash = _.cloneDeep(item);
            this.detailData = detailDataCrash;
            const parm = { roleId: item.id };
            this.oldCheckedMenu = [];
            this.oldCheckedOrg = [];
            getAclList(parm).then(({res}) => {
                res.map((item,index)=>{
                        this.getChildId(item)
                    })
                setTimeout(() => {
                    this.detailFlag = true;
                }, 100);
            });
        },
        changeAddUserFlag() {
            this.addUserFlag = false;
            this.getTableData();
        },
        changeInsertFlag() {
            this.insertFlag = false;
        },
        changedetailFlag() {
            this.detailFlag = false;
        },
        toaddUser(item) {
            this.detailData = item;
            this.addUserFlag = true;
        },
        toDel(id) {
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const parm = { id: id };
                roleDel(parm,this.timeStamp).then(({res}) => {
                    this.$message({...$handleSuccess});
                    this.insertFlag = false;
                    this.getTableData();
                });
            })
        },
        closeDialog() {
            this.oldCheckedMenu = [];
            this.oldCheckedOrg = [];
            this.detailData = {
                name: "",
                permissionType: "",
                remark: "",
                roleEncoding: "",
                id: "",
            };
        },
        toEdit(editFlag, item) {        
            this.editFlag = editFlag;
            let detailDataCrash = {};
            detailDataCrash = _.cloneDeep(item);
            this.detailData = detailDataCrash;
            
            // eslint-disable-next-line eqeqeq
            if (editFlag == 2) {
                const parm = { roleId: item.id };
                this.oldCheckedMenu = [];
                this.oldCheckedOrg = [];
                getAclList(parm).then(({res}) => {
                    console.log(res,'res========================')
                    res.map((item,index)=>{
                        this.getChildId(item)
                    })
                    setTimeout(() => {
                        this.insertFlag = true;
                    }, 10);
                });
                // getRolePermissions(parm).then(({res}) => {
                //     // res.showTree.map((item,index)=>{
                //     //     this.getChildIdOrg(item)
                //     // })
                //     this.oldCheckedOrg = res.selectDept.split(',')
                //     this.allFlag = res.allFlag;
                //     this.dept =res.dept;
                //     this.self = res.self;
                //     setTimeout(() => {
                //         this.insertFlag = true;
                //     }, 10);
                // });
            }else{
                this.insertFlag = true;
            }
        },
        getChildId(data){
            if(data.children&&data.children.length>0){
                data.children.map((item,index)=>{
                    this.getChildId(item)
                })
            }else{
                let oldCheckedMenuCache = this.oldCheckedMenu
                oldCheckedMenuCache.push(data.id)
                this.oldCheckedMenu = oldCheckedMenuCache
            }
        },
        // getChildIdOrg(data){
        //     if(data.children&&data.children.length>0){
        //         data.children.map((item,index)=>{
        //             this.getChildIdOrg(item)
        //         })
        //     }else{
        //         let oldCheckedMenuCache = this.oldCheckedOrg
        //         oldCheckedMenuCache.push(data.id)
        //         this.oldCheckedOrg = oldCheckedMenuCache
        //     }
        // },
        setStatus(item) {       //禁用/启用角色 id:角色ID status:禁用/启用状态
            const parm = {
                id: item.id,
                status: item.status,
            };
            roleDisable(parm,this.timeStamp).then(({res}) => {
                this.$message({...this.commonConfig.$handleSuccess});
            });
        },
        getTableData() {
            const param = this.$refs.mainSearch?this.$refs.mainSearch.packageData():this.searchForm;
            roleList(param).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp;
                console.log(res);
                const { records } = res;
                const recordsCrash = records.map((item, index) => {
                    item.createDate = moment(item.createDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                    return item;
                });
                this.tableData = recordsCrash;
            });
        },
        toInsert() {
            this.detailData = {
                name: "",
                permissionType: "",
                remark: "",
                roleEncoding: "",
                id: "",
            },
            this.insertFlag = true;
            this.editFlag = 0;
            this.oldCheckedMenu = [];
            this.oldCheckedOrg = [];//权限数组
        },

        /**
         * @description: 获取菜单管理列表
         * @param {*}
         * @return {*}
         */        
        getMenuData() {
            getList().then(({ res }) => {
                this.menuData = res;
            });
        },
        /**
         * 获取组织机构数据
         * @description: 从后台获取组织机构列表，获取完成后将数据绑定至tableData
         * @param {*}
         * @return {*}
         */
        getOrg() {
            getOrgUserList().then(({ res, timeStamp }) => {
                this.orgTableData = res;
            });
        },
        back() {
            this.$router.back()
        },
    },
};
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";
.clickCell {
    color: $theme;
    cursor: pointer;
}
.el-dialog__body {
    padding: 16px 20px;
}
.el-form-item {
    margin-bottom: 15px;
}
</style>
