<!--
 * @Description: 治疗工作站
 * @Author: yzw
 * @Date: 2021-02-03 10:49:05
 * @LastEditTime: 2023-04-17 10:55:07
 * @LastEditors: mazihao mazihao@newpay.la
 * @Reference:
-->

<template>
  <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from "vuex";
import MenuBox from "@/components/MenuBox";
export default {
  name: "demo",
  components: { MenuBox },
  data() {
    return {
      secondMenu: [],
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  created() {
    const { roles } = this.$store.getters;
    for (let i = 0; i < roles.length; i++) {
      const element = roles[i];
      if (element.name === "demoMain") {
        this.secondMenu = element.children.filter(
          (obj) =>
            obj.type === "1" &&
            obj.hidden === false &&
            element.redirect !== obj.path
        );
      }
    }
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
