<!--
 * @Author: your name
 * @Date: 2022-01-14 16:03:22
 * @LastEditTime: 2022-01-19 11:21:25
 * @LastEditors: ZF-WLY
 * @Description: 按模板汇总组件
 * @FilePath: \recovery_management\src\views\scale\summary\index.vue
-->
<template>
    <el-row class="scale-html-wrapper">
        <!-- scaleHtml为html内容 -->
        <div :id="id" v-html="scaleHtml"></div>
    </el-row>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'scaleIndex',
    props: {
        // 量表id
        id: {
            type: String
        },
        // 模板信息
        info: {
            type: Object
        }
    },
    components: {},
    data() {
        return {
            queList: [], // 问题列表及选项
            scaleHtml: '', // 页面html内容
            answerList: [], // 答案列表
        }
    },
    watch: {
        /** 监听this.info内容
         * @description: info发生改变时,重新获取信息
         * @param {Object} newVal 新info
         * @param {Object} oldVal 旧info
         * @return {*}
         */        
        info(newVal, oldVal) {
            this.getInfo(newVal)
        },
        /**监听this.id内容
         * @description: id发生改变且新内容不为空时,重新获取信息
         * @param {String} newVal 新id
         * @param {String} oldVal 旧id
         * @return {*}
         */        
        id(newVal, oldVal) {
            if (newVal) {
                this.getInfo(this.info)
            }
        }
    },
    /** 页面创建
     * @description: 页面创建时,根据info获取模板信息
     * @param {*}
     * @return {*}
     */    
    created() {
        this.getInfo(this.info)
    },
    mounted: function() {},
    methods: {
        /** 获取模板信息
         * @description: 获取模板及答案列表,页面渲染结束后,回填答案
         * @param {*} info
         * @return {*}
         */        
        getInfo(info) {
            const { scaleHtml, answerList } = info
            this.scaleHtml = scaleHtml
            this.answerList = answerList
            this.$nextTick(() => {
                this.fillAnswer()
            })
        },
        /** 回填答案
         * @description: 问答及小结,直接回填结果,并设置只读;单选及多选,进行回选并阻止用户点击;单选用radio,多选用checkbox
         * @param {*}
         * @return {*}
         */        
        fillAnswer() {
            const {questionList} = this.info
            this.answerList.map((item, index) => {
                //answerType--> "0":单选;"1":多选;"2":问答;"3":小结
                const {answerType, answerCoordinate, scaleQuestionOptionId} = item
                if (["0", "1"].includes(answerType)) {// 单选&多选
                    let currentQues = questionList.filter(ques => ques.answerCoordinate === answerCoordinate)
                    let options = []
                    if (currentQues.length && scaleQuestionOptionId) {
                        const selectIds = scaleQuestionOptionId.split(",")
                        options = currentQues[0].optionList
                        options.forEach((element, index) => {
                            const NUM = (index + 1) + ""
                            if (selectIds.includes(element.id)) {
                                this.scaleHtml = this.scaleHtml.replace(
                                    `[${item.answerCoordinate}_${NUM}]`,
                                    // "1":checkbox "0":radio
                                    `<input type='${answerType === "1" ? "checkbox" : "radio"}' name='${this.id}${item.answerCoordinate}' checked="checked" onclick="return false" />`
                                )
                            }
                        });
                    }
                    const regex = new RegExp(`\\[${item.answerCoordinate}_\\d\]`, 'g')
                    this.scaleHtml = this.scaleHtml.replaceAll(
                        regex,
                        // "1":checkbox "0":radio
                        `<input type='${answerType === "1" ? "checkbox" : "radio"}' name='${this.id}${item.answerCoordinate}' onclick="return false" />`
                    )
                } else {// 问答&小结
                    this.scaleHtml = this.scaleHtml.replace(`[${item.answerCoordinate}]`, item.answerContent)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
<style>
.scale-html-wrapper input[type="checkbox"],
.scale-html-wrapper input[type="radio"] {
   cursor: not-allowed;         
}
</style>
