<!--
 * @Author: your name
 * @Date: 2022-11-22 15:38:37
 * @LastEditTime: 2022-11-23 16:46:13
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\treatmentPlan\index.vue
-->
<template>
    <el-container ref="container">
        <el-tabs type="border-card" style="width: 100%" @tab-click="tabClick">
            <el-tab-pane v-if="hasPermission('plan-my-patient')" :lazy="true" label="我的患者">
                <myPatientList v-if="tableShowFlag == 0" :tableHeight="containerHeight" :selectFlag="0" />
            </el-tab-pane>
            <el-tab-pane v-if="hasPermission('plan-all-patient')" :lazy="true" label="全部患者">
                <allPatientList v-if="tableShowFlag == 1" :tableHeight="containerHeight" :selectFlag="1" />
            </el-tab-pane>
        </el-tabs>
    </el-container>
</template>
<script>
import myPatientList from "@/views/treatment/treatmentPlan/patientList";
import allPatientList from "@/views/treatment/treatmentPlan/patientList";

export default {
  name: "patientPlan",
  components: {
    myPatientList,
    allPatientList
  },
  data() {
    return {
      status: 1,
      containerHeight: 0,
      tableShowFlag:0,
    };
  },
  created() {
    this.getContainerHeight();
  },
  mounted: function() {},
  methods: {
    tabClick(data){
      this.tableShowFlag = data.index
    },
    /**
     * @description:通过手动获取container高度,再删减部分高度,赋值给表格组件, 否则会出现污染高度情况
     */
    getContainerHeight() {
      this.$nextTick(() => {
        this.containerHeight = this.$refs.container.$el.clientHeight - 16 - 150;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>