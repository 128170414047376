<!--
 * @Author: your name
 * @Date: 2021-10-20 09:57:32
 * @LastEditTime: 2021-12-29 13:31:14
 * @LastEditors: yzw
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\system\menu\detailDialog.vue
-->

<template>
  <el-dialog
    width="60%"
    title="菜单管理详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="菜单信息" :column="4" >
      <el-descriptions-item label="路由标题(CN)">{{ detail.name || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="页面名称(EN)">{{ detail.pageName || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="组件(import)">{{ detail.component || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="类型">{{ detail.type || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="上级目录">{{ detail.parentsLable || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="跳转路径(path)">{{ detail.path || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="权限标识">{{ detail.permission || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="排序">{{ detail.sort || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="图标">{{ detail.icon || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="是否缓存">{{ detail.keepAlive || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="是否隐藏">{{ detail.hidden || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="面包屑导航">{{ detail.breadcrumb || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{ detail.status || commonConfig.$nullData }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{ detail.remark || commonConfig.$nullData }}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from '@/api/menu'
const keepAliveMap = {
    0: '是',
    1: '否'
}
const breadcrumbMap = {
    0: '显示',
    1: '隐藏'
}
const hiddenMap = {
    1: '是',
    0: '否'
}
const statusMap = {
    1: '禁用',
    0: '启用'
}
const menuTypeMap = {
    1: '菜单',
    2: '按钮',
    3: '其他'
}
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        // eslint-disable-next-line vue/require-default-prop
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            visible: false, // 模块可见性
            parentsLables: [], // 父级数组标签
            detail: {
                name: '', // 菜单中文名
                pageName: '', // 菜单英文名
                component: '', // 模块路径
                type: '', // 菜单类型
                parentId: '', // 父级菜单id
                path: '', // 路由路径
                permission: '', // 权限
                sort: '', // 排序
                icon: '', // 图标
                keepAlive: '1', // 是否缓存
                hidden: '0', // 是否隐藏
                status: '0', // 启用/禁用状态
                remark: '', // 备注
                parentsLable: '' // 父级层级标签
            }
        }
    },
    watch: {
        visible(newVal, oldVal) {
            // 监听模块显示状态，显示时，自动获取信息
            if (newVal && this.id) this.getDetail()
        }
    },
    created() {},
    mounted() {},
    methods: {
        /** 获取父级菜单层级标签
         * @description:
         *      根据传入的父级id数组以及菜单树数据，
         *      循环递归，生成父级菜单级联标签，用"/"分隔
         * @param {Array}parentIds 父级id数组
         * @param {Array}tableArray 菜单树数据
         * @return {*}
         */
        getParentLabels(parentIds, tableArray) {
            tableArray.forEach((item) => {
                if (parentIds.includes(item.id)) {
                    this.parentsLables.push(item.name)
                }
                if (item.children) {
                    this.getParentLabels(parentIds, item.children)
                }
            })
        },
        /** 获取菜单详情
         * @description:
         *      获取菜单详情
         *      情况原有父级级联标签
         *      根据parentIds数组生成父级菜单级联标签， 以"/"分隔
         *      根据type字段及菜单列表，显示出对应的菜单类型名
         *      将"0"、"1"数据转换成对应的文字
         * @param {*}
         * @return {*}
         */
        getDetail() {
            getDetail({ id: this.id }).then(({res}) => {
                const detail = { ...this.detail, ...res }
                this.parentsLables = []
                // 根据parentIds数组生成父级菜单级联标签， 以"/"分隔
                this.getParentLabels(
                    detail.parentIds
                        .substring(0, detail.parentIds.length - 1)
                        .split(','),
                    this.tableData
                )
                detail.parentsLable = this.parentsLables.join('/')
                // 根据type字段及菜单列表，显示出对应的菜单类型名
                detail.type = menuTypeMap[detail.type]
                // 将"0"、"1"数据转换成对应的文字
                detail.keepAlive = keepAliveMap[detail.keepAlive]
                detail.status = statusMap[detail.status]
                detail.hidden = hiddenMap[detail.hidden]
                detail.breadcrumb = breadcrumbMap[detail.breadcrumb]
                this.detail = detail
            })
        },
        /**
         * @description: 更新模块可见性
         * @param {Boolean} flag true为可见, false为不可见
         * @return {*}
         */
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang='scss'>
</style>
