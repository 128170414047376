
<template>
  <el-dialog
    width="70%"
    class="dialog"
    :title="id === '' ? '新增评定方案' : '修改评定方案'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <!-- <div class="mdrcc-info-title">基本信息</div> -->
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="方案名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              clearable
              maxlength="20"
              @blur="setNamePinyinHead"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="评定分类" prop="scaleTypeId">
            <el-select
              v-model="form.scaleTypeId"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="operType in typeOptions"
                :key="operType.value"
                :label="operType.label"
                :value="operType.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        
      </el-row>
      <div class="mdrcc-info-title">方案量表</div>
      <el-row :gutter="10">
        <div class="table-btn-group">
          <el-button type="primary" @click="toggleModal()">添加量表</el-button>
        </div>
        <generalTable
          ref="mainTable"
          :table-data="tableData"
          :config="tableConfig"
          :total="total"
          :newHeight="'200'" 
        >
          <template slot="sort" slot-scope="scope">
            <el-input
              v-model="scope.data.row.sort"
              maxlength="4"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
            >
            </el-input>
          </template>
          <template slot="handle" slot-scope="scope">
            <el-button type="text" class="delBtn" @click="toDel(scope)"
              >删除</el-button
            >
          </template>
        </generalTable>
        <addScaleDialog :orgList="tableData" ref="addOper" @submit="add" />
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail, getScaleTypeList,getScaleList } from "@/api/evaluationScheme";
import addScaleDialog from '@/views/scale/evaluationScheme/addScaleDialog'
import generalTable from "@/components/Table";
import { CH2CYTPALL } from '@/utils/common'

export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  components: {
    generalTable,
    addScaleDialog
  },
  // 数据更新生命周期函数
  updated() {},
  data() {
    return {
      tableData:[],
      checkId: "",
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label: "评定分类", // 必填
            prop: "sctyName"
          },
          {
            label: "量表名称", // 必填
            prop: "scaleInfoName", // 必填
          },
          {
            label: "排序", // 必填
            slot: 'sort', // 必填
            width: "180", // 不必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right",
            width: "100", // 不必填
          },
        ],
      },
      rules: {
        name: [
          { required: true, message: "请输入方案名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
        scaleTypeId: [{ required: true, message: "请选择评定分类", trigger: "change" }],
      },
      operTypes: [],
      doctorOrderTypes: [],
      charges: [],
      timess: [],
      typeOptions:'',
      form: {
        checkbox: [],
        namePinyinHead: '',
        stName:'',
      },
      total: 0,
      visible: false,
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        this.id && this.getDetail();
        if (!this.id) {
          this.form = {
            ...this.form,
            stName:"",
          }
        }
      } 
    },

  },
  created() {
      this.getScaleTypeList();
  },
  mounted() {},
  methods: {
    add(newOpers){
      const newData = [...this.tableData, ...newOpers];
      this.tableData = newData.map((item,index) => {
        return {
          ...item,
          sort: item.sort ? item.sort : (index+1),
          chargeId: item.id
        }
      })
    },
    submit() {
      this.sortIndex=0
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = {...this.form, ruleScaleInfo: this.tableData};
          parm.id=this.id
          if(this.tableData == undefined || this.tableData.length <= 0){
            this.$message("方案量表不能为空，最少选择一张量表")
            return
          }else{
            if (parm.id === "" || parm.id == null || parm.id == undefined) {
              insertData(parm).then(({ res }) => {// success
                this.submitSuccess(true);
              });
            } else {
              editData(parm, this.timeStamp).then(({ res }) => {
                this.submitSuccess(true);
              });
            }
        }
        }
      });
    },
    submitSuccess(res) {
      this.$message({
        showClose: true,
        message: "操作成功",
        type: "success",
      });
      this.updateVisible(false);
      this.$emit("submit");
    },
    
     /**
     * @description: 获取评定分类
     * @param {*}
     * @return {*}
     */
    getScaleTypeList() {
        const param = {};
        getScaleTypeList(param).then(({ res, timeStamp }) => {
            this.typeOptions = res.map((element) => {
                const ele = {
                    value: element.id,
                    label: element.name,
                };
                return ele;
            });
        });
    },
    getDetail() {
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      this.getScaleList();
      getDetail(param).then(({ res, timeStamp }) => {
        this.form = Object.assign({},this.form,res);
      });
    },

    /**
     * 查询关联量表列表
     */
    getScaleList(){
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      getScaleList(param).then(({ res, timeStamp }) => {
        this.tableData = res;
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        this.tableData = this.tableData.filter(item => item.scaleInfoId !== scope.data.row.scaleInfoId)
        
      });
    },
   
    updateVisible(val) {
      this.sortIndex=0
             this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.form.sctyName=""
                 this.tableData=[]
               
            }
    },
    toggleModal(){
      this.$refs.addOper.updateVisible(true)
    },
   
    /**
     * 操作项简称赋值
     * 根据操作名称自动获取组套的拼音首字母
     */
    setNamePinyinHead()  {
      this.form.namePinyinHead = CH2CYTPALL(this.form.name);
    },
    
  },
};
</script>
<style scoped lang='scss'>
.mdrcc-info {
  height: calc(75vh - 400px);
  overflow-y: auto;
}
.doctor-order-add {
  margin-bottom: 15px;
}
.doctor-order-div {
  position: relative;
  margin-bottom: 15px;
}
.doctor-order-input {
  width: calc(100% - 48px);
  margin-right: 15px;
}
.doctor-order-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
      
        