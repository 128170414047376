
<!--
* @Description: 字典管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
    <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
    >
    <template slot="handle" slot-scope="scope">
      <el-button  type="text" @click="toEdit(scope)">详情</el-button>
      <el-button  type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
    </template>
    </generalTable>
    <detailDialog :id="checkId" ref="insert" @submit="search" />
    </el-main>
  </el-container>
</template>

<script>
import { getList,deleteData } from "@/api/etlLog";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import detailDialog from "@/views/dataPlatForm/kettle/detailDialog";

export default {
  name: "EtlConfig",
  components: {
    searchForm,
    generalTable,
    detailDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "select",
          placeholder: "执行结果",
          key: "stat",
          filterable: true,
          label: "类型",
          options: [
                {
                    value: '0',
                    label: '成功'
                },
                {
                    value: '1',
                    label: '失败'
                },
            ],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "etlConfig",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "请求时间", // 必填
            prop: "updateDate", // 必填
            formatter: (row, column) => {
              return row.updateDate?this.moment(row.updateDate).format("YYYY-MM-DD HH:mm:ss"): this.commonConfig.$nullData;
            },
            width: "200",
          },
          {
            label: "任务标识", // 必填
            prop: "jobName", // 必填
            width: "200",
          },
          {
            label: "执行结果", // 必填
            prop: "stat", // 必填
            formatter: (row, column) => {
              return ["成功", "失败"][row.stat];
            },
            width: "200",
          },
          {
            label: "结果信息", // 必填
            prop: "message", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
      delButton:true,//删除权限标识
      viewButton:true,//查看权限标识
    };
  },
  created() {
    this.delButton = this.hasPermission('etlLog-del')
    this.viewButton = this.hasPermission('etlLog-view')
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    }, 
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    /**
     * @description: 查看
     */
    toEdit(scope) {
      this.checkId = scope.data.row.id
      this.$refs.insert.updateVisible(true)
    },
    /**
     * @description: 删除
     */
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
        this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.getList();
        });
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    },
    
  },  
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        