<!--
 * @Author: your name
 * @Date: 2022-01-13 15:07:29
 * @LastEditTime: 2022-03-03 13:26:40
 * @LastEditors: ZF-WLY
 * @Description: 汇总展示页
 * @FilePath: \recovery_management\src\views\scale\patientAssessment\patientScaleSummary.vue
-->
<template>
    <el-container>
        <el-header class="page-header">
            <el-row>
                <el-button :size="size" @click="print()">打 印</el-button>
                <el-button :size="size" @click="back()">返 回</el-button>
            </el-row>
        </el-header>
        <el-main class="basic-main">
            <!-- 按配置显示汇总 -->
            <div v-if="showtype === '1'" id="table-print">
                <summaryTable
                    ref="table-print"
                    :scaleName="name"
                    :basicInfoId="basicInfoId"
                    :dataArray="scaleList"
                    :summaryRule="summaryRule"
                    :questionList="questionList"
                    :patientInfo="patientInfo"
                />
            </div>
            <!-- 按模板显示汇总 -->
            <div v-if="showtype === '2'" id="scale-print">
                <el-descriptions :column="5">
                    <el-descriptions-item
                        v-for="item in patientInfo"
                        :key="item"
                        :label="item.name"
                        >{{ item.descriptions }}</el-descriptions-item
                    >
                </el-descriptions>
                <summaryIndex
                    v-for="item in scaleList"
                    :id="item.id"
                    :key="item.id"
                    :info="item"
                />
            </div>
        </el-main>
    </el-container>
</template>

<script>
import { getScaleSummary } from "@/api/summaryScale";
import { getPatientPackage } from "@/api/common";
import { getTreatmentInfo } from "@/api/patient";
import summaryIndex from "@/views/scale/summary";
import summaryTable from "@/views/scale/summary/tableMode";
import { printHtml, print, printHtmlTable } from "@/utils/print"; // 引入公共方法中的打印方法
import { tableBorder } from "@/config/tablePrintStyle"; // 打印表格公共样式

export default {
    name: "assessScaleSummary",
    components: {
        summaryIndex, // 按模板显示汇总
        summaryTable, // 按表格显示汇总
    },
    data() {
        return {
            size: "mini", // 按钮大小
            basicInfoId: "", // 患者basicInfoId
            showtype: "", // 展示模式 "1" 按配置显示; "2" 按模板显示
            name: "", // 量表名
            scaleList: [], // 评估数据
            questionList: [], // 问题列表
            summaryRule: {}, // 汇总规则
            patientInfo: [],
        };
    },
    /** 页面创建
     * @description: 页面创建时,获取路由信息,获取汇总数据,更新basicInfoId
     * @param {*}
     * @return {*}
     */
    created() {
        this.getPatientPackage();
    },
    mounted() {
        const { id, basicInfoId } = this.$route.query;
        this.getScaleSummary(id);
        this.basicInfoId = basicInfoId;
    },
    methods: {
        /** 获取汇总数据
         * @description: 如果resultData不为空,按配置进行汇总;如果scaleList不为空,按模板显示
         *      按配置显示时,showtype设置为1,传入评估结果&配置数据&量表名&问题及选项
         *      按模板显示时,showtype设置为2,传入模板&问题及选项
         * @param {*} id
         * @return {*}
         */
        getScaleSummary(id) {
            getScaleSummary({ id })
                .then(({ res }) => {
                    const {
                        resultData,
                        scaleList,
                        summaryMouldCode,
                        questionList,
                        summaryJson,
                        name,
                    } = res;
                    if (resultData && resultData.length) {
                        this.showtype = "1";
                        this.scaleList = resultData; // 评估结果
                        this.summaryRule = summaryJson; // 汇总配置数据
                        this.questionList = questionList; // 问题及选项
                        this.name = name; // 量表名
                    } else if (scaleList && scaleList.length) {
                        this.showtype = "2";
                        this.scaleList = scaleList.map((item) => {
                            return {
                                ...item,
                                scaleType: "3",
                                scaleHtml: summaryMouldCode,
                                questionList,
                            };
                        });
                    }
                })
                .catch((error) => {
                    console.log("------getScaleSummary failure------", error);
                });
        },
        /** 打印
         * @description: 显示模板时,直接打印;显示配置时,调用子页面的print函数进行打印
         * @param {*}
         * @return {*}
         */
        print() {
            this.showtype === "1" && this.$refs["table-print"].print();
            this.showtype === "2" && printHtml("scale-print");
        },
        /**
         * @description: 获取全局评估基础信息数据
         */
        getPatientPackage() {
            getPatientPackage(0).then((res) => {
                // this.patientInfo = res.res.choose;
                this.headInfo(res.res.choose.sort((a,b) => a.sort-b.sort));
            });
        },
        headInfo(basicKeys) {
            getTreatmentInfo({ basicInfoId: this.basicInfoId }).then(
                ({ res }) => {
                    const { $nullData } = this.commonConfig;
                    let dataArr = [];
                    dataArr = basicKeys.map((ele) => {
                        let { name, keyField, sort, type } = ele;
                        let descriptions = "";
                        if (res[keyField] instanceof Array) {
                            descriptions = res[keyField].map(item => item.content).join("。")
                        } else {
                            descriptions = keyField === "sex" ?this.commonConfig.$sexList[res[keyField]] : res[keyField]
                        }
                        descriptions = descriptions || this.commonConfig.$nullData
                        return { name, keyField, sort, type, descriptions };
                    });
                    this.patientInfo = dataArr;
                }
            );
        },
        /** 返回上一页
         * @description: 路由返回
         * @param {*}
         * @return {*}
         */
        back() {
            this.$router.back();
        },
    },
};
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.page-header {
    height: auto !important;
    text-align: right;
    padding: 15px 20px;
}
</style>
