<!--
 * @Author: 张东阳
 * @Date: 2022-7-21 11:39:19
 * @LastEditTime: 2022-7-21 11:39:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\views\outSystem\treatmentRecord\index.vue
-->


<template>
  <div>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        @updatePage="getList"
      >
      </generalTable>
    </el-main>
  </div>
</template>
<script>
import generalTable from "@/components/Table";
import { getTreatmentListByVisitId } from "@/api/arrangeTreatment";

export default {
  name: "outSystemTreatmentRecord",
  components: {
    generalTable,
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        column: [
          {
            label: "患者姓名", // 必填
            prop: "basicInfoName", // 必填
          },
          {
            label: "住院号", // 必填
            prop: "recordNum", // 必填
          },
          {
            label: "就诊号", // 必填
            prop: "serialNum", // 必填
          },
          {
            label: "治疗师", // 必填
            prop: "therapyName", // 必填
          },
          {
            label: "医嘱类型", // 必填
            prop: "doctorOrderTypeLabel", // 必填
          },
          {
            label: "所属治疗", // 必填
            prop: "operTypeLabel", // 必填
          },
          {
            label: "治疗项目", // 必填
            prop: "operName", // 必填
          },
          {
            label: "治疗时间", // 必填
            prop: "treatmentDate", // 必填
            formatter: (row, column) => {
              return this.moment(row.treatmentDate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            },
          },
          {
            label: "状态", // 必填
            prop: "statusLabel", // 必填
          }
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkStatus: true,
      backStatus: true,
      timeStamp: "", //时间戳 用于修改数据或删除数据
      paramObject: null, //不带分页的参数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      hisVisitId: this.$route.query.id
    };
  },
  created() {
    this.getList();
  },
  mounted: function () {},
  methods: {
    getList() {
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        getTreatmentListByVisitId({hisVisitId: this.hisVisitId}).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";
</style>
