/*
 * @Author: your name
 * @Date: 2022-09-01 16:33:49
 * @LastEditTime: 2022-09-01 16:39:34
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\treatmentStatistics.js
 */

import request from '@/utils/request'

/**
 * @description: 获取治疗统计列表
 */
export function getList(data) {
    return request({
        url: 'statistics/doctorPerformance/doctorPerformanceList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}