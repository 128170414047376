<template>
  <el-dialog
    width="60%"
    title="数据接口日志详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" :column="1">
      <el-descriptions-item label="名称">{{
        detail.name
      }}</el-descriptions-item>
      <el-descriptions-item :contentClassName="'moreRow'" label="参数">{{
        detail.param
      }}</el-descriptions-item>
      <el-descriptions-item :contentClassName="'moreRow'" label="结果">{{
        detail.result
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{
        detail.status === "0" ? "成功" : "失败"
      }}</el-descriptions-item>
      <el-descriptions-item label="时间">{{
        this.moment(detail.createDate).format("YYYY-MM-DD HH:mm:ss")
      }}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from "@/api/webserviceLog";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      detail: [], // 详情数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && this.id) this.getDetail();
    },
  },
  created() {},
  mounted() {},
  methods: {
    getDetail() {
      getDetail({ id: this.id }).then(({ res }) => {
        this.detail = res;
      });
    },
    updateVisible(flag) {
      this.visible = flag;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
</style>
