<!--
 * @Author: your name
 * @Date: 2022-09-07 08:49:14
 * @LastEditTime: 2022-09-15 10:06:41
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\treatment\workbook\addDialog.vue
-->
<template>
    <el-dialog
        width="70%"
        title="核对详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-row>
            <el-col :span="24">
                <el-descriptions class="margin-top" title="患者信息" :column="4" :labelStyle="{width: '80px', justifyContent: 'flex-end'}">
                    <el-descriptions-item label="姓名">
                        {{ commonConfig.$checkNullData(patient.name) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">
                        {{ commonConfig.$checkNullData(patient.sex) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="住院/门诊号">
                        {{ commonConfig.$checkNullData(patient.hosNum) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="登记日期">
                        {{ commonConfig.$checkNullData(patient.useDate) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="排程数量">
                        {{ commonConfig.$checkNullData(patient.count) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="治疗数量">
                        {{ commonConfig.$checkNullData(patient.treatmentCount) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="登记数量">
                        {{ commonConfig.$checkNullData(patient.markCount) }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>
        
        <el-row gutter="20">
            <el-col :span="12">
                <div class="mdrcc-info-title">操作明细</div>
                <generalTable
                    ref="treatmentTable"
                    :table-data="treatmentList"
                    :config="treatmentConfig"
                    newHeight="200"
                    style="margin-bottom: 15px;"
                ></generalTable>
            </el-col>
            <el-col :span="12">
                <div class="mdrcc-info-title">登记明细</div>
                <generalTable
                    ref="markTable"
                    :table-data="markList"
                    :config="markConfig"
                    newHeight="200"
                    style="margin-bottom: 15px;"
                ></generalTable>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="mdrcc-info-title">操作记录</div>
                <generalTable
                    ref="chargeTable"
                    :table-data="chargeList"
                    :config="chargeConfig"
                    newHeight="150"
                    style="margin-bottom: 15px;"
                ></generalTable>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">关 闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import generalTable from '@/components/Table'
import { getCheckForm } from '@/api/treatmentVerification'
import { getDict } from '@/api/common'
export default {
    name: 'addWorkbook',
    components: {
        generalTable
    },
    props: {
        basicInfoId: String,
        type: String, // add为登记 edit为变更
        useDate: Number // 登记时间
    },
    data() {
        return {
            checkedIds: [],
            updateReason: '',
            operTypeMap: {},
            sexMap: {},
            visible: false,
            timeStamp: '',
            patient: {},
            // 治疗明细
            treatmentList: [],
            treatmentConfig: {
                id: 'treatmentTable',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType', // 必填
                        width: "80",
                        sortable: false
                    },
                    {
                        label: '操作项目', // 必填
                        prop: 'operName', // 必填
                        sortable: false
                    },
                    {
                        label: '项目创建时间', // 必填
                        prop: 'adviceDate', // 必填
                        sortable: false
                    },
                    {
                        label: '治疗时间', // 不必填
                        prop: 'treatmentDate', // 必填
                        sortable: false
                    },
                    {
                        label: '治疗师', // 不必填
                        prop: 'empName', // 必填
                        width: "100",
                        sortable: false
                    }
                ]
            },
            // 操作记录
            chargeList: [],
            chargeConfig: {
                id: 'chargeTable',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: '操作人', // 必填
                        prop: 'createBy', // 必填
                        sortable: false
                    },
                    {
                        label: '时间', // 不必填
                        prop: 'createDate', // 必填
                        sortable: false
                    },
                    {
                        label: '内容', // 不必填
                        prop: 'updateReason', // 必填
                        sortable: false
                    }
                ]
            },
            // 登记明细
            markList: [],
            markConfig: {
                id: 'markTable',
                align: 'center', // 不必填 默认为center
                index: true, // 不必填 序号
                column: [
                    {
                        label: '所属治疗', // 必填
                        prop: 'operType', // 必填
                        width: "80",
                        sortable: false
                    },
                    {
                        label: '操作项目', // 必填
                        prop: 'operName', // 必填
                        sortable: false
                    },
                    {
                        label: '项目创建时间', // 必填
                        prop: 'adviceDate', // 必填
                        sortable: false
                    },
                    {
                        label: '登记时间', // 不必填
                        prop: 'markDate', // 必填
                        sortable: false
                    },
                    {
                        label: '登记人', // 不必填
                        prop: 'markBy', // 必填
                        width: "100",
                        sortable: false
                    }
                ]
            }
        }
    },
    created() {
        console.log('workbook created------')
        this.getOperType()
        this.getSexType()
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.getData()
            } else {
                this.checkedIds = []
                this.patient = {}
                this.list = []
                this.updateReason = ''
            }
        }
    },
    methods: {
        getOperType() {
            const type = 'oper_type'
            getDict({ type }).then(({ res }) => {
                console.log('------oper_type', res)
                let operTypeMap = {}
                res[type].forEach(item => {
                    operTypeMap[item.value] = item.label
                })
                this.operTypeMap = operTypeMap
            })
        },
        getSexType(){
            const type="user_sex"
            getDict({ type }).then(({ res }) => {
                console.log('------oper_type', res)
                let resMap = {}
                res[type].forEach(item => {
                    resMap[item.value] = item.label
                })
                this.sexMap = resMap
            })
        },
        getData() {
            const param = {
                basicInfoId: this.basicInfoId,
                useDate: this.useDate
            }
            getCheckForm(param).then(({ res, timeStamp }) => {
                console.log('------getCheckForm', res)
                const {
                    patient, // 患者信息
                    treatmentList, // 治疗明细
                    markList, // 登记明细
                    chargeList // 操作记录
                } = res
                const {sex, useDate, ...others} = patient
                this.patient = {
                    ...others, 
                    sex: this.sexMap[sex], 
                    useDate: useDate* 1? this.moment(useDate * 1).format('YYYY-MM-DD HH:mm') : '--'
                }
                this.treatmentList = treatmentList.map(item => {
                    item.operType = this.operTypeMap[item.operType]
                    item.treatmentDate = this.moment(item.treatmentDate).format('YYYY-MM-DD HH:mm') || '--'
                    item.adviceDate = this.moment(item.adviceDate * 1 || new Date().getTime()).format("YYYY-MM-DD HH:mm")
                    return item
                })
                this.markList = markList.map(item => {
                    item.operType = this.operTypeMap[item.operType]
                    item.markDate = this.moment(item.markDate).format('YYYY-MM-DD HH:mm') || '--'
                    item.adviceDate = this.moment(item.adviceDate * 1 || new Date().getTime()).format("YYYY-MM-DD HH:mm")
                    return item
                })
                this.chargeList = chargeList.map(item => {
                    item.createDate = this.moment(item.createDate).format('YYYY-MM-DD HH:mm') || '--'
                    return item
                })
            })
        },
        /**
         * @description: 关闭
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
