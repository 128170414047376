
<template>
    <el-dialog width="70%" title="质控指标一览" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox"
            :rules="rules">
            <el-descriptions class="margin-top" title="基本信息" :column="4">
                <el-descriptions-item label="质控指标" :span="3">
                    {{ commonConfig.$checkNullData(form.name) }}
                </el-descriptions-item>
                <el-descriptions-item label="年份">
                    {{ commonConfig.$checkNullData(form.year) }}
                </el-descriptions-item>
                <el-descriptions-item label="指标定义" :span="4">
                    {{ commonConfig.$checkNullData(form.definition) }}
                </el-descriptions-item>
                <el-descriptions-item label="指标说明" :span="4">
                    {{ commonConfig.$checkNullData(form.description) }}
                </el-descriptions-item>
            </el-descriptions>

            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="12" :xl="12" v-for="(item, index) in form.dataList" :key="index">
                    <el-form-item :label="(index + 1) + '月'">
                        <el-input v-model="form.dataList[index]" placeholder="请输入..." clearable maxlength="20" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { editData, getDetail } from '@/api/indicatorSummary'

export default {
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            rules: {},
            form: {},
            total: 0,
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail()
                }
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const {year, id, dataList} = this.form
                    const parm = {
                        qualityControlId: id,
                        year: year * 1,
                        dataList
                    }
                    editData(parm, this.timeStamp).then(({ res }) => {
                        this.submitSuccess(res)
                    })
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail() {
            const param = { qualityControlId: this.id }
            getDetail(param).then(({ res, timeStamp }) => {
                this.form = res
                this.timeStamp = timeStamp
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
        }
    }
}
</script>
<style scoped lang='scss'>

</style>
      
