<!--
 * @LastEditors: Richardlv
 * @Author: Richardlv
-->


<template>
    <el-dialog
        width="70%"
        title="关联治疗师"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-button v-if="userAddSet" class="addPerson" type="primary" @click="addPerson()">新增</el-button>
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="userList"
        >
            <template slot="handle" slot-scope="scope">
                <el-button v-if="userDelSet" type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
            </template>
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <selectPerson selectType="multiple" ref="selectPerson" @selectPerson="selectPerson"></selectPerson>
    </el-dialog>
</template>
<script>

import selectPerson from '@/views/device/medicalCare/selectPerson'
import { userList, addUserArmariumRel, deleteUser } from "@/api/medicalCare";
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";

export default {
    components: {
        generalTable,
        searchForm,
        selectPerson,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            checkId: "",
            tableData: [],
            dept: [], //部门数据
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: this.selectType=="multiple"?"multiple":"single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "所属机构", // 必填
                        prop: "deptName", // 必填
                    },
                    {
                        label: "岗位", // 必填
                        prop: "position", // 必填
                    },
                    {
                        label: "姓名", // 必填
                        prop: "userName", // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    },
                ],
            },
            total: 0,
            visible: false,
            paramObj: null, // 筛选项
            timeStamp: "", //时间戳 用于修改数据或删除数据
            userAddSet:true,
            userDelSet:true,
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                this.userList();
            }
        },
    },
    created() {
        this.userAddSet = this.hasPermission('medicalCare-user-add')
        this.userDelSet = this.hasPermission('medicalCare-user-del')
    },
    mounted() {},
    methods: {
        addPerson(){
            this.$refs.selectPerson.updateVisible(true)
        },
        selectPerson(users){
            let usersId = users.map((item,index)=>{
                return item.id
            })
            let param={
                userId:usersId,
                armariumId:[this.id],
            }
            addUserArmariumRel(param).then((res)=>{
                this.userList();
                const { $handleSuccess } = this.commonConfig
                this.$message({...$handleSuccess})
            })
        },
        toDel(scope){
            const { $delMsg, $delTitle, $messageBoxConfig,$handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = {
                    userId: [scope.data.row.userId],
                    armariumId:[this.id],
                    }
                deleteUser(param,this.timeStamp).then(({res}) => {
                    this.$message({...$handleSuccess})
                    this.userList();
                })
            })
        },
        /**
         * @description: 获取治疗师列表
         * @param {*}
         * @return {*}
         */
        userList() {
            this.$nextTick(() => {
                const param = {id:this.id}
                userList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    this.timeStamp = timeStamp;
                });
            });
        },
        /**
         * @description: 关闭弹窗
         * @param {*} val
         * @return {*}
         */
        updateVisible(val) {
            if(!val){
                this.$emit("submit"); 
            }
            this.visible = val;
            
        },
    },
};
</script>
<style scoped lang="scss">
.addPerson{
    margin-bottom:10px
}
</style>
