<!--
 * @Description: 菜单框
 * @Author: yzw
 * @Date: 2021-03-10 11:16:11
 * @LastEditTime: 2022-11-15 10:45:13
 * @LastEditors: ZF-WLY
 * @Reference:
-->
<template>
  <el-container>
    <el-scrollbar class="menu-scroll">
      <el-main class="menu-main basic-main">
        <div class="menu-box">
          <div v-for="item in roles" :key="item.id" class="menu-item">
            <router-link :to="item.path">
              <el-image
                class="menu-item-img"
                :src="require(`@/assets/btnImg/${item.meta.icon}.png`)"
                fit="contain"
              >
              </el-image>
              <div class="menu-item-text" v-if="item.meta.icon === 'default'">{{item.meta.title}} </div>
            </router-link>
          </div>
        </div>
      </el-main>
    </el-scrollbar>
  </el-container>
</template>

<script>
export default {
	name: 'MenuBox',
	props: {
		roles: {
			type: Array,
			required: true
		}
	},
	data() {
		return {}
	}
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.menu-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-scroll{
  width: 100%;
  height: 100%;
  background: $white;
}
.menu-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 89%;
  margin: 0 auto;
  &::after {
    content: "";
    flex: 1;
  }
}
.menu-item {
  width: calc(calc(100% - 168px) / 6);
  margin: 14px;
  position: relative;
  &-img {
    transition: box-shadow 0.2s;
    border-radius: 5px;
    &:hover{
    box-shadow: 8px 6px 26px 1px
		rgba(108, 108, 108, 0.41);
    }
  }
  &-text {
      position: absolute;
      left: 80px;
      bottom: 15px;
      transform: translateX(-50%);
      font-size: 16px;
      color: #333333;
  }
}
</style>
