/*
 * @Author: magy “……”
 * @Date: 2021-12-29 11:36:02
 * @LastEditors: magy “……”
 * @LastEditTime: 2022-07-21 08:31:23
 * @FilePath: \recovery_management\src\api\judgeItems.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 评定项目统计
 * pageName: judgeItems
 **/
import request from '@/utils/request'

/**
 * @description: 获取评定项目统计列表数据
 */
export function getList(data) {
    return request({
        url: 'statistics/evaluationItem/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 删除评定项目统计数据
 */
    export function getDetailList(data) {
    return request({
        url: 'statistics/evaluationItem/detail',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
        