<!--
 * @Author: ligc
 * @Date: 2021-11-25 16:19:38
 * @LastEditTime: 2023-04-17 12:00:31
 * @LastEditors: mazihao mazihao@newpay.la
 * @Description: 治疗记录
 * @FilePath: \recovery_management\src\views\treatment\patientTreatment\index.vue
-->


<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch"
                  :searchoptions="searchOptions"
                  @search="search"
                  :backStatus="backStatus" />
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable"
                    :table-data="tableData"
                    :config="tableConfig"
                    :total="total"
                    @updatePage="getList">
        <template slot="handle"
                  slot-scope="scope">
          <el-button type="text"
                     @click="toDetail(scope)">详情</el-button>
        </template>
      </generalTable>
    </el-main>
  </el-container>
</template>
<script>
import generalTable from "@/components/Table";
import { getDept } from "@/api/patient";
import searchForm from "@/components/SearchForm";
import { exportTreat, getList } from "@/api/patientTreatment";
import { getDict } from "@/api/common";

export default {
  name: "patientTreatment",
  components: {
    generalTable,
    searchForm,
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "name",
          label: "患者姓名",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "deptId",
          filterable: true,
          label: "所属科室",
          options: [],
          defaultVal: [],
        },
        {
          type: "daterange",
          startTimePlaceholder: "入院/就诊开始日期",
          endTimePlaceholder: "入院/就诊结束日期",
          key: "treatmentDate",
          label: "入院时间",
          defaultVal: [],
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "serialNum",
          label: "就诊号",
          defaultVal: "",
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "status",
          filterable: true,
          label: "在院状态",
          options: [],
          defaultVal: [],
        },
      ],
      tableConfig: {
        id: "id",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: "患者姓名", // 必填
            prop: "name", // 必填
          },
          {
            label: "所属科室", // 必填
            prop: "deptName", // 必填
          },
          {
            label: "就诊号", // 必填
            prop: "serialNum", // 必填
          },
          {
            label: "住院号", // 必填
            prop: "recordNum", // 必填
          },
          {
            label: "入院/就诊时间", // 必填
            prop: "heDte", // 必填
            formatter: (row, column) => {
              return this.moment(row.heDte).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            label: "状态", // 必填
            prop: "status", // 必填
            formatter: (row, column) => {
              return ["出院", "在院", "门诊", "结束治疗"][row.status];
            },
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "180", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkStatus: true,
      backStatus: true,
      timeStamp: "", //时间戳 用于修改数据或删除数据
      options: [], // 所属科室
    };
  },
  created() {
    this.checkStatus = this.hasPermission("treatment-record");
    this.getList();
    this.getDeptList(); //获取科室
    this.getTypeDict(); //获取患者状态
  },
  mounted: function () {},
  methods: {
    search(data = {}) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    /**
     * @description: 获取在院状态 字典表
     * @param {Array}患者在院状态  patient_inHos_status 字典表类型
     * @return {*}
     */
    getTypeDict() {
      const inHosStatus = [];
      getDict({ type: "patient_inHos_status" }).then(({ res }) => {
        res.patient_inHos_status.forEach((element) => {
          const ele = {
            value: element.value,
            label: element.label,
          };
          inHosStatus.push(ele);
        });
      });
      this.searchOptions[4].options = inHosStatus;
    },
    /**
     * @description: 获取所属科室
     * @param {*}deptType 部门类型 4=>科室
     * @return {*}
     */
    getDeptList() {
      getDept({ deptType: "4" }).then(({ res, timeStamp }) => {
        res.forEach((element) => {
          const ele = {
            value: element.id,
            label: element.label,
          };
          this.options.push(ele);
        });
        this.searchOptions[1].options = this.options;
      });
    },
    back() {
      this.$router.back();
    },
    getList() {
      // 解决未挂载完组件时调用子组件方法报错问题
      this.$nextTick(() => {
        const param = this.getParam();
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.total = parseInt(res.total);
          this.timeStamp = timeStamp;
        });
      });
    },
    /**
     * @description:导出
     * @param {*}
     * @return {*}
     */
    toExport() {
      const obj = this.$refs.mainSearch.packageData(); //查询参数
      exportTreat(obj).then(({}) => {
        this.$message({ ...this.commonConfig.$handleSuccess });
      });
    },
    /**
     * @description:详情
     * @param {*}
     * @return {*}
     */
    toDetail(scope) {
      const id = scope.data.row.id;
      this.$router.push({
        path: "/treatment/detail",
        query: { basicInfoId: id },
      });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      if (paramObj.endTime && paramObj.startTime) {
        paramObj.startTime = new Date(paramObj.startTime).getTime();
        paramObj.endTime = new Date(paramObj.endTime).getTime();
      }
      const param = Object.assign({}, page, paramObj);
      this.paramObj = param;
      return param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
