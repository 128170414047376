<!--
 * @Author: your name
 * @Date: 2022-11-15 16:04:15
 * @LastEditTime: 2022-11-16 09:36:42
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\caseReport\tags\detailDialog.vue
-->

<template>
    <el-dialog
        width="78%"
        title="标签详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <el-descriptions class="margin-top" title="标签详情" :column="3">
            <el-descriptions-item label="标签名称">{{form.name || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{form.status || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="创建人">{{form.createName || commonConfig.$nullData }}</el-descriptions-item>
            <el-descriptions-item label="备注">{{form.remark || commonConfig.$nullData }}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {labelInfo} from "@/api/caseReport"
export default {
    name: 'AddDialog',
    components: {},
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            form: {
                name: '',
                status: '',
                remark: '',
                createName: ''
            },
            visible: false,
        }
    },
    created() {
        this.id && this.getLabelInfo()
    },
    mounted() {
    },
    methods: {
        /**
         * @description:根据身份证获取患者信息
         * @param {*}idCard 输入身份证号
         * @return {*}
         */
        getLabelInfo() {
            const param = {id: this.id}
            labelInfo(param).then(({res})=> {
                // 状态 0 启用 1禁用
                const statusMap = {"0": "启用", "1": "禁用"}
                const {status, ...others} = res
                this.form = {
                    ...others,
                    status: statusMap[status]
                }
            })
        },
        updateVisible(val) {
            this.visible = val
            if (!val) {
                this.$refs.insertForm.resetFields()
            } else {
                this.id && this.getLabelInfo()
            }
        },
    }
}
</script>
<style scoped></style>
