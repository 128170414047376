/*
 * @Author: your name
 * @Date: 2022-11-15 10:59:20
 * @LastEditTime: 2022-11-17 16:39:21
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\api\caseReport.js
 */
import request from '@/utils/request'

/**
 * 标签相关接口
 */

/**
 * @description: 标签列表
 * @param {Object} data
 */
export function caseLabelList(data) {
    return request({
        url: '/casesTemplateLabel/list',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 标签详情
 * @param {Object} data
 */
export function labelInfo(data) {
    return request({
        url: '/casesTemplateLabel/info',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 新增病例标签
 * @param {Object} data
 */
export function insertLabel(data) {
    return request({
        url: 'casesTemplateLabel/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 更新病例标签
 * @param {Object} data
 */
export function updateLabel(data, timeStamp) {
    return request({
        url: 'casesTemplateLabel/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * 标签相关接口结束
 */

/**
 * 模板相关接口
 */

/**
 * @description: 可选标签
 * @param {Object} data
 */
export function getCasesProperty(data) {
    return request({
        url: 'casesTemplate/getCasesProperty',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 模板列表
 * @param {Object} data
 */
export function caseTemplateList(data) {
    return request({
        url: 'casesTemplate/list',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * @description: 新增病例模板
 * @param {Object} data
 */
export function insertTemplate(data) {
    return request({
        url: 'casesTemplate/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 更新病例模板
 * @param {Object} data
 */
export function updateTemplate(data, timeStamp) {
    return request({
        url: 'casesTemplate/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除病例模板
 * @param {Object} data
 */
export function deleteTemplate(data, timeStamp) {
    return request({
        url: 'casesTemplate/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 病例模板详情
 * @param {Object} data
 */
export function casesTemplateInfo(data) {
    return request({
        url: 'casesTemplate/info',
        method: 'GET',
        showLoading: true,
        params: data
    })
}

/**
 * 模板相关接口结束
 */
