/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2021-12-21 14:29:18
 * @LastEditors: yzw
 * @Reference:
 */
const getters = {
  device: state => state.app.device,
  token: state => state.user.token,
  trueName: state => state.user.trueName,
  userName: state => state.user.userName,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  hospitalInfo: state => state.hospital.hospitalInfo,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  unReadMsgList: state => state.user.unReadMsgList
}
export default getters
