<!--
 * @Author: your name
 * @Date: 2022-11-24 14:19:15
 * @LastEditTime: 2022-12-13 09:30:53
 * @LastEditors: ZF-WLY
 * @Description: In User Settings Edit
 * @FilePath: \recovery_mdrcc_4.0\src\views\patient\summary\addTreatmentDialog.vue
-->

<template>
    <el-dialog
        width="70%"
        class="dialog"
        title="关联评估项"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" />
        </el-header>
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            :height="300"
            @selection-change="handleSelectionChange"
            @updatePage="getPatientAssessment"
        ></generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getScaleTypeList } from '@/api/evaluationScheme'
import { getPatientAssessment } from '@/api/patientTreatmentConclusion'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import moment from 'moment'
export default {
    props: {
        basicInfoId: String,
        orgList: Array
    },
    components: {
        searchForm,
        generalTable
    },
    // 数据更新生命周期函数
    updated() {},
    data() {
        return {
            checkId: '',
            tableData: [],
            searchOptions: [
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'scaleTypeId',
                    filterable: true,
                    label: '评定分类',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '量表名称',
                    key: 'name',
                    label: '量表名称',
                    defaultVal: ''
                }
            ],
            selectList: [], // 全部选中项
            currentSelect: [], // 当前页选中项
            otherSelect: [], // 其他页选中项
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '评定分类',
                        sortable: false,
                        prop: 'typeName'
                    },
                    {
                        label: '类型',
                        sortable: false,
                        prop: 'patientScaleTypeLabel'
                    },
                    {
                        label: '量表/方案名称',
                        sortable: false,
                        prop: 'name'
                    },
                    {
                        label: '评定日期',
                        sortable: false,
                        prop: 'evaluateDate',
                        formatter: (row, column) => {
                            return row.evaluateDate ? this.moment(row.evaluateDate).format('YYYY-MM-DD') : '--'
                        }
                    },
                    {
                        label: '评定人',
                        sortable: false,
                        prop: 'userName'
                    },
                    {
                        label: '状态',
                        sortable: false,
                        prop: 'patientScaleStatusLabel'
                    },
                ]
            },
            total: 0,
            visible: false,
            timeStamp: '' //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.basicInfoId) {
                this.selectList = [...this.orgList]
                this.currentSelect = [...this.orgList]
                this.otherSelect = []
                this.getPatientAssessment()
            } else {
                this.$refs.mainSearch.resetForm()
                this.selectList = []
            }
        }
    },
    created() {
        this.getScaleTypeList()
    },
    mounted() {},
    methods: {
        /**
         * 根据康复评定项名称--查询除选择的以外的所有量表项
         */
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /**
         * 查询除选择的以外的所有量表项
         */
        getPatientAssessment() {
            this.$nextTick(() => {
                const page = this.$refs.mainTable.getPage()
                const baseParam = {
                    basicInfoId: this.basicInfoId,
                    patientScaleType: '0',
                    patientScaleStatus: '2'
                }
                const param = { ...baseParam, ...this.getParam(), ...page}
                // 获取接口前把当前页选中项与其他页选中项拼在一起，就是
                this.selectList = [...this.currentSelect, ...this.otherSelect]
                getPatientAssessment(param).then(( {res} ) => {
                    const { records, total } = res
                    this.tableData = [...records]
                    // 全部选中项与当前页表格数据交集为当前页选中项
                    this.currentSelect = this.selectList.filter(e => this.tableData.map(item => item.id).includes(e.id))
                    // 全部选中项与非当前页表格数据交集为其他页选中项
                    this.otherSelect = this.selectList.filter(e => !this.tableData.map(item => item.id).includes(e.id))
                    // 选出与当前页表格数据匹配的选中项数据，暂存起来
                    // 由于翻页会触发select-change事件，如果不把选中数据缓存，会导致翻页后选中项清空
                    const selectIds = this.selectList.map(item => item.id)
                    const selectArray = this.tableData.filter(item => selectIds.includes(item.id))
                    this.total = parseInt(total)
                    this.$nextTick(() => {
                        this.$refs.mainTable.toggleSelection([...selectArray])
                    })
                })
            })
        },
        handleSelectionChange(val) {
            this.currentSelect = [...val]
            this.$refs.mainTable.tableChecked = val
            
        },
        submit() {
            this.selectList = [...this.currentSelect, ...this.otherSelect]
            if (this.selectList.length == 0) {
                this.$message('请选择一条量表项目')
            } else {
                
                this.$emit('submit', this.selectList)
                this.selectList = []
                this.updateVisible(false)
            }
        },
        submitSuccess(res) {
            this.$message({
                showClose: true,
                message: '操作成功',
                type: 'success'
            })
            this.updateVisible(false)
            this.$emit('submit')
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message(this.$handleSuccess)
                })
            })
        },
        /**
         * @description: 获取评定分类
         * @param {*}
         * @return {*}
         */
        getScaleTypeList() {
            const param = {}
            getScaleTypeList(param).then(({ res, timeStamp }) => {
                this.typeOptions = res.map(element => {
                    const ele = {
                        value: element.id,
                        label: element.name
                    }
                    return ele
                })
                this.searchOptions[0].options = this.typeOptions
            })
        },
        updateVisible(val) {
            // if (!val) {
            //     this.$refs.mainSearch.resetForm()
            //     this.$refs.mainTable.handleCurrentChange(1)
            // }
            this.visible = val
        },

        /**
         *  获取查询参数
         * @Description:
         * @Param null
         * @Return 参数对象
         * @Author wangjh
         * @Date 2021/11/11 9:46
         */
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>
<style scoped lang="scss">
.mdrcc-info {
    height: calc(75vh - 400px);
    overflow-y: auto;
}
.doctor-order-add {
    margin-bottom: 15px;
}
.doctor-order-div {
    position: relative;
    margin-bottom: 15px;
}
.doctor-order-input {
    width: calc(100% - 48px);
    margin-right: 15px;
}
.doctor-order-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
</style>
