<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->
<template>
    <el-dialog title="治疗设备绑定" :visible.sync="visible" :close-on-click-modal="false" width="60%" :show-close="false">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search"/>
        <generalTable 
            ref="mainTable" 
            :table-data="deviceResList" 
            :config="tableConfig" 
            :total="total"
            :checkIds="checkIds"
            @updateSelectedIds="updateSelectedIds" 
            @updatePage="getUserNotChooseArmarium" 
        />
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSubmit()">确 定</el-button>
            <el-button @click="cancelSubmit()">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getUserNotChooseArmarium, addUserArmariumRel } from '@/api/personalSetting'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: 'addDevice',
    components: {
        generalTable,
        searchForm
    },
    data() {
        return {
            visible: false,
            // 设备列表
            deviceResList: [],
            tableConfig: {
                id: 'deviceResList',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                saveSelected: true, // 翻页选中
                column: [
                    {
                        label: '设备名称', // 必填
                        prop: 'deviceName'
                    },
                    {
                        label: '设备编号', // 必填
                        prop: 'deviceNumber' // 必填
                    },
                    {
                        label: '关联治疗项', // 必填
                        prop: 'operName' // 必填
                    }
                ]
            },
            checkIds: [],
            total: 0,
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'deviceName',
                    label: '设备名称',
                    defaultVal: ''
                }
            ],
            paramObj: {
                inStatus:'0,2'
            },
        }
    },
    watch: {
        visible(newVal, oldVal) {
            // 显示弹窗时，获取未绑定的设备列表
            if (newVal === true) {
                this.getUserNotChooseArmarium()
            }
        }
    },
    mounted() {},
    created() {},
    props: {
        timeStamp: String
    },
    methods: {
        search(data) {
            this.$refs.mainTable.handleCurrentChange(1)
        },
        /** 获取未绑定设备列表
         * @description: 无法获取table组件时，传入空对象
         * @param {*}
         * @return {*}
         */
        getUserNotChooseArmarium() {
            this.$nextTick(() => {
                const param = this.getParam()
                getUserNotChooseArmarium(param).then(({ res }) => {
                    this.deviceResList = res.records
                    this.total = Number(res.total)
                })
            })
        },
        /**
         * 更新可见性
         * @description: 通过传入的val空调模态框的显隐
         * @param {Boolean} val true时显示；false时隐藏
         * @return {*}
         */
        updateVisible(val) {
            this.visible = val
        },
        /** 取消提交
         * @description: 隐藏弹窗
         * @param {*}
         * @return {*}
         */        
        cancelSubmit() {
            this.updateVisible(false)
        },
        /** 确认
         * @description: 获取选中项，当无选中项时，弹窗提示
         *      有选中项时，将选中项中的id字段取出拼成数组，作为armariumId字段传入添加绑定设备接口
         *      成功后，进入this.submitSuccess()函数
         * @param {*}
         * @return {*}
         */        
        onSubmit() {
            const checkList = this.$refs.mainTable.getChecked()
            if (!checkList.length) {
                this.$message({
                    type: 'warning',
                    message: '至少选择一台设备!'
                })
                return false
            }
            const armariumId = checkList.map((item) => item.id)
            addUserArmariumRel({ armariumId }).then(({ res }) => {
                this.submitSuccess()
            })
        },
        /** 更新选中的项目ids
         * @description: 将payload中数组更新为当前页面的this.checkIds
         * @param {Array} checkIds
         * @return {*}
         */        
        updateSelectedIds(checkIds){
            this.checkIds = [...checkIds]
        },
        /** 添加绑定设备的成功回调函数
         * @description: 消息提示，触发父级'@submit'绑定的函数，隐藏当前窗口
         * @param {*} checkList
         * @return {*}
         */        
        submitSuccess(checkList) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables.scss';
</style>