
<!--
* @Description: 按照治疗项医嘱统计详情页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <div style="display: flex; justify-content: end; align-items: center; margin-bottom: 5px">
        <el-button type="default" @click="back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getDetail">

      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
import { getDetail } from '@/api/treatmentOrder'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: 'TreatmentOrderDetail',
    components: {
        searchForm,
        generalTable,
    },
     data() {
    return {
      tableData: [],
      tableConfig: {
        id: 'TreatmentOrderDetail',
        align: 'center', // 不必填 默认为center
        selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
        pagination: true, // 不必填 分页 默认为false
        index: true, // 不必填 序号
        column: [
          {
            label: '患者姓名', // 必填
            prop: 'patientName' // 必填
          },
          {
            label: '开单医生', // 必填
            prop: 'trueName' // 必填
          },
          {
            label: '操作项名称', // 必填
            prop: 'operName' // 必填
          },
          {
            label: '医嘱执行次数', // 必填
            prop: 'orderNums' // 必填
          }
        ]
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: '', //时间戳 用于修改数据或删除数据
      searchParam: null
    }
  },
  created() {
    this.searchParam = {
      userId: this.$route.query.userId,
      operId: this.$route.query.operId,
      adviceDateStartTime: this.$route.query.adviceDateStartTime,
      adviceDateEndTime: this.$route.query.adviceDateEndTime
    }
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$nextTick(() => {
        const param = this.getParam()
        getDetail(param).then(({ res, timeStamp }) => {
          this.tableData = res.records
          this.total=parseInt(res.total)
        })
      })
    },
    onClickRow(row) {
    },
    getParam() {
      const searchParam = this.searchParam
      const page = this.$refs.mainTable.getPage()
      const param = Object.assign({}, this.paramObj, page, searchParam)
      this.paramObj = param
      return param
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        