/*
 * @Author: your name
 * @Date: 2021-12-03 13:39:14
 * @LastEditTime: 2022-02-28 10:58:53
 * @LastEditors: ZF-WLY
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_management\src\api\privateScaleLib.js
 */

/**
 * 页面: 评估量表库
 * pageName: privateScaleLib
 **/
import request from '@/utils/request'

/**
 * @description: 获取评估量表库列表数据
 */
export function getList(data) {
    return request({
        url: '/scaleInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取评定分类
 */
export function getTypeScale(data) {
    return request({
        url: '/scaleType/getScaleType',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 禁用/启用量表模板
 * @param {Object} data
 * @return {*}
 */
export function disableScale(data, timeStamp) {
    return request({
        url: '/scaleInfo/disableScale',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除评估量表库数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/scaleInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改排序
 */
export function changeSort(data, timeStamp) {
    return request({
        url: '/scaleInfo/changeSort',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 新增量表模板数据
 */
export function insertDataPrivate(data, timeStamp) {
    return request({
        url: '/scaleInfo/add',
        method: 'POST',
        data,
        timeout: 100 * 1000,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 量表模板详情
 */
export function getDetailPrivate(data) {
    return request({
        url: '/scaleInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 提交评估量表库规则
 */
export function addScaleConclusion(data, timeStamp) {
    return request({
        url: '/scaleInfo/addScaleConclusion',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表规则
 */
export function getPrivateScaleRule(data) {
    return request({
        url: '/scaleInfo/getScaleRule',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 复制量表模板数据
 */
export function copyDataPrivate(data, timeStamp) {
    return request({
        url: '/scaleInfo/copy',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表问题分类
 */
export function getScaleTypeTreeList(data) {
    return request({
        url: '/scaleQuestionType/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 修改量表模板数据
 */
export function updateDataPrivate(data, timeStamp) {
    return request({
        url: '/scaleInfo/update',
        method: 'POST',
        data,
        showLoading: true,
        timeout: 100 * 1000,
        timeStamp
    })
}
/**
 * @description: 新增量表问题分类
 */
export function addScaleTypePrivate(data) {
    return request({
        url: '/scaleQuestionType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 编辑量表问题分类
 */
export function updateScaleTypePrivate(data, timeStamp) {
    return request({
        url: '/scaleQuestionType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除量表问题分类
 */
export function delScaleTypePrivate(data, timeStamp) {
    return request({
        url: '/scaleQuestionType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取量表问题列表
 */
export function getScaleQuestion(data) {
    return request({
        url: '/scaleQuestion/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取量表问题列表
 */
export function getScaleQuestionInfo(data) {
    return request({
        url: '/scaleQuestion/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 添加量表库量表问题表
 */
export function addScaleQuestion(data) {
    return request({
        url: '/scaleQuestion/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 编辑量表库量表问题表
 */
export function updateScaleQuestion(data, timeStamp) {
    return request({
        url: '/scaleQuestion/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除量表问题
 */
export function delScaleQuestion(data, timeStamp) {
    return request({
        url: '/scaleQuestion/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
