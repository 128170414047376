<!--
* @Description: 患者医嘱统计页面
* @Reference:
-->

<template>
    <el-main class="basic-main">
    <el-row class="table-btn-group">
      <el-button style="float:right" plain @click="back()">返回</el-button>
    </el-row>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
        <template slot="adviceNums" slot-scope="scope">
          <el-button @click="toDetail(scope)" type="text" >{{scope.data.row.adviceNums}}</el-button>
        </template>
      </generalTable>
    </el-main>
</template>

<script>
import { getList } from '@/api/patientOrder'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
  name: 'PatientOrder',
  components: {
    searchForm,
    generalTable
  },
  data() {
    return {
      tableData: [],
      tableConfig: {
        id: 'patientOrder',
        align: 'center', // 不必填 默认为center
        selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: '患者姓名', // 必填
            prop: 'patientName' // 必填
          },
          {
            label: '性别', // 必填
            prop: 'sex' // 必填
          },
          {
            label: '住院号', // 必填
            prop: 'recordNum' // 必填
          },
          {
            label: '就诊号', // 必填
            prop: 'serialNum' // 必填
          },
          {
            label: '在院状态', // 必填
            prop: 'status' // 必填
          },
          {
            label: '所属科室', // 必填
            prop: 'deptName' // 必填
          },
          {
            label: '入院/就诊日期', // 必填
            prop: 'heDte', // 必填
            formatter: (row, column) => {
              return this.moment(row.heDte).format('YYYY-MM-DD')
            }
          },
          {
            label: '床号', // 必填
            prop: 'bedNum' // 必填
          },
          {
            label: '治疗医嘱数', // 必填
            slot: 'adviceNums' // 必填
          },
          {
            label: '营收金额', // 必填
            prop: 'amount' // 必填
          }
        ]
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: '', //时间戳 用于修改数据或删除数据
      searchParam: null //查询参数
    }
  },
  created() {
    this.searchParam = {
      userId:this.$route.query.userId,
      adviceDateStartTime:this.$route.query.adviceDateStartTime,
      adviceDateEndTime:this.$route.query.adviceDateEndTime
    }
    this.getList()
  },
  methods: {
    getList() {
      this.$nextTick(() => {
        const param = this.getParam()
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records
          this.total=parseInt(res.total)
        })
      })
    },
    toDetail(scope) {
      const queryParam = {
        userId: this.searchParam.userId,
        basicInfoId: scope.data.row.basicInfoId,
        adviceDateStartTime: this.searchParam.adviceDateStartTime,
        adviceDateEndTime: this.searchParam.adviceDateEndTime,
      }
      this.$router.push({ path: '/statistics/doctorWorkload/personal/patient/order', query:queryParam})
    },
    back() {
      this.$router.back()
    },
    getParam() {
      const searchParam = this.searchParam
      const page = this.$refs.mainTable.getPage()
      const param = Object.assign({}, this.paramObj, page, searchParam)
      this.paramObj = param
      return param
    }
  }
}
</script>

<style lang='scss' scoped>
  @import "~@/styles/variables.scss";
</style>

