
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增模板管理' : '修改模板管理'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        ><el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="模板名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="请输入..."
                    clearable
                    maxlength="20"
                    
                    @input="messageName(form.name)"
                />
                </el-form-item>
            </el-col>
            
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="状态" prop="status">
                    <el-radio v-model="form.status" label="0">启用</el-radio>
                    <el-radio v-model="form.status" label="1">禁用</el-radio>
                </el-form-item>
            </el-col>
            </el-row>
            <div class="mdrcc-info-title">
            基本信息
            
            <input type="checkbox" id="checkbox" v-model="form.checked" @change="changeAllChecked()">
            全选
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :xl="24">
                    <el-form-item label="" prop="checkedNames">
                    <input type="checkbox" id="name" value="name_姓名" v-model="form.checkedNames">
                    <label>
                        姓名
                    </label>
                    <input type="checkbox" id="sex" value="sex_性别" v-model="form.checkedNames">
                    <label >
                        性别
                    </label>
                     <input type="checkbox" id="age" value="age_年龄" v-model="form.checkedNames">
                    <label >
                        年龄
                    </label>
                    <input type="checkbox" id="department" value="department_所属科室" v-model="form.checkedNames">
                    <label >
                        所属科室
                    </label>
                    <input type="checkbox" id="inpatientNumber" value="inpatientNumber_住院号" v-model="form.checkedNames">
                    <label >
                        住院号
                    </label>
                    <input type="checkbox" id="admissionTime" value="admissionTime_入院时间" v-model="form.checkedNames">
                    <label >
                        入院时间
                    </label>
                    <input type="checkbox" id="dischargeTime" value="dischargeTime_出院时间" v-model="form.checkedNames">
                    <label >
                        出院时间
                    </label>
                    <input type="checkbox" id="educational" value="educational_文化程度" v-model="form.checkedNames">
                    <label >
                        文化程度
                    </label> 
                </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
            <div class="mdrcc-info-title">
            诊断信息
            <input type="checkbox" id="checkbox" v-model="form.diagnosis" @change="diagnosisAllChecked()">
            全选
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :xl="12">
                    <el-form-item label="" prop="diagnosisNames">
                        <input type="checkbox" id="complaint" value="complaint_主诉" v-model="form.diagnosisNames">
                        <label>
                            主诉
                        </label>
                        <input type="checkbox" id="history" value="history_既往史" v-model="form.diagnosisNames">
                        <label>
                            既往史
                        </label>
                        <input type="checkbox" id="medical" value="medical_现病史" v-model="form.diagnosisNames">
                        <label>
                            现病史
                        </label>
                        <input type="checkbox" id="disease" value="disease_疾病诊断" v-model="form.diagnosisNames">
                        <label>
                            疾病诊断
                        </label>
                        
                    </el-form-item>
                    
                </el-col>
            </el-row>
            <el-divider />
            <div class="mdrcc-info-title">
            检查信息
            
            <el-button
            class="doctor-order-add"
            type="primary"
            icon="el-icon-plus"
            @click="addList"
            >添加</el-button>
            </div>
            <el-row :gutter="10">
                <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                :newHeight="'200'" 
                >
            
          <template slot="checkProgram" slot-scope="scope">
            <el-select
              v-model="scope.data.row.checkProgram"
              placeholder="请输入关键字"
              :filterable="true"
            >
              <el-option
                v-for="option in inspection"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          
          <template slot="checkTime" slot-scope="scope">
            <el-select
              v-model="scope.data.row.checkTime"
              placeholder="请输入关键字"
              :filterable="true"
            >
              <el-option
                v-for="option in checkTime"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          <template slot="handle" slot-scope="scope">
            <el-button type="text" class="delBtn" @click="deleList(scope)"
              >删除</el-button
            >
          </template>
        
        </generalTable>
        </el-row>

        <div class="mdrcc-info-title">
            治疗项目
            <el-button
            class="doctor-order-add"
            type="primary"
            icon="el-icon-plus"
            @click="addTreatmentList"
            >添加</el-button>
            </div>
            <el-row :gutter="10">
                <generalTable
                ref="mainTable"
                :table-data="treatmentTableData"
                :config="treatmentTableConfig"
                :total="total"
                :newHeight="'200'" 
                >
            
          <template slot="treatment" slot-scope="scope">
            <el-select
              v-model="scope.data.row.treatment"
              placeholder="请输入关键字"
              :filterable="true"
            >
              <el-option
                v-for="option in treatment"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
    
          <template slot="handle" slot-scope="scope">
            <el-button type="text" class="delBtn" @click="deleTreatmentList(scope)"
              >删除</el-button
            >
          </template>
        
        </generalTable>
        </el-row>


        <div class="mdrcc-info-title">
            评估量表
            <el-button
            class="doctor-order-add"
            type="primary"
            icon="el-icon-plus"
            @click="addScaleList"
            >添加</el-button>
            </div>
            <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="" prop="derived">
                    <el-radio v-model="form.derived" label="0">按结果导出</el-radio>
                    <el-radio v-model="form.derived" label="1">按量表明细导出</el-radio>
                    <el-radio v-model="form.derived" label="2">按结果与量表明细导出</el-radio>
                </el-form-item>
            </el-col>
            <el-row :gutter="10">
                <generalTable
                ref="mainTable"
                :table-data="scaleTableData"
                :config="scaleTableConfig"
                :total="total"
                :newHeight="'200'" 
                >
            
          <template slot="scale" slot-scope="scope">
            <el-select
              v-model="scope.data.row.scale"
              placeholder="请输入关键字"
              :filterable="true"
            >
              <el-option
                v-for="option in scaleName"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </template>
          
          <template slot="range" slot-scope="scope">
           当次评估时间-首次评估时间>=
            <el-input
            class="numinput"
              v-model="scope.data.row.range"
              maxlength="5"
            >
            </el-input>
        </template>
         
            
          <template slot="handle" slot-scope="scope">
            <el-button type="text" class="delBtn" @click="deleScaleList(scope)"
              >删除</el-button
            >
          </template>
        
        </generalTable>
        </el-row>



        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData,getDetail,getList ,getInspection,getOper,getScale,messageName} from '@/api/tempManage'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common';
          
export default {
    props: {
        id: {
            type: String
        },
        timeStamp:{
            type: String
        }
    },
    components: {
      generalTable,
    },
    data() {
        return {
            checkId: '',
            tableData: [],
            treatmentTableData: [],
            scaleTableData: [],
            tableConfig: {
               
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                {
                    label: "检查项目", // 必填
                    slot: "checkProgram"
                },
               
                {
                    label: "检查时间", // 必填
                    slot: "checkTime"
                },
                {
                    slot: "handle", // 不必填 单列插槽
                    label: "操作", // 必填
                    fixed: "right",
                    width: "100", // 不必填
                },
                ],
            },
            treatmentTableConfig: {
               
               align: "center", // 不必填 默认为center
               selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
               index: true, // 不必填 序号
               pagination: false, // 不必填 分页 默认为false
               column: [
               {
                   label: "治疗项目", // 必填
                   slot: "treatment"
               },
              
               {
                   slot: "handle", // 不必填 单列插槽
                   label: "操作", // 必填
                   fixed: "right",
                   width: "100", // 不必填
               },
               ],
           },
           scaleTableConfig: {
               
               align: "center", // 不必填 默认为center
               selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
               index: true, // 不必填 序号
               pagination: false, // 不必填 分页 默认为false
               column: [
               {
                   label: "量表名称", // 必填
                   slot: "scale"
               },
               {
                   label: "范围", // 必填
                   slot: "range"
               },
              
               {
                   slot: "handle", // 不必填 单列插槽
                   label: "操作", // 必填
                   fixed: "right",
                   width: "100", // 不必填
               },
               ],
           },
            rules: {
                name: [
                    { required: true, message: '请输入模板名称', trigger: 'blur' },
                    { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' }
                ],
               
            },
          
            form: {
                checked: false,
                name:"",
                derived:"0",
                checkedNames: [],
                checkedArr: ["name_姓名", "sex_性别","age_年龄","department_所属科室","inpatientNumber_住院号","admissionTime_入院时间","dischargeTime_出院时间","educational_文化程度"],
                diagnosis: false,
                diagnosisNames: [],
		        diagnosisArr: ["complaint_主诉", "history_既往史","medical_现病史","disease_疾病诊断"],
                status: '0', // 0启用，1禁用，默认启用
                
            },
            mseeName: 'false',
            inspection:"",
            checkTime:[],
          
            range:"",
            scaleName:"",
            treatment:"",
            total: 0,
            visible: false,
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getDetail()
            }
            //...todo
          }
        },
        "form.checkedNames": function() {
			if (this.form.checkedNames.length == this.form.checkedArr.length) {
				this.form.checked = true
			} else {
				this.form.checked = false
			}
        },
        "form.diagnosisNames": function() {
			if (this.form.diagnosisNames.length == this.form.diagnosisArr.length) {
				this.form.diagnosis = true
			} else {
				this.form.diagnosis = false
			}
		}
    },
    created() {
        this.getList()
        this.getScaleList()
        this.getTreatmentList()
       this.getInspection()
       this.getOper()
       this.getScale()
       this.getCheckTime()
       
    },
    mounted() {},
    methods: {
         /**
     * @description: 校验模板名称是否重复
     * @param {*}
     * @return {*}
     */
     messageName(name) {
        const parm = {
            name: name
              }
              messageName(parm).then(({ res }) => {
                  if(res==true){this.$message("模板名称重复，请重新输入");}
                  this.mseeName = res
        });
      },
     /**
     * @description: 检查信息中的列表
     * @param {*}
     * @return {*}
     */
        getList() {
        this.$nextTick(() => {
            this.tableData =[{},{},{},{},{}];
        });
      },
      
    /**
     * @description: 检查信息中的列表新增
     * @param {*}
     * @return {*}
     */
      addList() {
        this.$nextTick(() => {
            this.tableData.push({})
        });
      },
    /**
     * @description: 检查信息中的列表删除
     * @param {*}
     * @return {*}
     */
     deleList(res) {
        this.$nextTick(() => {
            this.tableData.splice(res.data.$index,1)
        });
      },


   /**
     * @description: 治疗项目中的列表
     * @param {*}
     * @return {*}
     */
     getTreatmentList() {
        this.$nextTick(() => {
            this.treatmentTableData =[{},{},{},{},{}];
        });
      },
    /**
     * @description: 治疗项目中的列表新增
     * @param {*}
     * @return {*}
     */
      addTreatmentList() {
        this.$nextTick(() => {
            this.treatmentTableData.push({})
        });
      },
    /**
     * @description:治疗项目中的列表删除
     * @param {*}
     * @return {*}
     */
     deleTreatmentList(res) {
        this.$nextTick(() => {
            this.treatmentTableData.splice(res.data.$index,1)
        });
      },


 /**
     * @description: 评估量表中的列表
     * @param {*}
     * @return {*}
     */
     getScaleList() {
        this.$nextTick(() => {
            this.scaleTableData =[{},{},{},{},{}];
        });
      },
    /**
     * @description: 评估量表中的列表新增
     * @param {*}
     * @return {*}
     */
      addScaleList() {
        this.$nextTick(() => {
            this.scaleTableData.push({})
        });
      },
    /**
     * @description: 评估量表中的列表删除
     * @param {*}
     * @return {*}
     */
     deleScaleList(res) {
        this.$nextTick(() => {
            this.scaleTableData.splice(res.data.$index,1)
        });
      },




        changeAllChecked: function() {
			if (this.form.checked) {
				this.form.checkedNames = this.form.checkedArr
			} else {
				this.form.checkedNames = []
			}
        },
        diagnosisAllChecked: function() {
			if (this.form.diagnosis) {
				this.form.diagnosisNames = this.form.diagnosisArr
			} else {
				this.form.diagnosisNames = []
			}
		},
        submit() {
            if(this.mseeName==true){
                this.$message("模板名称重复，请重新输入");
            }else{
                this.$refs.insertForm.validate((valid) => {
                    if (valid) {
                    //const parm = this.form
                    const parm = {...this.form, tableData: this.tableData,treatmentTableData:this.treatmentTableData,scaleTableData:this.scaleTableData,ids:this.id};
                    if (this.id === '') {
                        insertData(parm).then(({res}) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm,this.timeStamp).then(({res}) => {
                           
                           this.submitSuccess(res)
                        })
                    }
                    }
                })
            }
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail(){
        
            getDetail({id:this.id}).then(({res}) => {
                this.form.name = res.name
                this.form.status = res.status
                if(res.checkedNames!=null){
                    this.form.checkedNames = res.checkedNames
                }
                if(res.diagnosisNames!=null){
                    this.form.diagnosisNames = res.diagnosisNames
                }
                if(res.tableData!=null){
                    this.tableData = res.tableData
                }else{
                    this.getList()
                }
                if(res.treatmentTableData!=null){
                    this.treatmentTableData = res.treatmentTableData
                }else{
                    this.getTreatmentList()
                }
                if(res.scaleTableData!=null){
                    this.scaleTableData = res.scaleTableData
                }else{
                    this.getScaleList()
                }
                if(res.derived!=null){
                    this.form.derived=res.derived
                }else{
                    this.form.derived="0"
                }
                
               
                
            })
        },
       
         /**
     * @description: 检查信息中的检查项目
     * @param {*}
     * @return {*}
     */
     getInspection() {
        const param = {};
        getInspection(param).then(({ res }) => {
            this.inspection = res.map((element) => {
                const ele = {
                    value: element.name,
                    label: element.name,
                };
                return ele;
            });
        });
    },
        /**
     * @description:治疗项目中的治疗项目
     * @param {*}
     * @return {*}
     */
     getOper() {
        const param = {};
        getOper(param).then(({ res }) => {
            this.treatment = res.map((element) => {
                const ele = {
                    value: element.id+"_"+element.name,
                    label: element.name,
                };
                return ele;
            });
        });
    },
         /**
     * @description:评估量表中的量表名称
     * @param {*}
     * @return {*}
     */
     getScale() {
        const param = {};
        getScale(param).then(({ res }) => {
            this.scaleName = res.map((element) => {
                const ele = {
                    value: element.scaleId+"_"+element.scaleName,
                    label: element.scaleName,
                };
                return ele;
            });
        });
    },
            /**
     * @description: 检查信息中的检查时间
     * @param {*}
     * @return {*}
     */
    getCheckTime(){
        getDict({ type: 'check_time'}).then(({res}) => {
            this.checkTime= res.check_time;
        });
      },
       
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.tableData =[{},{},{},{},{}];
                this.treatmentTableData =[{},{},{},{},{}];
                this.scaleTableData =[{},{},{},{},{}];
            }
        }
    }
}
</script>
<style scoped lang='scss'>
.numinput {
  width: 50%;
}
.mdrcc-info-title{
    margin-top: 10px;
}
</style>
      
        