<!--
 * @Author: ZF-WLY
 * @Date: 2021-12-29 09:00:41
 * @LastEditTime: 2021-12-30 15:39:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \recovery_management\src\views\other\Patient360\components\assessmentReport.vue
-->
<template>
    <el-container class="page-container">
        <el-header class="page-header">
            <el-button type="primary" @click="back()">返 回</el-button>
        </el-header>
        <div class="reportFileBox">
            <reportFile :fileKey="fileKey" :type="filetype" :printFlag="printFlag" />
        </div>
    </el-container>
</template>
<script>
import { getScaleReportDetail } from '@/api/patientAssessment'
import reportFile from "@/views/scale/patientAssessment/reportFile.vue"; //显示报告
export default {
    components: {
        reportFile,
    },
    created(){
        this.getScaleReportDetail();
        this.printFlag=this.$route.query.printFlag
    },
    data(){
        return {
            fileKey: "",
            filetype: "",
            printFlag:"0",
        }
    },
    methods: {
        /**
         * @description: 获取报告详情
         * @param {*}
         * @return {*}
         */
        getScaleReportDetail() {
            this.$nextTick(() => {
                const param = {
                    id: this.$route.query.id
                }
                getScaleReportDetail(param).then(({ res, timeStamp }) => {
                    this.fileKey = res.file.id
                    this.filetype = res.file.suffix
                })
            })
        },
        /**
         * @description: 返回上一页
         * @param {*}
         * @return {*}
         */        
        back(){
            this.$router.back(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
// 页面容器
.page-container {
    display: block;
    background-color: $menuText;
    overflow-y: auto;
}
// 页面按钮标题行
.page-header {
    height: auto !important;
    text-align: right;
    padding: 0;
}
</style>