<!--
 * @LastEditors: yzw
 * @Author: Richardlv
-->

<template>
    <el-dialog
        width="60%"
        title="设备库详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions
            class="margin-top"
            title="设备信息"
            :column="2"
            
        >
            <el-descriptions-item label="设备名称">{{name || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="设备型号">{{model || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="所属治疗">{{operTypeLabel || commonConfig.$nullData}}</el-descriptions-item>
            <el-descriptions-item label="备注">{{remark || commonConfig.$nullData}}</el-descriptions-item>
        </el-descriptions>
        <div class="mdrcc-info-title">关联治疗项</div>
        <generalTable
            ref="mainTable"
            :table-data="operList"
            :config="tableConfig"
            :total="total"
        >
        </generalTable>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)"
                >取 消</el-button
            >
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from "@/api/library";
import generalTable from '@/components/Table'
export default {
    components: {
        generalTable,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "治疗项名称", // 必填
                        prop: "name", // 必填
                    },
                ],
            },
            total: 0, // 总条数
            operList: [],
            name:"",
            model:"",
            operTypeLabel:"",
            remark:"",
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.name=res.name;
                this.model=res.model;
                this.operTypeLabel=res.operTypeLabel;
                this.remark=res.remark;
                this.operList = res.operations
            });
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
