
<!--
* @Description: 自定义配置详情页面
* @Reference:
-->

<template>
<el-container>
    <el-tabs class="disposeTabs" type="border-card">
        <el-tab-pane label="列表配置">
            <table-column :menuId="this.$route.query.id" />
        </el-tab-pane>
        <el-tab-pane label="筛选项配置">
            <filter-items :menuId="this.$route.query.id" />
        </el-tab-pane>
    </el-tabs>
</el-container>
</template>

<script>
import tableColumn from '@/views/system/customCollocation/dispose/tableColumn'
import filterItems from '@/views/system/customCollocation/dispose/filterItems'

export default {
    name: 'Dispose',
    components: {
        filterItems,
        tableColumn
    },
    data() {
        return {
        }
    },
    created(){
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.disposeTabs{
    width: 100%;
}
</style>

        